
import { ref, watch } from 'vue';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import SecretTip from '../campaigns/create/SecretTip.vue';

export default {
  name: 'ContentPiece',
  components: {
    SecretTip,
    InputText,
    Textarea,
  },
  props: {
    item: Object,
    number: Number,
    videosInfo: Object,
  },
  setup(props: any, ctx: any) {
    const imagePreview = ref();
    const uploadPhotoInput = ref();
    const activeIndex = ref(0);
    const allPhotos = ref<Array<string | any>>([]);
    const mainPreviewImage = ref();
    // eslint-disable-next-line vue/no-setup-props-destructure
    allPhotos.value = props.item.photos;
    const contentTittle = ref<string>(props.item?.title || '');
    const contentDescription = ref<string>(props.item?.description || '');
    watch(() => props.item, (first, second) => {
      contentTittle.value = first.title;
      contentDescription.value = first.description;
      allPhotos.value = first.photos;
    });
    const newContentPiece = ref({
      title: contentTittle.value,
      description: contentDescription.value,
      statistics: props.item.statistics,
      vid: props.item.vid,
      url: props.item.url,
      photos: [allPhotos.value[activeIndex.value]],
      index: props.number,
    });
    watch(contentTittle, () => {
      newContentPiece.value.title = contentTittle.value;
      ctx.emit('changeItemPiece', newContentPiece);
    });
    watch(contentDescription, () => {
      newContentPiece.value.description = contentDescription.value;
      ctx.emit('changeItemPiece', newContentPiece);
    });
    watch(activeIndex, () => {
      newContentPiece.value.photos = [allPhotos.value[activeIndex.value]];
      ctx.emit('changeItemPiece', newContentPiece);
    });
    const activePreview = (index: any) => {
      activeIndex.value = index;
      mainPreviewImage.value = allPhotos.value[index];
    };
    const removePhoto = (index: any) => {
      if (allPhotos.value.length > 1) {
        allPhotos.value.splice(index, 1);
      }
    };
    const copyLink = (url: any) => {
      navigator.clipboard.writeText(url);
    };

    const namesPhotos = ref(['youtube photo']);
    const handleFileUpload = () => {
      uploadPhotoInput.value = uploadPhotoInput.value.files[0];
      namesPhotos.value.push(uploadPhotoInput.value.name);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        allPhotos.value.push(reader.result);
        imagePreview.value = reader.result;
      }, false);
      if (uploadPhotoInput.value) {
        if (/\.(jpe?g|png|gif)$/i.test(uploadPhotoInput.value.name)) {
          reader.readAsDataURL(uploadPhotoInput.value);
        }
      }
      ctx.emit('changeItemPiece', newContentPiece);
    };
    const removeItem = () => {
      ctx.emit('removePiece', props.number);
    };
    return {
      removeItem,
      uploadPhotoInput,
      imagePreview,
      handleFileUpload,
      // preview
      activeIndex,
      allPhotos,
      activePreview,
      removePhoto,
      contentTittle,
      contentDescription,
      copyLink,
      namesPhotos,
    };
  },
};
