import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewAuth = _resolveComponent("NewAuth")!
  const _component_OldAuth = _resolveComponent("OldAuth")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.isNewLoginPageEnabled)
      ? (_openBlock(), _createBlock(_component_NewAuth, {
          key: 0,
          handleRouting: $setup.handleRouting,
          handleEmailSignIn: $setup.handleEmailSignIn,
          userType: $setup.userType,
          handleAdminEmailSignIn: $setup.handleAdminEmailSignIn,
          handleUserConnect: $setup.handleUserConnect,
          connecting: $setup.connecting
        }, null, 8, ["handleRouting", "handleEmailSignIn", "userType", "handleAdminEmailSignIn", "handleUserConnect", "connecting"]))
      : _createCommentVNode("", true),
    (!$setup.isNewLoginPageEnabled)
      ? (_openBlock(), _createBlock(_component_OldAuth, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}