
import { ref } from 'vue';
import { formatDate } from '@/utils/globals/date-utils';
import { useTransfers } from '@/modules';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useRoute } from 'vue-router';

export default {
  name: 'BillingAccount',
  components: { DataTable, Column },
  props: {},
  setup() {
    const route = useRoute();
    const transfersModule = useTransfers();
    transfersModule.list.getCreatorTransfers(route.params?.creatorID as string, 50, 0);
    const selectedAll = ref(false);
    const platformsOptions = ref([{
      value: 'Campaign',
      type: 'campaign',
      selected: false,
    }, {
      value: 'License',
      type: 'license',
      selected: false,
    }]);
    const clearFilters = () => {
      // eslint-disable-next-line no-param-reassign,no-return-assign
      platformsOptions.value.forEach((el) => el.selected = false);
      selectedAll.value = false;
    };
    const addPlatform = (element: any) => {
      const select = platformsOptions.value.findIndex((el) => el.value === element.value);
      if (platformsOptions.value[select].selected) {
        platformsOptions.value[select].selected = false;
        selectedAll.value = false;
      } else {
        platformsOptions.value[select].selected = true;
      }
    };
    const selectAllPlatforms = () => {
      if (selectedAll.value) {
        platformsOptions.value.forEach((el: any) => {
          // eslint-disable-next-line no-param-reassign
          el.selected = false;
        });
        selectedAll.value = false;
      } else {
        platformsOptions.value.forEach((el: any) => {
          // eslint-disable-next-line no-param-reassign
          el.selected = true;
        });
        selectedAll.value = true;
      }
    };

    const exportInvoice = async (creditNote: string) => {
      const link = document.createElement('a');
      link.href = creditNote;
      link.download = 'image file name here';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return {
      clearFilters,
      formatDate,
      selectAllPlatforms,
      addPlatform,
      exportInvoice,
      platformsOptions,
      transfers: transfersModule.list.getCreatorTransfersData,
    };
  },
};
