
import { useRouter } from 'vue-router';
import AskHint from '../utils/AskHint.vue';
import { formatDate } from '../../../utils/globals/date-utils';
import StatusChip from '../../commons/status/StatusChip.vue';

export default {
  name: 'LatestCampaignItem.vue',
  props: {
    item: Object,
  },
  components: {
    AskHint,
    StatusChip,
  },
  setup(props: any) {
    const router = useRouter();
    const goToCampaign = () => {
      router.push({ name: 'campaign-active', params: { campaignID: props.item.id || props.item[0].id } });
    };

    const charset = (v: string) => {
      if (v.length >= 81) {
        return `${v.slice(0, 78)}...`;
      }
      return v;
    };
    return {
      charset,
      goToCampaign,
      formatDate,
    };
  },
};
