
import { defineComponent, ref, watch } from 'vue';
import { useAdmins } from '@/modules';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import StatusChip from '@/pages/commons/status/StatusChip.vue';
import { formatDate } from '../../utils/globals/date-utils';

export default defineComponent({
  components: {
    Button,
    StatusChip,
    DataTable,
    Column,
    Dialog,
    Dropdown,
    InputText,
    Calendar,
    TextArea,
  },
  setup() {
    const adminsModule = useAdmins();
    const rejectReason = ref('');
    const approveReason = ref('');
    const seeRejectReason = ref(false);
    const seeApproveReason = ref(false);
    const currentRejectVideoId = ref();
    const currentApproveVideoId = ref();
    const selectedQuery = ref('pending');
    const filterAuthorName = ref('');
    const ownerName = ref('');
    const selectedOwnerType = ref('creator');
    const dateFilter = ref<any[]>();
    const filterCampaignName = ref('');
    const loadingState = ref(false);
    const queryesStatus = [
      { name: 'Pending', value: 'pending' },
      { name: 'Accepted', value: 'accepted' },
      { name: 'Rejected', value: 'rejected' },
    ];
    adminsModule.manager.getCampaignsWithPendingVideoCpm(0, 0, 'pending', {
      campaignName: filterCampaignName.value,
      authorName: filterAuthorName.value,
      date: dateFilter.value,
    });

    const approveVideo = async () => {
      loadingState.value = true;
      adminsModule.manager
        .approvalCpmVideo(currentApproveVideoId.value, approveReason.value)
        .then((data) => {
          if (data.success) {
            adminsModule.manager.getCampaignsWithPendingVideoCpmResult.value =
              adminsModule.manager.getCampaignsWithPendingVideoCpmResult.value.filter(
                (el: any) => el?._id !== currentApproveVideoId.value
              );
          }
          seeApproveReason.value = false;
          loadingState.value = false;
        });
    };

    const changeStatusQuery = () => {
      adminsModule.manager.getCampaignsWithPendingVideoCpm(
        0,
        0,
        selectedQuery.value,
        {
          campaignName: filterCampaignName.value,
          authorName: filterAuthorName.value,
          date: dateFilter.value,
          ownerName: ownerName.value,
          ownerType: selectedOwnerType.value,
        }
      );
    };

    watch(dateFilter, () => changeStatusQuery());

    const rejectVideo = async () => {
      loadingState.value = true;
      adminsModule.manager
        .rejectCpmVideo(currentRejectVideoId.value, rejectReason.value)
        .then((data) => {
          if (data.success) {
            adminsModule.manager.getCampaignsWithPendingVideoCpmResult.value =
              adminsModule.manager.getCampaignsWithPendingVideoCpmResult.value.filter(
                (el: any) => el?._id !== currentRejectVideoId.value
              );
            seeRejectReason.value = false;
            loadingState.value = false;
          }
        });
    };

    const debounce = (func: { (): void; apply?: any }, timeout = 400) => {
      let timer: NodeJS.Timeout;
      return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    };
    const processChange = debounce(() => changeStatusQuery());

    return {
      contentNeedActionAdmin:
        adminsModule.manager.getCampaignsWithPendingVideoCpmResult,
      approveVideo,
      rejectVideo,
      changeStatusQuery,
      formatDate,
      loadingState,
      approveReason,
      seeApproveReason,
      currentApproveVideoId,
      processChange,
      rejectReason,
      currentRejectVideoId,
      seeRejectReason,
      selectedQuery,
      queryesStatus,
      dateFilter,
      ownerName,
      selectedOwnerType,
      filterAuthorName,
      filterCampaignName,
    };
  },
});
