
import LatestCampaignItem from "@/pages/creators/latest/LatestCampaignItem.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { useAuth, useCreators } from "@/modules";
import { AgencyWorkers, CampaignData, CampaignPaymentMethod, User } from "@/data/types";
import { useRoute } from "vue-router";
import { Ref, ref, watch } from "vue";
import { formatDate } from "../../../utils/globals/date-utils";
import { findCurrentMetricsChannel } from "../../../utils/campaign-utils";
import AskHint from "../utils/AskHint.vue";
import { MonthlyEarnings } from '@/data/types/response-types';
import { EarningsStats } from '@/data/types/earning-types';

export default {
  name: "Overview",
  props: {
    licensedContent: Array,
    applications: Array,
    latestContracts: Array,
  },
  components: {
    LatestCampaignItem,
    DataTable,
    Column,
    AskHint,
  },
  setup(props: { latestContracts: Array<CampaignData> }) {
    const { user } = useAuth();
    const route = useRoute();
    const creatorsModule = useCreators();
    const cpmStats = ref();
    const currentChannelInfo = ref();

    if (props.latestContracts && props.latestContracts.length >= 5) {
      props.latestContracts = props.latestContracts.reverse();
      props.latestContracts.length = 5;
    }

    watch(
      () => props.latestContracts,
      () => {
        if (props.latestContracts?.length) {
          const campaignsCpmIds: string[] = [];
          props.latestContracts.forEach((campaign) => {
            if (campaign?.paymentMethod === "CPM") {
              campaignsCpmIds.push(campaign?.id);
            }
          });
          if (campaignsCpmIds?.length) {
            creatorsModule.manager.earningGeneratedSoFarCpm(campaignsCpmIds, route.params?.creatorID as string).then((data) => {
              cpmStats.value = data;
            });
          }
        }
      }
    );

    const totalOveral = ref(0);
    creatorsModule.manager.overallMonthlyEarningSoFar(route.params?.creatorID as string).then((data: any) => {
      totalOveral.value = data;
    });

    const getMonthlyEarnings = (campaignWithContract: CampaignData, isAgency: boolean): EarningsStats | [] => {
      let stats;

      if (typeof campaignWithContract?.contracts === "undefined" || campaignWithContract?.contracts?.length === 0) return [];
      
      if (isAgency) {
        const currentChannelId = campaignWithContract!.contracts[0]!.channel || campaignWithContract!.contracts[0]!.channelId;
        stats = findCurrentMetricsChannel(currentChannelId!, campaignWithContract, true);
        getCurrentChannelInfo(campaignWithContract);
      } else {
        const currentCreatorId = route.params?.creatorID as string;
        stats = findCurrentMetricsChannel(currentCreatorId, campaignWithContract, false);
      }

      return stats;
    };

    const getCurrentChannelInfo = (campaignWithContract: any) => {
      const currentChannelId = campaignWithContract?.contracts[0]?.channel;

      const channelInfo = user!.value!.agencyWorkers!.filter((worker: AgencyWorkers) => worker.channel.channelId === currentChannelId);

      if (channelInfo.length > 0)
        currentChannelInfo.value = {
          channelName: channelInfo[0]?.channel?.title,
          channelPhoto: channelInfo[0]?.channel?.photo,
        };
    };

    return {
      getCurrentChannelInfo,
      currentChannelInfo,
      formatDate,
      getMonthlyEarnings,
      user,
      CampaignPaymentMethod,
      cpmStats,
      totalOveral,
    };
  },
};
