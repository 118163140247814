import React, { useState } from 'react';
import './NewGenericPrompt.css';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function (props) {
  const { showSecondaryButton } = props;
  const [viewModal, setViewModal] = useState(true);
  const [inputValue, setInputValue] = useState('');

  const generateMessage = () => {
    const { version } = props;

    if (version === 'requestLicense') {
      return `Thank you for making an offer to purchase the creator's content, a GIO representative will be in touch soon. This request will expire in 14 days. Another one can be made if there is no response.`;
    } else if (version === 'withdrawLicense') {
      return `The license request is no longer active. You can always submit a new license request for this content.`;
    } else if (version === 'invalidEmail') {
      return `It seems your YouTube login gave us a @pages.plusgoogle.com email address, please input the email address you would like to have linked to payment and platform notifications:`;
    } else if (version === 'gigapayReminder') {
      return `Don't forget to register with our payment processor, Gigapay, to ensure you are eligible for payout!`;
    } else if (version === "cpmApplicationsWaitApproval") {
      return `The brand has been notified of your interest in taking part in this campaign and you will receive an answer soon. Keep an eye on your notifications and your inbox for a response in the coming days.`;
    } else if (version === "cpmApplicationRejectedCreator") {
      return `Unfortunately, your application for this campaign was rejected and you will not be able to participate.`;
    } else {
      return ``;
    }
  };

  const onSubmit = () => {
    let argument = null;

    if (props.useInputForSubmitFn) {
      argument = inputValue;
    } else if (props.useComponentVersionFn) {
      argument = props.version;
    }

    if (props.submitFunction) {
      props.submitFunction(argument);
    }

    setViewModal(false);
  };

  return (
    <Dialog visible={viewModal} onHide={() => setViewModal(false)} className={'genericPromptModal'}>
      {props.displayData && props.displayData.htmlElementToUse === 'DataTable' && props.displayData.displayMultiple && (
        <DataTable value={props.displayData.content}>
          {props.displayData.columns && props.displayData.columns.map((column, columnIndex) => (
            <Column key={columnIndex} header={column} sortable={false} body={(data) => (
              <span>{data[Object.keys(data)[columnIndex]]}</span>
            )} />
          ))}
        </DataTable>
      )}
      {props.displayData && props.displayData.htmlElementToUse === 'div' && <div>{props.displayData.content}</div>}
      {props.displayData && props.displayData.htmlElementToUse === 'p' && <p>{props.displayData.content}</p>}

      <p className="message">{generateMessage()}</p>

      {props.containsInput && <InputText type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />}

      <div className="buttonsWrapper">
        {showSecondaryButton && <Button label={props.closeBtnLabel ?? 'Not now'} className="notNowButton" onClick={onSubmit} />}
        <Button label="OK" icon="pi pi-check" className="okButton" onClick={onSubmit} />
      </div>
    </Dialog>
  )
}
