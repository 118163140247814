
import { defineComponent, PropType } from 'vue';
import { Brand, BrandData } from '../../../data/types';
import { applyPureReactInVue } from 'veaury';
import { isNewBrandEnabled } from "@/utils/globals/feature-flags";
import OldBrandPhoto from "@/pages/brands/profile/OldBrandPhoto.vue";
import NewBrandPhoto from "@/react_app/NewBrand/Profile/NewBrandPhoto/NewBrandPhoto";

export default defineComponent({
  name: 'BrandPhoto',
  components: {
    NewBrandPhoto: applyPureReactInVue(NewBrandPhoto),
    OldBrandPhoto
  },
  props: {
    brand: {
      type: Object as PropType<Brand | BrandData>,
    },
  },
  setup() {
    return {
      isNewBrandEnabled,
    }
  }
});
