import { User } from "@/data/types/user-types";
import { CampaignData } from "@/data/types/campaign-types";

export interface ITransactionItem {
  userId?: User | any;
  campaignId: CampaignData;
  brand: string;
  userType: string; // creator or agencyOwner
  amount: number;
  currency: string;
  creditNode: string | undefined; // pdf document ( link S3 )
  perMonth: Date;
  paidFor: {
    android: number;
    IOS: number;
    clicks: number;
    views: number;
  },
  createdAt: Date;
  id?: string;
  _id: string;
}

export enum PaymentStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Paid = 'paid',
}
