import ToastEventBus from 'primevue/toasteventbus';
import { APIError } from '@/utils/globals/error-utils';

export const useToastModule = () => {
  const add = (message: unknown) => {
    ToastEventBus.emit('add', message);
  };

  const showSuccess = (detail = '', summary = 'Success') => {
    add({
      severity: 'success',
      summary,
      detail,
      life: 3000,
    });
  };

  const showError = (detail = '', summary = 'Error') => {
    add({
      severity: 'error',
      summary,
      detail,
      life: 3000,
    });
  };

  const handleRequestSuccess = (detail = '', summary = 'Success') => () => {
    showSuccess(detail, summary);
  };

  const handleRequestError = (err?: APIError) => {
    if (err) {
      showError(err?.message);
    }
  };

  return {
    showSuccess,
    showError,
    handleRequestSuccess,
    handleRequestError,
  };
};
