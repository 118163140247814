import { AppPagePerspective, Perspective } from '@/data/types/page-types';
import { useContentItemsList } from './content-items-list-module';
import { useContentItemsManager } from './content-items-manager-module';
import { useContentItemsRequests } from './content-items-requests-module';

export const useContentItems = (perspective: AppPagePerspective = Perspective.CREATOR) => {
  const manager = useContentItemsManager(perspective);
  const list = useContentItemsList(perspective);
  const requests = useContentItemsRequests(perspective);

  return {
    manager,
    list,
    requests,
  };
};
