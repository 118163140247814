
import { reactive, ref, watch } from 'vue';
import InputText from 'primevue/inputtext';
import inputProductUrls from './inputProductUrls.vue';
import AskHint from '../../creators/utils/AskHint.vue';

export default {
  components: {
    InputText,
    AskHint,
    inputProductUrls,
  },
  props: {
    campaign: Object,
  },
  setup(props: any, ctx: any) {
    const valueProductNameInput = ref(localStorage.getItem('cname') || props.campaign?.productName || '');
    const valueTalkingLinkInput = ref(localStorage.getItem('valueTalkingLinkInput') || props.campaign?.trackingLink || '');
    const gamekeys = ref(props.campaign?.gamekeys || localStorage.getItem('gamekeys') || '');
    const parseProductUrls = reactive(props.campaign?.productUrls || JSON.parse(localStorage.getItem('parseProductUrls') as string) || [{
      type: 'Google',
      value: '',
    }, {
      type: 'Apple',
      value: '',
    }, {
      type: 'Webpage',
      value: '',
    }]);
    watch([gamekeys, valueProductNameInput, valueTalkingLinkInput, parseProductUrls], () => {
      localStorage.setItem('gamekeys', gamekeys.value);
      localStorage.setItem('cname', valueProductNameInput.value);
      localStorage.setItem('valueTalkingLinkInput', valueTalkingLinkInput.value);
      localStorage.setItem('parseProductUrls', JSON.stringify(parseProductUrls));
      ctx.emit(
        'countingProgress',
        valueProductNameInput.value,
        valueTalkingLinkInput.value,
        parseProductUrls,
        gamekeys.value,
      );
    });

    const addAnotherProductUrls = () => {
      parseProductUrls.push({ type: 'Webpage', value: '' });
    };
    const changedProductUrl = (value: string, type: string, index: number) => {
      parseProductUrls[index].value = value;
      ctx.emit(
        'countingProgress',
        valueProductNameInput.value,
        valueTalkingLinkInput.value,
        parseProductUrls,
        gamekeys.value,
      );
    };

    return {
      gamekeys,
      parseProductUrls,
      valueProductNameInput,
      valueTalkingLinkInput,
      addAnotherProductUrls,
      changedProductUrl,
    };
  },
};
