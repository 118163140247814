import React, { useEffect, useState } from 'react';
import "./NewContentItems.css";
import 'primeicons/primeicons.css';
import NewContentListItem from "@/react_app/NewLatestItems/NewContentListItem/NewContentListItem";
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { useCampaigns } from "@/modules";

const platformCategories = [
  'YouTube',
]

const INFLUENCERS_PER_PAGE = 12;

export default function (props) {

  const { user, handleRouting } = props;
  const [searchValue, setSearchValue] = useState('');
  const [contentItems, setContentItems] = useState([]);
  const [first, setFirst] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [campaignCategories, setCampaignCategories] = useState([]);
  const [isCampaignCategoryExtended, setIsCampaignCategoryExtended] = useState(false);
  const [time, setTime] = useState("");

  const campaigns = useCampaigns();

  useEffect(() => {
    if (props.contentItems) {
      setContentItems(props.contentItems);
    }
  }, [props.contentItems]);

  useEffect(() => {
    setNoResults(!contentItems.length);
  }, [contentItems]);

  useEffect(() => {
    campaigns.list.loadCampaigns().then((campaigns) => {
      if (campaigns) setCampaignCategories(campaigns);
      else setCampaignCategories([]);
    });
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
  };
  const handleSearch = (e) => {

    clearTimeout(time);
    const search = e.target.value;
    setSearchValue(search);
    if (search) {
      setTime(
        setTimeout(() => {
          setContentItems(props.contentItems?.filter((item) => item.title.toLowerCase().includes(search)));
        }, 1000)
      );
    }else setContentItems(props.contentItems);


  }

  const onCategoryChange = (event) => {
    const { checked, value } = event;
    const { id: valueId } = value;

    const _selectedCategories = checked
      ? [...selectedCampaigns, valueId]
      : selectedCampaigns.filter(category => category !== valueId);

    const filteredContentItems = _selectedCategories.length
      ? props.contentItems?.filter(item => _selectedCategories.includes(item.campaignId?.id))
      : props.contentItems;

    setContentItems(filteredContentItems);
    setSelectedCampaigns(_selectedCategories);
  };


  return (
    <div className={'content-items'}>
      <div className={'titleRow'}>
        <p className={'title'}>Content Items ({contentItems?.length})</p>
      </div>
      <div className={'content'}>
        <div className={'sideMenu'}>
          <div>
            <div className={'sideMenuCategoryLabel'}>
              <i className="pi pi-angle-down" />
              Platform
            </div>
            <div className={'sideMenuGroup'}>
              {platformCategories.map((item, index) =>
                <div key={index} className={'check'}>
                  <Checkbox checked={true} />
                  <span className={'sideMenuLabel'}>{item}</span>
                </div>)}
            </div>
          </div>
          <div>
            <div className={'sideMenuCategoryLabel'} onClick={() => setIsCampaignCategoryExtended(!isCampaignCategoryExtended)}>
              {isCampaignCategoryExtended ?
                <i className='pi pi-angle-down' /> :
                <i className="pi pi-angle-right" />
              }
              Campaign
            </div>
            {isCampaignCategoryExtended &&
              <div className={'sideMenuGroup'}>
                {campaignCategories.map((item, index) =>
                  <div key={index} className={'check'}>
                    <Checkbox inputId={item.id} name="item" value={item} onChange={onCategoryChange}
                      checked={selectedCampaigns.some((selected) => selected === item.id)} />
                    <span className={'sideMenuLabel'}>{item.name}</span>
                  </div>)}
              </div>
            }
          </div>
        </div>
        <div className={'contentItemsResult'}>
          <div className={'searchBar'}>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText placeholder="Search for content items" onChange={(e) => handleSearch(e)} value={searchValue} />
            </span>
            {!noResults &&
              <Paginator first={first} rows={INFLUENCERS_PER_PAGE} totalRecords={contentItems?.length || 0} onPageChange={onPageChange} template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }} />
            }
          </div>
          <div className={'contentItemsGrid'}>
            {contentItems?.slice(first, first + INFLUENCERS_PER_PAGE).map((item, index) =>
              <div key={index} className={'contentItem'}>
                <NewContentListItem item={item} creator={user} handleRouting={() => handleRouting(item.id)} />
              </div>)}
            {noResults &&
              <p className={'noResult'}>There are no content items matching the current search within your campaigns.</p>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
