<template>
  <div>
    <Dialog
        :visible="showModal"
        class="p-col-12 p-md-6 p-xl-4 gi-dialog"
        :modal="true"
        :style="{ width: '1000px' , height : '700px'} "
        @update:visible="$emit('update:showModal', false)"
    >
      <template #header>
        <section class="p-d-flex p-flex-column p-m-auto">
          <div class="p-mt-6">
            <h1>Upload Content</h1>
            <div class="page-image">
              <img src="../campaigns/assets/images/upload_content_second.svg">
            </div>
          </div>
        </section>
      </template>
      <div style="margin-left: 150px" class="p-mt-6">
        <h4>Content Link</h4>
        <InputText style="width:  600px;" v-model="link" placeholder=" Add Link here"></InputText>
        <p class="channel-error" v-if="errorChannel">The video you are trying to download was uploaded by a YouTube
          channel that is not added to your profile.
          Please add your YouTube channel to your profile first.
          <router-link :to="{ name: 'creator', params: { creatorID: user?.id } }">Go to profile -></router-link>
        </p>
      </div>
      <button class="continue-button p-mt-6" @click="send"> Upload Content</button>
      <div class="footer p-mt-6 p-pb-6">By submitting your content to Game Influencer, you acknowledge that you agree
        to Game Influecner's Terms And Conditions. Please be sure not to violate others
        Copyright or Privacy rights. Learn More
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { ref } from 'vue';
import { useAuth } from '@/modules';

export default {
  components: {
    Dialog,
    InputText,
  },
  props: {
    showModal: Boolean,
    errorChannel: {
      type: Boolean,
      default: false,
    },
  },
  emits: [ 'showModal', 'save' ],
  setup(props, { emit }) {
    const { user } = useAuth();
    const showApply = ref(false);
    const showTerms = ref(false);
    const link = ref('');
    const send = () => {
      emit('save', link);
    };
    return {
      showApply,
      link,
      send,
      showTerms,
      user,
    };
  },
};
</script>
<style scoped lang="scss">
span {
  text-decoration: underline;
  font-weight: 600;
}

.tittle-input {
  background: #FFFFFF;
  box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  height: 34px;
  width: 600px;
}

.footer {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #8B8F8F;
  width: 726px;
  margin-left: 100px;
}

.continue-button {
  border: none;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  width: 198px;
  height: 40.12px;
  background: #E0010B;
  box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  margin-left: 350px;
}

.channel-error {
  max-width: 75%;
  text-align: center;
  color: red;
  font-weight: 700;
}
</style>
