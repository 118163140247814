
import { defineComponent, ref } from 'vue';
import { useRoute } from "vue-router";
import { formatDate } from '../../../utils/globals/date-utils';
import { calculateCpiRate } from '../../../utils/globals/cpi-rate';
import { useContentItems } from '@/modules';
import { CampaignPaymentMethod } from '@/data/types';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

export default defineComponent({
  components: {
    Column,
    DataTable,
  },
  setup() {
    const route = useRoute();
    const contentModule = useContentItems();
    const contentId = ref(route.params.content);
    const selectContent = ref();

    const copyLink = (url: any) => {
      navigator.clipboard.writeText(url);
    };
    const reason = ref()
    if (contentId.value) {
      contentModule.manager.getVideoReason(contentId.value as string).then((d) => {
        reason.value = d.contentItem?.reasonReject || d.contentItem?.approveReason;
      });
    }

    return {
      formatDate,
      calculateCpiRate,
      copyLink,
      selectContent,
      reasonData: contentModule.manager.getVideoReasonResult,
      CampaignPaymentMethod,
      reason,
    };
  },
})
