
import { reactive, ref, watch } from 'vue';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import { useAuth, useNotificationsMethods } from '@/modules';
// import { onClickOutside } from '@vueuse/core';
import { useSocketIo, useSocketMethods } from '@/services/socket';
import { timeAgo } from '../../utils/globals/date-utils';
import { onClickOutside } from '@vueuse/core';
import { UserType } from '@/data/types';

export default {
  name: 'Notifications',
  props: {
    isBrand: Boolean,
  },
  components: {
    InputText,
    Dropdown,
  },
  setup() {
    const { user } = useAuth();

    const notificationsMethods = useNotificationsMethods();
    const userId = ref(user.value ? user.value.id : null);
    const notificationsFromUser = ref<any[]>([]);
    const countNewNotifications = ref();
    const isGetMore = ref(false);
    const limit = ref<number>(5);
    const skip = ref<number>(0);
    const notificationBlock = ref();
    const searchValue = ref('');
    const selectMostRecent = ref();
    const rangeDate = reactive([
    {
      value: 'this-week',
      title: 'This Week',
    },
    {
      value: 'this-month',
      title: 'This month',
    },
    {
      value: 'this-year',
      title: 'This year',
    }, {
      value: 'earlier',
      title: 'Earlier',
    },
    ]);
    interface Notification {
      type: keyof typeof notificationsMethods.methods;
      // other properties of Notification can go here
    }

    const socket = useSocketIo(userId.value as string);
    const socketMethods = useSocketMethods(socket);
    socketMethods.getNewNotificationsCount().then((newCount) => {
      watch(newCount, () => {
        countNewNotifications.value = newCount.value;
      });
    });

    const getMoreNotifications = async (firstLoad = false) => {
      if (!firstLoad) {
        // skip.value += 5;
        limit.value += 5;
      }
      socketMethods.getNotificationsUser(limit.value, skip.value);
      socket.on('notifications-data', (data: any) => {
        notificationsFromUser.value = data;
      });
    };

    socket.on('add-new-notifications', (newNotify: any) => {
      if (!notificationsFromUser.value || !notificationsFromUser.value.length) notificationsFromUser.value = [];
      notificationsFromUser.value.unshift(newNotify);
      countNewNotifications.value += 1;
    });
    socket.on('delited-notification', (data) => {
      const index = notificationsFromUser.value.findIndex((el: any) => el?.id === data?.id);
      notificationsFromUser.value.splice(index, 1);
      countNewNotifications.value -= 1;
    });

    const windowOnscroll = () => {
      const scrollY = notificationBlock.value.scrollHeight - notificationBlock.value.scrollTop;
      const height = notificationBlock.value.offsetHeight;
      const offset = height - scrollY;
      if (offset === -1 || offset === 0 || offset === 1) {
        getMoreNotifications();
        // load more content here
      }
    };

    socketMethods.howManyNewOnes();
    socketMethods.getNewNotificationsCount().then((newCount) => {
      countNewNotifications.value = newCount;
    });

    const changeStatusNotify = (notify: any, index: number) => {
      notificationsFromUser.value[index].status = 'read';
      socketMethods.changeNotifyStatusRead(notify?.id as string);
      countNewNotifications.value -= 1;
    };

    const showNotificationsItems = ref(false);

    watch(showNotificationsItems, () => {
      if (showNotificationsItems.value) {
        limit.value = 5;
        getMoreNotifications(true);
      } else {
        notificationsFromUser.value = [];
        skip.value = 0;
        isGetMore.value = false;
      }
    });

    const target = ref(null);
    onClickOutside(target, (event) => {
      showNotificationsItems.value = false;
    });

    const debounce = (func: { (): void; apply?: any }, timeout = 500) => {
      let timer: NodeJS.Timeout;
      return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    };
    const searchNotifications = debounce(() => {
      socketMethods.searchNotifications(searchValue.value);
    });

    const changeFilter = (v: { value: string }) => {
      socketMethods.searchNotifications(searchValue.value, v.value);
    };

    const handleAction = (notify: Notification) => {
      
      const { type } = notify;
      const perspective = user.value?.type as UserType;
      showNotificationsItems.value = false;
      notificationsMethods.methods[type](notify, perspective);
    };

    const isNewDesignEnabled = localStorage.getItem('new-design-toggle') === 'activated';

    return {
      selectMostRecent,
      rangeDate,
      target,
      windowOnscroll,
      changeStatusNotify,
      changeFilter,
      showNotificationsItems,
      timeAgo,
      notificationsFromUser,
      countNewNotifications,
      socketMethods,
      getMoreNotifications,
      notificationsMethods,
      isGetMore,
      limit,
      skip,
      userId,
      notificationBlock,
      searchValue,
      searchNotifications,
      perspective: user.value?.type,
      isNewDesignEnabled,
      handleAction
    };
  },
};
