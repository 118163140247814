import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createBlock(_component_InputText, {
    class: "default-input w-100",
    type: "text",
    modelValue: $setup.valueTalkingPointInput,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.valueTalkingPointInput) = $event)),
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('changeTalking', $props.indexInput, $setup.valueTalkingPointInput))),
    placeholder: "Enter your desired talking point"
  }, null, 8, ["modelValue"]))
}