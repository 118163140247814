
import { ref, watch } from 'vue';
import { currency } from '@/data/static/currency';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import AskHint from '@/pages/creators/utils/AskHint.vue';
import { CampaignPaymentMethod } from '@/data/types';
import { calculateMinimalBudgetCounter } from '@/utils/campaign-utils';
import SecretTip from './SecretTip.vue';

export default {
  name: 'createCampaignBudgetSteep',
  components: {
    InputNumber,
    Checkbox,
    SecretTip,
    AskHint,
  },
  props: {
    campaign: Object,
    paymentMethod: String,
  },
  setup(props: any, ctx: any) {
    const totalCampaignBudget = ref(props.campaign?.budget.amount || 0);
    const verifyPaymentInfo = ref(true);
    const selectedCurrency = ref(props.campaign?.budget?.currency || 'USD');
    const selectedCurrencyCpi = ref(props.campaign?.budget?.currency || 'USD');
    const minAndroid = ref(0);
    const cpiAndroid = ref(props.campaign?.cpi?.android?.amount || 4.5);
    const cpiIos = ref(props.campaign?.cpi?.ios?.amount || 4.5);
    const minIos = ref(0);
    const cpcValue = ref(props.campaign?.cpc?.amount || 0.1);
    const cpmValue = ref(props.campaign?.cpm?.amount || 0.1);
    const cpmPayoutCap = ref(props.campaign?.cpm?.payoutCap || 1);
    const isMinimalBudget = ref(false);

    const emitNewValue = () => {
      ctx.emit(
        'budgetSteep',
        totalCampaignBudget.value,
        verifyPaymentInfo.value,
        selectedCurrency.value,
        cpiIos.value,
        cpiAndroid.value,
        props.paymentMethod,
        cpcValue.value,
        cpmValue.value,
        cpmPayoutCap.value,
        isMinimalBudget.value,
      );
    };

    watch([totalCampaignBudget, cpiIos, minIos, cpcValue], () => {
      localStorage.setItem('totalCampaignBudget', totalCampaignBudget.value.toString());
      localStorage.setItem('cpiIos', cpiIos.value.toString());
      localStorage.setItem('minIos', minIos.value.toString());
      localStorage.setItem('selectedPaymentMethod', props.paymentMethod.toString());
      localStorage.setItem('cpcValue', cpcValue.value?.toString());
      if (props.paymentMethod === CampaignPaymentMethod.CPM) {
        isMinimalBudget.value = calculateMinimalBudgetCounter(totalCampaignBudget.value, props.paymentMethod, {
          amount: cpmValue.value,
        });
      }
      if (props.paymentMethod === CampaignPaymentMethod.CPC) {
        isMinimalBudget.value = calculateMinimalBudgetCounter(totalCampaignBudget.value, props.paymentMethod, {
          amount: cpcValue.value,
        });
      }
      if (props.paymentMethod === CampaignPaymentMethod.CPI) {
        isMinimalBudget.value = calculateMinimalBudgetCounter(totalCampaignBudget.value, props.paymentMethod, {
          android: { amount: cpiAndroid.value },
          ios: { amount: cpiIos.value },
        });
      }
      emitNewValue();
    });
    if (!props.campaign) {
      emitNewValue();
    }
    return {
      currency,
      totalCampaignBudget,
      verifyPaymentInfo,
      selectedCurrency,
      cpiAndroid,
      minAndroid,
      cpiIos,
      minIos,
      selectedCurrencyCpi,
      cpcValue,
      cpmValue,
      cpmPayoutCap,
      emitNewValue,
      isMinimalBudget,
      CampaignPaymentMethod,
    };
  },
};
