<template>
  <NewPrivacyPolicy v-if="isNewLegalInfoEnabled" :handleRouting="handleRouting"/>
  <OldPrivacyPolicy v-else/>
</template>

<script>
import OldPrivacyPolicy from "@/pages/auth/OldPrivacyPolicy.vue";
import NewPrivacyPolicy from "@/react_app/NewLegalInfo/NewPrivacyPolicy/NewPrivacyPolicy";
import { applyPureReactInVue } from 'veaury'
import { isNewLegalInfoEnabled } from '@/utils/globals/feature-flags';

export default {
  components: {
    NewPrivacyPolicy: applyPureReactInVue(NewPrivacyPolicy),
    OldPrivacyPolicy
  },
  setup() {
    const handleRouting = (name) => {
      window.location.href = `/${name}`;
    };

    return {
      isNewLegalInfoEnabled,
      handleRouting
    };
  },
};
</script>
