<template>
  <div class="empty">
    <h2>Please come back soon!</h2>
  </div>
</template>

<script>
export default {
  name: 'Empty',
};
</script>
