import { useAPI } from '@/modules';
import { CampaignData, AppPagePerspective } from '@/data/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useCampaignDeliverables = (perspective: AppPagePerspective) => {
  // add new deliverable
  const {
    loading: submitting,
    data: submittingSuccess,
    error: submittingError,
    execute: sendSingleContractRequest,
  } = useAPI<Array<CampaignData>>('', false);

  const submit = (
    campaignID: string,
    contractID: string,
    payload: {
      link: string;
      contentItemId?: string;
      type?: string;
      comment?: string;
      deliverableID?: string;
      index?: number;
    },
  ) => sendSingleContractRequest({
    url: `/campaigns/contracts/deliverables/${campaignID}/${contractID}`,
    method: 'POST',
    data: payload,
  });

  // accept deliverabl
  const {
    loading: accepting,
    data: acceptingSuccess,
    error: acceptingError,
    execute: sendAcceptRequest,
  } = useAPI('', false);

  const accept = (
    campaignID: string,
    contractID: string,
    deliverableID: string,
    reason?: string,
  ) => sendAcceptRequest({
    url: `/campaigns/contracts/deliverables/${campaignID}/${contractID}/${deliverableID}`,
    method: 'PUT',
    data: { action: 'accept', reason },
  });

  // reject deliverable
  const {
    loading: rejecting,
    data: rejectingSuccess,
    error: rejectingError,
    execute: sendRejectRequest,
  } = useAPI('', false);

  const reject = (
    campaignID: string,
    contractID: string,
    deliverableID: string,
    reason?: string,
  ) => sendRejectRequest({
    url: `/campaigns/contracts/deliverables/${campaignID}/${contractID}/${deliverableID}`,
    method: 'PUT',
    data: { action: 'reject', reason },
  });

  // edit link deliverable
  const {
    loading: editLinkLoading,
    data: editLinkSuccess,
    error: editLinkError,
    execute: editLinkRequest,
  } = useAPI('', false);

  const editLink = (
    campaignID: string,
    contractID: string,
    deliverableID: string,
    linkID: string,
    newLink: string,
    newContentItemId: string,
  ) => editLinkRequest({
    url: `/campaigns/contracts/deliverables/${campaignID}/${contractID}/${deliverableID}/${linkID}`,
    method: 'PUT',
    data: { newLink, newContentItemId },
  });

  return {
    // edit link
    editLink,
    editLinkError,
    editLinkSuccess,
    editLinkLoading,
    // add new deliverable
    submit,
    submitting,
    submittingSuccess,
    submittingError,
    // accepting
    accept,
    accepting,
    acceptingSuccess,
    acceptingError,
    // rejecting
    reject,
    rejecting,
    rejectingSuccess,
    rejectingError,
  };
};
