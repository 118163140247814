
import { defineComponent, PropType } from 'vue';
import { CampaignData } from '@/data/types';
import CampaignPhoto from '../view/CampaignPhoto.vue';

export default defineComponent({
  components: { CampaignPhoto },

  props: {
    campaign: Object as PropType<CampaignData>,
  },
});
