<template>
  <div id="testimonials" ref="testimonials" class="testimonials">
    <div style="margin: 0 auto; max-width: 1600px">
      <div class="p-d-flex p-justify-between influencer-say-row">
        <motion.div  
         v-motion
        :initial="{ opacity: 0, x: -50 }"
        :enter="{ opacity: 1, x: 0 ,y: 0}"
        :variants="{ custom: { scale: 2 } }"
        :delay="500"
        :duration="1200" >
          <div class="testimonials-title">
            {{ reviewList[currentReview].title }}
          </div>
          <div class="p-d-flex">
            <span class="material-icons quote">format_quote</span>
            <div class="p-ml-2">
              <div class="p-d-flex">
                <div class="influencer-name">
                  {{ reviewList[currentReview].name }}
                </div>
                <div class="star-margin-left">
                  <span
                    v-for="index in reviewList[currentReview].star"
                    :key="index"
                    class="material-icons star"
                  >
                    star
                  </span>
                </div>
              </div>
              <div class="yt-influencers">
                {{ reviewList[currentReview].designation }}
              </div>
            </div>
          </div>
          <div class="influencer-text">
            {{ reviewList[currentReview].description }}
          </div>
          <button class="join-btn" @click="$router.push({ name: 'login' })">JOIN NOW</button>
        </motion.div>
        <div class="testimonials-adjust">
          <div
            v-for="(image, index) in allImage"
            :key="index"
            @click="handleChangeReview(index)"
          >
            <img
              :src="require(`../../assets/landing/${image}`)"
              class="testimonials-image"
              alt="influencer avatar"
              draggable="false"
              :style="{
                filter: `grayscale(${index === currentReview ? 0 : 1})`,
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { motion } from '@vueuse/motion'; // Import motion

export default defineComponent({
  setup() {
    const router = useRouter();
    const reviewList = ref([
      {
        id: 1,
        title: 'What Do Brands And Influencers Say?',
        name: 'Anime Balls Deep',
        designation: 'YouTube Influencers',
        description:
          "We're always looking for new ways to monetize our content, and G.IO made it incredibly easy to do just that. It's great!",
        star: 5,
      },
      {
        id: 2,
        title: 'What Do Brands And Influencers Say?',
        name: 'LiamDoesGame',
        designation: 'YouTube Influencers',
        start: 5,
        description: `I'm always checking the website for any new campaigns since it makes me super excited to work with brands and different games.

          I would definitely recommend G.IO to other influencers because it can help influencers financially and open the doors to new experiences.       `,
      },
      {
        id: 3,
        title: 'What Do Brands And Influencers Say?',
        name: 'JVDB studios',
        designation: 'Talent Agency',
        start: 5,
        description: `Working with G.IO has been a positive experience for us. We have partnered on multiple campaigns and have consistently found the briefings to be clear, yet allowing room for creativity.
                  The support team is great, providing quick turnaround times that result in no conflict with our uploading schedule.`,
      },
      {
        id: 4,
        title: 'What Do Brands And Influencers Say?',
        name: 'PONOS',
        designation: 'Video Game Company',
        start: 5,
        description: `Results have been great!`,
      },
      {
        id: 5,
        title: 'What Do Brands And Influencers Say?',
        name: 'AAX',
        designation: 'Crypto Exchange Platform',
        start: 5,
        description: 'Very professional team and great communication.',
      },
      {
        id: 6,
        title: 'What Do Brands And Influencers Say?',
        name: 'JVDB studios',
        designation: 'Talent Agency',
        start: 5,
        description: `Working with G.IO has been a positive experience for us. We have partnered on multiple campaigns and have consistently found the briefings to be clear, yet allowing room for creativity.
          The support team is great, providing quick turnaround times that result in no conflict with our uploading schedule.`,
      },
    ]);
    const allImage = ref([
      'Influencer-image-1.png',
      'Influencer-image-2.png',
      'Influencer-image-3.png',
      'Influencer-image-4.png',
      'Influencer-image-5.png',
      'Influencer-image-6.png',
    ]);
    const currentReview = ref(0);

    const handleLoginClick = () => {
      // localStorage.setItem('login-view-Type', 'creator');
      router.push({ name: 'login' }).catch((err) => {
        console.error('Failed to navigate:', err);
      });
    };

    const handleChangeReview = (index) => {
      if (index != '5') currentReview.value = index;
    };
    return {
      reviewList,
      currentReview,
      allImage,
      handleChangeReview,
      handleLoginClick,
      motion
    };
  },
});
</script>

<style scoped lang="scss">
.testimonials {
  height: auto;
  background-color: #130a23;
  padding: 120px 60px;

  @media screen and (max-width: 1024px) {
    padding: 80px 30px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }

  @media screen and (max-width: 578px) {
    padding: 20px 15px;
  }
}

.testimonials-title {
  font-size: 36px;
  line-height: 44px;
  color: #ffffff;
  padding-bottom: 5%;
  font-weight: 700;

  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 32px;
    padding-bottom: 10%;
  }

  @media screen and (max-width: 578px) {
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 8%;
  }
}

.testimonials-adjust {
  display: flex;
  flex-wrap: wrap;
  max-width: 486px;
  gap: 35px 70px;
  align-self: end;

  @media screen and (max-width: 1024px) {
    gap: 20px 40px;
    max-width: 360px;
  }

  @media screen and (max-width: 768px) {
    gap: 15px 30px;
    max-width: 300px;
  }

  @media screen and (max-width: 578px) {
    gap: 10px 20px;
    max-width: 240px;
  }
}

.testimonials-image {
  max-height: 115px;
  max-width: 115px;
  width: 100%;
  height: 100%;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    max-height: 80px;
    max-width: 80px;
  }

  @media screen and (max-width: 578px) {
    max-height: 60px;
    max-width: 60px;
  }
}

.influencer-name {
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 25px;
  }

  @media screen and (max-width: 578px) {
    font-size: 18px;
    line-height: 22px;
  }
}

.quote {
  color: #ffffff;
  background-color: #e6343c;
  border-radius: 45px;
  font-size: 44px;
  width: 45px;
  height: 45px;

  @media screen and (max-width: 768px) {
    font-size: 36px;
    width: 36px;
    height: 36px;
  }

  @media screen and (max-width: 578px) {
    font-size: 30px;
    width: 30px;
    height: 30px;
  }
}

.star {
  color: #ffffff;
  font-size: 26px;
  margin-left: 7px;
  margin-top: -5px;

  @media screen and (max-width: 768px) {
    font-size: 22px;
    margin-left: 5px;
  }

  @media screen and (max-width: 578px) {
    font-size: 18px;
    margin-left: 3px;
  }
}

.star-margin-left {
  padding-left: 42px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding-left: 30px;
  }

  @media screen and (max-width: 578px) {
    padding-left: 20px;
    flex-wrap: wrap;
  }
}

.influencer-text {
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  line-height: 19px;
  min-height: 130px;
  width: 500px;
  padding-top: 5%;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
    width: 90%;
    padding-top: 3%;
  }

  @media screen and (max-width: 578px) {
    font-size: 12px;
    width: 100%;
    padding-top: 2%;
  }
}

.yt-influencers {
  color: white;
}

.join-btn {
  margin-top: 70px;

  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 578px) {
    margin-top: 10px;
  }
}

.box-start {
  height: 120px;

  @media screen and (max-width: 768px) {
    height: 100px;
  }

  @media screen and (max-width: 578px) {
    height: 80px;
  }
}
</style>
