
import { ref, computed } from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import { useCampaigns } from '@/modules';
import { countries } from '@/data/static/countries';
import { useRoute } from 'vue-router';

export default {
  name: 'BankPayment',
  components: {
    InputText,
    Button,
    Dropdown,
  },
  props: {
    campaignId: String,
    newBudgetData: Object,
    createFileCsv: Boolean,
    firstPayCampaign: {
      type: Boolean,
      default: false,
    },
    termsAgree: Boolean,
    influencerId: String,
  },
  setup(props: any, { emit }: any) {
    const route = useRoute();
    const iban = ref(''); // FR7630004000031234567890143
    const holder = ref('');
    const city = ref('');
    const fullAddress = ref('');
    const zip = ref('');
    const country = ref();
    const validateIban = computed(() => /^\w{2}\d{16,34}$/.test(iban.value));
    const valHolder = computed(() => holder.value.length > 2);
    const valCity = computed(() => /^\w{3,}$/.test(city.value));
    const valAddr = computed(() => /^.{6,}$/.test(fullAddress.value));
    const valZip = computed(() => /^\d{5,6}$/.test(zip.value));
    const validationFields = computed(() => validateIban.value && country.value && valHolder.value
        && valCity.value && valAddr.value && valZip.value);
    const campaignModule = useCampaigns();

    // eslint-disable-next-line consistent-return
    const createCardBankPayIn = async () => {
      if (props.createFileCsv) {
        emit('bankPaySuccess');
        await campaignModule.manager.changeBudgetWithCsv(route.params.campaignID as string, {
          newBudget: props.newBudgetData.budget,
          city: city.value,
          accountHolder: holder.value,
          country: country.value.code,
          zipCode: zip.value,
          iban: iban.value,
          cpi: props.newBudgetData.cpi,
          fullAddress: fullAddress.value,
        });
        return;
      }
      emit('bankPaySuccess');
    };

    return {
      iban,
      city,
      holder,
      fullAddress,
      country,
      validateIban,
      valAddr,
      valHolder,
      valZip,
      valCity,
      zip,
      countries,
      validationFields,
      createCardBankPayIn,
    };
  },
};
