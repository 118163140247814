import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewCampaignsManagement = _resolveComponent("NewCampaignsManagement")!
  const _component_OldCampaignsManagement = _resolveComponent("OldCampaignsManagement")!

  return (_ctx.isNewCampaignsManagementEnabled)
    ? (_openBlock(), _createBlock(_component_NewCampaignsManagement, {
        key: 0,
        counters: _ctx.counters,
        handleRoute: _ctx.handleRoute,
        campaigns: _ctx.campaigns,
        changeStatus: _ctx.handleChangeStatus,
        searchCampaigns: _ctx.handleSearch,
        goToCampaign: _ctx.goToCampaign,
        totalRecords: _ctx.totalRecords,
        limit: _ctx.limit,
        pageChange: _ctx.handlePageChange,
        isLoading: _ctx.isLoading,
        pushDraft: _ctx.pushDraft
      }, null, 8, ["counters", "handleRoute", "campaigns", "changeStatus", "searchCampaigns", "goToCampaign", "totalRecords", "limit", "pageChange", "isLoading", "pushDraft"]))
    : (_openBlock(), _createBlock(_component_OldCampaignsManagement, {
        key: 1,
        perspective: _ctx.viewPerspective,
        brandOwnerView: _ctx.brandOwnerView,
        private: _ctx.private
      }, null, 8, ["perspective", "brandOwnerView", "private"]))
}