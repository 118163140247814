
import {computed, defineComponent, ref, watch} from 'vue';
import {
  useAgencys,
  useAuth,
  useCampaigns,
  useContentItems,
  useCreators,
  useFavorites,
  useToastModule,
} from '@/modules';
import {
  AppPageConfig,
  AppPageProps,
  CampaignData,
  CampaignInvitationData,
  CampaignStatus,
  EditUserPayload,
  Perspective,
  User,
} from '@/data/types';
import {useRouter} from 'vue-router';
import {applyPureReactInVue} from 'veaury';
import {isInfluencerProfileEnabled} from '@/utils/globals/feature-flags';
import NewCreatorProfile from "@/react_app/NewCreatorProfile/NewCreatorProfile";
import OldCreator from "@/pages/creators/profile/OldCreator.vue";

export default defineComponent({
  components: {
    NewCreatorProfile: applyPureReactInVue(NewCreatorProfile),
    OldCreator
  },
  props: {
    creatorID: {
      type: String,
    },
    ...AppPageProps,
  },
  setup(props) {
    const { user } = useAuth();
    const content = useContentItems();
    const agencyModule = useAgencys();
    const creatorId = computed(() => props.creatorID || '');
    content.list.loadMyLatest(props.creatorID as string, 8);
    const config = ref<AppPageConfig>({
      title: 'Loading creator',
      ...props,
    });

    const router = useRouter();
    const showModalAddCreator = ref(); // add creators to agency
    const changePaymentInfo = ref(false);
    const showInviteModal = ref(false);

    const controller = useCreators(config.value.viewPerspective);
    const loadCreator = () => controller.manager.load(creatorId.value);
    const loadChannels = async () => controller.youtube.getCreatorChannels(creatorId.value, controller.manager.creator.value?.type);
    loadCreator();

    watch(controller.manager.creator, () => {
      if (controller.manager.creator.value?.name) {
        config.value.title = controller.manager.creator.value?.name;
        loadChannels();
      }
    });

    const successAddChannel = async () => {
      await loadChannels();
    };
    const removeChannel = async (id: string) => {
      controller.youtube.getCreatorChannelsResult.value.channel.items = controller.youtube.getCreatorChannelsResult.value?.channel?.items
          .filter((channel: any) => channel?.id !== id);
    }

    // edit
    const showEditModal = ref(false);
    const updateCreator = (payload: EditUserPayload) => {
      controller.manager.putInfluencerInfo(creatorId.value, payload)
        .then((success?: User) => {
          if (success) {
            showEditModal.value = false;
            window.location.reload();
          }
        });
    };
    // < edit

    const campaigns = useCampaigns(config.value.viewPerspective);

    const switchAccount = async () => {
      setTimeout(() => {
        router.go(0);
      }, 1000);
      await agencyModule.manager.switchAccount();
    };
    const forUsername = ref('');
    const searchResult = ref();

    const addCreator = async () => {
      const searchResults = await agencyModule.manager.searchChannels(forUsername.value);
      searchResult.value = searchResults;
    };

    const isChannelAlredyAdded = (userChannels: any, channelId: string) => {
      const isAlredy = userChannels?.findIndex((channel: any) => channel?.id === channelId);
      return isAlredy >= 0;
    };
    const deleteChanel = async (channelId: string, index: number) => {
      await agencyModule.manager.deleteChannel(channelId);
      await loadChannels();
    };

    const selectChannelForAdd = async (channelData: any) => {
      await agencyModule.manager.addCreator(channelData);
      await loadChannels();
    };

    const paymentInfoChange = async (data: any) => {
      changePaymentInfo.value = false;
    };

    const favoritesManager = useFavorites();
    const userInf = useAuth();
    const userFavorites = ref();

    if (userInf.user.value) {
      favoritesManager.getUSerFavorites('influences', userInf.user.value.id).then((favorites) => {
        userFavorites.value = favorites;
      });
    }

    const addToFavorites = async (influencerId: string) => {
      if (userInf.user.value) {
        const item = await favoritesManager.likeOrDislike('influences', userInf.user.value?.id, influencerId);
        if (item.type === 'delete') {
          const index = userFavorites.value.allUserFavorites.findIndex((el: any) => el.influences?.id === influencerId);
          userFavorites.value.allUserFavorites.splice(index, 1);
        } else if (item.type === 'create') {
          userFavorites.value.allUserFavorites.push({ influences: { id: influencerId }, type: 'influences' });
        }
      }
    };

    const handleContentRouting = (id: string) => {
      router.push({ name: 'content-item', params: { itemID: id } });
    }

    const campaignInviteDropdownOptions = ref();
    const { creator } = controller.manager;
    const toast = useToastModule();

    const updateCampaignsList = () => {
      campaigns.list
        .loadCampaigns(true)
        .then((data?: Array<CampaignData>) => {
          if (!data) {
            return;
          }
          campaignInviteDropdownOptions.value = data
            .filter((campaign) => campaign.status === CampaignStatus.Active)
            .map((campaign) => {
              const result = {
                id: campaign.id,
                name: campaign.name,
                optionDisabled: false,
              };
              const invitation: CampaignInvitationData | undefined = (campaign.invitations || [])
                .find((i: CampaignInvitationData) => i.creator?.id === creator.value?.id);
              if (invitation) {
                result.name = `${result.name} (already invited)`;
                result.optionDisabled = true;
              }
              return result;
            });
        });
    };
    if (user?.value?.isBrandOwner()) {
      updateCampaignsList();
    }

    watch(campaigns.invitations.inviteSuccess, () => {
      if (campaigns.invitations.inviteSuccess.value) {
        toast.showSuccess(`User invited successfully!`);
        updateCampaignsList();
      }
    });
    watch(campaigns.invitations.inviteError, toast.handleRequestError);

    return {
      isChannelAlredyAdded,
      deleteChanel,
      paymentInfoChange,
      selectChannelForAdd,
      updateCreator,
      successAddChannel,
      channels: controller.youtube.getCreatorChannelsResult,
      config,
      controller,
      user,
      forUsername,
      showInviteModal,
      addCreator,
      loading: controller.manager.loading,
      creator: controller.manager.creator,
      switchAccount,
      // edit
      creatorId,
      changePaymentInfo,
      showEditModal,
      showModalAddCreator,
      latestContent: content.list.loadMyLatestResult,
      searchChannelLoading: agencyModule.manager.searchChannelsLoading,
      searchResult,
      isInfluencerProfileEnabled,
      addToFavorites,
      favorites: userFavorites,
      handleContentRouting,
      campaignOptions: campaignInviteDropdownOptions,
      isCreator: config.value.viewPerspective === Perspective.CREATOR || config.value.viewPerspective === Perspective.AGENCY,
    };
  },
});
