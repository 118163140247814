
import { defineComponent, PropType } from 'vue';
import Button from 'primevue/button';
import { useConfirm } from 'primevue/useconfirm';
import { CampaignData, CampaignInvitationData, Perspective, CampaignPaymentMethod } from '@/data/types';
import UserShortPreview from '@/pages/commons/user/UserShortPreview.vue';
import StatusChip from '@/pages/commons/status/StatusChip.vue';
import CampaignShortPreview from '@/pages/campaigns/parts/CampaignShortPreview.vue';
import BrandShortPreview from '@/pages/commons/brand/BrandShortPreview.vue';

export default defineComponent({
  components: {
    BrandShortPreview,
    Button,
    StatusChip,
    UserShortPreview,
    CampaignShortPreview,
  },
  props: {
    campaign: {
      type: Object as PropType<CampaignData>,
    },
    viewPerspective: String as PropType<Perspective>,
  },
  emits: ['delete', 'accept'],
  setup(props, { emit }) {
    const confirm = useConfirm();

    const confirmDeleteInvitation = (campaign: CampaignData, invitation: CampaignInvitationData) => {
      confirm.require({
        message: 'Are you sure you want to drop this invitation? \n' +
            'If you are already in the company, then you will leave it',
        header: 'Drop?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          emit('delete', { campaign, invitation });
        },
      });
    };
    const confirmInvitation = (campaign: CampaignData, invitation: CampaignInvitationData) => {
      confirm.require({
        message: 'Are you sure you want to accept this invitation?',
        header: 'Accept?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          emit('accept', { campaign, invitation });
        },
      });
    };

    return {
      confirmInvitation,
      confirmDeleteInvitation,
      CampaignPaymentMethod,
    };
  },
});
