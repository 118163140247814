
import { defineComponent, reactive, watch, ref } from 'vue';
import { AppPageConfig, AppPageProps, Perspective } from '@/data/types';
import { usePage, useCampaigns, useAuth } from '@/modules';
import AppPage from '@/pages/commons/AppPage.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import EmptyList from '../commons/list/EmptyList.vue';
import CampaignsListCounters from './CampaignsListCounters.vue';
import { compactNumber } from '../../utils/globals/curency-utils';
import { formatDate } from '../../utils/globals/date-utils';
import StatusChip from '../commons/status/StatusChip.vue';
import SearchBar from '../creators/utils/SearchBar.vue';
import router from "@/router";

export default defineComponent({
  components: {
    AppPage,
    CampaignsListCounters,
    EmptyList,
    DataTable,
    Column,
    StatusChip,
    SearchBar,
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 0,
    },
    brandOwnerView: Boolean,
    ...AppPageProps,
    private: Boolean,
    perspective: String,
  },

  setup(props) {
    const { user } = useAuth();
    const { config } = usePage<AppPageConfig>({
      search: {
        enabled: props.showSearch,
        defaultType: 'campaigns',
        placeholder: 'Search for Campaign',
      },
      title: 'Campaign Management',
      ...props,
    });
    const editDate = ref(false);
    const page = useCampaigns(props.perspective === "brand" ? Perspective.BRAND : 
    props.perspective === "admin" ? Perspective.ADMIN 

    : Perspective.CREATOR);
    const loadPageData = () => page.list.loadCampaigns(props.private, props.limit, '');
    const campaigns = ref();
    const filteredCampaigns = ref();
    const isAllSelected = ref();

    loadPageData().then((campaignsAll) => {
      campaigns.value = campaignsAll;
      filteredCampaigns.value = campaignsAll;
      if (campaignsAll) {
        const contracts = campaignsAll.map((m: any) => m.contracts).flat();
        const applications = campaignsAll.map((m: any) => m.applications).flat();
        counters.campaigns = campaignsAll.filter((campaign) => campaign?.status === 'active').length;
        counters.contracts = contracts.length;
        counters.applications = applications.length;
      }
    });
    const counters = reactive({ campaigns: 0, contracts: 0, applications: 0 });
    watch(() => props.private, loadPageData);
    watch(editDate, () => loadPageData);

    const platformsOptions = ref([{
      value: 'Active Campaigns',
      type: 'active',
      selected: false,
    }, {
      value: 'Drafts',
      type: 'draft',
      selected: false,
    }, {
      value: 'Completed',
      type: 'completed',
      selected: false,
    }, {
      value: 'Pending',
      type: 'pending',
      selected: false,
    }]);
    const selectedAll = ref(false);
    const sendName = () => {
      filteredCampaigns.value = campaigns.value;
      const oneSelectedOrMore = platformsOptions.value.find((el) => el.selected);
      if (!oneSelectedOrMore) {
        filteredCampaigns.value = campaigns.value;
        return;
      }
      let allCampaigns: any = [];
      if (platformsOptions.value[0].selected === true) {
        allCampaigns = allCampaigns.concat(campaigns.value.filter((campaign: { status: string }) => campaign?.status === 'active'));
      }
      if (platformsOptions.value[1].selected === true) {
        allCampaigns = allCampaigns.concat(campaigns.value.filter((campaign: { status: string }) => campaign?.status === 'draft'));
      }
      if (platformsOptions.value[2].selected === true) {
        allCampaigns = allCampaigns.concat(campaigns.value.filter((campaign: { status: string }) => campaign?.status === 'completed'));
      }
      if (platformsOptions.value[3].selected === true) {
        allCampaigns = allCampaigns.concat(campaigns.value.filter((campaign: { status: string }) => campaign?.status === 'pending'));
      }
      filteredCampaigns.value = allCampaigns;
    };
    const selectAllPlatforms = () => {
      selectedAll.value = !selectedAll.value;
      if (selectedAll.value) {
        platformsOptions.value.forEach((el) => el.selected = true);
        filteredCampaigns.value = campaigns.value;
      } else {
        platformsOptions.value.forEach((el) => el.selected = false);
        filteredCampaigns.value = campaigns.value;
      }
    };
    const clearFilters = () => {
      platformsOptions.value.forEach((el) => el.selected = false);
      selectedAll.value = false;
    };
    const addPlatform = (element: any) => {
      const select = platformsOptions.value.findIndex((el) => el.value === element.value);
      if (platformsOptions.value[select].selected) {
        platformsOptions.value[select].selected = false;
      } else {
        platformsOptions.value[select].selected = true;
      }
    };
    const searchValueChange = (newValue: string) => {
      const regexp = new RegExp(newValue, 'gi');
      filteredCampaigns.value = campaigns.value.filter((campaign: any) => regexp.test(campaign.name));
    };

    return {
      sendName,
      filteredCampaigns,
      campaigns,
      searchValueChange,
      selectedAll,
      addPlatform,
      clearFilters,
      selectAllPlatforms,
      platformsOptions,
      editDate,
      config,
      loadPageData,
      loading: page.list.loading,
      isAllSelected,
      counters,
      compactNumber,
      formatDate,
      user,
    };
  },
});
