
import { ref } from 'vue';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import { timeAgo, formatDate } from '@/utils/globals/date-utils';
import { useCampaignDeliverables } from '@/modules/campaigns/campaign-deliverables-module';
import { useCampaignContracts } from '@/modules/campaigns/campaign-contracts-module';
import inboxNotifications from './inboxNotifications.vue';

export default {
  name: 'Inbox',
  components: {
    InputText,
    Textarea,
    Button,
    inboxNotifications,
  },
  props: {
    contracts: {
      type: Array,
      default: () => [],
    },
    viewPerspective: String,
  },
  setup(props: any) {
    const newArrayDeliverables: any = [];
    props.contracts.forEach(((campaign: any) => {
      newArrayDeliverables.push(...campaign?.contracts[0]?.deliverables);
    }));
    newArrayDeliverables.sort((a: any, b: any) => b.updatedAt - a.updatedAt);
    props.contracts.reverse();
    // TODO need added first message data
    const activeNav = ref(0);
    const searchInput = ref('');
    const link = ref('');
    const contract = ref();
    const commentTextarea = ref('');
    const activeMessageData = ref();
    const activeMessageIndex = ref(0);
    const activeDeliverableIndex = ref(0);
    const dataActiveSubmission = ref();
    const currentDeliveryAndReworkObject = ref();
    const deliverablesManager = useCampaignDeliverables(props.viewPerspective);
    const contractManager = useCampaignContracts(props.viewPerspective);

    const getActiveMessageData = (contractID: any, indexMsg: number, indexDeliverable: number, campaignID: string) => {
      activeMessageIndex.value = indexMsg;
      activeDeliverableIndex.value = indexDeliverable;
      contractManager.loadSingle(campaignID, contractID?.id as string).then((data) => {
        contract.value = data;
        // eslint-disable-next-line no-underscore-dangle
        currentDeliveryAndReworkObject.value = contract.value?.deliveryAndReworksObjects.filter((element: any) => element._id
            === contract.value?.contracts[0]?.deliverables[activeDeliverableIndex.value]?.deliveryAndReworksID)[0];
      });
    };

    const sendLinkAndComment = async (deliverableID: string) => {
      if (!deliverableID) {
        await deliverablesManager.submit(contract.value?.id, contract.value?.contracts[0]?.id, {
          link: link.value ? link.value : '',
          comment: commentTextarea.value ? commentTextarea.value : '',
        });
      } else {
        await deliverablesManager.submit(contract.value?.id, contract.value?.contracts[0]?.id, {
          link: link.value ? link.value : '',
          comment: commentTextarea.value ? commentTextarea.value : '',
          deliverableID,
        });
      }

      commentTextarea.value = '';
      link.value = '';
    };

    return {
      newArrayDeliverables,
      contract,
      activeNav,
      searchInput,
      link,
      commentTextarea,
      timeAgo,
      formatDate,
      activeMessageData,
      getActiveMessageData,
      sendLinkAndComment,
      activeMessageIndex,
      activeDeliverableIndex,
      dataActiveSubmission,
    };
  },
};
