
import { PropType, defineComponent, ref } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

interface DisplayData {
  htmlElementToUse: 'p' | 'div' | 'DataTable';
  displayMultiple: boolean; // if true iterate through an array to display the specified keys
  content: Array<object>;
  columns?: Array<string>; // if using a datatable specify column names
  closeBtnLabel?: string;
}

type PresetText =
  | 'requestLicense'
  | 'withdrawLicense'
  | 'invalidEmail'
  | 'gigapayReminder'
  | 'cpmApplicationsWaitApproval'
  | 'cpmApplicationApprovedCreator'
  | 'cpmApplicationApprovedAgency'
  | 'cpmApplicationRejectedCreator'
  ;

export default defineComponent({
  components: {
    Column,
    DataTable,
    Dialog,
    Button,
    InputText,
  },
  props: {
    version: String as PropType<PresetText>, // generate paragraph content based on preset versions
    containsInput: Boolean, // adds a textfield
    submitFunction: Function, // execute something on submit
    useComponentVersionFn: {
      type: Boolean,
      default: false,
    }, // if multiple generic prompts are used in the same component specify which instance to be closed for example
    useInputForSubmitFn: Boolean, // use the input from the textfield as an argument to the function call on submit
    displayData: Object as PropType<DisplayData>, // pass data to be displayed in this component and in what html element
    closeBtnLabel: String,
  },
  setup(props) {
    const viewModal = ref(true);
    const inputValue = ref('');
    const submitFunctionOutcome = ref({
      outcomeStatus: 'pending',
      message: null,
    });

    const generateMessage = () => {
      if (props?.version === 'requestLicense') {
        return `Thank you for making an offer to purchase the creators content, a GIO representative will be in touch soon. This
            request
            will expire in 14 days. Another one can be made if there is no response.`;
      } else if (props?.version === 'withdrawLicense') {
        return `The license request is no longer active. You can always submit a new license request for this content.`;
      } else if (props?.version === 'invalidEmail') {
        return `It seems your YouTube login gave 
                us a @pages.plusgoogle.com email address, please input the email address you would like to have 
                linked to payment and platform notifications:`;
      } else if (props?.version === 'gigapayReminder') {
        return `Don't forget to register with our payment processor, Gigapay, to ensure you are eligible for payout!`;
      } else if (props?.version === "cpmApplicationsWaitApproval") {
        return `The brand has been notified of your interest in taking part in this campaign and you will receive an answer soon. Keep an eye on your notifications and your inbox for a response in the coming days.`;
      }  else if (props?.version === "cpmApplicationRejectedCreator") {
        return `Unfortunately, your application for this campaign was rejected and you will not be able to participate.`;
      } else return ``;
    };

    const onSubmit = () => {
      let argument = null;

      if (props?.useInputForSubmitFn) {
        argument = inputValue.value;
      } else if (props?.useComponentVersionFn) {
        argument = props?.version;
      }

      if (props?.submitFunction){
        props.submitFunction(argument);
      }
        
      viewModal.value = false;
    };

    return {
      viewModal,
      onSubmit,
      generateMessage,
      inputValue,
      submitFunctionOutcome,
      display: props?.displayData,
    };
  },
});
