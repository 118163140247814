
import { ref } from 'vue';
import { useAuth, useFavorites } from '@/modules';
import LatestInfluencersItem from './LatestInfluencersItem.vue';

export default {
  name: 'LatestInfluencers',
  components: {
    LatestInfluencersItem,
  },
  props: {
    limit: {
      type: Number,
      default: 4,
    },
    items: {
      type: Array,
    },
  },
  setup() {
    const userFavorites = ref();
    const user = useAuth();
    const favoritesManager = useFavorites();

    if (user.user.value) {
      favoritesManager.getUSerFavorites('influences', user.user.value.id).then((favorites) => {
        userFavorites.value = favorites;
      });
    }
    const isInfluencerFavorite = (item: any) => {
      if (userFavorites.value) {
        const index = userFavorites.value?.allUserFavorites.findIndex((el: any) => el.influences?.id === item.id);
        if (index < 0) {
          return false;
        }
      }
      return true;
    };
    const favoriteChange = (data: any) => {
      if (data.item.type === 'delete') {
        const index = userFavorites.value.allUserFavorites.findIndex((el: any) => el.influences?.id === data.influencerId);
        userFavorites.value.allUserFavorites.splice(index, 1);
      } else if (data.item.type === 'create') {
        userFavorites.value.allUserFavorites.push({ influences: { id: data.influencerId }, type: 'influences' });
      }
    };
    return {
      isInfluencerFavorite,
      favoriteChange,
    };
  },
};
