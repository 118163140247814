import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0062ac40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "brand-photo" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "material-icons default"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.brand?.photo)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.brand.photo,
          alt: "",
          referrerpolicy: "no-referrer",
          class: "real"
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, "business"))
  ]))
}