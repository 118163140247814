<template>
  <NewLegalNotice v-if="isNewLegalInfoEnabled" :handleRouting="handleRouting"/>
  <OldLegalNotice v-else/>
</template>

<script>
import OldLegalNotice from "@/pages/auth/OldLegalNotice.vue";
import NewLegalNotice from "@/react_app/NewLegalInfo/NewLegalNotice/NewLegalNotice"
import { applyPureReactInVue } from 'veaury'
import { isNewLegalInfoEnabled } from '@/utils/globals/feature-flags';

export default {
  components: {
    NewLegalNotice: applyPureReactInVue(NewLegalNotice),
    OldLegalNotice,
  },
  setup() {
    const handleRouting = (name) => {
      window.location.href = `/${name}`;
    };

    return {
      handleRouting,
      isNewLegalInfoEnabled,
    };
  },
};
</script>
