import React, { useEffect, useState } from 'react';
import "./NewCampaigns.css";
import 'primeicons/primeicons.css';
import NewActiveCampaignItem from "@/react_app/NewLatestItems/NewActiveCampaignItem/NewActiveCampaignItem";
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const platformCategories = [
  'YouTube',
]

const regionsCategories = [
  'Global',
  'Europe',
  'Central & Eastern Europe',
  'Asia & CASA'
]

export default function (props) {
  const {
    totalCount, limit, handlePageChange, isLoading, campaigns, manageSearchCampaign
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const [first, setFirst] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [isRegionCategoryExtended, setIsRegionCategoryExtended] = useState(false);
  const [isPlatformCategoryExtended, setIsPlatformCategoryExtended] = useState(true);
  const [time, setTime] = useState("");

  useEffect(() => {
    setNoResults(!(campaigns?.length))
  }, [campaigns]);


  const onPageChange = (event) => {
    setFirst(event.first);
    handlePageChange(event.first)
  };

  const handleSearch = (value) => {
    clearTimeout(time);
    setSearchValue(value);
    setTime(
      setTimeout(() => {
        setFirst(0);
        manageSearchCampaign(value);
      }, 800)
    );
  }

  return (
    <div className={'campaigns'}>
      <div className={'titleRow'}>
        <p className={'title'}>Campaigns {`${!isLoading ? `(${props.campaigns?.length})` : 0}`}</p>
      </div>
      <div className={'content'}>
        <div className={'sideMenu'}>
          <div>
            <div className={'sideMenuCategoryLabel'} onClick={() => setIsPlatformCategoryExtended(!isPlatformCategoryExtended)}>
              {isPlatformCategoryExtended ?
                <i className="pi pi-angle-down" style={{ margin: '0 14px 0 3px' }} /> :
                <i className="pi pi-angle-right" style={{ margin: '0 14px 0 3px' }} />
              }
              Platform
            </div>
            {isPlatformCategoryExtended &&
              <div className={'sideMenuGroup'}>
                {platformCategories.map((item, index) =>
                  <div key={index} className={'check'}>
                    <Checkbox checked={true} />
                    <span className={'sideMenuLabel'}>{item}</span>
                  </div>)}
              </div>
            }
          </div>
          <div>
            <div className={'sideMenuCategoryLabel'} onClick={() => setIsRegionCategoryExtended(!isRegionCategoryExtended)}>
              {isRegionCategoryExtended ?
                <i className="pi pi-angle-down" style={{ margin: '0 14px 0 3px' }} /> :
                <i className="pi pi-angle-right" style={{ margin: '0 14px 0 3px' }} />
              }
              Region
            </div>
            {isRegionCategoryExtended &&
              <div className={'sideMenuGroup'}>
                {regionsCategories.map((item, index) =>
                  <div key={index} className={'check'}>
                    <Checkbox checked={false} />
                    <span className={'sideMenuLabel'}>{item}</span>
                  </div>)}
              </div>
            }
          </div>
        </div>
        <div className={'campaignsResult'}>
          <div className={'searchBar'}>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText placeholder="Search for campaigns" onChange={(e) => handleSearch(e.target.value)} value={searchValue} />
            </span>
            {!noResults &&
              <Paginator first={first} rows={limit} totalRecords={totalCount} onPageChange={onPageChange} template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }} />
            }
          </div>
          {isLoading ?
            <div className={'campaignsGrid'}>
              {[0, 1, 2, 3, 4, 5, 6, 7].map((campaign, index) =>
                <div key={index} className={'campaignItem'}>
                  <Skeleton count={1} />
                </div>)}
            </div>
            :
            <div>
              <div className={'campaignsGrid'}>
                {campaigns?.map((campaign, index) =>
                  <div key={index} className={'campaignItem'}>
                    <NewActiveCampaignItem campaign={campaign} goToCampaign={props.goToCampaign} />
                  </div>)}
                {noResults &&
                  <p className={'noResult'}>There are no campaigns matching the current search.</p>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
