import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "campaign-list-counter" }
const _hoisted_2 = { class: "counter" }
const _hoisted_3 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.counter), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1)
  ]))
}