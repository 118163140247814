<template>
  <div>
    <Dialog
      :visible="show"
      class="p-col-12 p-md-6 p-xl-4 gi-dialog"
      :modal="true"
      :style="{ width: '550px' , height : '350px'} "
      @update:visible="$emit('update:show', false)"
    >
      <template #header>
        <section class="p-d-flex p-flex-column">
          <div>
            <h1 class="warning-text">Warning</h1>
            <div class="apc">Are you sure you want to close the campaign ?</div>
           <div class="p-d-flex p-jc-between buttons">
             <button @click="$emit('update:show', false)" class="edit-button">CANCEL</button>
            <button class="continue-button" @click="showEndModal= true" >OK</button>
             <SuccessClosingModal
               campaign-status="CAMPAIGN ENDED" status-message="ended successfully"
               first-message="We’ll notify the influencers who worked on the campaign and send them a message."
               @close-modal-logo="closeModalLogo"
               v-model:is-showing="showEndModal">
             </SuccessClosingModal>
           </div>
          </div>
        </section>
      </template>
    </Dialog>
  </div>
</template>

<script>

import Dialog from 'primevue/dialog';
import { ref } from 'vue';
import SuccessClosingModal from '@/pages/campaigns/SuccessClosingModal.vue';

export default {
  components: {
    Dialog,
    SuccessClosingModal,
  },
  props: {
    show: Boolean,
  },
  setup(props, ctx) {
    const showEndModal = ref(false);
    const closeModalLogo = () => {
      ctx.emit('update:show', false);
      ctx.emit('campaignClosed');
      showEndModal.value = false;
    };
    return { showEndModal, closeModalLogo };
  },
};
</script>
<style scoped lang="scss">

.buttons{
  margin-top: 120px;
  margin-bottom: 15px;
}
.apc {
  font-weight: 700 ;
  font-size: 16px;
  line-height: 20px;
  color: #253340;
  text-align: center;
  margin-left: 80px;
  margin-bottom: 50px;
}

.warning-text{
  text-align: center;
  margin-bottom: 70px;
}

.cpi-text {
  color: #253340;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.continue-button {
  border: none;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  width: 198px;
  height: 40.12px;
  background: #E0010B;
  box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  margin-left: 30%;
}
.edit-button {
  margin-left: 10%;
  color: #162020;
  background-color: #FFFFFF;
  border: none;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight ) {
  background-color: #E0010B;
  color:#ffff;
}

</style>
