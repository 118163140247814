
import { ref, watch } from 'vue';
import OldCropperImage from "@/pages/campaigns/view/OldCropperImage.vue";
import NewImageCropper from "@/react_app/Other/NewImageCropper/NewImageCropper.jsx";
import { applyPureReactInVue } from 'veaury';
import { isNewBrandEnabled } from "@/utils/globals/feature-flags";

export default {
  props: {
    image: {
      type: String,
      default: '/images/defaultLogoCampaign.png',
    },
    showCrop: {
      type: Boolean,
      default: false,
    },
    userView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NewImageCropper: applyPureReactInVue(NewImageCropper),
    OldCropperImage
  },
  setup(props: any, ctx: any) {
    const show = ref(false);
    const dataURL = ref();
    const color = ref('black');

    watch(() => props.showCrop, () => {
      show.value = props.showCrop;
    })

    const cropMainImage = (e: { canvas: any }) => {
      dataURL.value = e.canvas.toDataURL('image/png');
    };

    const saveChanges = () => {
      ctx.emit('change', dataURL.value);
    }

    return {
      show,
      dataURL,
      color,
      saveChanges,
      cropMainImage,
      isNewBrandEnabled
    };
  },
};
