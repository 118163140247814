import { useAPI, useToastModule } from '@/modules';
import { AppPagePerspective } from '@/data/types';
import { watch } from 'vue';
import { ITransactionItem } from '@/data/types/payment-types';

export const useTransfersList = (perspective: AppPagePerspective) => {
  const toast = useToastModule();

  // < get transfers by campaign
  const {
    loading: getCampaignTransfersProgress,
    error: getCampaignTransfersError,
    data: getCampaignTransfersResult,
    execute: getCampaignTransfersRequest,
  } = useAPI<Array<ITransactionItem>>('', false);

  const getCampaignTransfers = (campaignID: string, range?: Array<string>) =>
    getCampaignTransfersRequest({
      url: `/transfers/campaign/${campaignID}`,
      params: { range },
      method: 'GET',
    });
  watch(getCampaignTransfersError, toast.handleRequestError);
  // < get transfers by campaign

  const {
    data: getCreatorTransfersData,
    error: getCreatorTransfersError,
    execute: getCreatorTransfersRequest,
  } = useAPI<Array<ITransactionItem>>('', {}, false);

  const getCreatorTransfers = (
    creatorID: string,
    limit: number,
    skip: number
  ) =>
    getCreatorTransfersRequest({
      url: `/transfers/${creatorID}`,
      params: { limit, skip },
      method: 'GET',
    });
  watch(getCreatorTransfersError, toast.handleRequestError);

  // < get transfers by campaigns
  const {
    loading: getOverallByCampaignsProgress,
    error: getOverallByCampaignsError,
    data: getOverallByCampaignsResult,
    execute: getOverallByCampaignsRequest,
  } = useAPI<Array<ITransactionItem>>('', false);

  const getOverallByCampaigns = (campaignIDs: Array<string>) =>
    getOverallByCampaignsRequest({
      url: '/transfers/overall',
      data: { campaignIDs },
      method: 'POST',
    });
  watch(getOverallByCampaignsError, toast.handleRequestError);
  // < get transfers by campaigns

  // < get transfers all by admin
  const {
    loading: getAdminTransfersProgress,
    error: getAdminTransfersError,
    data: getAdminTransfersResult,
    execute: getAdminTransfersRequest,
  } = useAPI<{ transfers: Array<ITransactionItem>; counts: number }>('', false);

  const getAdminTransfers = (
    skip: number,
    limit: number,
    status: string,
    search?: string,
    byMonth?: Date
  ) =>
    getAdminTransfersRequest({
      url: '/transfers/admin',
      method: 'GET',
      params: {
        skip,
        limit,
        status,
        search,
        byMonth,
      },
    });
  watch(getAdminTransfersError, toast.handleRequestError);
  // < get transfers all by admin

  return {
    // get all by admin
    getAdminTransfers,
    getAdminTransfersResult,
    getAdminTransfersError,
    getAdminTransfersProgress,
    // get overall
    getOverallByCampaigns,
    getOverallByCampaignsResult,
    getOverallByCampaignsError,
    getOverallByCampaignsProgress,
    // < get transfers by creator
    getCreatorTransfers,
    getCreatorTransfersError,
    getCreatorTransfersData,
    // < get transfers by campaign
    getCampaignTransfers,
    getCampaignTransfersResult,
    getCampaignTransfersProgress,
    getCampaignTransfersError,
  };
};
