
import {
  defineComponent, PropType, ref, watch,
} from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import { Brand } from '@/data/types';
import ModalWorkingOverlay from '../../commons/modals/ModalWorkingOverlay.vue';
import PhotoGallery from '../../commons/media/PhotoGallery.vue';
import CropperImage from '../../campaigns/view/CropperImage.vue';

export default defineComponent({
  components: {
    ModalWorkingOverlay,
    Button,
    Dialog,
    PhotoGallery,
    CropperImage,
    InputText,
    Textarea,
  },
  props: {
    show: Boolean,
    working: Boolean,
    brand: Object as PropType<Brand>,
  },
  emits: ['save', 'update:show'],
  setup(props, { emit }) {
    const photo = ref('');
    const name = ref('');
    const description = ref('');
    const showCrop = ref(false);

    watch(props, () => {
      if (props.brand) {
        photo.value = props.brand.photo;
        name.value = props.brand.name;
        description.value = props.brand.description;
      }
    });

    // working
    const saveBrand = () => {
      emit('save', {
        photo: photo.value,
        name: name.value,
        description: description.value,
      });
    };

    const cropMainImage = (data: string) => {
      photo.value = data;
      showCrop.value = !showCrop.value;
    };

    const open = () => emit('update:show', true);

    return {
      showCrop,
      cropMainImage,
      saveBrand,
      open,
      photo,
      name,
      description,
    };
  },
});
