import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewEarnings = _resolveComponent("NewEarnings")!
  const _component_OldEarningsPage = _resolveComponent("OldEarningsPage")!

  return (_ctx.isNewAdminEnabled)
    ? (_openBlock(), _createBlock(_component_NewEarnings, {
        key: 0,
        earnings: _ctx.earnings,
        limit: _ctx.limit,
        loading: _ctx.isLoading,
        changePage: _ctx.handlePageChange
      }, null, 8, ["earnings", "limit", "loading", "changePage"]))
    : (_openBlock(), _createBlock(_component_OldEarningsPage, {
        key: 1,
        isAdminData: _ctx.isAdminData
      }, null, 8, ["isAdminData"]))
}