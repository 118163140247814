
import { defineComponent, PropType } from 'vue';
import { User } from '@/data/types';
import UserPhoto from './UserPhoto.vue';

export default defineComponent({
  components: { UserPhoto },

  props: {
    user: Object as PropType<User>,
    link: {
      type: [Boolean, String],
      default: 'creator',
    },
  },

  methods: {
    onClick() {
      if (this.link === 'creator' && this.user) {
        this.$router.push({
          name: 'creator',
          params: { creatorID: this.user.id },
        });
      }
    },
  },
});
