import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewEditBrandModal = _resolveComponent("NewEditBrandModal")!
  const _component_OldEditBrandModal = _resolveComponent("OldEditBrandModal")!

  return (_ctx.isNewBrandEnabled)
    ? (_openBlock(), _createBlock(_component_NewEditBrandModal, { key: 0 }))
    : (_openBlock(), _createBlock(_component_OldEditBrandModal, {
        key: 1,
        show: _ctx.show,
        working: _ctx.working,
        brand: _ctx.brand
      }, null, 8, ["show", "working", "brand"]))
}