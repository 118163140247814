import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewMyContent = _resolveComponent("NewMyContent")!
  const _component_OldMyContent = _resolveComponent("OldMyContent")!

  return (_ctx.isNewMyContentPageEnabled)
    ? (_openBlock(), _createBlock(_component_NewMyContent, {
        key: 0,
        contentItems: _ctx.contentItems,
        selectStatusCategory: _ctx.selectStatusCategory,
        searchValueChange: _ctx.searchValueChange,
        goToVideo: _ctx.goToVideo,
        routeTo: _ctx.routeTo,
        goBack: _ctx.goBack,
        user: _ctx.user
      }, null, 8, ["contentItems", "selectStatusCategory", "searchValueChange", "goToVideo", "routeTo", "goBack", "user"]))
    : (_openBlock(), _createBlock(_component_OldMyContent, {
        key: 1,
        showSearch: _ctx.showSearch,
        private: _ctx.private,
        limit: _ctx.limit
      }, null, 8, ["showSearch", "private", "limit"]))
}