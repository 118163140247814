import React, { useEffect, useState } from "react";
import "./NewSubmitDraft.css";
import {
  CloseIcon, CopyIconSmall, DesktopIcon, TrashIcon
} from "@/react_app/img/Icons";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useAuth, useContentItems, useToastModule } from "@/modules";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { formatDate } from "@/utils/globals/date-utils";

const videoTemplate = (rowData) => {
  return (
    <div className={'videoTemplate'}>
      <Image src={rowData.thumbnails.default.url} alt={'video thumbnail'} className={'videoThumbnail'} />
      <p className={'videoTitle'}>{rowData.title}</p>
    </div>
  )
};

const urlTemplate = (rowData) => {
  return (
    <div className={'urlTemplate'}>
      <p className={'urlLink'}>{rowData.url}</p>
      <div onClick={() => navigator.clipboard.writeText(rowData.url)}>
        <CopyIconSmall />
      </div>
    </div>
  )
};

const statusTemplate = () => {
  return (
    <div className={'statusTemplate'}>
      Uploaded
    </div>
  )
};

const dateTemplate = (rowData) => {
  return (
    <div className={'dateTemplate'}>
      {formatDate(rowData.createdAt)}
    </div>
  )
};

const deleteTemplate = () => {
  return (
    <div className={'deleteTemplate'}>
      <TrashIcon />
    </div>
  )
};

export default function (props) {
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState('');
  const [isLinkUploading, setIsLinkUploading] = useState(false);
  const [showBadChannelError, setShowBadChannelError] = useState(false);
  const [videoInfoError, setNoVideoInfoError] = useState(false);

  const { user } = useAuth();
  const toast = useToastModule();
  const extractVideoIDs = () => {
    let IDs;
    if (youtubeLink?.includes("youtu.be")) {
      IDs = ["&id=" + youtubeLink?.split("/").filter(Boolean)[2]];
    } else {
      IDs = [`&id=${youtubeLink?.split("=")[1]}`];
    }
    return IDs;
  };

  const [progressCpmCampaign, setProgressCpmCampaign] = useState({
    videoDraft: false,
    draftAccepted: false,
    draftRejected: false,
    liveVideoPosted: false,
    rejectReason: "",
  });

  const [progressCpmCampaignChannels, setProgressCpmCampaignChannels] = useState([]);

  const contentItemsModule = useContentItems();

  const getProgressCpm = () => {
    if (user?.value && props.campaignID) {
      const calculatedChannelIDs = [];

      if (user?.value?.isAgencyOwner() && user?.value?.agencyWorkers?.length) {
        user?.value.agencyWorkers.map((worker) => {
          calculatedChannelIDs.push(worker?.channel?.channelId);
          return worker;
        });
      }

      contentItemsModule.manager.getStatusVideoCpm(user?.value?.id, props.campaignID, calculatedChannelIDs).then((data) => {
        if (user?.value?.isCreator()) {
          setProgressCpmCampaign({
            videoDraft: data.videoDraft,
            draftAccepted: data.draftAccepted,
            rejectReason: data.rejectReason,
            draftRejected: data.draftRejected,
            liveVideoPosted: data.liveVideoPosted,
          });
        }
        if (user?.value?.isAgencyOwner()) {
          setProgressCpmCampaignChannels(data);
        }
      });
    }
  };

  const submitUnlistedVideo = () => {
    setIsLinkUploading(true);
    const IDs = extractVideoIDs(youtubeLink);
    contentItemsModule.manager.createUnlistedContent(user?.value?.id, props.campaignID, IDs, props.selectedChannel)
      .then(async (data) => {
        if (data) {
          setProgressCpmCampaign(prevState => ({ ...prevState, videoDraft: true, draftRejected: false }));
          toast.showSuccess("Your Draft Video Uploaded Successfully!");
          setOpenUploadDialog(false);
          props?.setOpenCPMPendingDraftModal(false)
          if (!user?.value?.isAgencyOwner()) {
            props?.handleChangeTab(2, true);
          }
          setYoutubeLink('');
          props?.setSelectedChannel();
          props?.updateDataPage();
          await getProgressCpm();
          if (user?.value?.isAgencyOwner()) {
            props?.getAgencyProgress()
          }
        }
        setIsLinkUploading(false);
      }).catch(error => {
        setIsLinkUploading(false);
      })
  };

  useEffect(() => {
    if (props && props?.progressCpmCampaign) {
      setProgressCpmCampaign(prevState => ({ ...prevState, ...props?.progressCpmCampaign }))
    }
  }, [props])

  return (
    <div className={props.displayNone ? 'submit-draft-content display-none' : 'submit-draft-content'}>
      {props.selectedChannel && <p className={'channelName'}>Selected channel: {props.selectedChannel.channel.title}</p>}
      <div className={'topRow'}>
        <div className={'uploadSquare'}>
          <DesktopIcon />
          <p className={'uploadTitle'}>Upload Draft</p>
          <p className={'uploadText'}>Upload the relevant unlisted video from your youtube channel</p>
          <Button className={'uploadButton'} disabled={isLinkUploading || progressCpmCampaign.liveVideoPosted || progressCpmCampaign.draftAccepted} onClick={() => setOpenUploadDialog(true)}>Upload Draft</Button>
          <a className={'tutorialLink'} href={'https://www.youtube.com/watch?v=S6v1QZ675AI'} target={'_blank'}>Watch a step-by-step tutorial</a>
        </div>
        <div className={'linkAndGamekeys'}>
          <p className={'title'}>Your Process for Content Submission</p>
          <ol className='process-steps'>
            <li>Upload the relevant unlisted video from your youtube channel.</li>
            <li>Send your draft for an approval.</li>
            <li>Wait for our feedback.</li>
            <li>Once your draft is approved, publish the video and update the URL.</li>
            <li>Done! Enjoy the result.</li>
          </ol>
        </div>
      </div>
      <div>
        <p className={'uploadedContentTitle'}>Uploaded Content</p>
        <DataTable value={props?.latestSubmits}>
          <Column field="title" header="Video" body={videoTemplate} style={{ width: '45%' }}></Column>
          <Column field="url" header="Video Link" body={urlTemplate} style={{ width: '28%' }}></Column>
          <Column field="" header="Status" body={statusTemplate} style={{ width: '14%' }}></Column>
          <Column field="createdAt" header="Submission Date" body={dateTemplate} style={{ width: '14%' }}></Column>
          <Column field="" header="" body={deleteTemplate} style={{ width: '9%' }}></Column>
        </DataTable>
        <div className={'buttonGroup'}>
          <Button className={'leaveButton'} onClick={() => props.leaveCampaign()}>Leave Campaign</Button>
        </div>
      </div>
      <Dialog visible={openUploadDialog || props.openSubmit} onHide={() => { setOpenUploadDialog(false); props.setOpenSubmit(false); }} className={'uploadDialog'}>
        <div className={'closeWrapper'}>
          <div onClick={() => { setOpenUploadDialog(false); props.setOpenSubmit(false); }}><CloseIcon /></div>
        </div>
        <div className={'uploadDialogContent'}>
          <p className={'uploadDialogTitle'}>Upload draft video</p>
          <p className={'youtubeLinkTitle'}>Your unlisted youtube link</p>
          <InputText className={'youtubeLinkInput'} placeholder={'Add a link to your draft YouTube video'} value={youtubeLink}
            onChange={(e) => setYoutubeLink(e.target.value)} />
          <p className={'exampleLink'}>Example: https://www.youtube.com/watch?v=example/</p>
          {showBadChannelError &&
            <p className={'linkError'}>
              The video you are trying to download was uploaded by a YouTube channel that is not added to your profile.
              Please add your YouTube channel to your profile first.
            </p>
          }
          {videoInfoError &&
            <p className={'linkError'}>
              This video and the data from it are not available.
            </p>
          }
          <Button className={'uploadButton'} disabled={isLinkUploading || progressCpmCampaign.liveVideoPosted || progressCpmCampaign.draftAccepted} onClick={() => submitUnlistedVideo()} loading={isLinkUploading}>Upload draft video</Button>
          <p className={'warningText'}>By submitting your content to Gameinfluencer, you acknowledge that you agree to Gameinfluencer’s terms and conditions. please be sure not to violate others copyright or privacy rights. Learn more.</p>
        </div>
      </Dialog>
      <Dialog visible={props.openCPMPendingDraftModal} onHide={() => props.setOpenCPMPendingDraftModal(false)} className={'uploadDialog'}>
        <div className={'closeWrapper'}>
          <div onClick={() => props.setOpenCPMPendingDraftModal(false)}><CloseIcon /></div>
        </div>
        <div className={'uploadDialogContent'}>
          <p className={'uploadDialogTitle'}>Upload draft video</p>
          {/* {progressCpmCampaign?.rejectReason &&
            <p className={'uploadDialogSubTitle redText'}>You last draft video rejected with reason:{progressCpmCampaign?.rejectReason}</p>}
          <p className={'uploadDialogSubTitle'}>
            All CPM videos must be submitted as an unlisted YouTube video to be reviewed.
            Once they are accepted you will be notified and will be good to post your live YouTube
            video and submit it here.
          </p> */}
          <p className={'youtubeLinkTitle'}>Your unlisted youtube link</p>
          <InputText className={'youtubeLinkInput'} placeholder={'Add a link to your draft YouTube video'} value={youtubeLink}
            onChange={(e) => setYoutubeLink(e.target.value)} />
          <p className={'exampleLink'}>Example: https://www.youtube.com/watch?v=example/</p>
          {showBadChannelError &&
            <p className={'linkError'}>
              The video you are trying to download was uploaded by a YouTube channel that is not added to your profile.
              Please add your YouTube channel to your profile first.
            </p>
          }
          {videoInfoError &&
            <p className={'linkError'}>
              This video and the data from it are not available.
            </p>
          }
          <Button className={'uploadButton'} disabled={isLinkUploading || progressCpmCampaign.liveVideoPosted || progressCpmCampaign.draftAccepted} onClick={() => submitUnlistedVideo()} loading={isLinkUploading}>Upload draft video</Button>
          <p className={'warningText'}>By submitting your content to Gameinfluencer, you acknowledge that you agree to Gameinfluencer’s terms and conditions. please be sure not to violate others copyright or privacy rights. Learn more.</p>
        </div>
      </Dialog>
    </div>
  )
}
