<!-- eslint-disable vue/max-len -->
<template>
  <div>
    <div class="text-terms">
      <strong>Terms and conditions</strong>
      <p><strong>&nbsp;</strong></p>
      <p><strong>GameInfluencer.IO platform Terms of use&ndash;Brand</strong></p>
      <p><strong>Definitions</strong></p>
      <p><strong>Action - </strong>Shall mean (but not exclusively) a click, install, lead, sale, view defined
        as a payment trigger as set out below</p>
      <p><strong>Allocated Budget </strong>&ndash; Portion of the campaign budget already put to the side to pay out
        Creators based on
        tracked
        performance conversions thus far.</p>
      <p><strong>Brands </strong>&ndash; Refers to entities or persons using GameInfluencer.IO or in contact with Gameinfluencer for
        creating
        campaigns</p>
      <p><strong>Campaign Brief </strong>&ndash; Campaign details and documents provided by Brand for clarification of
        Campaign</p>
      <p><strong>Campaign CPI Model </strong>&ndash; predetermined metric “cost per install” to set the prices paid per
        conversions</p>
      <p><strong>Creators </strong>&ndash; Refer to Content Creators who make use of GameInfluencer.IO or are in contact with
        Gameinfluencer.</p>
      <p><strong>Creator Ad Campaign </strong>&ndash; Marketing Campaign available on GameInfluencer.IO which involves Content
        Creators</p>
      <p><strong>Creator Ad</strong> &ndash; Content produced following the Brief and in association with a specific
        Campaign from
        GameInfluencer.IO</p>
      <p><strong>Creators Channel </strong>&ndash; Refers to the Content Creators channel on a Media Platform</p>
      <p><strong>Creator Link </strong>&ndash; The tracking link provided to Content Creator to track performance of a
        Campaign from
        GameInfluencer.IO</p>
      <p><strong>Content </strong>&ndash; Refers to Posts made on the Creators Media Platforms</p>
      <p><strong>Intellectual Property Rights </strong>&ndash; includes all patents, utility models, rights to
        inventions, copyright and
        neighbouring and related rights and rights in other subject matter, trade-marks and service marks, business
        names and domain names, rights in get-up and trade dress, goodwill and the right to sue for passing off or
        unfair competition, rights in designs, database rights, rights to use, and protect the confidentiality of,
        confidential information (including know-how and trade secrets), and all other intellectual property rights,
        in each case whether registered or unregistered and including all applications and rights to apply for and
        be granted, renewals or extensions of, and rights to claim priority, in relation to such rights, and rights
        to enforce and seek remedies and redress for the infringement of violation of any such rights, past, present,
        or future, and all similar or equivalent rights or forms of protection which subsist or will subsist now or in
        the future in any part of the world;</p>
      <p><strong>License Request </strong>&ndash; A feature of GameInfluencer.IO in which brands can request to purchase the license
        rights to a
        Creator Ad,
        after the completion of the campaign.</p>
      <p><strong>Media Platform </strong>&ndash; Refers to social media platforms, including but not limited to,
        YouTube, Instagram and
        Tik Tok</p>
      <p><strong>Paid Content </strong>- Refers to Posts made on the Creators channel for a specific Campaign that they
        have accepted on
        GameInfluencer.IO</p>
      <p><strong>Posts </strong>&ndash; Refers to a Media Platform post to share Content with their community.</p>
      <p><strong>Real Installs</strong> - A Real Install means only a real individual person that actively accesses the
        application via
        a Creator Link and then proceeds to install and launch the campaign promoted application while on the
        internet.
        This only applies for a real individual person and cannot be a robot script or computer generated and can also
        not be identified as fraudulent or a duplicate by GameInfluencer.IO</p>
      <p><strong>Third-Party Services </strong>&ndash; Refers to any piece of Hardware or Software necessary to run GameInfluencer.IO
      </p>
      <p><strong>Third-party Tracking Data Providers </strong>&ndash; Refers to Attribution data partner companies such
        as but is not
        limited
        to companies such as Apps Flyer, Tune, Adjust.</p>
      <p>Tracking Link - Refers to a link that is created on the platform for Creators to share alongside their Ads
        which allows for the performance to be tracked.</p>
      <p>&nbsp;</p>
      <p><strong>Introduction.</strong>The GameInfluencer.IO platform is operated and owned by GameInfluencer. By using GameInfluencer.IO
        Platform,
        you decide to accept these GameInfluencer.IO Platform Terms of Use (“Terms of Use”).</p>
      <p>GameInfluencer created this platform to bring together Brands and Creators, allowing Brands to acquire
        Content for
        their marketing campaigns, and for Creators to sell Paid Content, generating and sharing sponsored Posts
        through
        the Creators Channels (YouTube) with support from
        GameInfluencer to help provide control and direction in alignment with these Terms if Use. In these Terms of
        Use,
        Creators and Brands are collectively referred to as “you” depending on the context.</p>
      <p>The GameInfluencer.IO Platform allows Brands to create Creator Ad Campaigns via the which are then distributed to our
        network of Creators and displayed on the GameInfluencer.IO Platform. Creators can choose to participate and accept the
        Creator
        Ad Campaign based on information& terms outlined in the Brief and Terms of Service. A Creator will earn, and
        a Brand will pay Creators when:</p>
      <p><strong>(a)</strong> in relation to a Creator Ad Campaign, when the Creator Ad is published to the Creator’s
        Community, such
        as
        viewers and fans, via their Channels, as selected; and starts generating conversions via their Designated
        Tracking Link</p>
      <p><strong>(b)</strong> in relation to a Creator Ad Campaign, the Brand requests and acquires rights to use one of
        the designated
        campaign Creator Ad in additional marketing channels as further clarified in the correlating License Request
        post campaign.</p>

      <p>At GameInfluencer.IO it’s important for us to ensure all Posts are transparent and have the consumers best interest at
        heart.
        Put simply, all Posts acquired through GameInfluencer.IO should be transparent and have labeled disclosure such as #ad or
        any other guidelines set by the relevant Media Platform. Brands and Creators making use of GameInfluencer.IO are expected
        to stay up to date themselves regarding the latest best practices regarding the labeling of ads in Creator’s
        marketing Posts so that the consumers best interest is kept at heart. Making use of GameInfluencer.IO users comply with
        all applicable laws and industry self-regulation relating to influencer marketing and advertising, such as
        all applicable laws and regulations, including without limitation guidelines and laws relevant to promotional
        content. Creator will follow all FTC and other guidelines and laws relevant to promotional content. GameInfluencer.IO uses
        YouTube API Services to gain insight on video performance and the channels audience to provide Creators with
        relevant Campaigns. By making use of GameInfluencer.IO and uploading relevant Content on YouTube Creators agree and are
        bound
        to the YouTube Terms of Service and Google Privacy Policy, as of the latest updates. If the user fails to
        comply
        with any of the Platform guidelines and laws previously mentioned, the user can instantly be terminated from
        the GameInfluencer.IO platform.</p>
      <p>The use of GameInfluencer.IO is only upon your agreement to accept these Terms of Use. Every time you make use of GameInfluencer.IO you
        are bound and in agreement with these Terms of Use. If you don’t desire to be bound to these Terms of Use,
        don’t use GameInfluencer.IO.</p>
      <p>Only legal entities as well as natural persons as entrepreneurs of unlimited legal capacity may become party
        of the Agreement. GameInfluencer reserves the right to demand supporting documents from the Brand to prove its
        legal status and in case of an employee of the Brand and / or an agency registering with the GameInfluencer
        Network and / or concluding the Agreement, to demand a written authorization</p>
      <p>For further transparency, you are required to accept these Terms of Use as part of the application process by
        clicking the “I agree to the Terms of Use” box. Both when using the GameInfluencer.IO platform in any forms possible as
        well
        as accepting the Terms of Service when registering, a legally binding contract is created between GameInfluencer.IO and you
        the Brand.</p>
      <p><strong>Registration requirements.</strong>To register an account to use GameInfluencer.IO as a Brand an individual must
        sign in to the platform with their google account or create a new account as specified on the platform and
        these
        Terms of Use. For signing up to the GameInfluencer.IO platform as well as when creating campaigns. The Brand must provide
        factual and clear information as part of the registration process, to ensure payment and tax filing purposes.
        Once the registration process has been completed successfully, and once payment requirements and while
        accepting
        this agreement, Brands are granted a limited, non-exclusive and non-transferable license to make use
        of the GameInfluencer.IO platform.</p>
      <p><strong>GameInfluencer.IO&amp; Warranties. </strong>The Brands & Creators understands that GameInfluencer.IO is in constant development
        and
        improvement and some of the platform will change over time. Brands & Creators have the right to use the
        platform
        through the registration process. Both Creators and Brands understand that depending on the market
        development,
        GameInfluencer.IO might be terminated or temporarily out of service. GameInfluencer.IO can be tweaked and improved whenever desired even
        if it potentially affects layout or current services, and can be done without requesting any consent from
        Creators or Brands. GameInfluencer shall notify the Brand, as far as plannable, with a period of 48 hours in
        advance of such technical measures, if these have an substantial impact on the GameInfluencer services
        rendered
        to Brand. Besides the key points outlined in the agreement, the entire platform is “as is” and doesn’t come
        with any warranties. The Brands & Creators are expected to use the platform as described and in good faith,
        any illegal or unlawful activities are forbidden. GameInfluencer.IO can deny any Creator or Brand access to GameInfluencer.IO without
        written notice and prior consent if GameInfluencer.IO & Gameinfluencer notice the Brand is harming the platform or
        affecting
        its useability in any form possible, including but not limited to, hacking and malware. Brands & Creators are
        responsible for keeping their login information confidential. In case any unrightful user acquires the login
        details, the Brand or Creator should inform GameInfluencer.IO & Gameinfluencer immediately as any changes made by the
        unrightful user in GameInfluencer.IO are sole liability of Brand. For Brands & Creators, any Third-Party Services, hardware
        or software necessary to successfully use GameInfluencer.IO are their sole responsibility, such as but not limited to
        computers, phones, Third-party Tracking Data Providers. In no way shape or form are GameInfluencer.IO & Gameinfluencer
        liable for the affected performance of GameInfluencer.IO based on these Third-Party Services, hardware or software
        performances. This agreement overrides all existing agreements, understandings or communications between the
        Brand and GameInfluencer.IO (and Gameinfluencer team) in regards to the topics covered in the Agreement and makes up the
        entire agreement between the Brand and the GameInfluencer.IO (and Gameinfluencer team).</p>
      <p><strong>Tracking Link &amp; Attribution data. </strong> Brands are also responsible for ensuring they acquire
        proper Attribution Data (The specific data that qualifies as a conversion in relation to the Brands campaign)
        and any other data or details and actively update GameInfluencer.IO & GameInfluencer with factual and accurate
        Attribution/Tracking
        Data performances where necessary for delivering of GameInfluencer.IO and Gameinfluencer’s Services. The testing of
        tracking
        pixels, -links or any other mechanism(s) (hereinafter referred to as “Tracking Measurements”) for observing
        and recording the performance of a campaign will be the responsibility of the Brand. In the event that the
        Brand incorrectly places Tracking Measurements or if the Brand removes or alters Tracking Measurements at any
        time during a campaign without express written permission from GameInfluencer the Brand shall pay to
        GameInfluencer, as liquidated damages, a sum equal to the daily average revenue generated by GameInfluencer
        for the campaign in question in a period of two (2) days immediately preceding such removal or alteration of
        Tracking Measurement by the Brand. If the Tracking Measurements are interrupted or disrupted due to technical
        problems, server downtime, data loss, transmission errors or other reasons by GameInfluencer, and, as a
        result,
        the Tracking Measurements are not recording valid actions correctly, then GameInfluencer shall be liable to
        pay the liquidated damages, a sum equal to the daily average campaign cost lost by the Brand due to such
        interruption or disruption by the GameInfluencer, multiplied by the number of days rounded up in respect of
        which tracking has been interrupted or rendered inoperable or ineffective. If there has not been a tracking
        of two days, the daily average revenue generated by GameInfluencer, based on all current campaigns with the
        respective Brand, shall be decisive for liquidated damages.
        Unless otherwise agreed, the Brand´s tracking technology shall be the sole system to track applicable actions
        and to calculate GameInfluencer´s remuneration. However, if Brand’s actions deviate significantly from
        GameInfluencer's tracked numbers, GameInfluencer has the right to audit the Brand’s numbers. The Brand shall
        therefore, at all times, during the term of the Agreement and for a period of three months thereafter, upon
        reasonable request, grant GameInfluencer access to the Brand’s tracking system and/ or tracking reports.
        All Actions shall be deemed confirmed or cancelled no later than 10 (ten) days in the following month by
        the Brand and thus be finally payable to GameInfluencer as set out in the Agreement (“Validation Period”).
        If the Brand does not reasonably object Actions within the validation period, such Action shall be deemed
        validated after the expiration of the Validation Period. In case Actions are cancelled by the Brand, the
        Brand must prove on reasonable grounds the non–validation of such Actions. The Brand shall furnish
        GameInfluencer
        with all relevant information and / or documentation, including, but not limited to, business records, and log
        files as well as any objections it may have on the Actions it has not validated, in order to allow
        GameInfluencer to review if the non – validation by the Brand was complete and accurate. In case the
        information provided is accurate and complete, GameInfluencer shall classify the relevant Actions as
        cancelled. However, Brands and GameInfluencer reserves the right to audit the given information during normal
        business hours of the Brand in order to review its classification at a later stage.
        An Action shall be especially, but not exclusively, considered as being non – valid, if it is generated
        automatically by a technical device and / or software (e.g. robot, spider, script).
        Any amounts due to GameInfluencer shall be plus the statutory value added tax (VAT), as far as value added tax
        is obligatory with respect to the GameInfluencer Services to the Brand. The Brand shall bear any and all
        banking
        fees related to payments by the Brand to GameInfluencer.
        if the Brand fails to make any payment due to GameInfluencer under the agreement by the due date, a late
        interest shall accrue on the overdue amount on a daily basis from the due date at the rate of 8% per annum
        of the base rate of the European Central Bank. Such interest shall accrue until actual payment of the overdue
        amount by the Brand.</p>
      <p><strong>Relevant Data & personal information. </strong>GameInfluencer.IO collects basic personal/corporate data as filled
        out in the application process and payment section of the platform. Such data includes, but is not limited to,
        examples such as company name, contact information, payment details, and brand inputted photos.</p>
      <p><strong>Intellectual Property rights.</strong> All right, title and interest and all Intellectual Property
        Rights in Creator provided Content as part of the Creator Marketing Campaign will remain held the Creator.
        Nothing in these Terms of Use suggest a transfer of Intellectual property regarding the Content available
        through GameInfluencer.IO & Gameinfluencer. All material provided by Brands as part of the Campaign process will remain
        the sole Intellectual Property of the Brand but the Creators have the limited and non-exclusive right to use
        the Brand provided materials to deliver on creating Content as part of the Campaign. In the event that a Brand
        chooses, but is not required to, give feedback for platform or service improvements for GameInfluencer.IO & Gameinfluencer,
        if GameInfluencer.IO & Gameinfluencer decide to act on this feedback or implement similar improvements these will be and
        remain the sole Intellectual Property of GameInfluencer.IO & GameInfluencer.</p>
      <p><strong>Content.</strong> In exchange for the Campaign related payments, brands will acquire the right to
        organically share and comment on the Content. The right to use the Creator’s Identity and performances is
        restricted to communicate the Content to the public in accordance with previous line outlined in this Terms of
        Use.
        In regards to the “additional Licensing request” after completion of Campaign, where Brands might request
        additional license from the Creator regarding the Content present in the Campaign. Depended on the filled out
        Licensing terms and after the Creator’s decision to accept the additional license terms and additional
        monetary
        compensation, the brand will have an additional license to promote the Creator’s Content in its own marketing
        efforts such as, but not limited to, the right to use the Creator’s Identity and performances in the Content
        to communicate the Content to the public in all languages, in all media such as online paid media banners,
        in all online owned media such as website, and in all owned social media, and in any earned media or public
        relations activity published by a third party. GameInfluencer.IO & Gameinfluencer will ensure Creators and submitted
        Content follow the Brand provided
        Campaign Brief details as well as comply with all necessary Media Platform regulations regarding Content such
        as but not limited to, YouTube Terms of Service and Google Privacy Policy, as of the latest updates.
        Furthermore,
        Creators will follow all FTC and other guidelines and laws relevant to promotional Content to ensure full
        transparency and desired Content. In case a Creator or Content, part of the Creator Ad Campaign, was modified
        or somehow doesn’t comply with the above mentioned, Campaign brief, guidelines and regulations, His Content
        will be terminated as soon as notified or when discovered. Brands will receive a full refund for fees paid
        relating to that specific sole Content.
        The positioning, placement, frequency and other editorial decisions related to the Creator Ads shall be
        compliant with the campaign restrictions and shall be made by GameInfluencer.IO &GameInfluencer, in its sole discretion.
        The Brand may request GameInfluencer.IO &GameInfluencer, that Creator Ads placed on Influencer Media may be changed or
        repositioned, if the Brand has the impression, that the current position of the Creator ads may damage the
        Brand´s and the Brand´s services reputation. In the event of such movement or reposition, GameInfluencer shall
        not be liable for any claims against GameInfluencer based on such movement or reposition.
        GameInfluencer reserves the right to reject, omit, exclude or terminate any Creator Ad for any reason at any
        time, with subsequent written notice (email shall be sufficient) to such Brand, whether or not such Creator
        Ad was previously used.</p>
      <p><strong>Confidentiality. </strong>Throughout the duration of this Agreement, it may be necessary for the
        relevant Parties (Creator,Brand,GameInfluencer & GameInfluencer.IO) to have access to each other’s confidential and
        protected information for the sole purpose of performing the Services subject to this Agreement. None of
        the Parties permitted to share or disclose such confidential information whatsoever, unless mandated by law,
        without written permission from the relevant Party. All of the obligations of confidentiality will survive the
        termination of the Agreement and stay in place 3 years after completion of the relevant Campaign. Upon the
        termination of the Agreement, the Parties involved agree to return any and all Confidential Information that
        is the property of the other Party upon written request. They shall promptly return all copies, whether in
        written, electronic, or other form or media, of the Party’s Confidential Information, or destroy all such
        copies and certify in writing to the Party that such Confidential Information has been destroyed.
        This confidentiality only applies to Content that is private and does not take into consideration information
        that is public, lawfully acquired through third party, or already in possession of the same information before
        the confidentially agreement.</p>
      <p><strong>Notice.</strong> Notice should be given in a timely manner and by the respective point of contact
        that
        has engaged the other party thus far, and is required to be given by email. If any of the parties change their
        point of contact or their contact details, they should notify all relevant parties via email in advance.</p>
      <p><strong>Force Majeure.</strong> Any of the relevant parties shall not be in breach of this Agreement for any
        delay or failure in performance caused by reasons out of its reasonable control. This includes, but is not
        limited to, acts of God or a public enemy; natural calamities; failure of a third party to perform; changes in
        the laws or regulations; actions of any civil, military or regulatory authority; power outage or other
        disruptions of communication methods or any other cause which would be out of the reasonable control of the
        relevant party.</p>
      <p><strong>Payments.</strong> GameInfluencer.IO will pay the Creator after having received the campaign funds from the
        relevant Brand. To manage and execute the campaign, the Brand deposits his campaign budget on the platform as
        part of the Creator Ad Campaign. GameInfluencer will hold the budget in escrow and pay out Creators on a
        monthly basis depending on their CPI and Brand provided Attribution Data. Once a Creator partaking in a
        Campaign generate Conversions, a small section of the escrow budget will be put aside under “Allocated Budget”
        meaning regardless if the Brand wishes to cancel the Campaign after the generated conversion, the Allocated
        Budget will be paid out to Creators to ensure compensation for the Conversions. GameInfluencer.IO will pay the Creator
        after having received the campaign funds from the relevant Brand. The payment will be made on a monthly basis
        (30 days) or upon sudden cancelation of Campaign and are based on the relevant Campaigns CPI Model. Meaning a
        Creator will be paid for Real Installs generated through their Creator Link from the relevant Campaign.
        A Real Install means only a real individual person that actively accesses the application via the third-party
        distribution service such as, but not limited to, Google or the Apple play store, and who access the
        distribution service through the Creator’s designated Creator Link for the Campaign, and then proceeds to
        install and launch the Campaign promoted application while on the Internet. This only applies for a real
        individual person and cannot be a robot script or computer generated and can also not be identified as
        fraudulent or a duplicate by GameInfluencer.IO, GameInfluencer or the Brand on a later date. The payments will be made for
        as long as the Campaign runs or until sudden shortened closure of relevant Campaign. Any installs that are
        already paid and later identified as a none Real Installs will get deducted from the Creators account before
        subsequent payments are made. Any identified fraud ways of acquiring the Installs can lead to the immediate
        termination of the Creators account and participation in Campaigns and the Creator will not receive any unpaid
        monthly payments on the Creators account. Brands will also be presented licensing agreement options to license
        Creator Ads for their own marketing efforts, Payment is a one-time fee. After payment brands will be sent the
        Creator Ads. All price points on the platform are excluding VAT tax or other applicable taxes charged on top.
        Brands are solely responsible for covering VAT and other tax obligations. Any identified fraud ways of
        acquiring the Installs can lead to the immediate termination of the Creators account and participation in
        Campaigns and the Creator will not receive any unpaid monthly payments on the Creators account. In the event
        that during Campaigns, Brands notice or have a firm belief that a Content piece is generating fraudulent
        Installs, they are to notify GameInfluencer.IO or Gameinfluencer within 33 days of the relevant install, to allow
        Gameinfluencer & GameInfluencer.IO to review and analyze it in a timely manner incase termination is necessary. In the
        event of early Termination of this agreement, Brands are not entitled to a refund of any prepaid fees. In case
        a Brand does activate Creators and their Content as part of their Campaign options on GameInfluencer.IO, up to 90 days
        after the day of the purchase and according to the terms of this Agreement, Brands cannot purchase or work
        with the Creators in any form directly , or pay a fee of 33000 Euro for breach of Agreement and the
        exclusivity clause. Any notice relating to Brands and their due payments, should be given in written form
        within 9 days after receiving Receipt.</p>
      <p><strong>Limitation of liability. </strong>The GameInfluencer.IO Platform & GameInfluencer are in no way liable for
        uncorrelated, incidental, indirect, special, or exemplary damages. Naturally this also includes, but is not
        limited to, any incident that cause a direct decrease of business opportunities or even potential revenue
        impact even if they are foreseeable and we have been advised of the potential consequences. Brand will be
        liable and won’t hold any other party accountable or liable for damages, liabilities, costs in relation to a
        third-party claim regarding Brand provided materials, Brand information, or the use of the services and
        Content in violation of this agreement. GameInfluencer shall not be liable for the Content on Creator
        Ads, for damages or other disturbances caused by defects and / or interoperability of Creator Ads and / or for
        damages resulting from the insufficient availability or imperfect functioning of the internet and / or the app
        stores. The only exemption to this case is intentional misconduct or gross negligence or any potential
        breaches regarding intellectual Property and confidentiality. We do not restrict our liability beyond what is
        legal by law. The total aggregate liability are limited to campaign fees paid or payables as part of the
        Creator Ads Campaign &additional Licensing options paid to GameInfluencer.IO & GameInfluencer. The Brand provided materials
        and Briefs do not contain any misinterpretations or any Content that is defamatory, contain Content that is
        violent, obscene, offensive, including Content that contains nudity or implied nudity or Content that is
        morally or ethically offensive or sexual suggestive, promote or support illegal gambling or sweepstakes or
        contests or contain any “worm”, “virus”, “Trojan” or other code which could impair or injure any hardware,
        software, person or entity; The Brand shall indemnify and hold harmless, at first request, GameInfluencer from
        any and against any and all liabilities, losses, damages or expenses (including, without limitation,
        reasonable attorney's fees, costs and expenses) arising out of or related to any claim related to the Brand 's
        breach of warranties set out in this section</p>
      <p><strong>Termination. </strong>This agreement has a termination period of 1 month, and can be terminated by
        any of the relevant Parties upon written notice. The written notice should be given in case either party
        breaches the preceding agreement, either party may terminate this agreement immediately in case of a breach of
        the agreement and incase it isn’t solved within 9 days. If the other party does not fix the breach of the
        agreement within 12 days of receiving written notice, the agreement will be terminated. Regarding any
        potential liability that was formed prior to the termination of the agreement, it will still hold. Also
        regarding Influencer uploaded Content that was submitted on GameInfluencer.IO before the termination, all the relevant
        licenses and granted rights that were given to GameInfluencer.IO, GameInfluencer, and Brand will also hold regardless of
        the termination. Finally when the agreement is terminated, all rights and licenses that were given to the
        Influencer will terminate immediately. In the event that the Brand becomes bankrupt or insolvent, GameInfluencer.IO and
        Gameinfluencer have the power to terminate the agreement immediately upon written notice. In the event of
        termination, any Allocated Budget won’t be refunded and will still be charged to the Brand as the services
        preceded the termination.</p>
      <p><strong>Promotion. </strong>For the nature of the platform, the Brand agrees that GameInfluencer.IO & Gameinfluencer have
        the right to use company name, and biography for promoting the platform to other Brands as part of the GameInfluencer.IO’s
        services.</p>
      <p><strong>Severability. </strong>If any term or provision of this Agreement is invalid, illegal, or
        unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other
        term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other
        jurisdiction.</p>
      <p><strong>Platform Protection. </strong>Users aren’t allowed to extract data, copy or reverse engineer any of
        GameInfluencer.IO platform source code, or damage & disrupt GameInfluencer.IO in any manner possible throughout multiple universes and
        in perpetuity. Any violation of the agreement terms and usage will be grounds for a lawsuit.</p>
      <p><strong>Dispute Resolution and Governing Law. </strong>Parties to this Agreement shall first attempt to
        settle any dispute through good-faith negotiation. If the dispute cannot be settled between the parties via
        negotiation, either party may initiate mediation or binding arbitration in German Law. Any dispute should be
        aimed to solve through negotiations, however if the parties can not solve the dispute through negotiations
        within 69 days, the case can be taken to court. All disputes arising in connection with this agreement or even
        parts thereofor its validity shall be finally settled according to the Arbitration Rules and the Supplementary
        Rules for Expedited Proceedings of the German Institution of Arbitration e.V. (DIS) without recourse to the
        ordinary courts of law. The place of arbitration is Munich. The substantive law is German law. The language is
        German or, by discretion of the judge, English. Only one judge shall oversee the dispute, which shall be
        appointed by the DIS. The Parties shall have the right to challenge the judge if they find him not to be
        materially familiar with the issues and/ or the industry in question. The Terms of Use and their
        interpretation shall be governed by the laws of Germany with the exception of the UN Convention on Contracts
        for the International Sale of Goods dated 11.4.1980.</p>
      <p><strong>Legal Fees. </strong>Should a dispute between the named Parties arise lead to legal action, the
        prevailing Party shall be entitled to any reasonable legal fees, including, but not limited to attorneys’
        fees.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #686E6E;
}

</style>
