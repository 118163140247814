
import { defineComponent, onMounted, ref } from 'vue';
import { useAdmins } from '@/modules';
import { useToastModule } from '@/modules';
import { FraudAlert } from '@/data/types/fraud-types';
import OldDetectedFrauds from '@/pages/admin/OldDetectedFrauds.vue';
import NewDetectedFrauds from '@/react_app/NewAdmin/NewDetectedFrauds/NewDetectedFrauds';
import { applyPureReactInVue } from 'veaury';
import { isNewAdminEnabled } from '@/utils/globals/feature-flags';

export default defineComponent({
  components: {
    NewDetectedFrauds: applyPureReactInVue(NewDetectedFrauds),
    OldDetectedFrauds,
  },
  setup() {
    const {
      manager: { getDetectedFrauds, getDetectedFraudsResult, dismissFraud },
    } = useAdmins();
    const toast = useToastModule();
    const selectedStatus = ref<'pending' | 'all'>('all');

    const statusFilterValues = [
      { name: 'Pending alerts only', value: 'pending' },
      { name: 'All alerts', value: 'all' },
    ];
    const limit = ref(10);
    const skip = ref(0);
    const loading = ref(true);

    onMounted(() => {
      // Set an initial value or trigger any function
      getFraudDetectList(true, limit.value, 0); //true indicate the "all" type.
    });

    const getFraudDetectList = (flag: boolean, limit: number, skip: number) => {
      loading.value = true;
      getDetectedFrauds(flag, limit, skip).then(() => (loading.value = false));
    };

    const getStatusDisplayValue = (dismissed: boolean) =>
      dismissed ? 'resolved' : 'pending';

    const getDetectedFraudsWithFilter = (status: 'pending' | 'all') => {
      skip.value = 0;
      selectedStatus.value = status;
      getDetectedFrauds(status === 'all', limit.value, 0);
    };

    const dismissFraudAlert = (fraudId: string) => {
      dismissFraud(fraudId)
        .then(() => {
          toast.showSuccess('Fraud alert dismissed successfully.');
          getDetectedFraudsResult.value = getDetectedFraudsResult.value.filter(
            (fraud: FraudAlert) => fraud.id !== fraudId
          );
        })
        .catch((err: Error) => {
          toast.showError('Unable to dismiss fraud alert: ' + err);
        });
    };

    const pageChange = (data: { first: number }) => {
      skip.value = data.first;
      const flag = selectedStatus.value === 'all';
      getDetectedFrauds(flag, limit.value, data.first);
    };

    return {
      detectedFrauds: getDetectedFraudsResult,
      getStatusDisplayValue,
      dismissFraudAlert,
      statusFilterValues,
      getDetectedFraudsWithFilter,
      selectedStatus,
      isNewAdminEnabled,
      limit,
      loading,
      pageChange,
    };
  },
});
