
import { defineComponent, PropType } from 'vue';
import { AppPageConfig, AppPageProps, Perspective } from '@/data/types';
import { useAuth, usePage } from '@/modules';
import WhiteListing from '@/react_app/NewAdmin/WhiteListing/WhiteListing';
import { applyPureReactInVue } from 'veaury';
import { isNewAdminEnabled } from '@/utils/globals/feature-flags';

export default defineComponent({
  components: {
    WhiteListing: applyPureReactInVue(WhiteListing),
  },
  props: {
    ...AppPageProps,
    viewPerspective: String as PropType<Perspective>,
  },

  setup(props) {
    const { user } = useAuth();

    const { config } = usePage<AppPageConfig>({
      title: 'Pending Campaign Request',
      ...props,
    });

    return {
      config,
      isNewAdminEnabled,
      user,
    };
  },
});
