import React, {useRef, useState} from "react";
import "./NewCampaignCoverCropper.css";
import { Dialog } from 'primereact/dialog';
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

export default function(props){
  const cropperRef = useRef(null);
  const [currentCropped, setCurrentCropped] = useState(props.image);

  const handleCrop = () => {
    const cropper = cropperRef.current?.cropper;
    setCurrentCropped(cropper.getCroppedCanvas().toDataURL());
  };

  const handleSave = () => {
    props.setNewCover(currentCropped);
    props.close();
  };

  return(
    <Dialog className={'cover-cropper'} visible={props.visible} onHide={props.close}>
      <div className={'topLayer'}>
        <i className="pi pi-times" style={{ fontSize: '25px', color: "#fff", cursor: "pointer" }} onClick={props.close}/>
      </div>
      <div className={'content'}>
        <p className={'title'}>Crop Image</p>
        <div className={'cropArea'}>
          <div>
            <p className={'label'}>Crop Image</p>
            <Cropper src={props.image} style={{ height: 300, width: 300 }} aspectRatio={1} guides={true} viewMode={1} crop={handleCrop} ref={cropperRef}/>
          </div>
          <div className={'campaignPreviews'}>
            <div>
              <p className={'label'}>Campaign Main Page Preview</p>
              <Image className={'mainPagePreview'} src={currentCropped} alt={'preview'}/>
            </div>
            <div>
              <p className={'label'}>Campaign Management Preview</p>
              <Image className={'managementPreview'} src={currentCropped} alt={'preview'}/>
            </div>
          </div>
        </div>
        <div className={'buttonGroup'}>
          <Button onClick={props.close} className={'cancelButton'}>Cancel</Button>
          <Button onClick={() => handleSave()} className={'saveButton'}>Save Changes</Button>
        </div>
      </div>
    </Dialog>
  )
}
