import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-396da826"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "value p-mr-1" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "p-p-6 p-text-center text-labels par-modal" }
const _hoisted_5 = { class: "inputContainer" }
const _hoisted_6 = { class: "btnContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_ctx.viewModal)
    ? (_openBlock(), _createBlock(_component_Dialog, {
        key: 0,
        closable: false,
        visible: _ctx.viewModal,
        style: { width: '50vw' }
      }, {
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Button, {
              label: _ctx.closeBtnLabel ?? 'Not now',
              class: "notNow-button",
              onClick: _ctx.onSubmit
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_Button, {
              label: "OK",
              icon: "pi pi-check",
              class: "ok-button",
              onClick: _ctx.onSubmit
            }, null, 8, ["onClick"])
          ])
        ]),
        default: _withCtx(() => [
          (
        _ctx.display?.htmlElementToUse === 'DataTable' && _ctx.display?.displayMultiple
      )
            ? (_openBlock(), _createBlock(_component_DataTable, {
                key: 0,
                value: _ctx.display?.content
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.display?.columns, (column, columnIndex) => {
                    return (_openBlock(), _createElementBlock("div", { key: columnIndex }, [
                      _createVNode(_component_Column, {
                        header: column,
                        sortable: false
                      }, {
                        body: _withCtx(({ data }) => [
                          _createElementVNode("span", _hoisted_1, _toDisplayString(data[Object.keys(data)[columnIndex]]), 1)
                        ]),
                        _: 2
                      }, 1032, ["header"])
                    ]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"]))
            : _createCommentVNode("", true),
          (_ctx.display?.htmlElementToUse === 'div')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.display?.content), 1))
            : _createCommentVNode("", true),
          (_ctx.display?.htmlElementToUse === 'p')
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.display?.content), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.generateMessage()), 1),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.containsInput)
              ? (_openBlock(), _createBlock(_component_InputText, {
                  key: 0,
                  type: "text",
                  modelValue: _ctx.inputValue,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event))
                }, null, 8, ["modelValue"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["visible"]))
    : _createCommentVNode("", true)
}