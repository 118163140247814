import React, { useEffect, useRef, useState } from "react";
import "./NewEditCreatorModal.css";
import NewImageCropper from "@/react_app/Other/NewImageCropper/NewImageCropper";
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { Image } from "primereact/image";
import { CloseIcon } from "@/react_app/img/Icons";
import { UserType } from "@/data/types";
import creator from "@/pages/creators/profile/Creator.vue";
import { MESSAGE_WRAPPER } from '@/utils/globals/constants';


const AddNew = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="59" height="59" viewBox="0 0 59 59" fill="none">
      <path d="M29.5 0C21.7053 0.0940405 14.2564 3.23226 8.74433 8.74433C3.23226 14.2564 0.0940405 21.7053 0 29.5C0.0940405
       37.2947 3.23226 44.7436 8.74433 50.2557C14.2564 55.7677 21.7053 58.906 29.5 59C37.2947 58.906 44.7436 55.7677 50.2557
       50.2557C55.7677 44.7436 58.906 37.2947 59 29.5C58.906 21.7053 55.7677 14.2564 50.2557 8.74433C44.7436 3.23226 37.2947
       0.0940405 29.5 0ZM46.3571 31.6071H31.6071V46.3571H27.3929V31.6071H12.6429V27.3929H27.3929V12.6429H31.6071V27.3929H46.3571V31.6071Z"
        fill="#9F9EA0" />
    </svg>
  )
}

export default function (props) {
  const [openCropper, setOpenCropper] = useState(false);
  const [customUrl, setCustomUrl] = useState('');
  const [availablePhotos, setAvailablePhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(-1);
  const [userDetails, setUserDetails] = useState({
    creatorName: "", creatorDescription: "", creatorPhoto: "", firstName: "", lastName: ""
  });
  const [errorMessage, setErrorMessage] = useState({
    creatorName: "", creatorDescription: "", creatorPhoto: "", firstName: "", lastName: ""
  });

  const fileUploadRef = useRef();

  useEffect(() => {
    if (props.creator) {
      const updatedDetails = {
        creatorName: props.creator.name || "",
        creatorDescription: "",
        creatorPhoto: "",
        firstName: "",
        lastName: "",
      }

      if (props.creator.type === UserType.Creator) {
        updatedDetails.creatorDescription = props.creator.profile?.description || "";
        updatedDetails.creatorPhoto = props.creator.profile?.photo || "";
        updatedDetails.creatorName = props.creator.name || "";
      } else if (props.creator.type === UserType.Agency) {
        updatedDetails.creatorDescription = props.creator.agencyData?.description || "";
        updatedDetails.creatorPhoto = props.creator.agencyData?.photo || "";
        updatedDetails.creatorName = props.creator.agencyData?.name || "";
      }
      updatedDetails.firstName = props.creator?.firstName || ""
      updatedDetails.lastName = props.creator?.lastName || ""

      setUserDetails(updatedDetails);
      setAvailablePhotos([]);
    }
    return () => {
      setErrorMessage({})
    }
  }, [props]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      addCustomPhoto();
    }
  };

  const addCustomPhoto = () => {
    if (customUrl && availablePhotos.length < 8) {
      setAvailablePhotos([...availablePhotos, customUrl])
      setCustomUrl('');
    }
  };

  const handleFileUpload = ({ files }) => {
    const [uploadPhotoInput] = files;

    if (uploadPhotoInput) {
      const file = uploadPhotoInput;
      const reader = new FileReader();
      reader.onload = () => {
        setAvailablePhotos([...availablePhotos, reader.result])
      };
      reader.readAsDataURL(file);
    }

    fileUploadRef.current.clear();
  };

  const getPhoto = () => {
    if (props.creator?.type === UserType.Creator) {
      return userDetails.creatorPhoto === props.creator?.profile?.photo ? undefined : userDetails.creatorPhoto;
    }
    else if (props.creator?.type === UserType.Agency) {
      return userDetails.creatorPhoto === props.creator?.agencyData?.photo ? undefined : userDetails.creatorPhoto;
    }
  }

  const handleChange = (key, value) => {
    setUserDetails((prev) => ({ ...prev, [key]: value }));
    if (key !== "creatorPhoto") {
      setErrorMessage((prev) => ({ ...prev, [key]: value ? "" : MESSAGE_WRAPPER[key] }));
    }
  };

  const validateForm = () => {
    const newErrors = { ...errorMessage };

    if (!userDetails?.creatorName) {
      newErrors.creatorName = MESSAGE_WRAPPER['creatorName'];
    }

    if (!userDetails.firstName) {
      newErrors.firstName = MESSAGE_WRAPPER['firstName'];

    }

    if (!userDetails.lastName) {
      newErrors.lastName = MESSAGE_WRAPPER['lastName'];
    }

    setErrorMessage(newErrors);

    return userDetails.creatorName && userDetails.firstName && userDetails.lastName
  }

  const handleSubmit = () => {
    const isValid = validateForm();
    if (isValid) {
      props.updateCreator({
        photo: getPhoto(), name: userDetails.creatorName, description: userDetails.creatorDescription, firstName: userDetails.firstName, lastName: userDetails.lastName
      })
    }

  }



  useEffect(() => {
    if (!!userDetails) {
      validateForm()
    }
  }, [userDetails])

  return (
    <Dialog className={'edit-creator-modal'} visible={props.visible} onHide={props.onHide}>
      <div className={'top-layer'}>
        <div onClick={() => {
          const allErrorsClear = Object.values(errorMessage).every(msg => msg === "");
          if (allErrorsClear) {
            props.onHide();
          }
        }} className={'pointer'}><CloseIcon /></div>
      </div>
      <div className={'content'}>
        <p className={'title'}>{props.creator?.type === UserType.Agency ? "Agency" : "Influencer"} Info</p>
        <div className={'content-main'}>
          <div className={'content-subgroup'}>
            <p className={'label'}>Profile Image</p>
            <Image src={userDetails.creatorPhoto} alt={'creator-photo'} className={'bigPhoto'} />
            <Button disabled={selectedPhoto === -1} className={'cropButton'} onClick={() => setOpenCropper(true)}>Crop Image</Button>
          </div>
          <div className={'content-subgroup'}>
            <p className={'label'}>Select New Image</p>
            <div className={'photosGrid'}>
              {availablePhotos.map((photo, index) => {
                return (
                  <div className={'addedPhotoWrapper'} key={index} onClick={() => { setSelectedPhoto(index); handleChange('creatorPhoto', photo); }}>
                    <Image className={selectedPhoto === index ? 'addedPhotoSelected' : 'addedPhoto'} src={photo} />
                  </div>
                )
              })}
              <div className={'addNew'}>
                <FileUpload ref={fileUploadRef} disabled={availablePhotos.length >= 8} mode="basic" name="photo" accept="image/*" auto={true} customUpload={true} uploadHandler={handleFileUpload} className={'upload'} />
                <AddNew />
              </div>
            </div>
          </div>
          <div>
            <p className={'label'}>{props.creator?.type === UserType.Agency ? "Agency" : "Influencer"} Name</p>
            <InputText value={userDetails.creatorName} onChange={(e) => handleChange('creatorName', e.target.value)} className={'inputField'} placeholder={"Your name"} />
            <div className={'registerError'} style={{ minHeight: 20 }}>{errorMessage.creatorName && <p>{errorMessage.creatorName}</p>}</div>
            <div className="first-last-wrap">
              <div className="input-wrap">
                <p className={'label'}>First Name <span className="required">*</span></p>
                <InputText value={userDetails.firstName} className={'inputField'} onChange={(e) => handleChange('firstName', e.target.value)} />
                <div className={'registerError'} style={{ minHeight: 20 }}>{errorMessage.firstName && <p>{errorMessage.firstName}</p>}</div>
              </div>
              <div className="input-wrap">
                <p className={'label'}>Last Name <span className="required">*</span></p>
                <InputText value={userDetails.lastName} className={'inputField'} onChange={(e) => handleChange('lastName', e.target.value)} />
                <div className={'registerError'} style={{ minHeight: 20 }}>{errorMessage.lastName && <p>{errorMessage.lastName}</p>}</div>
              </div>
            </div>
            <p className={'label'}>About you</p>
            <InputTextarea value={userDetails.creatorDescription} onChange={(e) => handleChange('creatorDescription', e.target.value)} className={'inputField'} rows={6} maxLength={200} autoResize placeholder={"Type here..."} />

          </div>
        </div>
        <div className={'buttonLayer'}>
          <Button className={'saveButton'} onClick={() => handleSubmit()} >Save Changes</Button>
        </div>
      </div>
      <NewImageCropper visible={openCropper} onHide={() => setOpenCropper(false)} image={userDetails.creatorPhoto} setCroppedPhoto={(croppedPhoto) => handleChange('creatorPhoto', croppedPhoto)} />
    </Dialog>
  )
}
