
import Button from 'primevue/button';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    Button,
  },
  props: {
    isMobile: Boolean,
    view: String,
  },
  setup() {
    const router = useRouter();

    const handleRedirect = (type: string | undefined, path: string | undefined) => {
      if (type === 'page') router.push({ name: 'login' });
      else if (path) window.open(path,'_blank');
    };
    const services = ref([
      {
        image: '../../assets/platform-image-1.png',
        alt: 'Scalable Campaigns',
        title: 'Scalable Campaigns',
        description: 'Run performance-based CPM, CPC & CPI campaigns!',
        buttonText: 'START NOW',
        disabled: false,
        path: '/login',
        type: 'page',
      },
      {
        image: '../../assets/platform-image-2.png',
        alt: 'UGC Creator Ads',
        title: 'UGC Creator Ads',
        description:
          'Harnessing authenticity & engagement to amplify your brand story!',
        buttonText: 'Coming soon',
        disabled: false,
        path: 'https://gameinfluencer-webclient-bucket-prod.s3.eu-central-1.amazonaws.com/misc/Your+Ultimate+UGC+Playbook.pdf',
        type: 'pdf',
      },
      {
        image: '../../assets/platform-image-3.png',
        alt: 'Community Uplift',
        title: 'Community Uplift',
        description:
          'Establish and grow a vibrant community of creators on Discord & Steam!',
        buttonText: 'Coming soon',
        disabled: true,
      },
    ]);

    return {
      services,
      handleRedirect,
    };
  },
});
