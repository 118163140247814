
import { defineComponent, ref } from 'vue';
import Button from 'primevue/button';
import Password from 'primevue/password';
import InputText from 'primevue/inputtext';

export default defineComponent({
  name: 'EmailConnect',

  components: { Button, Password, InputText },

  props: {
    connecting: String,
    signIn: Boolean,
    register: Boolean,
  },

  setup(props: any, ctx: any) {
    const email = ref<string>('');
    const password = ref<string>('');

    const handleClick = () => {
      if (props.signIn) {
        ctx.emit('signIn', { email: email.value, password: password.value });
      }
      if (props.register) {
        ctx.emit('register', { email: email.value, password: password.value });
      }
    };

    return {
      email,
      password,
      handleClick,
    };
  },
});
