
import { defineComponent, ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { useContext } from '@/modules';
import { Perspective } from '@/data/types';

export default defineComponent({
  name: 'PageSearchBar',

  components: { Button, InputText },

  props: {
    defaultSearchType: {
      type: String,
      default: 'brands',
    },
    placeholder: {
      type: String,
      default: 'Search term',
    },
  },

  emits: ['search', 'clearSearch'],

  setup(props, { emit }) {
    const router = useRouter();
    const { perspective } = useContext();

    const searchTypesOptions = [];
    if (perspective.value === Perspective.BRAND) {
      searchTypesOptions.push({ label: 'Content Item', value: 'content-items' });
      searchTypesOptions.push({ label: 'Creators', value: 'creators' });
    } else {
      searchTypesOptions.push({ label: 'Brands', value: 'brands' });
      searchTypesOptions.push({ label: 'Campaigns', value: 'campaigns' });
    }
    const searchTypesList = ref(searchTypesOptions);
    const searchType = ref(props.defaultSearchType);
    const searchTerm = ref('');

    const startSearch = () => {
      emit('search', {
        term: searchTerm.value,
        type: searchType.value,
      });
    };

    onMounted(() => {
      let timeout: NodeJS.Timeout;
      window.addEventListener('searchValue-localstorage-changed', (event: any) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          searchTerm.value = event.detail.storage;
          startSearch();
        }, 1000);
      });
    });

    const clearSearch = () => {
      searchTerm.value = '';
      emit('clearSearch');
    };
    watch(searchType, () => {
      setTimeout(() => {
        router.push({ name: searchType.value });
      }, 10);
    });

    return {
      searchTypesList,
      searchTerm,
      searchType,
      clearSearch,
      startSearch,
    };
  },
});
