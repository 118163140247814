
import LatestCampaignItem from '@/pages/creators/latest/LatestCampaignItem.vue';

export default {
  name: 'LatestCampaigns',
  components: {
    LatestCampaignItem,
  },
  props: {
    limit: {
      type: Number,
      default: 4,
    },
    items: {
      type: Array,
    },
  },
  setup() {
    return {
    };
  },
};
