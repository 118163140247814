import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c18ac88"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  src: "/images/campaign-default.svg",
  alt: "",
  class: "default campaign"
}
const _hoisted_3 = {
  key: 2,
  class: "material-icons default"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["profile-photo", { [_ctx.type]: true }])
  }, [
    (_ctx.url && !_ctx.action)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.url,
          alt: "",
          referrerpolicy: "no-referrer",
          class: "real"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.url && !_ctx.action && _ctx.type === 'campaign')
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    (!_ctx.url && !_ctx.action)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.defaultIcon), 1))
      : _createCommentVNode("", true),
    (!_ctx.url && _ctx.action === 'add')
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass(["material-icons", { [_ctx.type]: true }])
        }, "add", 2))
      : _createCommentVNode("", true)
  ], 2))
}