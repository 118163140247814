<template>
  <div>
    <Dialog
      :visible="isShowing"
      class="p-col-12 p-md-6 p-xl-4 gi-dialog"
      :modal="true"
      :style="{ width: '550px' , height : '600px'} "
      @update:visible="$emit('update:isShowing', false)"
    >
      <template #header>
        <section class="p-d-flex p-flex-column p-p-6">
          <div>
            <h1 class="apc-success">{{campaignStatus}}</h1>
            <div class="page-image">
              <img class="box" src="./assets/images/title.svg">
              <img class="success" src="./assets/images/application-success.svg">
            </div>
            <div class="apc-sent">Your campaign {{statusMessage}}</div>
            <p class="apc-instruction">{{firstMessage}}
              <span class="apc-sent">{{campaignName}}</span>{{secondMessage}}</p>
            <button @click="closeModal()"
                    class="continue-button">OK</button>
          </div>
        </section>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import { ref } from 'vue';

export default {
  components: {
    Dialog,
  },
  props: {
    isShowing: Boolean,
    campaignName: String,
    campaignStatus: String,
    statusMessage: String,
    firstMessage: String,
    secondMessage: String,

  },
  setup(props, ctx) {
    const show = ref(props.isShowing);
    const showApply = ref(false);
    const closeModal = () => {
      show.value = false;
      ctx.emit('closeModalLogo');
    };
    return { showApply, closeModal, show };
  },
};
</script>
<style scoped lang="scss">

.page-image {
  position: relative;
  width: 250px;
  height: 180px;
  margin: 0 auto;
  .success {
    position: absolute;
    top: 90px;
    right: 15px;
    width: 55px;
    height: 55px;
  }
  .box {
    position: absolute;
    width: 200px;
    height: 140px;
    left: 20px;
  }
}

.apc-instruction{
  text-align: center;
  line-height: 17px;
  font-size:14px;
  margin-top: 70px;
}

.apc-sent{
  font-weight: 700 ;
  font-size: 16px;
  line-height: 19px;
  color: #253340;
  text-align: center
}

.apc-success {
  text-align: center;
}

.cpi-text {
  color: #253340;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.continue-button {
  border: none;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  width: 198px;
  height: 40.12px;
  background: #E0010B;
  box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 100px
}

.agreement {
  font-weight: 600;
  font-size: 12rem;

  a {
    color: $giRed;
    text-decoration: underline;
    font-weight: 700;

    &:hover {
      text-decoration: none;
    }
  }
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight ){
  background-color: #E0010B;
  color:#ffff;
}

</style>
