<template>
  <div class="fit">
    <div class="content">
      <h1 class="head-h1-creator">please add your creator name/alias in order to continue</h1>
      <InputText type="text" v-model="newUsername" placeholder="Enter new influencer name..."
                 class="w-100" :class="validNewUsername && 'error-input'"/>
      <p class="err-msg-creator-modal" v-if="validNewUsername">
        The use of the "@" symbol is prohibited.
      </p>

      <Button label="CONTINUE" :disabled="validNewUsername" icon="pi pi-check"
              @click="updateCreatorInfo" class="continue-btn-v"/>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { useAuth, useCreators } from '@/modules';

export default {
  components: {
    Button,
    InputText,
  },
  setup() {
    const { user } = useAuth();
    const { loadUser } = useAuth();
    const creatorsModule = useCreators();
    const displayVerifyModal = ref(true);
    const newUsername = ref();

    const updateCreatorInfo = () => {
      creatorsModule.manager.update(user.value?.id, { name: newUsername.value }).then(() => {
        loadUser();
        displayVerifyModal.value = false;
      });
    };
    const validNewUsername = computed(() => /@/gi.test(newUsername.value));

    return {
      newUsername,
      validNewUsername,
      updateCreatorInfo,
      displayVerifyModal,
    };
  },
};
</script>

<style scoped lang="scss">
.p-dialog {
  min-height: calc(100vh);
  background: white;
}

.fit {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .content {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .error-input {
    border-color: red !important;
  }

  .continue-btn-v {
    border: none;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    width: 198px;
    height: 40.12px;
    background: #E0010B;
    box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 50px;
  }

  .err-msg-creator-modal {
    color: red;
    margin-top: 5px;
  }

  .head-h1-creator {
    text-align: center;
    font-size: 25px;
  }
}
</style>
