import React, { useEffect, useState } from "react";

import { Image } from 'primereact/image';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { Paginator } from 'primereact/paginator';
import { DataTable } from 'primereact/datatable';

import { ExpandArrowDown, ExpandArrowUp } from '../img/Icons';
import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';

import "./NewEarnings.css";

const creatorTemplate = (data) => {
  return (
    <div className={'creatorTemplate'}>
      <Image src={data.photo} alt={'creator photo'} className={'creatorTemplatePhoto'}></Image>
      <p className={'creatorTemplateName'}>{data.name}</p>
    </div>
  )
};
const totalEarningsTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      $ {data.totalEarnings?.toFixed(2) || 0}
    </div>
  )
};
const totalPaidTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      $ {data.totalPaid?.toFixed(2) || 0}
    </div>
  )
};
const eligibleForPayoutTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      $ {data.amountEarnedThisCycle?.toFixed(2) || 0}
    </div>
  )
};
const gigaPayTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      {data.gigapayStatus ? 'Yes' : 'No'}
    </div>
  )
};
const rowCreatorTemplate = (row) => {
  return (<div className="p-d-flex p-align-center creatorTemplateName">
    <Avatar image={row?.creatorPhoto} className="p-mr-2 photo" />
    <span className="value">{row?.creatorName}</span>
  </div>)
}
const campaignTemplate = (row) => {
  return <div className="p-d-flex creatorTemplateName">
    <Avatar className="p-overlay-badge  p-mr-2 photo" image={row?.campaignPhoto} size="xlarge"></Avatar>
    <span className="value">{row?.campaignName}</span>
  </div>
}
const campaignTypeTemplate = (row) => {
  return (
    <div className="p-d-flex creatorTemplateName">
      <span className="value">{row?.campaignPaymentMethod}</span>
    </div>
  )
}
const campaignEarnedTemplate = (row) => {
  return (
    <div className="p-d-flex p-flex-column defaultTemplate">
      <span className="value">{row?.earned?.toFixed(2) || 0} USD</span>
      <div className="p-d-flex p-flex-column">
        <span>Android: {row?.earnedStats?.android || 0}</span>
        <span>IOS: {row?.earnedStats?.ios}</span>
        <span>Clicks: {row?.earnedStats?.clicks}</span>
      </div>
    </div>
  )
}
const campaignPaidTemplate = (row) => {
  return (
    <div className="p-d-flex p-flex-column defaultTemplate">
      <span className="value">{row?.paid?.toFixed(2) || 0} USD</span>
      <div className="p-d-flex p-flex-column">
        <span>Android: {row?.paidStats?.android || 0}</span>
        <span>IOS: {row?.paidStats?.ios || 0}</span>
        <span>Clicks: {row?.paidStats?.clicks || 0}</span>
      </div>
    </div>
  )
}
const rowExpansionTemplate = (rowData) => {
  return (
    <DataTable value={rowData?.links} className="w-100">
      <Column field="creatorName" header="Creator name" body={rowCreatorTemplate} />
      <Column field="campaignName" header="Campaign" body={campaignTemplate} />
      <Column field="campaignPaymentMethod" header="Payment Method" body={campaignTypeTemplate} />
      <Column field="earned" header="Earned" body={campaignEarnedTemplate} />
      <Column field="paid" header="Paid" body={campaignPaidTemplate} />
    </DataTable>
  )
}

export default function (props) {

  const { earnings, changePage, limit, loading } = props;

  const [first, setFirst] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    setNoResults(!(earnings?.creatorEarnings?.length))
  }, [earnings]);

  const expanderTemplate = (rowData) => {
    return (
      <div className='expanderTemplate'>
        {expandedRows.includes(rowData) ?
          <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
            <ExpandArrowUp />
          </div> :
          <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
            <ExpandArrowDown />
          </div>
        }
      </div>
    )
  }
  const onPageChange = (event) => {
    const { first } = event;
    setExpandedRows([])
    setFirst(first);
    changePage(first);
  };

  return (
    <div className={'earnings'}>
      <p className={'title'}>Earnings</p>
      <DataTable value={earnings?.creatorEarnings || []} emptyMessage="No earnings found" rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
        loading={loading} loadingIcon={<CustomLoader />} style={{ minHeight: 500 }} >
        <Column expander={true} body={expanderTemplate} />
        <Column field={"name"} header={"Creator"} sortable body={creatorTemplate} style={{ width: '20%' }}></Column>
        <Column field={"totalEarnings"} header={"Total Earnings"} sortable body={totalEarningsTemplate} ></Column>
        <Column field={"totalPaid"} header={"Paid Out"} sortable body={totalPaidTemplate} ></Column>
        <Column field={"amountEarnedThisCycle"} header={"Eligible for Payout"} sortable body={eligibleForPayoutTemplate}></Column>
        <Column field={"gigapayStatus"} header={"On Gigapay"} sortable body={gigaPayTemplate}></Column>
      </DataTable>
      {!noResults && <Paginator first={first} rows={limit} totalRecords={earnings?.maxCount || 0} onPageChange={onPageChange} style={{ background: '#130A23' }} />}
    </div>
  )
}
