import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewMyCampaigns = _resolveComponent("NewMyCampaigns")!
  const _component_OldMyCampaigns = _resolveComponent("OldMyCampaigns")!

  return (_ctx.isNewMyContentPageEnabled)
    ? (_openBlock(), _createBlock(_component_NewMyCampaigns, {
        key: 0,
        counters: _ctx.counters,
        newCampaignClick: _ctx.newCampaignClick,
        filteredCampaigns: _ctx.filteredCampaigns,
        selectStatusCategory: _ctx.selectStatusCategory,
        goBack: _ctx.goBack,
        searchValueChange: _ctx.searchValueChange,
        goToCampaign: _ctx.goToCampaign,
        routeTo: _ctx.routeTo
      }, null, 8, ["counters", "newCampaignClick", "filteredCampaigns", "selectStatusCategory", "goBack", "searchValueChange", "goToCampaign", "routeTo"]))
    : (_openBlock(), _createBlock(_component_OldMyCampaigns, { key: 1 }))
}