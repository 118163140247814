
import { defineComponent } from 'vue';
import AskHint from '@/pages/creators/utils/AskHint.vue';

export default defineComponent({
  name: 'CampaignsCounterItemNew',
  components: { AskHint },
  props: {
    name: {
      type: String,
      default: '',
    },
    counter: {
      type: String,
      default: '',
    },
  },
});
