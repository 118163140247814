
import {
  defineComponent,
  watch, ref, reactive,
} from 'vue';
import { useRouter } from 'vue-router';
import { useCampaigns, usePage } from '@/modules';
import {
  targetMarkers,
  regionsOptions,
  paymentMethodsOptions,
  targetMarkets,
  paginationStep,
  clearLocalstorageForCreateCampaign,
  parseTypeIcons,
} from './data.campaign';
import { AppPageConfig, CampaignPaymentMethod } from '@/data/types';
import { DEFAULT_CAMPAIGN_FEE } from '@/utils/globals/cpi-rate';
import { applyPureReactInVue } from 'veaury';
import { isNewCreateCampaignEnabled } from '@/utils/globals/feature-flags';
import NewCreateCampaign from "@/react_app/NewCreateCampaign/NewCreateCampaign";
import OldCreateCampaign from "@/pages/campaigns/create/OldCreateCampaign.vue";

export default defineComponent({
  components: {
    NewCreateCampaign: applyPureReactInVue(NewCreateCampaign),
    OldCreateCampaign
  },
  props: {
    campaignID: String,
    campaign: Object,
  },

  setup(props, ctx) {
    const router = useRouter();
    const selected = ref(0);
    const showSuccessModal = ref(false);
    const searchValueMarket = ref('');
    const displayModalMarket = ref(false);
    const createCampaignStep = ref(0);
    const paginationSteeps = ref(0);
    const selectedMarket = ref(props.campaign ? props.campaign.targetMarket : null);
    const displayMarketModal = ref(false);
    const countProgress = ref(0);
    const showVerifyPaymentModal = ref(false);
    const marketModalInfo = ref(props.campaign && props.campaign.desiredRegions?.length);
    const change = ref(false);
    const endDateCampaign = ref();
    const showInfoModal = ref(false);
    const selectedPaymentMethodX = ref(localStorage.getItem('PaymentMethod') || '');
    watch(selectedPaymentMethodX, () => {
      localStorage.setItem('PaymentMethod', selectedPaymentMethodX.value);
    });

    const progressCampaign: any = reactive({
      campaignName: props.campaign ? props.campaign.name : '',
      photo: props.campaign ? props.campaign.photo : '',
      targetMarkets: props.campaign ? props.campaign.targetMarket : {},
      desiredRegions: props.campaign && props.campaign.desiredRegions.length ? props.campaign.desiredRegions[0] : [],

      productName: props.campaign ? props.campaign.productName : '',
      productUrls: props.campaign ? props.campaign.productUrls : [],
      talkingLink: props.campaign ? props.campaign.trackingLink : '',
      gamekeys: props.campaign ? props.campaign.gamekeys : '',

      talkingPoints: props.campaign ? props.campaign.talkingPoints : [],
      callToAction: props.campaign ? props.campaign.callToAction : '',
      attachments: props.campaign ? props.campaign.attachments : [],

      budget: props.campaign ? props.campaign : {
        amount: 0,
        currency: '',
      },
      cpi: props.campaign ? props.campaign.cpi : {
        ios: { amount: 0 },
        android: { amount: 0 }
      },
      cpc: props.campaign ? props.campaign.cpc : { amount: 0 },
      cpm: props.campaign ? props.campaign.cpm : { amount: 0, payoutCap: 0 },
      paymentMethod: selectedPaymentMethodX.value,
      paymentInformation: props.campaign ? props.campaign.paymentInformation : {},
      appliedFee: DEFAULT_CAMPAIGN_FEE,
    });

    const TotalProgressCountObjects = reactive({
      targetMarkets: 0,
      productName: 0,
      productUrls: 0,
      talkingLink: 0,
      talkingPoints: 0,
      callToAction: 0,
      attachments: 0,
      budget: 0,
      paymentInformationDone: 0,
      gamekeys: 0,
      photo: 0,
    });

    const CountProgress = ref(0);
    const totalProgress = ref(0);
    watch(TotalProgressCountObjects, () => {
      CountProgress.value = 0;
      totalProgress.value = 0;
      for (const [key, value] of Object.entries(TotalProgressCountObjects)) {
        totalProgress.value += 1;
        if (value === 1) {
          CountProgress.value += 1;
        }
      }
    });
    const countingProgressProduct = (
        name: string,
        talkingLink: string,
        productUrls: Array<{ value: string, type: string }>,
        gamekeys: string,
    ) => {
      progressCampaign.productName = name;
      if (name.length > 3) {
        TotalProgressCountObjects.productName = 1;
      } else {
        TotalProgressCountObjects.productName = 0;
      }
      progressCampaign.talkingLink = talkingLink;
      if (talkingLink.length > 3) {
        TotalProgressCountObjects.talkingLink = 1;
      } else {
        TotalProgressCountObjects.talkingLink = 0;
      }
      progressCampaign.productUrls = productUrls;
      const valid = productUrls?.filter((el) => el?.value?.length > 3);
      if (valid?.length) {
        TotalProgressCountObjects.productUrls = 1;
      } else {
        TotalProgressCountObjects.productUrls = 0;
      }
      progressCampaign.gamekeys = gamekeys;
      if (gamekeys) {
        TotalProgressCountObjects.gamekeys = 1;
      } else {
        TotalProgressCountObjects.gamekeys = 0;
      }
    };
    const isMinimalBudgetRef = ref(false);
    const isMinimalPayoutCap = ref(false);
    watch(() => progressCampaign.cpm.payoutCap, () => {
      if (progressCampaign.paymentMethod === CampaignPaymentMethod.CPM && progressCampaign?.cpm?.payoutCap
          && progressCampaign?.cpm?.amount) {
        isMinimalPayoutCap.value = progressCampaign.cpm.payoutCap < progressCampaign.cpm.amount;
      }
    });
    watch(() => progressCampaign.cpm.amount, () => {
      if (progressCampaign.paymentMethod === CampaignPaymentMethod.CPM && progressCampaign?.cpm?.payoutCap
          && progressCampaign?.cpm?.amount) {
        isMinimalPayoutCap.value = progressCampaign.cpm.payoutCap < progressCampaign.cpm.amount;
      }
    });

    const budgetSteep = (
        budget: number,
        verifyPaymentInfo: boolean,
        selectedCurrency: string,
        cpiIos: any,
        cpiAndroid: any,
        selectedPaymentMethod: string,
        cpcValue: any,
        cpmValue: number,
        cpmPaymentCap: number,
        isMinimalBudget: any,
    ) => {
      progressCampaign.budget.amount = budget;
      progressCampaign.budget.currency = selectedCurrency;
      showVerifyPaymentModal.value = verifyPaymentInfo;

      if (selectedPaymentMethod === CampaignPaymentMethod.CPI) {
        progressCampaign.cpi.ios.amount = cpiIos;
        progressCampaign.cpi.android.amount = cpiAndroid;
        if (budget > 0 && cpiIos > 0 && cpiAndroid > 0) {
          TotalProgressCountObjects.budget = 1;
        } else {
          TotalProgressCountObjects.budget = 0;
        }
      }
      if (selectedPaymentMethod === CampaignPaymentMethod.CPC) {
        progressCampaign.cpc.amount = cpcValue;
        if (budget > 0 && cpcValue > 0) {
          TotalProgressCountObjects.budget = 1;
        } else {
          TotalProgressCountObjects.budget = 0;
        }
      }
      if (selectedPaymentMethod === CampaignPaymentMethod.CPM) {
        progressCampaign.cpm.amount = cpmValue;
        progressCampaign.cpm.payoutCap = cpmPaymentCap;
      }
      isMinimalBudgetRef.value = isMinimalBudget;
    };
    const changeInstruction = (valueCallToActionInput: string, talkingPoints: any, attachment: any) => {
      progressCampaign.callToAction = valueCallToActionInput;
      if (valueCallToActionInput.length > 3) {
        TotalProgressCountObjects.callToAction = 1;
      } else {
        TotalProgressCountObjects.callToAction = 0;
      }
      progressCampaign.talkingPoints = talkingPoints;
      if (talkingPoints.length) {
        TotalProgressCountObjects.talkingPoints = 1;
      } else {
        TotalProgressCountObjects.talkingPoints = 0;
      }
      if (attachment) {
        progressCampaign.attachments.push(attachment);
        TotalProgressCountObjects.attachments = 1;
      }
    };

    const paymentInfoChange = (payload: any) => {
      progressCampaign.paymentInformation = payload;
      TotalProgressCountObjects.paymentInformationDone = 1;
    };

    const selectMarket = (market: any) => {
      if (selected.value <= 0) {
        return;
      }
      selectedMarket.value = market;
      progressCampaign.targetMarkets = market;
      displayModalMarket.value = false;
      TotalProgressCountObjects.targetMarkets = 1;
    };
    const { config } = usePage<AppPageConfig>({
      title: 'create campaign',
    });
    const page = useCampaigns(config?.value?.viewPerspective);
    const enD = new Date();
    enD.setDate(enD.getDate() + 30);

    const createDraft = async (pushRouter: boolean) => {
      const syncMethod = localStorage.getItem('PaymentMethod');
      if (progressCampaign.paymentMethod !== syncMethod) {
        progressCampaign.paymentMethod = syncMethod;
      }
      const dates: { startDate: Date; endDate?: Date } = {
        startDate: new Date(),
      };
      if (endDateCampaign.value) {
        dates.endDate = endDateCampaign.value;
      }
      const createdDraft = await page.manager.create({
        name: progressCampaign.campaignName,
        budget: {
          amount: +progressCampaign.budget.amount,
          currency: progressCampaign.budget.currency,
        },
        dates,
        targetMarket: progressCampaign.targetMarkets,
        desiredRegions: progressCampaign.desiredRegions,
        productName: progressCampaign.productName,
        productUrls: progressCampaign.productUrls,
        callToAction: progressCampaign.callToAction,
        talkingPoints: progressCampaign.talkingPoints,
        trackingLink: progressCampaign.talkingLink,
        // attachments: progressCampaign.attachments,
        gamekeys: progressCampaign.gamekeys,
        cpi: progressCampaign.cpi,
        cpc: progressCampaign.cpc,
        cpm: progressCampaign.cpm,
        paymentMethod: progressCampaign.paymentMethod,
        photo: progressCampaign.photo,
        paymentInformation: progressCampaign.paymentInformation,
        appliedFee: DEFAULT_CAMPAIGN_FEE,
      });
      if (progressCampaign?.attachments?.length) {
        const formData = new FormData();
        for await (const file of progressCampaign?.attachments) {
          formData.set('files[]', file);
          await page.manager.attachments(createdDraft?.id as string, formData);
        }
      }
      clearLocalstorageForCreateCampaign();
      if (pushRouter) {
        await router.push({
          name: 'campaigns-stats',
          params: {
            campaignID: createdDraft?.id as string,
          },
        });
      }
      return createdDraft;
    };
    const filterMarkets = () => {
      const regExp = new RegExp(searchValueMarket.value, 'gi');
      targetMarkets.targetMarkets = targetMarkets.targetMarkets.filter((market) => regExp.test(market.title));
    };

    const showPaymentModal = ref(false);
    const createdCamp = ref();
    const loadingCreate = ref(false);
    const launchCampaign = async () => {
      loadingCreate.value = true;
      if (!props.campaign) {
        await createDraft(false).then(async (createdCampaign) => {
          if (createdCampaign?.id) {
            await page.manager.pending(createdCampaign?.id);
            showSuccessModal.value = true;
            createdCamp.value = createdCampaign;
            await router.push({
              name: 'campaigns-stats',
              params: {
                campaignID: createdCampaign.id,
              },
            });
          }
        });
      }
      clearLocalstorageForCreateCampaign();
      loadingCreate.value = false;
    };
    const saveCampaign = async () => {
      await page.manager.update(props.campaign?.id, {
        name: progressCampaign.campaignName,
        budget: {
          amount: +progressCampaign.budget.amount,
          currency: progressCampaign.budget.currency,
        },
        targetMarket: progressCampaign.targetMarkets,
        desiredRegions: progressCampaign.desiredRegions,
        productName: progressCampaign.productName,
        productUrls: progressCampaign.productUrls,
        callToAction: progressCampaign.callToAction,
        talkingPoints: progressCampaign.talkingPoints,
        trackingLink: progressCampaign.talkingLink,
        attachments: progressCampaign.attachments,
        gamekeys: progressCampaign.gamekeys,
        cpi: progressCampaign.cpi,
        cpc: progressCampaign.cpc,
        cpm: progressCampaign.cpm,
        paymentMethod: progressCampaign.paymentMethod,
        dates: {
          startDate: new Date(),
          endDate: enD,
        },
        photo: progressCampaign.photo,
        paymentInformation: progressCampaign.paymentInformation,
      });
      clearLocalstorageForCreateCampaign();
      ctx.emit('saveEdit');
    };
    const goToNewCampaign = async () => {
      await router.push({ name: 'campaign', params: { campaignID: createdCamp.value.id } });
    };
    const changePhotos = (newLogo: { newLogo: string }) => {
      progressCampaign.photo = newLogo?.newLogo;
      if (progressCampaign.photo) {
        TotalProgressCountObjects.photo = 1;
      } else {
        TotalProgressCountObjects.photo = 1;
      }
    };

    const campaignPaySuccess = () => {
      showPaymentModal.value = false;
      showSuccessModal.value = true;
    };

    const marketModal = (active: number) => {
      progressCampaign.desiredRegions = regionsOptions[active];
      displayMarketModal.value = false;
      marketModalInfo.value = true;
    };

    const handleReturn = () => {
      router.push({ name: 'my-campaigns' });
    }

    const pushDraft = async (createdDraft: any) => {
      await router.push({
        name: 'campaigns-stats',
        params: {
          campaignID: createdDraft?.id as string,
        },
      });
    }

    const saveEdit = () => {
      ctx.emit('saveEdit');
    }

    return {
      enD,
      showInfoModal,
      endDateCampaign,
      change,
      TotalProgressCountObjects,
      CountProgress,
      totalProgress,
      marketModalInfo,
      showVerifyPaymentModal,
      countProgress,
      progressCampaign,
      paginationSteeps,
      paginationStep,
      createCampaignStep,
      regionsOptions,
      displayMarketModal,
      searchValueMarket,
      targetMarkers,
      parseTypeIcons,
      selectedMarket,
      selected,
      displayModalMarket,
      showSuccessModal,
      createdCamp,
      showPaymentModal,
      loadingCreate,
      selectedPaymentMethodX,
      paymentMethodsOptions,
      isMinimalBudgetRef,
      isMinimalPayoutCap,
      campaignPaySuccess,
      marketModal,
      selectMarket,
      filterMarkets,
      launchCampaign,
      goToNewCampaign,
      createDraft,
      changePhotos,
      countingProgressProduct,
      budgetSteep,
      changeInstruction,
      paymentInfoChange,
      targetMarkets,
      saveCampaign,
      isNewCreateCampaignEnabled,
      handleReturn,
      pushDraft,
      saveEdit
    };
  },
});
