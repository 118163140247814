
import { ref, watch } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { useAPI, useCampaigns } from '@/modules';
import { CampaignData } from '@/data/types';
import SecretTip from '../create/SecretTip.vue';
import CropperImage from './CropperImage.vue';

export default {
  name: 'ChangeCampaignLogo',
  props: {
    showChangeLogoModal: Boolean,
    mainImage: String,
    previousPhoto: Array,
    campaignId: String,
  },
  components: { SecretTip, Dialog, Button, CropperImage },
  setup(props: any, ctx: any) {
    const showModal = ref();
    const uploadPhotoInput = ref();
    const allPhotos = ref();
    const activeIndex = ref();
    const activeDefault = ref(false);
    const cropper = ref();
    const showCrop = ref(false);
    watch(() => props.showChangeLogoModal, () => {
      showModal.value = props.showChangeLogoModal;
    })

    allPhotos.value = props.previousPhoto ? [props.previousPhoto] : [];
    if (props.mainImage !== '/images/defaultLogoCampaign.png' && props.mainImage) {
      allPhotos.value.unshift(props.mainImage);
      activeIndex.value = 0;
    } else {
      activeDefault.value = true;
      activeIndex.value = false;
    }

    const {
      execute: sendChangeLogoRequest,
    } = useAPI<CampaignData>('', false);
    const changeLogo = (campaignId: string, base64Data: string) => sendChangeLogoRequest({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `/campaigns/logo/${campaignId}`,
      method: 'POST',
      data: { base64Data },
    });
    const closeModal = () => {
      ctx.emit('close');
    };
    const page = useCampaigns();
    const removePhoto = (index: any) => {
      allPhotos.value.splice(index, 1);
      page.manager.removePreviewLogo(props.campaignId?.toString(), index); // for remove photo
    };
    const saveChanges = () => {
      if (activeDefault.value) {
        ctx.emit('changePhoto', { newLogo: '/images/defaultLogoCampaign.png', allPhotos: allPhotos.value });
      } else {
        const newLogo = allPhotos.value[activeIndex.value];
        changeLogo(props.campaignId, newLogo).then((url) => {
          ctx.emit('changePhoto', { newLogo: url, allPhotos: allPhotos.value });
        });
      }
      closeModal();
    };

    const changeCampaignPfoto = async () => {
      const reader = new FileReader();
      reader.onload = function(){
        allPhotos.value.push(reader.result);
      };
      reader.readAsDataURL(uploadPhotoInput.value.files[0]);
    };

    const cropMainImage = (dataUrl: string) => {
      allPhotos.value[activeIndex.value] = dataUrl;
      showCrop.value = false;
    };

    const activePreview = (index: any) => {
      activeIndex.value = index;
      activeDefault.value = false;
    };
    return {
      activeIndex,
      uploadPhotoInput,
      showCrop,
      cropper,
      showModal,
      saveChanges,
      closeModal,
      cropMainImage,
      changeCampaignPfoto,
      allPhotos,
      activePreview,
      removePhoto,
      activeDefault,
    };
  },
};
