import { AppPagePerspective } from '@/data/types';
import { useAdminsManager } from './admins-manager-module';

export const useAdmins = (perspective?: AppPagePerspective) => {
  const manager = useAdminsManager(perspective);

  return {
    manager,
  };
};
