
import { defineComponent, ref } from 'vue';
import { useAuth, useNotificationsMethods } from '@/modules';
import { useSocketIo, useSocketMethods } from '@/services/socket';
import InputText from 'primevue/inputtext';

export default defineComponent({
  name: 'inboxNotifications',
  components: {
    InputText,
  },
  setup() {
    const { user } = useAuth();
    const notificationsMethods = useNotificationsMethods();
    const userId = ref();
    const searchInput = ref('');
    const notificationsForUser = ref<any[]>([]);
    if (user.value) {
      userId.value = user.value?.id;
    }
    const socket = useSocketIo(userId.value as string);
    const socketMethods = useSocketMethods(socket);

    const getMoreNotifications = async (firstLoad = false) => {
      if (!firstLoad) {
        // skip.value += 5;
        // limit.value += 5;
      }
      socketMethods.getNotificationsUser(5, 0);
      socket.on('notifications-data', (data: any) => {
        notificationsForUser.value = data.filter((el: any) => el.type === 'invite');
      });
    };
    getMoreNotifications();
    return {
      notificationsForUser,
      notificationsMethods,
      searchInput,
    };
  },
});
