
import {computed, defineComponent, ref, watch} from 'vue';
import {AppPageProps, Perspective} from '@/data/types';
import {useAuth, useContentItems} from '@/modules';
import {useRoute} from 'vue-router';
import {compactNumber} from '../../../utils/globals/curency-utils';
import {applyPureReactInVue} from 'veaury';
import {isContentItemsPageEnabled} from '@/utils/globals/feature-flags';
import OldContentItem from "./OldContentItem.vue";
import NewContentItem from "@/react_app/NewContentItem/NewContentItem.jsx";
import router from "@/router";

export default defineComponent({
  name: 'ContentItem',
  components: {
    NewContentItem: applyPureReactInVue(NewContentItem),
    OldContentItem
  },
  props: {
    itemID: String,
    creatorID: String,
    ...AppPageProps,
  },

  setup(props) {
    const previewPhoto = ref(true);
    const route = useRoute();
    const activeTab = ref(0);
    const { user } = useAuth();
    const content = useContentItems();


    watch(() => route.params.itemID, () => {
      // advanced Fetching After Navigation
      if (route.params.itemID) {
          content.manager.loadSingle(route.params.itemID as string).then((data) => {
              content.list.loadAllPublicContentCreator(data?.owner?.id as string,8);
            });
      }
    }, { immediate: true });

    const toggleItemVisibility = () => {
      if (props.itemID) {
        content.manager.update(props.itemID, { public: !content.manager.singleItem.value?.public });
      }
    };

    const copyLink = (url: any) => {
      navigator.clipboard.writeText(url);
    };
    const itemIsMine = computed(() => user?.value?.id === content.manager.singleItem.value?.owner.id);

    const handleReturn = () => {
      if (props.viewPerspective === Perspective.BRAND) {
        router.push({ name: 'content-items' });
      }
      else {
        router.push({ name: 'my-content-items' });
      }
    }

    const goToCreatorProfile = (id: string) => router.push({ path: `/app/creators/${id}` });

    const goToOtherContentItem = (id: string) => {
      window.location.href = `/app/content-items/${id}`;
    }

    return {
      previewPhoto,
      activeTab,
      content,
      loading: content.manager.loadingSingle,
      item: content.manager.singleItem,
      itemIsMine,
      // licensing
      // showRequestLicenseModal,
      // sendLicenseRequest,
      // visibility
      toggleItemVisibility,
      compactNumber,
      copyLink,
      // all user content public
      publicContentCreator: content.list.loadAllPublicContentCreatorResult,
      isContentItemsPageEnabled,
      handleReturn,
      goToCreatorProfile,
      goToOtherContentItem,
      user
    };
  },
});
