import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-mb-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ContentItemsList = _resolveComponent("ContentItemsList")!
  const _component_app_page = _resolveComponent("app-page")!

  return (_openBlock(), _createBlock(_component_app_page, {
    config: _ctx.config,
    loading: _ctx.loading,
    onSearch: _ctx.performSearch
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_ctx.private && false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_Button, {
                label: "Sync Youtube Videos",
                class: "p-button-danger gi-button p-text-uppercase",
                loading: _ctx.syncing,
                onClick: _ctx.sync
              }, null, 8, ["loading", "onClick"])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_ContentItemsList, {
              key: 1,
              items: _ctx.contentItems
            }, null, 8, ["items"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["config", "loading", "onSearch"]))
}