import React, { useState } from 'react';
import "./NewRestorePasswordDialog.css";
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from 'primereact/password';
import { Image } from 'primereact/image';
import { useAuth } from "@/modules";
import success from "../img/success.png";
import { emailRegex } from "@/utils/globals/regex";
import { minPasswordLength } from "@/utils/globals/constants";

export default function(props){
  const authModule = useAuth();

  const [email, setEmail] = useState('');
  const [restoreCode, setRestoreCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState('');
  const [existEmail, setExistEmail] = useState(false);
  const [stepRestore, setStepRestore] = useState(1);

  const sendCode = () => {
    if (emailRegex.test(email)) {
      authModule.restorePassword(email, 'create-code').then((d) => {
        if (d !== 'invalid email') {
          setStepRestore(2);
          setExistEmail(false);
        } else {
          setExistEmail(true);
        }
      });
    }
  };

  const checkAndChangePassword = () => {
    if (newPassword.length < minPasswordLength) {
      setPasswordError(`Password is less than ${minPasswordLength} characters.`);
    } else if (newPassword !== newPasswordConfirm) {
      setPasswordError('Passwords do not match.');
    } else {
      setPasswordError('');
      authModule.restorePassword(email, 'check-code', parseInt(restoreCode), newPassword).then((data) => {
        if (data === 'The verification code is invalid') {
          setVerificationCodeError(data);
        } else {
          setStepRestore(3);
        }
      });
    }
  };

  return(
    <Dialog className={'restore-password-dialog'} visible={props.visible} onHide={props.onHide}>
      {stepRestore === 1 &&
        <div className={'stepRestoreOne'}>
          <p className={'restorePassword'}>RESTORE PASSWORD</p>
          <p className={'restorePasswordText'}>Enter your e-mail address, you will receive a one-time code to reset your password.</p>
          <InputText placeholder={"Your e-mail"} className={'inputField'} onChange={(e) => { setEmail(e.target.value); setExistEmail(false); }}/>
          {!emailRegex.test(email) && <p className={'invalidMessage'}>This field must be a valid email address.</p>}
          {(emailRegex.test(email) && existEmail) && <p className={'invalidMessage'}>No user found with this email.</p>}
          <Button disabled={!emailRegex.test(email)} className={'sendCodeButton'} onClick={sendCode}>SEND THE CODE</Button>
        </div>
      }
      {stepRestore === 2 &&
        <div>
          <p className={'restorePassword'}>RESTORE PASSWORD</p>
          <p className={'inputFieldLabel'}>RESTORE CODE</p>
          <InputText className={'inputField'} value={restoreCode} onChange={(e) => {setRestoreCode(e.target.value); setVerificationCodeError('');}}/>
          {verificationCodeError && <p className={'passwordError'}>{verificationCodeError}</p>}
          <p className={'helperText'}>This is the six-digit verification code that was sent to your email address.</p>
          <div className={'newPassword'}>
            <div>
              <p className={'inputFieldLabel'}>NEW PASSWORD</p>
              <Password value={newPassword} onChange={(e) => setNewPassword(e.target.value)} toggleMask feedback={false}/>
            </div>
            <div>
              <p className={'inputFieldLabel'}>CONFIRM NEW PASSWORD</p>
              <Password value={newPasswordConfirm} onChange={(e) => setNewPasswordConfirm(e.target.value)} toggleMask feedback={false}/>
            </div>
          </div>
          {passwordError && <p className={'passwordError'}>{passwordError}</p>}
          <p className={'helperText'}>Password must be at least 8 characters long and must be confirmed.</p>
          <Button className={'sendCodeButton'} onClick={checkAndChangePassword}>CHANGE PASSWORD</Button>
        </div>
      }
      {stepRestore === 3 &&
        <div className={'stepRestoreThree'}>
          <Image src={success} alt={'success'}/>
          <p className={'successMessage'}>Your password has been successfully changed!</p>
          <Button className={'okButton'} onClick={props.onHide}>OK</Button>
        </div>
      }
    </Dialog>
  )
}
