
import { ref, watch } from 'vue';
import InputText from 'primevue/inputtext';
import { onClickOutside } from '@vueuse/core';
import { useRouter } from 'vue-router';

export default {
  name: 'SearchBarwithBrowse',
  components: {
    InputText,
  },
  props: {
    isCampaignPage: Boolean,
    user: Object,
  },
  setup() {
    const router = useRouter();
    const showBrowsePopular = ref(false);
    const searchAnythingValueInput = ref('');
    const browsePopular = ref(localStorage.getItem('browsePopular') || '');

    watch(searchAnythingValueInput, () => {
      // eslint-disable-next-line no-nested-ternary,max-len,vue/max-len
      router.push({ name: browsePopular.value === 'Influencers' ? 'creators' : '' || browsePopular.value === 'Campaigns' ? 'campaigns' : ''
        || browsePopular.value === 'Content' ? 'my-content-items' : '' });
      localStorage.setItem('searchValue', searchAnythingValueInput.value);
      window.dispatchEvent(new CustomEvent('searchValue-localstorage-changed', {
        detail: {
          storage: localStorage.getItem('searchValue'),
        },
      }));
    });

    const target = ref(null);
    onClickOutside(target, () => {
      showBrowsePopular.value = false;
    });
    return { showBrowsePopular, searchAnythingValueInput, target, browsePopular };
  },
};
