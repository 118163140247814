
import { computed, toRefs } from 'vue';

export default {
  name: 'CampaignBrandHeader',
  props: {
    brand: {
      type: Object,
    },
  },
  setup(props: any) {
    const { brand }: any = toRefs(props);
    const photo = computed(() => (brand.value?.photo && brand.value?.photo?.trim() !== ''
    // eslint-disable-next-line global-require
      ? props.brand?.photo : require('@/assets/images/brand/brand-default.svg')));
    return {
      photo,
    };
  },
};
