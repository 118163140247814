
import { defineComponent, ref } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useAuth } from '@/modules';

export default defineComponent({
  components: {
    Button,
    Dialog,
  },

  props: {
    show: Boolean,
  },
  setup(props, ctx) {
    const { user } = useAuth();
    const closeModal = () => {
      ctx.emit('update:show', false);
      ctx.emit('showContent');
    };
    const nextTutorials = ref(0);
    const wizzardOverlaySteps = ref(1);
    const showNextTutorials = () => {
      // eslint-disable-next-line no-plusplus
      nextTutorials.value++;
      wizzardOverlaySteps.value = 0;
    };
    const wizzFunc = () => {
      // eslint-disable-next-line no-plusplus
      wizzardOverlaySteps.value++;
    };
    const image = ref('https://files.slack.com/files-pri/T3H5T5DGD-F04AVH8PUHK/thumbnail-campaign-submit.png');
    return {
      image,
      closeModal,
      user,
      wizzardOverlaySteps,
      wizzFunc,
      showNextTutorials,
      nextTutorials,
    };
  },
});
