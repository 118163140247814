import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2aeda78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-flex-column w-100" }
const _hoisted_2 = { class: "campaign-list-counters" }
const _hoisted_3 = { class: "p-mb-4 p-mt-6 w-100" }
const _hoisted_4 = { class: "p-d-flex p-justify-between" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "campaign-name p-text-bold" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "campaign-name p-text-bold" }
const _hoisted_9 = { class: "actions-btns" }
const _hoisted_10 = { class: "LegalRepresentativeAddress" }
const _hoisted_11 = { class: "LegalRepresentativeAddress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_campaigns_counter_item = _resolveComponent("campaigns-counter-item")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_campaigns_counter_item, {
        name: 'Creators',
        counter: `${_ctx.creatorsCounts}`
      }, null, 8, ["counter"]),
      _createVNode(_component_campaigns_counter_item, {
        name: 'Brands',
        counter: `${_ctx.brandsCount}`
      }, null, 8, ["counter"]),
      _createVNode(_component_campaigns_counter_item, {
        name: 'Admins',
        counter: `${_ctx.adminsCount}`
      }, null, 8, ["counter"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_InputText, {
          type: "text",
          placeholder: "Search for Campaign",
          modelValue: _ctx.searchValueCampaign,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValueCampaign) = $event)),
          style: {"width":"80%"},
          class: "p-mr-3 search-input",
          onInput: _ctx.searchBrands,
          onKeydown: _withKeys(_ctx.searchBrands, ["enter"])
        }, null, 8, ["modelValue", "onInput", "onKeydown"]),
        _createVNode(_component_Button, {
          class: "search-bth",
          label: "search",
          onClick: _ctx.searchBrands
        }, null, 8, ["onClick"])
      ])
    ]),
    _createVNode(_component_DataTable, {
      value: _ctx.brandsList?.brands,
      responsiveLayout: "scroll",
      class: "p-mt-6 w-100",
      expandedRows: _ctx.expandedRows,
      "onUpdate:expandedRows": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.expandedRows) = $event))
    }, {
      empty: _withCtx(() => [
        _createTextVNode("No data found.")
      ]),
      expansion: _withCtx(({ data }) => [
        _createVNode(_component_DataTable, {
          value: data?.uniquePayments?.length ? [data?.uniquePayments[0]] : [],
          responsiveLayout: "scroll",
          class: "p-mt-6 w-100"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              header: "Names",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(() => [
                _createTextVNode(_toDisplayString(data?.uniquePayments?.map((el) => el?.name)?.join(', ')), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "LegalRepresentativeAddress",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("h6", null, [
                    _createTextVNode("AddressLine1: "),
                    _createElementVNode("span", null, _toDisplayString(data?.paymentInformation?.LegalRepresentativeAddress?.AddressLine1), 1)
                  ]),
                  _createElementVNode("h6", null, [
                    _createTextVNode("City: "),
                    _createElementVNode("span", null, _toDisplayString(data?.paymentInformation?.LegalRepresentativeAddress?.City), 1)
                  ]),
                  _createElementVNode("h6", null, [
                    _createTextVNode("PostalCode: "),
                    _createElementVNode("span", null, _toDisplayString(data?.paymentInformation?.LegalRepresentativeAddress?.PostalCode), 1)
                  ]),
                  _createElementVNode("h6", null, [
                    _createTextVNode("Country: "),
                    _createElementVNode("span", null, _toDisplayString(data?.paymentInformation?.LegalRepresentativeAddress?.Country), 1)
                  ])
                ])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "PersonType",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.PersonType), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "LegalPersonType",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.LegalPersonType), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "Name",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.Name), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "Birthday",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.Birthday), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "CountryOfResidence",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.CountryOfResidence), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "Nationality",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.Nationality), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "FirstName",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.FirstName), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "LastName",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.LastName), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["value"]),
        _createVNode(_component_DataTable, {
          value: data?.nonUniquePayments,
          responsiveLayout: "scroll",
          class: "p-mt-6 w-100"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              header: "Name",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.name), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "LegalRepresentativeAddress",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("h6", null, [
                    _createTextVNode("AddressLine1: "),
                    _createElementVNode("span", null, _toDisplayString(data?.paymentInformation?.LegalRepresentativeAddress?.AddressLine1), 1)
                  ]),
                  _createElementVNode("h6", null, [
                    _createTextVNode("City: "),
                    _createElementVNode("span", null, _toDisplayString(data?.paymentInformation?.LegalRepresentativeAddress?.City), 1)
                  ]),
                  _createElementVNode("h6", null, [
                    _createTextVNode("PostalCode: "),
                    _createElementVNode("span", null, _toDisplayString(data?.paymentInformation?.LegalRepresentativeAddress?.PostalCode), 1)
                  ]),
                  _createElementVNode("h6", null, [
                    _createTextVNode("Country: "),
                    _createElementVNode("span", null, _toDisplayString(data?.paymentInformation?.LegalRepresentativeAddress?.Country), 1)
                  ])
                ])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "PersonType",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.PersonType), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "LegalPersonType",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.LegalPersonType), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "Name",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.Name), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "Birthday",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(data?.paymentInformation?.Birthday)), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "CountryOfResidence",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.CountryOfResidence), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "Nationality",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.Nationality), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "FirstName",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.FirstName), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_Column, {
              header: "LastName",
              field: "id",
              sortable: false
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data?.paymentInformation?.LastName), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["value"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "brand",
          header: "Brand",
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("section", {
              class: "p-d-flex p-align-center",
              onClick: ($event: any) => (_ctx.expandItem(data))
            }, [
              (data && data?.photo)
                ? (_openBlock(), _createBlock(_component_Avatar, {
                    key: 0,
                    image: data?.photo,
                    class: "p-mr-2 photo"
                  }, null, 8, ["image"]))
                : (_openBlock(), _createBlock(_component_Avatar, {
                    key: 1,
                    image: _ctx.defaultImage,
                    class: "p-mr-2 photo"
                  }, null, 8, ["image"])),
              _createElementVNode("span", _hoisted_6, _toDisplayString(data?.name), 1)
            ], 8, _hoisted_5)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "creator",
          header: "E-mail",
          sortable: true
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("section", {
              class: "p-d-flex p-align-center",
              onClick: ($event: any) => (_ctx.expandItem(data))
            }, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(data?.creator?.email), 1)
            ], 8, _hoisted_7)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { field: "expand" }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_9, [
              (_ctx.expandedRows?.findIndex((el) => el?._id === data?._id))
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    onClick: ($event: any) => (_ctx.expandItem(data)),
                    icon: "pi pi-angle-down",
                    class: "p-button-lg p-button-rounded p-button-text"
                  }, null, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    onClick: ($event: any) => (_ctx.expandItem(data)),
                    icon: "pi pi-angle-up",
                    class: "p-button-lg p-button-rounded p-button-text"
                  }, null, 8, ["onClick"]))
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "expandedRows"]),
    _createVNode(_component_Paginator, {
      rows: _ctx.limitCampaigns,
      totalRecords: _ctx.brandsList?.pagination,
      onPage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onPage($event)))
    }, null, 8, ["rows", "totalRecords"])
  ]))
}