import React, { useEffect, useState } from 'react';
import "./NewDetectedFrauds.css";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import noImage from "@/react_app/img/no-image.png";
import {
  AndroidIconSmallWhite, AppleIconSmallWhite,
  ClicksIconSmall, ExpandArrowDown,
  ExpandArrowUp, SendIconSmall
} from "@/react_app/img/Icons";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Paginator } from 'primereact/paginator';
import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';

const statusTabs = [
  { value: 'All', type: 'all' },
  { value: 'Pending', type: 'pending' },
];

const userBodyTemplate = (rowData) => {
  return (
    <div className={'userTemplate'}>
      <Image src={rowData.flaggedUserId.photo ? rowData.flaggedUserId : noImage} alt={'user photo'} className={'userTemplateImage'} />
      <p className={'userTemplateName'}>{rowData.flaggedUserId.name}</p>
    </div>
  );
};

const emailBodyTemplate = (rowData) => {
  return (
    <div className={'defaultTemplate'}>
      {rowData.flaggedUserId.email}
    </div>
  );
};

const campaignBodyTemplate = (rowData) => {
  return (
    <div className={'campaignTemplate'}>
      <Image src={rowData.campaignId.photo ? rowData.campaignId.photo : noImage} alt={'campaign cover'} className={'campaignTemplateImage'} />
      <p className={'campaignTemplateTitle'}>{rowData.campaignId.name}</p>
    </div>
  );
};

const flagBodyTemplate = (rowData) => {
  return (
    <div className={'defaultTemplate'}>
      {rowData.flagType}
    </div>
  );
};

const statusBodyTemplate = (rowData) => {
  return (
    <div className={rowData.dismissed ? 'statusTemplate resolved' : 'statusTemplate pending'}>
      {rowData.dismissed ? "resolved" : "pending"}
    </div>
  );
};

export default function (props) {
  const {
    detectedFrauds, getDetectedFraudsWithFilter, limit, changePage, loading
  } = props;

  const [activeTab, setActiveTab] = useState('all');
  const [expandedRows, setExpandedRows] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [first, setFirst] = useState(0);

  const actionsBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.dismissed ?
          <Button disabled={true} className={'dismissButton greyBackground'}>Dismissed</Button> :
          <Button className={'dismissButton'} onClick={() => dismissFraudAlert(rowData.id)}>Dismiss</Button>
        }
      </div>
    );
  };

  const expanderTemplate = (rowData) => {
    return (
      <div className={'expanderTemplate'}>
        {expandedRows.findIndex((data) => JSON.stringify(data) === JSON.stringify(rowData)) >= 0 ?
          <div onClick={() => setExpandedRows(prevState => prevState.filter((item) => JSON.stringify(item) !== JSON.stringify(rowData)))}>
            <ExpandArrowUp />
          </div> :
          <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
            <ExpandArrowDown />
          </div>
        }
      </div>
    )
  }

  const rowExpansionTemplate = (rowData) => {
    const statsItems = [
      {
        label: 'iOS',
        subText: 'Total',
        icon: <AppleIconSmallWhite />,
        data: rowData.totalIOSInstallsCount,
      },
      {
        label: 'Android',
        subText: 'Total',
        icon: <AndroidIconSmallWhite />,
        data: rowData.totalAndroidInstallsCount,
      },
      {
        label: 'Clicks',
        subText: 'Total',
        icon: <ClicksIconSmall />,
        data: rowData.totalClicksCount,
      },
      {
        label: 'Conversion',
        subText: 'Rate',
        icon: <SendIconSmall />,
        data: rowData.conversionRate,
      }
    ]

    return (
      <div className={'expansionTemplate'}>
        <div className={'expansionStats'}>
          <p className={'expansionFlagTier'}>Flagged tier: <strong>{rowData.campaignTier ?? 'N/A'}</strong></p>
          <div className={'expansionStatsWrapper'}>
            {statsItems.map((item, index) =>
              <div className={'expansionStatsItem'} key={index}>
                <div className={'expansionStatsItemIconWrapper'}>
                  {item.icon}
                </div>
                <div className={'expansionStatsItemText'}>
                  <p className={'noMargin'}>{item.label}</p>
                  <p>{item.subText}: <strong>{item.data}</strong></p>
                </div>
              </div>)}
          </div>
        </div>
      </div>
    );
  };

  const handleChangeTab = (type) => {
    setActiveTab(type);
    getDetectedFraudsWithFilter(type);
  }
  const onPageChange = (event) => {
    setFirst(event.first);
    changePage(event)
  };

  useEffect(() => {
    setNoResults(!(detectedFrauds?.fraudResult?.length))
  }, [detectedFrauds]);

  return (
    <div className={'detected-frauds'}>
      <p className={'title'}>Detected frauds</p>
      <div className={'tabsWrapper'}>
        <div className={'tabs'}>
          {statusTabs.map((item) => (
            <div key={item.type} className={item.type === activeTab ? 'tabSelected' : 'tabNormal'}
              onClick={() => handleChangeTab(item.type)}>
              {item.value}
            </div>
          ))}
        </div>
      </div>
      <DataTable value={detectedFrauds?.fraudResult} removableSort emptyMessage={"No frauds found."} rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} loading={loading}
        loadingIcon={
          <CustomLoader />
        }
        style={{ minHeight: 500 }}>
        <Column field={"flaggedUserId.name"} header={"Flagged User"} body={userBodyTemplate} sortable style={{ width: '20%' }}></Column>
        <Column field={"flaggedUserId.email"} header={"User Email"} body={emailBodyTemplate} sortable style={{ width: '18%' }}></Column>
        <Column field={"campaignId.name"} header={"For Campaign"} body={campaignBodyTemplate} sortable style={{ width: '20%' }}></Column>
        <Column field={"flagType"} header={"Flag Type"} body={flagBodyTemplate} sortable style={{ width: '12%' }}></Column>
        <Column field={"dismissed"} header={"Status"} body={statusBodyTemplate} sortable style={{ width: '10%' }}></Column>
        <Column field={""} header={"Actions"} body={actionsBodyTemplate} style={{ width: '12%' }}></Column>
        <Column expander={true} body={expanderTemplate} style={{ width: '8%' }} />
      </DataTable>
      {!noResults && <Paginator first={first} rows={limit} totalRecords={detectedFrauds?.pagination} onPageChange={onPageChange} style={{ background: '#130A23' }} />}

    </div>
  )
}
