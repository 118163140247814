
import { defineComponent } from 'vue';
import CampaignsCounterItem from '@/pages/campaigns/CampaignsCounterItem.vue';

export default defineComponent({
  name: 'CampaignsListCounters',
  components: { CampaignsCounterItem },
  props: {
    counters: {
      type: Object,
      default: null,
    },
  },
});
