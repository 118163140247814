import { AppPagePerspective, Perspective } from '@/data/types/page-types';

import { useTransfersList } from './transfers-list-module';
import { useTransfersManager } from './transfers-manager-module';

export const useTransfers = (perspective: AppPagePerspective = Perspective.CREATOR) => {
  const manager = useTransfersManager();
  const list = useTransfersList(perspective);

  return {
    manager,
    list,
  };
};
