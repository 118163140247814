
import { defineComponent, PropType, ref, watch } from 'vue';
import {
  useAuth,
  useCreators,
  useFavorites,
  usePage,
  useUsers,
  usePayments,
  useNotificationsMethods,
} from '@/modules';
import {
  ActionOutcome,
  AppPageConfig,
  CampaignData,
  Perspective,
  User,
} from '@/data/types';
import { useGlobalState } from '@/store/store';
import OldDashboard from "@/pages/OldDashboard.vue";
import NewDashboard from "@/react_app/NewDashboard/NewDashboard";
import { applyPureReactInVue } from 'veaury';
import { isHomepageEnabled } from '@/utils/globals/feature-flags';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Home',
  components: {
    NewDashboard: applyPureReactInVue(NewDashboard),
    OldDashboard
  },
  props: {
    viewPerspective: String as PropType<Perspective>,
  },
  setup(props: any, ctx: any) {
    const limitCampaigns = ref(4);
    const limitInfluences = ref(4);
    const { config } = usePage<AppPageConfig>({
      title: 'dashboard',
      ...props,
    });
    const file = ref();
    const { user, userHasBrandEmail } = useAuth();
    const favoriteManager = useFavorites();
    const userController = useCreators(config.value.viewPerspective);
    const { manager: { getUserAppliedCampaigns } } = useUsers();
    const { state } = useGlobalState();
    const showSearchForCampaigns = ref(false);
    const op = ref();
    const notificationMethods = useNotificationsMethods();

    const showChangeEmailPrompt = ref(userHasBrandEmail(user.value!.email!));
    const changeEmailOutcome = ref({
      result: 'pending',
      message: '',
    } as ActionOutcome);

    const {
      manager: { changeEmail },
    } = useUsers();
    const { resendInvitation, updateUserProfile } = usePayments();

    const showSearch = () => {
      setTimeout(() => {
        showSearchForCampaigns.value = true;
      }, 1000);
    };

    const showOnBoardingModal = ref(!localStorage.getItem('show-modal'));
    watch(showOnBoardingModal, () => {
      if (!showOnBoardingModal.value) {
        localStorage.setItem('show-modal', 'true');
      }
    });

    const showSetYourProfile = ref(
      !localStorage.getItem('show-modal-influencer')
    );
    watch(showSetYourProfile, () => {
      if (!showSetYourProfile.value) {
        localStorage.setItem('show-modal-influencer', 'true');
      }
    });

    const loadPageData = () => userController.list.load({ limit: props.limit });
    const creator = ref();

    loadPageData().then((data) => {
      creator.value = data;
    });

    userController.list.lastCampaignsLoad(limitCampaigns.value);
    userController.list.lastInfluencersLoad(limitInfluences.value);

    const filteredCampaigns = ref();
    const filteregInfluencers = ref();


    /* When using Youtube to register or sign in it is possible that
    the user has the so called 'Brand account' (youtube added it in 2014)
    which creates a google+ email account for the user and their youtube account
    is only identified with it. Hence when registering to GameInfluencer.IO the
    google plus account is used. Users usually don't have access to that email
    and cannot set up their Gigapay payment accounts and cannot receive any emails.

    There is currently no workaround around this, the users have to change
    their youtube email first and then register on GameInfluencer.IO which is not ideal.
    That's why we prompt them to change their GameInfluencer.IO email instead. */
    const updateEmail = (newEmail: string) => {
      const revertEmail = user!.value!.email!;

      if (!newEmail) return;

      changeEmail(newEmail).then((response: User | undefined) => {
        if (response) {
          /*  Send a Gigapay invitation link to the new email address.
            If the user already has a gigapay account the connection is still present since
            GIO and gigapay communicate via API (through a unique identifier)
          */
          const { id, name } = user!.value!;

          updateUserProfile({ id, name }, { email: newEmail }).then(
            (response: any) => {
              if (response?.success) {
                showChangeEmailPrompt.value = false;
                changeEmailOutcome.value = {
                  result: 'success',
                  message: `E-mail address changed successfully.
          You will receive an E-mail with instructions how to set up your payment information.`,
                };
              } else {
                changeEmailOutcome.value = {
                  result: 'error',
                  message: `E-mail address change failed. Please double check the provided E-mail address and try again.`,
                };

                changeEmail(revertEmail); // if the provided E-mail is registered on Gigapay we need to revert back the email In GameInfluencer.IO
              }
            }
          );
        } else
          changeEmailOutcome.value = {
            result: 'error',
            message: `E-mail address change failed. Please double check the provided E-mail address and try again.`,
          };
      });
    };

    const searchDashboard = (searchValue: string, type: any) => {
      if (type === 'campaigns') {
        userController.list
          .lastCampaignsLoad(limitCampaigns.value, searchValue)
          .then((campaignss: any) => {
            filteredCampaigns.value = campaignss;
          });
      } else {
        userController.list
          .lastInfluencersLoad(limitInfluences.value, searchValue)
          .then((infs: any) => {
            filteregInfluencers.value = infs;
          });
      }
    };

    const inviteToCampaignId = localStorage.getItem('inviteCampaign');
    const showSuccessModalInvite = ref(false);
    const invitedCampaign = ref();

    const inviteToCampaign = async () => {
      if (user.value?.id) {
        const { CampaignApplications } =
          await getUserAppliedCampaigns(
            user.value?.id
          );
        const index = CampaignApplications.findIndex(
          (camp: CampaignData) => camp?.id?.toString() === inviteToCampaignId
        );
        if (index < 0 && inviteToCampaignId) {
          await userController.manager.applyCampaign(
            inviteToCampaignId,
            user.value?.id,
            0,
            true,
            0
          );
          showSuccessModalInvite.value = true;
          const { CampaignApplications } =
            await getUserAppliedCampaigns(
              user.value?.id
            );
          const index = CampaignApplications.findIndex(
            (camp: CampaignData) => camp?.id?.toString() === inviteToCampaignId
          );
          invitedCampaign.value = CampaignApplications[index];
        } else {
          localStorage.removeItem('inviteCampaign');
        }
      }
    };

    const router = useRouter();
    const goToInfluencerProfile = (id: any) => router.push({ path: `creators/${id}` });

    const goToCampaign = (itemID: string) => {
      if(props.viewPerspective === Perspective.CREATOR || props.viewPerspective === Perspective.AGENCY) {
        router.push({ name: 'campaign-active', params: { campaignID: itemID }});
      } else if(props.viewPerspective === Perspective.BRAND) {
        router.push({ name: 'campaign', params: { campaignID: itemID }});
      }
    };

    const favoritesManager = useFavorites();
    const userInf = useAuth();
    const userFavorites = ref();

    if (userInf.user.value) {
      favoritesManager.getUSerFavorites('influences', userInf.user.value.id).then((favorites) => {
        userFavorites.value = favorites;
      });
    }

    const addToFavorites = async (influencerId: string) => {
      if (userInf.user.value) {
        const item = await favoritesManager.likeOrDislike('influences', userInf.user.value?.id, influencerId);
        if (item.type === 'delete') {
          const index = userFavorites.value.allUserFavorites.findIndex((el: any) => el.influences?.id === influencerId);
          userFavorites.value.allUserFavorites.splice(index, 1);
        } else if (item.type === 'create') {
          userFavorites.value.allUserFavorites.push({ influences: { id: influencerId }, type: 'influences' });
        }
      }
    };

    const handleRouting = (route: string) => {
      router.push({ path: route })
    };

    return {
      changeEmailOutcome,
      showChangeEmailPrompt,
      updateEmail,
      op,
      showSearch,
      showSearchForCampaigns,
      showSetYourProfile,
      state,
      showOnBoardingModal,
      file,
      Perspective,
      user,
      loadPageData,
      searchDashboard,
      limitCampaigns,
      limitInfluences,
      filteredCampaigns,
      filteregInfluencers,
      lastCampaigns: userController.list.sendLastCampaignsLoadRequestCreators,
      lastInfluences: userController.list.lastInfluencesLoadData,
      showSuccessModalInvite,
      inviteToCampaignId,
      invitedCampaign,
      isHomepageEnabled,
      goToInfluencerProfile,
      addToFavorites,
      favorites: userFavorites,
      handleRouting: handleRouting,
      goToCampaign,notificationMethods
    };
  },
});
