<template>
  <div>
    <Dialog
      :visible="show"
      class="p-col-12 p-md-6 p-xl-4 gi-dialog"
      :modal="true"
      :style="{ width: '1301px' , height : '798'} "
      @update:visible="$emit('update:show', false)"
    >
      <template #header>
        <div class="p-flex-column" style="margin-left:250px">
        <img class="image" src="@/assets/images/live-content-img.svg">
        <p class="title-main">The advanced licensing creator program </p>
          <p style="margin-top: 90px">Besides creating creator ads for campaigns, here at GameInfluencer.IO
            we have another way for you to earn additional income. </p>
        </div>
      </template>
      <section class="p-d-flex p-flex-column p-ml-6">
        <div>
        <div  class="p-d-flex ended-border">
          <div class="decoration"><img class="hourglass-image" src="@/assets/images/hourglass.svg"></div>
          <div class="ended">This is an exclusive limited time offer for invited creators only!</div>
        </div>
        <div class="p-flex-column">
          <div class="title">Your content, your rules</div>
          <p class="paragraph-1">With our program you can sell licenses to each of the content
            pieces you manage to create and distribute to the brands.</p>
          <p class="paragraph-1">This way you can allow your creator ads to be featured for additional licensing which will grant
            the brand the right to publish the video content of your creator ads on their own media channels,
            such as TV, Google marketing campaigns etc., earning you a specific amount</p>
        </div>
        <div>
<!--          <h6 style="margin-top: 41px">Program member refrence</h6>-->
<!--          <div class="p-d-flex p-ai-center " v-if="user?.type === 'creator'" ref="target">-->
<!--            <div class="cursor p-d-flex p-ai-center" @click.prevent="showMenuProfile = !showMenuProfile">-->
<!--              <img class="image-user" :src="user?.profile?.photo"/>-->
<!--              <div class="p-flex-column">-->
<!--                <div>-->
<!--              <p class="testimonial">“This was one of the most amazing opportunities
 I managed to receive in my career as an influencer.-->
<!--                It doubled my regular income and got me the contracts I was looking for”</p>-->
<!--                </div>-->
<!--                <div>-->
<!--              <h5 style="margin-top: 12px">{{ user?.name }}</h5>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        </div>
        <div><img class="image-girl" src="@/assets/images/gi-girl-icon.svg"></div>
      </section>
      <template #footer>
        <div class="p-d-flex p-justify-center">
          <div class="continue-button" @click="showTermsModal = true; closeUnderstand()">I WANT TO JOIN NOW</div>
          <UnderstandingTermsModal v-model:show="showTermsModal" @close-understanding-modal="closeUnderstand"></UnderstandingTermsModal>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import { ref, watch } from 'vue';
import { useAuth } from '@/modules';
import UnderstandingTermsModal from '@/pages/creators/UnderstandingTermsModal.vue';

export default {
  components: {
    Dialog,
    UnderstandingTermsModal,
  },
  props: {
    show: Boolean,
  },
  setup(props, ctx, emit) {
    const { user } = useAuth();
    const showTermsModal = ref(false);
    const closeUnderstand = () => {
      ctx.emit('closeLicenseModal');
    };
    return { user, showTermsModal, closeUnderstand };
  },
};
</script>
<style scoped lang="scss">
.hourglass-image {
  margin-top: 5px;
  margin-left: 8px;
}
.testimonial {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #686E6E;
  width: 700px;
}
.image-girl {
  position: absolute;
  width: 498px;
  height: 659px;
  left: 736px;
  top: 87px;
}
.image-user {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 25px;
  margin-right: 25px;
}
.paragraph-1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 25px;
  color: #686E6E;
  width: 800px;
}

.decoration {
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 30px;
}
.ended {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #162020;
  margin-left: 20px;
  padding: 2px;
  margin-top: 5px;
}
.ended-border {
  background: radial-gradient(162.45% 162.45% at 50% 50%, #F5F7FB 0%, #F5F7FB 100%);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  padding: 16px;
  max-width: 842px;
  margin-top: 50rem;
}
.image {
  position: absolute;
  margin-left: 20px;
  width: 100px;
  height: 100px;
}
.title-main {
  font-weight: 800;
  font-size: 30px;
  line-height: 50px;
  color: #181818;
  position: absolute;
  margin-left: 60px;
  margin-top: 30px;
}
.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #162020;
  margin-top: 45px;
}
.warning-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #8B8F8F;
  text-align: center;
  margin-left: 5px;
}

.continue-button {
  border: none;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  width: 198px;
  height: 40.12px;
  background: #E0010B;
  box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  padding: 12px;
}
.main-title {
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 50px;
  text-align: center;
  color: #162020;
}

.change-info {
  font-weight: 800;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #162020;
  margin-left: 30px;
  cursor: pointer;
  padding: 8px;
}
::v-deep(.p-checkbox .p-checkbox-box.p-highlight ) {
  background-color: #E0010B;
  color:#ffff;
}

::v-deep(.p-inputgroup) {
  width: 450px;
}
::v-deep(.p-checkbox .p-checkbox-box.p-highlight), ::v-deep(.p-checkbox .p-checkbox-box.p-highlight:hover) {
  border-color: red;
  background: red;
}
</style>
