
import Chip from 'primevue/chip';

import { defineComponent, PropType } from 'vue';
import { CampaignData } from '../../../data/types';

// FIXME: use StatusChip instead of this one!

export default defineComponent({
  components: { Chip },
  props: {
    status: Object as PropType<CampaignData>,
  },
});
