
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useUsers, useCampaigns, useContentItems } from '@/modules';
import { ContentItem } from '@/data/types';
import InfluencerInformation from '../creators/profile/InfluencerInformation.vue';
import CampaignsCounterItemSwitch from '../campaigns/CampaignsCounterItemSwitch.vue';
import CampaignsCounterItem from '../campaigns/CampaignsCounterItem.vue';
import ContentItemsList from '../content/ContentItemsList.vue';
import { findCurrentMetricsChannel } from '../../utils/campaign-utils';

export default defineComponent({
  components: {
    InfluencerInformation,
    CampaignsCounterItemSwitch,
    CampaignsCounterItem,
    ContentItemsList,
  },
  setup() {
    const route = useRoute();
    const usersManager = useUsers();
    const campaignManager = useCampaigns();
    const contentItemsManager = useContentItems();
    const currentWorker = ref();
    const currentWorkerTrackingLink = ref();
    const currentCampaign = ref();
    const currentChannelMetrics = ref();
    const currentChannelContent = ref();

    contentItemsManager.list.getCampaignContent(route.query.campaignId as string).then((data) => {
      currentChannelContent.value = data?.filter((item: ContentItem) => item?.channelId === route.query.channelId);
    });

    campaignManager.manager.loadSingle(route.query.campaignId as string).then((data) => {
      currentCampaign.value = data;
      currentChannelMetrics.value = findCurrentMetricsChannel(route.query.channelId as string, data);
    });

    usersManager.manager.loadMyProfile().then((currentAgecy: any) => {
      const index = currentAgecy.agencyWorkers.findIndex((worker: any) => worker?.channel?.channelId === route.query.channelId);
      currentWorker.value = currentAgecy.agencyWorkers[index];
      const linkIndex = currentAgecy.trackingLinks.findIndex((link: any) => link?.channelId === route.query.channelId);
      currentWorkerTrackingLink.value = currentAgecy.trackingLinks[linkIndex];
    });

    return {
      currentWorker,
      currentChannelMetrics,
      currentCampaign,
      currentChannelContent,
    };
  },
});
