import { AppPageConfig } from '@/data/types';
import { ref } from 'vue';

// export interface UsePageReturn {
//   loading: Ref<boolean>;
//   finished: Ref<boolean>;
//   error: Ref<APIError | undefined>;
// }

export const usePage = <T = AppPageConfig> (config: T) => {
  const configRef = ref<T>(config);

  return {
    config: configRef,
  };
};
