export const compactNumber: any = (n: number, fraction?: number) => {
  if (n) {
    return Intl.NumberFormat('es-US', {
      notation: 'compact',
      maximumFractionDigits: fraction || 1,
    })
      .format(n)
      .replace(/\s/g, '')
      .toLocaleLowerCase();
  }else return 0
};
