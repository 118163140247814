import { watch } from 'vue';
import { useAPI, useAuth } from '@/modules';
import { AppPagePerspective, CampaignData, User, UserData } from '@/data/types';
import { useToastModule } from '@/modules/messages/toast-module';

export const useCreatorManager = (perspective: AppPagePerspective) => {
  const { user } = useAuth();
  const toast = useToastModule();

  // load single item
  const {
    loading,
    error: loadingError,
    execute: loadCreator,
    data: creator,
  } = useAPI<User>('', false);

  const load = (itemID: string) => loadCreator({
    url: `/users/creators/${itemID}`,
    parseResponse: (userData: UserData) => new User(userData, user?.value as User),
  });
  watch(loadingError, toast.handleRequestError);
  // < load single

  // // update
  const {
    loading: updatingInProgress,
    error: updatingError,
    data: updatingResult,
    execute: sendUpdateRequest,
  } = useAPI<User>('', false);

  const update = (userID: string, body: Record<string, any>) => sendUpdateRequest({
    url: `/users/creators/${userID}`,
    method: 'PUT',
    data: body,
    parseResponse: (userData: UserData) => new User(userData, user?.value as User),
  });
  watch(updatingResult, () => {
    if (updatingResult.value) {
      creator.value = updatingResult.value;
      toast.showSuccess('Profile updated successfully!');
    }
  });
  watch(updatingError, toast.handleRequestError);
  // // < update

  const {
    loading: applyingCampaign,
    error: applyCampaignError,
    data: applyCampaignResult,
    execute: sendApplyCampaignRequest,
  } = useAPI('', false);
  const applyCampaign = (campaignID: string, creatorID: string, price: number, final: boolean, cap?: number, isCPM?: boolean) => {
    const payload = { price, final, cap }

    if (!isCPM) {
      delete payload.cap;
    }

    return sendApplyCampaignRequest({
      url: `/campaigns/applications/${campaignID}/${creatorID}`,
      method: 'POST',
      data: payload,
    })
  };
  watch(applyCampaignError, toast.handleRequestError);
  // < apply campaign

  const {
    loading: withdrawingCampaign,
    error: withdrawCampaignError,
    data: withdrawCampaignResult,
    execute: sendWithdrawCampaign,
  } = useAPI<CampaignData>('', false);
  const withdrawCampaign = (campaignID: string, applicationID: string) => sendWithdrawCampaign({
    url: `/campaigns/applications/${campaignID}/${applicationID}`,
    method: 'DELETE',
  });
  watch(withdrawCampaignError, toast.handleRequestError);
  // < apply campaign
  type Base64 = string;
  const {
    loading: putInfluencerInfoLoading,
    error: putInfluencerInfoError,
    data: putInfluencerInfoResult,
    execute: putInfluencerInfoReq,
  } = useAPI('', false);
  const putInfluencerInfo = (creatorID: string, data: { description: string; name: string; photo: Base64 }) => putInfluencerInfoReq({
    url: `/users/creator/change/${creatorID}`,
    method: 'PUT',
    data,
  });
  watch(putInfluencerInfoError, toast.handleRequestError);

  const {
    loading: creatorAgreeSiteRulesLoading,
    error: creatorAgreeSiteRulesError,
    data: creatorAgreeSiteRulesResult,
    execute: creatorAgreeSiteRulesReq,
  } = useAPI('', false);

  const creatorAgreeSiteRules = (creatorID: string, agree: boolean) => creatorAgreeSiteRulesReq({
    url: `/creator/${creatorID}/agree-rules`,
    method: 'POST',
    data: { agree },
  });
  watch(creatorAgreeSiteRulesError, toast.handleRequestError);

  const {
    loading: earningGeneratedSoFarCpmLoading,
    error: earningGeneratedSoFarCpmError,
    data: earningGeneratedSoFarCpmResult,
    execute: earningGeneratedSoFarCpmReq,
  } = useAPI('', false);

  const earningGeneratedSoFarCpm = (campaignsIds: string[], creatorID: string) => earningGeneratedSoFarCpmReq({
    url: `/creator/${creatorID}/generated-earning-cpm`,
    method: 'GET',
    params: { campaignsIds },
  });
  watch(creatorAgreeSiteRulesError, toast.handleRequestError);

  const {
    loading: overallMonthlyEarningSoFarLoading,
    error: overallMonthlyEarningSoFarError,
    data: overallMonthlyEarningSoFarResult,
    execute: overallMonthlyEarningSoFarReq,
  } = useAPI('', false);

  const overallMonthlyEarningSoFar = (creatorID: string) => overallMonthlyEarningSoFarReq({
    url: `/creator/${creatorID}/overall-monthly-earning-so-far`,
    method: 'GET',
  });
  watch(overallMonthlyEarningSoFarError, toast.handleRequestError);


  const {
    loading: getCreatorCapLoading,
    error: getCreatorCapError,
    data: getCreatorCapResult,
    execute: getCreatorCapReq,
  } = useAPI('', false);

  const getCreatorCap = (channelId: string, queryData: {
    appliedFee: number,
    maxPayoutCap: number,
    clientCap: number
  }) => getCreatorCapReq({
    url: `/creator/${channelId}`,
    method: 'GET',
    params: queryData,
  });

  watch(getCreatorCapError, toast.handleRequestError);

  return {
    overallMonthlyEarningSoFar,
    overallMonthlyEarningSoFarResult,
    overallMonthlyEarningSoFarError,
    overallMonthlyEarningSoFarLoading,
    // all generated
    earningGeneratedSoFarCpm,
    earningGeneratedSoFarCpmResult,
    earningGeneratedSoFarCpmError,
    earningGeneratedSoFarCpmLoading,
    // cpm
    creatorAgreeSiteRules,
    creatorAgreeSiteRulesResult,
    creatorAgreeSiteRulesError,
    creatorAgreeSiteRulesLoading,
    // agree rules site
    putInfluencerInfo,
    putInfluencerInfoResult,
    putInfluencerInfoError,
    putInfluencerInfoLoading,
    // put influencer info
    load,
    loading,
    creator,
    // update creator
    update,
    updatingInProgress,
    updatingError,
    updatingResult,
    // apply campaign
    applyCampaign,
    applyCampaignResult,
    applyCampaignError,
    applyingCampaign,
    // withdraw campaign
    withdrawCampaign,
    withdrawCampaignResult,
    withdrawCampaignError,
    withdrawingCampaign,
    // get creator cap
    getCreatorCapLoading,
    getCreatorCapError,
    getCreatorCapResult,
    getCreatorCap,
  };
};
