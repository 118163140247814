
import { applyPureReactInVue } from 'veaury';
import { defineComponent, onMounted, ref } from 'vue';

import OldEarningsPage from './OldEarningsPage.vue';

import { useAdmins, useAgencys, useAuth } from '@/modules';
import { isNewAdminEnabled } from '@/utils/globals/feature-flags';
import { MatchAffSubsFromTrackingLink } from '@/utils/globals/regex';
import NewEarnings from '@/react_app/NewAdmin/NewEarnings/NewEarnings';

interface EarningsPageProps {
  isAdminData: boolean;
}

export default defineComponent({
  props: {
    isAdminData: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    NewEarnings: applyPureReactInVue(NewEarnings),
    OldEarningsPage
  },
  setup(props: EarningsPageProps) {
    const skip = ref(0);
    const limit = ref(10);
    const { user } = useAuth();
    const { getEarningsForAdmin } = useAdmins().manager;
    const { getEarningsForAgency } = useAgencys().manager;
    const earnings = ref();
    const isLoading = ref(true);

    const getEarningRecords = async (skip: number) => {
      isLoading.value = true;
      const response = props?.isAdminData
        ? await getEarningsForAdmin(limit.value, skip)
        : await getEarningsForAgency(
            limit.value,
            skip,
            false,
            user.value?.id as string
          );
      earnings.value = response;
      /* When the earnings are for an agency it should also display the creator that earned that particular amount.
         With the current database design that is not possible directly that's why we have to iterate through
         the campaign's tracking links and extract the creator from the URL.
         In the above response the `creatorId` is the agency itself and the creator that works for the agency
         is mapped nowhere. The tracking link contains the username though. */
      for await (const entity of response?.creatorEarnings) {
        for await (const currentLink of entity?.links) {
          const match = currentLink?.link?.match(MatchAffSubsFromTrackingLink);
          if (match) {
            let affSub3Value = match[3]; // username
            affSub3Value = affSub3Value.replaceAll('%20', ' ');

            currentLink.creatorName = affSub3Value;
          }
        }
      }
      isLoading.value = false;
    };
    const handlePageChange = (skipValue: number) => {
      skip.value = skipValue;
      getEarningRecords(skipValue);
    };

    onMounted(() => {
      getEarningRecords(skip.value);
    });
    return {
      skip,
      limit,
      earnings,
      isNewAdminEnabled,
      isLoading,handlePageChange
    };
  },
});
