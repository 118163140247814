
import 'primeicons/primeicons.css';
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuth, useCampaigns } from '@/modules';
import { formatDate } from '@/utils/globals/date-utils';
import { parseCountry, countries } from '@/data/static/countries';
import { parseLanguage, languages } from '@/data/static/languages';
import { AppPageConfig, AppPageProps } from '@/data/types';
import Tooltip from 'primevue/tooltip';
import { applyPureReactInVue } from 'veaury';
import NewCampaign from "@/react_app/NewCampaign/NewCampaign";
import OldCampaign from "./OldCampaign.vue";
import { isNewCampaignsPageEnabled } from '@/utils/globals/feature-flags';

export default defineComponent({
  name: 'Campaign',
  methods: {
    isNewCampaignsPageEnabled() {
      return isNewCampaignsPageEnabled
    }
  },
  directives: {
    tooltip: Tooltip,
  },
  components: {
    NewCampaign: applyPureReactInVue(NewCampaign),
    OldCampaign
  },
  props: {
    campaignID: {
      type: String,
    },
    ...AppPageProps,
  },

  setup(props) {
    const router = useRouter();
    const route = useRoute();

    if (!route.params.campaignID) {
      console.warn('campaign id is missing', route.params.campaignID);
      router.push({ name: 'campaigns' });
      return null;
    }

    const { campaignID } = route.params;
    const { user } = useAuth();

    const config = ref<AppPageConfig>({
      title: 'Loading campaign',
      ...props,
    });

    const controller = useCampaigns(props.viewPerspective);
    const refreshData = () => controller.manager.loadSingle(campaignID as string);

    const handleContentRouting = (id: string) => {
      router.push({ name: 'content-item', params: { itemID: id } });
    }

    const handleRoutingAll = () => {
      router.push({ name: 'content-items' });
    }

    const handleBackToAll = () => {
      router.push({ name: 'campaigns' });
    }

    // publish campaign
    const publishCampaign = () => {
      controller.manager
        .publish(campaignID as string)
        .then((success: any) => {
          if (success) {
            refreshData();
          }
        });
    };
    // < publish campaign
    refreshData();

    return {
      config,
      // singleCampaign,
      campaign: controller.manager.singleCampaign,
      loading: controller.manager.loadingSingle,
      // formatting & parse
      formatDate,
      parseCountry,
      parseLanguage,
      // publish campaign
      publishing: controller.manager.publishing,
      publishCampaign,
      languages,
      countries,
      handleContentRouting,
      handleRoutingAll,
      handleBackToAll,
      user,
      refreshData
    };
  },
});
