import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewImportantStats = _resolveComponent("NewImportantStats")!
  const _component_OldImportantStats = _resolveComponent("OldImportantStats")!

  return (_ctx.isNewAdminEnabled)
    ? (_openBlock(), _createBlock(_component_NewImportantStats, {
        key: 0,
        adminStatistics: _ctx.adminStatistics,
        campaigns: _ctx.campaignsList?.campaigns,
        searchCampaigns: _ctx.searchCampaigns,
        addPlatform: _ctx.addPlatform,
        parseAppliedCreators: _ctx.parseAppliedCreators,
        saveTrackingLink: _ctx.saveTrackingLink,
        updateCampaignData: _ctx.updateCampaignData,
        seeCampaignDetails: _ctx.seeCampaignDetails,
        campaignExpensesLoaded: _ctx.campaignExpensesLoaded,
        getCurrentCampaignExpenses: _ctx.getCurrentCampaignExpenses,
        changePage: _ctx.onPage,
        limit: _ctx.limitCampaigns,
        pagination: _ctx.campaignsList?.pagination,
        loading: _ctx.loading
      }, null, 8, ["adminStatistics", "campaigns", "searchCampaigns", "addPlatform", "parseAppliedCreators", "saveTrackingLink", "updateCampaignData", "seeCampaignDetails", "campaignExpensesLoaded", "getCurrentCampaignExpenses", "changePage", "limit", "pagination", "loading"]))
    : (_openBlock(), _createBlock(_component_OldImportantStats, { key: 1 }))
}