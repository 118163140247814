import { reactive, ref } from "vue";

export const tier1 = [
  'United Kingdom',
  'New Zealand',
  'Australia',
  'United States',
  'Canada',
  'France',
  'Germany',
  'Austria',
  'Denmark',
  'Finland',
  'Norway',
  'Sweden',
  'Switzerland',
  'Belgium',
  'Netherlands',
  'Luxembourg',
  'Lichtenstein',
  'Iceland',
  'Ireland',
  'Republic of Ireland',
  'Monaco',
  'Liechtenstein'];
export const tier2 = [
  'Portugal',
  'Poland',
  'Estonia',
  'Latvia',
  'Lithuania',
  'Hungary',
  'Czech Republic',
  'Italy',
  'Croatia',
  'Greece',
  'Slovakia',
  'Cyprus',
  'Luxembourg',
  'Monaco',
  'Spain'];
export const tier3 = [
  'Bulgaria',
  'Mexico',
  'Puerto Rico',
  'Ukraine',
  'Turkey',
  'Georgia',
  'Latvia',
  'Liechtenstein',
  'Malta',
  'Moldova',
  'Romania',
  'Bosnia And Herzegovina',
  'Bosnia',
  'Albania',
  'Montenegro'];
export const tier4 = [
  'Guyana',
  'Paraguay',
  'Suriname',
  'Colombia',
  'Argentina',
  'Falkland Islands (Malvinas)',
  'Uruguay',
  'Peru',
  'French Guiana',
  'Venezuela',
  'Brazil',
  'Chile',
  'Ecuador',
  'Bolivia',
  'Bahamas',
  'Barbados',
  'Costa Rica',
  'Cuba',
  'Dominican Republic',
  'El Salvador',
  'Guatemala',
  'Haiti',
  'Honduras',
  'Jamaica',
  'Panama',
  'Saint Lucia',
  'Trinidad And Tobago',
  'India',
  'Philippines'];

export const tierNames = ['Tier 1 - Global', 'Tier 2 - Europe', 'Tier 3 - Central and Eastern Europe', 'Tier 4 - ASIA & CASA'];

export const regionsOptions = [
  {
    name: tier1.join(', '),
    value: tierNames[0],
  },
  {
    name: tier2.join(', '),
    value: tierNames[1],
  },
  {
    name: tier3.join(', '),
    value: tierNames[2],
  },
  {
    name: tier4.join(', '),
    value: tierNames[3],
  },
];

export const targetMarkers = ref([
  {
    type: 'PC',
    title: 'PC Gaming',
  },
  {
    type: 'MobileRPG',
    title: 'Mobile Gaming',
  },
  {
    type: 'Console',
    title: 'Console Gaming',
  },
  {
    type: 'Other',
    title: 'Other',
  }
]);

export const paginationStep = ref(['Product', 'Instructions', 'Budget', 'Overview']);

export const paymentMethodsOptions = [
  {
    value: 'CPC',
    title: 'CPC (Cost Per Click)',
  },
  {
    value: 'CPI',
    title: 'CPI (Cost per Install)',
  },
  {
    value: 'CPM',
    title: 'CPM (Cost per Mille)',
  }];

export const targetMarkets = reactive({
  targetMarkets: [{
    type: 'PC',
    title: 'PC Gaming',
  }, {
    type: 'MobileRPG',
    title: 'Mobile Gaming',
  }, {
    type: 'Console',
    title: 'Console Gaming',
  }, {
    type: 'Other',
    title: 'Other',
  }],
});

export const clearLocalstorageForCreateCampaign = () => {
  const keysForClear = ['gamekeys', 'cname', 'valueTalkingLinkInput', 'parseProductUrls', 'talkingPoints', 'valueCallToActionInput',
    'attachment', 'totalCampaignBudget', 'cpiIos', 'minIos', 'LegalPersonType', 'Name', 'LegalRepresentativeAddress',
    'LegalRepresentativeBirthday', 'LegalRepresentativeCountryOfResidence', 'LegalRepresentativeNationality',
    'LegalRepresentativeFirstName', 'LegalRepresentativeLastName', 'selectedPaymentMethod', 'cpcValue'];
  keysForClear.forEach((field) => {
    localStorage.removeItem(field);
  });
};

export const parseTypeIcons = {
  Other: require('@/pages/creators/assets/images/FPS-Gaming.png'),
  Console: require('@/pages/creators/assets/images/Mobile-Gaming.png'),
  MobileRPG: require('@/pages/creators/assets/images/Mobile-Gaming.png'),
  PC: require('@/pages/creators/assets/images/PC-Gaming.png'),
};
