import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewPendingCampaignApplications = _resolveComponent("NewPendingCampaignApplications")!
  const _component_OldPendingCampaignApplications = _resolveComponent("OldPendingCampaignApplications")!

  return (_ctx.isHomepageEnabled)
    ? (_openBlock(), _createBlock(_component_NewPendingCampaignApplications, {
        key: 0,
        applications: _ctx.cpmCampaignApplications,
        fetchApplications: _ctx.getCpmCampaignApplications,
        displayChannelTitle: _ctx.displayChannelTitle,
        changeStatusQuery: _ctx.changeStatusQuery,
        resolveApplication: _ctx.resolveApplication,
        createIndividualCapProposal: _ctx.createIndividualCapProposal,
        manageCapProposalDialog: _ctx.manageCapProposalDialog,
        displayCapProposalDialog: _ctx.displayCapProposalDialog,
        loadingState: _ctx.loadingState,
        goToCampaign: _ctx.goToCampaign
      }, null, 8, ["applications", "fetchApplications", "displayChannelTitle", "changeStatusQuery", "resolveApplication", "createIndividualCapProposal", "manageCapProposalDialog", "displayCapProposalDialog", "loadingState", "goToCampaign"]))
    : (_openBlock(), _createBlock(_component_OldPendingCampaignApplications, { key: 1 }))
}