import React, { useEffect, useState } from 'react';
import './NewCampaignOverview.css';
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import {
  AndroidIcon, AppleIcon,
  AppStoreIconDefault,
  CheckMark,
  EditPencil,
  GooglePlayIconDefault,
  WebPageIconDefault
} from "@/react_app/img/Icons";
import { Divider } from "primereact/divider";
import { Image } from "primereact/image";
import { useUsers } from "@/modules";
import { CampaignPaymentMethod } from "@/data/types";
import { calculateCpiRate } from "@/utils/globals/cpi-rate";
import AttachmentsDisplay from '@/react_app/Other/AttachmentsDisplay/AttachmentsDisplay';

export function OtherUrlIcon(props) {
  return (
    <div>
      {props.index === 0 && <GooglePlayIconDefault />}
      {props.index === 1 && <AppStoreIconDefault />}
      {props.index > 1 && <WebPageIconDefault />}
    </div>
  )
}

export function PaymentMethodDisplay(props) {
  return (
    <div>
      {props.campaign?.paymentMethod === CampaignPaymentMethod.CPC &&
        <p className={'coverValue'}>{calculateCpiRate(props.campaign?.campaignCPC, props.campaign?.appliedFee)} USD</p>
      }
      {props.campaign?.paymentMethod === CampaignPaymentMethod.CPM &&
        <p className={'coverValue'}>{calculateCpiRate(props.campaign?.campaignCPM?.amount, props.campaign?.appliedFee)} USD</p>
      }
      {props.campaign?.paymentMethod === CampaignPaymentMethod.CPI &&
        <div>
          <div className={'coverValue'}>
            <AndroidIcon />
            {calculateCpiRate(props.campaign?.campaignCPI?.android, props.campaign?.appliedFee)}{' '} USD
          </div>
          <div className={'coverValue'}>
            <AppleIcon />
            {calculateCpiRate(props.campaign?.campaignCPI?.ios, props.campaign?.appliedFee)}{' '} USD
          </div>
        </div>
      }
    </div>
  )
}

const getUrlName = (index) => {
  if (index === 0) {
    return 'Google Play Link';
  } else if (index === 1) {
    return 'Apple App Store Link';
  }
  return 'Product Website Link';
}

export default function (props) {
  const [filteredAttachments, setFilteredAttachments] = useState([]);
  const [brand, setBrand] = useState('');
  const [applicationTimeLeft, setApplicationTimeLeft] = useState('');
  const users = useUsers();

  useEffect(() => {
    if (props.campaign) {
      setFilteredAttachments(props.campaign.attachments);
      setApplicationTimeLeft(props.campaign?.campaignEndDate ? props.campaign.campaignEndDate.toLocaleDateString
        ('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Until budget is complete');
    }
    users.manager?.loadMyProfile().then(user => setBrand(user));
  }, [props.campaign]);

  return (
    <div className={'campaign-overview'}>
      <div className={'topLayer'}>
        <p className={'progressBarText'}>99% completed</p>
        <ProgressBar value={99} />
        <p className={'topText'}>You’re about to launch your campaign! Make sure to review all details!</p>
      </div>
      <div className={'coverBackground'} style={{ background: `linear-gradient(rgba(19, 10, 35, 0.7), rgba(19, 10, 35, 0.7)), url(${props.campaign.coverImage})` }}>
        <p className={'coverTitle'}>{props.campaign?.campaignName}</p>
        <div className={'coverGrid'}>
          <div>
            <p className={'coverLabel'}>Category:</p>
            <p className={'coverValue'}>{props.campaign?.category.name}</p>
          </div>
          <div>
            <p className={'coverLabel'}>Campaign {props.campaign?.paymentMethod}:</p>
            <PaymentMethodDisplay campaign={props.campaign} />
          </div>
          {props.campaign?.paymentMethod === CampaignPaymentMethod.CPM &&
            <div>
              <p className={'coverLabel'}>Max payout:</p>
              <p className={'coverValue'}>{props.campaign?.campaignCPM?.payoutCap} USD</p>
            </div>
          }
          <div>
            <p className={'coverLabel'}>Application time left:</p>
            <p className={'coverValue'}>{applicationTimeLeft}</p>
          </div>
          {props.campaign?.paymentMethod === CampaignPaymentMethod.CPM ? <div>
            <p className={'coverLabel'}>Language:</p>
            <p className={'coverValue'}>{props?.campaign?.languages?.map(language => language?.name)?.join(',')}</p>
          </div> : <div>
            <p className={'coverLabel'}>Region:</p>
            <p className={'coverValue'}>{(props.campaign?.desiredRegions?.length) ? props.campaign?.desiredRegions[0]?.value : '-'}</p>
          </div>}
        </div>
        <Button className={'launchButton'} disabled={props.disableLaunchButton} onClick={() => props.launchCampaign()}>Launch Campaign</Button>
      </div>
      <div className={'overviewMain'}>
        <div className={'overviewMainLeft'}>
          <div>
            <p className={'overviewLabel'}>Campaign Details <EditPencil onClick={() => props.setCurrentTab(2)} /></p>
            <p className={'overviewText'}>{props.campaign?.campaignDescription}</p>
          </div>
          <div>
            <p className={'overviewLabel'}>Talking points <EditPencil onClick={() => props.setCurrentTab(3)} /></p>
            <div className={'talkingPointsWrapper'}>
              {props.campaign?.talkingPoints?.filter(item => item !== '').map((item, index) =>
                <div key={index} className={'talkingPoint'}>
                  <CheckMark />
                  <div className={'talkingPointText'}>
                    {item}
                  </div>
                </div>)}
            </div>
          </div>
        </div>
        <div className={'overviewMainRight'}>
          <div>
            <p className={'overviewLabel'}>Campaign URls <EditPencil onClick={() => props.setCurrentTab(2)} /></p>
            <div className={'linkWrapper'}>
              {props.campaign?.otherUrls?.map((item, index) =>
                <div key={index}>
                  <p className={'linkLabel'}>{getUrlName(index)}</p>
                  <div className={'link'}>
                    <OtherUrlIcon index={index} />
                    <span className={'overflowEllipsis'}>{item}</span>
                  </div>
                </div>)}
            </div>
          </div>
          <div>
            <p className={'overviewLabel'}>Call to Action <EditPencil onClick={() => props.setCurrentTab(3)} /></p>
            <div className={'callToActionBox'}>{props.campaign?.callToAction}</div>
          </div>
          <div>
            <p className={'overviewLabel'}>Attachments<EditPencil onClick={() => props.setCurrentTab(3)} /></p>
            <AttachmentsDisplay attachments={filteredAttachments} isEditMode={true} />
          </div>
        </div>
      </div>
      <Divider className={'divider'} />
      <div className={'overviewBottom'}>
        <div className={'overviewBottomLeft'}>
          <p className={'overviewLabel'}>About Brand</p>
          <div className={'brandPhotoRow'}>
            <Image src={brand.brand?.photo} alt={'brand photo'} className={'brandPhoto'} />
            <span>{brand.brand?.name}</span>
          </div>
          <p className={'overviewText'}>{brand.brand?.description}</p>
        </div>
        <div className={'overviewBottomRight'}>
          <Button className={'saveDraftButton'} onClick={() => props.saveDraft(true)}
            loading={props.loading} disabled={props.loading}>Save Draft</Button>
          <Button className={'launchButton'} onClick={() => props.launchCampaign()}>Launch Campaign</Button>
        </div>
      </div>
    </div>
  )
}
