import { AppPagePerspective } from '@/data/types';
import { useBrandsManager } from './brands-manager-module';

export const useBrands = (perspective: AppPagePerspective) => {
  const manager = useBrandsManager(perspective);

  return {
    manager,
  };
};
