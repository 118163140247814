<template>
  <div class="campaign-list-item">
    <div class="p-flex-column">
    <div class="p-d-flex">
      <div>
    <CampaignStatusChip :campaign="item" />
      </div>
      <div class="timeframe p-d-flex" style="margin-left: 4vh">
        <div class="icon"><span class="material-icons">today</span></div>
        <div class="dates">
          {{ formatDate(item.dates?.startDate) }}
          -
          {{ formatDate(item.dates?.endDate) }}
        </div>
      </div>
    </div>
      <div>
      <div class="p-d-flex main-box">
        <div><CampaignPhoto  :campaign="item" class="camp-photo" /></div>
        <div class="p-d-flex-column p-align-stretch">
          <h5 class="name-camp">{{ item.name }}</h5>
          <div class="p-d-flex">
          <BrandPhoto class="brand-photo" :brand="item.brand" />
          <div class="name">{{ item.brand.name }}</div>
          </div>
          <div class="budget p-d-flex" style="margin-left: 50px">
            <div class="icon"><span class="material-icons">account_balance_wallet</span></div>
            <div class="camp-amount">{{ item.budget ?  item.budget.amount : 'open budget' }}
              <div style="margin-left: 3px"> {{ item.budget.currency ? item.budget.currency : ''}}</div></div>
          </div>
        </div>
      </div>
      </div>
      <div class="done-button p-mt-4">
        <Button label="Mark as Done" @click.stop="showSetDoneModal = true" :disabled="item.status!='active'"/>
      </div>
    </div>
    <SetCampaignDoneModal
      ref="setCampaignDoneModalRef"
      v-model:show="showSetDoneModal"
      @setDone="setCampaignDone"
    />
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useAPI } from '@/modules';
import { APIError } from '@/utils/globals/error-utils';
import { useToast } from 'primevue/usetoast';
import { formatDate } from '@/utils/globals/date-utils';
import Button from 'primevue/button';
import BrandPhoto from '@/pages/brands/profile/BrandPhoto.vue';
import SetCampaignDoneModal from '@/pages/commons/modals/SetCampaignDoneModal.vue';
import CampaignPhoto from './view/CampaignPhoto.vue';
import CampaignStatusChip from './view/CampaignStatusChip.vue';

export default {
  name: 'CampaignListItem',

  components: {
    CampaignStatusChip, CampaignPhoto, BrandPhoto, Button, SetCampaignDoneModal,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  emits: ['updated'],

  setup(props, { emit }) {
    const toast = useToast();
    const showSetDoneModal = ref(false);
    const {
      loading: applyingInProgress,
      data: applicationResponse,
      error: applicationError,
      execute: sendCampaignPatch,
    } = useAPI(`/campaigns/${props.item.id}`, false);
    const sendApply = (feedback) => {
      sendCampaignPatch({
        url: `/campaigns/${props.item.id}/done`,
        method: 'POST',
        data: {
          feedback,
        },
      })
        .then(() => {
          emit('updated');
        });
    };
    const setCampaignDone = (feedback) => {
      sendApply(feedback);
    };
    watch(applicationResponse, () => {
      if (applicationResponse.value) {
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Campaign mark as Done',
          life: 3000,
        });
      }
    });

    watch(applicationError, (err) => {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: err?.message,
        life: 3000,
      });
    });
    return {
      formatDate,
      setCampaignDone,
      showSetDoneModal,
      applyingInProgress,
      applicationResponse,
      applicationError,
      sendCampaignPatch,
    };
  },
};
</script>

<style lang="scss" scoped>
.dates {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #162020;
  padding: 5px;
}
.camp-amount {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 29px;
  color: #162020;
}
.brand-photo {
  border-radius: 50%;
  background: rgba(232, 232, 232, 0.34);
  width: 26px;
  height: 26px;
}
.camp-photo {
  min-width: 97px;
  max-width: 97px;
  height: 113px;
  background: #EFF0F1;
  margin-right: 16px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
}
.main-box {
  width: 360px;
  background: #F8F9FA;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 4px;
  justify-items: center;
  height: 123px;
  margin-top: 12px;
}
.campaign-list-item {
  margin-bottom: 25vh;
  cursor: pointer;
  @include font-montserrat();

  &:hover {
    .box {
      border-color: #EEE;
    }
  }

  .box {
    margin-top: 14rem;
    padding: 20rem;

    background: #f9f9f9;
    border: 1px solid transparent;
    border-radius: 15px;
    transition: border-color .3s ease;
  }

  .header {
    display: flex;

    .campaign-photo {
      width: 85rem;
      height: 66rem;
      margin-right: 14rem;
    }
    .name {
      margin: 0;
      flex: 1 0 0;
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28rem;

    .timeframe, .budget {
      display: flex;
      font-weight: 600;
      font-size: 12rem;
      line-height: 15rem;
      color: $giDarkGrey;

      .icon .material-icons {
        margin-right: 8rem;
        font-size: 18rem;
      }
    }
  }

  .description {
    display: flex;
    .icon {
      margin-right: 8rem;
      color: #181818;
    }
    .text {
      --lh: 15rem;
      --max-lines: 3;

      font-size: 12rem;
      font-weight: 600;
      line-height: var(--lh);
      color: #BABABA;

      height: calc(var(--lh) * var(--max-lines));
      overflow: hidden;
    }
  }
  .name-camp {
    margin-top: 10px;
    margin-left: 5px;
    font-size: 14px;
    max-height: 34px;
    overflow: hidden;
    overflow-wrap: break-word;
  }
  .brand {
    align-items: center;
    margin-top: 10rem;

    &-photo {
      width: 26rem;
      height: 26rem;
      margin-right: 8rem;
      color: #181818;
      ::v-deep(.default) { // TODO: set icon font size to base, and just update Photo font-size
        font-size: 24rem;
      }
    }
    .name {
      font-weight: 600;
      font-size: 16rem;
      color: #181818;
    }
  }
}
</style>
