
import { useRouter } from 'vue-router';
import { useAuth, useFavorites } from '@/modules';
import { compactNumber } from '../../../utils/globals/curency-utils';

export default {
  name: 'LatestInfluencersItem',
  props: {
    item: {
      type: Object,
    },
    isFavorite: Boolean,
  },
  setup(props: any, ctx: any) {
    const router = useRouter();
    const goToProfile = () => router.push({ path: `creators/${props.item.id}` });

    const user = useAuth();
    const favoritesManager = useFavorites();

    const addToFavorite = async (influencerId: string) => {
      if (user.user.value) {
        const item = await favoritesManager.likeOrDislike('influences', user.user.value?.id, influencerId);
        ctx.emit('favoriteChange', { item, influencerId });
      }
    };

    const calculateFollowers = (followers: Array<any>) => {
      const initialValue = 0;
      const sumWithInitial = followers.reduce(
        (accumulator, currentValue) => accumulator.followers || 0 + currentValue.followers || 0,
        initialValue
      );
      return sumWithInitial;
    }

    return {
      calculateFollowers,
      compactNumber,
      goToProfile,
      addToFavorite,
    };
  },
};
