import { watch } from 'vue';
import { useAPI } from '@/modules';
import { AppPagePerspective } from '@/data/types';
import { useToastModule } from '@/modules/messages/toast-module';

export const useFavorites = (perspective?: AppPagePerspective) => {
  const toast = useToastModule();

  const {
    loading: likeOrDislikeProgress,
    error: likeOrDislikeError,
    data: likeOrDislikeResult,
    execute: likeOrDislikeRequest,
  } = useAPI('', false);
  const likeOrDislike = (contentType: string, userID: string, itemID: any) => likeOrDislikeRequest({
    url: `/favorites/${contentType}/${userID}/${itemID}`,
    method: 'POST',
  });
  watch(likeOrDislikeError, toast.handleRequestError);
  // likes or dislike

  const {
    loading: getUSerFavoritesProgress,
    error: getUSerFavoritesError,
    data: getUSerFavoritesResult,
    execute: getUSerFavoritesRequest,
  } = useAPI('', false);
  const getUSerFavorites = (contentType: string, userID: string) => getUSerFavoritesRequest({
    url: `/favorites/${contentType}/${userID}`,
    method: 'GET',
  });
  watch(getUSerFavoritesError, toast.handleRequestError);
  // get all user favorites with userID and type
  return {
    likeOrDislikeProgress,
    likeOrDislikeResult,
    likeOrDislikeError,
    likeOrDislike,
    // like or dislike
    getUSerFavoritesProgress,
    getUSerFavoritesError,
    getUSerFavoritesResult,
    getUSerFavorites,
    // get user favorites from type
  };
};
