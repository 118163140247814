
import { defineComponent, PropType } from 'vue';
import { ContentItem } from '@/data/types';
import ContentItemsListItem from './ContentItemsListItem.vue';
import EmptyList from '../commons/list/EmptyList.vue';

export default defineComponent({
  name: 'ContentItemsList',
  components: { ContentItemsListItem, EmptyList },
  props: {
    items: {
      type: Array as PropType<Array<ContentItem>>,
      default: () => [],
    },
  },
});
