
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import { computed, defineComponent, ref } from 'vue';
import { useAgencys, useAuth, useCreators } from '@/modules';
import { useRouter } from 'vue-router';
import { Perspective } from '@/data/types';

export default defineComponent({
  name: 'CreateAgency',
  components: {
    InputText,
    Textarea,
    Button,
  },
  setup() {
    const { user } = useAuth();
    const agencyModule = useAgencys();
    const router = useRouter();
    const currentSteep = ref(1);
    const name = ref('');
    const selectedPhoto = ref(user.value?.profile?.photo || '');
    const aboutAgency = ref('');
    const availablePhotos = ref<Array<string | ArrayBuffer | null>>(user.value?.profile?.photo ? [user.value?.profile?.photo] : []);
    const base64Photo = ref();
    const uploadPhotoInput = ref();
    const searchResult = ref();
    const forUsername = ref('');
    const regPath = ref(`${window?.location?.origin}${router.resolve({
      name: 'register',
    }).href}`);

    const controller = useCreators(Perspective.AGENCY);
    const loadChannels = async () => controller.youtube.getCreatorChannels(user.value?.id, user.value?.type);
    loadChannels();

    const handleFileUpload = () => {
      if (uploadPhotoInput.value) {
        const file = uploadPhotoInput.value.files[0];

        const reader = new FileReader();
        reader.onload = () => {
          base64Photo.value = reader.result;
          availablePhotos.value.push(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };

    const copyLink = (url: string) => {
      navigator.clipboard.writeText(url);
    };

    const addCreator = async () => {
      // const regEx = new RegExp('^.+@\\w+.\\w+$', 'g');
      const searchResults = await agencyModule.manager.searchChannels(forUsername.value);
      searchResult.value = searchResults;
    };

    const selectChannelForAdd = async (channelData: any) => {
      await agencyModule.manager.addCreator(channelData);
      await loadChannels();
    };
    const confirmAgency = async () => {
      await agencyModule.manager.createAgency({
        agencyDescription: aboutAgency.value,
        agencyName: name.value,
        agencyPhoto: base64Photo.value,
        photoLink: selectedPhoto.value,
      });
      await router.push({ name: 'dashboard' });
    };

    const computedProcess = computed(() => {
      if (currentSteep.value === 1) {
        return (base64Photo.value || selectedPhoto.value) && name.value && aboutAgency.value;
      }
      if (currentSteep.value === 2) {
        return true;
      }
      return false;
    });

    const deleteChanel = async (channelId: string, index: number) => {
      await agencyModule.manager.deleteChannel(channelId);
      await loadChannels();
    };

    const isChannelAlredyAdded = (userChannels: any, channelId: string) => {
      const isAlredy = userChannels?.findIndex((channel: any) => channel?.id === channelId);
      return isAlredy >= 0;
    };

    return {
      isChannelAlredyAdded,
      deleteChanel,
      handleFileUpload,
      addCreator,
      selectChannelForAdd,
      confirmAgency,
      channels: controller.youtube.getCreatorChannelsResult,
      computedProcess,
      forUsername,
      uploadPhotoInput,
      searchResult,
      base64Photo,
      currentSteep,
      selectedPhoto,
      aboutAgency,
      name,
      availablePhotos,
      regPath,
      copyLink,
      searchChannelLoading: agencyModule.manager.searchChannelsLoading,
    };
  },
});
