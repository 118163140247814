
import { ref } from 'vue';
import Button from 'primevue/button';
import { useAuth, useContentItems, useCreators } from '@/modules';
import SecretTip from '../campaigns/create/SecretTip.vue';
import VideoUrlInput from './VideoUrlInput.vue';
import ContentPiece from './ContentPiece.vue';
import SearchBarwithBrowse from './SearchBarwithBrowse.vue';

export default {
  name: 'ContentManagerPage',
  components: {
    Button,
    SecretTip,
    VideoUrlInput,
    ContentPiece,
    SearchBarwithBrowse,
  },
  setup() {
    const content = useContentItems();
    const countInputs = ref([{ value: 'https://www.youtube.com/watch?v=Ru0MZEIk8jk', input: 0 }]);
    const videosInfo = ref();
    const uploadingContent = ref(false);
    const steep = ref(0);
    const loadingVideoInfo = ref(false);
    const controller = useCreators();
    const { user } = useAuth();
    const changeValueUrl = (value: any) => {
      const finded = countInputs.value.findIndex((input) => input.input === value.input);
      if (finded === -1) {
        countInputs.value.push(value);
      } else {
        countInputs.value[finded] = value;
      }
    };
    const verifyUrls = async () => {
      loadingVideoInfo.value = true;
      if (countInputs.value.length > 1) {
        countInputs.value = countInputs.value.filter((item) => item.value !== '');
        if (!countInputs.value.length) {
          countInputs.value.push({ value: '', input: 0 });
        }
      }
      if (countInputs.value.length && countInputs.value[0].value !== '') {
        if (user.value) {
          const IDs: string[] = [];
          countInputs.value.forEach((value) => {
            if (value.value.includes('youtu.be')) {
              IDs.push('&id=' + value.value.split('/').filter(Boolean)[2]);
            } else {
              IDs.push(`&id=${value.value.split('=')[1]}`);
            }
          });
          await controller.youtube.getVideosInfo(user.value.id, IDs).then((data) => {
            videosInfo.value = [];
            for (let i = 0; i < data.videos.items.length; i += 1) {
              const item = {
                publishedAt: data.videos.items[i]?.snippet?.publishedAt,
                title: data.videos.items[i]?.snippet.title,
                description: data.videos.items[i]?.snippet.description,
                thumbnails: data.videos.items[i]?.snippet.thumbnails,
                statistics: data.videos.items[i]?.statistics,
                url: countInputs.value[i]?.value,
                vid: data.videos.items[i]?.id,
                photos: [data.videos.items[i]?.snippet.item?.thumbnails?.maxres?.url
                || data.videos.items[i]?.snippet.item?.thumbnails?.standard.url
                || data.videos.items[i]?.snippet.thumbnails?.high.url || data.videos.items[i]?.snippet.thumbnails?.medium.url
                || data.videos.items[i]?.snippet.thumbnails?.default.url],
              };
              videosInfo.value.push(item);
            }
          });
          steep.value += 1;
        }
      }
      loadingVideoInfo.value = false;
    };
    const changeItemPiece = (item: any) => {
      videosInfo.value.splice(item.value.index, 1, item.value);
    };
    const submitContentItems = async () => {
      uploadingContent.value = true;
      if (user.value) {
        // eslint-disable-next-line no-restricted-syntax
        for await (const item of videosInfo.value) {
          await content.manager.createContent(user.value.id, item, ''); // FIXME campaignID
        }
        uploadingContent.value = false;
      }
    };
    const removePiece = (index: any) => {
      videosInfo.value.splice(index, 1);
      if (!videosInfo.value.length) {
        steep.value -= 1;
        videosInfo.value.push({ value: '', input: 0 });
      }
      return videosInfo;
    };
    return {
      user,
      uploadingContent,
      videosInfo,
      removePiece,
      loadingVideoInfo,
      countInputs,
      changeValueUrl,
      steep,
      verifyUrls,
      controller,
      changeItemPiece,
      submitContentItems,
    };
  },
};
