
import {defineComponent, computed, ref} from 'vue';

export default defineComponent({
  name: 'UserTypeToggle',
  components: { },

  props: {
    isCreator: Boolean,
    disabled: Boolean,
  },

  emits: ['update:isCreator'],

  setup(props, { emit }) {
    const active = ref(0);
    const selection = computed({
      get: () => props.isCreator,
      set: (newValue) => {
        emit('update:isCreator', newValue);
      },
    });

    return {
      active,
      selection,
    };
  },
});
