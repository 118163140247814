<template>
  <NewTermsOfUse v-if="isNewLegalInfoEnabled" :handleRouting="handleRouting"/>
  <OldTermsOfUse v-else/>
</template>

<script>
import OldTermsOfUse from "@/pages/auth/OldTermsOfUse.vue";
import NewTermsOfUse from "@/react_app/NewLegalInfo/NewTermsOfUse/NewTermsOfUse";
import { applyPureReactInVue } from 'veaury'
import { isNewLegalInfoEnabled } from '@/utils/globals/feature-flags';

export default {
  components: {
    NewTermsOfUse: applyPureReactInVue(NewTermsOfUse),
    OldTermsOfUse
  },
  setup() {
    const handleRouting = (name) => {
      window.location.href = `/${name}`;
    };

    return {
      isNewLegalInfoEnabled,
      handleRouting
    };
  },
};
</script>
