import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewPayments = _resolveComponent("NewPayments")!
  const _component_OldPayments = _resolveComponent("OldPayments")!

  return ($setup.isNewAdminEnabled)
    ? (_openBlock(), _createBlock(_component_NewPayments, {
        key: 0,
        changeStatusQuery: $setup.changeStatusQuery,
        transfers: $setup.transfers,
        limit: $setup.limit,
        pagination: $setup.totalCount,
        changePage: $setup.pageChange,
        search: $setup.searchPayments,
        openInvoice: $setup.openInvoice,
        loadingUpload: $setup.loadingUpload,
        uploadInvoice: $setup.uploadInvoice,
        changeTransferStatus: $setup.changeTransferStatus,
        loading: $setup.loading,
        byMonth: $setup.byMonth,
        changeMonth: $setup.handleChangeMonth,
        searchValue: $setup.searchValue
      }, null, 8, ["changeStatusQuery", "transfers", "limit", "pagination", "changePage", "search", "openInvoice", "loadingUpload", "uploadInvoice", "changeTransferStatus", "loading", "byMonth", "changeMonth", "searchValue"]))
    : (_openBlock(), _createBlock(_component_OldPayments, { key: 1 }))
}