
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CampaignsCounterItem',
  props: {
    name: {
      type: String,
      default: '',
    },
    counter: {
      type: String,
      default: '',
    },
  },
});
