<template>
  <verefy-creator-data v-if="creatorNeedUpdateName" />

  <div :class="containerClass" @click="onWrapperClick" v-if="user && !creatorNeedUpdateName">
    <PageTopBar @menu-toggle="onMenuToggle" />

    <transition name="layout-sidebar" v-if="!isSmallSidebar">
      <div class="layout-sidebar layout-sidebar-light p-d-flex p-flex-column" v-show="isSidebarVisible()"
        @click="onSidebarClick">
        <div class="layout-logo" style="margin-left: 24rem">
          <router-link to="/">
            <img alt="Logo" src="../../assets/images/logo.png" width="133" />
          </router-link>
        </div>
        <PageMenu :isSmallSidebar="isSmallSidebar" class="page-menu" :items="menuItems"
          @menuitem-click="onMenuItemClick" />

        <div class="logout p-mt-auto p-d-flex p-justify-end" 
        @click="isSmallSidebar = !isSmallSidebar" v-if="false">
          <img class="p-mb-6 p-mr-5" src="../../assets/images/previous.svg" />
        </div>
      </div>
    </transition>

    <transition name="layout-sidebar" v-if="isSmallSidebar">
      <div class="layout-sidebar small line layout-sidebar-light p-d-flex p-flex-column p-ai-center"
        :class="{ 'background-dark': isNewDesignEnabled }" v-show="isSidebarVisible()" @click="onSidebarClick">
        <div class="layout-logo">
          <router-link to="/app/dashboard">
            <img alt="Logo" :src="isNewDesignEnabled ? require('../../assets/images/small-logo-red.svg') :
              require('../../assets/images/small-logo.svg')" width="45" height="52" />
          </router-link>
        </div>
        <PageMenu :isSmallSidebar="isSmallSidebar" class="page-menu" :items="menuItems"
          @menuitem-click="onMenuItemClick" />
        <div class="logout p-mt-auto p-d-flex p-justify-center p-flex-wrap" @click="isSmallSidebar = !isSmallSidebar"
          v-if="false">
          <img class="p-mb-6" src="../../assets/images/next.svg" />
        </div>
      </div>
    </transition>
    <div
      :class="{ 'layout-main-small': isSmallSidebar, 'layout-main': !isSmallSidebar, 'layout-main-darkBackground': isNewDesignEnabled }"
      class="layout-main ${}">
      <router-view />
    </div>

    <!--    <div class="open-welcome-button">-->
    <!--      <Button icon="pi pi-question" class="p-button-rounded p-button-success" @click="showWelcomeModal = true"/>-->
    <!--    </div>-->

    <welcome-modal ref="welcomeModalRef" v-model:show="showWelcomeModal" />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useGlobalState } from '../../store/store';
import { useAuth, useMenu } from '../../modules';
import WelcomeModal from '../commons/modals/WelcomeModal.vue';
import PageTopBar from './PageTopBar.vue';
import PageMenu from './PageMenu.vue';
import VerefyCreatorData from '../creators/profile/VerefyCreatorData.vue';

export default {
  components: {
    VerefyCreatorData,
    WelcomeModal,
    PageTopBar,
    PageMenu,
  },
  data: () => ({
    overlayMenuActive: false,
    mobileMenuActive: false,
    staticMenuInactive: false,
  }),

  computed: {
    containerClass() {
      return [
        'layout-wrapper', 'layout-static', 'p-input-filled-no', 'p-ripple-disabled',
        {
          'layout-static-sidebar-inactive': this.staticMenuInactive,
          'layout-mobile-sidebar-active': this.mobileMenuActive,
        },
      ];
    },
  },

  methods: {
    isDesktop() {
      return window.innerWidth > 1024;
    },

    isSidebarVisible() {
      if (this.isDesktop()) {
        return !this.staticMenuInactive;
      }

      return true;
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onSidebarClick() {
      this.menuClick = true;
    },

    onMenuToggle(event) {
      this.menuClick = true;

      if (this.isDesktop()) {
        this.staticMenuInactive = !this.staticMenuInactive;
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },

    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
  },

  setup() {
    const { state } = useGlobalState();
    const { user } = useAuth();
    const menu = useMenu();
    const displayModal = ref(false);
    const isSmallSidebar = ref(true);
    const openModal = () => {
      displayModal.value = true;
    };
    const closeDisplayModal = () => {
      displayModal.value = false;
    };
    // welcome modal
    const showWelcomeModal = ref(!!localStorage.getItem('show-welcome'));
    watch(showWelcomeModal, () => {
      if (!showWelcomeModal.value) {
        localStorage.removeItem('show-welcome');
      }
    });
    watch(isSmallSidebar, () => {
      const event = new CustomEvent('isSmallSidebar', { detail: isSmallSidebar.value });
      dispatchEvent(event);
    });

    const creatorNeedUpdateName = computed(() => user.value?.name === user.value?.email);

    const isNewDesignEnabled = localStorage.getItem('new-design-toggle') === 'activated';

    return {
      creatorNeedUpdateName,
      isSmallSidebar,
      state,
      user,
      menuItems: menu.items,
      // welcome
      showWelcomeModal,
      displayModal,
      openModal,
      closeDisplayModal,
      isNewDesignEnabled,
    };
  },
};
</script>

<style lang="scss" scoped>
.layout-sidebar {
  z-index: 1;
  text-align: center;
  .page-menu {
    margin-top: 47rem;
  }

  .create-campaign {
    background: #FFFFFF;
    box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 34rem;

    display: flex;
    align-items: center;

    margin-top: 60rem;
    width: 220rem;

    cursor: pointer;

    .plus {
      background: #E0010B;
      box-shadow: 5px 4px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      width: 34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: #fff;
      margin-right: 24rem;
      transition: opacity .3s ease;
    }

    .plus-small {
      background: #E0010B;
      box-shadow: 5px 4px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      width: 34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: #fff;
      transition: opacity .3s ease;
    }

    .text {
      @include font-montserrat();
      font-weight: bold;
      font-size: 14rem;
      color: $giDarkGrey;
    }

    &:hover {
      .plus {
        opacity: .7;
      }
    }
  }

  .logout {
    cursor: pointer;
    width: 100%;
  }
}

.open-welcome-button {
  position: fixed;
  bottom: 30rem;
  right: 30rem;
  z-index: 100;
}

// old
.p-toast.p-toast-topright {
  z-index: 1000;
  top: 70px;
}

.layout-main {
  z-index: 2; // higher than sidebar
  transition: margin-left $transitionDuration;
  padding: 80rem 42rem 42rem 42rem;
  flex-grow: 1;
  display: flex;
  box-shadow: 0 0 20rem 0 rgba(0, 0, 0, 0.16);
  position: relative;
}

.layout-main-darkBackground {
  background: #130A23;
}

.layout-sidebar {
  overflow: hidden;
  position: fixed;
  width: $sidebarWidth;
  height: 100%;
  overflow-y: auto;
  user-select: none;
  transition: transform $transitionDuration;

  &.layout-sidebar-enter-from,
  &.layout-sidebar-leave-to {
    transform: translateX(-100%);
  }

  .layout-logo {
    margin-top: 1em;
  }

  .menuitem-badge {
    display: inline-block;
    margin-left: 4px;
    font-size: 10px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    color: $menuitemBadgeColor;
    background-color: $menuitemBadgeBgColor;
    border-radius: 50%;
  }
}

.small {
  width: $sidebarWidthSmall !important;
}

.small-left {
  margin-left: $sidebarWidthSmall !important;
}

.layout-sidebar-light {
  background: white;
  z-index: 3;

  .layout-menu {
    >li {
      >a {
        border-top: 1px solid $menuitemBorderColor;
      }

      &:last-child {
        >a {
          border-bottom: 1px solid $menuitemBorderColor;
        }
      }

      ul {
        background-color: $menuitemActiveBgColor;
      }
    }

    li {
      a {
        color: $menuitemColor;

        &.router-link-active {
          color: $menuitemActiveRouteColor;
        }

        &:hover {
          color: $menuitemHoverColor;
        }
      }

      &.active-menuitem {
        >a {
          background-color: $menuitemActiveBgColor;
          color: $menuitemActiveColor;
        }
      }
    }
  }
}

.line {
  z-index: 100;
}

.background-dark {
  background: #130A23;
}
</style>
