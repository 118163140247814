
import { defineComponent, ref } from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { useCampaigns } from "@/modules";

export default defineComponent({
  components: {
    Button,
    InputText,
  },
  props: {
    campaign: Object,
  },
  setup(props) {
    const campaigns = useCampaigns();
    const showAddInput = ref(false);
    const showEditLink = ref(true);
    const loadingSaveLink = ref(false);
    const url = ref('');

    const saveUrl = async () => {
      loadingSaveLink.value = true;
      await campaigns.manager.editTrackingLink(props.campaign?.id || props.campaign?._id, url.value, false);
      await campaigns.manager.loadSingle(props.campaign?.id || props.campaign?._id as string);
      loadingSaveLink.value = false;
    };
    
    const copyLink = (currentUrl: any) => {
      if (loadingSaveLink.value) return;
      navigator.clipboard.writeText(currentUrl);
    };
    return {
      showAddInput,
      showEditLink,
      loadingSaveLink,
      url,
      saveUrl,
      copyLink,
    }
  }
})
