export const minPasswordLength = 8;

export const minUsernameLength = 4;


export const MESSAGE_WRAPPER = {
  'firstName': "First name is required.",
  'lastName': "Last name is required.",
  'brandName': "Brand name is required.",
  'creatorName': "Creator name is required",
  'username': `The name must be at least ${minUsernameLength} characters long.`,
  'invalidUsername': 'This field must not include the @ symbol.',
  'email': "Email is required.",
  'invalidEmail': "Invalid Email.",
  'password': `Password must be at least ${minPasswordLength} characters long.`,
  'verificationCode': "Failed to send verification code."
}

export const FILES = { CAMPAIGN_COVER_IMAGE: 1.1 };
export const IMAGE_FORMAT = '.png,.jpg,.jpeg,.gif';
export const FILE_DIMENSION = { height: 1300, width: 2400 };

export const TYPE_OPTIONS = [
  {
    label: 'Influencer',
    value: 'influencer',
    title: 'Login as an influencer gives you access to campaigns published by gaming companies, developers and publishers.',
    subTitle:"Earn money by applying to campaigns and delivering meaningful content."
  },
  {
    label: 'Brand',
    value: 'brand',
    title: 'Login as a brand gives you access to campaigns creation and collaborations with influencers to promote your games.',
    subTitle:'Push your game to the next level by running scalable campaigns, reaching new audiences and increasing your ROI.'
  },
];
