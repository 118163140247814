import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-084ed1b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "campaign-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_campaign_photo = _resolveComponent("campaign-photo")!

  return (_openBlock(), _createElementBlock("div", {
    class: "campaign-short-preview",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'campaign-active', params: { campaignID: _ctx.campaign.id } })))
  }, [
    _createVNode(_component_campaign_photo, {
      campaign: _ctx.campaign,
      class: "campaign-photo"
    }, null, 8, ["campaign"]),
    _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.campaign.name), 1)
  ]))
}