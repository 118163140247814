
import { defineComponent, reactive, ref, onMounted } from 'vue';
import {
  AppPageConfig,
  AppPageProps,
  CampaignData,
  CampaignList,
} from '@/data/types';
import { usePage, useCampaigns, useAuth } from '@/modules';
import { compactNumber } from '@/utils/globals/curency-utils';
import { formatDate } from '@/utils/globals/date-utils';
import { applyPureReactInVue } from 'veaury';
import { isNewCampaignsManagementEnabled } from '@/utils/globals/feature-flags';
import OldCampaignsManagement from '@/pages/campaigns/OldCampaignsManagement.vue';
import NewCampaignsManagement from '@/react_app/NewCampaignsManagement/NewCampaignsManagement';
import router from '@/router';

export default defineComponent({
  components: {
    NewCampaignsManagement: applyPureReactInVue(NewCampaignsManagement),
    OldCampaignsManagement,
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 10,
    },
    brandOwnerView: Boolean,
    ...AppPageProps,
    private: Boolean,
  },

  setup(props) {
    const { user } = useAuth();
    const { config } = usePage<AppPageConfig>({
      search: {
        enabled: props.showSearch,
        defaultType: 'campaigns',
        placeholder: 'Search for Campaign',
      },
      title: 'Campaign Management',
      ...props,
    });
    const editDate = ref(false);
    const page = useCampaigns(config.value.viewPerspective);
    const campaigns = ref<Array<CampaignData>>([]);
    const filteredCampaigns = ref<Array<CampaignData>>([]);
    const isAllSelected = ref();
    const platformsOptions = ref([
      {
        value: 'Active Campaigns',
        type: 'active',
        selected: false,
      },
      {
        value: 'Drafts',
        type: 'draft',
        selected: false,
      },
      {
        value: 'Completed',
        type: 'completed',
        selected: false,
      },
      {
        value: 'Pending',
        type: 'pending',
        selected: false,
      },
    ]);
    const selectedAll = ref(false);
    const skip = ref(0);
    const totalRecords = ref(0);
    const status = ref('');
    const searchValue = ref('');
    const isLoading = ref(true);
    const counters = reactive({ campaigns: 0, contracts: 0, applications: 0 });

    const getCampaigns = (
      skip: number,
      currentStatus: string,
      search?: string
    ) => {
      isLoading.value = true;
      page.list
        .getCampaignList(false, props.limit, skip, search, currentStatus)
        .then((data?: CampaignList) => {
          if (data?.campaigns) {
            campaigns.value = data?.campaigns;
            filteredCampaigns.value = data?.campaigns;
            totalRecords.value = data?.pagination;
            const contracts = data?.campaigns
              .map((m: any) => m.contracts)
              .flat();
            const applications = data?.campaigns
              .map((m: any) => m.applications)
              .flat();
            counters.campaigns = data?.campaigns.filter(
              (campaign) => campaign?.status === 'active'
            ).length;
            counters.contracts = contracts.length;
            counters.applications = applications.length;
            isLoading.value = false;
          } else {
            campaigns.value = [];
            filteredCampaigns.value = [];
            isLoading.value = false;
          }
        });
    };
    onMounted(() => {
      getCampaigns(skip.value, status.value, '');
    });

    const sendName = () => {
      filteredCampaigns.value = campaigns.value;
      const oneSelectedOrMore = platformsOptions.value.find(
        (el) => el.selected
      );
      if (!oneSelectedOrMore) {
        filteredCampaigns.value = campaigns.value;
        return;
      }
      let allCampaigns: any = [];
      if (platformsOptions.value[0].selected === true) {
        allCampaigns = allCampaigns.concat(
          campaigns.value.filter(
            (campaign: { status: string }) => campaign?.status === 'active'
          )
        );
      }
      if (platformsOptions.value[1].selected === true) {
        allCampaigns = allCampaigns.concat(
          campaigns.value.filter(
            (campaign: { status: string }) => campaign?.status === 'draft'
          )
        );
      }
      if (platformsOptions.value[2].selected === true) {
        allCampaigns = allCampaigns.concat(
          campaigns.value.filter(
            (campaign: { status: string }) => campaign?.status === 'completed'
          )
        );
      }
      if (platformsOptions.value[3].selected === true) {
        allCampaigns = allCampaigns.concat(
          campaigns.value.filter(
            (campaign: { status: string }) => campaign?.status === 'pending'
          )
        );
      }
      filteredCampaigns.value = allCampaigns;
    };
    const selectAllPlatforms = () => {
      selectedAll.value = !selectedAll.value;
      if (selectedAll.value) {
        platformsOptions.value.forEach((el) => (el.selected = true));
        filteredCampaigns.value = campaigns.value;
      } else {
        platformsOptions.value.forEach((el) => (el.selected = false));
        filteredCampaigns.value = campaigns.value;
      }
    };
    const clearFilters = () => {
      platformsOptions.value.forEach((el) => (el.selected = false));
      selectedAll.value = false;
    };
    const addPlatform = (element: any) => {
      const select = platformsOptions.value.findIndex(
        (el) => el.value === element.value
      );
      if (platformsOptions.value[select].selected) {
        platformsOptions.value[select].selected = false;
      } else {
        platformsOptions.value[select].selected = true;
      }
    };
    const handleSearch = (search: string) => {
      skip.value = 0;
      searchValue.value = search;
      getCampaigns(0, status.value, search);
    };

    const handleRoute = (route: string) => {
      router.push({ name: route });
    };

    const handleChangeStatus = (statusType: string) => {
      skip.value = 0;
      const currentStatus = statusType === 'all' ? '' : statusType;
      status.value = currentStatus;
      searchValue.value = '';
      getCampaigns(0, currentStatus, '');
    };

    const handlePageChange = (page: number) => {
      skip.value = page;
      getCampaigns(page, status.value, searchValue.value);
    };

    const goToCampaign = (id: string) => {
      router.push({ name: 'campaigns-stats', params: { campaignID: id } });
    };

    const pushDraft = async (id: string) => {
      await router.push({
        name: 'campaigns-stats',
        params: {
          campaignID: id,
        },
      })
    }

    return {
      viewPerspective: config.value.viewPerspective,
      sendName,
      filteredCampaigns,
      campaigns,
      handleSearch,
      selectedAll,
      addPlatform,
      clearFilters,
      selectAllPlatforms,
      platformsOptions,
      editDate,
      config,
      // loadPageData,
      loading: page.list.loading,
      isAllSelected,
      counters,
      compactNumber,
      formatDate,
      user,
      isNewCampaignsManagementEnabled,
      handleRoute,
      handleChangeStatus,
      goToCampaign,
      totalRecords,
      handlePageChange,
      isLoading,
      pushDraft
    };
  },
});
