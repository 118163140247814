
import { ref, watch, reactive } from 'vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import { getUTCTimestampFromDate } from '@/utils/globals/date-utils';
import { useCampaigns } from '@/modules';
import { countries, gigapaySupportedCountries } from '@/data/static/countries';

export default {
  components: {
    Dialog,
    InputText,
    Button,
    Calendar,
    Dropdown,
    InputSwitch,
  },
  props: {
    showModal: Boolean,
    campaignId: String,
    isCreator: {
      type: Boolean,
      default: false,
    },
    user: Object,
  },
  setup(props: any, ctx: any) {
    const mangopayObject = ref(props?.user?.mangopay);
    const checkedUser = ref(!(mangopayObject.value?.PersonType === 'NATURAL'));
    const campaigns = useCampaigns();
    const FirstName = ref(mangopayObject.value?.FirstName || ''); // 'Joe'
    const LastName = ref(mangopayObject.value?.LastName || ''); // 'Blogs'
    const iban = ref(mangopayObject.value?.IBAN || ''); // 'FR7630004000031234567890143'
    const T = new Date();
    T.setFullYear(T.getFullYear() - 18);
    const Birthday = ref(new Date(mangopayObject.value?.Birthday || T)); // 1114933008486

    const userNationalityFilter = ref(countries.filter((obj) => obj.code === mangopayObject.value?.Nationality)[0]);
    const userCountryOfResidenceFilter = ref(countries.filter((obj) => obj.code === mangopayObject.value?.CountryOfResidence)[0]);

    const Nationality = ref(userNationalityFilter.value);
    const CountryOfResidence = ref();
    const displayModalInfo = ref(props.showModal);

    watch(displayModalInfo, () => {
      ctx.emit('hide');
    });

    async function handleSubmit() {
      displayModalInfo.value = false;
      const payload = {
        PersonType: 'NATURAL',
        FirstName: FirstName.value,
        LastName: LastName.value,
        Birthday: getUTCTimestampFromDate(Birthday.value),
        Nationality: Nationality.value?.code,
        CountryOfResidence: CountryOfResidence.value?.code,
      };
      ctx.emit('paymentInfoChange', payload);
      if (props.campaignId) {
        campaigns.manager.loadSingle(props.campaignId).then((campaign: any) => {
          const paymentDataUpdate = campaign?.paymentInformation;
          paymentDataUpdate.PersonType = 'NATURAL';
          paymentDataUpdate.FirstName = FirstName.value;
          paymentDataUpdate.LastName = LastName.value;
          paymentDataUpdate.Birthday = getUTCTimestampFromDate(Birthday.value);
          paymentDataUpdate.Nationality = Nationality.value?.code;
          paymentDataUpdate.CountryOfResidence = CountryOfResidence.value?.code;
          campaigns.manager.update(props.campaignId, {
            paymentInformation: paymentDataUpdate,
          });
        });
      }
    }

    const LegalPersonType = ref(mangopayObject.value?.LegalPersonType || localStorage.getItem('LegalPersonType') || 'BUSINESS');
    const LegalRepresentativeAddress = reactive(mangopayObject.value?.LegalRepresentativeAddress
        || JSON.parse(localStorage.getItem('LegalRepresentativeAddress') as string) || {
        AddressLine1: '', // 1 Mangopay Street
        City: '', // 'Paris'
        PostalCode: '', // 75001
        Country: '',
      });
    const LegalRepresentativeCountryOfResidence = ref(userCountryOfResidenceFilter.value
        || JSON.parse(localStorage.getItem('LegalRepresentativeCountryOfResidence') as string));
    const LegalRepresentativeNationality = ref(userNationalityFilter.value
        || JSON.parse(localStorage.getItem('LegalRepresentativeNationality') as string));
    const LegalRepresentativeFirstName = ref(mangopayObject.value?.FirstName
        || localStorage.getItem('LegalRepresentativeFirstName') || '');
    const LegalRepresentativeLastName = ref(mangopayObject.value?.LastName
        || localStorage.getItem('LegalRepresentativeLastName') || '');

    watch([LegalRepresentativeCountryOfResidence, LegalRepresentativeNationality,
      LegalRepresentativeFirstName, LegalRepresentativeLastName, LegalRepresentativeAddress, LegalPersonType], () => {
      localStorage.setItem('LegalPersonType', LegalPersonType.value);
      localStorage.setItem('LegalRepresentativeAddress', JSON.stringify(LegalRepresentativeAddress));
      localStorage.setItem('LegalRepresentativeCountryOfResidence', JSON.stringify(LegalRepresentativeCountryOfResidence.value));
      localStorage.setItem('LegalRepresentativeNationality', JSON.stringify(LegalRepresentativeNationality.value));
      localStorage.setItem('LegalRepresentativeFirstName', LegalRepresentativeFirstName.value);
      localStorage.setItem('LegalRepresentativeLastName', LegalRepresentativeLastName.value);
    });

    async function handleSubmitLegal() {
      displayModalInfo.value = false;
      const payload = {
        PersonType: 'LEGAL',
        LegalPersonType: LegalPersonType.value,
        LegalRepresentativeAddress: {
          AddressLine1: LegalRepresentativeAddress.AddressLine1,
          City: LegalRepresentativeAddress.City,
          PostalCode: LegalRepresentativeAddress.PostalCode,
          Country: LegalRepresentativeAddress.Country?.code,
        },
        IBAN: iban.value,
        Birthday: getUTCTimestampFromDate(Birthday.value),
        CountryOfResidence: LegalRepresentativeCountryOfResidence.value?.code,
        Nationality: LegalRepresentativeNationality.value?.code,
        FirstName: LegalRepresentativeFirstName.value,
        LastName: LegalRepresentativeLastName.value,
      };
      payload.LegalRepresentativeAddress.Country = LegalRepresentativeAddress.Country?.code;
      ctx.emit('paymentInfoChange', payload);

      if (props.campaignId) {
        campaigns.manager.loadSingle(props.campaignId).then((campaign: any) => {
          const paymentDataUpdate = campaign?.paymentInformation;
          paymentDataUpdate.PersonType = 'LEGAL';
          paymentDataUpdate.FirstName = LegalRepresentativeFirstName.value;
          paymentDataUpdate.LastName = LegalRepresentativeLastName.value;
          paymentDataUpdate.Birthday = getUTCTimestampFromDate(Birthday.value);
          paymentDataUpdate.Nationality = LegalRepresentativeNationality.value?.code;
          paymentDataUpdate.CountryOfResidence = LegalRepresentativeCountryOfResidence.value?.code;
          paymentDataUpdate.LegalRepresentativeAddress = LegalRepresentativeAddress;
          paymentDataUpdate.LegalRepresentativeAddress.Country = LegalRepresentativeAddress.Country?.code;
          campaigns.manager.update(props.campaignId, {
            paymentInformation: paymentDataUpdate,
          });
        });
      }
    }

    const influencerData = () => {
      displayModalInfo.value = false;
      const payload = {
        PersonType: 'NATURAL',
        FirstName: FirstName.value,
        LastName: LastName.value,
        Birthday: getUTCTimestampFromDate(Birthday.value),
        IBAN: iban.value,
        Nationality: Nationality.value?.code,
        CountryOfResidence: CountryOfResidence.value?.code,
        LegalRepresentativeAddress: {
          AddressLine1: LegalRepresentativeAddress.AddressLine1,
          City: LegalRepresentativeAddress.City,
          PostalCode: LegalRepresentativeAddress.PostalCode,
          Country: LegalRepresentativeAddress.Country?.code,
        },
      };
      ctx.emit('changeInfo', payload);
    };
    const influencerLegaldata = () => {
      displayModalInfo.value = false;
      const payload = {
        PersonType: 'LEGAL',
        LegalPersonType: LegalPersonType.value, // FIXME dont work with this save
        LegalRepresentativeAddress: {
          AddressLine1: LegalRepresentativeAddress.AddressLine1,
          City: LegalRepresentativeAddress.City,
          PostalCode: LegalRepresentativeAddress.PostalCode,
          Country: LegalRepresentativeAddress.Country?.code,
        },
        IBAN: iban.value,
        Birthday: getUTCTimestampFromDate(Birthday.value),
        CountryOfResidence: LegalRepresentativeCountryOfResidence.value?.code,
        Nationality: LegalRepresentativeNationality.value?.code,
        FirstName: LegalRepresentativeFirstName.value,
        LastName: LegalRepresentativeLastName.value,
      };
      payload.LegalRepresentativeAddress.Country = LegalRepresentativeAddress.Country?.code;
      ctx.emit('changeInfoLegal', payload);
    };

    return {
      influencerLegaldata,
      influencerData,
      handleSubmitLegal,
      checkedUser,
      campaigns,
      displayModalInfo,
      handleSubmit,
      countries,
      gigapaySupportedCountries,
      FirstName,
      LastName,
      Birthday,
      Nationality,
      CountryOfResidence,
      // ****
      LegalPersonType,
      LegalRepresentativeAddress,
      LegalRepresentativeCountryOfResidence,
      LegalRepresentativeNationality,
      LegalRepresentativeFirstName,
      LegalRepresentativeLastName,
      iban,
    };
  },
};
