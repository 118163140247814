
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Password from 'primevue/password';
import { ref, computed, defineComponent } from 'vue';
import { useAuth } from "@/modules";

export default defineComponent({
  props: {
    show: Boolean,
  },
  components: {
    InputText,
    InputNumber,
    Password,
    Button,
    Dialog,
  },
  setup(props, ctx) {
    const authModule = useAuth();

    const email = ref('');
    const newPassword = ref('');
    const newPasswordConfirm = ref('');
    const restoreCode = ref();
    const steepRestore = ref(0);
    const vereficationCodeBad = ref('');
    const invalidEmail = ref(false);

    const emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const validNewPasswords = computed(() => newPassword.value?.length > 7 && newPassword.value === newPasswordConfirm.value);
    const emailInvalid = computed(() => !emailRegEx.test(email.value));

    const sendCode = () => {
      if (!emailInvalid.value) {
        authModule.restorePassword(email.value, 'create-code').then((d) => {
          if (d !== 'invalid email') {
            steepRestore.value += 1;
            invalidEmail.value = false;
          } else {
            invalidEmail.value = true;
          }
        });
      }
    };

    const checkAndChangePassword = () => {
      if (validNewPasswords.value) {
        authModule.restorePassword(email.value, 'check-code', restoreCode.value, newPassword.value).then((data) => {
          if (data === 'The verification code is invalid') {
            vereficationCodeBad.value = data;
          } else {
            ctx.emit('restored');
          }
        });
      }
    };

    return {
      sendCode,
      checkAndChangePassword,
      invalidEmail,
      vereficationCodeBad,
      emailInvalid,
      validNewPasswords,
      newPassword,
      newPasswordConfirm,
      steepRestore,
      restoreCode,
      email,
    };
  }
})
