
import { ref, defineComponent } from "vue";
import { useRoute } from "vue-router";
import Paginator from "primevue/paginator";
import { useAdmins, useAuth, useCampaigns } from "../../modules";
import { UserType } from "../../data/types/user-types";
import ContentItemsListItem from "../content/ContentItemsListItem.vue";
import { ContentItemData } from "@/data/types";

export default defineComponent({
  name: "CampaignContentList",
  components: {
    Paginator,
    ContentItemsListItem,
  },
  setup() {
    const route = useRoute();
    const skip = ref(0);
    const limit = ref(10);
    const searchValue = ref("");
    const campaign = ref();
    const campaignContent = ref();

    const adminModule = useAdmins();
    const campaignsModule = useCampaigns();
    const { user } = useAuth();

    adminModule.manager.getCampaignContent(route.params.campaignId.toString(), limit.value, skip.value, searchValue.value).then((response: any) => {
      const { pagination } = response;
      let { campaignContent: campaignContentResponse } = response;

      campaignContentResponse = campaignContentResponse?.filter((content: any) => content?.public);

      campaignContent.value = { pagination, campaignContent: campaignContentResponse };
    });

    const onPage = (event: { page: number; first: number; rows: number; pageCount: number }) => {
      skip.value = event.first;

      adminModule.manager.getCampaignContent(route.params.campaignId.toString(), limit.value, skip.value, searchValue.value).then((response: any) => {
        const { pagination } = response;
        let { campaignContent: campaignContentResponse } = response;

        campaignContentResponse = campaignContentResponse?.filter((content: any) => content?.public);

        campaignContent.value = { pagination, campaignContent: campaignContentResponse };
      });
    };

    if (user.value?.type === UserType.Admin) {
      campaignsModule.manager.loadSingle(route.params.campaignId.toString()).then((camp) => {
        campaign.value = camp;
      });
    }

    return {
      onPage,
      skip,
      limit,
      searchValue,
      campaignContent,
      campaign: campaignsModule.manager.singleCampaign,
    };
  },
});
