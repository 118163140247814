import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewBrandPhoto = _resolveComponent("NewBrandPhoto")!
  const _component_OldBrandPhoto = _resolveComponent("OldBrandPhoto")!

  return (_ctx.isNewBrandEnabled)
    ? (_openBlock(), _createBlock(_component_NewBrandPhoto, { key: 0 }))
    : (_openBlock(), _createBlock(_component_OldBrandPhoto, {
        key: 1,
        brand: _ctx.brand
      }, null, 8, ["brand"]))
}