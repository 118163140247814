
import { defineComponent, ref } from "vue";
import { useAdmins } from "@/modules";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import TextArea from 'primevue/textarea';
import Column from "primevue/column";
import Dialog from 'primevue/dialog';
import Dropdown from "primevue/dropdown";
import { formatDate } from "../../utils/globals/date-utils";
import { useCampaignApplications } from "@/modules/campaigns/campaign-applications-module";
import { CampaignApplicationData, Perspective, User } from "@/data/types";
import { useToastModule } from "@/modules";

interface ProposalInfo {
  campaignId: string,
  applicationId: string,
  channelId?: string,
  payoutCap: number,
  campaignBudget?: CampaignBudget,
}

interface CampaignBudget {
  currency: string,
  amount: number,
  amountLeft: number,
}

export default defineComponent({
  components: {
    Button,
    DataTable,
    Column,
    Dropdown,
    Dialog,
    TextArea,
  },
  setup() {
    const {
      manager: { getCpmCampaignApplications, getCpmCampaignApplicationsResult },
    } = useAdmins();
    const { rejectApplication, acceptApplication, proposeIndividualCap } = useCampaignApplications(Perspective.ADMIN);
    const toast = useToastModule();
    const displayCapProposalDialog = ref(false);
    const currentProposalInfo = ref<ProposalInfo>({campaignId: "", applicationId: "", channelId: "", payoutCap: 0});

    const selectedStatusFilter = ref("pending");
    const statusFilterValues = [
      { name: "Pending", value: "pending" },
      { name: "Accepted", value: "accepted" },
      { name: "Rejected", value: "rejected" },
    ];

    (async () => { 
      await getCpmCampaignApplications(50, 0, "pending");
      
      if (getCpmCampaignApplicationsResult.value.length > 0) {
        getCpmCampaignApplicationsResult.value = getCpmCampaignApplicationsResult.value.slice().sort((itemOne: CampaignApplicationData, itemTwo: CampaignApplicationData) => {
          return new Date(itemOne.createdAt).getTime() - new Date(itemTwo.createdAt).getTime();
        })
      }
    })()

    const changeStatusQuery = () => {
      getCpmCampaignApplications(50, 0, selectedStatusFilter.value);
    };

    const resolveApplication =
      async (campaignId: string, applicationId: string, contractWork: boolean, action: "approve" | "reject", campaignBudget: CampaignBudget, creatorId?: string, channelId?: string) =>
      {
        const resolveApplicationFn = action === "approve" ? acceptApplication : rejectApplication;

        resolveApplicationFn(campaignId, applicationId, contractWork, creatorId, channelId).then((res) => {
          
          if(res !== undefined){            
              toast.showSuccess("Participation request successfully handled.");
              getCpmCampaignApplications(50, 0, 'pending');
              if (action === "approve") displayCapProposalDialog.value = true;        
          }
        }).catch((err: Error) => {
          toast.showError("Unable to resolve participation. Reason: " + err);
        });
        currentProposalInfo.value.applicationId = applicationId;
        currentProposalInfo.value.campaignId = campaignId;
        currentProposalInfo.value.campaignBudget = campaignBudget;

        if (channelId) {
          currentProposalInfo.value.channelId = channelId;
        } else {
          currentProposalInfo.value.channelId = '';
        }
      };

    const displayChannelTitle = (userId: Partial<User>, channelId: string) => {
      if (!userId || !channelId) {
        return "N/A";
      }

      return userId.agencyWorkers?.find((worker) => worker.channel.channelId === channelId)?.channel?.title;
    };

    const parseUserType = (userType: "creator" | "agencyOwner") => {
      return userType === "agencyOwner" ? "Agency Owner" : "Creator";
    };

    const parseDate = (date: string) => {
      return date?.split("T")[0];
    };

    const createIndividualCapProposal = (proposalInfo: ProposalInfo) => {
      const { campaignId, applicationId, payoutCap, channelId } = proposalInfo;

      if (isNaN(payoutCap)) {
        toast.showError("Unable to forward a proposal. The proposed cap needs to be a valid number.");
        return;
      }

      proposeIndividualCap(campaignId, applicationId, payoutCap, channelId).then(() => {
        toast.showSuccess("Successfully forwaded an individual payout cap for this participation.");
        getCpmCampaignApplications(50, 0, selectedStatusFilter.value);
        displayCapProposalDialog.value = false;
      }).catch((err) => {
        toast.showError("Unable to forward a proposal. Reason: " + err);
      });
    };

    return {
      parseDate,
      resolveApplication,
      parseUserType,
      cpmCampaignApplications: getCpmCampaignApplicationsResult,
      formatDate,
      selectedStatusFilter,
      statusFilterValues,
      displayCapProposalDialog,
      displayChannelTitle,
      createIndividualCapProposal,
      currentProposalInfo,
      getCpmCampaignApplications,
      changeStatusQuery
    };
  },
});
