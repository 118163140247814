
import { defineComponent, ref } from "vue";
import { useAdmins } from "@/modules";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import StatusChip from "@/pages/commons/status/StatusChip.vue";
import { useToastModule } from "@/modules";
import { FraudAlert } from "@/data/types/fraud-types";

export default defineComponent({
  components: {
    Button,
    StatusChip,
    DataTable,
    Column,
    Dropdown,
  },
  setup() {
    const {
      manager: { getDetectedFrauds, getDetectedFraudsResult, dismissFraud },
    } = useAdmins();
    const toast = useToastModule();
    const selectedStatus = ref<"pending" | "all">("pending");

    const statusFilterValues = [
      { name: "Pending alerts only", value: "pending" },
      { name: "All alerts", value: "all" },
    ];

    getDetectedFrauds(false);

    const getStatusDisplayValue = (dismissed: boolean) => (dismissed ? "resolved" : "pending");
    const getDetectedFraudsWithFilter = (selectedStatus: "pending" | "all") => getDetectedFrauds(selectedStatus === "all" ? true : false);

    const dismissFraudAlert = (fraudId: string) => {
      dismissFraud(fraudId)
        .then(() => {
          toast.showSuccess("Fraud alert dismissed successfully.");
          getDetectedFraudsResult.value = getDetectedFraudsResult.value.filter((fraud: FraudAlert) => fraud.id !== fraudId);
        })
        .catch((err: Error) => {
          toast.showError("Unable to dismiss fraud alert: " + err);
        });
    };

    return {
      detectedFrauds: getDetectedFraudsResult,
      getStatusDisplayValue,
      dismissFraudAlert,
      statusFilterValues,
      getDetectedFraudsWithFilter,
      selectedStatus,
    };
  },
});
