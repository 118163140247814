import { watch } from 'vue';
import { useAPI } from '@/modules';
import { AppPagePerspective, UserType } from '@/data/types';
import { useToastModule } from '@/modules/messages/toast-module';

export const useCreatorYoutube = (perspective: AppPagePerspective) => {
  const toast = useToastModule();
  const {
    loading: gettingCreatorChannels,
    error: getCreatorChannelsError,
    data: getCreatorChannelsResult,
    execute: sendGetCreatorChannelsRequest,
  } = useAPI('', false);
  const getCreatorChannels = (creatorID: any, perspectiveUser: UserType | undefined) => sendGetCreatorChannelsRequest({
    url: `/creator/${creatorID}/channels`,
    method: 'GET',
    params: { perspective: perspectiveUser },
  });
  watch(getCreatorChannelsError, toast.handleRequestError);
  // < get channels creator

  const {
    loading: getVideosInfoChannels,
    error: getVideosInfoError,
    data: getVideosInfoResult,
    execute: sendGetVideosInfoRequest,
  } = useAPI('', false);
  const getVideosInfo = (creatorID: any, IDs: string[]) => sendGetVideosInfoRequest({
    url: `/creator/${creatorID}/videos`,
    method: 'POST',
    data: { IDs },
  });
  watch(getVideosInfoError, toast.handleRequestError);
  // < get channels creator
  return {
    getCreatorChannels,
    getCreatorChannelsResult,
    getCreatorChannelsError,
    gettingCreatorChannels,
    // get channels creator
    getVideosInfo,
    getVideosInfoResult,
    getVideosInfoError,
    getVideosInfoChannels,
    // get videos info IDs
  };
};
