import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewInvitations = _resolveComponent("NewInvitations")!
  const _component_OldInvitations = _resolveComponent("OldInvitations")!

  return (_ctx.isNewCampaignInvitationsEnabled)
    ? (_openBlock(), _createBlock(_component_NewInvitations, {
        key: 0,
        campaigns: _ctx.campaigns,
        updateStatusInvitation: _ctx.updateStatusInvitation,
        goToCampaign: _ctx.goToCampaign
      }, null, 8, ["campaigns", "updateStatusInvitation", "goToCampaign"]))
    : (_openBlock(), _createBlock(_component_OldInvitations, {
        key: 1,
        viewPerspective: _ctx.config.viewPerspective
      }, null, 8, ["viewPerspective"]))
}