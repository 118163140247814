import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cba9696e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "brand-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandPhoto = _resolveComponent("BrandPhoto")!

  return (_openBlock(), _createElementBlock("div", {
    class: "brand-short-preview",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'brand', params: { brandID: _ctx.brand.id } })))
  }, [
    _createVNode(_component_BrandPhoto, {
      brand: _ctx.brand,
      class: "brand-photo"
    }, null, 8, ["brand"]),
    _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.brand.name), 1)
  ]))
}