
import {
  defineComponent, PropType, ref, watch,
} from 'vue';
import { Brand } from '@/data/types';
import { applyPureReactInVue } from 'veaury'
import { isNewBrandEnabled } from "@/utils/globals/feature-flags";
import OldEditBrandModal from "@/pages/brands/profile/OldEditBrandModal.vue";
import NewEditBrandModal from "@/react_app/NewBrand/Profile/NewEditBrandModal/NewEditBrandModal";

export default defineComponent({
  components: {
    NewEditBrandModal: applyPureReactInVue(NewEditBrandModal),
    OldEditBrandModal
  },
  props: {
    show: Boolean,
    working: Boolean,
    brand: Object as PropType<Brand>,
  },
  emits: ['save', 'update:show'],
  setup(props, { emit }) {
    const photo = ref('');
    const name = ref('');
    const description = ref('');
    const showCrop = ref(false);

    watch(props, () => {
      if (props.brand) {
        photo.value = props.brand.photo;
        name.value = props.brand.name;
        description.value = props.brand.description;
      }
    });

    // working
    const saveBrand = () => {
      emit('save', {
        photo: photo.value,
        name: name.value,
        description: description.value,
      });
    };

    const cropMainImage = (data: string) => {
      photo.value = data;
      showCrop.value = !showCrop.value;
    };

    const open = () => emit('update:show', true);

    return {
      showCrop,
      cropMainImage,
      saveBrand,
      open,
      photo,
      name,
      description,
      isNewBrandEnabled
    };
  },
});
