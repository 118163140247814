
import { ref, defineComponent } from "vue";
import paymentInformationModal from "@/pages/campaigns/create/paymentInformationModal.vue";
import CampaignsListCountersNew from "@/pages/campaigns/CampaignsListCountersNew.vue";
import moment from "moment";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import AppPage from "@/pages/commons/AppPage.vue";
import WarningModal from "@/pages/campaigns/WarningModal.vue";
import BudgetModal from "@/pages/campaigns/BudgetModal.vue";
import { useCampaigns, useContentItems, useToastModule, useUsers } from "@/modules";
import { useRoute, useRouter } from "vue-router";
import CampaignClosedCard from "@/pages/campaigns/CampaignClosedCard.vue";
import { BrandData, CampaignPaymentMethod } from "@/data/types";
import PayAndFinishModal from "./create/PayAndFinishModal.vue";
import Terms from "../static/Terms.vue";
import shortCampaignPage from "../creators/shortCampaignPage.vue";
import PaymentInformationStatus from "../commons/status/PaymentInformationStatus.vue";
import CreateCampaign from "./create/CreateCampaign.vue";
import campaignSuccessModal from "./create/campaignSuccessModal.vue";
import ContentList from "./ContentList.vue";
import AskHint from "@/pages/creators/utils/AskHint.vue";
import { formatDate } from "../../utils/globals/date-utils";
import { getGlobalCampaignByRegion } from "../../utils/campaign-utils";
import CampaignsPerformance from "../charts/CampaignsPerformance.vue";
import TrackingLinkInput from "../campaigns/parts/TrackingLinkInput.vue";

export default defineComponent({
  components: {
    Terms,
    PayAndFinishModal,
    CampaignClosedCard,
    AppPage,
    Button,
    TabView,
    TabPanel,
    TrackingLinkInput,
    CampaignsListCountersNew,
    BudgetModal,
    WarningModal,
    ContentList,
    paymentInformationModal,
    shortCampaignPage,
    campaignSuccessModal,
    PaymentInformationStatus,
    CreateCampaign,
    CampaignsPerformance,
    AskHint,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  setup() {
    const route = useRoute();
    const campaigns = useCampaigns();
    const contentItemsModule = useContentItems();
    const router = useRouter();
    const user = useUsers();
    const toast = useToastModule();
    const currentCampaign = ref<any>();
    const selectedInviteQuery = ref();

    const campaignClosed = ref(false);
    campaigns.manager.loadSingle(route.params.campaignID as string).then((camp) => {
      currentCampaign.value = camp;
      if (camp?.status === "completed") {
        campaignClosed.value = true;
      }
    });

    const showTerms = ref(false);
    const showBudgetModal = ref(false);
    const showWarningModal = ref(false);
    const cardShow = ref(false);
    const showPayment = ref(false);
    const showSuccessModal = ref(false);
    const campaignFinished = ref(false);
    const active = ref(0);
    const editCampaign = ref(false);
    const differenceout = ref();
    const remDays = ref();
    const isLoggedUserCampaignOwner = ref(false);

    campaigns.manager.loadSingle(route.params.campaignID as string).then((camp) => {
      currentCampaign.value = camp;
      const startDate = moment(camp?.dates?.startDate);
      const endDate = moment(camp?.dates?.endDate);
      const current = moment().startOf("day");
      const diff1 = moment.duration(current.diff(startDate)).asDays();
      const diff = moment.duration(endDate.diff(startDate)).asDays();
      const remainingDays = moment.duration(endDate.diff(current)).asDays();
      remDays.value = remainingDays;
      let difference = 0;
      if (startDate.isAfter(current)) {
        difference = 0;
      } else if (endDate.isBefore(current)) {
        difference = 100;
        campaignFinished.value = true;
      } else {
        difference = (diff1 / diff) * 100;
      }
      differenceout.value = difference;
      if (!camp?.dates?.endDate) {
        campaignFinished.value = true;
        difference = 100;
        differenceout.value = difference;
      }

      // Prevent users that are not the campaign owners to view the edit page of the campaign
      user.manager.loadMyProfile().then((loggedUser: any) => {
        const { brand: loggedUserBrand } = loggedUser;

        if (!loggedUser || !loggedUserBrand || !loggedUserBrand?.id) {
          isLoggedUserCampaignOwner.value = false;
          return;
        }

        const { brand: campaignBrand } = currentCampaign.value;

        if (typeof campaignBrand === "string" && loggedUserBrand.id === campaignBrand) isLoggedUserCampaignOwner.value = true;
        else if (loggedUserBrand.id === (campaignBrand as BrandData).id) isLoggedUserCampaignOwner.value = true;
        else isLoggedUserCampaignOwner.value = false;
      });
    });

    const saveEdit = () => {
      editCampaign.value = false;
      campaigns.manager.loadSingle(route.params.campaignID as string).then((camp) => {
        if (camp?.status === "completed") {
          campaignClosed.value = true;
        }
      });
    };

    const updateCampaignData = async () => {
      await campaigns.manager.loadSingle(route.params.campaignID as string);
    };

    const paymentInfoChange = async (payload: any) => {
      showPayment.value = false;
      await campaigns.manager.update(currentCampaign.value?.id, {
        paymentInformation: {
          ...currentCampaign.value?.paymentInformation,
          ...payload,
        },
      });
      await campaigns.manager.loadSingle(route.params.campaignID as string);
    };

    campaigns.manager.getTotalViewsCampaign(route.params.campaignID as string);
    const showPaymentModal = ref(false);
    const showPaymentModalActive = ref(false);

    const campaignClosing = async () => {
      await campaigns.manager.complete(route.params.campaignID as string);
      active.value = 0;
      cardShow.value = true;
      campaignClosed.value = true;
    };
    const closeCard = () => {
      campaignClosed.value = true;
    };
    const newBudget = ref();

    const budgetModal = async (newBudgets: any) => {
      if (
        currentCampaign.value?.paymentMethod === CampaignPaymentMethod.CPI &&
        (newBudgets.cpi.android.amount >= currentCampaign.value?.cpi.android.amount || newBudgets.cpi.ios.amount >= currentCampaign.value?.cpi.ios.amount)
      ) {
        await campaigns.manager.changeCpi(currentCampaign.value?.id, newBudgets.cpi);
        await campaigns.manager.loadSingle(route.params.campaignID as string);
      }
      if (currentCampaign.value?.paymentMethod === CampaignPaymentMethod.CPM) {
        await campaigns.manager.changeCpm(currentCampaign.value?.id, newBudgets.cpm);
        await campaigns.manager.loadSingle(route.params.campaignID as string);
      }
      if (currentCampaign.value?.paymentMethod === CampaignPaymentMethod.CPC) {
        await campaigns.manager.changeCpc(currentCampaign.value?.id, newBudgets.cpc);
        await campaigns.manager.loadSingle(route.params.campaignID as string);
      }

      if (newBudgets.budget.amount > currentCampaign.value?.budget.amount) {
        showPaymentModalActive.value = true;
      }
      if (newBudgets.budget.amount > currentCampaign.value?.budget.amount) {
        showPaymentModalActive.value = true;
      }
      showBudgetModal.value = false;
      newBudget.value = newBudgets;
    };

    contentItemsModule.list.getCampaignContent(route.params.campaignID as string);

    const editedCampaign = () => {
      editCampaign.value = true;
    };

    const createdCamp = ref();
    const createLoading = ref(false);

    const launchCampaign = async () => {
      createLoading.value = true;
      await campaigns.manager.activateCampaignWithCsv(route.params.campaignID as string);
      await campaigns.manager.loadSingle(route.params.campaignID as string);
      createLoading.value = false;
    };
    const goToNewCampaign = async () => {
      await router.push({
        name: "campaign",
        params: { campaignID: createdCamp.value.id },
      });
    };
    const campaignPaySuccess = async () => {
      showSuccessModal.value = true;
      showPaymentModalActive.value = false;
    };
    if (route.query && route.query.transactionId) {
      campaignPaySuccess(); // FIXME nee update logic
      router.push(route.path);
    }

    const requestSuccess = async () => contentItemsModule.list.getCampaignContent(route.params.campaignID as string);

    const updateAtt = async () => {
      await campaigns.manager.loadSingle(route.params.campaignID as string);
    };

    const inviteCreators = async (v: { value: { name: string; value: string } }) => {
      if (v.value.value === "on") {
        await router.push({ name: "creators" });
      }
      if (v.value.value === "outside") {
        if (route.params?.campaignID) {
          selectedInviteQuery.value = "";
          toast.showSuccess("The invitation link to your company has been copied.");
          await navigator.clipboard.writeText(`${location.protocol}//${location.host}/?invite=${route.params.campaignID}`);
        }
      }
    };

    return {
      isLoggedUserCampaignOwner,
      inviteCreators,
      updateAtt,
      formatDate,
      requestSuccess,
      campaignPaySuccess,
      saveEdit,
      launchCampaign,
      updateCampaignData,
      goToNewCampaign,
      getGlobalCampaignByRegion,
      budgetModal,
      closeCard,
      campaignClosing,
      paymentInfoChange,
      createLoading,
      remDays,
      differenceout,
      showPaymentModal,
      showPaymentModalActive,
      showSuccessModal,
      showTerms,
      active,
      currentCampaign,
      selectedInviteQuery,
      contentItems: contentItemsModule.list.getCampaignContentResult,
      totalCampaignsViews: campaigns.manager.getTotalViewsCampaignData,
      newBudget,
      paymentInformationModal,
      campaignClosed,
      showBudgetModal,
      CampaignClosedCard,
      editCampaign,
      showWarningModal,
      cardShow,
      campaignFinished,
      showPayment,
      campaign: campaigns.manager.singleCampaign,
      CampaignPaymentMethod,
    };
  },
});
