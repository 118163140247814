import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-123a703b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relativ" }
const _hoisted_2 = { class: "p-d-flex p-ai-center main-section" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "p-d-flex p-flex-column" }
const _hoisted_5 = { class: "inf-name" }
const _hoisted_6 = {
  key: 0,
  class: "count-followers"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["badge-star", [$props.isFavorite ? 'badge-star-orange' : '']]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.addToFavorite($props.item?.id)))
      }, null, 2),
      ($props.item?.profile?.photo.length > 0)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: $props.item?.profile?.photo,
            class: "image-influencer"
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString($props.item?.name), 1),
        ($props.item?.youtube?.length > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($setup.compactNumber($setup.calculateFollowers($props.item?.youtube))) + " Followers ", 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", {
      class: "p-d-flex p-justify-end goTo",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.goToProfile && $setup.goToProfile(...args)))
    }, "Go to profile >")
  ]))
}