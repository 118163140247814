import React from "react";
import "./CustomLoader.css";


export default function () {
  return (
    <div className={'custom-loading-wrap'}>
      <span className="custom-loader wrap"></span>
    </div>
  )
}



