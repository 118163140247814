
import { defineComponent } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useAuth } from '@/modules';

export default defineComponent({
  components: {
    Button,
    Dialog,
  },

  props: {
    show: Boolean,
  },

  emits: ['update:show'],

  setup(props, { emit }) {
    const { user } = useAuth();

    const open = () => {
      emit('update:show', true);
    };

    return {
      open,
      user,
    };
  },
});
