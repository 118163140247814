<template>
  <div>
    <Dialog
      :visible="show"
      class="p-col-12 p-md-6 p-xl-4 gi-dialog"
      :modal="true"
      :style="{ width: '1110px' , height : '798'} "
      @update:visible="$emit('update:show', false)"
    >
      <template #header>
        <div class="p-flex-column" style="margin-left:150px">
          <img class="image" src="@/assets/images/live-content-img.svg">
          <p class="title-main">The advanced licensing creator program </p>
          <p style="margin-top: 90px">Besides creating creator ads for campaigns, here at GameInfluencer.IO
            we have another way for you to earn additional income. </p>
        </div>
      </template>
      <section class="p-d-flex p-flex-column">
       <div>
         <p class="title">Understanding Terms</p>
         <p class="paragraph-1">As you know, when creating creator ads for campaigns you retain all rights
           and only you have the right to publish it on YouTube. When the Campaign is over brands will be able
           to review which creator ads performed the best and will be presented with additional licensing optios.</p>
        </div>
        <div class="p-d-flex p-mt-6 w-75 p-as-center" >
          <div v-if="readTerm1"><img class="success-image" src="@/pages/campaigns/assets/images/application-success.svg"></div>
          <div class="secondary-title" :class="[readTerm1 ? 'line-through' : '']">What is a Content Distribution Platform?</div>
          <div  v-if="!term1" style="margin-left: auto" @click="readTerm1=true;term1=true;term2=false;">
            <img class="plus-image" src="@/pages/campaigns/assets/images/minus.svg"></div>
          <div v-if="term1" style="margin-left: auto" @click="readTerm1=false;term1=false;">
            <img class="plus-image" src="@/pages/campaigns/assets/images/plus.svg"></div>
        </div>
        <p v-if="!term1" class="w-75 p-as-center p-mt-2">A platform in which content is released for public consumption, such as
          YouTube, TikTok, Twitch, Instagram etc.</p>

        <div class="p-d-flex p-mt-6 w-75 p-as-center">
          <div v-if="readTerm2"><img class="success-image" src="@/pages/campaigns/assets/images/application-success.svg"></div>
          <div class="secondary-title" :class="[readTerm2 ? 'line-through' : '']">What is a Content Distribution Period?</div>
          <div v-if="!term2" style="margin-left: auto" @click="readTerm2=true;term2=true;term3=false;">
            <img class="plus-image" src="@/pages/campaigns/assets/images/minus.svg"></div>
          <div v-if="term2" style="margin-left: auto">
            <img class="plus-image" src="@/pages/campaigns/assets/images/plus.svg" @click="readTerm2=false;term2=false;"></div>
        </div>
        <div  v-if="!term2" class="w-75 p-as-center p-mt-2">A period of time in which the content in question must be
          released on and stay on the relevant platform.</div>
        <div  class="p-d-flex p-mt-6 w-75 p-as-center">
          <div  v-if="readTerm3"><img class="success-image" src="@/pages/campaigns/assets/images/application-success.svg"></div>
          <div class="secondary-title" :class="[readTerm3 ? 'line-through' : '']">What is a Content Distribution Across?</div>
          <div v-if="!term3" style="margin-left: auto"  @click="readTerm3=true;term3=true;">
            <img class="plus-image" src="@/pages/campaigns/assets/images/minus.svg"></div>
          <div v-if="term3" style="margin-left: auto">
            <img class="plus-image" src="@/pages/campaigns/assets/images/plus.svg" @click="readTerm3=false;term3=false"></div>
        </div>
      </section>
      <template #footer>
        <div class="p-d-flex p-justify-center">
          <div class="continue-button" @click="showTerms = true; closeModal()">GOT IT, Lets Continue</div>
          <TermsModal v-model:show="showTerms" @close-modal="closeModal"></TermsModal>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import { ref, watch } from 'vue';
import TermsModal from '@/pages/creators/TermsModal.vue';

export default {
  components: {
    TermsModal,
    Dialog,
  },
  props: {
    show: Boolean,
  },
  setup(props, ctx, emit) {
    const term1 = ref(false);
    const term2 = ref(true);
    const term3 = ref(true);
    const readTerm1 = ref(false);
    const readTerm2 = ref(false);
    const readTerm3 = ref(false);
    const showTerms = ref(false);
    const closeModal = () => {
      if (term1.value === true && term2.value === true && term3.value === true) {
        ctx.emit('closeUnderstandingModal');
      }
    };
    return {
      showTerms, term1, term2, term3, closeModal, readTerm1, readTerm2, readTerm3,
    };
  },
};
</script>
<style scoped lang="scss">

.line-through {
  text-decoration-line: line-through;
  color: #253340;
  opacity: 0.5;
}
.success-image {
  width: 15px;
  height: 15px;
  margin-right: 11px;
  transition: all 0.6s ease-in-out;
}
.paragraph-1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #686E6E;
  width: 800px;
  text-align: center;
  margin: 0 auto;
}

.image {
  position: absolute;
  margin-left: 20px;
  width: 100px;
  height: 100px;
}
.title-main {
  font-weight: 800;
  font-size: 30px;
  line-height: 50px;
  color: #181818;
  position: absolute;
  margin-left: 60px;
  margin-top: 30px;
}
.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #162020;
  margin-top: 15px;
  text-align: center;
}
.warning-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #8B8F8F;
  text-align: center;
  margin-left: 5px;
}

.continue-button {
  border: none;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  width: 198px;
  height: 40.12px;
  background: #E0010B;
  box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  padding: 11px;
}
.secondary-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #253340;
  transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight ) {
  background-color: #E0010B;
  color:#ffff;
}
p {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

::v-deep(.p-inputgroup) {
  width: 450px;
}
::v-deep(.p-checkbox .p-checkbox-box.p-highlight), ::v-deep(.p-checkbox .p-checkbox-box.p-highlight:hover) {
  border-color: red;
  background: red;
}</style>
