
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import BrandTerms from './BrandTerms.vue';

export default {
  components: {
    Dialog,
    Button,
    BrandTerms,
  },
  props: {
    showModal: {
      type: Boolean,
    },
  },
};
