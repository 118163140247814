
import { defineComponent, PropType } from 'vue';
import { User } from '../../../data/types';

export default defineComponent({
  name: 'UserPhoto',

  props: {
    user: {
      type: Object as PropType<User>,
    },
  },
});
