
import { computed, defineComponent, ref, watch } from 'vue';
import Button from 'primevue/button';

import { AppPageProps, LicenseRequestRequest } from '@/data/types';
import { useAuth, useContentItems } from '@/modules';
import { useRoute } from 'vue-router';
// import LicenseRequestModal from '@/pages/commons/modals/LicenseRequestModal.vue';
import { compactNumber } from '@/utils/globals/curency-utils';
import AppListPage from '../../commons/AppListPage.vue';
import InfluencerInformation from '../../creators/profile/InfluencerInformation.vue';
import LatestContentItem from '../../creators/latest/LatestContentItem.vue';

export default defineComponent({
  name: 'ContentItem',

  components: {
    Button,
    // LicenseRequestModal,
    AppListPage,
    InfluencerInformation,
    LatestContentItem,
  },

  props: {
    itemID: String,
    creatorID: String,
    ...AppPageProps,
  },

  setup(props) {
    const previewPhoto = ref(true);
    const route = useRoute();
    const activeTab = ref(0);
    const { user } = useAuth();
    const content = useContentItems();
    watch(() => route.params.itemID, () => {
      // advanced Fetching After Navigation
      content.manager.loadSingle(route.params.itemID as string).then((data) => {
        content.list.loadAllPublicContentCreator(data?.owner?.id as string, 8);
      });
    }, { immediate: true });
    content.manager.loadSingle(props.itemID as string).then((data) => {
      content.list.loadAllPublicContentCreator(data?.owner?.id as string, 8);
    });

    // requesting a license
    // const showRequestLicenseModal = ref(false);
    // const sendLicenseRequest = (payload: LicenseRequestRequest) => {
    //   content.requests
    //     .request(props.itemID as string, payload)
    //     .then((success?: boolean) => {
    //       if (success) {
    //         showRequestLicenseModal.value = false;
    //       }
    //     });
    // };

    const toggleItemVisibility = () => {
      if (props.itemID) {
        content.manager.update(props.itemID, { public: !content.manager.singleItem.value?.public });
      }
    };

    const copyLink = (url: any) => {
      navigator.clipboard.writeText(url);
    };
    const itemIsMine = computed(() => user?.value?.id === content.manager.singleItem.value?.owner.id);

    return {
      previewPhoto,
      activeTab,
      content,
      loading: content.manager.loadingSingle,
      item: content.manager.singleItem,
      itemIsMine,
      // licensing
      // showRequestLicenseModal,
      // sendLicenseRequest,
      // visibility
      toggleItemVisibility,
      compactNumber,
      copyLink,
      // all user content public
      publicContentCreator: content.list.loadAllPublicContentCreatorResult,
    };
  },
});
