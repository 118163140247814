import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fd81e44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-page" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = {
  key: 0,
  class: "search"
}
const _hoisted_4 = { class: "content" }
const _hoisted_5 = {
  key: 0,
  class: "spinner-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_search_bar = _resolveComponent("page-search-bar")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1)
    ]),
    (_ctx.showSearch)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_page_search_bar, {
            perspective: _ctx.perspective,
            defaultSearchType: _ctx.searchType,
            onSearch: _ctx.performSearch,
            onClearSearch: _ctx.clearSearch
          }, null, 8, ["perspective", "defaultSearchType", "onSearch", "onClearSearch"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_ProgressSpinner, { class: "spinner" })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (!_ctx.loading)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
        : _createCommentVNode("", true)
    ])
  ]))
}