
import { defineComponent } from 'vue';
import { discord, copyToClipboard } from './utils';
import Sidebar from 'primevue/sidebar';

export default defineComponent({
  props: {
    view: String,
    op: Boolean,
  },
  components: {
    Sidebar,
  },
  setup() {
    return {
      discord,
      copyToClipboard,
    };
  },
})
