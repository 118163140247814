import React, { useState } from 'react';
import "./NewInviteToCampaign.css";
import 'primeicons/primeicons.css';
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useCampaigns } from "@/modules";
import { Perspective } from "@/data/types";

export default function(props){
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const selectedCampaignTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const campaignOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  const campaigns = useCampaigns(Perspective.BRAND);

  const sendInvite = () => {
    if (selectedCampaign && props.creator) {
      campaigns.invitations.invite(selectedCampaign.id, props.creator.id);
    }
    props.onHide();
  };

  return(
    <Dialog className={'invite-to-campaign'} visible={props.visible} onHide={props.onHide}>
      <div className={'header'}>
        <i className="pi pi-times" style={{ fontSize: '25px', color: '#fff', cursor: 'pointer' }} onClick={props.onHide}/>
      </div>
      <div className={'content'}>
        <p className={'title'}>Campaign Invitation</p>
        <p className={'inviteText'}>Select the campaign you’d like to invite <strong>{props.creator?.name}</strong> to:</p>
        <Dropdown value={selectedCampaign} onChange={(e) => setSelectedCampaign(e.value)} itemTemplate={campaignOptionTemplate}
                  options={props.campaignOptions} valueTemplate={selectedCampaignTemplate} optionLabel="name" placeholder="Select campaign" filter showClear />
        <Button className={'inviteButton'} disabled={!selectedCampaign} onClick={() => sendInvite()}>Send Invitation</Button>
      </div>
    </Dialog>
  )
}
