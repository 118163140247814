<template>
  <div>
    <Dialog

        :visible="isCardShow"
        class="p-col-12 p-md-6 p-xl-4 gi-dialog"
        :modal="false"
        :style="{ width: '1554px' , height : '724px'} "
        @update:visible="$emit('update:isCardShow', false)"
        @click="CloseCard"
    >
      <template #header>
        <section class="p-d-flex p-flex-column w-75" @click="CloseCard">
          <h1 style="text-align:center; margin-left: 12vw">Best Performing Ads Licensing Request</h1>

          <div class="p-d-flex">
            <img class="live-content-img" src="@/assets/images/live-content-img.svg">
            <div style=" margin-top: 50px;">
            </div>
          </div>
          <div class="ads-textbox"> Want to feature some of your best performers on your
            own marketing efforts?, reach out for a exclusive licensing request below!
          </div>
          <div class="p-d-flex p-jc-center p-flex-wrap w-100" style="margin-top:80px">
            <ContentList :items="items" :campaign-closed="true"/>
          </div>
        </section>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import ContentList from './ContentList.vue';

export default {
  components: {
    Dialog,
    ContentList,
  },
  props: {
    isCardShow: Boolean,
    working: Boolean,
    items: Array,
  },
  setup(props, ctx) {
    const CloseCard = () => {
      ctx.emit('update:show', false);
      ctx.emit('closeCard');
    };

    return {
      CloseCard,
    };
  },
};
</script>
<style scoped lang="scss">
.live-content-img {
  width: 200px;
  height: 200px;
  margin-left: 500px;
  position: absolute;
}

.ads-textbox {
  width: 30%;
  text-align: center;
  margin-left: 62%;
}

.continue-button {
  border: none;
  font-weight: 800;
  padding: 11px;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  width: 184px;
  height: 34px;
  background: #E0010B;
  box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  align-items: center;
  margin-left: 75px;
}
</style>
