import { watch } from 'vue';
import { useAPI } from '@/modules';
import { AppPagePerspective, ContentItem, ContentItemData } from '@/data/types';
import { useToastModule } from '@/modules/messages/toast-module';

export const useContentItemsManager = (perspective?: AppPagePerspective) => {
  const toast = useToastModule();

  // load single item
  const {
    loading: loadingSingle,
    error: loadingSingleError,
    execute: loadSingleItem,
    data: singleItem,
  } = useAPI<ContentItem>('', false);

  const loadSingle = (itemID: string) => loadSingleItem({
    url: `/content/${itemID}`,
  });
  watch(loadingSingleError, toast.handleRequestError);
  // < load single

  // update
  const {
    loading: updatingInProgress,
    error: updatingError,
    data: updatingResult,
    execute: sendUpdateRequest,
  } = useAPI<ContentItem>('', false);

  const update = (itemID: string, payload: Record<string, any>) => sendUpdateRequest({
    url: `/content/${itemID}`,
    method: 'PUT',
    data: payload,
    parseResponse: (raw: ContentItemData) => new ContentItem(raw),
  })
    .then((updatedItem?: ContentItem) => {
      if (updatedItem && singleItem.value) {
        singleItem.value = updatedItem;
      }
      return updatedItem
    });
  // < update

  // sync
  const {
    loading: syncingInProgress,
    error: syncingError,
    data: syncingResult,
    execute: sendSyncRequest,
  } = useAPI('/content/sync', false);

  const sync = () => sendSyncRequest();
  // < sync

  const {
    loading: completingCreateContent,
    error: createContentError,
    data: createContentResult,
    execute: sendCreateContentRequest,
  } = useAPI('', false);
  const createContent = (creatorId: string, contentBody: any, campaignId: string, channel?: string) => sendCreateContentRequest({
    url: `/content/create/${creatorId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { videoData: contentBody, campaignId, channel },
  });
  watch(createContentError, toast.handleRequestError);

  // create unlisted content
  const {
    loading: createUnlistedContentLoading,
    error: createUnlistedContentError,
    data: createUnlistedContentResult,
    execute: createUnlistedContentRequest,
  } = useAPI('', false);
  const createUnlistedContent = (creatorId: string, campaignId: string, IDs: any, channel?: string) => createUnlistedContentRequest({
    url: `/content/create-unlisted/${creatorId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { campaignId, channel, IDs },
  });
  watch(createUnlistedContentError, toast.handleRequestError);
  // create unlisted content

  // create unlisted content
  const {
    loading: getStatusVideoCpmLoading,
    error: getStatusVideoCpmError,
    data: getStatusVideoCpmResult,
    execute: getStatusVideoCpmRequest,
  } = useAPI('', false);
  const getStatusVideoCpm = (creatorId: string, campaignId: string, channelIds?: Array<string>) => {
    let params: { campaignId: string, creatorId: string, channelIds?: string } = {
      campaignId,
      creatorId,
    };

    if (channelIds && channelIds!.length > 0) {
      params = {
        ...params,
        channelIds: channelIds?.join(",")
      }
    }
    return getStatusVideoCpmRequest({
      url: '/content/status/video',
      method: 'GET',
      params,
    });
  }
  watch(getStatusVideoCpmError, toast.handleRequestError);
  // get status

  // video-reason
  const {
    loading: getVideoReasonLoading,
    error: getVideoReasonError,
    data: getVideoReasonResult,
    execute: getVideoReasonRequest,
  } = useAPI('', false);
  const getVideoReason = (id: string) => getVideoReasonRequest({
    url: '/content/video-reason/get',
    method: 'GET',
    params: {
      id,
    },
  });
  watch(getVideoReasonError, toast.handleRequestError);
  // get video-reason

  return {
    getVideoReason,
    getVideoReasonResult,
    getVideoReasonError,
    getVideoReasonLoading,
    // video reason
    getStatusVideoCpm,
    getStatusVideoCpmResult,
    getStatusVideoCpmError,
    getStatusVideoCpmLoading,
    // get status
    createUnlistedContent,
    createUnlistedContentResult,
    createUnlistedContentError,
    createUnlistedContentLoading,
    // create unlisted content for cpm campaign
    createContent,
    createContentResult,
    createContentError,
    completingCreateContent,
    // loading single
    loadSingle,
    loadingSingle,
    singleItem,
    // update campaign
    update,
    updatingInProgress,
    updatingError,
    updatingResult,
    // syncing content items
    sync,
    syncingInProgress,
    syncingError,
    syncingResult,
  };
};
