
import { computed, defineComponent, ref, watch } from 'vue';
import AppPage from '@/pages/commons/AppPage.vue';
import {
  useAuth, useCreators, useCampaigns, useToastModule, useContentItems, useAgencys,
} from '@/modules';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import {
  AppPageConfig,
  AppPageProps,
  EditUserPayload, User,
} from '@/data/types';
import { useRouter } from 'vue-router';
import InfluencerEdit from './InfluencerEdit.vue';
import paymentInformationModal from '../../campaigns/create/paymentInformationModal.vue';
import InfluencerInformation from './InfluencerInformation.vue';
import Channels from './Channels.vue';
import LatestContentItem from '../latest/LatestContentItem.vue';
import EmptyList from '../../commons/list/EmptyList.vue';
import InviteToCampaign from './InviteToCampaign.vue';

export default defineComponent({
  components: {
    AppPage,
    Channels,
    InfluencerEdit,
    Button,
    Dialog,
    InputText,
    LatestContentItem,
    InfluencerInformation,
    EmptyList,
    paymentInformationModal,
    InviteToCampaign,
  },
  props: {
    creatorID: {
      type: String,
    },
    ...AppPageProps,
  },
  setup(props) {
    const { user } = useAuth();
    const content = useContentItems();
    const agencyModule = useAgencys();
    const creatorId = computed(() => props.creatorID || '');
    content.list.loadMyLatest(props.creatorID as string, 8);
    const config = ref<AppPageConfig>({
      title: 'Loading creator',
      ...props,
    });

    const router = useRouter();
    const showModalAddCreator = ref(); // add creators to agency
    const changePaymentInfo = ref(false);
    const showInviteModal = ref(false);

    const controller = useCreators(config.value.viewPerspective);
    const loadCreator = () => controller.manager.load(creatorId.value);
    const loadChannels = async () => controller.youtube.getCreatorChannels(creatorId.value, controller.manager.creator.value?.type);
    loadCreator();

    watch(controller.manager.creator, () => {
      if (controller.manager.creator.value?.name) {
        config.value.title = controller.manager.creator.value?.name;
        loadChannels();
      }
    });

    const successAddChannel = async () => {
      await loadChannels();
    };
    const removeChannel = async (id: string) => {
      controller.youtube.getCreatorChannelsResult.value.channel.items = controller.youtube.getCreatorChannelsResult.value?.channel?.items
        .filter((channel: any) => channel?.id !== id);
    }

    // edit
    const showEditModal = ref(false);
    const updateCreator = (payload: EditUserPayload) => {
      controller.manager.putInfluencerInfo(creatorId.value, payload)
        .then((success?: User) => {
          if (success) {
            showEditModal.value = false;
            window.location.reload();
          }
        });
    };
    // < edit

    const campaigns = useCampaigns(config.value.viewPerspective);

    const switchAccount = async () => {
      setTimeout(() => {
        router.go(0);
      }, 1000);
      await agencyModule.manager.switchAccount();
    };
    const forUsername = ref('');
    const searchResult = ref();

    const addCreator = async () => {
      const searchResults = await agencyModule.manager.searchChannels(forUsername.value);
      searchResult.value = searchResults;
    };

    const isChannelAlredyAdded = (userChannels: any, channelId: string) => {
      const isAlredy = userChannels?.findIndex((channel: any) => channel?.id === channelId);
      return isAlredy >= 0;
    };
    const deleteChanel = async (channelId: string, index: number) => {
      await agencyModule.manager.deleteChannel(channelId);
      await loadChannels();
    };

    const selectChannelForAdd = async (channelData: any) => {
      await agencyModule.manager.addCreator(channelData);
      await loadChannels();
    };

    const paymentInfoChange = async (data: any) => {
      changePaymentInfo.value = false;
    };

    return {
      isChannelAlredyAdded,
      deleteChanel,
      paymentInfoChange,
      selectChannelForAdd,
      updateCreator,
      successAddChannel,
      channels: controller.youtube.getCreatorChannelsResult,
      config,
      controller,
      user,
      forUsername,
      showInviteModal,
      addCreator,
      loading: controller.manager.loading,
      creator: controller.manager.creator,
      switchAccount,
      // edit
      creatorId,
      changePaymentInfo,
      showEditModal,
      showModalAddCreator,
      latestContent: content.list.loadMyLatestResult,
      searchChannelLoading: agencyModule.manager.searchChannelsLoading,
      searchResult,
    };
  },
});
