import { watch } from 'vue';
import { useAPI } from '@/modules';
import { AppPagePerspective, GigapayUserProfile, PaymentData, User } from '@/data/types';
import { useToastModule } from '@/modules/messages/toast-module';

export const usePayments = (perspective?: AppPagePerspective) => {
  const toast = useToastModule();

  const {
    loading: checkVerifiedProgress,
    error: checkVerifiedError,
    data: checkVerifiedResult,
    execute: checkVerifiedReq,
  } = useAPI('', false);
  const checkVerified = () => checkVerifiedReq({
    url: `/payments/`,
    method: 'GET',
  });
  watch(checkVerifiedError, toast.handleRequestError);
  // check user verified status gigapay

  const {
    loading: updatePaymentDataProgress,
    error: updatePaymentDataError,
    data: updatePaymentDataResult,
    execute: updatePaymentDataReq,
  } = useAPI('', false);

  const updatePaymentData = (userId: string, data: PaymentData) => updatePaymentDataReq({
    url: `/payments/`,
    method: 'PATCH',
    data,
  });
  watch(updatePaymentDataError, toast.handleRequestError);

  const {
    loading: updateUserProfileProgress,
    error: updateUserProfileError,
    data: updateUserProfileResult,
    execute: updateUserProfileReq,
  } = useAPI('', false);


  // Update user profile (email, personal info etc.) on Gigapay
  const updateUserProfile = (user: any, gigapayPayload: GigapayUserProfile) => updateUserProfileReq({
    url: `/payments/employees/update-profile`,
    method: 'PATCH',
    data: { gigapayPayload, user },
  });


  watch(updateUserProfileError, toast.handleRequestError);

  // create user gigapay

  const {
    loading: resendInvitationProgress,
    error: resendInvitationError,
    data: resendInvitationResult,
    execute: resendInvitationReq,
  } = useAPI('', false);
  const resendInvitation = () => resendInvitationReq({
    url: `/payments/employees/resend`,
    method: 'PATCH',
  });
  watch(checkVerifiedError, toast.handleRequestError);
  // Resend an Invitation

  return {
    // Resend an Invitation
    resendInvitation,
    resendInvitationResult,
    resendInvitationError,
    resendInvitationProgress,
    // update user data
    updatePaymentData,
    updatePaymentDataResult,
    updatePaymentDataError,
    updatePaymentDataProgress,
    // update user profile data (personal)
    updateUserProfile,
    updateUserProfileResult,
    updateUserProfileError,
    updateUserProfileProgress,
    // check user verified status gigapay
    checkVerified,
    checkVerifiedResult,
    checkVerifiedError,
    checkVerifiedProgress,
  };
};
