
import { defineComponent, watch } from 'vue';
import { useAuth, usePage } from '@/modules';
import { useContentItems } from '@/modules/content-items/content-items-module';
import { AppPageConfig, AppPageProps } from '@/data/types';
import { applyPureReactInVue } from 'veaury';
import { isContentItemsPageEnabled } from '@/utils/globals/feature-flags';
import NewContentItems from "@/react_app/NewContentItems/NewContentItems";
import OldContentItems from "@/pages/content/OldContentItems.vue";
import router from "@/router";

export default defineComponent({
  name: 'ContentItems',
  components: {
    NewContentItems: applyPureReactInVue(NewContentItems),
    OldContentItems
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    ...AppPageProps,
    private: Boolean,
    limit: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: 'Content Items',
      search: {
        enabled: props.showSearch,
        defaultType: 'creators',
        placeholder: 'Search for Content items',
      },
      ...props,
    });

    const { user } = useAuth();

    const page = useContentItems(config.value.viewPerspective);
    const loadPageData = () => page.list.loadContentItems(false, props.limit, '');

    loadPageData();
    watch(() => props.private, loadPageData);

    const handleRouting = (id: string) => {
      router.push({ name: 'content-item', params: { itemID: id } });
    }

    return {
      config,
      page,
      loading: page.list.loading,
      contentItems: page.list.items,
      sync: page.manager.sync,
      syncing: page.manager.syncingInProgress,
      performSearch: page.list.search('name', props.limit || 0),
      isContentItemsPageEnabled,
      handleRouting,
      user
    };
  },
});
