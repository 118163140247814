
import {
  defineComponent,
  ref,
  watch,
  computed,
} from 'vue';
import Button from 'primevue/button';
import { useAuth } from '@/modules';
import LatestContentItem from '../../creators/latest/LatestContentItem.vue';

export default defineComponent({
  components: {
    Button,
    LatestContentItem,
  },

  props: {
    show: Boolean,
    working: Boolean,
    nOffer: Object,
    data: Object,
  },

  emits: ['update:show', 'respond'],

  setup(props, { emit }) {
    const auth = useAuth();
    const payload: any = props.data || null;
    const offer = ref(props.nOffer || null);
    const lastOfferIsFinal = computed(() => !!offer?.value?.final);

    const selectedOption = ref('');
    const isSelected = (option: string) => selectedOption.value === option;

    // counter offer
    const finalOffer = ref(false);
    const counterOfferPrice = ref();
    const sendResponse = () => {
      emit('respond', {
        ...payload,
        action: selectedOption.value,
        price: counterOfferPrice.value || 0,
        final: finalOffer.value,
      });
    };

    const selectOption = (option: string) => {
      if (props.working) {
        return;
      }
      if (option === 'counter' && lastOfferIsFinal.value) {
        return;
      }
      selectedOption.value = option;
      sendResponse();
    };

    watch(() => props.show, () => {
      selectedOption.value = '';
      finalOffer.value = false;
      counterOfferPrice.value = undefined;
    });

    // working
    const sendButtonIcon = computed(() => (props.working ? 'pi pi-spin pi-spinner' : ''));

    return {
      offer,
      lastOfferIsFinal,
      selectedOption,
      isSelected,
      selectOption,
      // counter offer
      finalOffer,
      counterOfferPrice,
      sendButtonIcon,
      sendResponse,
      payload,
      user: auth.user.value,
    };
  },
});
