<template>
  <div class="container p-d-flex p-flex-column p-ai-center p-justify-center">
    <div class="faq-box">
      <div class="faq-title"> FAQ</div>
      <div class="userMenu">
        <Button v-for="user of allUser" :key="user.id" class="userBtn fill" :outlined="user.id !== currentUser"
          :style="{ backgroundColor: user.id === currentUser ? '#E73D38' : '' }" @click="handleChangeUser(user.id)">
          {{ user.name }}
        </Button>
      </div>
      <div v-for="(item, index) of userWiseFAQ[currentUser]" :key="index" class="p-mt-6" v-motion
        :initial="{ opacity: 0, y: 500 }" :enter="{ opacity: 1, x: 0, y: 0 }" :variants="{ custom: { scale: 2 } }"
        :delay="200" :duration="1200">
        <div class="p-d-flex p-ai-center p-mt-4 adjust" @click="toggleAccordion(index)">
          <i class="material-icons p-justify-start">{{ item?.isExpanded ? 'remove' : 'add' }}</i>
          <span class="p-flex-column acc-header">{{ item.header }}</span>
        </div>
        <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @leave="leave">
          <div v-if="item?.isExpanded" class="accordion-content">
            <p class="accordion-content-text" v-html="item.content"></p>
          </div>
        </transition>
        <div class="line"></div>
      </div>
      <template>
        <div class='back p-mt-6' @click="$emit('update:isShow', false)">Back</div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Button from 'primevue/button';
import { motion } from '@vueuse/motion';
export default {
  components: {
    Button
  },
  props: {
    isShow: Boolean,
  },
  setup(props, ctx, emit) {

    const currentUser = ref('brand');
    const allUser = ref([
      { id: 'brand', name: 'Brand' },
      { id: 'influencer', name: 'Influencer' },
      { id: 'agency', name: 'Talent Agencies' },
    ]);
    const userWiseFAQ = ref({
      brand: [
        {
          header: 'What is CPI, CPC, and CPM?',
          content: 'The campaigns on gameinfluencer.io work on either a CPI, CPC, or CPM basis.<br><br>' +
            '<strong>CPI:</strong> Cost Per Install works using the number of installs a game or app gets through your' +
            ' specific tracking link.' +
            ' The CPI for each campaign will be different, as well as the CPI for Android vs IOS devices, you can see' +
            ' these when going through the campaign preview.<br><br>' +
            '<strong>CPC:</strong> Cost per Click works by counting the number ' +
            'of clicks on the personalised tracking link. This link will take the user to the landing/home page of the ' +
            'game, and you will be paid out per click. CPC rates tend to be lower than CPI rates, but clicks are far easier ' +
            'to acquire than downloads.<br><br>' +
            '<strong>CPM:</strong> Cost-per-Mille values the number of views your sponsored video gets, with a rate per 1000 views. ' +
            'The views are tracked and paid out for the first 30 days that the video is live - all future views ' +
            'will not be counted.',
          isExpanded: false,
        },
        {
          header: 'How do you ensure campaign management efficiency?',
          content: 'We offer automated, performance-based solutions that streamline the entire campaign management process—from influencer selection to tracking and optimization.',
          isExpanded: false,
        },
        {
          header: 'How do I measure the success of a campaign?',
          content: `We are continually enhancing our service to provide a complete range of KPIs, comprehensive reporting, and post-campaign analysis. We are on the right track to deliver the full scope of KPIs in the near future,
         ensuring transparency and accountability throughout the process.`,
          isExpanded: false,
        },
        {
          header: 'Can I launch a campaign for different markets/languages?',
          content: `Yes, we support global campaigns across different markets and languages!`,
          isExpanded: false,
        },
        {
          header: 'Will there be other platforms than YouTube in the future?',
          content: `Yes! We're working on it. We hope to be able to include at least Twitch campaigns in the not-too-distant future.`,
          isExpanded: false
        }

      ],
      influencer: [
        {
          header: 'How can I get started?',
          content: 'Once you have registered as a creator you are free to check out all the available campaigns and see ' +
            'which ones look like the best fit for your channel! If you need further help, you can check out the ' +
            'tutorial for the site by clicking on the <strong>?</strong> in the bottom left.',
          isExpanded: false
        },
        {
          header: 'What is the advanced licensing creator program? (Selling the license to your ads)',
          content: 'One of the benefits of gameInfluencer.IO is the ability to earn extra revenue by allowing the ' +
            'license to your ads to be sold directly to the developers.<br><br>With our program you can sell licenses to ' +
            'each of the content pieces you manage to create and distribute to the brands.<br><br>This way you can allow' +
            ' your creator ads to be featured for additional licensing which will grant the brand the<strong>right to ' +
            'publish the video content of your creator ads on their own media channels' +
            ', such as TV, Google marketing campaigns etc.,</strong>' +
            'earning you a specific amount.<br><br>If the brand is interested in additional licensing you will receive ' +
            'a request notification on your account outlining their desired terms for the additional license.',
          isExpanded: false,
        },
        {
          header: 'What is CPI, CPC, and CPM?',
          content: 'The campaigns on gameInfluencer.IO work on either a CPI, CPC, or CPM basis.<br><br>' +
            '<strong>CPI:</strong> Cost Per Install works using the number of installs a game or app gets through your' +
            ' specific tracking link.' +
            ' The CPI for each campaign will be different, as well as the CPI for Android vs IOS devices, you can see' +
            ' these when going through the campaign preview.<br><br>' +
            '<strong>CPC:</strong> Cost per Click works by counting the number ' +
            'of clicks on the personalised tracking link. This link will take the user to the landing/home page of the ' +
            'game, and you will be paid out per click. CPC rates tend to be lower than CPI rates, but clicks are far easier ' +
            'to acquire than downloads.<br><br>' +
            '<strong>CPM:</strong> Cost-per-Mille values the number of views your sponsored video gets, with a rate per 1000 views. ' +
            'The views are tracked and paid out for the first 30 days that the video is live - all future views ' +
            'will not be counted. This is beneficial to you as it allows you to gauge how much you would be likely ' +
            'to earn from posting a sponsored video.<br><br>' +
            'It is important to note that each of these pricing models has its own advantages and disadvantages,' +
            ' and what works best for a particular campaign will depend on various factors such as the goals, ' +
            'target audience, budget, and type of product or service being advertised.',
          isExpanded: false,
        },
        {
          header: 'Is my channel too small to take part in campaigns?',
          content: 'No, there is no minimum size requirement for influencers to take part in campaigns. <br/>' +
            'GameInfluencer.IO is here to enabled you to monetise your content and earn from your passion!',
          isExpanded: false,
        },
        {
          header: 'How does payment work?',
          content: 'Payouts occur once a month. Creators will need to reach a minimum of $100 earned per 30-day cycle to ' +
            'activate their payout; if the payout limit is not reached then this amount will carry over to the following ' +
            'month and will accumulate.',
          isExpanded: false
        },
        {
          header: 'When will I get paid?',
          content: '<strong>CPM campaigns:</strong> after 30 days of tracking PLUS 30 days of finalization -> the payment cycle at the beginning of the following month <br/>' +
            '<strong>CPI campaigns:</strong> At the beginning of each month if the $100 threshold is met. If not, earnings will accumulate and carry over to the next month until the minimum is reached.',
          isExpanded: false
        },
        {
          header: 'What is the personalized cap?',
          content: 'The personalized cap is calculated based on the views of your previous uploads. It gives you a realistic goal and allows us to allocate the campaign budget to prevent it from overflowing.',
          isExpanded: false
        },
        {
          header: 'Can the personalized cap can be adjusted / changed?',
          content: 'No, in the interest of fairness, we cannot raise the caps for individual creators.',
          isExpanded: false
        },
        {
          header: 'Reasons of rejection',
          content: 'Often the client decides who to reject and who to accept, but the following aspects can play a big role: <br/>' +
            '- <strong>Activity:</strong> if there have been no uploads in the last 2 (or more) months, the channel is likely to be rejected.<br/>' +
            '- <strong>Regions/Languages:</strong> the channel does not meet the region/language requirements.<br/>' +
            '- <strong>Channel type:</strong> Channel type did not match the game / product being promoted <br/>' +
            '- Client preferences (may include channel size, views, type, etc.)',
          isExpanded: false
        },
        {
          header: 'Is there another way of payment?',
          content: 'No, all payments are made through our partner GigaPay and we currently don’t offer <br/> ' +
            'different payment methods. Please check if GigaPay supports your region before taking part in campaigns.',
          isExpanded: false
        },
        {
          header: 'Will there be other platforms than YouTube in the future?',
          content: "Yes! We're working on it. We hope to be able to include at least Twitch campaigns in the not-too-distant future.",
          isExpanded: false
        }
      ],
      agency: [
        {
          header: 'How can our agency join GameInfluencer?',
          content: 'To get started, register on our platform and explore campaigns that align with your roster of <br/>' +
            `talent. You can easily manage your influencers' participation and track their performance <br/>` +
            'through our streamlined dashboard',
          isExpanded: false
        },
        {
          header: 'What is the benefit of joining GameInfluencer as a talent agency?',
          content: 'GameInfluencer provides access to a large database of campaigns and performance-based <br/>' +
            'opportunities tailored for the gaming industry. Your influencers can earn revenue through <br/>' +
            '<strong>CPI</strong>, <strong>CPC</strong>, and <strong>CPM</strong> models, along with the option to license their content to brands for <br/>' +
            'additional revenue streams.',
          isExpanded: false
        },
        {
          header: 'Can we manage multiple influencers and campaigns simultaneously?',
          content: 'Yes, our platform is designed for scalability. You can manage multiple influencers and <br/>' +
            'campaigns at once, all from a single interface. Our automated solutions make this process <br/>' +
            'efficient and error-free.',
          isExpanded: false
        },
        {
          header: 'How are payments handled for agencies? ',
          content: 'Payments are processed monthly through our partner, GigaPay. The minimum payout <br/>' +
            'threshold is $100 per 30-day cycle. If this threshold isn’t met, earnings will accumulate until it <br/>' +
            'is reached.',
          isExpanded: false
        },
        {
          header: 'Are there any restrictions on the size of influencers we represent?',
          content: 'No, there is no minimum requirement for influencer size. We encourage agencies to onboard <br/>' +
            'influencers of all sizes to take advantage of the opportunities on our platform.',
          isExpanded: false
        },
        {
          header: 'How can our influencers benefit from the advanced licensing program?',
          content: 'Influencers can sell licenses to their ads, allowing brands to use their content on various <br/>' +
            'media channels. If a brand is interested in additional licensing, your agency will receive a <br/>' +
            'request outlining the terms.',
          isExpanded: false
        },
        {
          header: 'Can we adjust campaign budgets or caps?',
          content: 'No, the personalized cap is set based on previous performance and is non-negotiable. This <br/>' +
            'ensures fairness and optimal budget allocation across campaigns.',
          isExpanded: false
        },
        {
          header: 'What if an influencer is rejected from a campaign?',
          content: 'Rejections may occur based on factors such as activity, region/language, channel type, or <br/>' +
            'client preferences. We provide feedback to help you understand the reasons behind the <br/>' +
            'decision.',
          isExpanded: false
        },
        {
          header: 'Are other platforms besides YouTube supported?',
          content: 'While our primary focus is currently on YouTube, we are working to include platforms like <br/>' +
            'Twitch in the near future, expanding the opportunities for your talent.',
          isExpanded: false
        }
      ]
    })

    const modal = () => {
      ctx.emit('memberModalClose');
    };
    const toggleAccordion = (index) => {
      userWiseFAQ.value[currentUser.value][index].isExpanded = !userWiseFAQ.value[currentUser.value][index].isExpanded;
    };
    const handleChangeUser = (userId) => {
      currentUser.value = userId;
    };

    const beforeEnter = (el) => {
      el.style.maxHeight = '0';
      el.style.opacity = '0';
    };

    const enter = (el) => {
      el.style.transition = 'max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out';
      el.style.maxHeight = `${el.scrollHeight}px`;
      el.style.opacity = '1';
      el.style.transform = 'translateY(0)';
    };

    const leave = (el) => {
      el.style.transition = 'max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out';
      el.style.maxHeight = '0';
      el.style.opacity = '0';
      el.style.transform = 'translateY(-20px)';
    };

    return {
      modal,
      toggleAccordion,
      allUser,
      currentUser,
      handleChangeUser,
      userWiseFAQ,
      beforeEnter,
      enter,
      leave,
      motion
    };
  },
};
</script>
<style scoped lang="scss">
.faq-box {
  padding-bottom: 135px;
  height: auto;
  max-width: 1600px;
  width: 100%;
  overflow: hidden;
}

.container {
  width: 100%;
  padding: 0px 30px;
}

.box {
  width: 200px;
  height: 200px;
  background-color: #E73D38;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.acc-header {
  font-style: normal;
  color: #FFFFFF;
  width: 1000px;
  margin-left: 1%;
  margin-top: 2px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  max-width: 85%;
}

.acc-header:hover {
  text-decoration: underline;
}

.accordion-content {
  max-height: 0px;
  height: max-content;
  overflow: hidden;
  transition: all .7s;
}

.show {
  max-height: 1000px;
}

.userMenu {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 10px;
  padding: 20px;
  flex-wrap: wrap;
}

.userBtn {
  /* background-color: #E73D38; */
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.09s ease;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #E73D38;
  background: none;
  z-index: 1;
  cursor: pointer;
  transition: 0.27s ease-in;
  -o-transition: 0.27s ease-in;
  -ms-transition: 0.27s ease-in;
  -moz-transition: 0.27s ease-in;
  -webkit-transition: 0.27s ease-in;
}

.fill:hover {
  color: whitesmoke;
}

.fill:before {
  content: "";
  position: absolute;
  background: #E73D38;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  -webkit-transition: top 0.09s ease-in;
}

.fill:hover:before {
  top: 0;
}

.faq-box .p-button.p-button-outlined {
  border: 1px solid #E73D38;
  color: #FFFFFF;
}

.faq-box .p-button.p-button-outlined:enabled:hover {
  background-color: #E73D38;
  /* Darker red on hover */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid #E73D38;
  color: #FFFFFF;
}

.accordion-content-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;
  color: #FFFFFF;
  margin-left: 97px;
  border-left: solid red 3px;
  padding-left: 20px;
  width: 68%;
}

.line {
  border: 1px solid #898989;
  margin-top: 20px;
  width: 70%;
}

.back {
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  cursor: pointer;
  color: #8B8F8F;
  text-align: left;
  margin-left: 50px;
}

h1 {
  margin-left: 500px;
}

.adjust {
  cursor: pointer;
  color: #E6443C;
}

.faq-title {
  font-size: 36px;
  line-height: 44px;
  color: #FFFFFF;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-enter-active,
.accordion-leave-active {
  transition: max-height 0.3s ease;
}

.accordion-enter,
.accordion-leave-to {
  max-height: 0;
  opacity: 0;
}

.accordion-content {
  max-height: 500px;
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

@media screen and (max-width: 1000px) {
  .faq-title {
    text-align: center;
    font-size: 28px;
  }

  .material-icons {
    font-size: 18px;
  }

  .acc-header {
    font-size: 14px;
    margin-top: -2px;
  }

  .accordion-content-text {
    font-weight: 400;
    font-size: 10px;
  }

  .line {
    margin-top: 8px;
  }

  .faq-box {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .accordion-content-text {
    margin-left: 30px;
    width: 80%;
  }
}
</style>
