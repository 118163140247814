<template>
  <Dialog v-if="viewModal" :closable="false" :visible="viewModal" :style="{ width: '50vw' }">
    <p class="p-p-6 p-text-center text-labels par-modal">
      As our platform is still in its MVP stage, all campaigns will await approval, a member of our team will
      reach out to you via the email you registered with shortly
    </p>

    <template #footer>
      <div>
        <Button label="OK" icon="pi pi-check" class="ok-button" @click="viewModal = !viewModal; $emit('okay');"/>
      </div>
    </template>
  </Dialog>

</template>

<script>
import { ref } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

export default {
  components: {
    Dialog,
    Button,
  },
  setup() {
    const viewModal = ref(true);

    return {
      viewModal,
    };
  },
};
</script>

<style scoped lang="scss">
.par-modal {
  font-size: 18rem;
  line-height: 20rem;
}

.ok-button {
  border: none;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  min-width: 70px;
  height: 40.12px;
  background: #E0010B;
  box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}
</style>
