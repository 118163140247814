import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-619eb990"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserPhoto = _resolveComponent("UserPhoto")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["user-short-preview", _ctx.link && 'link']),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createVNode(_component_UserPhoto, {
      user: _ctx.user,
      class: "user-photo"
    }, null, 8, ["user"]),
    _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.user.name), 1)
  ], 2))
}