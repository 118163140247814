import React, { useEffect, useState } from "react";
import "./NewOverviewBrands.css";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import noImage from "../img/no-image.png";
import NewBrandInfoPage from "@/react_app/NewAdmin/NewOverviewBrands/NewBrandInfoPage/NewBrandInfoPage";
import { Paginator } from 'primereact/paginator';
import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';

const brandBodyTemplate = (rowData) => {
  return (
    <div className={'brandTemplate'}>
      <Image className={'brandTemplateImage'} src={rowData.photo ? rowData.photo : noImage} alt={'campaignPhoto'} />
      <p className={'brandTemplateText'}>{rowData.name}</p>
    </div>
  );
};

const emailBodyTemplate = (rowData) => {
  return (
    <div className={'emailTemplate'}>
      {rowData.creator?.email}
    </div>
  );
};

export default function (props) {
  const {
    loading, brandsList, searchBrands, limit, changePage
  } = props;
  const [first, setFirst] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const detailsBodyTemplate = (rowData) => {
    return (
      <div className={'detailsTemplate'} onClick={() => setSelectedBrand(rowData)}>
        View Details
      </div>
    );
  };
  const onPageChange = (event) => {
    setFirst(event.first);
    changePage(event)
  };
  useEffect(() => {
    setNoResults(!(brandsList?.brands?.length))
  }, [brandsList]);

  const handleSearch = (value) => {
    setFirst(0);
    setSearchValue(value)
    searchBrands(value);
  }

  return (
    <div className={'overview-brands'}>
      {!selectedBrand ?
        <div>
          <p className={'title'}>Overview Brands</p>
          <span className="p-input-icon-left inputFieldWrapper">
            <i className="pi pi-search" />
            <InputText placeholder="Search for brand" className={'inputField'} value={searchValue} onChange={(e) => handleSearch(e.target.value)} />
          </span>
          <DataTable value={brandsList?.brands} emptyMessage={"No brands found."} loading={loading}
            loadingIcon={
              <CustomLoader />
            }
            style={{ minHeight: 500 }}>
            <Column field={"name"} header={"Brand"} body={brandBodyTemplate} sortable style={{ width: '43%' }}></Column>
            <Column field={"creator.email"} header={"E-mail"} body={emailBodyTemplate} sortable style={{ width: '43%' }}></Column>
            <Column body={detailsBodyTemplate} style={{ width: '14%' }}></Column>
          </DataTable>
          {!noResults && <Paginator first={first} rows={limit} totalRecords={brandsList?.pagination} onPageChange={onPageChange} style={{ background: '#130A23' }} />}
        </div> :
        <NewBrandInfoPage handleReturn={() => setSelectedBrand('')} brand={selectedBrand} />
      }
    </div>
  )
}
