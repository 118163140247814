
import { defineComponent, PropType } from 'vue';
import { CampaignData, CampaignInvitationData, Perspective } from '@/data/types';
import EmptyList from '@/pages/commons/list/EmptyList.vue';
import InvitationsListItem from './InvitationsListItem.vue';

export default defineComponent({
  components: { EmptyList, InvitationsListItem },
  props: {
    campaigns: {
      type: Array as PropType<Array<CampaignData>>,
      default: () => [],
    },
    viewPerspective: String as PropType<Perspective>,
  },
  emits: ['delete', 'accept'],
  setup(props, { emit }) {
    const deleteInvitation = ({ campaign, invitation }: { campaign: CampaignData; invitation: CampaignInvitationData }) => {
      emit('delete', { campaign, invitation });
    };
    const acceptInvitation = ({ campaign, invitation }: { campaign: CampaignData; invitation: CampaignInvitationData }) => {
      emit('accept', { campaign, invitation });
    };

    return {
      acceptInvitation,
      deleteInvitation,
    };
  },
});
