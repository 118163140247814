
import { defineComponent, PropType } from 'vue';
import { ContentItem } from '@/data/types';
import { applyPureReactInVue } from 'veaury';
import { isContentItemsPageEnabled } from "@/utils/globals/feature-flags";
import NewContentItemsList from "@/react_app/NewContentItemsList/NewContentItemsList";
import OldContentItemsList from "@/pages/content/OldContentItemsList.vue";
import router from "@/router";
import { useAuth } from '@/modules';

export default defineComponent({
  name: 'ContentItemsList',
  components: {
    NewContentItemsList: applyPureReactInVue(NewContentItemsList),
    OldContentItemsList
  },
  props: {
    items: {
      type: Array as PropType<Array<ContentItem>>,
      default: () => [],
    },
  },
  setup() {
    const { user } = useAuth();

    const handleRouting = (id: string) => {
      router.push({ name: 'content-item', params: { itemID: id } });
    }

    return {
      handleRouting,
      isContentItemsPageEnabled,
      user
    };
  },
});
