import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chip = _resolveComponent("Chip")!

  return (_openBlock(), _createBlock(_component_Chip, {
    label: _ctx.text || _ctx.status,
    class: "status-chip chip",
    "data-status": _ctx.status
  }, null, 8, ["label", "data-status"]))
}