import { useAPI, useAuth } from '@/modules';
import {
  AppPagePerspective,
  CampaignData,
  CampaignList,
  Perspective,
} from '@/data/types';

const { user } = useAuth();

export const useCampaignsList = (perspective: AppPagePerspective) => {
  const {
    loading,
    finished,
    data: campaigns,
    execute,
  } = useAPI<Array<CampaignData>>('/campaigns', false);

  const loadCampaigns = (loadOnlyMine = false, limit = 0, search = '') => {
    const creatorID = user?.value?.id;
    const brandID = user?.value?.brand?.id;
    const targetID = perspective === Perspective.BRAND ? brandID : creatorID;

    if (perspective === Perspective.BRAND && !brandID) {
      throw new Error(
        'Could not load campaigns for a brand, because brandID is missing!'
      );
    }

    const params: { perspective: any; targetID: any; name?: string } = {
      perspective,
      targetID,
      name: search,
    };

    if (perspective === Perspective.CREATOR && !loadOnlyMine) {
      delete params.targetID;
    }

    if (!search) {
      delete params.name;
    }

    return execute({ params }).then((result?: Array<CampaignData>) => {
      if (result) {
        if (limit) {
          campaigns.value = result.slice(0, limit);
        } else {
          campaigns.value = result;
        }
      }
      return campaigns.value;
    });
  };

  const search =
    (termKey: string, limit: number) =>
    ({ term }: { term: string }) => {
      loadCampaigns(false, limit, term);
    };

  const {
    loading: getCampaignListLoading,
    error: getCampaignListFinished,
    data: getCampaignListData,
    execute: getCampaignListRequest,
  } = useAPI<CampaignList>('/campaigns', false);

  const getCampaignList = (
    loadOnlyMine = false,
    limit = 0,
    skip: number,
    search = '',
    status= 'all'
  ) => {
    const creatorID = user?.value?.id;
    const brandID = user?.value?.brand?.id;
    const targetID = perspective === Perspective.BRAND ? brandID : creatorID;

    if (perspective === Perspective.BRAND && !brandID) {
      throw new Error(
        'Could not load campaigns for a brand, because brandID is missing!'
      );
    }

    const params: {
      perspective: string;
      targetID: any;
      name?: string;
      limit: number;
      skip: number;
      status: string;
    } = {
      perspective,
      targetID,
      name: search,
      limit,
      skip,
      status,
    };

    if (perspective === Perspective.CREATOR && !loadOnlyMine)
      delete params.targetID;
    if (!search) delete params.name;

    return getCampaignListRequest({ params });
  };

  return {
    campaigns,
    loading,
    finished,
    loadCampaigns,
    search,
    getCampaignList,
    getCampaignListData,
    getCampaignListLoading,
  };
};
