<template>
  <Dialog :visible="viewModal" :style="{width: '90vw'}" :modal="true" :closable="false">
    <template #header>
      <span class="launch">Pay and Launch Campaign</span>
      <span class="close-btn-terms"
            @click="closePay">X</span>
    </template>
    <div>
      <payment-methods :first-pay-campaign="firstPayCampaign" :new-budget-data="newDataBudget" :campaign-id="campaignId"
                      @card-pay-success="$emit('campaignPaySuccess')"
                      @bank-pay-success="$emit('campaignPaySuccess')" :create-file-csv="createFileCsv" />
    </div>
  </Dialog>

</template>
<script>
import Dialog from 'primevue/dialog';
import { useCampaigns } from '@/modules';
import PaymentMethods from '../paymentMethods/PaymentMethods.vue';

export default {
  name: 'PayAndFinishModal',
  components: {
    PaymentMethods,
    Dialog,
  },
  props: {
    viewModal: {
      type: Boolean,
      default: false,
    },
    createFileCsv: {
      type: Boolean,
      default: false,
    },
    campaignId: String,
    newDataBudget: Object,
    firstPayCampaign: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const campaigns = useCampaigns();

    const cancelPay = async () => {
      await campaigns.manager.update(props.campaignId, { status: 'draft' });
    };

    const closePay = () => ctx.emit('cancelPay');

    return {
      closePay,
      cancelPay,
    };
  },
};
</script>

<style scoped>
.launch {
  font-weight: 800;
  font-size: 30px;
  line-height: 50px;
  color: #162020;
}

.close-btn-terms {
  position: absolute;
  top: 35px;
  right: 35px;
  font-size: 20px;
  font-weight: 500;
  color: #8B8F8F;
  cursor: pointer;
}
</style>
