
import { computed, defineComponent, ref, nextTick } from 'vue';
import Button from 'primevue/button';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import { useAPI } from '@/modules/api-module';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';
import { BrandData } from '@/data/types';

export default defineComponent({
  name: 'CreateBrand',

  components: { Card, InputText, Button, Textarea },

  setup() {
    const router = useRouter();
    const toast = useToast();

    const { loading, execute } = useAPI<BrandData>('/brands', false);
    const name = ref('');
    const description = ref('');

    const createButtonIcons = computed(() => {
      let result = 'pi ';
      if (loading.value) {
        result += 'pi-spin pi-spinner';
      } else {
        result += 'pi-check';
      }

      return result;
    });

    const create = () => {
      execute({
        params: {
          name: name.value,
          description: description.value,
        },
      })
        .then((brand) => {
          toast.add({
            severity: 'success',
            summary: 'Success',
            detail: `Brand "${brand!.name}" successfully created`,
            life: 3000,
          });
          nextTick(() => {
            router.push({ name: 'brand', params: { brandID: brand!.id } });
          });
        });
    };

    return {
      create,
      loading,
      name,
      description,
      createButtonIcons,
    };
  },
});
