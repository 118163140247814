import { useAPI } from '@/modules/api-module';
import { ref, watch } from 'vue';

export const usePageList = (endpoint: string, runOnMount = true, limit = 0) => {
  const { loading, data, error, execute } = useAPI(endpoint, runOnMount);

  const list = ref([]);

  watch(data, () => {
    if (data) {
      list.value = data.value.slice(0, limit || data.value.length);
    } else {
      list.value = [];
    }
  });

  return {
    loading,
    error,
    list,
    loadList: execute,
  };
};
