
import 'primeicons/primeicons.css';
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useCampaigns } from '@/modules';
import { formatDate } from '@/utils/globals/date-utils';
import { parseCountry, countries } from '@/data/static/countries';
import { parseLanguage, languages } from '@/data/static/languages';
import { AppPageConfig, AppPageProps } from '@/data/types';
import Tooltip from 'primevue/tooltip';
import AppPage from '@/pages/commons/AppPage.vue';
import shortCampaignPage from '../../creators/shortCampaignPage.vue';

export default defineComponent({
  name: 'Campaign',
  directives: {
    tooltip: Tooltip,
  },
  components: {
    shortCampaignPage,
    AppPage,
  },
  props: {
    campaignID: {
      type: String,
    },
    ...AppPageProps,
  },

  setup(props) {
    const router = useRouter();
    const route = useRoute();
    if (!route.params.campaignID) {
      console.warn('campaign id is missing', route.params.campaignID);
      router.push({ name: 'campaigns' });
      return null;
    }

    const { campaignID } = route.params;
    const config = ref<AppPageConfig>({
      title: 'Loading campaign',
      ...props,
    });
    const controller = useCampaigns(props.viewPerspective);
    const refreshData = () => controller.manager.loadSingle(route.params.campaignID as string);

    // publish campaign
    const publishCampaign = () => {
      controller.manager
        .publish(campaignID as string)
        .then((success: any) => {
          if (success) {
            refreshData();
          }
        });
    };
    // < publish campaign
    refreshData();

    return {
      config,
      // singleCampaign,
      campaign: controller.manager.singleCampaign,
      loading: controller.manager.loadingSingle,
      // formatting & parse
      formatDate,
      parseCountry,
      parseLanguage,
      // publish campaign
      publishing: controller.manager.publishing,
      publishCampaign,
      languages,
      countries,
    };
  },
});
