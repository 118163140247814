
import { defineComponent, ref, watch } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import { CampaignData, CampaignInvitationData, CampaignStatus, Perspective, } from '@/data/types';
import { useAuth, useCampaigns, useToastModule } from '@/modules';

export default defineComponent({
  props: {
    showModal: {
      type: Boolean,
    },
    creatorId: String,
    creator: Object,
    closeModal: Function,
  },
  components: {
    Dialog,
    Button,
    Dropdown,
  },
  setup(props) {
    const { user } = useAuth();
    const toast = useToastModule();
    const campaigns = useCampaigns(Perspective.BRAND);
    // invite to a campaign
    interface CampaignDropdownOption {
      id: string;
      name: string;
      optionDisabled: boolean;
    }

    const campaignInviteDropdownOptions = ref<Array<CampaignDropdownOption>>();
    const selectedCampaignID = ref<string>('');

    const updateCampaignsList = () => {
      campaigns.list
        .loadCampaigns(true)
        .then((data?: Array<CampaignData>) => {
          if (!data) {
            return;
          }
          campaignInviteDropdownOptions.value = data
            .filter((campaign) => campaign.status !== CampaignStatus.Active)
            .map((campaign) => {
              const result: CampaignDropdownOption = {
                id: campaign.id,
                name: campaign.name,
                optionDisabled: false,
              };
              const invitation: CampaignInvitationData | undefined = (campaign.invitations || [])
                .find((i: CampaignInvitationData) => i.creator.id === props.creatorId);
              if (invitation) {
                result.name = `${result.name} (already invited)`;
                result.optionDisabled = true;
              }
              return result;
            });
        });
    };
    if (user?.value?.isBrandOwner()) {
      updateCampaignsList();
    }

    watch(campaigns.invitations.inviteSuccess, () => {
      if (campaigns.invitations.inviteSuccess.value) {
        toast.showSuccess(`User invited successfully!`);
        updateCampaignsList();
      }
    });
    watch(campaigns.invitations.inviteError, toast.handleRequestError);

    const sendInvite = () => {
      if (selectedCampaignID.value && props.creatorId) {
        campaigns.invitations.invite(selectedCampaignID.value, props.creatorId as string);
      }
    };

    return {
      sendInvite,
      inviting: campaigns.invitations.inviting,
      selectedCampaignID,
      campaignInviteDropdownOptions,
    };
  }
})
