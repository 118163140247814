import React from 'react';
import "./NewContentItemsList.css";
import NewContentListItem from "@/react_app/NewLatestItems/NewContentListItem/NewContentListItem";

export default function(props) {
  return (
    <div className={'content-items-list'}>
      {props.items.map((item, index) =>
        <div key={index} className={'item-wrapper'}>
          <NewContentListItem item={item} creator={props.user} handleRouting={() => props.handleRouting(item.id)}/>
        </div>)}
    </div>
  )
}
