
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    url: String,
    type: {
      type: String as PropType<'brand' | 'user' | 'campaign' | 'add'>,
      required: true,
    },
    action: String,
  },

  setup(props) {
    const defaultIcon = computed(() => {
      if (props.type === 'brand') {
        return 'business';
      }
      if (props.type === 'user') {
        return 'account_circle';
      }
      // if (props.type === 'campaign') {
      //   return 'campaign';
      // }
      return '';
    });

    return {
      defaultIcon,
    };
  },
});
