import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-126a17e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "p-text-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "p-d-flex p-ai-center" }
const _hoisted_6 = { class: "value" }
const _hoisted_7 = { class: "p-d-flex" }
const _hoisted_8 = { class: "value" }
const _hoisted_9 = { class: "p-d-flex p-justify-center" }
const _hoisted_10 = { class: "value" }
const _hoisted_11 = { class: "p-d-flex" }
const _hoisted_12 = { class: "value" }
const _hoisted_13 = { class: "p-d-flex" }
const _hoisted_14 = { class: "value" }
const _hoisted_15 = {
  key: 0,
  class: "p-d-flex"
}
const _hoisted_16 = { class: "value" }
const _hoisted_17 = { class: "p-d-flex" }
const _hoisted_18 = { class: "value" }
const _hoisted_19 = { class: "p-d-flex" }
const _hoisted_20 = { class: "value" }
const _hoisted_21 = { class: "p-d-flex p-flex-column" }
const _hoisted_22 = { class: "value" }
const _hoisted_23 = { class: "p-d-flex p-flex-column" }
const _hoisted_24 = { class: "p-d-flex p-flex-column" }
const _hoisted_25 = { class: "value" }
const _hoisted_26 = { class: "p-d-flex p-flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, [
      (_ctx.isAdminData)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Earnings"))
        : (_openBlock(), _createElementBlock("span", _hoisted_4, "Agency earnings breakdown"))
    ]),
    _createVNode(_component_DataTable, {
      field: "expander",
      value: _ctx.data?.creatorEarnings,
      responsiveLayout: "scroll",
      expandedRows: _ctx.expandedRows,
      "onUpdate:expandedRows": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expandedRows) = $event)),
      dataKey: "creatorId",
      class: "p-mt-6 w-100"
    }, {
      expansion: _withCtx((slotProps) => [
        _createVNode(_component_DataTable, {
          value: slotProps.data?.links,
          responsiveLayout: "scroll",
          class: "w-100"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "creatorName",
              header: "Creator name"
            }, {
              body: _withCtx(({ data }) => [
                (data?.creatorName)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      (data?.creatorPhoto)
                        ? (_openBlock(), _createBlock(_component_Avatar, {
                            key: 0,
                            image: data?.creatorPhoto,
                            class: "p-mr-2 photo"
                          }, null, 8, ["image"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(data?.creatorName), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "campaignName",
              header: "Campaign"
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_17, [
                  (data?.campaignPhoto)
                    ? (_openBlock(), _createBlock(_component_Avatar, {
                        key: 0,
                        image: data?.campaignPhoto,
                        class: "p-mr-2 photo"
                      }, null, 8, ["image"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_18, _toDisplayString(data?.campaignName), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "campaignPaymentMethod",
              header: "Payment Method"
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("span", _hoisted_20, _toDisplayString(data?.campaignPaymentMethod), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "earned",
              header: "Earned"
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("span", _hoisted_22, _toDisplayString(data?.earned) + " USD", 1),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("span", null, "Android: " + _toDisplayString(data?.earnedStats?.android), 1),
                    _createElementVNode("span", null, "IOS: " + _toDisplayString(data?.earnedStats?.ios), 1),
                    _createElementVNode("span", null, "Clicks: " + _toDisplayString(data?.earnedStats?.clicks), 1)
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "paid",
              header: "Paid"
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("span", _hoisted_25, _toDisplayString(data?.paid) + " USD", 1),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("span", null, "Android: " + _toDisplayString(data?.paidStats?.android), 1),
                    _createElementVNode("span", null, "IOS: " + _toDisplayString(data?.paidStats?.ios), 1),
                    _createElementVNode("span", null, "Clicks: " + _toDisplayString(data?.paidStats?.clicks), 1)
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["value"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          expander: "",
          style: {"width":"5rem"}
        }),
        _createVNode(_component_Column, {
          header: "Creator",
          field: "photo",
          sortable: false
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_5, [
              (data?.photo)
                ? (_openBlock(), _createBlock(_component_Avatar, {
                    key: 0,
                    image: data?.photo,
                    class: "p-mr-2 photo"
                  }, null, 8, ["image"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_6, _toDisplayString(data?.name), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "Total Earnings",
          field: "totalEarnings",
          sortable: false
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(data?.totalEarnings), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "Paid Out",
          field: "totalPaid",
          sortable: false
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(data?.totalPaid), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "Eligible for Payout",
          field: "amountEarnedThisCycle",
          sortable: false
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(data?.amountEarnedThisCycle >= 100 ? "YES" : "No") + " - " + _toDisplayString(data?.amountEarnedThisCycle) + " USD ", 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "On Gigapay",
          field: "gigapayStatus",
          sortable: false
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("span", _hoisted_14, _toDisplayString(data?.gigapayStatus ? "Yes" : "No"), 1)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "expandedRows"])
  ]))
}