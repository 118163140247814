
import { ref } from 'vue';
import InputText from 'primevue/inputtext';

export default {
  name: 'VideoUrlInput',
  props: {
    input: Object,
  },
  components: {
    InputText,
  },
  setup(props: any) {
    const searchInput = ref(props.input);
    return { searchInput };
  },
};
