import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27139bfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "invitations-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_invitations_list_item = _resolveComponent("invitations-list-item")!
  const _component_empty_list = _resolveComponent("empty-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.campaigns, (campaign) => {
      return (_openBlock(), _createBlock(_component_invitations_list_item, {
        key: campaign.id,
        viewPerspective: _ctx.viewPerspective,
        campaign: campaign,
        onDelete: _ctx.deleteInvitation,
        onAccept: _ctx.acceptInvitation
      }, null, 8, ["viewPerspective", "campaign", "onDelete", "onAccept"]))
    }), 128)),
    (!_ctx.campaigns.length)
      ? (_openBlock(), _createBlock(_component_empty_list, {
          key: 0,
          class: "p-mt-6",
          title: "No Invitations",
          text: "Have you applied a campaign filter?"
        }))
      : _createCommentVNode("", true)
  ]))
}