import React from "react";
import "./NewBrandPhoto.css";
import { Image } from "primereact/image";

const NoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="45" viewBox="0 0 50 45" fill="none">
      <path d="M40 30H35V35H40M40 20H35V25H40M45 40H25V35H30V30H25V25H30V20H25V15H45M20 10H15V5H20M20 20H15V15H20M20 30H15V25H20M20 40H15V35H20M10 10H5V5H10M10 20H5V15H10M10 30H5V25H10M10 40H5V35H10M25 10V0H0V45H50V10H25Z" fill="#3A3A3A"/>
    </svg>
  )
}

export default function(props){
  return(
    <div className={props.photo ? 'brand-photo' : 'brand-photo-no'}>
      {props.photo ?
        <Image src={props.photo} className={props.redBorder ? 'photoBrandRedBorder' : 'photoBrand'} alt={"brand-photo"}/> :
        <div className={'noPhotoIcon'}>
          <NoIcon/>
        </div>
      }
    </div>
  )
}
