import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewCampaignStats = _resolveComponent("NewCampaignStats")!
  const _component_OldCampaignStats = _resolveComponent("OldCampaignStats")!

  return (_ctx.isNewCampaignsManagementEnabled)
    ? (_openBlock(), _createBlock(_component_NewCampaignStats, {
        key: 0,
        campaign: _ctx.campaign,
        campaignClosing: _ctx.campaignClosing,
        liveContentItems: _ctx.liveContentItems,
        handleReturn: _ctx.handleReturn,
        saveEdit: _ctx.saveEdit,
        goToContent: _ctx.goToContent,
        pushDraft: _ctx.pushDraft,
        handlePublishCampaign: _ctx.handlePublishCampaign,
        campaignId: _ctx.campaignId,
        isLoadingPublishCampaign: _ctx.isLoadingPublishCampaign
      }, null, 8, ["campaign", "campaignClosing", "liveContentItems", "handleReturn", "saveEdit", "goToContent", "pushDraft", "handlePublishCampaign", "campaignId", "isLoadingPublishCampaign"]))
    : (_openBlock(), _createBlock(_component_OldCampaignStats, {
        key: 1,
        name: _ctx.name
      }, null, 8, ["name"]))
}