
import { defineComponent, watch } from 'vue';
import Button from 'primevue/button';

import { usePage } from '@/modules';
import { useContentItems } from '@/modules/content-items/content-items-module';
import { AppPageConfig, AppPageProps } from '@/data/types';
import AppPage from '../commons/AppPage.vue';
import ContentItemsList from './ContentItemsList.vue';

export default defineComponent({
  name: 'ContentItems',

  components: { AppPage, ContentItemsList, Button },

  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    ...AppPageProps,
    private: Boolean,
    limit: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: 'Content Items',
      search: {
        enabled: props.showSearch,
        defaultType: 'creators',
        placeholder: 'Search for Content items',
      },
      ...props,
    });

    const page = useContentItems(config.value.viewPerspective);
    const loadPageData = () => page.list.loadContentItems(props.private, props.limit, '');

    loadPageData();
    watch(() => props.private, loadPageData);

    return {
      config,
      page,
      loading: page.list.loading,
      contentItems: page.list.items,
      sync: page.manager.sync,
      syncing: page.manager.syncingInProgress,
      performSearch: page.list.search('name', props.limit || 0),
    };
  },
});
