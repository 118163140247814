
import { defineComponent } from 'vue';

import { useAPI, usePage, useCampaigns } from '@/modules';
import {
  AppPageConfig,
  AppPageProps,
  CampaignData,
  CampaignInvitationData,
} from '@/data/types';
import AppCampaignSubpage from '../../commons/AppCampaignSubpage.vue';
import InvitationsList from './InvitationsList.vue';
import InvitationsListItem from "@/pages/campaigns/invitations/InvitationsListItem.vue";

export default defineComponent({
  components: { InvitationsList, AppCampaignSubpage },
  props: AppPageProps,
  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: 'Campaign Invitations',
      ...props,
    });
    const page = useCampaigns(config.value.viewPerspective);
    page.invitations.loadInvitations();

    // delete
    const updateStatusInvitation = (campaign: CampaignData, invitation: CampaignInvitationData, status: string) => {
      const { execute: sendDeleteInvitation } = useAPI('', { method: 'put' }, false);
      sendDeleteInvitation({
        url: `/campaigns/invitations/${campaign.id}/${invitation.id}`,
        data: { status },
      })
        .then(() => {
          page.invitations.loadInvitations();
        });
    }
    const deleteInvitation = async ({ campaign, invitation }: { campaign: CampaignData; invitation: CampaignInvitationData }) => {
      updateStatusInvitation(campaign, invitation, 'rejected');
    };

    const acceptInvitation = async ({ campaign, invitation }: { campaign: CampaignData; invitation: CampaignInvitationData }) => {
      updateStatusInvitation(campaign, invitation, 'accepted');
    };

    return {
      config,
      loading: page.invitations.loading,
      campaigns: page.invitations.invitations,
      associatedCampaigns: page.invitations.associatedCampaigns,
      filterByCampaignID: page.invitations.filterByCampaignID,
      deleteInvitation,
      acceptInvitation,
    };
  },
});
