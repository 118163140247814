
import { defineComponent } from 'vue';
import { useAPI, usePage, useCampaigns } from '@/modules';
import {
  AppPageConfig,
  AppPageProps,
  CampaignData,
  CampaignInvitationData,
} from '@/data/types';
import { applyPureReactInVue } from 'veaury';
import NewInvitations from "@/react_app/NewInvitations/NewInvitations";
import OldInvitations from "./OldInvitations.vue";
import { isNewCampaignInvitationsEnabled } from '@/utils/globals/feature-flags';
import router from '@/router';

export default defineComponent({
  components: {
    NewInvitations: applyPureReactInVue(NewInvitations),
    OldInvitations
  },
  props: AppPageProps,
  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: 'Campaign Invitations',
      ...props,
    });
    const page = useCampaigns(config.value.viewPerspective);
    page.invitations.loadInvitations();

    // delete
    const updateStatusInvitation = (campaign: CampaignData, invitation: CampaignInvitationData, status: string) => {
      const { execute: sendDeleteInvitation } = useAPI('', { method: 'put' }, false);
      sendDeleteInvitation({
        url: `/campaigns/invitations/${campaign.id}/${invitation.id}`,
        data: { status },
      })
        .then(() => {
          page.invitations.loadInvitations();
        });
    }

    const goToCampaign = (id: string) => {
      router.push({ name: 'campaign-active', params: { campaignID: id } });
    }

    return {
      config,
      // dataLoaded,
      loading: page.invitations.loading,
      campaigns: page.invitations.invitations,
      associatedCampaigns: page.invitations.associatedCampaigns,
      filterByCampaignID: page.invitations.filterByCampaignID,
      updateStatusInvitation,
      isNewCampaignInvitationsEnabled,
      goToCampaign
    };
  },
});
