import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreatorsList = _resolveComponent("CreatorsList")!
  const _component_app_page = _resolveComponent("app-page")!

  return (_openBlock(), _createBlock(_component_app_page, {
    config: _ctx.config,
    loading: _ctx.loading,
    onSearch: _ctx.performSearch
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_CreatorsList, {
              key: 0,
              creators: _ctx.creators
            }, null, 8, ["creators"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["config", "loading", "onSearch"]))
}