import { watch } from 'vue';
import { useAPI, useToastModule } from '@/modules';
import { CampaignData } from '@/data/types';
import { ITransactionItem } from "@/data/types/payment-types";

export const useTransfersManager = () => {
  const toast = useToastModule();

  // < transfer
  const {
    loading: updateInvoicesProgress,
    error: updateInvoiceError,
    data: updateInvoiceResult,
    execute: updateInvoiceRequest,
  } = useAPI<ITransactionItem>('', false);

  const updateInvoice = (transferId: string, invoice: FormData) => updateInvoiceRequest({
    url: `/transfers/invoice/${transferId}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: invoice,
  });
  watch(updateInvoiceError, toast.handleRequestError);
  // <  transfer

  // < transfer status
  const {
    loading: changeTransferStatusProgress,
    error: changeTransferStatusError,
    data: changeTransferStatusResult,
    execute: changeTransferStatusRequest,
  } = useAPI<ITransactionItem>('', false);

  const changeTransferStatus = (transferId: string, status: string) => changeTransferStatusRequest({
    url: `/transfers/status/${transferId}`,
    method: 'PUT',
    params: { status },
  });
  watch(changeTransferStatusError, toast.handleRequestError);
  // <  transfer status

  return {
    // change transfer status
    changeTransferStatus,
    changeTransferStatusResult,
    changeTransferStatusError,
    changeTransferStatusProgress,
    // update invoice
    updateInvoice,
    updateInvoiceResult,
    updateInvoiceError,
    updateInvoicesProgress,
  };
};
