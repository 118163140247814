import { useAPI, useAuth } from '@/modules';
import {
  AppPagePerspective, Perspective, ContentItem, ContentItemData,
} from '@/data/types';
import { watch } from 'vue';
import { useToastModule } from '@/modules/messages/toast-module';

const { user } = useAuth();

export const useContentItemsList = (perspective: AppPagePerspective) => {
  const toast = useToastModule();
  const { loading, error, data: items, execute } = useAPI<Array<ContentItem>>('/content', false);

  // FIXME: fix all the limit things!
  const loadContentItems = (loadOnlyMine = false, limit = 0, search = '') => {
    const creatorID = user?.value?.id;
    const brandID = user?.value?.brand?.id;
    const targetID = perspective === Perspective.BRAND ? brandID : creatorID;

    if (perspective === Perspective.BRAND && !brandID) {
      throw new Error('Could not load content items for a brand, because brandID is missing!');
    }

    const params: { perspective: any, targetID: any, name?: string } = { perspective, targetID, name: search };

    if (!loadOnlyMine) {
      delete params.targetID;
    }

    if (!search) {
      delete params.name;
    }

    return execute({
      params,
      parseResponse: (response: Array<ContentItemData>) => {
        const r = limit ? response.slice(0, limit) : response;
        return r
      },
    });
  };

  const search = (termKey: string, limit: number) => ({ term }: { term: string }) => {
    loadContentItems(false, limit, term);
  };

  // load getMyLatestContents item
  const {
    loading: loadMyLatestProgress,
    error: loadMyLatestError,
    data: loadMyLatestResult,
    execute: sendLoadMyLatestquest,
  } = useAPI<{ contentItems: ContentItem[] }>('', false);

  const loadMyLatest = (creatorId: string, limit: number) => sendLoadMyLatestquest({
    url: `/content/latest/${creatorId}/${limit}`,
    method: 'GET',
  });
  watch(loadMyLatestError, toast.handleRequestError);
  // < load getMyLatestContents item

  // load all creators getMyLatestContents item
  const {
    loading: loadAllPublicLatestProgress,
    error: loadAllPublicLatestError,
    data: loadAllPublicLatestResult,
    execute: sendLoadAllPublicLatestRequest,
  } = useAPI<ContentItem>('', false);

  const loadAllPublicLatest = (limit: number) => sendLoadAllPublicLatestRequest({
    url: `/content/public/${limit}`,
    method: 'GET',
  });
  watch(loadAllPublicLatestError, toast.handleRequestError);
  // < load getMyLatestContents item

  // load all creators getMyLatestContents item
  const {
    loading: loadAllPublicContentCreatorProgress,
    error: loadAllPublicContentCreatorError,
    data: loadAllPublicContentCreatorResult,
    execute: loadAllPublicContentCreatorRequest,
  } = useAPI<ContentItem>('', false);

  const loadAllPublicContentCreator = (creatorId: string, limit: number) => loadAllPublicContentCreatorRequest({
    url: `/content/public/creator/${creatorId}/${limit}`,
    method: 'GET',
  });
  watch(loadAllPublicContentCreatorError, toast.handleRequestError);
  // < load all creators getMyLatestContents item

  // load getCampaignContent
  const {
    loading: getCampaignContentProgress,
    error: getCampaignContentError,
    data: getCampaignContentResult,
    execute: getCampaignContentRequest,
  } = useAPI<Array<ContentItem>>('', false);

  const getCampaignContent = (campaignId: string, showCompletedVideos = true) => getCampaignContentRequest({
    url: `/content/campaign/${campaignId}/${showCompletedVideos}`,
    method: 'GET',
  });
  watch(getCampaignContentError, toast.handleRequestError);
  // < getCampaignContent

  return {
    getCampaignContent,
    getCampaignContentResult,
    getCampaignContentError,
    getCampaignContentProgress,
    // get live content for campaign
    loadAllPublicContentCreatorProgress,
    loadAllPublicContentCreatorError,
    loadAllPublicContentCreatorResult,
    loadAllPublicContentCreator,
    // < get all public content creator
    loadAllPublicLatestProgress,
    loadAllPublicLatestError,
    loadAllPublicLatestResult,
    loadAllPublicLatest,
    // load all public latest
    loadMyLatestProgress,
    loadMyLatestError,
    loadMyLatestResult,
    loadMyLatest,
    // load latest my content
    loadContentItems,
    search,
    loading,
    error,
    items,
  };
};
