
import { defineComponent, PropType, ref, watch } from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import EntityPhoto from '@/pages/commons/media/EntityPhoto.vue';

export default defineComponent({
  components: { EntityPhoto, Button, InputText },
  props: {
    current: {
      type: String,
      default: '',
    },
    photo: {
      type: String,
    },
    type: {
      type: String as PropType<'brand' | 'user' | 'campaign'>,
      required: true,
    },
    allowAdd: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const availablePhotos = ref<Array<string | ArrayBuffer | null>>([]);
    const selectedPhoto = ref<string | undefined>(props.photo || '');
    const customURL = ref('');
    const showAddInput = ref(false);
    const uploadPhotoInput = ref();

    watch(() => props.photo, () => {
      selectedPhoto.value = props.photo;
    })

    const handleFileUpload = () => {
      if (uploadPhotoInput.value) {
        const file = uploadPhotoInput.value.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          availablePhotos.value.push(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
    const addCustomPhoto = () => {
      if (customURL.value) {
        availablePhotos.value.push(customURL.value);
        showAddInput.value = false;
        customURL.value = '';
        selectPhoto(customURL.value);
      }
    };
    const removeCustomPhoto = () => {
      if (!selectedPhoto.value) {
        return;
      }
      const index = availablePhotos.value.findIndex((i) => i === selectedPhoto.value);
      if (index > -1) {
        availablePhotos.value.splice(index, 1);
        selectPhoto(availablePhotos.value[availablePhotos.value.length - 1]);
      }
    };
    const selectPhoto = (photo: any) => {
      selectedPhoto.value = photo;
      showAddInput.value = false;
      emit('selected', selectedPhoto.value);
      emit('update:photo', selectedPhoto.value);
      emit('update:modelValue', selectedPhoto.value);
    };
    return {
      availablePhotos,
      uploadPhotoInput,
      handleFileUpload,
      selectedPhoto,
      customURL,
      showAddInput,
      addCustomPhoto,
      removeCustomPhoto,
      selectPhoto,
    };
  },
});
