import moment from 'moment';
import { User, UserData } from '@/data/types/user-types';
import { NegotiationOffer } from '@/data/types/negotiation-types';

export enum ContentItemType {
  Video = 'video',
}

export enum ContentStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export interface ContentItemThumbnail {
  url: string;
  width: number;
  height: number;
}

export interface ContentItemThumbnails {
  default: ContentItemThumbnail;
  medium: ContentItemThumbnail;
}

export interface LicenseRequestRequest {
  startDate: string;
  duration: {
    period: number;
    type: 'day' | 'week' | 'month' | 'year';
    perpetual: boolean;
  };
  format?: 'landscape' | 'portrait';
  platforms: 'social-media' | 'linear-tv' | 'social-media+linear-tv';
  price?: number;
  final?: boolean;
}
export interface LicenseRequest extends LicenseRequestRequest {
  status: 'pending' | 'accepted' | 'rejected';
  brand: {
    id: string;
    name: string;
    photo: string;
  };
  offers: Array<NegotiationOffer>;
}

export interface ContentItemData {
  id: string;
  title: string;
  type: ContentItemType;
  provider: string; // youtube
  publishedAt: string;
  owner: UserData;
  statusAccept: boolean | undefined;
  thumbnails: ContentItemThumbnails;
  previewPhoto: string;
  createdAt: string;
  channelId: string;
  campaignId: { id: string; budget: { currency: string; amount: number }};
  statistics: any;

  public: boolean;
  licenseRequests?: Array<LicenseRequest>;

  // video (wip)
  vid: string; // youtube video id
}

export function parseContentItems(items: Array<ContentItemData>, user?: User): Array<ContentItem> {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return items.map((data: ContentItemData) => new ContentItem(data, user));
}

export class ContentItem {
  public readonly id: string;
  public readonly title: string;
  public readonly type: ContentItemType;
  public readonly provider: string;
  public readonly publishedAt: string;
  public readonly thumbnails: ContentItemThumbnails;
  public readonly owner: User;
  public readonly public: boolean;
  public readonly licenseRequests: Array<LicenseRequest>;
  public readonly vid: string;
  public readonly previewPhoto: string;
  public readonly createdAt: string;
  public readonly channelId: string;
  public readonly campaignId: any;
  public readonly statistics: any;

  constructor(data: ContentItemData, owner?: User | UserData) {
    this.id = data.id;
    this.title = data.title;
    this.type = data.type;
    this.provider = data.provider;
    this.publishedAt = moment(data.publishedAt).format('D MMM YYYY');
    this.thumbnails = data.thumbnails;
    this.previewPhoto = data.previewPhoto;
    this.createdAt = data.createdAt;
    this.channelId = data.channelId;
    this.campaignId = data.campaignId;
    this.statistics = data.statistics;

    if (owner && owner instanceof User) {
      this.owner = JSON.parse(JSON.stringify(owner));
    } else {
      this.owner = new User(owner || data.owner);
    }

    this.public = data.public;
    this.licenseRequests = data.licenseRequests || [];
    this.vid = data.vid;
  }
}
