import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewCampaignActivePage = _resolveComponent("NewCampaignActivePage")!
  const _component_OldCampaignActivePage = _resolveComponent("OldCampaignActivePage")!

  return ($setup.isNewCampaignPageEnabled)
    ? (_openBlock(), _createBlock(_component_NewCampaignActivePage, {
        key: 0,
        campaign: $setup.campaign,
        handleBackToAll: $setup.handleBackToAll,
        handleContentRouting: $setup.handleContentRouting,
        handleRoutingAll: $setup.handleRoutingAll,
        isCreator: true,
        toggleCampaignFavorite: $setup.toggleCampaignFavorite,
        isCampaignFav: $setup.isCampaignFav,
        agencyWorkers: $setup.agencyWorkers,
        disableApplyButton: $setup.disableApplyButton,
        currentApplicationInfoForAgency: $setup.currentApplicationInfoForAgency,
        isUserAppliedToCampaign: $setup.isUserAppliedToCampaign,
        isCampaignCPM: $setup.isCampaignCPM,
        loadingAccept: $setup.loadingAccept,
        getIndividualChannelInfo: $setup.getIndividualChannelInfo,
        getIndividualChannelApplicationPayoutCap: $setup.getIndividualChannelApplicationPayoutCap,
        getApplyButtonLabel: $setup.getApplyButtonLabel,
        selectedChannels: $setup.selectedChannels,
        searchInfluencersUser: $setup.searchInfluencersUser,
        creator: $setup.creator,
        currentUserId: $setup.currentUserId,
        displayModeratedContent: $setup.displayModeratedContent,
        acceptChannels: $setup.acceptChannels,
        currentApplicationInfoForCreator: $setup.currentApplicationInfoForCreator,
        progressCpmCampaign: $setup.progressCpmCampaign,
        resolveCapProposalWrapperFn: $setup.resolveCapProposalWrapperFn,
        isSelectedChannel: $setup.isSelectedChannel,
        selectChannel: $setup.selectChannel,
        showApprovedMessagePrompt: $setup.showApprovedMessagePrompt,
        handleApply: $setup.handleApply,
        showRejectedMessagePrompt: $setup.showRejectedMessagePrompt,
        applicationStatus: $setup.applicationStatus,
        cpmApplicationPrompt: $setup.cpmApplicationPrompt,
        unmountPrompt: $setup.unmountPrompt,
        leaveCampaign: $setup.withdrawCampaign,
        findProgressChannel: $setup.findProgressChannel,
        withdrawChannelApplication: $setup.withdrawChannelApplication,
        displayChannelApplicationStatusCondition: $setup.displayChannelApplicationStatusCondition,
        getPayoutCap: $setup.getPayoutCap,
        getIndividualChannelApplicationDisplay: $setup.getIndividualChannelApplicationDisplay,
        gigapayPrompt: $setup.gigapayPrompt,
        handleRoutingHomepage: $setup.handleRoutingHomepage,
        isLoading: $setup.isLoading,
        getProgressCpm: $setup.getProgressCpm,
        resetSelectChannel: $setup.resetSelectChannel,
        selectAllItems: $setup.selectAllItems,
        currentCreatorCap: $setup.currentCreatorCap,
        currentAgencyCreatorsCap: $setup.currentAgencyCreatorsCap,
        isLoadingAgencyCap: $setup.isLoadingAgencyCap
      }, null, 8, ["campaign", "handleBackToAll", "handleContentRouting", "handleRoutingAll", "toggleCampaignFavorite", "isCampaignFav", "agencyWorkers", "disableApplyButton", "currentApplicationInfoForAgency", "isUserAppliedToCampaign", "isCampaignCPM", "loadingAccept", "getIndividualChannelInfo", "getIndividualChannelApplicationPayoutCap", "getApplyButtonLabel", "selectedChannels", "searchInfluencersUser", "creator", "currentUserId", "displayModeratedContent", "acceptChannels", "currentApplicationInfoForCreator", "progressCpmCampaign", "resolveCapProposalWrapperFn", "isSelectedChannel", "selectChannel", "showApprovedMessagePrompt", "handleApply", "showRejectedMessagePrompt", "applicationStatus", "cpmApplicationPrompt", "unmountPrompt", "leaveCampaign", "findProgressChannel", "withdrawChannelApplication", "displayChannelApplicationStatusCondition", "getPayoutCap", "getIndividualChannelApplicationDisplay", "gigapayPrompt", "handleRoutingHomepage", "isLoading", "getProgressCpm", "resetSelectChannel", "selectAllItems", "currentCreatorCap", "currentAgencyCreatorsCap", "isLoadingAgencyCap"]))
    : (_openBlock(), _createBlock(_component_OldCampaignActivePage, {
        key: 1,
        item: $props.item
      }, null, 8, ["item"]))
}