
import { ref, defineComponent, onMounted, onUnmounted } from 'vue';
import { useCounter } from '@vueuse/core';
import { Ref } from 'vue'; // Import the Ref type

export default defineComponent({
  props: {
    isMobile: Boolean,
    view: String,
  },
  setup() {
    const influencerCounter = useCounter(0, { max: 2000 });
    const campaignCounter = useCounter(0, { max: 50 });
    const agencyCounter = useCounter(0, { max: 50 });

    const sectionRef = ref<HTMLElement | null>(null);

    const incrementCounter = (
      counter: Ref<number>,
      targetValue: number,
      duration: number,
      speedUpThreshold = 1000,
      speedUpFactor = 2
    ) => {
      const initialStepTime = Math.floor(duration / targetValue);
      let incrementStep = Math.ceil(targetValue / (duration / initialStepTime));

      const interval = setInterval(() => {
        if (counter.value >= targetValue) {
          clearInterval(interval);
          return;
        }

        if (counter.value >= speedUpThreshold) {
          incrementStep *= speedUpFactor; // Increase the step size to speed up
        }

        counter.value = Math.min(counter.value + incrementStep, targetValue);
      }, initialStepTime);
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          incrementCounter(influencerCounter.count, 2000, 2000, 300, 5);
          incrementCounter(campaignCounter.count, 50, 1000);
          incrementCounter(agencyCounter.count, 50, 1000);
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    });

    onMounted(() => {
      if (sectionRef.value) {
        observer.observe(sectionRef.value);
      }
    });

    onUnmounted(() => {
      if (sectionRef.value) {
        observer.unobserve(sectionRef.value);
      }
    });

    function formatNumber(number: number): string {
      return number.toLocaleString('en-US', {
        notation: 'compact',
        compactDisplay: 'short',
        maximumFractionDigits: 1,
      });
    }


    return {
      allInfo: ref([
        { id: 1, title: 'Influencers', count: influencerCounter.count },
        { id: 2, title: 'Campaigns', count: campaignCounter.count },
        { id: 3, title: 'Talent Agencies', count: agencyCounter.count },
      ]),
      sectionRef,
      formatNumber
    };
  },

});
