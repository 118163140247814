<template>
  <div class="influencer-block flex p-lg-5">

    <img v-if="!isChannel" :src="creator?.type === 'agencyOwner' ? creator?.agencyData?.photo : creator?.profile?.photo" class="inf-img"/>
    <img v-if="isChannel" :src="channelData?.channel?.photo" class="inf-img"/>
    <div class="flex p-mt-6">
      <span class="name" v-if="!isChannel">
      {{ creator?.type === 'agencyOwner' ? creator?.agencyData?.name : creator?.name }}
    </span>
      <span class="name" v-if="isChannel">
      {{ channelData?.channel?.title }}
    </span>

      <span v-if="!isChannel" class="p-mt-2">{{ creator?.type !== 'creator' ? 'Agency' : 'Influencer' }}</span>
      <span v-if="isChannel" class="p-mt-2">influencer</span>
    </div>
    <div class="p-d-flex p-justify-between p-mt-5 p-flex-wrap">
      <div class="flex p-mr-3">
        <span class="tittle">Fast reply</span>
        <div class="flex p-flex-row p-mt-2"><span class="rating">--.--/5</span>
          <div class="star"></div>
        </div>
      </div>
      <div class="flex p-mr-3">
        <span class="tittle">Brief accuracy</span>
        <div class="flex p-flex-row p-mt-2"><span class="rating">--.--/5</span>
          <div class="star"></div>
        </div>
      </div>
      <div class="flex">
        <span class="tittle">Content</span>
        <div class="flex p-flex-row p-mt-2"><span class="rating">--.--/5</span>
          <div class="star"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CampaignData } from '@/data/types/campaign-types';

export default {
  name: 'InfluencerInformation',
  props: {
    isChannel: {
      type: Boolean,
      default: false,
    },
    creator: Object,
    channelData: Object,
    channelStats: {
      android: Number,
      IOS: Number,
      clicks: Number,
      conversions: Number,
      forPay: {
        IOS: Number,
        android: Number,
        total: Number,
        currency: String,
      },
    },
    campaign: CampaignData,
  },
};
</script>

<style scoped lang="scss">
.star {
  mask-image: url('../assets/images/star.svg');
  mask-repeat: no-repeat;
  background-color: #F2D24C;;
  width: 15px;
  height: 15px;
}

.influencer-block {
  max-width: 478px;
  min-height: 335px;
  max-height: 335px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 10px;
}

.inf-img {
  width: 94.75px;
  height: 94.75px;
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15));
  border-radius: 50%;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rating {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #B9BCBC;
  margin-right: 6px;
  display: flex;
}

.tittle {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #686E6E;
}

.name {
  font-weight: bold;
  font-size: 18px;
  color: #162020;
}
</style>
