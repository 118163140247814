import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "content-items-list p-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_items_list_item = _resolveComponent("content-items-list-item")!
  const _component_empty_list = _resolveComponent("empty-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_content_items_list_item, {
        key: item.id,
        class: "p-col-12 p-md-6 p-lg-6 p-xl-4 p-xxl-3",
        item: item
      }, null, 8, ["item"]))
    }), 128)),
    (!_ctx.items.length)
      ? (_openBlock(), _createBlock(_component_empty_list, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}