
import { defineComponent, PropType } from 'vue';
import { Brand, BrandData } from '@/data/types';
import BrandPhoto from '../../brands/profile/BrandPhoto.vue';

export default defineComponent({
  components: { BrandPhoto },

  props: {
    brand: Object as PropType<Brand | BrandData>,
  },
});
