import uid from './uid';

const getDefaultPopupName = () => `Popup ${uid()}`;

export interface PopupOptions {
  width: number;
  height: number;
  menubar?: 'yes' | 'no';
  resizable?: 'yes' | 'no';
  location?: 'yes' | 'no';
  scrollbars?: 'yes' | 'no';
  centered?: boolean;
  top?: number;
  left?: number;
}

export const defaultOptions: PopupOptions = {
  width: 700,
  height: 520,
  menubar: 'no',
  resizable: 'yes',
  location: 'yes',
  scrollbars: 'no',
  centered: true,
};

const convertCenteredOption = (options: PopupOptions): PopupOptions => {
  const result = { ...options };

  if (options && options.centered) {
    const width = window.outerWidth - options.width;
    const height = window.outerHeight - options.height;

    result.left = options.left || Math.round(window.screenX + width / 2);
    result.top = options.top || Math.round(window.screenY + height / 2.5);

    delete result.centered;
  }

  return result;
};

const serializeOptions = (options: PopupOptions) => Object.entries(options).map(([key, value]) => `${key}=${value}`).join(',');

export default function openPopup(
  url: string,
  name: string,
  options: any,
  callback?: (err?: Error | null, user?: object) => void,
): Window | null {
  const popupOptions = convertCenteredOption({ ...defaultOptions, ...options });
  const popupCallback = callback || function noop() {}; // eslint-disable-line @typescript-eslint/no-empty-function
  const optionsString = serializeOptions(popupOptions);
  const popupWindow = window.open(url, name || getDefaultPopupName(), optionsString);

  let isMessageSent = false;
  let interval: number;

  function popupCallbackOnce(err: Error | null, data?: object) {
    if (!isMessageSent) {
      isMessageSent = true;
      popupCallback(err, data);
    }
  }

  function onMessage(message: MessageEvent) {
    const data = message?.data;
    const source = data?.source;
    if (source === 'gi') {
      popupCallbackOnce(null, data);
      window.removeEventListener('message', onMessage);
    }
  }

  window.addEventListener('message', onMessage, false);

  if (popupWindow) {
    interval = window.setInterval(() => {
      if (popupWindow == null || popupWindow.closed) {
        window.setTimeout(() => {
          window.clearInterval(interval);
          popupCallbackOnce(new Error('popup-closed'));
        }, 500);
      }
    }, 100);
  } else {
    popupCallbackOnce(new Error('popup-blocked'));
  }

  return popupWindow;
}

export function openSocialPopup(network: string, state: any = {}, callback?: any): Window | null {
  const url = new URL(`${process.env.VUE_APP_API_URL}/auth/${network}`);
  url.search = new URLSearchParams(state).toString();

  return openPopup(
    url.toString(),
    `${network} Connect`,
    {
      height: 750,
    }, // options
    (err?: Error | null, userData?: unknown) => {
      if (callback) {
        callback(err, userData);
      }
    },
  );
}
