
import { defineComponent, ref, watch } from 'vue';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import { useCampaigns } from '@/modules';
import Terms from '../../static/Terms.vue';
import ToltipInfo from '../../utility/ToltipInfo.vue';
import { AgencyWorkers, TrackingLink } from '@/data/types/user-types';
import { CampaignContractData, CampaignData, CampaignPaymentMethod } from '@/data/types';

export default defineComponent({
  name: 'TrackingLink.vue',
  components: {
    Checkbox,
    Terms,
    ToltipInfo,
    Button,
  },
  props: {
    contract: Object,
    user: Object,
  },
  setup(props) {
    const trackingLink = ref();
    const showTerms = ref();
    const acceptedTerms = ref();
    const campaignModule = useCampaigns();
    const agree = ref(false);

    watch(() => props.contract, () => {
      if (props && props?.user && props?.contract) {
        if (props.user?.isAgencyOwner()) {
          trackingLink.value = props.contract.trackingLinks.filter((currentLink: TrackingLink) => {
            if (currentLink.creatorId === props?.user?.id) {
              return true;
            }

           if (props?.user?.agencyOwners?.some((agencyWorker: AgencyWorkers) => agencyWorker?.channel?.channelId === currentLink?.channelId)) {
            return true;
           }
          })[0]?.link;
        } else if (props?.user.isCreator()) {
          trackingLink.value = props.contract.trackingLinks.filter((currentLink: TrackingLink) => {
            if (currentLink.creatorId === props?.user?.id) {
              return true;
            }
          })[0]?.link;
        }
      }
    });

    const copyLink = (url: string) => {
      navigator.clipboard.writeText(url);
    };

    // The campaigns have 'acceptTerms' which is an array of IDs and a "contracts" property which is an object
    // The person who accepted the terms and conditions should be findable in the contracts array and must have a setup channel
    const contractHasCreatorAndSetChannel = (acceptedCreatorId: string, contract: CampaignData) => {
      if (contract?.contracts) {
        return acceptedCreatorId.toString() === contract.contracts[0]?.creator?.id.toString() || contract.contracts[0]?.channel;
      }

      return false;
    };

    const contractMatchesChannel = (acceptedCreatorId: string, contract: CampaignData) => {
      if (contract?.contracts) {
        return acceptedCreatorId.toString() === contract?.contracts[0]?.channel?.toString();
      }

      return false;
    };

    // Improve readability by extracting all the findIndexes and business logic from the vue template
    const findConditionInCampaignTerms = (callbackFn: (acceptedCreatorId: string, contract: CampaignData) => boolean | string | undefined, contract: CampaignData) => {
      return contract?.agreeTerms.findIndex((acceptedCreatorId: string) => callbackFn(acceptedCreatorId, contract)) >= 0 ? true : false;
    };

    
    return {
      trackingLink,
      showTerms,
      acceptedTerms,
      campaignModule,
      agree,
      copyLink,
      contractHasCreatorAndSetChannel,
      contractMatchesChannel,
      findConditionInCampaignTerms,
      CampaignPaymentMethod,
    };
  },
});
