
import { ref } from "vue";
import {
  useCreators, usePage, useContentItems, useAuth, usePayments
} from "@/modules";
import { AppPageConfig, AppPagePerspective } from "@/data/types";
import PaymentInformationStatus from "../../commons/status/PaymentInformationStatus.vue";
import { useRoute } from "vue-router";
import { useCampaignContracts } from "@/modules/campaigns/campaign-contracts-module";
import Overview from "./Overview.vue";
import Campaigns from "./Campaigns.vue";
import Inbox from "./Inbox.vue";
import LicensedContent from "./LicensedContent.vue";
import BillingAccount from "./BillingAccount.vue";
import paymentInformationModal from "../../campaigns/create/paymentInformationModal.vue";
import AcceptContentTerms from "./AcceptContentTerms.vue";
import CreateAgency from "./CreateAgency.vue";
import EarningsPage from "../../admin/EarningsPage.vue";

export default {
  components: {
    Overview,
    Campaigns,
    Inbox,
    LicensedContent,
    BillingAccount,
    paymentInformationModal,
    PaymentInformationStatus,
    AcceptContentTerms,
    CreateAgency,
    EarningsPage,
  },
  props: {
    viewPerspective: String,
  },
  setup(props: any) {
    const { config } = usePage<AppPageConfig>({
      title: "account creator",
      ...props,
    });
    const tabview = ref(0);
    const gigapayModule = usePayments();
    const route = useRoute();
    const content = useContentItems();
    const controller = useCreators(config.value.viewPerspective);
    const applications = ref();
    const { user } = useAuth();
    const contracts = ref();
    const closeModalRef = ref(false);
    const contractManager = useCampaignContracts(config.value.viewPerspective as AppPagePerspective);

    const gigapayUser = ref();
    gigapayModule.checkVerified().then((data) => {
      gigapayUser.value = data;
    });

    if (user.value && user.value?.type === "creator") {
      contractManager.loadContracts().then((contractsArray) => {
        contractsArray?.forEach((campaign: any) => {
          campaign.contracts = campaign.contracts.filter((contract: any) => contract.creator);
        });
        contracts.value = contractsArray;
      });
    }

    if (user.value && user.value?.type === "agencyOwner") {
      setTimeout(() => {
        contractManager.loadContracts().then((contractsArrayX) => {
          contractsArrayX?.forEach((campaign: any) => {
            campaign.contracts = campaign.contracts.filter((contract: any) => contract.channel);
          });
          contracts.value = contractsArrayX;
        });
      }, 111);
    }

    // const loadAppliedCampaigns = () => controller.applications.getCreatorAppliedCampaigns(route.params.creatorID as string);
    controller.manager.load(route.params.creatorID as string);
    // loadAppliedCampaigns().then((data) => {
    //   applications.value = data;
    // });
    content.list.loadMyLatest(user.value?.id as string, 6);
    const showPayment = ref(false);
    const paymentInfoChange = async (payload: any) => {
      if (user.value) {
        await gigapayModule.updatePaymentData(user.value?.id, payload);
      }
      showPayment.value = false;
      await controller.manager.load(route.params.creatorID as string);
      gigapayModule.checkVerified().then((data) => {
        gigapayUser.value = data;
      });
    };

    const closeModal = () => {
      closeModalRef.value = true;
    };

    const alredyInvited = ref(false);
    const sendAgain = async () => {
      const resended = await gigapayModule.resendInvitation();
      alredyInvited.value = resended;
    };

    return {
      paymentInfoChange,
      closeModal,
      sendAgain,
      alredyInvited,
      closeModalRef,
      config,
      creator: controller.manager.creator,
      user,
      contracts,
      applications,
      tabview,
      latestContent: content.list.loadMyLatestResult,
      showPayment,
      gigapayUser,
    };
  },
};
