
import { ref, computed } from 'vue';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputMask from 'primevue/inputmask';
import Button from 'primevue/button';

export default {
  name: 'CardPayment',
  components: {
    Dropdown,
    InputMask,
    InputText,
    InputNumber,
    Button,
  },
  props: {
    campaignId: String,
    userId: String,
    newBudgetData: Object,
    firstPayCampaign: {
      type: Boolean,
      default: false,
    },
    termsAgree: Boolean,
  },
  setup() {
    const cvvCode = ref(123);
    const cardNumber = ref('4970105181854329');
    const expMoon = ref(12);
    const expYear = ref(24);
    const cardHolder = ref('Volodymyr GIO');
    const loading = ref(false);

    const paymentMethod = ref([
      { name: 'visa' },
      { name: 'mastercard' },
    ]);
    const photoMethods = {
      /* eslint-disable global-require */
      visa: require('./assets/images/visa.png'),
      mastercard: require('./assets/images/MasterCard.png'),
    };
    const selectedMethod = ref(paymentMethod.value[0]);

    const valCvv = computed(() => /^\d{3}$/.test(cvvCode.value.toString()));
    // eslint-disable-next-line max-len,vue/max-len
    const valCard = computed(() => /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/.test(cardNumber.value.replace(/[\D]+/g, '')));
    const valMoon = computed(() => /^\d{2}$/.test(expMoon.value.toString()));
    const valYear = computed(() => /^\d{2}$/.test(expYear.value.toString()));
    const valHolder = computed(() => /^.{6,}$/.test(cardHolder.value));
    const validatedFields = computed(() => valCvv.value && valCard.value && valMoon.value && valYear.value && valHolder.value);

    const registrationCardData = ref();
    const createCardRegistrationAndPayIn = async () => {
      loading.value = true;

      loading.value = false;
    };

    return {
      loading,
      cvvCode,
      cardNumber,
      paymentMethod,
      selectedMethod,
      photoMethods,
      expMoon,
      expYear,
      cardHolder,
      createCardRegistrationAndPayIn,
      registrationCardData,
      valCvv,
      valCard,
      valMoon,
      valYear,
      valHolder,
      validatedFields,
    };
  },
};
