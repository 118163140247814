
import { defineComponent, onMounted, ref } from 'vue';
import { useAdmins } from '../../modules';
import { applyPureReactInVue } from 'veaury';
import NewCreatorEarnings from '@/react_app/NewAdmin/NewCreatorEarnings/NewCreatorEarning';
import { isNewAdminEnabled } from '@/utils/globals/feature-flags';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Paginator from 'primevue/paginator';

export default defineComponent({
  components: {
    NewCreatorEarnings: applyPureReactInVue(NewCreatorEarnings),
    DataTable,
    Column,
    Paginator,
  },
  setup() {
    const adminModule = useAdmins();

    const limit = ref(10);
    const skip = ref(0);
    const loading = ref(true);

    const getCreatorsEarningList = (skip: number) => {
      loading.value = true;
      adminModule.manager
        .getCreatorsEarnings(limit.value, skip)
        .then(() => (loading.value = false));
    };

    const onPageChange = (event: { first: number }) => {
      skip.value = event.first;
      getCreatorsEarningList(event.first);
    };

    onMounted(() => {
      getCreatorsEarningList(skip.value);
    });

    return {
      onPageChange,
      data: adminModule.manager.getCreatorsEarningsResult,
      limit,
      skip,
      isNewAdminEnabled,loading
    };
  },
});
