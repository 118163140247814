import { AppPagePerspective, Perspective } from '@/data/types/page-types';

import { useCampaignsList } from './campaigns-list-module';
import { useCampaignsCounters } from './campaigns-counters-module';
import { useCampaignInvitations } from './campaign-invitations-module';
import { useCampaignApplications } from './campaign-applications-module';
import { useCampaignContracts } from './campaign-contracts-module';
import { useCampaignDeliverables } from './campaign-deliverables-module';
import { useCampaignManager } from './campaign-manager-module';

export const useCampaigns = (perspective: AppPagePerspective = Perspective.CREATOR) => {
  const manager = useCampaignManager();
  const list = useCampaignsList(perspective);
  const counters = useCampaignsCounters(perspective);
  const invitations = useCampaignInvitations(perspective);
  const applications = useCampaignApplications(perspective);
  const contracts = useCampaignContracts(perspective);
  const deliverables = useCampaignDeliverables(perspective);

  return {
    manager,
    list,
    invitations,
    applications,
    contracts,
    deliverables,
    counters,
  };
};
