<template>
<div style="min-height: 400px" class="p-d-flex p-justify-center p-align-center">
<span class="not-eyt">This payment type is not yet available</span>
</div>
</template>

<script>
export default {
  name: 'CryptoPayment',
};
</script>

<style scoped lang="scss">
.not-eyt {
  color: #162020;
  opacity: 0.5;
  font-weight: 700;
}
</style>
