
/* eslint-disable global-require */
import { ref } from 'vue';
import InputText from 'primevue/inputtext';

export default {
  name: 'inputProductUrls',
  components: {
    InputText,
  },
  props: {
    value: String,
    type: String,
    index: Number,
  },
  setup(props: any) {
    const valueProductNameInput = ref(props.value || '');
    const changedValueInput = ref(props.value || '');
    // eslint-disable-next-line no-nested-ternary
    const parsePlaceholder = ref(props.type === 'Google' ? 'Google Play' : props.type === 'Apple' ? 'Apple Store' : 'Webpage');
    const parseImages = ref({
      Google: require('../assets/images/socialLinks/PLAY.png'),
      Apple: require('../assets/images/socialLinks/apple_store.png'),
      Webpage: require('../assets/images/socialLinks/webpage.png'),
    });

    return {
      parseImages,
      changedValueInput,
      valueProductNameInput,
      parsePlaceholder,
    };
  },
};
