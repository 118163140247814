<template>
  <div :class="[isSmallSidebar ? 'page-menu-small' : 'page-menu']">
    <PageMenuList :isSmallSidebar="isSmallSidebar" :items="items" />
  </div>
</template>

<script>
import PageMenuList from './PageMenuList.vue';

export default {
  props: {
    items: Array,
    isSmallSidebar: Boolean,
  },
  methods: {
    onMenuItemClick(event) {
      this.$emit('menuitem-click', event);
    },
  },
  components: {
    PageMenuList,
  },
};
</script>

<style lang="scss">
.page-menu {
  padding-left: 25rem;
}

.page-menu-small {
  padding-left: 0;
}
</style>
