import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewCreators = _resolveComponent("NewCreators")!
  const _component_OldCreators = _resolveComponent("OldCreators")!

  return (_ctx.isInfluencersPageEnabled)
    ? (_openBlock(), _createBlock(_component_NewCreators, {
        key: 0,
        creators: _ctx.creators,
        goToInfluencerProfile: _ctx.goToInfluencerProfile,
        addToFavorites: _ctx.addToFavorites,
        favorites: _ctx.favorites
      }, null, 8, ["creators", "goToInfluencerProfile", "addToFavorites", "favorites"]))
    : (_openBlock(), _createBlock(_component_OldCreators, {
        key: 1,
        showSearch: _ctx.showSearch,
        limit: _ctx.limit
      }, null, 8, ["showSearch", "limit"]))
}