import { computed, ref, ComputedRef } from 'vue';
import { Perspective } from '@/data/types';

// eslint-disable-next-line no-underscore-dangle
const _perspective = ref<Perspective>(Perspective.CREATOR);

export const MANAGER = Symbol('used for managing the context');

export const useContext = (manager?: symbol) => {
  const perspective = computed<Perspective>(() => _perspective.value);

  const result: { perspective: ComputedRef<Perspective>; setPerspective?: (value: Perspective) => void } = { perspective };

  if (manager && manager === MANAGER) {
    result.setPerspective = (value: Perspective) => {
      _perspective.value = value;
    };
  }

  return result;
};
