<template>
  <div class="empty-list">
    <span class="material-icons no-items-icon">folder_off</span>
    <h2>{{ title }}</h2>
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'No items',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .no-items-icon {
    font-size: 42rem;
  }

  .text {
    font-size: 12rem;
  }
}
</style>
