import { Ref, ref } from 'vue';
import { useAuth } from '@/modules/auth-module';
import { isNewAdminEnabled } from '@/utils/globals/feature-flags';

export interface MenuItem {
  id?: string;
  label?: string;
  icon?: string;
  to?: {
    name: string;
    params?: Record<string, string | undefined>;
  };
  actionIcon?: string;
  badge?: number;
  badgeSeverity?: string;
  opened?: boolean;
  separator?: boolean;
  items?: Array<MenuItem>;
}

const items = ref<Array<MenuItem>>([]);

export const useMenu = () => {
  const updateItem = (id: string, data: MenuItem) => {
    const item = items.value.reduce((result: Array<MenuItem>, current) => {
      result.push(current);
      if (current.items) {
        result.push(...current.items);
      }
      return result;
    }, []).find((i) => i?.id === id);

    if (item) {
      Object.assign(item, data);
    }
  };

  const generateMenu = (): Ref<Array<MenuItem>> => {
    const { user } = useAuth();

    if (!user || !user.value) {
      return items;
    }

    const menu: Array<MenuItem> = [];

    menu.push({
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'dashboard',
      to: { name: 'dashboard' },
    });

    if (user.value.isCreator() || user.value.isAgencyOwner()) {
      menu.push({
        label: 'Campaigns',
        icon: 'list',
        to: { name: 'campaigns' },
      });
    } else if (user.value.isBrandOwner()) {
      menu.push({
        label: 'Creators',
        icon: 'portrait',
        to: { name: 'creators' },
      });
      menu.push({
        label: 'Content Items',
        icon: 'play_circle_outline',
        to: { name: 'content-items' },
      }); // shop_two
      menu.push({
        label: 'My Campaigns',
        icon: 'list',
        // items: campaignSubItems,
        to: { name: 'campaigns-management' },
        opened: true,
      });
    }

    menu.push({ separator: true });

    const contentSubItems = [];
    contentSubItems.push({
      id: 'license-requests',
      label: 'License Requests',
      icon: 'pending_actions',
      to: { name: 'license-requests' },
    });
    if (user.value.isBrandOwner()) {
      // menu.push({
      //   label: 'My Content Items',
      //   icon: 'request_quote',
      //   to: { name: 'licensed-items' },
      //   items: contentSubItems,
      //   opened: true,
      // }); // shop_two
    } else if (user.value.isCreator() || user.value.isAgencyOwner()) {
      menu.push({
        label: 'My Content Items',
        icon: 'shop_two',
        to: { name: 'my-content-items' },
        items: [
          ...contentSubItems,
          {
            label: 'Licensed Items',
            icon: 'request_quote',
            to: { name: 'licensed-items' },
          },
        ],
        opened: true,
      });
      // menu.push({
      //   label: 'My license-requests',
      //   icon: 'shop_two',
      //   to: { name: 'license-requests' },
      //   opened: true,
      // });
      menu.push({
        label: 'My invitations',
        icon: 'list_alt',
        to: { name: 'creator-invitations' },
        opened: true,
      });
      menu.push({
        label: 'System Tutorials',
        icon: 'help',
        to: { name: '' },
      });
      menu.push({
        label: 'Discord Channel',
        icon: 'discord',
        to: { name: '' },
      });
    }

    if (user.value.isBrandOwner()) {
     
      menu.push({
        label: 'Campaigns',
        icon: 'campaign',
        to: {
          name: 'campaigns',
        },
      });
      menu.push({
        label: 'My Brand',
        icon: 'person',
        to: {
          name: 'brand',
          params: { brandID: user.value.brand?.id },
        },
      });
      menu.push({
        label: 'System Tutorials',
        icon: 'help',
        to: { name: '' },
      });
    }

    if (user.value.isAdmin()) {
      menu.push({ separator: true });
      menu.push({
        label: 'Admin',
        icon: 'admin_panel_settings',
        to: { name: 'admin' },
        items: [
          {
            label: 'Invites',
            icon: 'drafts',
            to: { name: 'admin-campaign-invitations' },
          },
        ],
      });
      menu.push({
        label: 'Overview Brands',
        icon: 'folder_open',
        to: { name: 'overview-brands' },
      });
      menu.push({
        label: 'Important Stats',
        icon: 'query_stats',
        to: { name: 'important-stats' },
      });
      if (isNewAdminEnabled) {
        menu.push({
          label: 'Whitelisting',
          icon: 'how_to_reg',
          to: { name: 'white-listing' },
        });
      }
      menu.push({
        label: 'Pending videos',
        icon: 'slow_motion_video',
        to: { name: 'pending-videos' },
      });
      menu.push({
        label: 'Creators Earning',
        icon: 'people_alt',
        to: { name: 'creators-earning' },
      });
      menu.push({
        label: 'Payments',
        icon: 'incomplete_circle',
        to: { name: 'overview-payments' },
      });
      menu.push({
        label: 'Earnings',
        icon: 'article',
        to: { name: 'earnings' },
      });
      // menu.push({
      //   label: 'Pending payments',
      //   icon: 'wallet',
      //   to: { name: 'pending-payments' },
      // });
      menu.push({
        label: 'Detected frauds',
        icon: 'book',
        to: { name: 'detected-frauds' },
      });
    }

    items.value = menu;
    return items;
  };

  generateMenu();

  return {
    items,
    generateMenu,
    updateItem,
  };
};
