<template>
  <div>
    <Dialog
      :visible="isShow"
      class="p-col-12 p-md-6 p-xl-4 gi-dialog"
      :modal="true"
      :style="{ width: '766px' , height : '633px'} "
      @update:visible="$emit('update:isShow', false)"
    >
      <template #header>
        <section class="p-d-flex p-flex-column p-m-auto">
          <div>
            <h1 class="p-mt-6">Congrats You Are a Member</h1>
            <div style="margin-left: 200px"></div>
          </div>
        </section>
      </template>
      <div class="p-d-flex p-flex-column p-p-4 p-ai-center vh-container">
        <h4 class="p-mt-4">You are now part of the Advanced licensing program for creators!</h4>
         <p class="paragraph">If the brand is interested in additional licensing you will recieve a
           request notification on your account outlining their desired terms for the additional license.</p>

          <p class="paragraph"> Depending how big of a additional license they requested plus your average channel size,
           we us our advanced analytics to calculate the appropriate price for the license. </p>

           <p class="paragraph" style="margin-bottom: 25px">the license request notification will give you the option
             to accept or decline the price. </p>
        <Button label="OK"
          class="continue-button p-mt-6"
          @click="modal"/>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { ref } from 'vue';

export default {
  components: {
    Button,
    Dialog,
  },

  props: {
    isShow: Boolean,
  },
  setup(props, ctx, emit) {
    const modal = () => {
      ctx.emit('memberModalClose');
    };
    return { modal };
  },
};
</script>
<style scoped lang="scss">
.success-image {
  width: 60px;
  height: 60px;
}
.paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #686E6E;
}

.continue-button {
  margin-top: 120px;
  border: none;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  width: 198px;
  height: 40.12px;
  background: #E0010B;
  box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}

.agreement {
  font-weight: 600;
  font-size: 12rem;

  a {
    color: $giRed;
    text-decoration: underline;
    font-weight: 700;

    &:hover {
      text-decoration: none;
    }
  }
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight ){
  background-color: #E0010B;
  color:#ffff;
}

div::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #FFFFFF;
}

div::-webkit-scrollbar-thumb{
  background: #686E6E;
  border-radius: 5px;
}

</style>
