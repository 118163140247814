import { AppPagePerspective, Perspective } from '@/data/types/page-types';
import { useCreatorManager } from './creator-manager-module';
import { useCreatorsList } from './creators-list-module';
import { useCreatorYoutube } from './creator-youtube-module';
import { useCreatorApplications } from './creator-applications-module';

export const useCreators = (perspective: AppPagePerspective = Perspective.CREATOR) => {
  const manager = useCreatorManager(perspective);
  const list = useCreatorsList();
  const youtube = useCreatorYoutube(perspective);
  const applications = useCreatorApplications(perspective);

  return {
    manager,
    list,
    youtube,
    applications,
  };
};
