
import { defineComponent } from 'vue';
import Card from 'primevue/card';

export default defineComponent({
  name: 'Auth',
  components: { Card },
  setup() {
    return {};
  },
});
