import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68f1c82c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date" }
const _hoisted_2 = { class: "p-d-flex" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "p-d-flex p-flex-column" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: "main-section p-mb-4",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (!$props.item?.delete ? _ctx.$router.push({ name: 'content-item', params: { itemID: $props.item?.id }}) : null))
  }, [
    _createElementVNode("div", {
      class: "big-img",
      style: _normalizeStyle({ 'background-image': `url(${$props.item?.previewPhoto})` })
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString($setup.formatDate($props.item?.createdAt)), 1),
      ($props.favorites)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "heart",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.addToFavorite($props.item?.id)), ["stop"]))
          }, [
            _createElementVNode("div", {
              class: "heart-image",
              style: _normalizeStyle([$props.isFavorite ? 'background-color: red' : ''])
            }, null, 4)
          ]))
        : _createCommentVNode("", true)
    ], 4),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: $props.item?.owner?.youtube?.photo || $props.item?.owner?.profile?.photo,
        class: "img-class"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString($props.item?.title), 1),
        _createElementVNode("span", _hoisted_6, _toDisplayString($props.item?.owner?.youtube?.name || $props.item?.owner?.name), 1)
      ])
    ])
  ]))
}