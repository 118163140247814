import React, { useEffect, useRef, useState } from "react";
import "./NewEditBrandModal.css";
import NewBrandPhoto from "@/react_app/NewBrand/Profile/NewBrandPhoto/NewBrandPhoto";
import NewImageCropper from "@/react_app/Other/NewImageCropper/NewImageCropper";
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { Image } from "primereact/image";
import { MESSAGE_WRAPPER } from '@/utils/globals/constants';

const AddNew = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="59" height="59" viewBox="0 0 59 59" fill="none">
      <path d="M29.5 0C21.7053 0.0940405 14.2564 3.23226 8.74433 8.74433C3.23226 14.2564 0.0940405 21.7053 0 29.5C0.0940405
       37.2947 3.23226 44.7436 8.74433 50.2557C14.2564 55.7677 21.7053 58.906 29.5 59C37.2947 58.906 44.7436 55.7677 50.2557
       50.2557C55.7677 44.7436 58.906 37.2947 59 29.5C58.906 21.7053 55.7677 14.2564 50.2557 8.74433C44.7436 3.23226 37.2947
       0.0940405 29.5 0ZM46.3571 31.6071H31.6071V46.3571H27.3929V31.6071H12.6429V27.3929H27.3929V12.6429H31.6071V27.3929H46.3571V31.6071Z"
        fill="#9F9EA0" />
    </svg>
  )
}

export default function (props) {
  const {
    updateBrand, brand, onHide, working, visible
  } = props;
  const [brandPhoto, setBrandPhoto] = useState('');
  const [showAddImage, setShowAddImage] = useState(false);
  const [openCropper, setOpenCropper] = useState(false);
  const [customUrl, setCustomUrl] = useState('');
  const [availablePhotos, setAvailablePhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(-1);
  const [errorMessage, setErrorMessage] = useState({ firstName: "", lastName: "", brandName: "" });
  const [userDetails, setUserDetails] = useState({ firstName: "", lastName: "", brandName: "", brandDescription: "" });
  const fileUploadRef = useRef();

  useEffect(() => {
    setUserDetails({
      brandName: brand?.name,
      firstName: brand?.firstName,
      lastName: brand?.lastName,
      brandDescription: brand?.description
    })
    if (brand?.photo) {
      setBrandPhoto(brand.photo);
    }
    setAvailablePhotos([]);
    setShowAddImage(false);
    return () => {
      setErrorMessage({})
    }
  }, [props, brand, onHide]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      addCustomPhoto();
    }
  };

  const addCustomPhoto = () => {
    if (customUrl && availablePhotos.length < 8) {
      setAvailablePhotos([...availablePhotos, customUrl])
      setCustomUrl('');
    }
  };

  const handleFileUpload = ({ files }) => {
    const [uploadPhotoInput] = files;
    if (uploadPhotoInput) {
      const file = uploadPhotoInput;
      const reader = new FileReader();
      reader.onload = () => {
        setAvailablePhotos([...availablePhotos, reader.result])
      };
      reader.readAsDataURL(file);
    }
    fileUploadRef.current.clear();
  };

  const handleChange = (key, value) => {
    setUserDetails((prev) => ({ ...prev, [key]: value }));
    if (key !== 'brandDescription')
      setErrorMessage((prev) => ({ ...prev, [key]: (value) ? "" : MESSAGE_WRAPPER[key] }))
  }
  const validateForm = () => {
    const newErrors = { ...errorMessage };

    if (!userDetails?.brandName) {
      newErrors.brandName = MESSAGE_WRAPPER['brandName'];
    }

    if (!userDetails.firstName) {
      newErrors.firstName = MESSAGE_WRAPPER['firstName'];

    }

    if (!userDetails.lastName) {
      newErrors.lastName = MESSAGE_WRAPPER['lastName'];
    }

    setErrorMessage(newErrors);

    return userDetails.brandName && userDetails.firstName && userDetails.lastName
  }
  const handleSubmit = () => {
    const isValid = validateForm();

    if (isValid) {
      updateBrand({
        photo: brandPhoto, name: userDetails.brandName, description: userDetails.brandDescription,
        firstName: userDetails.firstName, lastName: userDetails.lastName
      }).then(onHide());
    }
  }


  useEffect(() => {
    if (!!userDetails) {
      validateForm()
    }
  }, [userDetails])

  return (
    <Dialog className={'edit-brand-modal'} visible={visible} onHide={onHide}>
      <div className={'top-layer'}>
        <i className="pi pi-times" style={{ fontSize: '25px', color: "#fff", cursor: "pointer" }} onClick={() => {
          const allErrorsClear = Object.values(errorMessage).every(msg => msg === "");
          if (allErrorsClear) {
            onHide();
          }
        }} />
      </div>
      <div className={'content'}>
        <p className={'title'}>Brand Info</p>
        <div className={'content-main'}>
          <div className={'content-subgroup'}>
            <p className={'label'}>Profile Image</p>
            <NewBrandPhoto photo={brandPhoto} redBorder={true} />
            <Button disabled={selectedPhoto === -1} className={'cropButton'} onClick={() => setOpenCropper(true)}>Crop Image</Button>
          </div>
          <div className={'content-subgroup'}>
            <p className={'label'}>Select New Image</p>
            <div className={'photosGrid'}>
              {availablePhotos.map((photo, index) => {
                return (
                  <div className={'addedPhotoWrapper'} key={index} onClick={() => { setSelectedPhoto(index); setBrandPhoto(photo) }}>
                    <Image className={selectedPhoto === index ? 'addedPhotoSelected' : 'addedPhoto'} src={photo} />
                  </div>
                )
              })}
              <div className={'addNew'} onClick={() => setShowAddImage(true)}>
                <AddNew />
              </div>
            </div>
            {(showAddImage && availablePhotos.length < 8) &&
              <div>
                <div className={'urlGroup'}>
                  <InputText value={customUrl} onChange={(e) => setCustomUrl(e.target.value)} className={'inputFieldUrl'} placeholder={'Paste photo URL here'} onKeyDown={handleKeyDown} />
                  <Button className={'addButton'} onClick={addCustomPhoto}>Add</Button>
                </div>
                <div className={'urlGroup'} >
                  <p className={'labelOr'}>Or</p>
                  <FileUpload ref={fileUploadRef} disabled={availablePhotos.length >= 8} mode="basic" name="photo" accept="image/*" auto={true} customUpload={true} chooseLabel="Upload from gallery" uploadHandler={handleFileUpload} />
                </div>
                <Button className={'cancelButton'} onClick={() => setShowAddImage(false)}><i className="pi pi-times" style={{ marginRight: '10px', color: '#E73D38' }} />Cancel</Button>
              </div>
            }
          </div>
          <div>
            <p className={'label'}>Brand Name <span className="required">*</span></p>
            <InputText value={userDetails.brandName} onChange={(e) => handleChange('brandName', e.target.value)} className={'inputField'} placeholder={"Enter your brand's name"} />
            <div className={'registerError'} style={{ minHeight: 20 }}>{errorMessage.brandName && <p>{errorMessage.brandName}</p>}</div>
            <div className="first-last-wrap">
              <div className="input-wrap">
                <p className={'label'}>First Name <span className="required">*</span></p>
                <InputText value={userDetails.firstName} className={'inputField'} onChange={(e) => handleChange('firstName', e.target.value)} />
                <div className={'registerError'} style={{ minHeight: 20 }}>{errorMessage.firstName && <p>{errorMessage.firstName}</p>}</div>
              </div>
              <div className="input-wrap">
                <p className={'label'}>Last Name <span className="required">*</span></p>
                <InputText value={userDetails.lastName} className={'inputField'} onChange={(e) => handleChange('lastName', e.target.value)} />
                <div className={'registerError'} style={{ minHeight: 20 }}>{errorMessage.lastName && <p>{errorMessage.lastName}</p>}</div>
              </div>
            </div>
            <p className={'label'}>Brand Description</p>
            <InputTextarea value={userDetails.brandDescription} onChange={(e) => handleChange('brandDescription', e.target.value)} className={'inputField'} rows={6} maxLength={200} autoResize placeholder={"Enter your brand's description"} />
          </div>
        </div>
        <div className={'buttonLayer'}>
          <Button className={'saveButton'} loading={working} disabled={working} onClick={() => handleSubmit()}>Save Changes</Button>
        </div>
      </div>
      <NewImageCropper visible={openCropper} onHide={() => setOpenCropper(false)} image={brandPhoto} setCroppedPhoto={setBrandPhoto} />
    </Dialog>
  )
}
