import { useAPI, useAuth } from '@/modules';
import { AppPagePerspective, CampaignData, Perspective } from '@/data/types';

const { user } = useAuth();

export const useCampaignsCounters = (perspective: AppPagePerspective) => {
  const { loading, finished, data: counters, execute } = useAPI<Array<CampaignData>>('/campaigns/counters', false);

  const loadCampaignsCounters = (loadOnlyMine = false) => {
    const creatorID = user?.value?.id;
    const brandID = user?.value?.brand?.id;
    const targetID = perspective === Perspective.BRAND ? brandID : creatorID;

    if (perspective === Perspective.BRAND && !brandID) {
      throw new Error('Could not load campaigns for a brand, because brandID is missing!');
    }

    const params = { perspective, targetID };

    if (perspective === Perspective.CREATOR && !loadOnlyMine) {
      delete params.targetID;
    }

    return execute({ params })
      .then((result?: Array<CampaignData>) => counters.value);
  };

  return {
    counters,
    loading,
    finished,
    loadCampaignsCounters,
  };
};
