import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewDetectedFrauds = _resolveComponent("NewDetectedFrauds")!
  const _component_OldDetectedFrauds = _resolveComponent("OldDetectedFrauds")!

  return (_ctx.isNewAdminEnabled)
    ? (_openBlock(), _createBlock(_component_NewDetectedFrauds, {
        key: 0,
        detectedFrauds: _ctx.detectedFrauds,
        dismissFraudAlert: _ctx.dismissFraudAlert,
        getDetectedFraudsWithFilter: _ctx.getDetectedFraudsWithFilter,
        limit: _ctx.limit,
        loading: _ctx.loading,
        changePage: _ctx.pageChange
      }, null, 8, ["detectedFrauds", "dismissFraudAlert", "getDetectedFraudsWithFilter", "limit", "loading", "changePage"]))
    : (_openBlock(), _createBlock(_component_OldDetectedFrauds, { key: 1 }))
}