import React, { useEffect, useState } from 'react';
import './NewMarketModal.css';
import 'primeicons/primeicons.css';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { regionsOptions } from "@/pages/campaigns/create/data.campaign";

export default function(props) {
  const [currentTier, setCurrentTier] = useState(regionsOptions[0].value);
  const [currentCountries, setCurrentCountries] = useState('');
  const [options, setOptions] = useState(regionsOptions);

  useEffect(() => {
    if(props.marketTier) {
      setCurrentTier(props.marketTier);
    }
  }, [props.marketTier]);

  const handleSave = () => {
    props.setMarketTier(currentTier);
    props.setMarketCountries(currentCountries);
    props.close();
  }

  const filterMarkets = (searchValue) => {
    const filteredOptions = regionsOptions.filter(item => {
      const tierMatch = item.value.toLowerCase().includes(searchValue.toLowerCase());
      const countryMatch = item.name.toLowerCase().includes(searchValue.toLowerCase());
      return tierMatch || countryMatch;
    });
    setOptions(filteredOptions);
  };

  return (
    <Dialog className={'market-modal'} visible={props.visible} onHide={props.close}>
      <div className={'topLayer'}>
        <i className="pi pi-times" onClick={props.close} style={{ color: '#FFF', fontSize: '22px', cursor: 'pointer' }}/>
      </div>
      <div className={'content'}>
        <p className={'title'}>Market</p>
        <div>
          <p className={'fieldLabel'}>Search</p>
          <InputText className={'inputField'} placeholder={'Search for region or country'} onChange={(e) => filterMarkets(e.target.value)}/>
        </div>
        <div className={'marketsWrapper'}>
          {options.map((item, index) =>
            <div key={index} className={currentTier === item.value ? 'marketItemSelected' : 'marketItem'} onClick={() => {
              setCurrentTier(item.value); setCurrentCountries(item.name)}}>
              <p className={'tierName'}>{item.value}</p>
              <p className={'tierCountries'}>{item.name}</p>
            </div>)}
        </div>
        <Button className={'selectButton'} onClick={() => handleSave()}>Select Market</Button>
      </div>
    </Dialog>
  )
}
