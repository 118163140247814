
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useCampaigns, useUsers, useContentItems } from '@/modules';
import moment from 'moment';
import CampaignsListCountersNew from '@/pages/campaigns/CampaignsListCountersNew.vue';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ContentItemsList from '../content/ContentItemsList.vue';
import shortCampaignPage from '../creators/shortCampaignPage.vue';
import { getStatisticsByRegion, findCurrentMetricsChannel } from '../../utils/campaign-utils';
import { formatDate } from '../../utils/globals/date-utils';

export default defineComponent({
  components: {
    CampaignsListCountersNew,
    shortCampaignPage,
    ContentItemsList,
    Button,
    DataTable,
    Column,
  },
  setup() {
    const route = useRoute();
    const campaignManager = useCampaigns();
    const usersManager = useUsers();
    const contentItemsManager = useContentItems();

    const currentCampaign = ref();
    const active = ref(0);
    const differenceout = ref();
    const remDays = ref();
    const campaignFinished = ref(false);
    const filteredWorkers = ref();

    campaignManager.manager.loadSingle(route.query.campaignId as string).then((data: any) => {
      currentCampaign.value = data;
      data?.contracts?.filter((contract: any) => contract?.channel);

      const startDate = moment(data?.dates?.startDate);
      const endDate = moment(data?.dates?.endDate);
      const current = moment().startOf('day');
      const diff1 = moment.duration(current.diff(startDate)).asDays();
      const diff = moment.duration(endDate.diff(startDate)).asDays();
      const remainingDays = moment.duration(endDate.diff(current)).asDays();
      remDays.value = remainingDays;
      let difference = 0;
      if (startDate.isAfter(current)) {
        difference = 0;
      } else if (endDate.isBefore(current)) {
        difference = 100;
        campaignFinished.value = true;
      } else {
        difference = ((diff1 / diff) * 100);
      }
      differenceout.value = difference;
      if (!data?.dates?.endDate) {
        campaignFinished.value = true;
        difference = 100;
        differenceout.value = difference;
      }
    });

    usersManager.manager.loadMyProfile().then((currentAgency: any) => {
      filteredWorkers.value = currentAgency?.agencyWorkers.filter((worker: any) => worker
          ?.campaignContracts.includes(route.query.campaignId));
    });

    const currentCampaignContent = ref<Array<any>>([]);
    contentItemsManager.list.getCampaignContent(route.query.campaignId as string).then((contentItems: any) => {
      contentItems?.forEach((dataContent: any) => {
        if (dataContent?.channelId) {
          filteredWorkers.value.forEach((worker: any) => {
            if (worker?.channel?.channelId === dataContent?.channelId) {
              currentCampaignContent.value.push(dataContent);
            }
          });
        }
      });
    });

    const copyLink = (currentUrl: string) => {
      navigator.clipboard.writeText(currentUrl);
    };

    return {
      active,
      currentCampaign,
      differenceout,
      campaignFinished,
      remDays,
      filteredWorkers,
      currentCampaignContent,
      campaign: campaignManager.manager.singleCampaign,
      getStatisticsByRegion,
      formatDate,
      copyLink,
      findCurrentMetricsChannel,
    };
  },
});
