
import { useAuth, useFavorites } from '@/modules';
import { formatDate } from '../../../utils/globals/date-utils';

export default {
  name: 'LatestContentItem',
  props: {
    item: Object,
    favorites: {
      type: Boolean,
      default: false,
    },
    isFavorite: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const user = useAuth();
    const favoritesManager = useFavorites();
    const addToFavorite = async (itemID: string) => {
      if (user.user.value) {
        const item = await favoritesManager.likeOrDislike('contents', user.user.value?.id, itemID);
        ctx.emit('favoriteChange', { item, itemID });
      }
    };
    return { formatDate, addToFavorite };
  },
};
