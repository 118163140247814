import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewCreateCampaign = _resolveComponent("NewCreateCampaign")!
  const _component_OldCreateCampaign = _resolveComponent("OldCreateCampaign")!

  return (_ctx.isNewCreateCampaignEnabled)
    ? (_openBlock(), _createBlock(_component_NewCreateCampaign, {
        key: 0,
        handleReturn: _ctx.handleReturn,
        pushDraft: _ctx.pushDraft,
        campaign: _ctx.campaign,
        saveEdit: _ctx.saveEdit,
        isCampaignManagement: false
      }, null, 8, ["handleReturn", "pushDraft", "campaign", "saveEdit"]))
    : (_openBlock(), _createBlock(_component_OldCreateCampaign, {
        key: 1,
        campaignID: _ctx.campaignID,
        campaign: _ctx.campaign
      }, null, 8, ["campaignID", "campaign"]))
}