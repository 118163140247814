
import {
  defineComponent, ref, reactive, watch, PropType
} from 'vue';
import {
  AgencyApplicationRef, ApplicationRef, CampaignApplicationStatus, CampaignPaymentMethod, UserType ,

} from '@/data/types';
import { CampaignApplicationStatusValues } from '@/data/enums/campaign-application';
import { useAuth, useCampaigns } from '@/modules';
import { calculateCpiRate } from '../../utils/globals/cpi-rate';
import { parseCampaignEndTimeLeft } from '../../utils/globals/date-utils';
import AskHint from './utils/AskHint.vue';
import StatusChip from '../commons/status/StatusChip.vue';
import UploadedFileViews from '@/pages/campaigns/UoloadedFileaViews.vue';
import inputTalkingPoint from '../campaigns/create/inputTalkingPoint.vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';


interface HideInfoPropObject {
  applicationStatus?: CampaignApplicationStatus;
  isCampaignCPM: boolean;
}

export default defineComponent({
  components: {
    AskHint,
    inputTalkingPoint,
    UploadedFileViews,
    StatusChip,
    InputText,
    Calendar,
    Button,
  },
  props: {
    shortPreview: {
      default: true,
    },
    campaignInfo: {
    type: Object,
    required: true,
  },
  applications: {
    type: [Object, Array],
    default: () => [],
  },
    hideInfo: Object as PropType<HideInfoPropObject>,
  },
  emits: ['updateAtt', 'updateCampaign'],
  setup(props, ctx) {
    const { user } = useAuth();
    const campaignModule = useCampaigns();
    const openBasic = ref(false);
    const productName = ref(props?.campaignInfo?.productName);
    const callToAction = ref(props?.campaignInfo?.callToAction);
    const talkingPoints = ref(props.campaignInfo?.talkingPoints);
    const startDate = reactive(props?.campaignInfo?.dates?.startDate);
    const endDateCampaign = ref(props?.campaignInfo?.dates?.endDate);
    const enD = new Date();
    enD.setDate(enD.getDate() + 30);

    const editOptions = reactive({
      productName: false,
      callToAction: false,
      talkingPoints: false,
      dates: false,
    });
    watch(() => props?.campaignInfo, () => {
      productName.value = props?.campaignInfo?.productName;
      callToAction.value = props?.campaignInfo?.callToAction;
      talkingPoints.value = props.campaignInfo?.talkingPoints;
    });

    const campaignTypeIcons = {
      Other: require('@/pages/creators/assets/images/FPS-Gaming.png'),
      Console: require('@/pages/creators/assets/images/Mobile-Gaming.png'),
      MobileRPG: require('@/pages/creators/assets/images/Mobile-Gaming.png'),
      PC: require('@/pages/creators/assets/images/PC-Gaming.png'),
    };
    const linksIcons = {
      Apple: require('@/pages/creators/assets/images/links-images/apple_store.png'),
      Google: require('@/pages/creators/assets/images/links-images/PLAY.png'),
      Webpage: require('@/pages/creators/assets/images/links-images/just-link.png'),
    };
    const parseTitle = {
      Apple: 'Apple App store',
      Google: 'Google Play',
      Webpage: 'Product website',
    };
    const openLink = function (link: string) {
      const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
      if (newWindow) {
        newWindow.opener = null;
      }
    };

    const updateAtt = () => {
      ctx.emit('updateAtt');
    };

    const changedTalkingInput = (index: number, value: string) => {
      talkingPoints.value[index] = value;
    };

    const addTalkingPoint = () => {
      talkingPoints.value.push('');
    };

    const getPayoutCapDisplayValue = (capValue: number, appliedFee: number, currency: string, applyCommission: boolean) => {
      if (applyCommission) {
        return `${calculateCpiRate(capValue, appliedFee)} ${currency}`;
      }

      return `${capValue} ${currency}`;
    };

    const getPayoutCap = (applications: ApplicationRef | AgencyApplicationRef[], standardCap: number, currency: string, appliedFee: number) => {
      if (Array.isArray(applications) && ((applications as AgencyApplicationRef[]).length > 0)) {
        let clientMessage = "";
        applications = applications as AgencyApplicationRef[];

        applications.forEach((currentApplication: AgencyApplicationRef) => {
          if (currentApplication?.capProposals && currentApplication?.capProposals?.length > 0) {
            const acceptedProposal = currentApplication.capProposals[0]; //only one proposal is possible. It is an array with an idea of scale in mind if requirements change

            if (acceptedProposal?.clientDecision === "approved") {
              clientMessage +=
                `${acceptedProposal.channelTitle}: ${getPayoutCapDisplayValue(acceptedProposal.proposedPayoutCap, appliedFee, currency, false)} \n`;
            }
          }
        });

        if (clientMessage) {
          return clientMessage;
        }

        return `${getPayoutCapDisplayValue(standardCap, appliedFee, currency, true)}`;
      }

      if (applications) {
        applications = applications as ApplicationRef;

        if (applications?.capProposals && applications?.capProposals?.length > 0) {
          if (applications.capProposals[0]?.clientDecision !== "approved") {
            return `${getPayoutCapDisplayValue(standardCap, appliedFee, currency, true)}`;
          }


          return `${getPayoutCapDisplayValue(applications.capProposals[0].proposedPayoutCap, appliedFee, currency, false)}`;
        }

        return `${getPayoutCapDisplayValue(standardCap, appliedFee, currency, true)}`;
      }

      return `${getPayoutCapDisplayValue(standardCap, appliedFee, currency, true)}`;
    };

    const updateCampaignData = async (data: {
      talkingPoints?: Array<any>, callToAction?: string, productName?: string,
      dates: { startDate: Date, endDate: Date | undefined }
    }) => {
      if (data?.talkingPoints?.length) {
        data.talkingPoints = data.talkingPoints.filter((e) => e?.length);
        talkingPoints.value = data.talkingPoints.filter((e) => e?.length);
      }
      await campaignModule.manager.update(props.campaignInfo?.id || props.campaignInfo?._id, data);
      ctx.emit('updateCampaign');
    };

    const displayModeratedContent = (isCampaignCPM: boolean, applicationStatus?: CampaignApplicationStatusValues) => {
      if (!isCampaignCPM) {
        return true;
      }

      if (user?.value?.type === UserType.BrandOwner || user?.value?.type === UserType.Admin) {
        return true;
      }

      if (applicationStatus && applicationStatus !== CampaignApplicationStatusValues.ACCEPTED) {
        return false;
      }

      return true;
    };

    return {
      displayModeratedContent,
      updateAtt,
      openLink,
      parseCampaignEndTimeLeft,
      calculateCpiRate,
      changedTalkingInput,
      addTalkingPoint,
      updateCampaignData,
      enD,
      endDateCampaign,
      startDate,
      parseTitle,
      campaignTypeIcons,
      linksIcons,
      CampaignPaymentMethod,
      editOptions,
      callToAction,
      talkingPoints,
      productName,
      user,
      openBasic,
      CampaignApplicationStatusValues,
      getPayoutCap,
    };
  },
});
