
import Chip from 'primevue/chip';

import { defineComponent } from 'vue';

export default defineComponent({
  components: { Chip },
  props: {
    text: String,
    status: {
      type: String,
      required: true,
    },
  },
});
