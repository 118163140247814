<template>
<section class="gamification-block">
  <img src="../../../assets/images/gamification/gamification-icon.png" />
</section>
</template>

<script>
export default {
  name: 'Gamification',
};
</script>

<style scoped lang="scss">
.gamification-block {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 40px;
  cursor: pointer;
}
.gamification-block::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  border-radius: 50%;
  height: 10px;
  background: #E0010B;
}
</style>
