import { useAPI, useAuth } from '@/modules';
import { User, UserData } from '@/data/types';

const { user } = useAuth();

export interface SearchParams {
  limit?: number;
  name?: string;
}

export const useCreatorsList = () => {
  const {
    loading,
    finished,
    data: creators,
    execute: sendLoadCreatorsRequest,
  } = useAPI<Array<User>>('/users/creators', {
    parseResponse: (items: Array<UserData>) => items.map((data) => new User(data, user?.value as User)),
  }, false);

  const load = (search?: SearchParams) => sendLoadCreatorsRequest({ params: search })
    .then((result?: Array<User>) => {
      if (result) {
        creators.value = result;
      }
      return creators.value;
    });

  const search = (termKey: string, limit: number) => ({ term }: { term: string }) => {
    load({
      [termKey]: term,
      limit,
    });
  };

  const {
    data: sendLastCampaignsLoadRequestCreators,
    execute: sendLastCampaignsLoadRequest,
  } = useAPI<Array<User>>('', {
  }, false);

  const lastCampaignsLoad = (limit: number, searchValue?: string) => sendLastCampaignsLoadRequest({
    url: `/creator/last/campaigns/${limit}`,
    params: { searchValue },
    method: 'GET',
  });

  const {
    data: lastInfluencesLoadData,
    execute: sendLastInfluencersLoadRequest,
  } = useAPI<Array<User>>('', {
  }, false);

  const lastInfluencersLoad = (limit: number, searchValue?: string) => sendLastInfluencersLoadRequest({
    url: `/creator/last/influences/${limit}`,
    params: { searchValue },
    method: 'GET',
  });

  return {
    creators,
    loading,
    finished,
    load,
    search,
    // last campaigns active && last
    lastCampaignsLoad,
    sendLastCampaignsLoadRequestCreators,
    // last influencers
    lastInfluencersLoad,
    lastInfluencesLoadData,
  };
};
