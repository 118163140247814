import React, { useEffect, useState } from 'react';
import './NewMyCampaigns.css'
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { CampaignStatus } from "@/data/types";
import { formatDate } from '@/utils/globals/date-utils';
import { compactNumber } from '@/utils/globals/curency-utils';
import {
  BackIcon,
  PlayIconGrey,
  PlayIconRed, VideosIconGrey, VideosIconRed
} from "@/react_app/img/Icons";

const campaignManagementTabs = [
  { value: 'All', type: 'all' },
  { value: 'Active', type: 'active' },
  { value: 'Completed', type: 'completed' },
];

const sideMenuItems = [
  { activeIcon: <VideosIconRed />, normalIcon: <VideosIconGrey />, title: 'My Videos', route: 'my-content-items' },
  { activeIcon: <PlayIconRed />, normalIcon: <PlayIconGrey />, title: 'Campaigns', route: 'my-campaigns' },
];

const campaignBodyTemplate = (rowData) => {
  return (
    <div className={'campaignTemplate'}>
      <Image src={rowData.photo} alt={'campaign cover'} className={'campaignTemplateImage'} />
      <p className={'campaignTemplateTitle'}>{rowData.name}</p>
    </div>
  );
};

const statusBodyTemplate = (rowData) => {
  return (
    <div className={'statusTemplate'}>
      {rowData.status === CampaignStatus.Active &&
        <div className={'statusTemplateBox statusActive'} >
          Active
        </div>
      }
      {rowData.status === CampaignStatus.Draft &&
        <div className={'statusTemplateBox statusDraft'} >
          Draft
        </div>
      }
      {rowData.status === CampaignStatus.Completed &&
        <div className={'statusTemplateBox statusCompleted'} >
          Completed
        </div>
      }
      {rowData.status === 'pending' &&
        <div className={'statusTemplateBox statusDraft'} >
          Pending
        </div>
      }
    </div>
  );
};

const periodBodyTemplate = (rowData) => {
  return (
    <div className={'periodTemplate'}>
      {formatDate(rowData.dates.startDate)}{' '}-{' '}
      {rowData.dates.endDate ? formatDate(rowData.dates.endDate) : 'Until the end of budget'}
    </div>
  );
};

const brandTemplate = (rowData) => {
  return (
    <div className={'brandTemplate'}>
      <Image src={rowData.brand.photo} alt={'brand photo'} className={'brandTemplatePhoto'} />
      <p className={'brandTemplateName'}>{rowData.brand.name}</p>
    </div>
  );
};

const earningsBodyTemplate = (rowData) => {
  return (
    <div className={'earningsTemplate'}>
      {rowData.budget.currency}{' '}{compactNumber(0)}
    </div>
  );
};

export default function (props) {
  const {
 selectStatusCategory, searchValueChange, goBack, filteredCampaigns, routeTo 
} = props;
  const [activeTab, setActiveTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    selectStatusCategory(campaignManagementTabs[activeTab].type);
    setSearchValue('');
  }, [activeTab]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    searchValueChange(e.target.value);
  }

  return (
    <div className={'my-campaigns'}>
      <div className={'sideMenu'}>
        <div className={'goBack'} onClick={() => goBack()}>
          <BackIcon />
          Back
        </div>
        <div className={'sideMenuItemsWrapper'}>
          {sideMenuItems.map((item, index) =>
            <div key={index} className={item === sideMenuItems[1] ? 'sideMenuItemSelected' : 'sideMenuItem'} onClick={() => routeTo(item.route)}>
              {item.activeIcon}
              <p className={'sideMenuItemTitle'}>{item.title}</p>
            </div>)}
        </div>
      </div>
      <div className={'mainContent'}>
        <p className={'pageTitle'}>Campaigns</p>
        <div>
          <div className={'tabsWrapper'}>
            <div className={'tabs'}>
              {campaignManagementTabs.map((item, index) =>
                <div key={index} className={index === activeTab ? 'tabSelected' : 'tabNormal'} onClick={() => setActiveTab(index)}>
                  {item.value}
                </div>)}
            </div>
          </div>
          <div className={'campaignsSearchBarWrapper'}>
            <i className="pi pi-search searchIcon" />
            <InputText className={'campaignsSearchBar'} placeholder={'Search for campaign'} value={searchValue} onChange={(e) => handleSearch(e)} />
          </div>
        </div>
        <DataTable value={filteredCampaigns} removableSort emptyMessage={"No campaigns found."}>
          <Column field={"name"} header={"Campaign"} body={campaignBodyTemplate} sortable style={{ width: '28%' }}></Column>
          <Column field={"status"} header={"Status"} body={statusBodyTemplate} sortable style={{ width: '14%' }}></Column>
          <Column field={"dates.startDate"} header={"Campaign Period"} body={periodBodyTemplate} style={{ width: '22%' }}></Column>
          <Column field={"brand.name"} header={"Brand"} body={brandTemplate} sortable style={{ width: '18%' }}></Column>
          <Column field={""} header={"Earnings generated"} body={earningsBodyTemplate} sortable style={{ width: '18%' }}></Column>
        </DataTable>
      </div>
    </div>
  )
}
