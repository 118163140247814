
import { reactive, ref, watch } from 'vue';
import InputText from 'primevue/inputtext';
import inputTalkingPoint from './inputTalkingPoint.vue';
import UploadInput from '../UploadInput.vue';

export default {
  name: 'createCampaignProductSteep',
  components: {
    InputText,
    inputTalkingPoint,
    UploadInput,
  },
  props: {
    attachments: Array,
    campaign: Object,
  },
  setup(props: any, ctx: any) {
    const talkingPoints = reactive(props.campaign?.talkingPoints || JSON.parse(localStorage.getItem('talkingPoints') as string) || ['']);
    const valueCallToActionInput = ref(props.campaign?.callToAction || localStorage.getItem('valueCallToActionInput') || '');
    const attachment: any = reactive(props.campaign?.attachment || JSON.parse(localStorage.getItem('attachment') as string) || []);
    watch([talkingPoints, valueCallToActionInput, attachment], () => {
      localStorage.setItem('talkingPoints', JSON.stringify(talkingPoints));
      localStorage.setItem('valueCallToActionInput', valueCallToActionInput.value);
      localStorage.setItem('attachment', JSON.stringify(attachment));
    });
    const addTalkingPoint = () => {
      talkingPoints.push('');
    };
    const changedTalkingInput = (index: number, value: string) => {
      talkingPoints[index] = value;
      ctx.emit('changeInstruction', valueCallToActionInput.value, talkingPoints);
    };
    const onAddedFile = (data: any) => {
      ctx.emit('changeInstruction', valueCallToActionInput.value, talkingPoints, data);
    };

    return {
      onAddedFile,
      valueCallToActionInput,
      talkingPoints,
      addTalkingPoint,
      changedTalkingInput,
    };
  },
};
