
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Chip from 'primevue/chip';
import Calendar from 'primevue/calendar';
import { ref } from 'vue';

export default {
  name: 'SearchBar',
  components: {
    InputText,
    Button,
    Chip,
    Calendar,
  },
  props: {
    optionsName: String,
    options: Array,
    placeholder: String,
    range: Boolean,
  },
  setup(props: any, ctx: any) {
    const searchValue = ref('');
    const selectedAll = ref(false);
    const startDate = ref('');
    const endDate = ref('');

    const clearFilters = () => {
      searchValue.value = '';
      ctx.emit('clearFilters');
    };
    const selectAllPlatforms = () => {
      ctx.emit('selectAll', selectedAll.value);
      selectedAll.value = !selectedAll.value;
    };
    const sendName = () => {
      ctx.emit('sendName');
    };
    const addPlatform = (platform: any) => {
      selectedAll.value = false;
      ctx.emit('addPlatform', platform);
    };
    return {
      searchValue, selectedAll, clearFilters, selectAllPlatforms, addPlatform, startDate, endDate, sendName,
    };
  },
};
