
export default {
  emits: ['addInfo', 'changeInfo', 'sendAgain'],
  props: {
    validated: {
      type: Boolean,
      required: true,
    },
    isCreator: {
      type: Boolean,
      default: false,
    },
    alredyInvited: String,
    gigapayUser: Object,
  },
};
