import React from 'react';
import "./NewContentListItem.css";
import { formatDate } from '@/utils/globals/date-utils';
import { Image } from "primereact/image";

export default function (props) {
  const { item, handleRouting, creator } = props;
  return (
    <div className={'content-list-item'} onClick={handleRouting}>
      <Image src={item?.thumbnails?.medium?.url} alt={'content thumbnail'} className={'contentThumbnail'} />
      <p className={'contentTitle'}>{item?.title}</p>
      <div className={'creator'}>
        <Image src={item?.owner?.profile?.photo ? item?.owner?.profile?.photo : creator?.profile?.photo} alt={'creator photo'} className={'creatorPhoto'} />
        <div className={'creatorAndCampaignInfo'}>
          <p className={'creatorName'}>{item?.owner?.name ? item?.owner?.name : creator?.name}</p>
          <p className={'campaign'}>{item?.campaignId?.name || '-'}</p>
        </div>
      </div>
      <div className={'date'}>
        {formatDate(item?.publishedAt ?? item?.createdAt)}
      </div>
    </div>
  )
}
