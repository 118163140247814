import { io } from 'socket.io-client';
import { ref } from 'vue';
// https://d6mcm5jrhhyg7.cloudfront.net
export function useSocketIo(userId?: string) {
  // delete /v1 here
  return io(
    process.env.VUE_APP_API_URL
      ? process.env.VUE_APP_API_URL.slice(0, -3)
      : 'http://localhost:3000',
    {
      transports: ['websocket', 'polling'],
      query: {
        userId,
      },
    }
  );
}

export function useSocketMethods(socket: any) {
  const getNotificationsUser = (limit: number, skip: number) => {
    socket.emit('get-notifications', limit, skip);
  };
  const getLatestNotifications = (limit: number, skip: number) => {
    socket.emit('get-notifications-latest', limit, skip);
  };

  const changeNotifyStatusRead = async (notifyId: string) => {
    socket.emit('notify-read', notifyId);
  };

  const howManyNewOnes = async () => {
    socket.emit('how-many-new-ones');
  };
  const getNewNotificationsCount = async () => {
    const newCountNotify = ref();
    socket.on('new-notifications-count', (newCount: number) => {
      newCountNotify.value = newCount;
    });
    return newCountNotify;
  };

  const addNewNotification = async (newNotify: any) => {
    socket.emit('add-new-notifications', newNotify);
  };

  const deleteNotification = async (query: any) => {
    socket.emit('delete-new-notifications', query);
  };

  const searchNotifications = async (value: string, byPeriod?: string) => {
    socket.emit('search-notification-by-message', value, byPeriod);
  };

  const getAllNotification = async (limit: number, skip: number,value: string) => {
    socket.emit('get-notifications-all', limit, skip,value);
  };
  return {
    getNotificationsUser,
    changeNotifyStatusRead,
    howManyNewOnes,
    getNewNotificationsCount,
    addNewNotification,
    deleteNotification,
    searchNotifications,
    getLatestNotifications,
    getAllNotification
  };
}
