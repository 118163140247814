import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc4b296e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "campaign-list-counter p-d-flex" }
const _hoisted_2 = { style: {"margin-left":"18vw"} }
const _hoisted_3 = { class: "counter" }
const _hoisted_4 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ask_hint = _resolveComponent("ask-hint")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ask_hint, { class: "hint" })
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.counter), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.name), 1)
  ]))
}