
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

export default {
  components: {
    Dialog,
    Button,
  },
  props: {
    showModal: Boolean,
    campaignId: String,
    campaign: Object,
  },
  setup() {

    return {};
  }
}
