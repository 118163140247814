<template>
 <section class="section-tip">
   <div class="secret-tip-images">
       <img class="img-1" style="width: 24px; height: 24px;" src="../assets/images/tip/big.svg">
       <img class="img-2" style="width: 11px; height: 11px;" src="../assets/images/tip/small.svg">
       <img class="img-3" style="width: 11px; height: 11px;" src="../assets/images/tip/small.svg">
       <img class="img-4" style="width: 24px; height: 11px;" src="../assets/images/tip/width.svg">
       <img class="img-5" style="width: 24px; height: 24px;" src="../assets/images/tip/big.svg">
       <img class="img-6" style="width: 11px; height: 25px;" src="../assets/images/tip/height.svg">
       <img class="img-7" style="width: 11px; height: 25px;" src="../assets/images/tip/height.svg">
       <img class="img-8" style="width: 11px; height: 25px;" src="../assets/images/tip/light.svg">
    </div>
   <div class="p-d-flex p-flex-column p-ml-md-4 p-ml-xl-0">
     <span class="title-tip">SECRET TIP</span>
     <span class="text-tip">{{ text }}</span>
   </div>
 </section>
</template>

<script>
export default {
  name: 'SecretTip',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.section-tip {
  min-height: 50px;
  margin: 25px 0 100px 0;
  display: flex;
  .title-tip {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #8B8F8F;
  }
  .text-tip {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #686E6E;
    margin-top: 5px;
    max-width: 550px;
  }
}
.secret-tip-images {
  position: relative;
  width: 90px;
  height: 55px;
}
.img-1 {
  position: absolute;
  top: 0;
  left: 0;
}
.img-2 {
  position: absolute;
  top: 27px;
  left: 0;
}
.img-3 {
  position: absolute;
  top: 27px;
  left: 13px;
}
.img-4 {
  position: absolute;
  top: 41px;
  left: 0;
}
.img-5 {
  position: absolute;
  top: 0;
  left: 27px;
}
.img-6 {
  position: absolute;
  top: 27px;
  left: 27px;
}
.img-7 {
  position: absolute;
  top: 27px;
  left: 41px;
}
.img-8 {
  position: absolute;
  top: -1px;
  left: 34px;
}
@media only screen and (max-width: 540px) {
  .title-tip {
    margin-left: 15px;
  }
  .text-tip {
    margin-left: 15px;
  }
}
@media only screen and (max-width: 414px) {
  .title-tip {
    margin-left: 27px;
    max-width: 100%;
  }
  .text-tip {
    margin-left: 27px;
    max-width: 100%;
  }
}
</style>
