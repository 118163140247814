import React from 'react';
import "./NewInfluencerItem.css";
import { Image } from "primereact/image";
import { compactNumber } from "@/utils/globals/curency-utils";
import youtube from "@/react_app/img/youtube.png";
import 'primeicons/primeicons.css';

export default function (props) {

  const calculateFollowers = (followers) => {     // TODO: Move to backend and think about performance.
    const initialValue = 0;
    
    return followers.reduce(
      (accumulator, currentValue) =>
        accumulator + (currentValue.followers || 0),
      initialValue
    );
};

  return (
    <div className={'latest-influencer'}>
      <div className={'profilePhotoWrapper'} onClick={() => props.goToProfile(props.influencer?.id)}>
        <Image src={props.influencer?.profile.photo} alt={'profile photo'} className={'profilePhoto'}/>
      </div>
      <div className={'influencerInfo'}>
        <p className={'username'} onClick={() => props.goToProfile(props.influencer?.id)}>{props.influencer?.name}</p>
        <p className={'followerCount'}>{compactNumber(calculateFollowers(props.influencer?.youtube))} Subscribers</p>
        <div className={'icons'}>
          <Image src={youtube} alt={'icon'} className={'icon'} title={'youtube'}/>
        </div>
      </div>
      <div className={'favorites'} onClick={() => props.addToFavorites(props.influencer?.id)}>
        {props.favorites?.allUserFavorites.find((item) => item.influences?.id === props.influencer?.id) ?
          <i className="pi pi-star-fill starIcon"/> :
          <i className="pi pi-star starIcon"/>
        }
      </div>
    </div>
  )
}
