
import { defineComponent, PropType } from 'vue';
import ProgressSpinner from 'primevue/progressspinner';
import PageSearchBar from '@/pages/commons/search/PageSearchBar.vue';
import CampaignBrandHeader from '@/pages/campaigns/CampaignBrandHeader.vue';
import { AppPageConfig } from '../../data/types';

export default defineComponent({
  name: 'AppPage',

  components: { PageSearchBar, ProgressSpinner, CampaignBrandHeader },
  props: {
    config: Object as PropType<AppPageConfig>,
    title: String,
    loading: Boolean,
    padded: Boolean,
    brand: Object,
    type: String,
    publishWithMenu: Boolean,
  },

  emits: ['search', 'publishCampaign'],
});
