
import { ref } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useContentItemsRequests } from '@/modules/content-items/content-items-requests-module';
import { useAuth } from '@/modules';
import { Perspective } from '@/data/types';
import SearchBar from '../utils/SearchBar.vue';
import { formatDate } from '../../../utils/globals/date-utils';

export default {
  name: 'Campaigns',
  components: {
    SearchBar,
    DataTable,
    Column,
  },
  props: {
    latestContracts: Array, // TODO remove production
  },
  setup() {
    const { user } = useAuth();
    const useContentItemsRequestsModule = useContentItemsRequests(user.value?.isAgencyOwner() ? Perspective.AGENCY : Perspective.CREATOR);
    const searchValue = ref('');
    const clearFilters = () => {
      searchValue.value = '';
    };

    useContentItemsRequestsModule.loadRequests();

    const searchValueChange = (newValue: string) => {
      searchValue.value = newValue;
    };
    return {
      clearFilters, formatDate, searchValueChange, searchValue, content: useContentItemsRequestsModule.requests,
    };
  },
};
