<template>
  <div :class="isCreator ? 'creator p-col-11' : ''">
    <h4>
      <img class="success-image" src="../../campaigns/assets/images/danger-icon.svg">You need to accept the Licensed content agreement</h4>
    <div class="p-d-flex" :class="isCreator ? 'p-justify-between btn-creator' : ''">
      <p class="info" :class="!isCreator ? 'info2' : ''">Please review and accept to begin earning from licensed content.</p>
      <div class="add-info" @click="showModal = !showModal">ACCEPT</div>
    </div>
    <TermsModal @closeModal="closeModal" :show="showModal" />
  </div>
</template>

<script>
import { ref } from 'vue';
import TermsModal from '../TermsModal.vue';

export default {
  components: {
    TermsModal,
  },
  name: 'AcceptContentTerms.vue',
  props: {
    isCreator: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const showModal = ref(false);
    const closeModal = () => {
      showModal.value = false;
      ctx.emit('closeModal');
    };

    return {
      showModal,
      closeModal,
    };
  },
};
</script>

<style scoped lang="scss">
.creator {
  max-width: 90%;
  margin-top: 50px;
  padding-left: 24px;
  padding-bottom: 24px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 15px;
}

.btn-creator {
  margin-right: 32px;
}

.success-image {
  height: 22px;
  width: 22px;
  margin-right: 20px;
  margin-bottom: -5px;
  margin-top: 30px;
}

.info {
  margin-left: 40px;
}
.info2 {
  width: 35%;
}

.add-info {
  color: #E0010B;
  margin-left: 50px;
  background-color: #FFFFFF;
  border: none;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

.change-info {
  font-weight: 800;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #162020;
  margin-left: 30px;
  cursor: pointer;
}
</style>
