<template>
  <div>
    <Dialog
      :visible="isShowApplication"
      class="p-col-12 p-md-6 p-xl-4 gi-dialog"
      :modal="true"
      :style="{ width: '550px' , height : '600px'} "
      @update:visible="$emit('update:isShowApplication', false)"
    >
      <template #header>
        <section class="p-d-flex p-flex-column p-p-6 card">
          <div>
            <h1 class="apc-success">Request Success</h1>
              <img class="apc-success-svg" src="../assets/images/application-success.svg">
            <div class="apc-sent">Your request was sent successfully!</div>
            <div>
              <p class="apc-instruction"> We’ll notify the influencers who worked on the content and send them a message.</p>
             <button @click="closeModal()"
                     class="continue-button">OK</button>
            </div>
          </div>
        </section>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import { ref } from 'vue';

export default {
  components: {
    Dialog,
  },
  props: {
    isShowApplication: Boolean,
    working: Boolean,
  },
  emits: ['update:isShowApplication'],
  setup(props, ctx) {
    const showApply = ref(props.isShowApplication);
    const closeModal = () => {
      showApply.value = false;
      ctx.emit('closeApcModal');
    };
    return { showApply, closeModal };
  },
};
</script>
<style scoped lang="scss">

.apc-success-svg{
  margin-left: 150px
}

.apc-instruction{
  text-align: center;
  line-height: 17px;
  font-size:14px;
  margin-top: 70px;
}

.apc-sent{
  font-weight: 700 ;
  font-size: 16px;
  line-height: 19px;
  margin-top: 50px;
  color: #253340;
  text-align: center
}

.apc-success {
  text-align: center;
  margin-bottom: 70px;
}

.cpi-text {
  color: #253340;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.continue-button {
  border: none;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  width: 198px;
  height: 40.12px;
  background: #E0010B;
  box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  margin-top: 70px;
  margin-left: 100px
}

.agreement {
  font-weight: 600;
  font-size: 12rem;

  a {
    color: $giRed;
    text-decoration: underline;
    font-weight: 700;

    &:hover {
      text-decoration: none;
    }
  }
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight ){
  background-color: #E0010B;
  color:#ffff;
}

</style>
