import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51e75148"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-d-flex p-flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_items_list_item = _resolveComponent("content-items-list-item")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back())),
      class: "back"
    }, "GO BACK"),
    (!_ctx.campaignContent?.campaignContent.length)
      ? (_openBlock(), _createElementBlock("h6", _hoisted_1, "NO DATA"))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.campaignContent?.campaignContent, (item, index) => {
        return (_openBlock(), _createBlock(_component_content_items_list_item, {
          class: "p-m-3",
          style: {"max-width":"360px"},
          campaign: _ctx.campaign,
          item: item,
          key: `${index}_${_ctx.campaign?.id}`
        }, null, 8, ["campaign", "item"]))
      }), 128))
    ]),
    _createVNode(_component_Paginator, {
      rows: _ctx.limit,
      totalRecords: _ctx.campaignContent?.pagination,
      onPage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onPage($event)))
    }, null, 8, ["rows", "totalRecords"])
  ]))
}