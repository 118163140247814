
import { defineComponent, ref, watch } from 'vue';
import Button from 'primevue/button';
import Avatar from 'primevue/avatar';
import Paginator from 'primevue/paginator';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { calculateCpiRate } from '@/utils/globals/cpi-rate';
import { useAdmins } from '../../modules';
import { compactNumber } from '../../utils/globals/curency-utils';
import { formatDate } from '../../utils/globals/date-utils';
import CampaignsCounterItem from '../campaigns/CampaignsCounterItem.vue';
import { CampaignPaymentMethod } from '../../data/types/campaign-types';

export default defineComponent({
  name: 'ImportantStats',
  components: {
    Button,
    Avatar,
    InputText,
    Paginator,
    CampaignsCounterItem,
    DataTable,
    Column,
  },
  setup() {
    const adminsModule = useAdmins();
    adminsModule.manager.checkAdmin();
    adminsModule.manager.getCreatorsCount();
    adminsModule.manager.getBrandsCount();
    adminsModule.manager.getAdminsCount();
    const selectedAll = ref(false);

    const selectedCampaignForActions = ref();
    const newCampaignStatus = ref();
    const newBudgetAmount = ref();
    const newBudgetCurrency = ref(selectedCampaignForActions.value?.budget?.currency);

    const changeStatus = ref([{
      status: 'active',
    }, {
      status: 'draft',
    }, {
      status: 'pending',
    }, {
      status: 'completed',
    }, {
      status: 'live',
    }]);

    const limitCampaigns = ref(10);
    const skipCampaigns = ref(0);
    const priorityCampaignStatus = ref('active');
    const searchValueCampaign = ref('');

    watch(priorityCampaignStatus, () => {
      skipCampaigns.value = 0;
      adminsModule.manager.getAllCampaigns(
        limitCampaigns.value,
        skipCampaigns.value,
        priorityCampaignStatus.value,
        searchValueCampaign.value,
      );
    });

    adminsModule.manager.getOverviewBrands(limitCampaigns.value, skipCampaigns.value);

    const searchCampaigns = () => {
      skipCampaigns.value = 0;
      adminsModule.manager.getOverviewBrands(
        limitCampaigns.value,
        skipCampaigns.value,
        searchValueCampaign.value,
      );
    };

    const onPage = (event: { page: number; first: number; rows: number; pageCount: number }) => {
      skipCampaigns.value = event.first;
      adminsModule.manager.getOverviewBrands(
        limitCampaigns.value,
        skipCampaigns.value,
        searchValueCampaign.value,
      );
    };

    const expandedRows = ref<any>([]);
    const expandItem = (data: any) => {
      const index = expandedRows.value.findIndex((camp: any) => camp?._id?.toString() === data?._id?.toString());
      if (index < 0) {
        expandedRows.value.push(data);
      }
      if (index >= 0) {
        expandedRows.value = expandedRows.value.filter((el: any) => el?._id !== data?._id);
      }
    };


    const debounce = (func: { (): void; apply?: any }, timeout = 400) => {
      let timer: NodeJS.Timeout;
      return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    };

    const searchBrands = debounce(() => adminsModule.manager.getOverviewBrands(
      limitCampaigns.value,
      skipCampaigns.value,
      searchValueCampaign.value,
    ));

    return {
      expandItem,
      expandedRows,
      searchCampaigns,
      onPage,
      compactNumber,
      formatDate,
      calculateCpiRate,
      searchBrands,
      creatorsCounts: adminsModule.manager.getCreatorsCountResult,
      brandsCount: adminsModule.manager.getBrandsCountResult,
      adminsCount: adminsModule.manager.getAdminsCountResult,
      brandsList: adminsModule.manager.getOverviewBrandsResult,
      priorityCampaignStatus,
      searchValueCampaign,
      limitCampaigns,
      selectedCampaignForActions,
      changeStatus,
      newCampaignStatus,
      newBudgetAmount,
      newBudgetCurrency,
      selectedAll,
      CampaignPaymentMethod,
      // eslint-disable-next-line global-require
      defaultImage: require('../campaigns/assets/images/default.png'),
    };
  },
});
