
import { defineComponent, ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { User } from '@/data/types';
import { useAuth } from '@/modules/auth-module';
import { useUsers } from '@/modules';
import { useAuthForm } from './modules/auth-form-module';
import { SocialConnectResponse } from './social-def';
import SocialConnect from './components/SocialConnect.vue';
import UserTypeToggle from './components/UserTypeToggle.vue';
import EmailConnect from './components/EmailConnect.vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

export default defineComponent({
  name: 'RegistrationForm',

  components: {
    SocialConnect,
    UserTypeToggle,
    EmailConnect,
    InputText,
    Button,
  },

  setup() {
    const showPrivacyPolicy = ref(false);
    const showTermsOfUse = ref(false);
    const showLegacy = ref(false);
    const creatorName = ref('');
    const brandName = ref('');
    const registrationSteep = ref(1);
    const { loadUser } = useAuth();
    const router = useRouter();
    const users = useUsers();
    const nameError = ref(false);
    const username = computed(() => userType.value === 'creator' ? creatorName.value : brandName.value);
    const emailUser = ref('');
    const firstNameUser = ref('');
    const lastNameUser = ref('');
    const passwordUser = ref('');
    const sendVerificationEmail = ref(false);
    const verificationCode = ref('');
    const verificationCodeError = ref(false);

    watch([brandName, creatorName], () => {
      if (userType.value !== 'creator') {
        if (/@/gi.test(brandName.value)) {
          nameError.value = true;
          localStorage.removeItem('brandName');
        } else {
          nameError.value = false;
          localStorage.setItem('brandName', brandName.value);
        }
      } else {
        if (/@/gi.test(creatorName.value)) {
          nameError.value = true;
          localStorage.removeItem('creatorName');
        } else {
          nameError.value = false;
          localStorage.setItem('creatorName', brandName.value);
        }
      }
    });

    const { connecting, userIsCreator, userType, availableSocialConnections } = useAuthForm();
    const handleUserConnect = (response: SocialConnectResponse) => {
      loadUser(response.tokens.access.token)
          .then((success?: User | undefined) => {
            if (success) {
              return router.push({ name: 'dashboard' });
            }
            // TODO: handle somehow?
          });
    };

    const sendVerificationCode = async () => {
      return users.manager.verificationEmail(emailUser.value);
    }

    const registrationViaEmail = async (data: { email: string; password: string ,firstName:string,lastName:string}) => {
      const { email, password,firstName,lastName } = data;
      emailUser.value = email;
      passwordUser.value = password;
      firstNameUser.value = firstName
      lastNameUser.value = lastName

      const name = username.value;
      if (!name) {
        nameError.value = true;
      }
      if (!nameError.value) {
        await sendVerificationCode().then(() => {
          sendVerificationEmail.value = true;
        });
      }
    };

    const registryWithVode = async () => {
      const tokens = await users.manager.signUp(
          emailUser.value,
          firstNameUser.value,
          lastNameUser.value,
          passwordUser.value,
          userType.value,
          verificationCode.value,
          username.value,
      );
      if (!tokens) {
        verificationCodeError.value = true;
        return;
      }
      loadUser(tokens?.tokens.access.token)
          .then((success?: User | undefined) => {
            if (success) {
              router.push({ name: 'dashboard' });
            }
          });
    };

    return {
      nameError,
      creatorName,
      brandName,
      showLegacy,
      showTermsOfUse,
      showPrivacyPolicy,
      connecting,
      userIsCreator,
      userType,
      registrationSteep,
      sendVerificationEmail,
      verificationCodeError,
      verificationCode,
      username,
      emailUser,
      availableSocialConnections: availableSocialConnections.value.filter((item) => item.disabled !== true),
      handleUserConnect,
      registrationViaEmail,
      sendVerificationCode,
      registryWithVode,
    };
  },
});
