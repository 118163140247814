<template>
  <div>
    <div class="p-flex-column">
      <div class="text-align p-mt-6">
        <img  class="logo" alt="Logo" src="@/assets/images/logo.png">
      </div>
      <div class="p-d-flex p-justify-center p-mt-6 margin">
        <img class="image" src="@/assets/images/live-content-img.svg">
        <p class="title-main">Privacy Policy</p>
      </div>
    </div>
    <div class="p-d-flex p-flex-column p-p-4 p-ai-center vh-container p-mt-2">
      <div class="p-ai-start terms-conditions">Privacy Policy</div>
      <div class="text-box">
        <div class="entry-content">
          <h2 style="box-sizing: border-box; margin: 0px; font-size: 2.38889rem; line-height: 1.17829;
             font-weight: 800;">Information pursuant to Sect. 5 German Telemedia Act (TMG)</h2>
          <p style="box-sizing: border-box; margin: 1.8rem 0px 0px;">GameInfluencer GmbH
            <br style="box-sizing: border-box;
             margin: 0px;">Wessobrunner Platz 6<br style="box-sizing: border-box; margin: 0px;">
            81377 M&uuml;nchen<br
              style="box-sizing: border-box; margin: 0px;"><br style="box-sizing: border-box; margin: 0px;">
            Commercial Register: HRB 226885
            <br style="box-sizing: border-box; margin: 0px;">Registration court: M&uuml;nchen</p>
          <p style="box-sizing: border-box; margin: 1.8rem 0px 0px;">Benedikt Seitz &amp; Georg Broxtermann</p>
          <h2 style="box-sizing: border-box; margin: 1.8rem 0px 0px; font-size: 2.38889rem; line-height: 1.17829;
             font-weight: 800;">Contact</h2>
          <p style="box-sizing: border-box; margin: 1.8rem 0px 0px;">Email: contact@gameinfluencer.com
            <br style="box-sizing: border-box; margin: 0px;">&nbsp;</p>
          <h2 style="box-sizing: border-box; margin: 1.8rem 0px 0px; font-size: 2.38889rem;
             line-height: 1.17829; font-weight: 800;">VAT ID</h2>
          <p style="box-sizing: border-box; margin: 1.8rem 0px 0px;">
            Sales tax identification number according to Sect. 27 a of the Sales Tax Law:
            <br style="box-sizing: border-box; margin: 0px;">DE308045842
            <br style="box-sizing: border-box; margin: 0px;">&nbsp;</p>
          <h2 style="box-sizing: border-box; margin: 1.8rem 0px 0px;
             font-size: 2.38889rem; line-height: 1.17829; font-weight: 800;">
            Responsible for the content according to Sect. 18, paragraph 2 MStV</h2>
          <p style="box-sizing: border-box; margin: 1.8rem 0px 0px;">
            GameInfluencer GmbH<br style="box-sizing: border-box; margin: 0px;">Wessobrunner Platz 6
            <br style="box-sizing: border-box; margin: 0px;">81377 M&uuml;nchen</p>
          <p style="box-sizing: border-box; margin: 1.8rem 0px 0px;">
            <strong style="box-sizing: border-box; margin: 0px; font-weight: bolder;">
              Represented by:</strong><br style="box-sizing: border-box; margin: 0px;">Benedikt Seitz &amp;
            Georg Broxtermann</p>
          <h2 style="box-sizing: border-box; margin: 1.8rem 0px 0px; font-size: 2.38889rem;
             line-height: 1.17829; font-weight: 800;">EU dispute resolution</h2>
          <p style="box-sizing: border-box; margin: 1.8rem 0px 0px;">
            The European Commission provides a platform for online dispute resolution (ODR):
            <br style="box-sizing: border-box; margin: 0px;">
            https://ec.europa.eu/consumers/odr.<br style="box-sizing: border-box; margin: 0px;">
            Our e-mail address can be found above in the site notice.</p>
          <h2 style="box-sizing: border-box; margin: 1.8rem 0px 0px; font-size: 2.38889rem;
            line-height: 1.17829; font-weight: 800;">
            Dispute resolution proceedings in front of a consumer arbitration board</h2>
          <p style="box-sizing: border-box; margin: 1.8rem 0px 0px;">
            We are not willing or obliged to participate in dispute resolution proceedings in
            front of a consumer arbitration board.</p>
          <h3 style="box-sizing: border-box; margin: 1.8rem 0px 0px; font-weight: 800;
            font-size: 1.16667rem; line-height: 1.39683;">Liability for Contents</h3>
          <p style="box-sizing: border-box; margin: 0.44444rem 0px 0px;">
            As service providers, we are liable for own contents of these websites according to Paragraph 7, Sect. 1
            <br style="box-sizing: border-box; margin: 0px;">
            German Telemedia Act (TMG). However, according to
            Paragraphs 8 to 10 German Telemedia Act (TMG),
            <br style="box-sizing: border-box; margin: 0px;">
            service providers are not obligated to permanently monitor submitted or stored information or to search for
            <br style="box-sizing: border-box; margin: 0px;">
            evidences that indicate illegal activities.
            <br style="box-sizing: border-box; margin: 0px;">
            br style="box-sizing: border-box; margin: 0px;">
            Legal obligations to removing information or to blocking the use of information remain unchallenged.
            In this<br style="box-sizing: border-box; margin: 0px;">case, liability is only possible at the time
            of knowledge about a specific violation of law. Illegal contents will<br style="box-sizing: border-box;
               margin: 0px;">be removed immediately at the time we get knowledge of them.</p>
          <h3 style="box-sizing: border-box; margin: 1.8rem 0px 0px; font-weight: 800; font-size: 1.16667rem;
             line-height: 1.39683;">Liability for Links</h3>
          <p style="box-sizing: border-box; margin: 0.44444rem 0px 0px;">
            Our offer includes links to external third party websites.
            We have no influence on the contents of those<br style="box-sizing:
               border-box; margin: 0px;">websites, therefore we cannot guarantee for those contents.
            Providers or administrators of linked websites<br style="box-sizing: border-box; margin: 0px;">
            are always responsible for their own contents.<br style="box-sizing: border-box; margin: 0px;">
            <br style="box-sizing: border-box; margin: 0px;">The linked websites had been checked for
            possible violations of law at the time of the establishment of th
            e<br style="box-sizing: border-box; margin: 0px;">
            link. Illegal contents were not detected at the time of the linking. A permanent monitoring of the contents of
            <br style="box-sizing: border-box; margin: 0px;">
            linked websites cannot be imposed without reasonable indications
            that there has been a violation of law.
            <br style="box-sizing: border-box; margin: 0px;">Illegal links will be
            removed immediately at the time we get knowledge of them.
            <br style="box-sizing: border-box; margin: 0px;">&nbsp;</p>
          <h3 style="box-sizing: border-box; margin: 1.8rem 0px 0px; font-weight: 800;
            font-size: 1.16667rem; line-height: 1.39683;">Copyright</h3>
          <p style="box-sizing: border-box; margin: 0.44444rem 0px 0px;">
            Contents and compilations published on these websites by the providers are
            subject to German copyright<br style="box-sizing: border-box; margin: 0px;">
            laws. Reproduction
            , editing, distribution as well as the use of any kind outside the scope of the copyright law<br
            style="box-sizing: border-box; margin: 0px;">require a written permission of the author or originator.
            Downloads and copies of these websites are<br style="box-sizing: border-box; margin: 0px;">permitted for
            private use only.<br style="box-sizing: border-box; margin: 0px;"><br style="box-sizing: border-box;
              margin: 0px;">The commercial use of our contents without permission of the originator is prohibited.
            <br style="box-sizing: border-box; margin: 0px;">Copyright laws of third parties are respected as long
            as the contents on these websites do not originate from<br style="box-sizing: border-box; margin: 0px;">
            the provider. Contributions of third parties on this site are indicated as such. However, if you notice any
            <br style="box-sizing: border-box; margin: 0px;">violations of copyright law, please inform us.
            Such contents will be removed immediately.</p>
        </div>
      </div>
      <Button
        label="back to previous page"
        class="continue-button"
        @click="$router.push({ name: 'login' })"/>
      <div class="p-d-flex p-justify-around position">
        <div>© 2022 Gameinfluencer GmbH |</div>
        <div class="footer" @click="$router.push({ name: 'terms-of-use' })"> TERMS OF USE |</div>
        <div class="footer" @click="$router.push({ name: 'legacy-of-notice' })">LEGAL NOTICE |</div>
        <div class="footer" @click="$router.push({name: 'privace-policy'})"> Privacy Policy |</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Button from 'primevue/button';

export default {
  components: {
    Button,
  },
  props: {
    show: Boolean,
  },
  setup(props) {
    const showing = ref(props.show);
    return {
      showing,
    };
  },
};
</script>
<style scoped lang="scss">
.margin {
  margin-right: 12vh;
}

.position {
  margin-top: 8vh;
  width: 30%;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.footer {
  text-decoration: underline;
  font-weight: bold;
  color: #E0010B;
  cursor: pointer;
}

.logo {
  width: 170px;
  height: 67.61px;
}

.text-align {
  text-align: center;
}

.image {
  position: relative;
  margin-left: 20px;
  width: 60px;
  height: 60px;
}

.text-box {
  width: 875px;
  height: 500px;
  overflow-y: scroll;
}

.title-main {
  opacity: 0.7;
  font-weight: 800;
  font-size: 30px;
  line-height: 50px;
  color: #181818;
  position: absolute;
  margin-left: 220px;
  margin-top: 20px
}

.terms-conditions {
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  color: #253340;
}

.continue-button {
  margin-top: 40px;
  border: none;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  width: 237px;
  height: 34px;
  background: #E0010B;
  box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}

.agreement {
  font-weight: 600;
  font-size: 12rem;

  a {
    color: $giRed;
    text-decoration: underline;
    font-weight: 700;

    &:hover {
      text-decoration: none;
    }
  }
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight ){
  background-color: #E0010B;
  color:#ffff;
}

div::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #FFFFFF;
}

div::-webkit-scrollbar-thumb{
  background: #686E6E;
  border-radius: 5px;
}

</style>
