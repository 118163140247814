import { useRouter } from 'vue-router';

export const useNotificationsMethods = () => {
  const router = useRouter();
  const methods = {
    apply: async (notification: any) => {
      await router.push({
        name: 'campaigns-draft',
        params: {
          campaignID: notification.campaignId?.id || notification.campaignId,
        },
      });
    },
    editedTrackingLink: async (notification: any) => {
      await router.push({
        name: 'campaign-active',
        params: {
          campaignID: notification.campaignId?.id || notification.campaignId,
        },
      });
    },
    invite: async (notification: any) => {
      await router.push({
        name: 'creator-invitations',
      });
    },
    videoReason: async (notification: any) => {
      await router.push({
        name: 'video-reason',
        params: {
          content: notification.forApprovalVideo,
        },
      });
    },
    campaignSmallBudget: async (notification: any, perspective: string) => {
      if (perspective === 'creator' || perspective === 'AgencyOwner') {
        await router.push({
          name: 'campaign-active',
          params: {
            campaignID: notification.campaignId?.id || notification.campaignId,
          },
        });
      } else {
        await router.push({
          name: 'campaigns-stats',
          params: {
            campaignID: notification.campaignId?.id || notification.campaignId,
          },
        });
      }
    },
    campaignChangedCpi: async (notification: any) => {
      await router.push({
        name: 'campaign-active',
        params: {
          campaignID: notification.campaignId?.id || notification.campaignId,
        },
      });
    },
    cpmPayoutCapProposal: async (notification: any, perspective: string) => {
      if (perspective === 'creator' || perspective === 'agencyOwner') {
        await router.push({
          name: 'campaign-active',
          params: {
            campaignID: notification.campaignId?.id || notification.campaignId,
          },
        });
      }
    },
    pendingCampaignRequest: async (notification: any, perspective: string) => {
      if (perspective === 'admin') {
        await router.push({
          name: 'important-stats',
        });
      }
    },
  };

  return {
    methods,
  };
};
