import React, { useEffect, useState } from "react";
import { Image } from 'primereact/image';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from "primereact/inputtext";
import { Paginator } from 'primereact/paginator';
import "./NewPendingPayments.css";
import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';

const creatorTemplate = (data) => {
  return (
    <div className={'creatorTemplate'}>
      <Image src={data.photo} alt={'creator photo'} className={'creatorTemplatePhoto'} />
      <p className={'creatorTemplateName'}>{data.name}</p>
    </div>
  )
};

const amountTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      $ {data.amountEarnedThisCycle} USD
    </div>
  )
};

export default function (props) {
  const {
    limit, changePage, search, loading, data
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [first, setFirst] = useState(0);
  const [time, setTime] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    clearTimeout(time);
    setTime(
      setTimeout(() => {
        setFirst(0);
        search(e.target.value);
      }, 1000)
    );
  }

  const onPageChange = (event) => {
    setFirst(event.first);
    changePage(event)
  };

  useEffect(() => {
    setNoResults(!(data?.creatorEarnings?.length))
  }, [data]);

  return (
    <div className={'pending-payments'}>
      <p className={'title'}>Pending Payments</p>
      <span className="p-input-icon-left searchFieldWrapper">
        <i className="pi pi-search" />
        <InputText placeholder="Search for creator" className={'searchField'} value={searchValue}
          onChange={(e) => handleSearch(e)} />
      </span>
      <DataTable value={data?.creatorEarnings} emptyMessage="No current pending payments."
        loading={loading}
        loadingIcon={
          <CustomLoader />
        }
        style={{ minHeight: 500 }}>
        <Column field={"name"} header={"Creator"} sortable body={creatorTemplate} style={{ width: '50%' }}></Column>
        <Column field={"amount"} header={"Amount"} sortable body={amountTemplate} style={{ width: '50%' }}></Column>
      </DataTable>
      {!noResults && <Paginator first={first} rows={limit} totalRecords={data?.maxCount} onPageChange={onPageChange} style={{ background: '#130A23' }} />}
    </div>
  )
}
