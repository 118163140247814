import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "campaign-list-counters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CampaignsCounterItemNew = _resolveComponent("CampaignsCounterItemNew")!
  const _component_CampaignsCounterItemSwitch = _resolveComponent("CampaignsCounterItemSwitch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CampaignsCounterItemNew, {
      name: "Total View Count",
      counter: `${_ctx.counters?.views}`
    }, null, 8, ["counter"]),
    _createVNode(_component_CampaignsCounterItemNew, {
      name: "Total Clicks",
      counter: `${_ctx.counters?.clicks}`
    }, null, 8, ["counter"]),
    _createVNode(_component_CampaignsCounterItemSwitch, {
      name: "Campaign CPI",
      android: _ctx.counters?.android,
      ios: _ctx.counters?.ios
    }, null, 8, ["android", "ios"])
  ]))
}