import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewCampaign = _resolveComponent("NewCampaign")!
  const _component_OldCampaign = _resolveComponent("OldCampaign")!

  return (_ctx.isNewCampaignsPageEnabled())
    ? (_openBlock(), _createBlock(_component_NewCampaign, {
        key: 0,
        campaign: _ctx.campaign,
        handleContentRouting: _ctx.handleContentRouting,
        handleRoutingAll: _ctx.handleRoutingAll,
        handleBackToAll: _ctx.handleBackToAll,
        user: _ctx.user,
        refreshData: _ctx.refreshData,
        loading: _ctx.loading
      }, null, 8, ["campaign", "handleContentRouting", "handleRoutingAll", "handleBackToAll", "user", "refreshData", "loading"]))
    : (_openBlock(), _createBlock(_component_OldCampaign, {
        key: 1,
        campaignID: _ctx.campaignID
      }, null, 8, ["campaignID"]))
}