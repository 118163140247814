
import { computed, defineComponent, ref, watch } from 'vue';
import { useBrands } from '@/modules';
import { AppPageConfig, AppPageProps, Brand, Perspective } from '@/data/types';
import OldBrand from "./OldBrand.vue";
import NewBrand from "@/react_app/NewBrand/Profile/NewBrand/NewBrand";
import { applyPureReactInVue } from 'veaury';
import { isNewBrandEnabled } from "@/utils/globals/feature-flags";

export default defineComponent({
  components: {
    NewBrand: applyPureReactInVue(NewBrand),
    OldBrand,
  },

  props: {
    brandID: {
      type: String,
    },
    ...AppPageProps,
  },

  setup(props) {
    const brandID = computed(() => props.brandID || '');
    const config = ref<AppPageConfig>({
      title: 'Loading brand',
      ...props,
    });
    const controller = useBrands(props.viewPerspective as Perspective);
    const loadBrand = () => controller.manager.loadBrand(brandID.value);
    const title = ref("My brand");

    loadBrand();
    watch(controller.manager.brand, () => {
      if (controller.manager.brand.value?.name) {
        config.value.title = controller.manager.brand.value?.name;
      }
    });

    // const isMyBrand = computed(() => user?.value?.isBrandOwner() && (user?.value?.brand?.id === brandID.value));
    // edit
    const showEditBrandModal = ref(false);
    const updateBrand = async (payload: Record<string, unknown>) => {
      await controller.manager
        .update(brandID.value, payload)
        .then((success?: Brand) => {
          if (success) {
            showEditBrandModal.value = false;
            window.location.reload();
            localStorage.removeItem('socialIn');
          }
        });
    };

    return {
      config,
      controller,
      loading: controller.manager.loadingBrand,
      brand: controller.manager.brand,
      isMyBrand: controller.manager.brand.value?.isOwnedByMe(),
      // edit
      showEditBrandModal,
      updateBrand,
      isNewBrandEnabled,
      title,
    };
  },
});
