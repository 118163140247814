<template>
  <div class="p-d-flex p-align-center p-mb-6" v-if="!openModal">
    <div class="dot-progress checked-cpm-progress">
      <div class="dot-lable">Campaign Accepted</div>
    </div>
    <div class="line-progress" :class="progressData.videoDraft ? 'checked-cpm-progress' : ''"></div>
    <div class="dot-progress" :class="progressData.videoDraft ? 'checked-cpm-progress' : ''">
      <div class="dot-lable" v-if="progressData.videoDraft && !progressData.draftRejected">Draft Submitted</div>
      <div class="dot-lable" v-if="progressData.draftRejected">Draft is not approved</div>
    </div>
    <div class="line-progress" :class="progressData.draftAccepted ? 'checked-cpm-progress' : ''"></div>
    <div class="dot-progress" :class="progressData.draftAccepted ? 'checked-cpm-progress' : ''">
      <div class="dot-lable">Draft Accepted</div>
    </div>
    <div class="line-progress" :class="progressData.liveVideoPosted ? 'checked-cpm-progress' : ''"></div>
    <div class="dot-progress" :class="progressData.liveVideoPosted ? 'checked-cpm-progress' : ''">
      <div class="dot-lable">Live Video Posted</div>
    </div>
  </div>

  <Dialog :closable="false" :visible="openModal" :style="{ 'min-width': '50vw', padding: '150px' }">
    <template #header>
      <div class="header-modal">
        <h6>Channel Progress</h6>
        <div class="p-d-flex p-justify-end p-mb-2 full-width pointer" @click="$emit('close')">
          X</div>
      </div>
    </template>
    <div class="p-d-flex p-align-center p-mb-6" style="padding: 55rem">
      <div class="dot-progress checked-cpm-progress">
        <div class="dot-lable">Campaign Accepted</div>
      </div>
      <div class="line-progress" :class="progressData.videoDraft ? 'checked-cpm-progress' : ''"></div>
      <div class="dot-progress" :class="progressData.videoDraft ? 'checked-cpm-progress' : ''">
        <div class="dot-lable" v-if="progressData.videoDraft && !progressData.draftRejected">Draft Submitted</div>
        <div class="dot-lable" v-if="progressData.draftRejected">Draft is not approved</div>
      </div>
      <div class="line-progress" :class="progressData.draftAccepted ? 'checked-cpm-progress' : ''"></div>
      <div class="dot-progress" :class="progressData.draftAccepted ? 'checked-cpm-progress' : ''">
        <div class="dot-lable">Draft Accepted</div>
      </div>
      <div class="line-progress" :class="progressData.liveVideoPosted ? 'checked-cpm-progress' : ''"></div>
      <div class="dot-progress" :class="progressData.liveVideoPosted ? 'checked-cpm-progress' : ''">
        <div class="dot-lable">Live Video Posted</div>
      </div>
    </div>
    <template #footer>
      <button label="OK"  @click="$emit('close')"
           class="button-progress p-d-flex p-align-center p-justify-center p-button-text red-button">OK</button>
    </template>
  </Dialog>
</template>

<script>
// import { ref } from 'vue';
import Dialog from 'primevue/dialog';

export default {
  emits: ['close'],
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    progressData: {
      type: Object,
      default() {
        return {
          videoDraft: false,
          draftAccepted: false,
          draftRejected: false,
          rejectReason: '',
          liveVideoPosted: false,
        };
      },
    },
  },
  components: {
    Dialog,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.dot-progress {
  background-color: black;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: relative;
}

.dot-lable {
  position: absolute;
  bottom: -30px;
  left: -50px;
  color: black;
  font-weight: 900;
  white-space: nowrap;
}

.line-progress {
  background-color: black;
  width: 33%;
  min-width: 50px;
  height: 5px;
}

.checked-cpm-progress {
  background-color: red;
}

.button-progress {
  min-width: 160px;
  cursor: pointer;
}

.header-modal {
  display: flex;
  justify-content: space-between;
  width: 100%;

  h6 {
    font-size: 23px;
  }
}
</style>
