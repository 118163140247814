<template>
  <div>
    <Dialog
        :visible="show"
        class="p-col-12 p-md-6 p-xl-4 gi-dialog"
        :modal="true"
        :style="{ width: '840px' , height : '820px'} "
        @update:visible="$emit('update:show', false)"
    >
      <template #header>
        <div class="p-m-auto">
          <div class="main-title">
            {{ counterOffer ? 'Counter offer' : 'Request Content' }}
          </div>
        </div>
      </template>
      <div class="p-d-flex p-justify-center p-flex-column p-align-center">
        <p>{{
            counterOffer ? 'Send us your counter offer proposal'
                : 'Send us a note about the content you’d like to request'
          }}</p>
        <div class="p-grid p-jc-center p-mt-4">
          <div>
            <LatestContentItem :item="item"></LatestContentItem>
          </div>
          <div>
            <div>
              <h6>Content distribution platform</h6>
              <Dropdown
                  v-model="platforms"
                  :options="platformsOptions"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Select Platforms"
                  class="w-100 p-dropdown"/>
            </div>
            <div>
              <h6 class="margin-top-bottom">Content distribution period</h6>
              <Dropdown
                  v-model="duration"
                  :options="durationOptions"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Select Duration"
                  class="w-100 p-dropdown"/>
            </div>
            <div>
              <h6 class="margin-top-bottom">Content distribution across</h6>
              <MultiSelect
                  v-model="regions"
                  :options="regionsOptions"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Select Regions"
                  class="w-100 p-dropdown"/>
            </div>
            <div class="w-100">
              <h6 class="p-mt-2">Influencer verified price</h6>
              <p class="price" v-if="!counterOffer">{{ price?.amount }} {{ price?.currency }}</p>
              <div v-else class="w-100 input-rel">
                <InputNumber class="integer-only w-100" v-model="price"/>
                <MultiSelect
                    v-model="newCurrency"
                    :options="currencyOptions"
                    optionLabel="name"
                    optionValue="value"
                    class="p-dropdown-int"/>
              </div>
            </div>
          </div>
        </div>
        <div class="p-jc-center margin-top-left">
          <h6>Request Note</h6>
          <Textarea class="textarea" cols="80" rows="5" v-model="description"
                    placeholder="Enter your preferred way of getting the content"></Textarea>
          <ApplicationSuccess v-model:isShowApplication="showEndModal"
                              @close-apc-modal="closeModal"></ApplicationSuccess>
        </div>
      </div>
      <template #footer>
        <div class="p-d-flex p-justify-center p-pt-4">
          <Button class="continue-button" @click="counterOffer ? counterOfferRequest() : LicenseRequest();"
                  :disabled="!fieldValidation">{{
              counterOffer ? 'Counter offer'
                  : 'request license'
            }}
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>

import Dialog from 'primevue/dialog';
import { ref, computed } from 'vue';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import LatestContentItem from '@/pages/creators/latest/LatestContentItem.vue';
import Textarea from 'primevue/textarea';
import ApplicationSuccess from '@/pages/campaigns/create/ApplicationSuccess.vue';

export default {
  components: {
    Dialog,
    Dropdown,
    InputNumber,
    ApplicationSuccess,
    MultiSelect,
    Button,
    LatestContentItem,
    Textarea,
  },
  props: {
    show: Boolean,
    counterOffer: {
      type: Boolean,
      default: false,
    },
    item: Object,
  },
  setup(props, ctx) {
    const showEndModal = ref(false);
    const platforms = ref('');
    const regions = ref('');
    const duration = ref(0);
    const price = computed(() => duration.value?.minCost);
    const description = ref('');
    const newCurrency = ref(['USD']);
    const LicenseRequest = () => {
      ctx.emit('update:show', false);
      ctx.emit('licenseRequest', platforms.value, regions.value, duration.value, price.value, props?.item?.id);
      showEndModal.value = true;
    };

    const fieldValidation = computed(
      () => platforms.value && regions.value && duration.value && price.value && newCurrency.value,
    );
    const counterOfferRequest = () => {
      if (fieldValidation.value) {
        const durationD = {};
        if (duration.value === 'indefinite') {
          durationD.perpetual = true;
        } else {
          durationD.period = duration.value?.period;
          durationD.type = duration.value?.type;
        }

        const data = {
          counter: {
            platforms: platforms.value,
            duration: durationD,
            distributionAcross: regions.value,
          },
          currency: newCurrency.value[0],
          price: price.value,
          description: description.value,
        };
        ctx.emit('counterOffer', data);
      }
    };
    const closeModal = () => {
      ctx.emit('update:show', false);
      showEndModal.value = false;
    };
    const platformsOptions = [
      { name: 'Social Media', value: 'social-media' },
      { name: 'Linear TV', value: 'linear-tv' },
      { name: 'Social Media + Linear TV', value: 'social-media+linear-tv' },
    ];
    const durationOptions = [
      {
        name: '1 Month',
        value: {
          period: 1,
          type: 'month',
          minCost: {
            amount: 200,
            currency: 'USD',
          },
          perpetual: false,
        },
      },
      {
        name: '3 Months',
        value: {
          period: 3,
          type: 'month',
          perpetual: false,
          minCost: {
            amount: 300,
            currency: 'USD',
          },
        },
      },
      {
        name: '6 Months',
        value: {
          period: 6,
          type: 'month',
          perpetual: false,
          minCost: {
            amount: 500,
            currency: 'USD',
          },
        },
      },
      {
        name: '12 Months',
        value: {
          period: 12,
          type: 'month',
          perpetual: false,
          minCost: {
            amount: 750,
            currency: 'USD',
          },

        },
      },
      {
        name: 'Indefinite',
        value: {
          type: 'indefinite',
          period: 'indefinite',
          perpetual: true,
          minCost: {
            amount: 1000,
            currency: 'USD',
          },
        },
      },
    ];
    const regionsOptions = [
      { name: 'EMEA', value: 'emea' },
      { name: 'APAC', value: 'apac' },
      { name: 'North America', value: 'north-america' },
      { name: 'Latin America', value: 'latin-america' },
    ];
    const currencyOptions = [{ name: '$', value: 'USD' }];

    return {
      showEndModal,
      LicenseRequest,
      counterOfferRequest,
      platformsOptions,
      durationOptions,
      regionsOptions,
      duration,
      platforms,
      price,
      regions,
      description,
      closeModal,
      currencyOptions,
      newCurrency,
      fieldValidation,
    };
  },
};
</script>
<style scoped lang="scss">
.margin-top-bottom {
  margin-top: 30px;
  margin-bottom: 10px
}
.margin-top-left {
  margin-top: 20px;
  margin-left:85px;
}

.buttons {
  margin-top: 120px;
  margin-bottom: 15px;
}

.apc {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #253340;
  text-align: center;
  margin-left: 80px;
  margin-bottom: 50px;
}

.warning-text {
  text-align: center;
  margin-bottom: 70px;
}

.cpi-text {
  color: #253340;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.continue-button {
  border: none;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  width: 184px;
  height: 34px;
  background: #E0010B;
  box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.edit-button {
  margin-left: 10%;
  color: #162020;
  background-color: #FFFFFF;
  border: none;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
}

.price {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #162020;
  font-style: normal;
}

.textarea {
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.main-title {
  font-weight: 800;
  font-size: 30px;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;
  color: #162020;
}

::v-deep(.p-dropdown) {
  border-radius: 10px;
  box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.1);
  width: 120%;
  color: #8B8F8F;
  border: none;
}

.default-input ::v-deep(.p-inputnumber-input) {
  flex: 1;
  background: #FFFFFF;
  box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.1);
  border: none;
  max-width: 70px;
  height: 34px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #162020;
}

::v-deep(.p-dialog .p-dialog-content) {
  background: #ffffff;
  color: #495057;
  padding: 0px 31rem 15rem 0rem;
}

input {
  background: #FFFFFF;
  box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.input-rel {
  position: relative;

  .p-dropdown-int {
    position: absolute;
    top: 0;
    left: 10px;
    width: 60px;
    font-weight: bold;
    border: none;
  }

  .p-dropdown-int::after {
    content: '';
    position: absolute;
    top: 6px;
    right: 0;
    width: 1px;
    height: 20px;
    border-right: 1px solid #8B8F8F;
  }

  .integer-only ::v-deep(.p-inputnumber-input) {
    background: #FFFFFF;
    box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: none;
    padding-left: 85px;
    height: 34px;
  }
}

</style>
