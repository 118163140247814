import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "creators-list p-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreatorListItem = _resolveComponent("CreatorListItem")!
  const _component_EmptyList = _resolveComponent("EmptyList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.creators, (user) => {
      return (_openBlock(), _createBlock(_component_CreatorListItem, {
        key: user.id,
        class: "p-col-12 p-md-6 p-lg-6 p-xxl-4 p-xxxl-3",
        user: user
      }, null, 8, ["user"]))
    }), 128)),
    (!_ctx.creators.length)
      ? (_openBlock(), _createBlock(_component_EmptyList, {
          key: 0,
          title: "No Creators"
        }))
      : _createCommentVNode("", true)
  ]))
}