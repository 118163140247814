
import { defineComponent, onMounted, ref, watch } from 'vue';
import { calculateCpiRate } from '@/utils/globals/cpi-rate';
import { applyPureReactInVue } from 'veaury';
import NewOverviewBrands from '@/react_app/NewAdmin/NewOverviewBrands/NewOverviewBrands';
import OldOverviewBrands from '@/pages/admin/OldOverviewBrands.vue';
import { isNewAdminEnabled } from '@/utils/globals/feature-flags';
import { useAdmins } from '@/modules';
import { compactNumber } from '@/utils/globals/curency-utils';
import { formatDate } from '@/utils/globals/date-utils';
import { CampaignPaymentMethod } from '@/data/types';
import router from '@/router';

export default defineComponent({
  name: 'OverviewBrands',
  components: {
    NewOverviewBrands: applyPureReactInVue(NewOverviewBrands),
    OldOverviewBrands,
  },
  setup() {
    const adminsModule = useAdmins();
    adminsModule.manager.checkAdmin();
    adminsModule.manager.getCreatorsCount();
    adminsModule.manager.getBrandsCount();
    adminsModule.manager.getAdminsCount();
    const selectedAll = ref(false);

    const selectedCampaignForActions = ref();
    const newCampaignStatus = ref();
    const newBudgetAmount = ref();
    const newBudgetCurrency = ref(
      selectedCampaignForActions.value?.budget?.currency
    );

    const changeStatus = ref([
      {
        status: 'active',
      },
      {
        status: 'draft',
      },
      {
        status: 'pending',
      },
      {
        status: 'completed',
      },
      {
        status: 'live',
      },
    ]);

    const limitCampaigns = ref(10);
    const skipCampaigns = ref(0);
    const priorityCampaignStatus = ref('active');
    const searchValueCampaign = ref('');
    const loading = ref(true);

    onMounted(() => {
      // Set an initial value or trigger any function
      getOverviewBrandsList(
        limitCampaigns.value,
        skipCampaigns.value,
        searchValueCampaign.value
      );
    });

    watch(priorityCampaignStatus, () => {
      skipCampaigns.value = 0;
      adminsModule.manager.getAllCampaigns(
        limitCampaigns.value,
        skipCampaigns.value,
        priorityCampaignStatus.value,
        searchValueCampaign.value
      );
    });

    const getOverviewBrandsList = (
      limit: number,
      skip: number,
      search: string
    ) => {
      loading.value = true;
      adminsModule.manager
        .getOverviewBrands(limit, skip, search)
        .then((res) => {
          loading.value = false;
        });
    };

    const onPage = (event: {
      page: number;
      first: number;
      rows: number;
      pageCount: number;
    }) => {
      skipCampaigns.value = event.first;
      getOverviewBrandsList(
        limitCampaigns.value,
        event.first,
        searchValueCampaign.value
      );
    };

    const expandedRows = ref<any>([]);
    const expandItem = (data: any) => {
      const index = expandedRows.value.findIndex(
        (camp: any) => camp?._id?.toString() === data?._id?.toString()
      );
      if (index < 0) {
        expandedRows.value.push(data);
      }
      if (index >= 0) {
        expandedRows.value = expandedRows.value.filter(
          (el: any) => el?._id !== data?._id
        );
      }
    };

    const debounce = (func: (searchValue: string) => void, timeout = 100) => {
      let timer: NodeJS.Timeout;

      return (searchValue: string) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, [searchValue]);
        }, timeout);
      };
    };

    const searchBrands = debounce((searchValue: string) => {
      skipCampaigns.value = 0;
      getOverviewBrandsList(limitCampaigns.value, 0, searchValue);
    }, 500);

    const handleViewDetails = (brandID: string) => {
      router.push({ name: 'overview-brands-id', params: { brandID: brandID } });
    };

    return {
      expandItem,
      expandedRows,
      onPage,
      compactNumber,
      formatDate,
      calculateCpiRate,
      searchBrands,
      creatorsCount: adminsModule.manager.getCreatorsCountResult,
      brandsCount: adminsModule.manager.getBrandsCountResult,
      adminsCount: adminsModule.manager.getAdminsCountResult,
      brandsList: adminsModule.manager.getOverviewBrandsResult,
      priorityCampaignStatus,
      searchValueCampaign,
      limitCampaigns,
      selectedCampaignForActions,
      changeStatus,
      newCampaignStatus,
      newBudgetAmount,
      newBudgetCurrency,
      selectedAll,
      CampaignPaymentMethod,
      // eslint-disable-next-line global-require
      defaultImage: require('../campaigns/assets/images/default.png'),
      isNewAdminEnabled,
      handleViewDetails,
      loading,
    };
  },
});
