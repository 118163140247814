
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'CreateAgency',
  components: {
    Dialog,
    Button,
  },
  props: {
    agencyCreated: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const router = useRouter();
    const showModalCreate = ref(false);
    let dismissAgencyV = localStorage.getItem('d:agency');
    const learnMore = () => {
      showModalCreate.value = true;
    };

    const iWantToBecomeAnAgency = async () => {
      await router.push({ name: 'create-agency' });
    };
    const dismissAgency = () => {
      localStorage.setItem('d:agency', 'dismiss');
      dismissAgencyV = 'dismiss';
    }

    return {
      learnMore,
      iWantToBecomeAnAgency,
      dismissAgency,
      showModalCreate,
      dismissAgencyV,
    };
  },
});
