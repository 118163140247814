
import { useAuth, useContentItems } from '@/modules';
import { ref } from 'vue';
import LatestContentItem from './latest/LatestContentItem.vue';
import SearchBar from './utils/SearchBar.vue';

export default {
  name: 'MyContantPage',
  components: {
    LatestContentItem,
    SearchBar,
  },
  setup() {
    const { user } = useAuth();
    const selectedAll = ref(false);
    const platformsOptions = ref([{
      value: 'YouTube',
      selected: false,
    }, {
      value: 'Facebook',
      selected: false,
    }]);
    const content = useContentItems();
    content.list.loadMyLatest(user.value?.id as string, 8);
    const selectAllPlatforms = () => {
      selectedAll.value = !selectedAll.value;
      if (selectedAll.value) {
        // eslint-disable-next-line no-param-reassign,no-return-assign
        platformsOptions.value.forEach((el) => el.selected = true);
      } else {
        // eslint-disable-next-line no-param-reassign,no-return-assign
        platformsOptions.value.forEach((el) => el.selected = false);
      }
    };
    const clearFilters = () => {
      // eslint-disable-next-line no-param-reassign,no-return-assign
      platformsOptions.value.forEach((el) => el.selected = false);
      selectedAll.value = false;
    };
    const addPlatform = (element: any) => {
      const select = platformsOptions.value.findIndex((el) => el.value === element.value);
      if (platformsOptions.value[select].selected) {
        platformsOptions.value[select].selected = false;
      } else {
        platformsOptions.value[select].selected = true;
      }
    };
    return {
      user,
      platformsOptions,
      selectedAll,
      selectAllPlatforms,
      addPlatform,
      clearFilters,
      latestContent: content.list.loadMyLatestResult,
    };
  },
};
