import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewBrand = _resolveComponent("NewBrand")!
  const _component_OldBrand = _resolveComponent("OldBrand")!

  return (_ctx.isNewBrandEnabled)
    ? (_openBlock(), _createBlock(_component_NewBrand, {
        key: 0,
        controller: _ctx.controller,
        brand: _ctx.brand,
        isMyBrand: _ctx.isMyBrand,
        updateBrand: _ctx.updateBrand,
        working: _ctx.controller.manager.updatingInProgress.value
      }, null, 8, ["controller", "brand", "isMyBrand", "updateBrand", "working"]))
    : (_openBlock(), _createBlock(_component_OldBrand, {
        key: 1,
        brandID: _ctx.brandID
      }, null, 8, ["brandID"]))
}