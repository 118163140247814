import { watch } from 'vue';
import { useAPI } from '@/modules';
import { AppPagePerspective } from '@/data/types';
import { useToastModule } from '@/modules/messages/toast-module';

export const useCreatorApplications = (perspective: AppPagePerspective) => {
  const toast = useToastModule();
  const {
    loading: gettingCreatorAppliedCampaigns,
    error: getCreatorAppliedCampaignsError,
    data: getCreatorAppliedCampaignsResult,
    execute: sendGetCreatorAppliedCampaignsRequest,
  } = useAPI('', false);
  const getCreatorAppliedCampaigns = (creatorID: string) => sendGetCreatorAppliedCampaignsRequest({
    url: `/creator/${creatorID}/applied`,
    method: 'GET',
  });
  watch(getCreatorAppliedCampaignsError, toast.handleRequestError);

  return {
    getCreatorAppliedCampaigns,
    getCreatorAppliedCampaignsResult,
    getCreatorAppliedCampaignsError,
    gettingCreatorAppliedCampaigns,
  };
};