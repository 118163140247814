
import { ref, watch } from 'vue';
import ProgressBar from 'primevue/progressbar';
import Button from 'primevue/button';
import CampaignPhoto from '../view/CampaignPhoto.vue';
import ChangeCampaignLogo from '../view/ChangeCampaignLogo.vue';

export default {
  components: {
    Button,
    ProgressBar,
    CampaignPhoto,
    ChangeCampaignLogo,
  },
  props: {
    isProductSteep: Boolean,
    details: Boolean,
    instructions: Boolean,
    budget: Boolean,
    name: String,
    campaignPhoto: String,
    countProgress: Number,
    total: Number,
  },
  setup(props: any, ctx: any) {
    const showModalChangeLogo = ref(false);
    const isActive = ref(0);
    const mainPhoto = ref(props.campaignPhoto);
    const previousPhotos = ref();

    const totalProgressCount = ref(Math.round((100 / (props.total || 1)) * props.countProgress));
    watch(() => props.countProgress, () => {
      totalProgressCount.value = Math.round(((100 / props.total) * props.countProgress));
    });
    const changePhoto = (mainLogos: any) => {
      mainPhoto.value = mainLogos.newLogo;
      previousPhotos.value = mainLogos.allPhotos;
      ctx.emit('changePhotos', mainLogos);
    };
    const closeModalLogo = () => {
      showModalChangeLogo.value = false;
    };

    return {
      closeModalLogo,
      previousPhotos,
      mainPhoto,
      changePhoto,
      totalProgressCount,
      isActive,
      showModalChangeLogo,
    };
  },
};
