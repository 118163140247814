import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39a1b76d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_latest_campaign_item = _resolveComponent("latest-campaign-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", {
      class: "p-d-flex p-justify-end p-mb-6 seeMore",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'campaigns' })))
    }, "See more campaigns >"),
    _createElementVNode("section", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
        return (_openBlock(), _createBlock(_component_latest_campaign_item, {
          key: index,
          item: item,
          class: "p-mb-3"
        }, null, 8, ["item"]))
      }), 128))
    ])
  ]))
}