
import { onMounted, ref } from 'vue';
import { CampaignPaymentMethod } from '@/data/types';
import { formatDate } from '../../utils/globals/date-utils';
import { useAdmins } from '@/modules';
import OldPendingPayments from '@/pages/admin/OldPendingPayments.vue';
import NewPendingPayments from '@/react_app/NewAdmin/NewPendingPayments/NewPendingPayments';
import { applyPureReactInVue } from 'veaury';
import { isNewAdminEnabled } from '@/utils/globals/feature-flags';

export default {
  components: {
    NewPendingPayments: applyPureReactInVue(NewPendingPayments),
    OldPendingPayments,
  },
  setup() {
    const skip = ref(0);
    const limit = ref(10);
    const loadingUpload = ref('');
    const totalCount = ref(0);
    const searchValue = ref('');
    const loading = ref(true);

    const adminModule = useAdmins();

    const getPendingPaymentList = (skip: number, search: string) => {
      loading.value = true;
      adminModule.manager
        .getEarningsForAdmin(limit.value, skip, true, search)
        .then(() => (loading.value = false));
    };
    onMounted(() => {
      getPendingPaymentList(skip.value, searchValue.value);
    });

    const pageChange = (data: { page: number; first: number }) => {
      skip.value = data?.page * limit.value;
      getPendingPaymentList(data.first, searchValue.value);
    };

    const searchPayment = (search: string) => {
      searchValue.value = search;
      skip.value = 0;
      getPendingPaymentList(0, searchValue.value);
    };

    return {
      pageChange,
      formatDate,
      data: adminModule.manager.getEarningsForAdminResult,
      loadingUpload,
      CampaignPaymentMethod,
      totalCount,
      isNewAdminEnabled,
      limit,
      searchPayment,
      loading,
    };
  },
};
