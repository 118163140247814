import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewImageCropper = _resolveComponent("NewImageCropper")!
  const _component_OldCropperImage = _resolveComponent("OldCropperImage")!

  return ($setup.isNewBrandEnabled)
    ? (_openBlock(), _createBlock(_component_NewImageCropper, { key: 0 }))
    : (_openBlock(), _createBlock(_component_OldCropperImage, {
        key: 1,
        image: $props.image,
        showCrop: $props.showCrop,
        userView: $props.userView
      }, null, 8, ["image", "showCrop", "userView"]))
}