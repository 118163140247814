import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewContentItems = _resolveComponent("NewContentItems")!
  const _component_OldContentItems = _resolveComponent("OldContentItems")!

  return (_ctx.isContentItemsPageEnabled)
    ? (_openBlock(), _createBlock(_component_NewContentItems, {
        key: 0,
        contentItems: _ctx.contentItems,
        handleRouting: _ctx.handleRouting,
        user: _ctx.user
      }, null, 8, ["contentItems", "handleRouting", "user"]))
    : (_openBlock(), _createBlock(_component_OldContentItems, {
        key: 1,
        showSearch: _ctx.showSearch,
        private: _ctx.showSearch,
        limit: _ctx.limit
      }, null, 8, ["showSearch", "private", "limit"]))
}