import React from 'react';
import { Image } from "primereact/image";
import './AttachmentsDisplay.css';
import { AttachmentDocumentIcon, AttachmentDownloadIcon } from '@/react_app/img/Icons';

const AttachmentsDisplay = ({ attachments, isEditMode = false }) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  const imageAttachments = attachments.filter(item => imageExtensions.includes(item.name.split('.').pop().toLowerCase()));
  const nonImageAttachments = attachments.filter(item => !imageExtensions.includes(item.name.split('.').pop().toLowerCase()));

  function truncateFileName(fileName, maxLength = 40) {
    if (fileName.length <= maxLength) return fileName;

    const extension = fileName.split('.').pop();
    const nameWithoutExtension = fileName.slice(0, -(extension.length + 1)); // Remove the extension and dot
    const truncatedName = nameWithoutExtension.slice(0, maxLength - (extension.length + 4)) + '...';

    return `${truncatedName}.${extension}`;
  }

  function downloadFile(url, name) {
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="attachmentsDisplay">
      <div className="attachmentsWrapper">
        {imageAttachments.map((item, index) => (
          <Image key={index}
            src={isEditMode ? item.objectURL : item.url}
            alt="attachment image" className="attachmentImage" />
        ))}
      </div>
      <div className="attachmentsWrapper attachmentLinksWrapper">
        {nonImageAttachments.map((item, index) => (
          <span className="attachmentLink" key={index}>
            <AttachmentDocumentIcon />
            <a href="javascript:void(0)" rel="noopener noreferrer" >
              {truncateFileName(item?.name)}
            </a>
            {!isEditMode && <AttachmentDownloadIcon className="download" onClick={() => downloadFile(item.url, item.name)} />}
          </span>
        ))}
      </div>
    </div>
  );
};

export default AttachmentsDisplay;