
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { ref, defineComponent, computed } from 'vue';
import CountriesModal from '@/pages/campaigns/CountriesModal.vue';
import {
  tier1, tier2, tier3, tier4, tierNames,
} from './create/data.campaign';

export default defineComponent({
  components: {
    InputText,
    Dialog,
    CountriesModal,
  },
  props: {
    displayMarketModal:
    Boolean,
  },
  setup(props, ctx) {
    const active = ref(0);
    const tiers = ref([tier1, tier2, tier3, tier4]);
    const tiersCopy = [tier1, tier2, tier3, tier4];
    const names = ref(tierNames);
    const showCountriesModal = ref(false);
    const searchValueMarket = ref('');
    const filterMarkets = () => {
      names.value = [];
      const regExp = new RegExp(searchValueMarket.value, 'gi');
      tiers.value = tiersCopy.filter((tiersList, index) => {
        const idx = tiersList.findIndex((t) => regExp.test(t));
        if (idx < 0) {
          names.value.splice(index, 1);
          return false
        } else {
          names.value.splice(index, 1, tierNames[index]);
          return true;
        }
      });

      if (!searchValueMarket.value) {
        tiers.value = tiersCopy;
      }
    };

    const activeIndex = computed(() => tierNames.findIndex((name) => name === names.value[active.value]));

    const selectTier = () => {
      ctx.emit('marketModal', activeIndex.value);
    };

    return {
      searchValueMarket,
      selectTier,
      filterMarkets,
      active,
      showCountriesModal,
      names,
      tiers,
      activeIndex,
    };
  },
});
