
import { defineComponent, ref } from 'vue';

import AppListPage from '../commons/AppListPage.vue';
import BrandListItem from './BrandListItem.vue';
import EmptyList from '../commons/list/EmptyList.vue';

export default defineComponent({
  name: 'Brands',

  components: { AppListPage, BrandListItem, EmptyList },

  props: {
    brandOwnerView: Boolean,
    showSearch: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const brands = ref([]);

    const updateSearchResults = (list: any) => {
      if (props.limit) {
        brands.value = list.slice(0, props.limit);
      } else {
        brands.value = list;
      }
    };

    return {
      brands,
      updateSearchResults,
    };
  },
});
