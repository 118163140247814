
import { defineComponent } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useAuth } from '@/modules';

export default defineComponent({
  components: {
    Button,
    Dialog,
  },

  props: {
    show: Boolean,
  },
  setup(props, ctx) {
    const { user } = useAuth();
    const closeModal = () => {
      ctx.emit('update:show', false);
      ctx.emit('showCampSearch');
      localStorage.setItem('show-inf-red', 'true');
    };
    return {
      closeModal,
      user,
    };
  },
});
