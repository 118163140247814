import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewContentItemsList = _resolveComponent("NewContentItemsList")!
  const _component_OldContentItemsList = _resolveComponent("OldContentItemsList")!

  return (_ctx.isContentItemsPageEnabled)
    ? (_openBlock(), _createBlock(_component_NewContentItemsList, {
        key: 0,
        items: _ctx.items,
        handleRouting: _ctx.handleRouting,
        user: _ctx.user
      }, null, 8, ["items", "handleRouting", "user"]))
    : (_openBlock(), _createBlock(_component_OldContentItemsList, {
        key: 1,
        items: _ctx.items
      }, null, 8, ["items"]))
}