
import { defineComponent } from 'vue';
import { calculateCpiRate } from '@/utils/globals/cpi-rate';
import { CampaignPaymentMethod } from '../../data/types/campaign-types';

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ['hide'],
  setup(props) {
    return {
      calculateCpiRate,
      CampaignPaymentMethod,
    };
  },
});
