/* eslint-disable vue/max-len */
/* eslint-disable max-len */
import React, { useState } from 'react';
import "./NewTermsOfUse.css";
import logo from "../../NewAuth/img/logo-white.png";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { ScrollPanel } from 'primereact/scrollpanel';
import { SelectButton } from "primereact/selectbutton";

const options = [
  { label: 'Influencer', value: 'influencer' },
  { label: 'Brand', value: 'brand' }
];

const CheckedIcon = () => {
  return (
    <div style={{
      position: "relative", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "9px", marginLeft: "9px"
    }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="17.6" height="17.6" viewBox="0 0 17.6 17.6" fill="none" style={{ position: "absolute", margin: "auto" }}>
        <circle cx="8.4" cy="8.4" r="8" fill="white" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 10 10" fill="none" style={{ position: "absolute", margin: "auto" }}>
        <path d="M8.42471 0.144404C8.68498 0.291169 8.87671 0.536005 8.95772 0.825072C9.03873 1.11414 9.0024 1.42377
                8.85671 1.68587L5.15705 8.33782C5.06335 8.50634 4.93339 8.65162 4.7767 8.763C4.62 8.87439 4.44054 8.94906
                4.25146 8.98155C4.06238 9.01404 3.86847 9.00352 3.68396 8.95077C3.49944 8.89801 3.32898 8.80436 3.18508
                8.67667L0.38124 6.18987C0.157377 5.99125 0.0210464 5.71123 0.00223909 5.41141C-0.0165682 5.11159 0.0836884
                4.81652 0.280954 4.59112C0.478219 4.36573 0.756334 4.22846 1.05412 4.20952C1.3519 4.19059 1.64496 4.29153
                1.86882 4.49015L3.77683 6.18275L6.89342 0.579367C6.96559 0.449567 7.06246 0.335353 7.17847 0.243246C7.29449
                0.151139 7.42739 0.0829428 7.56959 0.0425523C7.71178 0.0021618 7.86049 -0.00963226 8.00721 0.00784359C8.15393
                0.0253194 8.2958 0.071723 8.42471 0.144404Z" fill="#E73D38" />
      </svg>
    </div>
  )
}

const NotCheckedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.6" height="17.6" viewBox="0 0 17.6 17.6" fill="none">
      <circle cx="8.4" cy="8.4" r="8" stroke="#D9D9D9" />
    </svg>
  )
}

export default function (props) {
  const [userType, setUserType] = useState(options[0].value);
  const renderOptionItem = (option) => (
    <div className={'optionItem'}>
      {userType === option.value ? <CheckedIcon /> : <NotCheckedIcon />}
      {option.label}
    </div>
  );

  const handleSelectChange = (e) => {
    if (e.value !== null) {
      setUserType(e.value);
    }
  }

  return (
    <div className={'terms-of-use'}>
      <div className={'content'}>
        <Image src={logo} alt={"logo"} height={'73px'} className={'logo'} />
        <p className={'title'}>Terms of use</p>
        <SelectButton id={"selectButton"} allowEmpty={false} value={userType} onChange={handleSelectChange} options={options} optionLabel="label" itemTemplate={renderOptionItem} />
        <hr className={'horizontalLine'} />
        {userType === options[0].value &&
          <ScrollPanel className={'scrollPanel'}>
            <p className={'textBold'}>GameInfluencer.IO platform Terms of Service – Creator</p>
            <p className={'textBold'}>Definitions</p>
            <p><b>Action</b> - Shall mean (but not exclusively) a click, install, lead, sale, view defined as a payment trigger as set out below</p>
            <p><b>Brands</b> – Refers to entities or persons using GameInfluencer.IO or in contact with Gameinfluencer for creating campaigns</p>
            <p><b>Campaign Brief</b> – Campaign details and documents provided by Brand for clarification of Campaign</p>
            <p><b>Campaign CPI Model</b> – predetermined metric “cost per install” to set the prices paid per conversions</p>
            <p><b>Campaign CPC Model</b> – predetermined metric “cost per click” to set the prices paid per conversions</p>
            <p><b>Campaign CPM Model</b> – predetermined metric “cost per 1000 views” to set the prices paid per conversions</p>
            <p><b>Creators</b> – refer to Content Creators who make use of GameInfluencer.IO or are in contact with Gameinfluencer to advertise product(s),
              service(s) or brand(s) for the benefit of, and/or under an assignment from, the Brands under this Agreement.</p>
            <p><b>Creator Ad Campaign</b> – Marketing Campaign available on GameInfluencer.IO which involves Content Creators</p>
            <p><b>Creator Ad</b> – Content produced following the Brief and in association with a specific Campaign from GameInfluencer.IO</p>
            <p><b>Creators Channel</b> – refers to the Content Creators channel on a Media Platform</p>
            <p><b>Creator Link</b> – The tracking link provided to Content Creator to track performance of a Campaign from GameInfluencer.IO</p>
            <p><b>Content</b> – refers to all content uploaded by the creator to the relevant and agreed channel under this Agreement Posts made on the Creators Media Platforms</p>
            <p><b>Intellectual Property Rights</b> – includes all patents, utility models, rights to inventions, copyright and neighbouring and
              related rights and rights in other subject matter, trade-marks and service marks, business names and domain names, rights in get-up and trade dress, goodwill
              and the right to sue for passing off or unfair competition, rights in designs, database rights, rights to use, and protect the confidentiality of, confidential
              information (including know-how and trade secrets), and all other intellectual property rights, in each case whether registered or unregistered and including all
              applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority, in relation to such rights, and rights to enforce and
              seek remedies and redress for the infringement of violation of any such rights, past, present, or future, and all similar or equivalent rights or forms of protection
              which subsist or will subsist now or in the future in any part of the world;</p>
            <p><b>License Request</b> – A feature of GameInfluencer.IO in which brands can request to purchase the license rights to a Creator Ad, after the completion of the campaign.</p>
            <p><b>Media Platform</b> – refers to social media platforms, including but not limited to, YouTube, Instagram and Tik Tok</p>
            <p><b>Paid Content</b> - refers to Posts made on the Creators channel for a specific Campaign that they have accepted on GameInfluencer.IO</p>
            <p><b>Posts</b> – refers to a Media Platform post to share Content with their community.</p>
            <p><b>Real Installs</b> - A Real Install means only a real individual person that actively accesses the application via a Creator Link and then proceeds to
              install and launch the campaign promoted application while on the Internet. This only applies for a real individual person and cannot be a robot script or computer generated and
              can also not be identified as fraudulent or a duplicate by GameInfluencer.IO. A Real Install also means that is has come from the targeted tier specified in the relevant Campaign (e.g. Tier 1 countries).</p>
            <p><b>Third-Party Services</b> – refers to any piece of Hardware or Software necessary to run GameInfluencer.IO</p>
            <p><b>Third-party Tracking Data Providers</b> – refers to Attribution data partner companies such as but is not limited to companies such as Apps Flyer, Tune, Adjust.</p>
            <p><b>Tracking Link</b> – refers to a link that is created on the platform for Creators to share alongside their Ads which allows for the performance to be tracked.</p><br />
            <p><b>Introduction.</b> The GameInfluencer.IO platform is operated and owned by GameInfluencer. By using GameInfluencer.IO Platform, you decide to accept these GameInfluencer.IO Platform Terms of Use (“Terms of Use”).
              GameInfluencer created this platform to bring together Brands and Creators, allowing Brands to acquire Content for their marketing campaigns, and for Creators to sell Paid Content, generating and sharing
              sponsored Posts through the Creators Channels (YouTube) with support from GameInfluencer to help provide control and direction in alignment with these Terms if Use. In these Terms of Use, Creators and Brands
              are collectively referred to as “you” depending on the context. The GameInfluencer.IO Platform allows Brands to create Creator Ad Campaigns via the which are then distributed to our network of Creators and displayed on the
              GameInfluencer.IO Platform. Creators can choose to participate and accept the Creator Ad Campaign based on information& terms outlined in the Brief and Terms of Service. A Creator will earn, and a Brand will pay Creators when:</p>
            <ul>
              <li className={'textNormal'}>in relation to a Creator Ad Campaign, when the Creator Ad is published to the Creator’s Community, such as viewers and fans, via their Channels, as selected; and starts generating conversions
                via their Designated Tracking Link.</li>
              <li className={'textNormal'}>in relation to a Creator Ad Campaign, the Brand requests and acquires rights to use one of the designated campaign Creator Ad in additional marketing channels as further clarified in the
                correlating License Request post campaign.</li>
            </ul>
            <p>At GameInfluencer.IO it’s important for us to ensure all Posts are transparent and have the consumers best interest at heart. Put simply, all Posts acquired through GameInfluencer.IO should be transparent and have
              labeled disclosure such as #ad or any other guidelines set by the relevant Media Platform. Brands and Creators making use of GameInfluencer.IO are expected to stay up to date themselves regarding the latest best practices
              regarding the labeling of ads in Creator’s marketing Posts so that the consumers best interest is kept at heart. Making use of GameInfluencer.IO users comply with all applicable laws and industry self-regulation relating to
              influencer marketing and advertising, such as all applicable laws and regulations, including without limitation guidelines and laws relevant to promotional content. Creator will follow all FTC and other guidelines
              and laws relevant to promotional content. GameInfluencer.IO uses YouTube API Services to gain insight on video performance and the channels audience to provide Creators with relevant Campaigns. By making use of GameInfluencer.IO and uploading
              relevant Content on YouTube Creators agree and are bound to the YouTube Terms of Service and Google Privacy Policy, as of the latest updates. If the user fails to comply with any of the Platform guidelines and laws
              previously mentioned, the user can instantly be terminated from the GameInfluencer.IO platform. The use of GameInfluencer.IO is only upon your agreement to accept these Terms of Use. Every time you make use of GameInfluencer.IO you are bound and in agreement
              with these Terms of Use. If you don’t desire to be bound to these Terms of Use, don’t use GameInfluencer.IO.</p>
            <p>Only legal entities as well as natural persons as entrepreneurs of unlimited legal capacity may become party of the Agreement. GameInfluencer reserves the right to demand supporting documents
              from the Brand to prove its legal status and in case of an employee of the Brand and / or an agency registering with the GameInfluencer Network and / or concluding the Agreement, to demand a written authorization.</p>
            <p>For further transparency, you are required to accept these Terms of Use as part of the application process by clicking the “I agree to the Terms of Use” box. Both when using the GameInfluencer.IO platform
              in any forms possible as well as accepting the Terms of Service when registering, a legally binding contract is created between GameInfluencer.IO and you the Creator.</p>
            <p><b>Registration requirements.</b> To register an account to use GameInfluencer.IO as a Creator (“Creator Account”), an individual must sign-in to the platform with their YouTube account, as specified on the
              platform and these Terms of Use. For signing up to the GameInfluencer.IO platform as well as when applying to campaigns, Creator’s must provide factual and clear information as part of the registration process, to ensure rightful
              ownership of Content as well as for Payment and Tax Filing purposes. Creators must also ensure they are eligible for payout through our payment Partner GigaPay; find their terms and conditions here. In order to be
              able to accept campaigns and receive monetary compensation you are required to connect your YouTube account. By doing so, you are granting GameInfluencer.IO permission to access historical account activity, usage data and audience
              insights, in compliance with these Terms of Use.</p>
            <p>Any person aged 16+ years, or 12+ years with the written consent of a parent or legal guardian, may register an account on GameInfluencer.IO, but in must meet the following requirements to be
              rewarded compensation:</p>
            <ul className={'textNormal'}>
              <li>You must be of legal age to use the relevant social Media Platform and the relevant platforms prevailing terms of conditions.</li>
              <li>You must accept and participate in campaigns and receive monetary compensation.</li>
              <li>Your channel cannot feature any Content that is contrary to these terms of use.</li>
              <li>Your channel must be grown organically, it is forbidden to misrepresent sub counts, views, or audience data. Channels who acquired their followers through unethical behavior such as (but not limited to) purchasing
                of followers, fabricating subscribers or any other engagement metric displayed on YouTube can be removed from GameInfluencer.IO platform instantaneously without prior required notice.</li>
              <li>The content you release on this channel must be original and created by you, not recycled or reposted work of any other creator or entity.</li>
            </ul>
            <p><b>Creator & Content Requirements.</b> You are personally responsible for reviewing the campaign briefs and campaign information to ensure you are eligible to accept the relevant campaigns.
              All Content you upload or submit on GameInfluencer.IO must comply with the requirements outlined in the Terms of Use and any additional requirements outlined by GameInfluencer.IO or the Brand for the relevant campaign submission. GameInfluencer.IO and
              GameInfluencer have absolute discretion to remove from the platform any Content submission they choose whether or not they have been submitted, reviewed, or approved by the Brand. You agree GameInfluencer and GameInfluencer.IO
              can review and moderate any piece of Content you upload on GameInfluencer.IO and give any necessary input and feedback.</p>
            <p>CPC and CPI campaigns do not typically require a draft and approval process, once a campaign has been accepted, content can be created, and conversions earned. CPM campaigns require the submission
              of a draft video, which may be refused with feedback to try again, or without.</p>
            <p>You agree to disclose sponsored ad Content and your relationship with the relevant Brand in an obvious and transparent manner to every day viewers of your channel and as stipulated in the
              requirements of your relevant social Media Platform such as, but no limited to, YouTube. GameInfluencer.IO will scan and review relevant uploads on your channel and historic upload behavior in regards to transparency disclosure
              and if required because of a lack of transparency, remove you from the GameInfluencer.IO platform.</p>
            <p>For every upload on GameInfluencer.IO you guarantee that:</p>
            <ul className={'textNormal'}>
              <li>You are aged 16+ or aged 12+ with parental consent.</li>
              <li>If you are aged 16+ and your Content features children aged younger than 17 years, you are their parent or legal guardian.</li>
              <li>If you are aged between 12 and 16, your Content shouldn’t feature other kids around your age.</li>
            </ul>
            <p>The submitted Content and uploaded Content for Campaigns shall remain live on your channel for a minimum of 180 days unless otherwise stipulated.</p>
            <p>Basic Dos and Don’ts for creating content that are to be followed unless specifically mentioned in the brief:</p>
            <p className={'textBold'}>Dos</p>
            <ul className={'textNormal'}>
              <li>Please make sure that the integration fits in with the rest of the video</li>
              <li>Content Structure – Please be creative while meeting format requirements</li>
              <li>Content – Keep it fun, exciting, entertaining, and informative</li>
              <li>Visual Quality – HD 1080p 30fps or above without pixelated footage</li>
              <li>Sound Quality – Clear voice with no background noise</li>
              <li>Editing Quality – Clean and enjoyable to watch</li>
              <li>Title – Make it as intriguing as possible</li>
              <li>Thumbnail – Make it eye-catching and look positive</li>
              <li>Script – Speak naturally. DO NOT read from the text on camera</li>
              <li>Please show your face if you usually do so in your past videos</li>
            </ul>
            <p className={'textBold'}>Don'ts</p>
            <ul className={'textNormal'}>
              <li>
                Do not make negative or derogatory comments about the promoted product
                and any brands associated with it in any of the content items released
                for this campaign
              </li>
              <li>
                Do not exploit or talk negatively on other brands such as competitors
                to the promoted product
              </li>
              <li>
                Please do not compare or allude to the in-game characters/creatures to
                people/creatures in real life or in other works.
              </li>
              <li>Please do not include violence, sex, politics related content.</li>
              <li>Please do not swear, drink, smoke, or do drugs during content.</li>
              <li>
                Please do not say words like “now the advertisement/integration
                begins” at the beginning of the video.
              </li>
            </ul>
            <p>
              In case of any breach of this Terms of Service or any guidelines
              provided by the GameInfluencer.IO platform could result in immediate termination of
              the agreement, any form of monetary compensation or remuneration as
              well potential immediate removal of published Content. In case any
              product was sent to the Creator as part of the campaign, it shall be
              returned to GameInfluencer incase it’s in connection with the
              Campaign. In case of breach of agreement, Creator understands that he
              could be liable to cover the damages incurred by GameInfluencer.IO & GameInfluencer
              including but not limited to damages to goodwill.
            </p>
            <p>
              Creator is only eligible for payout once they have ensured they have
              connected with our payment Partner GigaPay and have posted the
              relevant content for the campaign correctly. Alongside the relevant
              Content created by the Creator and published on their YouTube channel,
              the Creator agrees to include in the relevant description the
              potential Brand & GameInfluencer.IO provided Creator Link. This Creator Link will
              link to a relevant campaign promotion and is often, but not limited
              to, mobile store, website, product promotional page. Alongside the
              Creator Link, the Creator is expected to follow the provided
              guidelines for the sentence structuring around the link. When a
              Creator Link is provided it must be included and ensured that it’s the
              correct Creator Link provided by GameInfluencer.IO. It should be present in the
              Content in the beginning of the video’s description or any other place
              if required.
            </p>
            <p>
              The Brands & Creators understands that GameInfluencer.IO is in constant development
              and improvement and some of the platform will change over time. Brands
              & Creators have the right to use the platform through the registration
              process. Both Creators and Brands understand that depending on the
              market development, GameInfluencer.IO might be terminated or temporarily out of
              service. GameInfluencer.IO can be tweaked and improved whenever desired even if it
              potentially affects layout or current services, and can be done
              without requesting any consent from Creators or Brands. GameInfluencer
              shall notify the Brand, as far as plannable, with a period of 48 hours
              in advance of such technical measures, if these have an substantial
              impact on the GameInfluencer services rendered to Brand. Besides the
              key points outlined in the agreement, the entire platform is “as is”
              and doesn’t come with any warranties. The Brands & Creators are
              expected to use the platform as described and in good faith, any
              illegal or unlawful activities are forbidden. GameInfluencer.IO can deny any
              Creator or Brand access to GameInfluencer.IO without written notice and prior
              consent if GameInfluencer.IO & Gameinfluencer notice the Brand is harming the
              platform or affecting its useability in any form possible, including
              but not limited to, hacking and malware. Brands & Creators are
              responsible for keeping their login information confidential. In case
              any unrightful user acquires the login details, the Brand or Creator
              should inform GameInfluencer.IO & Gameinfluencer immediately as any changes made by
              the unrightful user in GameInfluencer.IO are sole liability of Brand. For Brands &
              Creators, any Third-Party Services, hardware or software necessary to
              successfully use GameInfluencer.IO are their sole responsibility, such as but not
              limited to computers, phones, Third-party Tracking Data Providers. In
              no way shape or form are GameInfluencer.IO & Gameinfluencer liable for the affected
              performance of GameInfluencer.IO based on these Third-Party Services, hardware or
              software performances. This agreement overrides all existing
              agreements, understandings or communications between the Brand and
              GameInfluencer.IO (and Gameinfluencer team) in regards to the topics covered in the
              Agreement and makes up the entire agreement between the Brand and the
              GameInfluencer.IO (and Gameinfluencer team).
            </p>
            <p>
              <b>Relevant Data & personal information.</b> GameInfluencer.IO collects
              basic personal data as filled out in the application process and
              payment section of the platform. Such data includes, but is not
              limited to, examples such as Channel name, contact information,
              payment details, and Photo’s. Creator’s and Platform accept that GameInfluencer.IO
              acquires tracking data, usage data, and other technical data relating
              to the relevant Content. GameInfluencer.IO requires to analyze the nature of the
              Content on the Creator’s Channel to provide them the most accurate
              Campaign opportunities to their Channel’s Content.
            </p>
            <p>
              <b>Intellectual property rights.</b> You own all the
              Intellectual Property Rights to your GameInfluencer.IO uploaded Content and all the
              necessary licensing to register the social media as well as license
              the Content to GameInfluencer.IO and the Brand. You have all the rights to sell the
              Content to GameInfluencer.IO for use of GameInfluencer.IO, the Brand or any other third party
              (as applicable), in the manner set out in these Terms of Use. All
              opinions expressed in the Content are entirely your own and your
              Content does not contain any misrepresentation that you or any entity
              has received approval or a potential sponsorship of any other entity
              which isn’t the case.
            </p>
            <p>
              All points made about a Brand in the Content posted for a Campaign are
              true and genuine representative of your opinion. You accurately state
              your use and experience and incase anything changes you actively reach
              out GameInfluencer.IO in a timely manner to notify them in case any views stated in
              your Content have changed. Besides any Third-Party Material mentioned
              and included in the Brand’s Campaign, the Content is entirely unique
              having not been previously broadcasted, streamed, published, posted or
              communicated to the public by you. If you do add Third Party Material
              you have all the required licenses, consents, and permissions to add
              them into your Content and for any third party such as GameInfluencer.IO,
              GameInfluencer, or the relevant Brand to use your Content as clarified
              in these Terms of Use. You are also required to fully disclose and use
              or license limitations to GameInfluencer.IO, GameInfluencer or the relevant Brand.
              In case you are a part of any guild or union, you ensure that you have
              not entered any agreement arrangement or understanding which would
              limit you from following these Terms of Use in relation to your
              submitted Content on GameInfluencer.IO.
            </p>
            <p>
              If the Content contains images or references to third parties or third
              party property outside of the material provided by the Brand for the
              campaign, you have written agreement that those images, property and
              references may be included into the Content and used by GameInfluencer.IO,
              GameInfluencer, the Brand or any third party in accordance with these
              Terms of Use without remuneration or compensation to the third party
              to promote GameInfluencer.IO, the Brand or any other third party in any media and
              in perpetuity and neither you, GameInfluencer.IO nor the Brand needs to obtain any
              licenses or pay any royalties to any third party regarding the
              relevant Content. The use of the GameInfluencer.IO submitted Content won’t infringe
              any legal rights, copyright or other Intellectual Property Rights of
              any entity or person or any form of liability to pay compensation
              pertaining to the use by the Brand, GameInfluencer or GameInfluencer.IO.
            </p>
            <p>
              When it comes to Campaign submissions, all right, title, interest and
              Intellectual Property Rights in the Content will remain held by you.
              These Terms of Use don’t constitute a transfer or grant any ownership
              rights in the Content to any other party, including GameInfluencer.IO,
              GameInfluencer and the Brand. In respect to the Content submitted on
              the GameInfluencer.IO platform for application or posted as part of a Campaign,
              grants GameInfluencer.IO & GameInfluencer (and its agents) the right to edit,
              re-format the Content into such formats or versions for use by GameInfluencer.IO
              for such media as GameInfluencer.IO requires to conduct the relevant Campaign.
            </p>
            <p>
              In exchange for the Campaign related payment, you grant in relation to
              every Content uploaded for application or published on your YouTube
              Channel as part of a Campaign:
            </p>
            <p>
              For GameInfluencer.IO and its agents a royalty-free, perpetual, worldwide,
              irrevocable, unconditional, non-exclusive, transferable,
              sub-licensable license to use the Post for the purpose of marketing
              and promoting GameInfluencer.IO (and its products and services) in any manner,
              without further notification to or consent of you or any further
              compensation payable to you. Aswell as the right to use the Creator’s
              Identity and performances in the Content to communicate the Content to
              the public in all languages, in all media such as online paid media
              banners, in all online owned media such as website, and in all owned
              social media, and in any earned media or public relations activity
              published by a third party.
            </p>
            <p>
              For the relevant Brand and its agents, the right to organically share
              and comment on the Content. The right to use the Creator’s Identity
              and performances to communicate the Content to the public in
              accordance with the Terms of Service.
            </p>
            <p>
              Regarding the Content uploaded to GameInfluencer.IO you consent to the relevant
              Brand and its successors, and anyone authorized by them, changing,
              copying, adding to, taking from, translating notwithstanding that such
              conduct may infringe your Moral Rights in the Content and to the
              extent possible, you grant a waiver of all Moral Rights for each
              Content submitted via GameInfluencer.IO.
            </p>
            <p>
              In regard to the “Additional Licensing request” after completion of
              Campaign, where Brands might request additional license from the
              Creator regarding the Content present in the Campaign. Depended on the
              filled out Licensing terms and after the Creator’s decision to accept
              the Additional License and additional monetary compensation, The brand
              will have an additional License to promote the Creator’s Content in
              its own marketing efforts such as, but not limited to, the right to
              use the Creator’s Identity and performances in the Content to
              communicate the Content to the public in all languages, in all media
              such as online paid media banners, in all online owned media such as
              website, and in all owned social media, and in any earned media or
              public relations activity published by a third party.
            </p>
            <p>
              <b>Confidentiality.</b> Throughout the duration of this
              Agreement, it may be necessary for the relevant Parties (Creator,
              Brand, GameInfluencer & GameInfluencer.IO) to have access to each other’s
              confidential and protected information for the sole purpose of
              performing the Services subject to this Agreement. None of the Parties
              permitted to share or disclose such confidential information
              whatsoever, unless mandated by law, without written permission from
              the relevant Party. All the obligations of confidentiality will
              survive the termination of the Agreement and stay in place 6 years
              after completion of the relevant Campaign. Upon the termination of the
              Agreement, the Parties involved agree to return any and all
              Confidential Information that is the property of the other Party upon
              written request. They shall promptly return all copies, whether in
              written, electronic, or other form or media, of the Party’s
              Confidential Information, or destroy all such copies and certify in
              writing to the Party that such Confidential Information has been
              destroyed.
            </p>
            <p>
              This confidentiality only applies to Content that is private and does
              not take into consideration information that is public, lawfully
              acquired through third party, or already in possession of the same
              information before the confidentially agreement.
            </p>
            <p>
              <b>Force Majeure.</b> Any of the relevant parties shall not
              be in breach of this Agreement for any delay or failure in performance
              caused by reasons out of its reasonable control. This includes, but is
              not limited to, acts of God or a public enemy; natural calamities;
              failure of a third party to perform; changes in the laws or
              regulations; actions of any civil, military, or regulatory authority;
              power outage or other disruptions of communication methods or any
              other cause which would be out of the reasonable control of the
              relevant party.
            </p>
            <p>
              <b>Monetary compensation for Creators.</b> GameInfluencer.IO will pay the
              Creator after having received the campaign funds from the relevant
              Brand. The payment will be made on a Monthly basis (30 days) and are
              based on the relevant Campaign’s payment Model. If the Campaign’s
              payment model is ‘CPM’ then the amount earned from this campaign will
              activate in the following month, so payment will come in the following
              payment cycle (up to 60 days). Creator is only eligible for payout
              once they have posted the relevant content for the campaign correctly.
              Creators must also ensure they are eligible for payout through our
              payment Partner GigaPay.
            </p>
            <p>
              Meaning a Creator will be paid for
              <b>Real Conversions</b> generated through their Creator Link
              for said Campaign. These conversions can be in the form of a Real
              Install, a Real Click, or a Real View. A
              <b>Real Install</b> means only a real individual person that
              actively accesses the application via the third-party distribution
              service such as iOS app store or Google play store, and who accesses
              the distribution service through the Creator’s designated Creator Link
              for the Campaign, and then proceeds to install and launch the Campaign
              promoted application while on the Internet. A
              <b>Real Click</b> means only a real individual person that
              actively accesses the distribution service through the Creator’s
              designated Creator Link for the Campaign. A
              <b>Real View</b> means only a real individual person that
              actively views the creators video content through their own free will.
            </p>
            <p>
              This only applies for a real individual person and cannot be a robot
              script or computer generated and can also not be identified as
              fraudulent or a duplicate by GameInfluencer.IO, GameInfluencer or the Brand on a
              later date. A Real Conversion also means that it has come from the
              targeted tier specified in the relevant Campaign (e.g. Tier 1
              countries).
              <b>Payments will be made for as long as the Campaign runs or until
                sudden shortened closure of relevant Campaign. Once the campaign has
                finished running, the budget is complete, or the campaign is closed
                - payments for conversions will end.</b>
              Any conversions that are already paid and later identified as a
              non-Real Conversions will get deducted from the Creators account
              before subsequent payments are made. Any identified fraud ways of
              acquiring the conversions can lead to the immediate termination of the
              Creators account and participation in Campaigns and the Creator will
              not receive any unpaid Monthly Payments on the Creators account.
              Creators will need to reach a minimum of $100 earned per 30-day cycle
              to activate their payout; if the payout limit is not reached then this
              amount will carry over to the following month and will accumulate.
              Creators will be liable and responsible for their own Tax & Government
              requirements as pertaining to the Monetary Compensation received
              through participation of Campaigns and the GameInfluencer.IO platform. GameInfluencer.IO &
              GameInfluencer can choose to, but are not required, to declare the
              Monetary Compensation paid to Creators. Payments will be made through
              our payment Partner GigaPay; find their terms and conditions
              <a target={"_blank"} href="https://www.gigapay.co/resources/t-and-c/terms-and-conditions"> here.</a>
            </p>
            <p>
              <b>Limitation of liability.</b> The GameInfluencer.IO Platform &
              GameInfluencer are in no way liable for uncorrelated, incidental,
              indirect, special, or exemplary damages. Naturally this also includes,
              but is not limited to, any incident that cause a direct decrease of
              business opportunities or even potential revenue impact even if they
              are foreseeable and we have been advised of the potential
              consequences. The only exemption to this case is intentional
              misconduct or gross negligence or any potential breaches regarding
              intellectual Property and confidentiality. We do not restrict our
              liability beyond what is legal by law. The total aggregate liability
              is preceding up to 6 months of damages have been claimed to happen and
              are limited to campaign fees paid or payable by GameInfluencer.IO & GameInfluencer.
              The GameInfluencer.IO Platform & GameInfluencer are in no way liable for
              uncorrelated, incidental, indirect, special, or exemplary damages.
              Naturally this also includes, but is not limited to, any incident that
              cause a direct decrease of business opportunities or even potential
              revenue impact even if they are foreseeable and we have been advised
              of the potential consequences. Creator’s will be liable and won’t hold
              any other party accountable or liable for damages, liabilities, costs
              in relation to a third-party claim regarding Creator provided Content
              or the use of the services and Content in violation of this agreement.
              GameInfluencer shall not be liable for the Content on Creator Ads, for
              damages or other disturbances caused by defects and / or
              interoperability of Creator Ads and / or for damages resulting from
              the insufficient availability or imperfect functioning of the internet
              and / or the app stores. The only exemption to this case is
              intentional misconduct or gross negligence or any potential breaches
              regarding intellectual Property and confidentiality. We do not
              restrict our liability beyond what is legal by law.
            </p>
            <p>
              <b>Termination.</b> This agreement has a termination period
              of 1 month and can be terminated by any of the relevant Parties upon
              written notice. The written notice should be given in case either
              party breaches the preceding agreement. If the other party does not
              fix the breach of the agreement within 12 days of receiving written
              notice, the agreement will be terminated. Regarding any potential
              liability that was formed prior to the termination of the agreement,
              it will still hold. Also, regarding Influencer uploaded Content that
              was submitted on GameInfluencer.IO before the termination, all the relevant
              licenses and granted rights that were given to GameInfluencer.IO, GameInfluencer,
              and Brand will also hold regardless of the termination. Finally, when
              the agreement is terminated, all rights and licenses that were given
              to the Influencer will terminate immediately.
            </p>
            <p>
              <b>Promotion.</b> For the nature of the platform, The
              Influencer agrees that GameInfluencer.IO & Gameinfluencer have the right to use
              channel name, likeness, and biography for promoting the Influencer to
              other Brands as part of the GameInfluencer.IO’s services.
            </p>
            <p>
              <b>Severability.</b> If any term or provision of this
              Agreement is invalid, illegal, or unenforceable in any jurisdiction,
              such invalidity, illegality, or unenforceability shall not affect any
              other term or provision of this Agreement or invalidate or render
              unenforceable such term or provision in any other jurisdiction.
            </p>
            <p>
              <b>Independent Contractor.</b> GameInfluencer.IO, Gameinfluencer, Brand
              and Influencer expressly agree and understand that the above-listed
              Influencer is a contractor hired by GameInfluencer.IO and nothing in this Agreement
              shall be construed in any way or manner, to create between them a
              relationship of employer and employee. The Influencer is not entitled
              to receive any other compensation or any benefits from GameInfluencer.IO,
              Gameinfluencer and Brand. Except as otherwise required by law, the
              parties shall not withhold any sums or payments made to the Influencer
              for social security or other federal, state, or local tax liabilities
              or contributions, and all withholdings, liabilities, and contributions
              shall be solely the Influencer’s responsibility. The Influencer
              further understands and agrees that the Services are not covered under
              the unemployment compensation laws and are not intended to be covered
              by workers’ compensation laws. GameInfluencer.IO and Gameinfluencer are solely
              responsible for directing and controlling the performance of the
              Services, including the time, place, and manner in which the Services
              are performed. The Influencer shall use its best efforts, energy, and
              skill in its own name and in such manner as it sees fit.
            </p>
            <p>
              <b>Platform Protection.</b> Users aren’t allowed to extract
              data, copy, or reverse engineer any of GameInfluencer.IO platform source code, or
              damage & disrupt GameInfluencer.IO in any manner possible throughout multiple
              universes and in perpetuity. Any violation of the agreement terms and
              usage will be grounds for a lawsuit.
            </p>
            <p>
              <b>Assigned Rights.</b> Gameinfluencer & GameInfluencer.IO have all the
              right to assign rights and obligations entirely to a third-party
              business acquirer or partner without prior consent from Influencer.
              The Influencer isn’t allowed to delegate or assign any of the rights &
              obligations outlined in the agreement to a third-party due to the
              nature of the agreement requiring the Influencer’s personal likeness.
            </p>
            <p>
              <b>Dispute Resolution and Governing Law.</b> Parties to this
              Agreement shall first attempt to settle any dispute through good-faith
              negotiation. If the dispute cannot be settled between the parties via
              negotiation, either party may initiate mediation or binding
              arbitration in German Law. Any dispute should be aimed to solve
              through negotiations, however if the parties cannot solve the dispute
              through negotiations within 69 days, the case can be taken to court.
              All disputes arising in connection with this agreement or even parts
              thereof or its validity shall be finally settled according to the
              Arbitration Rules and the Supplementary Rules for Expedited
              Proceedings of the German Institution of Arbitration e.V. (DIS)
              without recourse to the ordinary courts of law. The place of
              arbitration is Munich. The substantive law is German law. The language
              is German or, by discretion of the judge, English. Only one judge
              shall oversee the dispute, which shall be appointed by the DIS. The
              Parties shall have the right to challenge the judge if they find him
              not to be materially familiar with the issues and/ or the industry in
              question. The Terms of Use and their interpretation shall be governed
              by the laws of Germany with the exception of the UN Convention on
              Contracts for the International Sale of Goods dated 11.4.1980.
            </p>
            <p>
              <b>Legal Fees.</b> Should a dispute between the named
              Parties arise lead to legal action, the prevailing Party shall be
              entitled to any reasonable legal fees, including, but not limited to
              attorneys’ fees.
            </p><br />
          </ScrollPanel>
        }
        {userType === options[1].value &&
          <ScrollPanel className={'scrollPanel'}>
            <p className={'textBold'}>GameInfluencer.IO platform Terms of Service - Brand</p>
            <p className={'textBold'}>Definitions</p>
            <p><b>Action</b> - Shall mean (but not exclusively) a click, install, lead, sale, view defined
              as a payment trigger as set out below</p>
            <p><b>Allocated Budget </b> - Portion of the campaign budget already put to the side to pay out Creators based on tracked
              performance conversions thus far.</p>
            <p><b>Brands</b> - Refers to entities or persons using GameInfluencer.IO or in contact with Gameinfluencer for creating campaigns</p>
            <p><b>Campaign Brief</b> - Campaign details and documents provided by Brand for clarification of Campaign</p>
            <p><b>Campaign CPI Model</b> - predetermined metric “cost per install” to set the prices paid per conversions</p>
            <p><b>Creators</b> - Refer to Content Creators who make use of GameInfluencer.IO or are in contact with Gameinfluencer.</p>
            <p><b>Creator Ad Campaign</b> - Marketing Campaign available on GameInfluencer.IO which involves Content Creators</p>
            <p><b>Creator Ad</b> - Content produced following the Brief and in association with a specific Campaign from GameInfluencer.IO</p>
            <p><b>Creators Channel</b> - Refers to the Content Creators channel on a Media Platform</p>
            <p><b>Creator Link</b> - The tracking link provided to Content Creator to track performance of a Campaign from GameInfluencer.IO</p>
            <p><b>Content</b> - Refers to Posts made on the Creators Media Platforms</p>
            <p><b>Intellectual Property Rights</b> - includes all patents, utility models, rights to inventions, copyright and
              neighbouring and related rights and rights in other subject matter, trade-marks and service marks, business
              names and domain names, rights in get-up and trade dress, goodwill and the right to sue for passing off or
              unfair competition, rights in designs, database rights, rights to use, and protect the confidentiality of,
              confidential information (including know-how and trade secrets), and all other intellectual property rights,
              in each case whether registered or unregistered and including all applications and rights to apply for and
              be granted, renewals or extensions of, and rights to claim priority, in relation to such rights, and rights
              to enforce and seek remedies and redress for the infringement of violation of any such rights, past, present,
              or future, and all similar or equivalent rights or forms of protection which subsist or will subsist now or in
              the future in any part of the world;</p>
            <p><b>License Request</b> - A feature of GameInfluencer.IO in which brands can request to purchase the license
              rights to a Creator Ad, after the completion of the campaign.</p>
            <p><b>Media Platform</b> - Refers to social media platforms, including but not limited to, YouTube, Instagram and Tik Tok</p>
            <p><b>Paid Content</b>- Refers to Posts made on the Creators channel for a specific Campaign that they have accepted on GameInfluencer.IO</p>
            <p><b>Posts</b> - Refers to a Media Platform post to share Content with their community.</p>
            <p><b>Real Installs</b> - A Real Install means only a real individual person that actively accesses the application via
              a Creator Link and then proceeds to install and launch the campaign promoted application while on the internet.
              This only applies for a real individual person and cannot be a robot script or computer generated and can also
              not be identified as fraudulent or a duplicate by GameInfluencer.IO</p>
            <p><b>Third-Party Services</b> - Refers to any piece of Hardware or Software necessary to run GameInfluencer.IO
            </p>
            <p><b>Third-party Tracking Data Providers</b> - Refers to Attribution data partner companies such as but is not limited
              to companies such as Apps Flyer, Tune, Adjust.</p>
            <p><b>Tracking Link</b> - Refers to a link that is created on the platform for Creators to share alongside their Ads
              which allows for the performance to be tracked.</p>
            <p><b>Introduction.</b>The GameInfluencer.IO platform is operated and owned by GameInfluencer. By using GameInfluencer.IO
              Platform, you decide to accept these GameInfluencer.IO Platform Terms of Use (“Terms of Use”).</p>
            <p>GameInfluencer created this platform to bring together Brands and Creators, allowing Brands to acquire Content for
              their marketing campaigns, and for Creators to sell Paid Content, generating and sharing sponsored Posts through
              the Creators Channels (YouTube) with support from GameInfluencer to help provide control and direction in alignment with these Terms if Use. In these Terms of Use,
              Creators and Brands are collectively referred to as “you” depending on the context.</p>
            <p>The GameInfluencer.IO Platform allows Brands to create Creator Ad Campaigns via the which are then distributed to our
              network of Creators and displayed on the GameInfluencer.IO Platform. Creators can choose to participate and accept the Creator
              Ad Campaign based on information& terms outlined in the Brief and Terms of Service. A Creator will earn, and
              a Brand will pay Creators when:</p>
            <p><b>(a)</b> in relation to a Creator Ad Campaign, when the Creator Ad is published to the Creator’s Community, such as
              viewers and fans, via their Channels, as selected; and starts generating conversions via their Designated Tracking Link</p>
            <p><b>(b)</b> in relation to a Creator Ad Campaign, the Brand requests and acquires rights to use one of the designated
              campaign Creator Ad in additional marketing channels as further clarified in the correlating License Request post campaign.</p>
            <p>At GameInfluencer.IO it’s important for us to ensure all Posts are transparent and have the consumers best interest at heart.
              Put simply, all Posts acquired through GameInfluencer.IO should be transparent and have labeled disclosure such as #ad or
              any other guidelines set by the relevant Media Platform. Brands and Creators making use of GameInfluencer.IO are expected
              to stay up to date themselves regarding the latest best practices regarding the labeling of ads in Creator’s
              marketing Posts so that the consumers best interest is kept at heart. Making use of GameInfluencer.IO users comply with
              all applicable laws and industry self-regulation relating to influencer marketing and advertising, such as
              all applicable laws and regulations, including without limitation guidelines and laws relevant to promotional
              content. Creator will follow all FTC and other guidelines and laws relevant to promotional content. GameInfluencer.IO uses
              YouTube API Services to gain insight on video performance and the channels audience to provide Creators with
              relevant Campaigns. By making use of GameInfluencer.IO and uploading relevant Content on YouTube Creators agree and are bound
              to the YouTube Terms of Service and Google Privacy Policy, as of the latest updates. If the user fails to comply
              with any of the Platform guidelines and laws previously mentioned, the user can instantly be terminated from
              the GameInfluencer.IO platform.</p>
            <p>The use of GameInfluencer.IO is only upon your agreement to accept these Terms of Use. Every time you make use of GameInfluencer.IO you
              are bound and in agreement with these Terms of Use. If you don’t desire to be bound to these Terms of Use,
              don’t use GameInfluencer.IO.</p>
            <p>Only legal entities as well as natural persons as entrepreneurs of unlimited legal capacity may become party
              of the Agreement. GameInfluencer reserves the right to demand supporting documents from the Brand to prove its
              legal status and in case of an employee of the Brand and / or an agency registering with the GameInfluencer
              Network and / or concluding the Agreement, to demand a written authorization</p>
            <p>For further transparency, you are required to accept these Terms of Use as part of the application process by
              clicking the “I agree to the Terms of Use” box. Both when using the GameInfluencer.IO platform in any forms possible as well
              as accepting the Terms of Service when registering, a legally binding contract is created between GameInfluencer.IO and you
              the Brand.</p>
            <p><b>Registration requirements.</b>To register an account to use GameInfluencer.IO as a Brand an individual must
              sign in to the platform with their google account or create a new account as specified on the platform and
              these
              Terms of Use. For signing up to the GameInfluencer.IO platform as well as when creating campaigns. The Brand must provide
              factual and clear information as part of the registration process, to ensure payment and tax filing purposes.
              Once the registration process has been completed successfully, and once payment requirements and while
              accepting
              this agreement, Brands are granted a limited, non-exclusive and non-transferable license to make use
              of the GameInfluencer.IO platform.</p>
            <p><b>GameInfluencer.IO & Warranties.</b>The Brands & Creators understands that GameInfluencer.IO is in constant development and
              improvement and some of the platform will change over time. Brands & Creators have the right to use the platform
              through the registration process. Both Creators and Brands understand that depending on the market development,
              GameInfluencer.IO might be terminated or temporarily out of service. GameInfluencer.IO can be tweaked and improved whenever desired even
              if it potentially affects layout or current services, and can be done without requesting any consent from
              Creators or Brands. GameInfluencer shall notify the Brand, as far as plannable, with a period of 48 hours in
              advance of such technical measures, if these have an substantial impact on the GameInfluencer services rendered
              to Brand. Besides the key points outlined in the agreement, the entire platform is “as is” and doesn’t come
              with any warranties. The Brands & Creators are expected to use the platform as described and in good faith,
              any illegal or unlawful activities are forbidden. GameInfluencer.IO can deny any Creator or Brand access to GameInfluencer.IO without
              written notice and prior consent if GameInfluencer.IO & Gameinfluencer notice the Brand is harming the platform or affecting
              its useability in any form possible, including but not limited to, hacking and malware. Brands & Creators are
              responsible for keeping their login information confidential. In case any unrightful user acquires the login
              details, the Brand or Creator should inform GameInfluencer.IO & Gameinfluencer immediately as any changes made by the
              unrightful user in GameInfluencer.IO are sole liability of Brand. For Brands & Creators, any Third-Party Services, hardware
              or software necessary to successfully use GameInfluencer.IO are their sole responsibility, such as but not limited to
              computers, phones, Third-party Tracking Data Providers. In no way shape or form are GameInfluencer.IO & Gameinfluencer
              liable for the affected performance of GameInfluencer.IO based on these Third-Party Services, hardware or software
              performances. This agreement overrides all existing agreements, understandings or communications between the
              Brand and GameInfluencer.IO (and Gameinfluencer team) in regards to the topics covered in the Agreement and makes up the
              entire agreement between the Brand and the GameInfluencer.IO (and Gameinfluencer team).</p>
            <p><b>Tracking Link & Attribution data.</b> Brands are also responsible for ensuring they acquire
              proper Attribution Data (The specific data that qualifies as a conversion in relation to the Brands campaign)
              and any other data or details and actively update GameInfluencer.IO & GameInfluencer with factual and accurate Attribution/Tracking
              Data performances where necessary for delivering of GameInfluencer.IO and Gameinfluencer’s Services. The testing of tracking
              pixels, -links or any other mechanism(s) (hereinafter referred to as “Tracking Measurements”) for observing
              and recording the performance of a campaign will be the responsibility of the Brand. In the event that the
              Brand incorrectly places Tracking Measurements or if the Brand removes or alters Tracking Measurements at any
              time during a campaign without express written permission from GameInfluencer the Brand shall pay to
              GameInfluencer, as liquidated damages, a sum equal to the daily average revenue generated by GameInfluencer
              for the campaign in question in a period of two (2) days immediately preceding such removal or alteration of
              Tracking Measurement by the Brand. If the Tracking Measurements are interrupted or disrupted due to technical
              problems, server downtime, data loss, transmission errors or other reasons by GameInfluencer, and, as a result,
              the Tracking Measurements are not recording valid actions correctly, then GameInfluencer shall be liable to
              pay the liquidated damages, a sum equal to the daily average campaign cost lost by the Brand due to such
              interruption or disruption by the GameInfluencer, multiplied by the number of days rounded up in respect of
              which tracking has been interrupted or rendered inoperable or ineffective. If there has not been a tracking
              of two days, the daily average revenue generated by GameInfluencer, based on all current campaigns with the
              respective Brand, shall be decisive for liquidated damages.
              Unless otherwise agreed, the Brand´s tracking technology shall be the sole system to track applicable actions
              and to calculate GameInfluencer´s remuneration. However, if Brand’s actions deviate significantly from
              GameInfluencer's tracked numbers, GameInfluencer has the right to audit the Brand’s numbers. The Brand shall
              therefore, at all times, during the term of the Agreement and for a period of three months thereafter, upon
              reasonable request, grant GameInfluencer access to the Brand’s tracking system and/ or tracking reports.
              All Actions shall be deemed confirmed or cancelled no later than 10 (ten) days in the following month by
              the Brand and thus be finally payable to GameInfluencer as set out in the Agreement (“Validation Period”).
              If the Brand does not reasonably object Actions within the validation period, such Action shall be deemed
              validated after the expiration of the Validation Period. In case Actions are cancelled by the Brand, the
              Brand must prove on reasonable grounds the non–validation of such Actions. The Brand shall furnish GameInfluencer
              with all relevant information and / or documentation, including, but not limited to, business records, and log
              files as well as any objections it may have on the Actions it has not validated, in order to allow
              GameInfluencer to review if the non – validation by the Brand was complete and accurate. In case the
              information provided is accurate and complete, GameInfluencer shall classify the relevant Actions as
              cancelled. However, Brands and GameInfluencer reserves the right to audit the given information during normal
              business hours of the Brand in order to review its classification at a later stage.
              An Action shall be especially, but not exclusively, considered as being non – valid, if it is generated
              automatically by a technical device and / or software (e.g. robot, spider, script).
              Any amounts due to GameInfluencer shall be plus the statutory value added tax (VAT), as far as value added tax
              is obligatory with respect to the GameInfluencer Services to the Brand. The Brand shall bear any and all banking
              fees related to payments by the Brand to GameInfluencer.
              if the Brand fails to make any payment due to GameInfluencer under the agreement by the due date, a late
              interest shall accrue on the overdue amount on a daily basis from the due date at the rate of 8% per annum
              of the base rate of the European Central Bank. Such interest shall accrue until actual payment of the overdue
              amount by the Brand.</p>
            <p><b>Relevant Data & personal information.</b>GameInfluencer.IO collects basic personal/corporate data as filled
              out in the application process and payment section of the platform. Such data includes, but is not limited to,
              examples such as company name, contact information, payment details, and brand inputted photos.</p>
            <p><b>Intellectual Property rights.</b> All right, title and interest and all Intellectual Property
              Rights in Creator provided Content as part of the Creator Marketing Campaign will remain held the Creator.
              Nothing in these Terms of Use suggest a transfer of Intellectual property regarding the Content available
              through GameInfluencer.IO & Gameinfluencer. All material provided by Brands as part of the Campaign process will remain
              the sole Intellectual Property of the Brand but the Creators have the limited and non-exclusive right to use
              the Brand provided materials to deliver on creating Content as part of the Campaign. In the event that a Brand
              chooses, but is not required to, give feedback for platform or service improvements for GameInfluencer.IO & Gameinfluencer,
              if GameInfluencer.IO & Gameinfluencer decide to act on this feedback or implement similar improvements these will be and
              remain the sole Intellectual Property of GameInfluencer.IO & GameInfluencer.</p>
            <p><b>Content.</b> In exchange for the Campaign related payments, brands will acquire the right to
              organically share and comment on the Content. The right to use the Creator’s Identity and performances is
              restricted to communicate the Content to the public in accordance with previous line outlined in this Terms of Use.
              In regards to the “additional Licensing request” after completion of Campaign, where Brands might request
              additional license from the Creator regarding the Content present in the Campaign. Depended on the filled out
              Licensing terms and after the Creator’s decision to accept the additional license terms and additional monetary
              compensation, the brand will have an additional license to promote the Creator’s Content in its own marketing
              efforts such as, but not limited to, the right to use the Creator’s Identity and performances in the Content
              to communicate the Content to the public in all languages, in all media such as online paid media banners,
              in all online owned media such as website, and in all owned social media, and in any earned media or public
              relations activity published by a third party. GameInfluencer.IO & Gameinfluencer will ensure Creators and submitted Content follow the Brand provided
              Campaign Brief details as well as comply with all necessary Media Platform regulations regarding Content such
              as but not limited to, YouTube Terms of Service and Google Privacy Policy, as of the latest updates. Furthermore,
              Creators will follow all FTC and other guidelines and laws relevant to promotional Content to ensure full
              transparency and desired Content. In case a Creator or Content, part of the Creator Ad Campaign, was modified
              or somehow doesn’t comply with the above mentioned, Campaign brief, guidelines and regulations, His Content
              will be terminated as soon as notified or when discovered. Brands will receive a full refund for fees paid relating to that specific sole Content.
              The positioning, placement, frequency and other editorial decisions related to the Creator Ads shall be
              compliant with the campaign restrictions and shall be made by GameInfluencer.IO &GameInfluencer, in its sole discretion.
              The Brand may request GameInfluencer.IO &GameInfluencer, that Creator Ads placed on Influencer Media may be changed or
              repositioned, if the Brand has the impression, that the current position of the Creator ads may damage the
              Brand´s and the Brand´s services reputation. In the event of such movement or reposition, GameInfluencer shall
              not be liable for any claims against GameInfluencer based on such movement or reposition.
              GameInfluencer reserves the right to reject, omit, exclude or terminate any Creator Ad for any reason at any
              time, with subsequent written notice (email shall be sufficient) to such Brand, whether or not such Creator
              Ad was previously used.</p>
            <p><b>Confidentiality.</b>Throughout the duration of this Agreement, it may be necessary for the
              relevant Parties (Creator,Brand,GameInfluencer & GameInfluencer.IO) to have access to each other’s confidential and
              protected information for the sole purpose of performing the Services subject to this Agreement. None of
              the Parties permitted to share or disclose such confidential information whatsoever, unless mandated by law,
              without written permission from the relevant Party. All of the obligations of confidentiality will survive the
              termination of the Agreement and stay in place 3 years after completion of the relevant Campaign. Upon the
              termination of the Agreement, the Parties involved agree to return any and all Confidential Information that
              is the property of the other Party upon written request. They shall promptly return all copies, whether in
              written, electronic, or other form or media, of the Party’s Confidential Information, or destroy all such
              copies and certify in writing to the Party that such Confidential Information has been destroyed.
              This confidentiality only applies to Content that is private and does not take into consideration information
              that is public, lawfully acquired through third party, or already in possession of the same information before
              the confidentially agreement.</p>
            <p><b>Notice.</b> Notice should be given in a timely manner and by the respective point of contact
              that
              has engaged the other party thus far, and is required to be given by email. If any of the parties change their
              point of contact or their contact details, they should notify all relevant parties via email in advance.</p>
            <p><b>Force Majeure.</b> Any of the relevant parties shall not be in breach of this Agreement for any
              delay or failure in performance caused by reasons out of its reasonable control. This includes, but is not
              limited to, acts of God or a public enemy; natural calamities; failure of a third party to perform; changes in
              the laws or regulations; actions of any civil, military or regulatory authority; power outage or other
              disruptions of communication methods or any other cause which would be out of the reasonable control of the
              relevant party.</p>
            <p><b>Payments.</b> GameInfluencer.IO will pay the Creator after having received the campaign funds from the
              relevant Brand. To manage and execute the campaign, the Brand deposits his campaign budget on the platform as
              part of the Creator Ad Campaign. GameInfluencer will hold the budget in escrow and pay out Creators on a
              monthly basis depending on their CPI and Brand provided Attribution Data. Once a Creator partaking in a
              Campaign generate Conversions, a small section of the escrow budget will be put aside under “Allocated Budget”
              meaning regardless if the Brand wishes to cancel the Campaign after the generated conversion, the Allocated
              Budget will be paid out to Creators to ensure compensation for the Conversions. GameInfluencer.IO will pay the Creator
              after having received the campaign funds from the relevant Brand. The payment will be made on a monthly basis
              (30 days) or upon sudden cancelation of Campaign and are based on the relevant Campaigns CPI Model. Meaning a
              Creator will be paid for Real Installs generated through their Creator Link from the relevant Campaign.
              A Real Install means only a real individual person that actively accesses the application via the third-party
              distribution service such as, but not limited to, Google or the Apple play store, and who access the
              distribution service through the Creator’s designated Creator Link for the Campaign, and then proceeds to
              install and launch the Campaign promoted application while on the Internet. This only applies for a real
              individual person and cannot be a robot script or computer generated and can also not be identified as
              fraudulent or a duplicate by GameInfluencer.IO, GameInfluencer or the Brand on a later date. The payments will be made for
              as long as the Campaign runs or until sudden shortened closure of relevant Campaign. Any installs that are
              already paid and later identified as a none Real Installs will get deducted from the Creators account before
              subsequent payments are made. Any identified fraud ways of acquiring the Installs can lead to the immediate
              termination of the Creators account and participation in Campaigns and the Creator will not receive any unpaid
              monthly payments on the Creators account. Brands will also be presented licensing agreement options to license
              Creator Ads for their own marketing efforts, Payment is a one-time fee. After payment brands will be sent the
              Creator Ads. All price points on the platform are excluding VAT tax or other applicable taxes charged on top.
              Brands are solely responsible for covering VAT and other tax obligations. Any identified fraud ways of
              acquiring the Installs can lead to the immediate termination of the Creators account and participation in
              Campaigns and the Creator will not receive any unpaid monthly payments on the Creators account. In the event
              that during Campaigns, Brands notice or have a firm belief that a Content piece is generating fraudulent
              Installs, they are to notify GameInfluencer.IO or Gameinfluencer within 33 days of the relevant install, to allow
              Gameinfluencer & GameInfluencer.IO to review and analyze it in a timely manner incase termination is necessary. In the
              event of early Termination of this agreement, Brands are not entitled to a refund of any prepaid fees. In case
              a Brand does activate Creators and their Content as part of their Campaign options on GameInfluencer.IO, up to 90 days
              after the day of the purchase and according to the terms of this Agreement, Brands cannot purchase or work
              with the Creators in any form directly , or pay a fee of 33000 Euro for breach of Agreement and the
              exclusivity clause. Any notice relating to Brands and their due payments, should be given in written form
              within 9 days after receiving Receipt.</p>
            <p><b>Limitation of liability. </b>The GameInfluencer.IO Platform & GameInfluencer are in no way liable for
              uncorrelated, incidental, indirect, special, or exemplary damages. Naturally this also includes, but is not
              limited to, any incident that cause a direct decrease of business opportunities or even potential revenue
              impact even if they are foreseeable and we have been advised of the potential consequences. Brand will be
              liable and won’t hold any other party accountable or liable for damages, liabilities, costs in relation to a
              third-party claim regarding Brand provided materials, Brand information, or the use of the services and
              Content in violation of this agreement. GameInfluencer shall not be liable for the Content on Creator
              Ads, for damages or other disturbances caused by defects and / or interoperability of Creator Ads and / or for
              damages resulting from the insufficient availability or imperfect functioning of the internet and / or the app
              stores. The only exemption to this case is intentional misconduct or gross negligence or any potential
              breaches regarding intellectual Property and confidentiality. We do not restrict our liability beyond what is
              legal by law. The total aggregate liability are limited to campaign fees paid or payables as part of the
              Creator Ads Campaign &additional Licensing options paid to GameInfluencer.IO & GameInfluencer. The Brand provided materials
              and Briefs do not contain any misinterpretations or any Content that is defamatory, contain Content that is
              violent, obscene, offensive, including Content that contains nudity or implied nudity or Content that is
              morally or ethically offensive or sexual suggestive, promote or support illegal gambling or sweepstakes or
              contests or contain any “worm”, “virus”, “Trojan” or other code which could impair or injure any hardware,
              software, person or entity; The Brand shall indemnify and hold harmless, at first request, GameInfluencer from
              any and against any and all liabilities, losses, damages or expenses (including, without limitation,
              reasonable attorney's fees, costs and expenses) arising out of or related to any claim related to the Brand 's
              breach of warranties set out in this section</p>
            <p><b>Termination. </b>This agreement has a termination period of 1 month, and can be terminated by
              any of the relevant Parties upon written notice. The written notice should be given in case either party
              breaches the preceding agreement, either party may terminate this agreement immediately in case of a breach of
              the agreement and incase it isn’t solved within 9 days. If the other party does not fix the breach of the
              agreement within 12 days of receiving written notice, the agreement will be terminated. Regarding any
              potential liability that was formed prior to the termination of the agreement, it will still hold. Also
              regarding Influencer uploaded Content that was submitted on GameInfluencer.IO before the termination, all the relevant
              licenses and granted rights that were given to GameInfluencer.IO, GameInfluencer, and Brand will also hold regardless of
              the termination. Finally when the agreement is terminated, all rights and licenses that were given to the
              Influencer will terminate immediately. In the event that the Brand becomes bankrupt or insolvent, GameInfluencer.IO and
              Gameinfluencer have the power to terminate the agreement immediately upon written notice. In the event of
              termination, any Allocated Budget won’t be refunded and will still be charged to the Brand as the services
              preceded the termination.</p>
            <p><b>Promotion. </b>For the nature of the platform, the Brand agrees that GameInfluencer.IO & Gameinfluencer have
              the right to use company name, and biography for promoting the platform to other Brands as part of the GameInfluencer.IO’s
              services.</p>
            <p><b>Severability. </b>If any term or provision of this Agreement is invalid, illegal, or
              unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other
              term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other
              jurisdiction.</p>
            <p><b>Platform Protection. </b>Users aren’t allowed to extract data, copy or reverse engineer any of
              GameInfluencer.IO platform source code, or damage & disrupt GameInfluencer.IO in any manner possible throughout multiple universes and
              in perpetuity. Any violation of the agreement terms and usage will be grounds for a lawsuit.</p>
            <p><b>Dispute Resolution and Governing Law. </b>Parties to this Agreement shall first attempt to
              settle any dispute through good-faith negotiation. If the dispute cannot be settled between the parties via
              negotiation, either party may initiate mediation or binding arbitration in German Law. Any dispute should be
              aimed to solve through negotiations, however if the parties can not solve the dispute through negotiations
              within 69 days, the case can be taken to court. All disputes arising in connection with this agreement or even
              parts thereofor its validity shall be finally settled according to the Arbitration Rules and the Supplementary
              Rules for Expedited Proceedings of the German Institution of Arbitration e.V. (DIS) without recourse to the
              ordinary courts of law. The place of arbitration is Munich. The substantive law is German law. The language is
              German or, by discretion of the judge, English. Only one judge shall oversee the dispute, which shall be
              appointed by the DIS. The Parties shall have the right to challenge the judge if they find him not to be
              materially familiar with the issues and/ or the industry in question. The Terms of Use and their
              interpretation shall be governed by the laws of Germany with the exception of the UN Convention on Contracts
              for the International Sale of Goods dated 11.4.1980.</p>
            <p><b>Legal Fees. </b>Should a dispute between the named Parties arise lead to legal action, the
              prevailing Party shall be entitled to any reasonable legal fees, including, but not limited to attorneys’
              fees.</p><br />
          </ScrollPanel>
        }
        <Button onClick={() => props.handleRouting('auth/login')} className={'goBackButton'}>GO BACK TO PREVIOUS PAGE</Button>
        <div className={'footerText'}>
          <span className={'copyright'}>© 2023 Gameinfluencer GmbH</span>
          <div className={'dividerGrey'} />
          <span className={'redUnderlineTextSmall'} onClick={() => props.handleRouting('terms-of-use')}>TERMS OF USE</span>
          <div className={'dividerRed'} />
          <span className={'redUnderlineTextSmall'} onClick={() => props.handleRouting('legal-notice')}>LEGAL NOTICE</span>
          <div className={'dividerRed'} />
          <span className={'redUnderlineTextSmall'} onClick={() => props.handleRouting('privacy-policy')}>PRIVACY POLICY</span>
        </div>
      </div>
    </div>
  )
}
