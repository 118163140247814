
import { defineComponent, ref } from 'vue';
import AskHint from '@/pages/creators/utils/AskHint.vue';

export default defineComponent({
  name: 'CampaignsCounterItemNew',
  components: { AskHint },
  props: {
    name: {
      type: String,
      default: '',
    },
    android: Number,
    ios: Number,
  },
  setup(props) {
    const showAndroid = ref(true);
    const showIos = ref(false);
    return {
      showAndroid, showIos,
    };
  },
});
