import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_invitations_list = _resolveComponent("invitations-list")!
  const _component_app_campaign_subpage = _resolveComponent("app-campaign-subpage")!

  return (_openBlock(), _createBlock(_component_app_campaign_subpage, {
    config: _ctx.config,
    campaignFilterOptions: _ctx.associatedCampaigns,
    onFilterByCampaignID: _ctx.filterByCampaignID
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_invitations_list, {
              key: 0,
              campaigns: _ctx.campaigns,
              viewPerspective: _ctx.viewPerspective,
              onDelete: _ctx.deleteInvitation,
              onAccept: _ctx.acceptInvitation
            }, null, 8, ["campaigns", "viewPerspective", "onDelete", "onAccept"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["config", "campaignFilterOptions", "onFilterByCampaignID"]))
}