
import { ref } from 'vue';
import Checkbox from 'primevue/checkbox';
import Terms from '../../static/Terms.vue';

export default {
  name: 'ContractItem',
  components: {
    Checkbox,
    Terms,
  },
  props: {
    campaign: Object,
    newBudgetData: Object,
  },
  setup() {
    const acceptedTerms = ref();
    const requireInvoice = ref();
    const showTerms = ref(false);
    const fees = ref<number>(18);

    return {
      acceptedTerms,
      requireInvoice,
      showTerms,
      fees,
    };
  },
};
