import { useAPI } from '@/modules/api-module';
import { useAuth } from '@/modules/auth-module';
import { CampaignData, AppPagePerspective, Perspective, CampaignStatus } from '@/data/types';
import { ref, watch } from 'vue';
import { useCampaignsList } from './campaigns-list-module';
import { CampaignApplicationPayoutCapProposal } from '../../data/types/campaign-types';
import { CampaignApplicationStatusValues } from '@/data/enums/campaign-application';
import { useToastModule } from "@/modules/messages/toast-module";

const { user } = useAuth();
const toast = useToastModule();

export const useCampaignApplications = (perspective: AppPagePerspective) => {
  const selectedCampaignID = ref<string>('');
  const applications = ref<Array<CampaignData>>([]);
  const associatedCampaigns = ref<Array<CampaignData>>([]);

  const { loading, finished, data: loadedApplicationCampaigns, execute } = useAPI<Array<CampaignData>>('/campaigns/applications/', false);
  const allCampaigns = useCampaignsList(perspective);

  const loadApplications = () => {
    const creatorID = user?.value?.id;
    const brandID = user?.value?.brand?.id;
    const targetID = perspective === Perspective.BRAND ? brandID : creatorID;

    if (perspective === Perspective.BRAND && !brandID) {
      throw new Error('Could not load campaigns for a brand, because brandID is missing!');
    }

    if (perspective === Perspective.BRAND || perspective === Perspective.ADMIN) {
      allCampaigns.loadCampaigns();
    }

    return execute({ params: { perspective, targetID } });
  };

  watch(loadedApplicationCampaigns, () => {
    if (perspective === Perspective.CREATOR) {
      associatedCampaigns.value = loadedApplicationCampaigns.value || [];
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    filterList();
  });

  watch(allCampaigns.campaigns, () => {
    associatedCampaigns.value = allCampaigns
      .campaigns.value?.filter((campaign) => campaign.status !== CampaignStatus.Draft)
      || [];
  });

  // filtering

  const filterList = () => {
    const campaignsWithApplications = loadedApplicationCampaigns
      .value?.filter((campaign) => !!campaign.applications?.length) || [];

    if (selectedCampaignID.value) {
      applications.value = campaignsWithApplications.filter((campaign) => campaign.id === selectedCampaignID.value);
    } else {
      applications.value = campaignsWithApplications;
    }
  };

  const filterByCampaignID = (campaignID = '') => {
    selectedCampaignID.value = campaignID;
    filterList();
  };

  const {
    loading: publishing,
    error: rejectApplicationError,
    data: publishingResult,
    execute: sendRejectRequest,
  } = useAPI<CampaignData>('', false);
  const rejectApplication = (campaignID: string, applicationID: string, contractWork: boolean, userId?: string, channelId?: string) => sendRejectRequest({
    url: `/campaigns/applications/resolve/${campaignID}/${applicationID}/${contractWork}`,
    method: 'PUT',
    data: { action: 'reject', userId, channelId, perspective: 'brand' },
  });
  watch(rejectApplicationError, toast.handleRequestError);

  const {
    loading: acceptApplicationProgress,
    error: acceptApplicationError,
    data: acceptApplicationResult,
    execute: acceptApplicationRequest,
  } = useAPI<CampaignData>('', false);
  const acceptApplication = (campaignID: string, applicationID: string, contractWork: boolean, userId?: string, channelId?: string) => acceptApplicationRequest({
    url: `/campaigns/applications/resolve/${campaignID}/${applicationID}/${contractWork}`,
    method: 'PUT',
    data: { action: 'accept', userId, channelId, perspective: 'brand' },
  });
  watch(acceptApplicationError, toast.handleRequestError);

  const {
    loading: proposeIndividualCapProgress,
    error: proposeIndividualCapError,
    data: proposeIndividualCapResult,
    execute: proposeIndividualCapRequest,
  } = useAPI<CampaignData>('', false);
  const proposeIndividualCap = (campaignID: string, applicationID: string, payoutCap: number, channelID?: string) => proposeIndividualCapRequest({
    url: `/campaigns/applications/cap/propose/${campaignID}/${applicationID}`,
    method: 'PUT',
    data: { payoutCap, channelID, perspective: 'brand' },
  });
  watch(proposeIndividualCapError, toast.handleRequestError);

  const {
    loading: resolveCapProposalProgress,
    error: resolveCapProposalError,
    data: resolveCapProposalResult,
    execute: resolveCapProposalRequest,
  } = useAPI<CampaignApplicationPayoutCapProposal>('', false);
  const resolveCapProposal = (action: "accept" | "reject", campaignID: string, applicationID: string, channelID?: string) => resolveCapProposalRequest({
    url: `/campaigns/applications/cap/resolve/${campaignID}/${applicationID}`,
    method: 'PUT',
    data: { action, channelID, perspective: 'brand' },
  });
  watch(resolveCapProposalError, toast.handleRequestError);

  const {
    loading: getApplicationProposalsProgress,
    error: getApplicationProposalsError,
    data: getApplicationProposalsResult,
    execute: getApplicationProposalsRequest,
  } = useAPI<CampaignApplicationPayoutCapProposal[]>('', false);
  const getApplicationProposals = (campaignID: string, applicationID: string, filterStatus: CampaignApplicationStatusValues) => getApplicationProposalsRequest({
    url: `/campaigns/applications/caps/proposals/${campaignID}/${applicationID}/${filterStatus}`,
    method: 'GET',
    data: { perspective: 'brand' },
  });

  return {
    applications,
    rejectApplication,
    acceptApplication,
    acceptApplicationError,
    acceptApplicationResult,
    rejectApplicationError,
    proposeIndividualCapError,
    loading,
    finished,
    loadApplications,
    filterByCampaignID,
    associatedCampaigns,
    proposeIndividualCap,
    resolveCapProposal,
    getApplicationProposals,
    resolveCapProposalError,
    resolveCapProposalResult
  };
};
