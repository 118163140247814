import React from 'react';
import "./NewAuth.css";
import NewLoginFull from "@/react_app/NewAuth/NewLoginFull/NewLoginFull";
import NewRegistrationFull from "@/react_app/NewAuth/NewRegistrationFull/NewRegistrationFull";
import NewAdminAuthorization from "@/react_app/NewAuth/NewAdminAuthorization/NewAdminAuthorization";
import { Image } from 'primereact/image';
import logo from "./img/logo-white.png";
import { PrimeReactProvider } from "primereact/api";

export default function (props) {
  return (
    <div className={'new-auth'}>
      <PrimeReactProvider>
        <div className={'leftScreen'}>
          <div className={'noFooter'}>
            <Image src={logo} alt={"logo"} height={'73px'} className={'logo'} />
            {window.location.pathname === "/auth/login" &&
              <NewLoginFull handleRouting={props.handleRouting} signIn={props.handleEmailSignIn} handleUserConnect={props.handleUserConnect}
                userType={props.userType} connecting={props.connecting} />}
            {window.location.pathname === "/auth/register" &&
              <NewRegistrationFull userType={props.userType} handleUserConnect={props.handleUserConnect} connecting={props.connecting} handleRouting={props.handleRouting} />}
            {window.location.pathname === "/admin" &&
              <NewAdminAuthorization signInAdmin={props.handleAdminEmailSignIn} />}
          </div>
          <div className={'footerText'}>
            <span>© 2023 Gameinfluencer GmbH</span>
            <div className={'dividerGrey'} />
            <span className={'redUnderlineTextSmall'} onClick={() => props.handleRouting('terms-of-use')}>Terms Of Use</span>
            <div className={'dividerRed'} />
            <span className={'redUnderlineTextSmall'} onClick={() => props.handleRouting('legal-notice')}>Legal Notice</span>
            <div className={'dividerRed'} />
            <span className={'redUnderlineTextSmall'} onClick={() => props.handleRouting('privacy-policy')}>Privacy Policy</span>
          </div>
        </div>
        <div className={'rightScreen'} />
      </PrimeReactProvider>
    </div>
  )
}
