import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewInviteToCampaign = _resolveComponent("NewInviteToCampaign")!
  const _component_OldInviteToCampaign = _resolveComponent("OldInviteToCampaign")!

  return (_ctx.isInfluencerProfileEnabled)
    ? (_openBlock(), _createBlock(_component_NewInviteToCampaign, { key: 0 }))
    : (_openBlock(), _createBlock(_component_OldInviteToCampaign, {
        key: 1,
        showModal: _ctx.showModal,
        creatorId: _ctx.creatorId,
        creator: _ctx.creator,
        closeModal: _ctx.closeModal
      }, null, 8, ["showModal", "creatorId", "creator", "closeModal"]))
}