
import { reactive, ref } from 'vue';
import { useAuth, useCampaigns } from '@/modules';
import { useRoute } from 'vue-router';
import CardPayment from './CardPayment.vue';
import BankPayment from './BankPayment.vue';
import CryptoPayment from './CryptoPayment.vue';
import ContractItem from './ContractItem.vue';

export default {
  name: 'PaymentMethods',
  components: {
    CardPayment,
    BankPayment,
    CryptoPayment,
    ContractItem,
  },
  props: {
    campaignId: String,
    newBudgetData: Object,
    createFileCsv: {
      type: Boolean,
      default: false,
    },
    firstPayCampaign: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any) {
    const campaigns = useCampaigns();
    const termsAgree = ref(false);
    const { user } = useAuth();
    const route = useRoute();
    campaigns.manager.loadSingle(route.params.campaignID as string || props.campaignId as string);
    const paymentMethods = reactive([{
      type: 'mangopay',
      // eslint-disable-next-line global-require
      image: require('../assets/images/mangopay-logo.png'),
      paymentType: ['Card Payment', 'Bank Payment', 'Crypto Payment'],
    }]);
    const selectedMethod = ref(paymentMethods[0]);
    const selectedActivePaymentType = ref(selectedMethod.value.paymentType[0]);
    const agreeTerms = (agree: boolean) => {
      termsAgree.value = agree;
    };

    return {
      agreeTerms,
      termsAgree,
      paymentMethods,
      selectedMethod,
      selectedActivePaymentType,
      campaign: campaigns.manager.singleCampaign,
      user: user.value,
    };
  },
};
