
import { defineComponent, ref, watch } from 'vue';
import { usePage } from '@/modules';
import { useContentItems } from '@/modules/content-items/content-items-module';
import { AppPageConfig, AppPageProps, ContentItem } from '@/data/types';
// import LatestContentItem from '@/pages/creators/latest/LatestContentItem.vue';
// import AppPage from '../../commons/AppPage.vue';

export default defineComponent({
  // components: { AppPage, LatestContentItem },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    ...AppPageProps,
    private: Boolean,
  },
  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: 'Licensed Items',
      ...props,
    });
    const items = ref<Array<ContentItem>>([]);
    const page = useContentItems(config.value.viewPerspective);
    const loadPageData = () =>
      page.list
        .loadContentItems(props.private)
        .then((result?: Array<ContentItem>) => {
          items.value = (result || []).filter(
            (item: ContentItem) =>
              !!item.licenseRequests.find((r) => r.status === 'accepted')
          );
        });
    loadPageData();
    watch(() => props.private, loadPageData);
    return {
      config,
      loading: page.list.loading,
      contentItems: items,
    };
  },
});
