
import { defineComponent, PropType } from 'vue';
import { Brand } from '@/data/types';
import BrandPhoto from './profile/BrandPhoto.vue';

export default defineComponent({
  name: 'BrandListItem',

  components: { BrandPhoto },

  props: {
    brand: {
      type: Object as PropType<Brand>,
      required: true,
    },
  },
});
