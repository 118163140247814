
import Carousel from 'primevue/carousel';
import { defineComponent, ref, computed, onMounted } from 'vue';

export default defineComponent({
  components: {
    Carousel
  },
  setup() {

    const carouselItems = [
      {
        image: require('@/assets/landing/case-study-1.png'),
        logo: require('@/assets/landing/case-study-logo-1.svg'),
        campaignName: 'Honkai: Star Rail',
        influencerCount: 240,
        marketCount: 6,
        totalViews: 12000000,
      },
      {
        image: require('@/assets/landing/case-study-2.png'),
        logo: require('@/assets/landing/case-study-logo-2.svg'),
        // campaignName: 'Honkai: Star Rail',
        // influencerCount: 200,
        // marketCount: 30,
        // totalViews: 1000000,
      },
      {
        image: require('@/assets/landing/case-study-3.png'),
        logo: require('@/assets/landing/case-study-logo-3.svg'),
        // campaignName: 'Honkai: Star Rail',
        // influencerCount: 120,
        // marketCount: 20,
        // totalViews: 40000,
      },
      {
        image: require('@/assets/landing/case-study-4.png'),
        logo: require('@/assets/landing/case-study-logo-4.svg'),
        // campaignName: 'Honkai: Star Rail',
        // influencerCount: 120,
        // marketCount: 20,
        // totalViews: 40000,
      },
      {
        image: require('@/assets/landing/case-study-5.png'),
        logo: require('@/assets/landing/case-study-logo-5.svg'),
        campaignName: 'RAID: Shadow Legends',
        influencerCount: 28,
        // marketCount: 31,
        videoAds: 31,
        totalViews: 1900000,
      },
      {
        image: require('@/assets/landing/case-study-1.png'),
        logo: require('@/assets/landing/case-study-logo-6.svg'),
        campaignName: 'X8 VR Hero Shooter',
        // influencerCount: 20,
        videoAds: 20,
        totalClicks: 6300,
        // marketCount: 20,
        totalViews: 30000,
      },
      {
        image: require('@/assets/landing/case-study-2.png'),
        logo: require('@/assets/landing/case-study-logo-2.svg'),
        campaignName: 'Albion Online',
        influencerCount: 14,
        videoAds: 14,
        // marketCount: 14,
        totalViews: 506000,
      },
      {
        image: require('@/assets/landing/case-study-5.png'),
        logo: require('@/assets/landing/case-study-logo-5.svg'),
        campaignName: 'Warhammer Combat Cards',
        influencerCount: 32,
        totalClicks: 5000,
        // marketCount: 20,
        totalViews: 13000,
      },
      {
        image: require('@/assets/landing/case-study-1.png'),
        logo: require('@/assets/landing/case-study-logo-6.svg'),
        campaignName: 'Warpath: Ace Shooter',
        influencerCount: 7,
        engagement: 11600,
        totalViews: 415000,
      },
    ];

    const responsiveOptions = ref([
      {
        breakpoint: '2500px',
        numVisible: 5,
        numScroll: 1
      },
      {
        breakpoint: '1900px',
        numVisible: 4,
        numScroll: 1
      },
      {
        breakpoint: '1400px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '1199px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '767px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
      }
    ]);

    function formatNumber(number: number): string {
      return number.toLocaleString('en-US', {
        notation: 'compact',
        compactDisplay: 'short',
        maximumFractionDigits: 1,
      });
    }

    return {
      carouselItems,
      responsiveOptions,
      formatNumber
    };
  },
});
