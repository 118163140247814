
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { User } from '@/data/types';
import { useAuth, useUsers } from '@/modules';
import { useToast } from 'primevue/usetoast';
import { useAuthForm } from './modules/auth-form-module';
import { SocialConnectResponse } from './social-def';

import EmailConnect from './components/EmailConnect.vue';

export default defineComponent({
  name: 'LoginForm',

  components: {
    EmailConnect,
  },

  setup() {
    const { loadUser } = useAuth();
    const users = useUsers();
    const router = useRouter();
    const { connecting, userIsCreator, userType, allSocialConnections } = useAuthForm();
    const showPrivacyPolicy = ref(false);
    const showTermsOfUse = ref(false);
    const showLegacy = ref(false);

    const loadUserData = (token: string, refresh: string) => {
      loadUser(token, refresh)
        .then((success?: User | undefined) => {
          if (success) {
            router.push({ name: 'dashboard' });
          } else {
            // TODO: handle somehow?
          }
        });
    };
    const toast = useToast();

    const handleUserConnect = (response: SocialConnectResponse) => {
      if (!response.tokens) {
        connecting.value = '';
        return toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'You are not registered, please register',
          life: 3000,
        });
      }
      return loadUserData(response.tokens.access.token, response.tokens.refresh.token);
    };

    const handleEmailSignIn = ({ email, password }: { email: string; password: string }) => {
      if (email && password) {
        connecting.value = 'email';
        users.manager
          .signin(email, password, 'admin', 'admin')
          .then((response?: { tokens: any }) => {
            if (response?.tokens) {
              loadUserData(response?.tokens.access.token, response?.tokens.refresh.token);
            } else {
              connecting.value = '';
            }
          });
      }
    };

    return {
      showTermsOfUse,
      showLegacy,
      showPrivacyPolicy,
      connecting,
      userIsCreator,
      userType,
      allSocialConnections: allSocialConnections.filter((item) => item.disabled !== true),
      handleUserConnect,
      handleEmailSignIn,
    };
  },
});
