
import { defineComponent, ref } from 'vue';
import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton';

import { UserType } from '@/data/types';
import { useAuth } from '@/modules/auth-module';
import AppListPage from '../commons/AppListPage.vue';

export default defineComponent({
  name: 'Admin',

  components: { AppListPage, Button, RadioButton },

  props: {},

  setup() {
    const { user } = useAuth();
    const actAs = ref(user?.value?.type || '');

    const saveActAs = () => {
      localStorage.setItem('actAs', actAs.value);
      window.location.reload();
    };

    return {
      user,
      UserType,
      actAs,
      saveActAs,
    };
  },
});
