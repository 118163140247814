
import { defineComponent, ref, watch } from 'vue';
import {useAuth, usePage } from '@/modules';
import { useContentItems } from '@/modules';
import { AppPageConfig, AppPageProps } from '@/data/types';
import { applyPureReactInVue } from 'veaury'
import NewMyContent from "@/react_app/NewMyContent/NewMyContent.jsx"
import OldMyContent from "@/pages/content/OldMyContent.vue";
import { isNewMyContentPageEnabled } from '@/utils/globals/feature-flags';
import router from '@/router';

export default defineComponent({
  name: 'ContentItems',
  components: {
    NewMyContent: applyPureReactInVue(NewMyContent),
    OldMyContent
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    ...AppPageProps,
    private: Boolean,
    limit: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: 'Content Items',
      search: {
        enabled: props.showSearch,
        defaultType: 'creators',
        placeholder: 'Search for Content items',
      },
      ...props,
    });

    const { user } = useAuth();

    const page = useContentItems(config.value.viewPerspective);
    const loadPageData = () => page.list.loadContentItems(props.private, props.limit, '');

    const contentItems = ref();
    const filteredContentItems = ref();

    loadPageData().then((items) => {
      contentItems.value = items;
      filteredContentItems.value = items;
    });

    const selectStatusCategory = (category: any) => {
      let allContentItems: any = [];
      if (category !== 'all') {
        allContentItems = allContentItems.concat(contentItems.value.filter((item: { public: boolean }) => item?.public === category));
      } else {
        allContentItems = contentItems.value;
      }

      filteredContentItems.value = allContentItems;
    }

    const searchValueChange = (newValue: string) => {
      const lowercaseValue = newValue.toLowerCase();
      filteredContentItems.value = contentItems.value.filter((item: any) =>
        item.title.toLowerCase().includes(lowercaseValue));
    };

    const goToVideo = (id: string) => {
      router.push({ name: 'content-item', params: { itemID: id } });
    };

    const routeTo = (name: string) => {
      router.push({ name: name });
    }

    const goBack = () => {
      router.push({ name: 'dashboard' });
    }

    watch(() => props.private, loadPageData);

    return {
      config,
      page,
      user: user,
      loading: page.list.loading,
      contentItems: filteredContentItems,
      sync: page.manager.sync,
      syncing: page.manager.syncingInProgress,
      performSearch: page.list.search('name', props.limit || 0),
      isNewMyContentPageEnabled,
      selectStatusCategory,
      searchValueChange,
      goToVideo,
      routeTo,
      goBack
    };
  },
});
