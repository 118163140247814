import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0680a32a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-pt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    class: "modal-page",
    header: _ctx.pageHeader,
    visible: _ctx.isModalVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isModalVisible) = $event)),
    style: { width: '50vw' },
    modal: true,
    maximizable: true,
    dismissableMask: _ctx.config.dismissableMask
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Button, {
          label: _ctx.acceptLabel,
          icon: "pi pi-check",
          onClick: _ctx.accept,
          class: "",
          autofocus: ""
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_Button, {
          label: _ctx.rejectLabel,
          icon: "pi pi-times",
          onClick: _ctx.reject,
          class: "p-button-text"
        }, null, 8, ["label", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.pageComponent)))
    ]),
    _: 1
  }, 8, ["header", "visible", "dismissableMask"]))
}