
import { ref, defineComponent } from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { openSocialPopup } from '@/utils/globals/popup-utils';
import { SocialConnectResponse } from '@/pages/auth/social-def';
import { useAuth, useAgencys } from '@/modules';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import EmptyList from '../../commons/list/EmptyList.vue';
import Button from 'primevue/button';


export default defineComponent({
  components: {
    TabView,
    TabPanel,
    Button,
    EmptyList,
  },
  props: {
    user: {
      type: Object,
    },
    channelsYT: {
      type: Array,
    },
  },
  setup(props, ctx) {
    const confirm = useConfirm();
    const agencyModule = useAgencys();
    const toast = useToast();
    const { user: authUser } = useAuth();

    const activeClass = 'active_link';
    const tabviewDraft = ref(0);
    const menuOptions = ref(['Youtube']);
    const removeChannelItem = ref('');

    const openConfirmRemoveModal = (event: any, item: any) => {
      removeChannelItem.value = item;
      confirm.require({
        target: event.currentTarget,
        message: 'Do you confirm the deletion of channel ' + item?.snippet?.title + ' from your agency profile?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: async () => {
          agencyModule.manager.deleteChannel(item?.id).then(async (data) => {
            toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Channel ' + item?.snippet?.title + ' deleted', life: 3000 });
            ctx.emit('removeChannel', item?.id);
          });
        },
      });
    }

    const openConnectPopup = (): void => {
      openSocialPopup(
          'youtube',
          { type: props.user?.id, action: 'add_channel_yt' },
          async (err?: Error | null, response?: SocialConnectResponse) => {
            if (response) {
              ctx.emit('success-add-channel');
            }
          },
      );
    };

    return {
      tabviewDraft,
      activeClass,
      menuOptions,
      authUser,
      openConfirmRemoveModal,
      removeChannelItem,
      openConnectPopup,
    };
  },
});
