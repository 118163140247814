import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "campaign-list-counters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_campaigns_counter_item = _resolveComponent("campaigns-counter-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_campaigns_counter_item, {
      name: "Active Campaigns",
      counter: `${_ctx.counters?.campaigns}`
    }, null, 8, ["counter"]),
    _createVNode(_component_campaigns_counter_item, {
      name: "Influencer Applications",
      counter: `${_ctx.counters?.applications}`
    }, null, 8, ["counter"]),
    _createVNode(_component_campaigns_counter_item, {
      name: "Submissions",
      counter: `${_ctx.counters?.contracts}`
    }, null, 8, ["counter"])
  ]))
}