
import { useTransfers } from '@/modules';
import { ref, defineComponent } from 'vue';
import Chart from 'primevue/chart';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import { ITransactionItem } from "@/data/types/payment-types";
import { useRoute } from "vue-router";
import moment from 'moment';
// import { Chart } from 'chart.js';

export default defineComponent({
  components: {
    Chart,
    Button,
    Calendar,
  },
  setup() {
    const route = useRoute();
    const transfersModule = useTransfers();
    const rangeDates = ref();
    const dataSet = ref<Array<ITransactionItem> | undefined>([]);

    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December",
    ];
    const getLabelsMonth = (dataset: Array<ITransactionItem> | undefined) => {
      if (dataset?.length) {
        const labels: Array<string> = [];
        // need add 1 month?
        const moments = dataset.map((el) => moment(el?.perMonth).add(1, 'month'));
        const uniqueMoments = moments.filter((v, i) => {
          return moments.findIndex((candidate) => v.isSame(candidate, 'month')) == i
        });
        const sortedArray = uniqueMoments.sort((a, b) => a.diff(b))
        sortedArray.forEach((perMonth: any) => {
          const currentMonth = moment(perMonth).subtract(1, "month").startOf("month").format('MMMM');
          let year = new Date(perMonth)?.getFullYear();
          if (currentMonth === 'December') {
            year = new Date(perMonth)?.getFullYear() - 1;
          }
          labels.push(`${currentMonth} ${year}`);
        });
        return labels;
      }
      return [];
    };

    // const colors = ['#00bb7e', '#42A5F5', '#42A5F5', '#FFA726'];
    const getDataByUsers = (dataset: Array<ITransactionItem> | undefined, campaignId: string) => {
      if (!dataset?.length) return [];
      const filteredByCampaign = dataset.filter((el) => el.campaignId?.id === campaignId);
      const uniqueUsers = new Set();
      dataset.forEach((data) => {
        uniqueUsers.add(data?.userId?.id);
      });
      const datasets: {
        label: any; fill: boolean;
        yAxisID: string; tension: number; data: number[];
      }[] = [];
      let idx = 0;

      uniqueUsers.forEach((item) => {
        const filteredByUser = filteredByCampaign.filter((itemData) => itemData.userId?.id === item);
        if (filteredByUser?.length) {
          const data: number[] = [];
          filteredByUser.forEach((el) => {
            const labels = getLabelsMonth(dataset);
            const month = `${monthNames[new Date(el?.perMonth)?.getMonth()]} ${new Date(el?.perMonth)?.getFullYear()}`;
            const monthIndex = labels.findIndex((label) => label === month);
            if (monthIndex >= 0) {
              data[monthIndex] = el.amount;
            }
          });
          for (let i = 0; i < data?.length; i += 1) {
            if (!data[i]) {
              data[i] = 0;
            }
          }
          const objectForDataset = {
            label: filteredByUser[0]?.userId?.name,
            fill: false,
            // borderColor: colors[idx],
            // borderColor: '#'+(Math.random()*0xFFFFFF<<0).toString(16),
            yAxisID: 'y',
            tension: .4,
            data,
          };
          idx += 1;
          datasets.push(objectForDataset);
        }
      });
      return datasets;
    };
    const multiAxisData = ref({});
    const getChartData = () => {
      transfersModule.list.getCampaignTransfers(route.params?.campaignID as string, rangeDates.value).then((data) => {
        dataSet.value = data;
        multiAxisData.value = {
          labels: getLabelsMonth(data),
          datasets: getDataByUsers(data, route.params.campaignID as string),
        };
      });
    };
    getChartData();

    const dataChartRef = ref();
    const downloadChart = () => {
      const a = document.createElement('a');
      a.href = dataChartRef.value?.getBase64Image();
      a.download = "campaign-performance.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    const multiAxisOptions = ref(
        {
          stacked: false,
          responsive: true,
          plugins: {
            legend: {
              labels: {
                color: '#495057',
              },
            },
          },
          scales: {
            x: {
              ticks: {
                color: '#495057',
              },
              grid: {
                color: '#ebedef',
              },
            },
            y: {
              type: 'linear',
              display: true,
              position: 'left',
              ticks: {
                color: '#495057',
              },
              grid: {
                color: '#ebedef',
              },
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              ticks: {
                color: '#495057',
              },
              grid: {
                drawOnChartArea: true,
                color: '#ebedef',
              },
            },
          },
        },
    );

    const reloadChart = () => {
      getChartData();
    };

    const changeDatesRange = () => {
      if (!rangeDates.value?.includes(null)) {
        getChartData();
      }
    };

    return {
      multiAxisData,
      multiAxisOptions,
      dataChartRef,
      downloadChart,
      reloadChart,
      rangeDates,
      changeDatesRange,
      dataSet,
    };
  },
});
