
import { applyPureReactInVue } from 'veaury'
import NewAuth from "@/react_app/NewAuth/NewAuth.jsx"
import OldAuth from "./OldAuth.vue"
import { isNewLoginPageEnabled } from '@/utils/globals/feature-flags';
import { useRouter } from "vue-router";
import { useAuth, useUsers } from "@/modules";
import { useAuthForm } from "@/pages/auth/modules/auth-form-module";
import { User } from "@/data/types";
import { useToast } from "primevue/usetoast";
import { SocialConnectResponse } from "@/pages/auth/social-def";

export default {
  name: 'Auth',
  components: {
    NewAuth: applyPureReactInVue(NewAuth),
    OldAuth
  },
  setup() {
    const { loadUser } = useAuth();
    const users = useUsers();
    const router = useRouter();
    const { connecting, userType } = useAuthForm();

    const loadUserData = (token: string, refresh: string, action?: string | "login" | "register", loginType?: string | "google" | "youtube" | "email", userType?: string | "brandOwner" | "creator" | "admin") => {
      const isSocialBrandOwner = (currentUserType: string) => {
        return loginType === "google" && currentUserType === "brandOwner";
      }
      const isSocialCreator = (currentUserType: string) => {
        return loginType === "youtube" && currentUserType === "creator";
      }
      
      const isEmailBrandOwner = (currentUserType: string) => {
        return loginType === "email" && currentUserType === "brandOwner";
      }
      const isisEmailCreator = (currentUserType: string) => {
        return loginType === "email" && currentUserType === "creator";
      }

      const setSocialIn = (currentUserType: string, hasFirsAndLastName: boolean) => {
        localStorage.setItem('socialIn', JSON.stringify({
          action,
          loginType,
          userType: currentUserType,
          hasFirsAndLastName
        }));
      }

      loadUser(token, refresh)
        .then((success?: User | undefined) => {
          const currentUserType = success ? success?.type : (userType || "brandOwner");
          if (success) {
            const hasFirstAndLastName = success?.firstName && success?.lastName;
            const hasBrandId = success?.brand?.id;
            const hasUserId = success?.id;
            if (isSocialBrandOwner(currentUserType) || isSocialCreator(currentUserType)) {
              if (action === "register") {
                setSocialIn(currentUserType, false)
              } else {
                setSocialIn(currentUserType, !!hasFirstAndLastName)
              }
            }
            if (isEmailBrandOwner(currentUserType) || isisEmailCreator(currentUserType)) {
              if (action === "register") {
                setSocialIn(currentUserType, !!hasFirstAndLastName)
              } else {
                setSocialIn(currentUserType, !!hasFirstAndLastName)
              }
            }
            if (!hasFirstAndLastName && userType !== 'admin') {
              setSocialIn(currentUserType, !!hasFirstAndLastName)
              setTimeout(() => {
                if (currentUserType === "brandOwner") {
                  if (hasBrandId) {
                    router.push({ name: 'brand', params: { brandID: success?.brand?.id } });
                  } else {
                    router.push({ name: 'dashboard' });
                  }
                } else {
                  if (currentUserType === "creator" && !hasFirstAndLastName && !!hasUserId) {
                    router.push({ name: 'creator-account', params: { creatorID: success?.id } });
                  } else {
                    router.push({ name: 'dashboard' });
                  }
                }
              }, 100);
            } else {
              setTimeout(() => {
                router.push({ name: 'dashboard' });
              }, 100);

            }
            // }
          } else {
            return toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error occurred. Try again.',
              life: 3000,
            });
          }
        });
    };
    const toast = useToast();

    const handleUserConnect = (response: SocialConnectResponse, action?: string | "login" | "register", loginType?: string | "google" | "youtube" | "email", userType?: string | "brandOwner" | "creator" | "admin") => {
      if (!response.tokens) {
        connecting.value = '';
        return toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'You are not registered, please register',
          life: 3000,
        });
      }
      return loadUserData(response.tokens.access.token, response?.tokens.refresh.token, action, loginType, userType);
    };

    const handleEmailSignIn = ({ email, password }: { email: string; password: string }) => {
      if (email && password) {
        connecting.value = 'email';
        users.manager
          .signin(email, password, 'user', userType.value)
          .then((response?: { tokens: any }) => {
            if (response?.tokens) {
              loadUserData(response?.tokens.access.token, response?.tokens.refresh.token, 'login', 'email', userType.value);
            } else {
              connecting.value = '';
            }
          });
      }
    };

    const handleAdminEmailSignIn = ({ email, password }: { email: string; password: string }) => {
      if (email && password) {
        connecting.value = 'email';
        users.manager
          .signin(email, password, 'admin', 'admin')
          .then((response?: { tokens: any }) => {
            if (response?.tokens) {
              loadUserData(response?.tokens.access.token, response?.tokens.refresh.token, 'login', 'admin', 'admin');
            } else {
              connecting.value = '';
            }
          });
      }
    };

    const handleRouting = (name: string) => {
      window.location.href = `/${name}`;
    };

    return {
      isNewLoginPageEnabled,
      handleRouting,
      handleEmailSignIn,
      handleAdminEmailSignIn,
      handleUserConnect,
      userType,
      connecting
    };
  },
};
