import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewInfluencerItem = _resolveComponent("NewInfluencerItem")!
  const _component_OldLatestInfluencersItem = _resolveComponent("OldLatestInfluencersItem")!

  return ($setup.isHomepageEnabled)
    ? (_openBlock(), _createBlock(_component_NewInfluencerItem, { key: 0 }))
    : (_openBlock(), _createBlock(_component_OldLatestInfluencersItem, {
        key: 1,
        item: $props.item,
        isFavorite: $props.isFavorite
      }, null, 8, ["item", "isFavorite"]))
}