import React from "react";
import "./NewLoginForm.css";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";

export default function (props) {
  const { errors, handleChange } = props;
  return (
    <div className={'login-form'}>
      <div className="p-mb-2">
        <p className={'inputFieldLabel'}>Email <span className="required">*</span></p>
        <InputText className={'inputField'} onChange={(e) => handleChange('email', e.target.value)} />
        <div className={'registerError'}>{errors?.email && <p>{errors?.email}</p>}</div>
      </div>
      <div>
        <p className={'inputFieldLabel'}>Password <span className="required">*</span></p>
        <Password onChange={(e) => handleChange('password', e.target.value)} feedback={false} toggleMask />
        <div className={'registerError'} >{errors?.password && <p>{errors?.password}</p>}</div>
      </div>
    </div>
  )
}
