
import InputText from 'primevue/inputtext';
import { ref } from 'vue';

export default {
  name: 'inputTalkingPoint',
  props: {
    indexInput: Number,
    valueInput: String,
  },
  components: {
    InputText,
  },
  setup(props: any) {
    const valueTalkingPointInput = ref(props.valueInput || '');
    return {
      valueTalkingPointInput,
    };
  },
};
