
import { ref, watch } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Avatar from 'primevue/avatar';
import Button from 'primevue/button';
import FileUpload from 'primevue/fileupload';
import Paginator from 'primevue/paginator';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import { useTransfers } from "@/modules";
import { CampaignPaymentMethod } from '@/data/types';
import { formatDate } from '@/utils/globals/date-utils';
import { ITransactionItem } from "@/data/types/payment-types";

export default {
  components: {
    Calendar,
    Dropdown,
    Paginator,
    FileUpload,
    DataTable,
    Column,
    Avatar,
    Button,
  },
  setup() {
    const transfersModule = useTransfers();

    const skip = ref(0);
    const limit = ref(50);
    const loadingUpload = ref('');
    const transfers = ref<Array<ITransactionItem>>();
    const totalCount = ref(0);
    const selectedQuery = ref('active');
    const queryesStatus = [{ name: 'Active', value: 'active' },
      { name: 'Cancelled', value: 'cancelled' }, { name: 'Paid', value: 'paid' }];
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1)
    const byMonth = ref(currentDate);

    transfersModule.list.getAdminTransfers(skip.value, limit.value, selectedQuery.value).then((data) => {
      transfers.value = data?.transfers;
      totalCount.value = (data?.counts || 1);
    });

    const uploadInvoice = async (id: any, { files }: { files: Array<File> }) => {
      loadingUpload.value = id;
      const formData = new FormData();
      formData.set('invoice', files[0]);
      const updatedTransfer = await transfersModule.manager.updateInvoice(id, formData);
      const currentEditedTransferIndex = transfers.value?.findIndex((tr) => tr?.id === updatedTransfer?.id);
      if (currentEditedTransferIndex && currentEditedTransferIndex >= 0 && updatedTransfer && transfers.value?.length) {
        transfers.value[currentEditedTransferIndex] = updatedTransfer;
      }
      loadingUpload.value = '';
    };

    const openInvoice = (data: ITransactionItem) => {
      window.open(data?.creditNode);
    }

    const changeTransferStatus = async (data: ITransactionItem, status: string) => {
      let conformValue = '';
      if (status === 'canceled') {
        conformValue = 'are you sure you want to cancel this transfer?';
      }
      if (status === 'active') {
        conformValue = 'Do you really want to return this transfer to "Active" status?';
      }
      if (status === 'paid') {
        conformValue = 'Are you sure you want to mark this transfer as "Paid"?';
      }
      const result = confirm(conformValue);
      if (result) {
        const canceled = await transfersModule.manager.changeTransferStatus(data?._id, status);
        const indexCanceled = transfers.value?.findIndex((tr) => tr?.id === canceled?.id) || -1;
        transfers.value?.splice(indexCanceled, 1);
      }
    };

    const pageChange = (data: { page: number }) => {
      skip.value = data?.page * limit.value;
      transfersModule.list.getAdminTransfers(skip.value, limit.value, selectedQuery.value).then((data) => {
        transfers.value = data?.transfers;
        totalCount.value = (data?.counts || 0 + 1);
      });
    };

    const changeStatusQuery = async () => {
      await transfersModule.list.getAdminTransfers(skip.value, limit.value, selectedQuery.value).then((data) => {
        transfers.value = data?.transfers;
        totalCount.value = (data?.counts || 0 + 1);
      });
    };

    watch(byMonth, async () => {
      await transfersModule.list.getAdminTransfers(skip.value, limit.value, selectedQuery.value,"", byMonth.value).then((data) => {
        transfers.value = data?.transfers;
        totalCount.value = (data?.counts || 0 + 1);
      });
    });



    return {
      changeStatusQuery,
      pageChange,
      changeTransferStatus,
      openInvoice,
      uploadInvoice,
      formatDate,
      byMonth,
      queryesStatus,
      selectedQuery,
      loadingUpload,
      CampaignPaymentMethod,
      transfers,
      totalCount,
    }
  }
}
