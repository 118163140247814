import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "campaigns-list p-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CampaignListItem = _resolveComponent("CampaignListItem")!
  const _component_EmptyList = _resolveComponent("EmptyList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.campaigns, (item) => {
      return (_openBlock(), _createBlock(_component_CampaignListItem, {
        key: item.id,
        class: "p-col-12 p-md-6 p-lg-6 p-xl-6 p-xxl-4 p-xxxl-3",
        item: item,
        onClick: ($event: any) => (_ctx.selectItem(item))
      }, null, 8, ["item", "onClick"]))
    }), 128)),
    (!_ctx.campaigns.length)
      ? (_openBlock(), _createBlock(_component_EmptyList, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}