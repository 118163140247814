import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewCampaigns = _resolveComponent("NewCampaigns")!
  const _component_OldCampaigns = _resolveComponent("OldCampaigns")!

  return (_ctx.isNewCampaignsPageEnabled)
    ? (_openBlock(), _createBlock(_component_NewCampaigns, {
        key: 0,
        campaigns: _ctx.campaigns,
        goToCampaign: _ctx.goToCampaign,
        isLoading: _ctx.isLoading,
        totalCount: _ctx.totalCount,
        limit: _ctx.limit,
        handlePageChange: _ctx.handlePageChange,
        manageSearchCampaign: _ctx.handleSearch
      }, null, 8, ["campaigns", "goToCampaign", "isLoading", "totalCount", "limit", "handlePageChange", "manageSearchCampaign"]))
    : (_openBlock(), _createBlock(_component_OldCampaigns, {
        key: 1,
        brandOwnerView: _ctx.brandOwnerView,
        showSearch: _ctx.showSearch,
        limit: _ctx.limit
      }, null, 8, ["brandOwnerView", "showSearch", "limit"]))
}