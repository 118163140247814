<template>
  <img src="../../campaigns/assets/images/ask_hint.svg" v-tooltip.top="tooltipText" class=" hint"/>
</template>

<script>
export default {
  name: 'AskHint',
  props: {
    tooltipText: String,
  },
};
</script>

<style scoped>
.hint {
  width: 20px;
}
</style>
