
import { defineComponent, ref, watch } from 'vue';
import {
  CampaignData,
  CampaignPaymentMethod, CampaignsEarningsExpenses,
} from '../../data/types/campaign-types';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Avatar from 'primevue/avatar';
import Paginator from 'primevue/paginator';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Chip from 'primevue/chip';
import Divider from 'primevue/divider';
import { calculateCpiRate } from '@/utils/globals/cpi-rate';
import { useAdmins, useCampaigns, useTransfers } from '../../modules';
import { compactNumber } from '../../utils/globals/curency-utils';
import { formatDate } from '../../utils/globals/date-utils';
import CampaignsCounterItem from '../campaigns/CampaignsCounterItem.vue';
import TrackingLinkInput from '../campaigns/parts/TrackingLinkInput.vue';
import StatusChip from '../commons/status/StatusChip.vue';
import MontlyEarningModal from '../../components/admin/MontlyEarningModal.vue';
import { useCreators } from '../../modules';
import { TrackingLink, User } from '@/data/types';
import GenericPrompt from '../utility/generic/GenericPrompt.vue';
import router from "@/router";

interface AppliedCreator {
  email: string;
  name: string;
}

export default defineComponent({
  components: {
    GenericPrompt,
    Dropdown,
    Button,
    Avatar,
    InputText,
    Paginator,
    Dialog,
    InputNumber,
    Divider,
    TrackingLinkInput,
    CampaignsCounterItem,
    MontlyEarningModal,
    StatusChip,
    DataTable,
    Column,
    Chip,
  },
  setup() {
    const adminsModule = useAdmins();
    const transfersModule = useTransfers();
    const { manager } = useCreators();

    adminsModule.manager.checkAdmin();
    adminsModule.manager.getCreatorsCount();
    adminsModule.manager.getBrandsCount();
    adminsModule.manager.getAdminsCount();
    const selectedAll = ref(false);

    const openBasic = ref(false);
    const selectedCampaignForActions = ref();
    const newCampaignStatus = ref();
    const newBudgetAmount = ref();
    const newBudgetCurrency = ref(
      selectedCampaignForActions.value?.budget?.currency
    );
    const showMonthly = ref('');
    const showAppliedCreatorsForSelectedCampaign = ref(false);
    const apiCallFinished = ref(false);
    const appliedCreatorsForSelectedCampaign = ref([] as AppliedCreator[]);
    const campaignExpenses = ref([]);
    const campaignExpensesLoaded = ref(false);

    const statuses = ref([
      {
        value: 'all',
        selected: false,
      },
      {
        value: 'active',
        selected: true,
      },
      {
        value: 'draft',
        selected: false,
      },
      {
        value: 'pending',
        selected: false,
      },
      {
        value: 'completed',
        selected: false,
      },
      {
        value: 'live',
        selected: false,
      },
    ]);

    const changeStatus = ref([
      {
        status: 'active',
      },
      {
        status: 'draft',
      },
      {
        status: 'pending',
      },
      {
        status: 'completed',
      },
      {
        status: 'live',
      },
    ]);

    const updateCampaignData = async (campaign: any, status: string, budget: number) => {
      await adminsModule.manager.updateCampaignData(
        campaign.id || campaign._id,
        {
          status: status ? status : campaign.status,
          budget: {
            amount: budget ? budget : campaign.budget?.amount,
            currency: campaign.budget?.currency,
          },
        }
      ).then(() => window.location.reload());
    };

    // Inside the campaigns there are tracking links. The tracking links store the user id only
    // Make an API call to fetch the necessary information to display user information.
    const parseAppliedCreators = async (
      campaignTrackingLinks: TrackingLink[]
    ) => {
      const creatorInfos: AppliedCreator[] = [];

      for await (const link of campaignTrackingLinks) {
        const { creatorId } = link;
        const user = (await manager.load(creatorId)) as User;

        creatorInfos.push({
          email: user.email,
          name: user.name,
          photo: user.profile.photo,
        } as AppliedCreator);
      }

      appliedCreatorsForSelectedCampaign.value = creatorInfos;
      apiCallFinished.value = true;
      return creatorInfos;
    };

    const limitCampaigns = ref(0);
    const skipCampaigns = ref(0);
    const priorityCampaignStatus = ref('active');
    const searchValueCampaign = ref('');

    watch(priorityCampaignStatus, () => {
      limitCampaigns.value = 0;
      skipCampaigns.value = 0;
      adminsModule.manager.getAllCampaigns(
        limitCampaigns.value,
        skipCampaigns.value,
        priorityCampaignStatus.value,
        searchValueCampaign.value
      );
    });

    adminsModule.manager.getAllCampaigns(
      limitCampaigns.value,
      skipCampaigns.value,
      priorityCampaignStatus.value
    );

    const searchCampaigns = (searchValue: string) => {
      limitCampaigns.value = 0;
      skipCampaigns.value = 0;
      adminsModule.manager.getAllCampaigns(
        limitCampaigns.value,
        skipCampaigns.value,
        priorityCampaignStatus.value,
        searchValue
      );
    };

    const onPage = (event: {
      page: number;
      first: number;
      rows: number;
      pageCount: number;
    }) => {
      limitCampaigns.value += 10;
      skipCampaigns.value = event.first;
      adminsModule.manager.getAllCampaigns(
        limitCampaigns.value,
        skipCampaigns.value,
        priorityCampaignStatus.value,
        searchValueCampaign.value
      );
    };

    const addPlatform = (platform: any) => {
      priorityCampaignStatus.value = platform.value;
      statuses.value.forEach((el) => {
        el.selected = false;

        if (el.value === platform.value) {
          el.selected = true;
        }
      });

      selectedAll.value = false;
    };

    watch(adminsModule.manager.getAllCampaignsResult, async () => {
      const campaignIDs =
        adminsModule.manager.getAllCampaignsResult.value?.campaigns?.map(
          (camp: CampaignData | any) => camp?._id
        );

      await getOverallTransfers(campaignIDs);

      const currentAppliedStatus = statuses.value.filter((status) => status.selected)[0]?.value;
      const campaignsExpensesResponse = await adminsModule.manager.getMultipleCampaignExpenses(limitCampaigns.value, skipCampaigns.value, currentAppliedStatus, false);
      campaignExpenses.value = campaignsExpensesResponse;
      campaignExpensesLoaded.value = true;
    });

    const getOverallTransfers = async (campaignIDs: Array<string>) => {
      await transfersModule.list.getOverallByCampaigns(campaignIDs);
    };

    const getCurrentCampaignExpenses = (campaignId: string) => {
      const expenses = campaignExpenses.value.filter((currentCampaignExpenses: CampaignsEarningsExpenses) => currentCampaignExpenses?.id === campaignId.toString()) as CampaignsEarningsExpenses[];
      return expenses.length > 0 ? expenses[0].totalAmount : 0;
    }

    const appliedCreatorsOnClose = () => {
      showAppliedCreatorsForSelectedCampaign.value = false;
      apiCallFinished.value = false;
      appliedCreatorsForSelectedCampaign.value = [];
    };

    return {
      apiCallFinished,
      appliedCreatorsOnClose,
      appliedCreatorsForSelectedCampaign,
      parseAppliedCreators,
      showAppliedCreatorsForSelectedCampaign,
      searchCampaigns,
      onPage,
      updateCampaignData,
      compactNumber,
      formatDate,
      addPlatform,
      calculateCpiRate,
      showMonthly,
      creatorsCounts: adminsModule.manager.getCreatorsCountResult,
      brandsCount: adminsModule.manager.getBrandsCountResult,
      adminsCount: adminsModule.manager.getAdminsCountResult,
      campaignsList: adminsModule.manager.getAllCampaignsResult,
      transfersByCampaigns: transfersModule.list.getOverallByCampaignsResult,
      getCurrentCampaignExpenses,
      campaignExpensesLoaded,
      campaignExpenses,
      statuses,
      priorityCampaignStatus,
      searchValueCampaign,
      limitCampaigns,
      openBasic,
      selectedCampaignForActions,
      changeStatus,
      newCampaignStatus,
      newBudgetAmount,
      newBudgetCurrency,
      selectedAll,
      CampaignPaymentMethod,
      defaultImage: require('../campaigns/assets/images/default.png'),
    };
  },
});
