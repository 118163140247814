
import { defineComponent, ref } from 'vue';
import LatestContentItem from '@/pages/creators/latest/LatestContentItem.vue';
import ContentRequestModal from '@/pages/campaigns/ContentRequestModal.vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { formatDate, addToDate } from '@/utils/globals/date-utils';
import moment from 'moment';
import { useAuth, useContentItems } from '../../modules/index';

export default defineComponent({
  name: 'content',
  components: {
    LatestContentItem,
    ContentRequestModal,
    Button,
    Dialog,
  },
  props: {
    items: Array,
    campaignClosed: {
      type: Boolean,
      default: false,
    },
    campaign: Object,
  },
  emits: ['requestSuccess'],
  setup(props, ctx) {
    const content = useContentItems();
    const { user } = useAuth();
    const requestItem = ref();

    // eslint-disable-next-line vue/no-setup-props-destructure
    const contentItems = (props.items);

    const showContentModal = ref(false);
    const requestPlatforms = ref('');
    const requestRegions = ref('');
    const requestDuration = ref('');

    const findStatus = (item: any) => {
      const currentOffer = item.licenseRequests[item.licenseRequests.findIndex((el: any) => el?.brand === user.value?.brand?.id)];
      return currentOffer?.status;
    };

    const showNeedPayModal = ref(false);

    const licenseRequest = async (platforms: any, regions: string, duration: any, price: any, itemId: string) => {
      requestRegions.value = regions;
      requestDuration.value = duration;
      requestPlatforms.value = platforms;
      await content.requests.request(itemId, {
        price: price?.amount,
        duration,
        platforms,
        startDate: new Date().toString(),
      }, props?.campaign?.id);
      showContentModal.value = false;
      ctx.emit('requestSuccess');
    };

    const isItemBuy = (item: any) => {
      const currentItemIndex = item.licenseRequests.findIndex((el: any) => el?.brand.toString() === user.value?.brand?.id.toString()
          && moment(new Date()).isBefore(addToDate(el?.startDate, el?.duration?.period, el?.duration?.type).toDate()));

      const perpetual = item.licenseRequests.findIndex((el: any) => el?.brand.toString() === user.value?.brand?.id
          && el?.duration?.perpetual);

      return {
        startDate: item.licenseRequests[perpetual >= 0 ? perpetual : currentItemIndex]?.startDate,
        accepted: perpetual >= 0 ? item.licenseRequests[perpetual]?.duration?.perpetual
          : item.licenseRequests[currentItemIndex]?.status === 'accepted',
        pending: item.licenseRequests[perpetual >= 0 ? perpetual : currentItemIndex]?.status === 'pending',
        duration: item.licenseRequests[perpetual >= 0 ? perpetual : currentItemIndex]?.duration,
      };
    };
    return {
      contentItems,
      addToDate,
      formatDate,
      isItemBuy,
      showContentModal,
      licenseRequest,
      requestRegions,
      requestDuration,
      requestPlatforms,
      showNeedPayModal,
      findStatus,
      requestItem,
    };
  },
});
