
import { useAuth, useFavorites } from '@/modules';
import { ref } from 'vue';
import { formatDate } from '../../../utils/globals/date-utils';

export default {
  name: 'TutorialItem',
  props: {
    item: Object,
  },
  setup(props: any, ctx: any) {
    const user = useAuth();
    const showModalTutorials = ref(false);
    const favoritesManager = useFavorites();
    const send = () => {
      ctx.emit('showModal');
    };
    return { formatDate, showModalTutorials, send };
  },
};
