import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e369672"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "social-connect" }
const _hoisted_2 = { class: "social-connect-list" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "p-m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_Button, {
          key: item.type,
          disabled: _ctx.platformDisabled(item) || _ctx.nameError,
          label: item.label || `${_ctx.action === 'login' ? 'sign in with' : 'Register with' } ${item.type}`,
          onClick: ($event: any) => (_ctx.openConnectPopup(item.type)),
          class: "social-connect-item p-button-sm"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              alt: "social-logo",
              src: _ctx.getIconClass(item.type),
              style: {"height":"90%"}
            }, null, 8, _hoisted_3),
            _createElementVNode("div", _hoisted_4, _toDisplayString(item.label || `${_ctx.action === 'login' ? 'sign in with' : 'Register with' } ${item.type}`), 1)
          ]),
          _: 2
        }, 1032, ["disabled", "label", "onClick"]))
      }), 128))
    ])
  ]))
}