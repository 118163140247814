import { watch } from 'vue';
import { useAPI } from '@/modules';
import { useToastModule } from '@/modules/messages/toast-module';

export const useCommonApiManager = () => {
  const toast = useToastModule();

  const {
    loading: getMasterDataLoading,
    error: getMasterDataError,
    data: getMasterDataResult,
    execute: getMasterDataRequest,
  } = useAPI('', false);
  const getMasterData = (type?:string) =>
    getMasterDataRequest({
      url: `/master-data/${type}`,
      method: 'GET',
    });
  watch(getMasterDataError, toast.handleRequestError);

  return {
    getMasterData,
    getMasterDataResult,
    getMasterDataError,
    getMasterDataLoading,
  };
};
