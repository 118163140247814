
import React, { useEffect, useState } from "react";

import { Image } from 'primereact/image';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';

import noImage from "@/react_app/img/no-image.png";

import "./NewCreatorEarning.css";
import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';

const creatorTemplate = (data) => {
  return (
    <div className='creatorTemplate'>
      <Image src={data.photo || noImage} alt='creator photo' className='creatorTemplatePhoto'></Image>
      <p className={'creatorTemplateName'}>{data.name}</p>
    </div>
  )
};

const totalMonthlyEarningsTemplate = (data) => {
  return (
    <div className="p-flex-column">
      <div className="p-d-flex row-conversions">
        <div>
          Android: <span>{data?.forPay?.androidConversions}</span>
        </div>
        <div>
          IOS: <span>{data?.forPay?.iosConversions}</span>
        </div>
      </div>

      <div className="p-d-flex row-conversions">
        <div>
          Clicks: <span>{data?.forPay?.clickConversions}</span>
        </div>
        <div>
          Views: <span>{data?.forPay?.viewsConversions}</span>
        </div>
      </div>

      <div className="p-d-flex row-conversions">
        <div>
          Amount: <span>{`${(data?.forPay?.totalAmount).toFixed(2)} ${(data?.forPay?.totalAmount) ? data?.currency : ''}`}</span>
        </div>
        <div>
          Without Commission: <span>{`${(data?.forPay?.withoutDeductedCommission).toFixed(2)} ${(data?.forPay?.withoutDeductedCommission) ? data?.currency : ''}`}</span>
        </div>
      </div>
    </div>
  )
};

const totalEarningTemplate = (data) => {
  return (
    <div className="p-flex-column">
      <div className="p-d-flex row-conversions">
        <div>
          Android: <span>{data?.totalConversions?.androidConversions}</span>
        </div>
        <div>
          IOS: <span>{data?.totalConversions?.iosConversions}</span>
        </div>
      </div>

      <div className="p-d-flex row-conversions">
        <div>
          Clicks: <span>{data?.totalConversions?.clickConversions}</span>
        </div>
        <div>
          Views: <span>{data?.totalConversions?.viewsConversions}</span>
        </div>
      </div>

      <div className="p-d-flex row-conversions">
        <div>
          Amount: <span>{`${(data?.totalConversions?.totalAmount).toFixed(2)}  ${(data?.totalConversions?.totalAmount) ? data?.currency : ''}`}</span>
        </div>
        <div>
          Without Commission: <span>{`${(data?.totalConversions?.withoutDeductedCommission).toFixed(2)}  ${(data?.totalConversions?.withoutDeductedCommission) ? data?.currency : ''}`}</span>
        </div>
      </div>
    </div>
  )
};


export default function (props) {
  const { earningList, loading, limit, pageChange } = props;

  const [noResults, setNoResults] = useState(false);
  const [first, setFirst] = useState(0);

  useEffect(() => {
    setNoResults(!(earningList))
  }, [earningList]);

  const onPageChange = (event) => {
    setFirst(event.first);
    pageChange(event)
  };

  return (
    <div className='creator-earnings'>
      <p className='title'>Creator Earnings</p>
      <DataTable value={earningList?.conversions} emptyMessage="No creator earning found." loading={loading}
        loadingIcon={
          <CustomLoader />
        }
        style={{ minHeight: 500 }}
      >
        <Column field="name" header="Creator" sortable body={creatorTemplate} style={{ width: '20%' }}></Column>
        <Column field="email" header="E-mail" sortable style={{ width: '20%' }}></Column>
        <Column field="userType" header="User Type" sortable style={{ width: '20%' }}></Column>
        <Column field="forPay" header="Monthly Earnings (Current)" sortable body={totalMonthlyEarningsTemplate}
          style={{ width: '20%' }}></Column>
        <Column field="totalConversions" header="Total Earnings" sortable body={totalEarningTemplate} style={{ width: '20%' }}></Column>
      </DataTable>
      {!noResults && <Paginator first={first} rows={limit} totalRecords={earningList?.totalResults} onPageChange={onPageChange} style={{ background: '#130A23' }} />}
    </div>
  )
}
