
import { useRoute } from 'vue-router';
import { useCampaigns } from '@/modules';
import StatusChip from '../commons/status/StatusChip.vue';
import PaymentMethods from './paymentMethods/PaymentMethods.vue';
import { parseApplicationTimeLeft } from '../../utils/globals/date-utils';

export default {
  name: 'PayAndFinishCampaign',
  components: {
    StatusChip,
    PaymentMethods,
  },
  setup() {
    const route = useRoute();
    const campaigns = useCampaigns();
    campaigns.manager.loadSingle(route.params.campaignID as string);

    return {
      campaign: campaigns.manager.singleCampaign,
      parseApplicationTimeLeft,
    };
  },
};
