
import { defineComponent, reactive, watch, ref } from 'vue';
import { AppPageConfig, AppPageProps } from '@/data/types';
import { usePage, useCampaigns, useAuth } from '@/modules';
import { compactNumber } from '@/utils/globals/curency-utils';
import { formatDate } from '@/utils/globals/date-utils';
import AppPage from "@/pages/commons/AppPage.vue";
import { applyPureReactInVue } from 'veaury';
import { isNewMyContentPageEnabled } from '@/utils/globals/feature-flags';
import OldMyCampaigns from "@/pages/campaigns/OldMyCampaigns.vue";
import NewMyCampaigns from "@/react_app/NewMyCampaigns/NewMyCampaigns.jsx";
import router from "@/router";

export default defineComponent({
  components: {
    NewMyCampaigns: applyPureReactInVue(NewMyCampaigns),
    OldMyCampaigns
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 0,
    },
    brandOwnerView: Boolean,
    ...AppPageProps,
    private: Boolean,
  },

  setup(props) {
    const { user } = useAuth();
    const { config } = usePage<AppPageConfig>({
      search: {
        enabled: props.showSearch,
        defaultType: 'campaigns',
        placeholder: 'Search for Campaign',
      },
      title: 'Campaign Management',
      ...props,
    });
    const editDate = ref(false);
    const page = useCampaigns(config.value.viewPerspective);
    const loadPageData = () => page.list.loadCampaigns(props.private, props.limit, '');
    const campaigns = ref();
    const filteredCampaigns = ref();
    const isAllSelected = ref();

    loadPageData().then((campaignsAll) => {
      if(campaignsAll){
      campaigns.value = campaignsAll;
      filteredCampaigns.value = campaignsAll;
    }else{
      campaigns.value = [];
      filteredCampaigns.value = [];
    }
    });
    const counters = reactive({ campaigns: 0, contracts: 0, applications: 0 });
    loadPageData().then((camp) => {
      if (camp) {
        const contracts = camp.map((m: any) => m.contracts).flat();
        const applications = camp.map((m: any) => m.applications).flat();
        counters.campaigns = camp.filter((campaign) => campaign?.status === 'active').length;
        counters.contracts = contracts.length;
        counters.applications = applications.length;
      }
    });
    watch(() => props.private, loadPageData);
    watch(editDate, () => loadPageData);

    const platformsOptions = ref([{
      value: 'Active Campaigns',
      type: 'active',
      selected: false,
    }, {
      value: 'Drafts',
      type: 'draft',
      selected: false,
    }, {
      value: 'Completed',
      type: 'completed',
      selected: false,
    }, {
      value: 'Pending',
      type: 'pending',
      selected: false,
    }]);
    const selectedAll = ref(false);
    const sendName = () => {
      filteredCampaigns.value = campaigns.value;
      const oneSelectedOrMore = platformsOptions.value.find((el) => el.selected);
      if (!oneSelectedOrMore) {
        filteredCampaigns.value = campaigns.value;
        return;
      }
      let allCampaigns: any = [];
      if (platformsOptions.value[0].selected === true) {
        allCampaigns = allCampaigns.concat(campaigns.value.filter((campaign: { status: string }) => campaign?.status === 'active'));
      }
      if (platformsOptions.value[1].selected === true) {
        allCampaigns = allCampaigns.concat(campaigns.value.filter((campaign: { status: string }) => campaign?.status === 'draft'));
      }
      if (platformsOptions.value[2].selected === true) {
        allCampaigns = allCampaigns.concat(campaigns.value.filter((campaign: { status: string }) => campaign?.status === 'completed'));
      }
      if (platformsOptions.value[3].selected === true) {
        allCampaigns = allCampaigns.concat(campaigns.value.filter((campaign: { status: string }) => campaign?.status === 'pending'));
      }
      filteredCampaigns.value = allCampaigns;
    };
    const selectAllPlatforms = () => {
      selectedAll.value = !selectedAll.value;
      if (selectedAll.value) {
        platformsOptions.value.forEach((el) => el.selected = true);
        filteredCampaigns.value = campaigns.value;
      } else {
        platformsOptions.value.forEach((el) => el.selected = false);
        filteredCampaigns.value = campaigns.value;
      }
    };
    const clearFilters = () => {
      platformsOptions.value.forEach((el) => el.selected = false);
      selectedAll.value = false;
    };
    const addPlatform = (element: any) => {
      const select = platformsOptions.value.findIndex((el) => el.value === element.value);
      if (platformsOptions.value[select].selected) {
        platformsOptions.value[select].selected = false;
      } else {
        platformsOptions.value[select].selected = true;
      }
    };
    const searchValueChange = (newValue: string) => {
      const lowercaseValue = newValue.toLowerCase();
      filteredCampaigns.value = campaigns.value.filter((campaign: any) =>
        campaign.name.toLowerCase().includes(lowercaseValue));
    };

    const newCampaignClick = () => {
      router.push({ name: 'create-campaign' });
    }

    const selectStatusCategory = (category: string) => {
      let allCampaigns: any = [];
      if(category !== 'all') {
        allCampaigns = allCampaigns.concat(campaigns.value.filter((campaign: { status: string }) => campaign?.status === category));
      } else {
        allCampaigns = campaigns.value;
      }

      filteredCampaigns.value = allCampaigns;
    }

    const goToCampaign = (id: string) => {
      router.push({ name: 'campaigns-stats', params: { campaignID: id } })
    }

    const routeTo = (name: string) => {
      router.push({ name: name })
    }

    const goBack = () => {
      router.push({ name: 'dashboard' });
    }

    return {
      sendName,
      filteredCampaigns,
      campaigns,
      searchValueChange,
      selectedAll,
      addPlatform,
      clearFilters,
      selectAllPlatforms,
      platformsOptions,
      editDate,
      config,
      loadPageData,
      loading: page.list.loading,
      isAllSelected,
      counters,
      compactNumber,
      formatDate,
      user,
      isNewMyContentPageEnabled,
      newCampaignClick,
      selectStatusCategory,
      goToCampaign,
      routeTo,
      goBack
    };
  },
});
