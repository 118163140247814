import React, { useState } from 'react';
import "./NewInvitations.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { CampaignPaymentMethod } from "@/data/types";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { CloseIcon, WarningIcon } from "@/react_app/img/Icons";

const brandBodyTemplate = (rowData) => {
  return (
    <div className={'brandTemplate'}>
      <Image src={rowData.brand.photo} alt={'brand photo'} className={'brandTemplatePhoto'}/>
      {rowData.brand.name}
    </div>
  );
};

const budgetBodyTemplate = (rowData) => {
  return (
    <div className={'budgetTemplate'}>
      $ {rowData.budget.amount}
    </div>
  );
};

const ratesBodyTemplate = (rowData) => {
  return (
    <div className={'ratesTemplate'}>
      {rowData.paymentMethod === CampaignPaymentMethod.CPM &&
        <div>
          CPM rates: $ {rowData.cpm.amount}<br/>
          Payout cap: $ {rowData.cpm.payoutCap}
        </div>
      }
      {rowData.paymentMethod === CampaignPaymentMethod.CPI &&
        <div>
          Android: $ {rowData.cpi.android.amount}<br/>
          iOS: $ {rowData.cpi.ios.amount}
        </div>
      }
      {rowData.paymentMethod === CampaignPaymentMethod.CPC &&
        <div>
          Click: $ {rowData.cpc.amount}<br/>
        </div>
      }
    </div>
  );
};

const statusBodyTemplate = (rowData) => {
  return (
    <div className={'statusTemplate'}>
      {rowData.invitations[0].status === 'pending' &&
        <div className={'statusPending'}>Pending</div>
      }
      {rowData.invitations[0].status === 'rejected' &&
        <div className={'statusRejected'}>Rejected</div>
      }
      {rowData.invitations[0].status === 'accepted' &&
        <div className={'statusAccepted'}>Accepted</div>
      }
    </div>
  );
};

export default function(props) {
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [openDeclineDialog, setOpenDeclineDialog] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(undefined);

  const campaignBodyTemplate = (rowData) => {
    return (
      <div className={'campaignTemplate'} onClick={() => props.goToCampaign(rowData.id)}>
        <Image src={rowData.photo} alt={'campaign cover'} className={'campaignTemplatePhoto'}/>
        {rowData.name}
      </div>
    );
  };

  const buttonsBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.invitations[0].status === 'pending' &&
          <div className={'buttonsTemplate'}>
            <Button className={'acceptButton'} onClick={() => { setOpenAcceptDialog(true); setSelectedCampaign(rowData) }}>Accept Invite</Button>
            <Button className={'declineButton'} onClick={() => { setOpenDeclineDialog(true); setSelectedCampaign(rowData)}}>Decline Invite</Button>
          </div>
        }
        {rowData.invitations[0].status === 'rejected' &&
          <div className={'buttonsTemplate'}>
            <Button className={'acceptButton noBackground'} onClick={() => { setOpenAcceptDialog(true); setSelectedCampaign(rowData) }}>Accept Invite</Button>
          </div>
        }
        {rowData.invitations[0].status === 'accepted' &&
          <div className={'buttonsTemplate'}>
            <Button className={'declineButton'} onClick={() => props.goToCampaign(rowData.id)}>Go To Campaign</Button>
          </div>
        }
      </div>
    );
  };

  const deleteInvitation = async (campaign, invitation) => {
    await props.updateStatusInvitation(campaign, invitation, 'rejected');
    setOpenDeclineDialog(false);
  };

  const acceptInvitation = async (campaign, invitation) => {
    await props.updateStatusInvitation(campaign, invitation, 'accepted');
    setOpenAcceptDialog(false);
  };


  return (
    <div className={'invitations'}>
      <p className={'title'}>Campaign Invitations</p>
      <div className={'table'}>
        <DataTable value={props.campaigns} removableSort emptyMessage={"No results found."}>
          <Column field={"name"} header={"Campaign"} body={campaignBodyTemplate} style={{ width: '33%' }}></Column>
          <Column field={"brand.name"} header={"Brand"} body={brandBodyTemplate} style={{ width: '11%' }}></Column>
          <Column field={"budget"} header={"Budget"} body={budgetBodyTemplate} style={{ width: '11%' }}></Column>
          <Column field={""} header={"Cap"} body={ratesBodyTemplate} style={{ width: '11%' }}></Column>
          <Column field={"status"} header={"Status"} body={statusBodyTemplate} style={{ width: '11%' }}></Column>
          <Column field={""} header={""} body={buttonsBodyTemplate} style={{ width: '24%' }}></Column>
        </DataTable>
      </div>
      <Dialog visible={openAcceptDialog} onHide={() => setOpenAcceptDialog(false)} className={'dialogInvitation'}>
        <div className={'dialogTop'}>
          <div onClick={() => setOpenAcceptDialog(false)}><CloseIcon/></div>
        </div>
        <p className={'dialogTitle'}>Accept invitation</p>
        <p className={'dialogText'}>Are you sure you want to accept this invitation?</p>
        <div className={'buttonGroup'}>
          <Button className={'noButton'} onClick={() => setOpenAcceptDialog(false)}>No</Button>
          <Button className={'yesButton backgroundLime'} onClick={() => acceptInvitation(selectedCampaign, selectedCampaign.invitations[0])}>Yes</Button>
        </div>
      </Dialog>
      <Dialog visible={openDeclineDialog} onHide={() => setOpenDeclineDialog(false)} className={'dialogInvitation'}>
        <div className={'dialogTop'}>
          <div onClick={() => setOpenDeclineDialog(false)}><CloseIcon/></div>
        </div>
        <p className={'dialogTitle'}>Decline invitation</p>
        <WarningIcon/>
        <p className={'dialogText'}>Are you sure you want to drop this invitation?</p>
        <div className={'buttonGroup'}>
          <Button className={'noButton'} onClick={() => setOpenDeclineDialog(false)}>No</Button>
          <Button className={'yesButton'} onClick={() => deleteInvitation(selectedCampaign, selectedCampaign.invitations[0])}>Yes</Button>
        </div>
      </Dialog>
    </div>
  )
}
