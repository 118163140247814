import React, { useEffect, useRef, useState } from 'react';
import "./NewLoginSocials.css";
import youtube from "../img/youtube.png"
import google from "../img/google.png"
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { openSocialPopup } from '@/utils/globals/popup-utils';
import { UserType } from "@/data/types";

export default function (props) {
  const [connectingPlatform, setConnectingPlatform] = useState('');
  const toast = useRef(null);

  useEffect(() => {
    setConnectingPlatform(props.connecting || '');
  }, [props.connecting]);

  const popupError = (error) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: error, life: 3000 });
  }

  const platformDisabled = (item) => {
    const localUserType = localStorage.getItem('login-view-Type');

    if (connectingPlatform || props.disabled || item.disabled) {
      return true;
    }
    if (props.action === 'login' && localUserType) {
      return item.forUser !== localUserType
    }

    if (props.action === 'login') {
      return false;
    }

    return item.forUser !== props.userType;
  };

  const openConnectPopup = (network) => {
    setConnectingPlatform(network);

    openSocialPopup(
      network,
      { type: props.userType, action: props.action, name: props.username },
      async (err, response) => {
        if (err) {
          let errorMessage = err.message;
          if (err.message === 'popup-closed') {
            errorMessage = 'Popup was closed. You should select your social profile and complete all the steps. Please try again!';
            popupError(errorMessage);
          }

          setConnectingPlatform('');
        } else {
          if (response && response.source && response.source === 'vue-devtools-backend') {
            return;
          }

          if (!response) {
            console.warn('Login :: no error, but user is missing!');
            return;
          }

          props.onConnect(response, props.action, network, props.userType);
        }
      }
    );
  };

  return (
    <div className={'login-socials'}>
      <Button className={'socialsSignIn'} disabled={platformDisabled({ type: 'google', forUser: UserType.BrandOwner })} onClick={() => openConnectPopup('google')}>
        <img alt="google-logo" src={google} className={'socialsSignInIcon'} />
        Sign in with Google
      </Button>
      <Button className={'socialsSignIn'} disabled={platformDisabled({ type: 'youtube', forUser: UserType.Creator })} onClick={() => openConnectPopup('youtube')}>
        <img alt="youtube-logo" src={youtube} className={'socialsSignInIcon'} />
        Sign in with Youtube
      </Button>
      <Toast ref={toast} position="top-center" />
    </div>
  )
}
