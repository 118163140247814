import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30255ec1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "p-flex-column" }
const _hoisted_5 = { class: "p-d-flex row-conversions" }
const _hoisted_6 = { class: "p-d-flex row-conversions" }
const _hoisted_7 = { class: "p-d-flex row-conversions" }
const _hoisted_8 = { class: "p-flex-column" }
const _hoisted_9 = { class: "p-d-flex row-conversions" }
const _hoisted_10 = { class: "p-d-flex row-conversions" }
const _hoisted_11 = { class: "p-d-flex row-conversions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewCreatorEarnings = _resolveComponent("NewCreatorEarnings")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_ctx.isNewAdminEnabled)
    ? (_openBlock(), _createBlock(_component_NewCreatorEarnings, {
        key: 0,
        earningList: _ctx.data,
        limit: _ctx.limit,
        loading: _ctx.loading,
        pageChange: _ctx.onPageChange
      }, null, 8, ["earningList", "limit", "loading", "pageChange"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_DataTable, {
          value: _ctx.data?.conversions,
          tableStyle: "min-width: 90vw"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "name",
              header: "Name"
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("img", {
                    src: data?.photo,
                    alt: "user logo",
                    class: "user-logo"
                  }, null, 8, _hoisted_3),
                  _createElementVNode("span", null, _toDisplayString(data?.name), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "email",
              header: "E-mail"
            }),
            _createVNode(_component_Column, {
              field: "userType",
              header: "User Type"
            }),
            _createVNode(_component_Column, {
              field: "forPay",
              header: "Monthly Earnings ( Current )"
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", null, [
                      _createTextVNode(" Android: "),
                      _createElementVNode("span", null, _toDisplayString(data?.forPay?.androidConversions), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createTextVNode(" IOS: "),
                      _createElementVNode("span", null, _toDisplayString(data?.forPay?.iosConversions), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", null, [
                      _createTextVNode(" Clicks: "),
                      _createElementVNode("span", null, _toDisplayString(data?.forPay?.clickConversions), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createTextVNode(" Views: "),
                      _createElementVNode("span", null, _toDisplayString(data?.forPay?.viewsConversions), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", null, [
                      _createTextVNode(" Amount: "),
                      _createElementVNode("span", null, _toDisplayString(data?.forPay?.totalAmount) + " " + _toDisplayString(data?.currency), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createTextVNode(" Without Commission: "),
                      _createElementVNode("span", null, _toDisplayString(data?.forPay?.withoutDeductedCommission) + " " + _toDisplayString(data?.currency), 1)
                    ])
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "totalConversions",
              header: "Total Earnings"
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", null, [
                      _createTextVNode(" Android: "),
                      _createElementVNode("span", null, _toDisplayString(data?.totalConversions?.androidConversions), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createTextVNode(" IOS: "),
                      _createElementVNode("span", null, _toDisplayString(data?.totalConversions?.iosConversions), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", null, [
                      _createTextVNode(" Clicks: "),
                      _createElementVNode("span", null, _toDisplayString(data?.totalConversions?.clickConversions), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createTextVNode(" Views: "),
                      _createElementVNode("span", null, _toDisplayString(data?.totalConversions?.viewsConversions), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", null, [
                      _createTextVNode(" Amount: "),
                      _createElementVNode("span", null, _toDisplayString(data?.totalConversions?.totalAmount) + " " + _toDisplayString(data?.currency), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createTextVNode(" Without Commission: "),
                      _createElementVNode("span", null, _toDisplayString(data?.totalConversions?.withoutDeductedCommission) + " " + _toDisplayString(data?.currency), 1)
                    ])
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"]),
        _createVNode(_component_Paginator, {
          rows: _ctx.limit,
          totalRecords: _ctx.data?.totalResults,
          onPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPage($event)))
        }, null, 8, ["rows", "totalRecords"])
      ]))
}