
import { defineComponent, ref, watch } from 'vue';

import { usePage } from '@/modules';
import { useContentItems } from '@/modules/content-items/content-items-module';
import RespondToNegotiation from '@/pages/commons/modals/RespondToNegotiationModal.vue';
import { AppPageConfig, AppPageProps } from '@/data/types';
import AppPage from '../../commons/AppPage.vue';
// import LicenseRequestsList from './LicenseRequestsList.vue';
import ActionsForRequestLicense from './ActionsForRequestLicense.vue';

export default defineComponent({
  components: { RespondToNegotiation, AppPage, ActionsForRequestLicense }, //LicenseRequestsList
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    ...AppPageProps,
    private: Boolean,
  },

  setup(props) {
    const { config } = usePage<AppPageConfig>({
      title: 'License Requests',
      ...props,
    });

    const controller = useContentItems(config.value.viewPerspective);
    const loadPageData = () => controller.requests.loadRequests();

    loadPageData();
    watch(() => props.private, loadPageData);

    // responding to a request
    const showResponseModal = ref(false);
    const oferN = ref();
    const payLoad = ref();
    const showModalActions = ref<string>('');

    const openRequestModal = ({ item, request, offer }: any) => {
      config.value.title = 'Content License Request';
      oferN.value = offer;
      payLoad.value = { item, request };
      showResponseModal.value = true;
    };
    const counterOffer = async (data: any) => {
      const newCounterOfferObj = {
        action: 'counter',
        final: false,
        perspective: 'creator',
        ...data,
      };
      await controller.requests.respond(
        payLoad.value?.item?.id,
        payLoad.value?.request?.id,
        newCounterOfferObj
      );
      showModalActions.value = '';
    };
    const handleRequestModalResponse = ({ item, request, ...payload }: any) => {
      const { action } = payload;
      if (action === 'counter') {
        showModalActions.value = 'counter';
        return;
      }
      controller.requests
        .respond(item.id, request.id, payload)
        .then((success) => {
          if (success) {
            loadPageData(); // refresh list

            showResponseModal.value = false;

            if (action === 'accept') {
              showModalActions.value = 'accept';
            } else if (action === 'reject') {
              showModalActions.value = 'reject';
            } else if (action === 'counter') {
              showModalActions.value = 'counter';
            }
          }
        });
    };

    return {
      config,
      controller,
      loading: controller.requests.requestsLoading,
      requests: controller.requests.requests,
      // responding
      showResponseModal,
      oferN,
      payLoad,
      openRequestModal,
      counterOffer,
      handleRequestModalResponse,
      showModalActions,
    };
  },
});
