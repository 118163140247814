import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f43dc5ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-justify-between p-col-12 p-search" }
const _hoisted_2 = { class: "p-mb-5 p-mr-6" }
const _hoisted_3 = { class: "p-mr-5" }
const _hoisted_4 = { class: "p-d-flex p-flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_LatestCampaignItem = _resolveComponent("LatestCampaignItem")!
  const _component_EmptyList = _resolveComponent("EmptyList")!
  const _component_app_list_page = _resolveComponent("app-list-page")!

  return (_openBlock(), _createBlock(_component_app_list_page, {
    title: 'Campaigns',
    pageListEndpoint: "/campaigns/",
    showSearch: _ctx.showSearch,
    searchType: "campaigns",
    searchEndpoint: "/campaigns",
    onSearchResult: _ctx.updateSearchResults,
    onClearSearch: _ctx.clearSearch,
    "show-search-barwith-browse": true,
    style: {"padding-left":"70px"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.campaigns?.length) + " Results", 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _createTextVNode("Order by: "),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedOrder,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOrder) = $event)),
              options: _ctx.orders,
              optionLabel: "name",
              optionValue: "code"
            }, null, 8, ["modelValue", "options"])
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.campaigns, (item, index) => {
          return (_openBlock(), _createBlock(_component_LatestCampaignItem, {
            key: index,
            item: item,
            class: "p-mb-6"
          }, null, 8, ["item"]))
        }), 128))
      ]),
      (!_ctx.campaigns?.length)
        ? (_openBlock(), _createBlock(_component_EmptyList, {
            key: 0,
            title: "No Campaigns"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["showSearch", "onSearchResult", "onClearSearch"]))
}