
import { defineComponent, PropType } from 'vue';
import { CampaignData } from '../../../data/types';

export default defineComponent({
  name: 'CampaignPhoto',

  props: {
    campaign: {
      type: Object as PropType<CampaignData>,
    },
    campaignPhoto: {
      type: String,
    },
  },
});
