
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import { ref } from 'vue';
import LicenseInfluencer from '@/pages/creators/LicenseInfluencer.vue';
import TermsModal from '@/pages/creators/TermsModal.vue';
import { Perspective } from '../../data/types/page-types';

export default {
  components: {
    LicenseInfluencer,
    TermsModal,
    Dropdown,
    InputText,
  },
  props: {
    user: Object,
    viewPerspective: String,
  },
  setup(props: any, ctx: any) {
    const searchValueInput = ref('');
    const showLicense = ref(false);
    const showTerms = ref(false);
    const aMember = ref(false);
    const closeAndOpen = () => {
      showLicense.value = false;
      showTerms.value = true;
    };
    const closeTermsModal = () => {
      showTerms.value = false;
      aMember.value = true;
    };
    const typeSearchOptionsCreator = ref([{
      value: 'campaigns',
    }]);
    const typeSearchOptionsBrand = ref([{
      value: 'influencers',
    }]);
    const typeSearch = ref(props?.viewPerspective === Perspective.CREATOR || props?.viewPerspective === Perspective.AGENCY
      ? typeSearchOptionsCreator.value[0].value
      : typeSearchOptionsBrand.value[0].value);
    const searchMethod = (searchValueInputh: string, typeSearchh: string) => setTimeout(() => {
      ctx.emit('search', searchValueInputh, typeSearchh);
    }, 500);

    const handleSwitch = () => {
      localStorage.setItem('new-design-toggle', 'activated');
      location.reload();
    }

    return {
      searchMethod,
      typeSearch,
      typeSearchOptionsCreator,
      typeSearchOptionsBrand,
      searchValueInput,
      showLicense,
      aMember,
      showTerms,
      Perspective,
      closeAndOpen,
      closeTermsModal,
      handleSwitch
    };
  },
};
