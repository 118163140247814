
import { defineComponent } from 'vue';
import CampaignsCounterItemNew from '@/pages/campaigns/CampaignsCounterItemNew.vue';
import CampaignsCounterItemSwitch from '@/pages/campaigns/CampaignsCounterItemSwitch.vue';

export default defineComponent({
  components: { CampaignsCounterItemNew, CampaignsCounterItemSwitch },
  props: {
    counters: {
      type: Object,
      default: null,
    },
  },
});
