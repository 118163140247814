import React, { useEffect, useRef, useState } from "react";

import { Image } from "primereact/image";
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { FileUpload } from 'primereact/fileupload';

import { useToastModule } from '@/modules';
import { checkForImageValidations } from '@/utils/campaign-utils';
import NewCampaignCoverCropper from "@/react_app/NewCampaignCoverCropper/NewCampaignCoverCropper";

import "./NewCampaignCoverModal.css";

export default function (props) {

  const { coverImage, close, visible, setCoverImage } = props;

  const fileUploadRef = useRef();
  const toast = useToastModule();
  const [newCover, setNewCover] = useState('');
  const [openCropper, setOpenCropper] = useState(false);
  const [availablePhotos, setAvailablePhotos] = useState('');

  useEffect(() => {
    setNewCover(coverImage);
    const noImageUrl = '/img/no-image.cdd31067.png';
    setAvailablePhotos(coverImage !== noImageUrl ? coverImage : '');
  }, [coverImage, close]);


  const handleFileUpload = async ({ files } = event) => {
    const [uploadPhotoInput] = files;
    if (uploadPhotoInput) {
      const file = uploadPhotoInput;
      const fileCheck = await checkForImageValidations(file);
      if (fileCheck instanceof Error) {
        toast.showError(fileCheck.message)
        event.target.value = null;
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setAvailablePhotos(reader.result)
          setNewCover(reader.result);
        };
        reader.readAsDataURL(file);
      }
      fileUploadRef.current.clear();
    }
  };

  return (
    <Dialog className='campaign-cover-modal' visible={visible} onHide={close}>
      <div className='top-layer'>
        <i className="pi pi-times" style={{ fontSize: '25px', color: "#fff", cursor: "pointer" }} onClick={close} />
      </div>
      <div className='content'>
        <p className='title'>Cover Image</p>
        <p className='subTitle'>Upload a nice image for your content to make it more wanted!</p>
        <div className='contentMain'>
          <div className='contentLeft'>
            <Image src={newCover} alt='cover image' className='coverImage' />
            <Button disabled={!availablePhotos} className='cropButton' onClick={() => setOpenCropper(true)}>Crop Image</Button>
          </div>
          <div className='contentRight'>
            <p className='label'>Select a new image</p>
            <div className='photosGrid'>
              <div className='addedPhotoWrapper'>
                <Image className={availablePhotos ? 'addedPhotoSelected' : ''} src={availablePhotos} alt='cover option' />
              </div>
              <FileUpload ref={fileUploadRef} mode="basic" name="photo"
                accept="image/*" auto={true} customUpload={true} uploadHandler={handleFileUpload}>
              </FileUpload>
            </div>
          </div>
        </div>
        <div className='buttonLayer'>
          <Button className='saveButton' disabled={!availablePhotos} onClick={() => { setCoverImage(newCover); close() }}>Save Changes</Button>
        </div>
      </div>
      <NewCampaignCoverCropper visible={openCropper} close={() => setOpenCropper(false)} image={newCover} setNewCover={setNewCover} />
    </Dialog>
  )
}
