
import { PropType, defineComponent, ref, reactive } from 'vue';
import { CampaignData, ContentItem } from '@/data/types';
import { formatDate } from '@/utils/globals/date-utils';
import { useAuth } from "@/modules";

export default defineComponent({
  name: 'ContentItemsListItem',
  components: {},
  props: {
    item: {
      type: Object as PropType<ContentItem>,
      required: true,
    },
    campaign: Object as PropType<CampaignData>,
  },
  setup(props) {
    const { user } = useAuth();
    const findTrackingLinkForItem = (item: any) => {
      if (props?.campaign) {
        const creatorId = item?.owner?.id;
        const channelId = item?.channelId;

        if (channelId) {
          return props.campaign?.trackingLinks?.filter((link: any) => link?.channelId === channelId);
        }
        return props.campaign?.trackingLinks?.filter((link: any) => link?.owner?.id === creatorId);
      }
      return undefined;
    };

    const currentLinkStats = () => {
      if (!props?.campaign) {
        return;
      }
      const campaign = props?.campaign as any;
      campaign.trackingLinks = findTrackingLinkForItem(campaign);
      const creatorId = props?.item?.owner?.id;
      const channelId = props?.item?.channelId;

      const allowedRegions: string[] = [];
      campaign.desiredRegions.forEach((region: { value: string }) => {
        if (region.value === 'Tier 1 - Global') {
          allowedRegions.push('tierOne');
        }
        if (region.value === 'Tier 2 - Europe') {
          allowedRegions.push('tierTwo');
        }
        if (region.value === 'Tier 3 - CEE') {
          allowedRegions.push('tierThree');
        }
        if (region.value === 'ASIA & CASA') {
          allowedRegions.push('tierFour');
        }
      });
      const currentStats = reactive({
        android: 0,
        IOS: 0,
        clicks: 0,
        conversions: 0,
      });

      campaign.trackingLinks.forEach((currentTrackingLink: any) => {
        allowedRegions.forEach((el: string) => {
          if (channelId) {
            if (currentTrackingLink?.channelId === channelId) {
              currentStats.IOS += currentTrackingLink[el].IOS || 0;
              currentStats.android += currentTrackingLink[el].android || 0;
              currentStats.clicks += currentTrackingLink[el].clicks || 0;
              currentStats.conversions += currentTrackingLink[el].conversions || 0;
            }
          }
          if (!channelId) {
            if (currentTrackingLink?.creatorId === creatorId) {
              currentStats.IOS += currentTrackingLink[el].IOS || 0;
              currentStats.android += currentTrackingLink[el].android || 0;
              currentStats.clicks += currentTrackingLink[el].clicks || 0;
              currentStats.conversions += currentTrackingLink[el].conversions || 0;
            }
          }
        });
      });
      // eslint-disable-next-line consistent-return
      return currentStats;
    };
    const currentStats = ref();
    currentStats.value = currentLinkStats();

    return {
      user,
      formatDate,
      currentStats,
    };
  },
});
