<template>
  <div class="card">
    <div class="text">
      <div class="title">
        <div class="tooltip">
          <div class="tooltip-spacing">
            <slot name="body"></slot>
          </div>
        </div>
        <svg class="popup-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 130" height="130" width="300">
          <path
              d="M36.5 12.695c15.9-2.4 32.556-4.284 82.977-3.815 79.67.74 121.785.26 145.294 5.51 18.483 4.13 34.333 11.696 33.382 32.11l-1.696 36.39c-1.01 21.68-11.678 29.377-21.934 30.838-14.884 2.12-29.72 3.52-54.512-.848C232.522 118.263 233.5 129 233.5 129s-1.992-7.686-32.218-14c-17.933-5.043-118.204 3.687-163.51-2.544-21.317-2.932-33.706-8.26-34.228-27.022L2.272 39.717c-.46-16.58 12.34-23.718 34.23-27.022z"
              fill="#303030" stroke="#000"/>
        </svg>
        <svg class="popup-outline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 130" height="130" width="300">
          <g stroke-width="2" stroke-linecap="round">
            <path class="popup-outline-left"
                  d="M233.5 129s-1.992-7.686-32.218-14c-17.933-5.043-118.204 3.687-163.51-2.544-21.317-2.932-33.706-8.26-34.228-27.022L2.272 39.717c-.46-16.58 12.34-23.718 34.23-27.022 15.897-2.4 32.554-4.284 82.975-3.815"
                  fill="none" stroke="#303030"/>
            <path class="popup-outline-right"
                  d="M119.477 8.88c79.67.74 121.785.26 145.294 5.51 18.483 4.13 34.333 11.696 33.382 32.11l-1.696 36.39c-1.01 21.68-11.678 29.377-21.934 30.838-14.884 2.12-29.72 3.52-54.512-.848C232.522 118.263 233.5 129 233.5 129"
                  fill="none" stroke="#303030"/>
          </g>
        </svg>
        <div class="popup-text">
          Feel free to shorten the link or even turn it into a QR code to add to your content and description to encourage engagement!
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 336px;
}

.top {
  background-color: #eee;
  border-radius: 20px;
  margin: 16px 0;
  width: 260px;
}

.text {
  box-sizing: border-box;
  padding: 0 20px 20px;
  width: 100%;
}

.title {
  align-items: center;
  display: flex;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 4px;
  position: relative;
}

.tooltip {
  font-size: 16px;
  font-weight: normal;
  margin-top: 2px;
  position: relative;
}

.tooltip:hover {
  cursor: help;
}

.tooltip-spacing {
  position: relative;
  display: flex;
}

.popup-bg {
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16)) drop-shadow(0 3px 6px rgba(0, 0, 0, 0.23));
  position: absolute;
  top: -126px;
}

.popup-outline {
  position: absolute;
  top: -126px;
}

.popup-text {
  border-radius: 12px;
  box-sizing: border-box;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  left: 8px;
  opacity: 0;
  position: absolute;
  top: -117px;
  transition: opacity 240ms 120ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 292px;
  padding: 10px;
}

.tooltip:hover ~ .popup-text {
  display: block;
}

.popup-bg {
  opacity: 0;
  transition: opacity 240ms 120ms cubic-bezier(0.4, 0, 0.2, 1);
}

.popup-outline-left {
  stroke-dasharray: 0 426px;
  stroke-dashoffset: 1px;
  transition: stroke-dasharray 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.popup-outline-right {
  stroke-dasharray: 352px 352px;
  stroke-dashoffset: -352px;
  transition: stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.tooltip:hover ~ .popup-text {
  opacity: 1;
}

.tooltip:hover ~ .popup-bg {
  opacity: 1;
  transition: opacity 240ms 120ms cubic-bezier(0.4, 0, 0.2, 1);
}

.tooltip:hover ~ .popup-outline .popup-outline-left {
  stroke-dasharray: 426px 426px;
  transition: stroke-dasharray 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.tooltip:hover ~ .popup-outline .popup-outline-right {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

</style>
