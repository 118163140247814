import React, { useEffect, useState } from 'react';

import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';

import { useAuth } from '@/modules';
import noImage from "../img/no-image.png";
import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';

import './NewAdminNotification.css';

function NewAdminNotification(props) {
  const {
    notificationList,
    notificationMethods,
    searchNotifications,
    loading,
    socketMethods,
    totalCount,
    limit,changePage
  } = props;

  const { user } = useAuth();
  const { type } = user?.value;
  const [time, setTime] = useState('');
  const [first, setFirst] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [noResults, setNoResults] = useState(false);

  const handleSearch = (value) => {
    clearTimeout(time);
    setSearchValue(value);
    setTime(
      setTimeout(() => {
        searchNotifications(value);
      }, 800)
    );
  };
  const onPageChange = ({ first }) => {
    setFirst(first);
    changePage(first);
  };
  const handleNotification = (notification) => {
    if (notification.status === 'new')
      socketMethods.changeNotifyStatusRead(notification.id);
    if (notificationMethods.methods.hasOwnProperty(notification.type)) {
      notificationMethods.methods[notification.type](notification, type);
    }
  };
  useEffect(() => {
    setNoResults(!(notificationList?.length))
  }, [notificationList]);

  return (
    <div className="adminNotification">
      <p className="title">Notifications</p>
      <div className="contentMain">
        <div className="searchBar">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              placeholder="Search for notification"
              onChange={(e) => handleSearch(e.target.value)}
              value={searchValue}
            />
          </span>
        </div>
        {loading ? (
          <div className="pendingLoader">
            <CustomLoader />
          </div>
        ) : (
          <div className="notifications">
            {notificationList?.map((notification) => (
              <div key={notification.id} className="notificationWrapper">
                <div className="notificationLeft">
                  <div className="notificationBrandInfo">
                    <Image
                      src={notification.brandId?.photo || noImage}
                      alt="brand photo"
                      className="notificationBrandPhoto"
                    />
                    <p className="notificationBrandName">
                      {notification.brandId?.name}
                    </p>
                  </div>
                  <p
                    className="notificationMessage"
                    dangerouslySetInnerHTML={{ __html: notification.message }}
                  />
                </div>
                <Button
                  className="viewDetailsButton"
                  onClick={() => handleNotification(notification)}
                  disabled={
                    !notificationMethods.methods.hasOwnProperty(
                      notification.type
                    )
                  }
                >
                  View Details
                </Button>
              </div>
            ))}
            {notificationList?.length === 0 ?
              <div className="emptyMessage">Notifications Inbox is empty.</div>
            :
            <>
            {!noResults && (
              <Paginator
                first={first}
                rows={limit}
                totalRecords={totalCount}
                onPageChange={onPageChange}
                style={{ background: '#130A23' }}
              />
            )}
            </>
            }
            
          </div>
        )}
      </div>
    </div>
  );
}

export default NewAdminNotification;
