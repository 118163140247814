import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d36b5bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "campaigns-list p-mt-6 p-mb-4" }
const _hoisted_2 = { class: "p-text-center" }
const _hoisted_3 = { class: "p-d-flex p-justify-center" }
const _hoisted_4 = { class: "p-d-flex p-jc-between p-mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog, {
      header: "Campaign Invitation",
      visible: _ctx.showModal,
      class: "login-card p-col-12 p-md-6 p-xl-6 gi-dialog",
      dismissableMask: !_ctx.inviting,
      modal: true,
      closable: false
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            label: "Cancel",
            class: "p-button-link",
            onClick: _ctx.closeModal
          }, null, 8, ["onClick"]),
          _createVNode(_component_Button, {
            label: "Send Invitation",
            class: "gi-button",
            loading: _ctx.inviting,
            icon: "pi pi-check",
            disabled: !_ctx.selectedCampaignID || _ctx.inviting,
            onClick: _ctx.sendInvite
          }, null, 8, ["loading", "disabled", "onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, [
            _createTextVNode("Select the campaign you'd like to invite "),
            _createElementVNode("strong", null, _toDisplayString(_ctx.creator?.name), 1),
            _createTextVNode(" to:")
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.selectedCampaignID,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCampaignID) = $event)),
              options: _ctx.campaignInviteDropdownOptions,
              filter: true,
              showClear: true,
              optionLabel: "name",
              optionValue: "id",
              optionDisabled: "disabled",
              placeholder: "Select campaign",
              emptyFilterMessage: "No campaign found",
              class: "w-50"
            }, null, 8, ["modelValue", "options"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "dismissableMask"])
  ]))
}