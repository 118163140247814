import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewCreatorProfile = _resolveComponent("NewCreatorProfile")!
  const _component_OldCreator = _resolveComponent("OldCreator")!

  return (_ctx.isInfluencerProfileEnabled)
    ? (_openBlock(), _createBlock(_component_NewCreatorProfile, {
        key: 0,
        creator: _ctx.creator,
        addToFavorites: _ctx.addToFavorites,
        favorites: _ctx.favorites,
        channelsYT: _ctx.channels?.channel?.items,
        handleContentRouting: _ctx.handleContentRouting,
        campaignOptions: _ctx.campaignOptions,
        isCreator: _ctx.isCreator,
        content: _ctx.latestContent
      }, null, 8, ["creator", "addToFavorites", "favorites", "channelsYT", "handleContentRouting", "campaignOptions", "isCreator", "content"]))
    : (_openBlock(), _createBlock(_component_OldCreator, {
        key: 1,
        creatorID: _ctx.creatorID
      }, null, 8, ["creatorID"]))
}