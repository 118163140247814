import React, { useState } from "react";
import "./NewPendingCampaignApplications.css";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { formatDate } from "@/utils/globals/date-utils";
import { Button } from "primereact/button";
import { ContentStatus, UserType } from "@/data/types";
import { Dialog } from "primereact/dialog";
import { CloseIcon } from "@/react_app/img/Icons";
import { InputNumber } from "primereact/inputnumber";

const statusTabs = [
  { value: 'Pending', type: ContentStatus.Pending },
  { value: 'Accepted', type: ContentStatus.Accepted },
  { value: 'Rejected', type: ContentStatus.Rejected },
];

const dateBodyTemplate = (data) => {
  return (
    <div className={'defaultTextTemplate'}>
      {formatDate(data.createdAt)}
    </div>
  );
};

const applicantNameBodyTemplate = (data) => {
  return (
    <div className={'defaultTextTemplate'}>
      {data.creator?.name}
    </div>
  );
};

const applicantEmailBodyTemplate = (data) => {
  return (
    <div className={'defaultTextTemplate'}>
      {data.creator?.email}
    </div>
  );
};


const applicantTypeBodyTemplate = (data) => {
  return (
    <div className={'defaultTextTemplate'}>
      {data.creator?.type === UserType.Creator && "Creator"}
      {data.creator?.type === UserType.Agency && "Agency"}
    </div>
  );
};

const statusBodyTemplate = (data) => {
  return (
    <div>
      {data.status === ContentStatus.Pending && <div className={'statusTemplate statusPending'}>Pending</div>}
      {data.status === ContentStatus.Accepted && <div className={'statusTemplate statusApproved'}>Accepted</div>}
      {data.status === ContentStatus.Rejected && <div className={'statusTemplate statusRejected'}>Rejected</div>}
    </div>
  );
};

export default function (props) {
  const { displayCapProposalDialog, manageCapProposalDialog,loadingState } = props;
  const [activeTab, setActiveTab] = useState(ContentStatus.Pending);
  const [selectedEntity, setSelectedEntity] = useState('');
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [capProposal, setCapProposal] = useState(0);

  const handleTabChange = (item) => {
    setActiveTab(item.type);
    props.changeStatusQuery(item.type);
  }

  const handleRejectApplication = () => {
    props.resolveApplication(selectedEntity.campaignId, selectedEntity.applicationId, false, 'reject', selectedEntity.creator?.id, selectedEntity.channel).then((res) => {
      setOpenRejectDialog(false);
    });
  }

  const handleApproveApplication = () => {
    props.resolveApplication(selectedEntity.campaignId, selectedEntity.applicationId, false, 'approve', selectedEntity.creator?.id, selectedEntity.channel).then((result) => {
      setOpenApproveDialog(false);
    });
  }

  const handleProposeCap = () => {
    const currentProposalInfo = { campaignId: selectedEntity.campaignId, applicationId: selectedEntity.applicationId, channelId: selectedEntity.channel, payoutCap: capProposal };
    props.createIndividualCapProposal(currentProposalInfo)
  }

  const agencyChannelBodyTemplate = (data) => {
    return (
      <div className={'defaultTextTemplate'}>
        {props.displayChannelTitle(data?.creator, data?.channel)}
      </div>
    );
  };

  const actionsBodyTemplate = (rowData) => {
    return (
      <div className={'actionsTemplate'}>
        <Button className={'approveButton'} onClick={() => { setOpenApproveDialog(true); setSelectedEntity(rowData) }}>Approve</Button>
        <Button className={'rejectButton'} onClick={() => { setOpenRejectDialog(true); setSelectedEntity(rowData) }}>Reject</Button>
      </div>
    );
  };

  const campaignBodyTemplate = (data) => {
    return (
      <div className={'underlinedTextTemplate'} onClick={() => props.goToCampaign(data.campaignId)}>
        {data.campaignName}
      </div>
    );
  };

  return (
    <div className={'pending-applications'}>
      <p className={'title'}>Pending CPM Campaign Requests</p>
      <div className={'tabsWrapper'}>
        <div className={'tabs'}>
          {statusTabs.map((item, index) =>
            <div key={index} className={item.type === activeTab ? 'tabSelected' : 'tabNormal'} onClick={() => handleTabChange(item)}>
              {item.value}
            </div>)}
        </div>
      </div>
      {activeTab === ContentStatus.Pending &&
        <DataTable value={props.applications} paginator rows={12} removableSort emptyMessage={"No videos found."}>
          <Column field={"createdAt"} header={"Date"} body={dateBodyTemplate} sortable style={{ width: '10%' }}></Column>
          <Column field={"creator.name"} header={"Applicant Name"} body={applicantNameBodyTemplate} sortable style={{ width: '15%' }}></Column>
          <Column field={"creator.email"} header={"Applicant email"} body={applicantEmailBodyTemplate} sortable style={{ width: '15%' }}></Column>
          <Column field={"channel"} header={"Agency Channel"} body={agencyChannelBodyTemplate} sortable style={{ width: '15%' }}></Column>
          <Column field={"status"} header={"Status"} body={statusBodyTemplate} style={{ width: '10%' }}></Column>
          <Column field={"creator.type"} header={"Applicant Type"} body={applicantTypeBodyTemplate} style={{ width: '15%' }}></Column>
          <Column field={"campaignName"} header={"Campaign"} body={campaignBodyTemplate} sortable style={{ width: '25%' }}></Column>
          <Column field={""} header={""} body={actionsBodyTemplate} style={{ width: '10%' }}></Column>
        </DataTable>
      }
      {activeTab !== ContentStatus.Pending &&
        <DataTable value={props.applications} paginator rows={12} removableSort emptyMessage={"No videos found."}>
          <Column field={"createdAt"} header={"Date"} body={dateBodyTemplate} sortable style={{ width: '10%' }}></Column>
          <Column field={"creator.name"} header={"Applicant Name"} body={applicantNameBodyTemplate} sortable style={{ width: '17%' }}></Column>
          <Column field={"creator.email"} header={"Applicant email"} body={applicantEmailBodyTemplate} sortable style={{ width: '15%' }}></Column>
          <Column field={"channel"} header={"Agency Channel"} body={agencyChannelBodyTemplate} sortable style={{ width: '17%' }}></Column>
          <Column field={"status"} header={"Status"} body={statusBodyTemplate} style={{ width: '12%' }}></Column>
          <Column field={"creator.type"} header={"Applicant Type"} body={applicantTypeBodyTemplate} style={{ width: '17%' }}></Column>
          <Column field={"campaignName"} header={"Campaign"} body={campaignBodyTemplate} sortable style={{ width: '25%' }}></Column>
        </DataTable>
      }
      <Dialog visible={openApproveDialog} onHide={() => setOpenApproveDialog(false)} className={'reasonDialog'}>
        <div className={'closeRow'}>
          <div className={'closeButton'} onClick={() => setOpenApproveDialog(false)}><CloseIcon /></div>
        </div>
        <div className={'reasonContent'}>
          <p className={'reasonTitle'}>APPROVE APPLICATION</p>
          <p className={'reasonText'}>Are you sure you want to accept <strong>{selectedEntity.creator?.name}'s</strong> application to <strong>{selectedEntity.campaignName}</strong>?</p>
          <div className={'reasonButtonGroup'}>
            <Button className={'reasonButtonCancel'} disabled={loadingState} onClick={() => setOpenApproveDialog(false)}>Cancel</Button>
            <Button className={'reasonButtonSave'} disabled={loadingState} onClick={() => handleApproveApplication()}>Approve</Button>
          </div>
        </div>
      </Dialog>
      <Dialog visible={openRejectDialog} onHide={() => setOpenRejectDialog(false)} className={'reasonDialog'}>
        <div className={'closeRow'}>
          <div className={'closeButton'} onClick={() => setOpenRejectDialog(false)}><CloseIcon /></div>
        </div>
        <div className={'reasonContent'}>
          <p className={'reasonTitle'}>REJECT APPLICATION</p>
          <p className={'reasonText'}>Are you sure you want to reject <strong>{selectedEntity.creator?.name}'s</strong> application to <strong>{selectedEntity.campaignName}</strong>?</p>
          <div className={'reasonButtonGroup'}>
            <Button className={'reasonButtonCancel'} disabled={loadingState} onClick={() => setOpenRejectDialog(false)}>Cancel</Button>
            <Button className={'reasonButtonSave'} disabled={loadingState} onClick={() => handleRejectApplication()}>Reject</Button>
          </div>
        </div>
      </Dialog>
      <Dialog visible={displayCapProposalDialog} onHide={() => manageCapProposalDialog(false)} className={'reasonDialog'}>
        <div className={'closeRow'}>
          <div className={'closeButton'} onClick={() => manageCapProposalDialog(false)}><CloseIcon /></div>
        </div>
        <div className={'reasonContent'}>
          <p className={'reasonTitle'}>PROPOSE INDIVIDUAL PAYOUT CAP</p>
          <h6>
            Budget left for the campaign: {selectedEntity?.campaignBudget?.amount - selectedEntity?.campaignBudget?.amountLeft}
            {selectedEntity?.campaignBudget?.currency}.
            Total budget: {selectedEntity?.campaignBudget?.amount} {selectedEntity?.campaignBudget?.currency}.
          </h6>
          <InputNumber className={'inputField'}  value={capProposal} onValueChange={(e) => setCapProposal(e.value)} />
          <div className={'reasonButtonGroup'}>
            <Button className={'reasonButtonCancel'} disabled={loadingState} onClick={() => manageCapProposalDialog(false)}>Cancel</Button>
            <Button className={'reasonButtonSave'} disabled={loadingState || !capProposal} onClick={() => handleProposeCap()}>Confirm</Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
