
import { ref } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Avatar from 'primevue/avatar';
import Paginator from 'primevue/paginator';
import { CampaignPaymentMethod } from '@/data/types';
import { formatDate } from '../../utils/globals/date-utils';
import { useAdmins } from "@/modules";

export default {
  components: {
    Paginator,
    DataTable,
    Column,
    Avatar,
  },
  setup() {
    const skip = ref(0);
    const limit = ref(10);
    const loadingUpload = ref('');
    const totalCount = ref(0);

    const adminModule = useAdmins();
    adminModule.manager.getEarningsForAdmin(limit.value, skip.value, true);

    const pageChange = (data: { page: number }) => {
      skip.value = data?.page * limit.value;
      // transfersModule.list.getAdminTransfers(skip.value, limit.value, 'active').then((data) => {
      //   transfers.value = data?.transfers;
      //   totalCount.value = (data?.counts || 0 + 1);
      // });
    };

    const changeStatusQuery = async () => {
      // await transfersModule.list.getAdminTransfers(skip.value, limit.value, 'active').then((data) => {
      //   transfers.value = data?.transfers;
      //   totalCount.value = (data?.counts || 0 + 1);
      // });
    };

    return {
      changeStatusQuery,
      pageChange,
      formatDate,
      data: adminModule.manager.getEarningsForAdminResult,
      loadingUpload,
      CampaignPaymentMethod,
      totalCount,
    }
  }
}
