
import { defineComponent, ref } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';

export default defineComponent({
  components: {
    Button,
    Dialog,
    Textarea,
  },

  props: {
    show: Boolean,
  },

  emits: ['update:show', 'setDone'],

  setup(props, { emit }) {
    const feedback = ref<string>('');
    const open = () => {
      emit('update:show', true);
    };
    return {
      open,
      feedback,
    };
  },
});
