
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import ContentRequestModal from '../../campaigns/ContentRequestModal.vue';

export default {
  name: 'ActionsForRequestLicense',
  components: {
    Dialog,
    Button,
    ContentRequestModal,
  },
  props: {
    action: {
      type: String,
      enum: ['reject', 'accept', 'counter'],
    },
    item: Object,
  },
  setup(props: any, { emit }: any) {
    const counterOffer = (data: any) => {
      emit('counterOffer', data);
    };
    return {
      counterOffer,
    };
  },
};
