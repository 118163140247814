<template>
  <div>
    <Dialog
        :visible="show"
        class="p-col-12 p-md-6 p-xl-4 gi-dialog"
        :modal="true"
        :style="{ width: '620px' , height : '842px'} "
        @update:visible="$emit('update:show', false)"
    >
      <template #header>
        <h1 style="margin-left:30%">EDIT Budget</h1>
      </template>
      <section class="p-d-flex p-flex-column p-p-4">
        <div v-if="campaign?.paymentMethod === CampaignPaymentMethod.CPC">
          <h4>Campaign CPC</h4>
          <h5 style="opacity: 0.7">This is your CPC for this campaign</h5>
          <div class="p-d-flex">
            <InputNumber
                input-style="background: #FFFFFF;bcountingProgressox-shadow:
              3px 4px 15px rgba(0, 0, 0, 0.1);font-weight: bold;font-size: 24px;
                        border-bottom-left-radius: 10px;border-top-left-radius: 10px;padding-left:25px;
                        line-height: 29px;color: #162020;" v-model="cpcValue"
                mode="currency" :min="0.1" :currency="selectedCurrency"
                :class="[ cpcValue < campaign?.cpc?.amount ? 'p-invalid' : '' ]"/>
            <select class="multi-select" v-model="selectedCurrency"
                    :disabled="campaign?.budget.currency">
              <option v-for="(cur, index) of currency" :key="index">{{ cur }}</option>
            </select>
          </div>
        </div>

        <div v-if="campaign?.paymentMethod === CampaignPaymentMethod.CPM">
          <h4>Campaign CPM</h4>
          <h5 style="opacity: 0.7">This is your CPM for this campaign</h5>
          <div class="p-d-flex">
            <InputNumber
                input-style="background: #FFFFFF;bcountingProgressox-shadow:
              3px 4px 15px rgba(0, 0, 0, 0.1);font-weight: bold;font-size: 24px;
                        border-bottom-left-radius: 10px;border-top-left-radius: 10px;padding-left:25px;
                        line-height: 29px;color: #162020;" v-model="cpmValue"
                mode="currency" :min="0.1" :currency="selectedCurrency"
                :class="[ cpmValue < campaign?.cpm?.amount ? 'p-invalid' : '' ]"/>
            <select class="multi-select" v-model="selectedCurrency"
                    :disabled="campaign?.budget.currency">
              <option v-for="(cur, index) of currency" :key="index">{{ cur }}</option>
            </select>
            </div>

          <h4>Payout Cap</h4>
          <h5 style="opacity: 0.7">
            This is your max payout cap for each creator on this campaign
            <AskHint class="p-ml-1" v-tooltip.right="'This is your max payout cap for each creator on this campaign’.' +
         ' And a hint saying: ‘This is the max payout that a single creator can gain from this campaign. E.g.' +
          ' if you chose a cap at $3000 and a CPM rate of $20 then after the creator has gained 150,000 views they' +
           ' would no longer earn from their views generated within the 30 days as they have hit the $3000 cap.' +
            ' (Keep in mind that lower caps may discourage larger creators who are more likely to gain more views' +
             ' within the 30 days).’'"/>
          </h5>
          <div class="p-d-flex">
            <InputNumber
                input-style="background: #FFFFFF;bcountingProgressox-shadow:
              3px 4px 15px rgba(0, 0, 0, 0.1);font-weight: bold;font-size: 24px;
                        border-bottom-left-radius: 10px;border-top-left-radius: 10px;padding-left:25px;
                        line-height: 29px;color: #162020;" v-model="cpmPayoutCap"
                mode="currency" :min="0.1" :currency="selectedCurrency"
                :class="[ cpmPayoutCap < campaign?.cpm?.payoutCap ? 'p-invalid' : '' ]"/>
            <select class="multi-select" v-model="selectedCurrency"
                    :disabled="campaign?.budget.currency">
              <option v-for="(cur, index) of currency" :key="index">{{ cur }}</option>
            </select>
          </div>
        </div>

        <div v-if="campaign?.paymentMethod === CampaignPaymentMethod.CPI">
          <p class="titles">Campaign CPI</p>
          <h6 style="margin-bottom: 5px">Android CPI RATE</h6>
          <div class="p-inputgroup p-mt-3">
            <InputNumber :minFractionDigits="2" :maxFractionDigits="2"
                         input-style="background: #FFFFFF;font-weight:bold;font-size: 24px;width:350px;
                        border-bottom-left-radius: 10px;border-top-left-radius: 10px;padding-left:25px;
                        line-height: 29px;color: #162020;" v-model="campaignCpiAndroid"
                         :currency="selectedCurrency"
                         :class="[ campaignCpiAndroid < 1 ? 'p-invalid' : '' ]"/>
            <select class="multi-select" v-model="selectedCurrency" :disabled="campaign?.budget?.currency">
              <option v-for="(cur, index) of currency" :key="index">{{ cur }}</option>
            </select>
          </div>

          <h6 style="margin-bottom: 5px">iOS CPI RATE</h6>
          <div class="p-inputgroup p-mt-3">
            <InputNumber :minFractionDigits="2" :maxFractionDigits="2"
                         input-style="background: #FFFFFF;font-weight:bold;font-size: 24px;width:350px;
                        border-bottom-left-radius: 10px;border-top-left-radius: 10px;padding-left:25px;
                        line-height: 29px;color: #162020;" v-model="campaignCpiIos"
                         :currency="selectedCurrency"
                         :class="[ campaignCpiIos < 1 ? 'p-invalid' : '' ]"/>
            <select class="multi-select" v-model="selectedCurrency" :disabled="campaign?.budget?.currency">
              <option v-for="(cur, index) of currency" :key="index">{{ cur }}</option>
            </select>
          </div>
        </div>

        <div class="p-d-flex" style="margin-top:10px; margin-left:5px">
          <img style="height: 15px;width:13px" src="@/assets/images/warning.svg">
          <p class="warning-text">Your rate cannot be lower than the minimum for this type of campaign</p>
        </div>
        <p class="titles" style="margin-top:30px">Campaign Budget</p>
        <div>
          <h6 style="margin-bottom: 10px">Your budget</h6>
          <div class="p-inputgroup p-mt-3">
            <InputNumber
                input-style="background: #FFFFFF;font-weight:bold;font-size: 24px;width:350px;
                        border-bottom-left-radius: 10px;border-top-left-radius: 10px;padding-left:25px;
                        line-height: 29px;color: #162020;" id="currency-usa" v-model="totalCampaignBudget"
                :currency="selectedCurrency"
                :class="[ totalCampaignBudget < 1 ? 'p-invalid' : '' ]"/>
            <select class="multi-select" v-model="selectedCurrency" :disabled="campaign?.budget?.currency">
              <option v-for="(cur, index) of currency" :key="index">{{ cur }}</option>
            </select>
          </div>
          <div v-if="totalCampaignBudget < campaign?.budget?.amount" class="p-d-flex"
               style="margin-top:10px; margin-left:5px">
            <img style="height: 15px;width:13px" src="@/assets/images/warning.svg">
            <p class="warning-text">The new budget cannot be less than the current one</p>
          </div>
        </div>
        <SecretTip class="p-mt-6" :text="'Try and share as much info about your product as possible.' +
         ' This will help people understand the' + ' concept behind it and create the best content for your campaign.'"/>
      </section>
      <template #footer>
        <div class="p-d-flex p-justify-between">
          <div class="change-info p-justify-start" @click="$emit('update:show', false)">CANCEL</div>
          <Button class="continue-button" :disabled="totalCampaignBudget < campaign?.budget?.amount || smallRatesCampaign(campaign)"
                  @click="emitChangeBudget">
            SUBMIT
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import { watch, ref } from 'vue';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import { currency } from '@/data/static/currency';
import SecretTip from './create/SecretTip.vue';
import { CampaignPaymentMethod } from '../../data/types/campaign-types';
import AskHint from '../creators/utils/AskHint.vue';

export default {
  components: {
    Dialog,
    SecretTip,
    AskHint,
    InputNumber,
    Button,
  },
  props: {
    show: Boolean,
    campaign: Object,
  },
  setup(props, { emit }) {
    const campaignCpiAndroid = ref(props.campaign?.cpi?.android?.amount || 3.5);
    const campaignCpiIos = ref(props.campaign?.cpi?.ios?.amount);
    const totalCampaignBudget = ref(props.campaign?.budget?.amount);
    const selectedCurrency = ref(props.campaign?.budget?.currency || 'USD');
    const cpmValue = ref(props.campaign?.cpm?.amount || 0.1);

    const cpcValue = ref(props.campaign?.cpc?.amount || 0.1);
    const cpmPayoutCap = ref(props.campaign?.cpm?.payoutCap || 1);

    watch(() => props.campaign?.cpi, () => {
      campaignCpiAndroid.value = props.campaign?.cpi?.android?.amount;
      campaignCpiIos.value = props.campaign?.cpi?.ios?.amount;
      totalCampaignBudget.value = props.campaign?.budget?.amount;
      selectedCurrency.value = props.campaign?.budget?.currency;
    });

    const emitChangeBudget = () => {
      let selectedPaymentMethod = {};
      if (props.campaign?.paymentMethod === CampaignPaymentMethod.CPI) {
        selectedPaymentMethod = {
          cpi: {
            android: {
              amount: campaignCpiAndroid.value,
              currency: selectedCurrency.value,
            },
            ios: {
              amount: campaignCpiIos.value,
              currency: selectedCurrency.value,
            },
          },
        };
      }
      if (props.campaign?.paymentMethod === CampaignPaymentMethod.CPC) {
        selectedPaymentMethod = {
          cpc: {
            amount: cpcValue.value,
            currency: selectedCurrency.value,
          },
        };
      }

      if (props.campaign?.paymentMethod === CampaignPaymentMethod.CPM) {
        selectedPaymentMethod = {
          cpm: {
            amount: cpmValue.value,
            currency: selectedCurrency.value,
            payoutCap: cpmPayoutCap.value,
          },
        };
      }

      emit('budgetModal', {
        budget: {
          amount: totalCampaignBudget,
          currency: selectedCurrency,
        },
        ...selectedPaymentMethod,
      });
    };

    const smallRatesCampaign = (campaign) => {
      if (campaign?.paymentMethod === CampaignPaymentMethod.CPI) {
        return campaignCpiIos.value < campaign?.cpi?.ios?.amount || campaignCpiAndroid.value < campaign?.cpi?.android?.amount;
      }
      if (campaign?.paymentMethod === CampaignPaymentMethod.CPC) {
        return cpcValue.value < campaign?.cpc?.amount;
      }
      if (campaign?.paymentMethod === CampaignPaymentMethod.CPM) {
        return cpmValue.value < campaign?.cpm?.amount || cpmPayoutCap.value < campaign?.cpm?.payoutCap;
      }
      return true;
    };

    return {
      smallRatesCampaign,
      emitChangeBudget,
      currency,
      campaignCpiAndroid,
      totalCampaignBudget,
      selectedCurrency,
      campaignCpiIos,
      CampaignPaymentMethod,
      cpmValue,
      cpcValue,
      cpmPayoutCap,
    };
  },
};
</script>
<style scoped lang="scss">
.buttons {
  margin-top: 120px;
  margin-bottom: 15px;
}

.titles {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #253340;
}

.warning-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #8B8F8F;
  text-align: center;
  margin-left: 5px;
}

.continue-button {
  border: none;
  font-weight: 800;
  text-transform: uppercase;
  min-width: 198px;
  max-width: 198px;
  height: 40.12px;
  background: #E0010B;
  box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;
  padding: 8px;
}

.main-title {
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 50px;
  text-align: center;
  color: #162020;
}

.edit-button {
  margin-left: 10%;
  color: #162020;
  background-color: #FFFFFF;
  border: none;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
}

.change-info {
  font-weight: 800;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #162020;
  margin-left: 30px;
  cursor: pointer;
  padding: 8px;
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight ) {
  background-color: #E0010B;
  color: #ffff;
}

.multi-select {
  max-width: 86px;
  height: 46px;
  background: #E0010B;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: none;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

::v-deep(.p-inputgroup) {
  width: 450px;
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight), ::v-deep(.p-checkbox .p-checkbox-box.p-highlight:hover) {
  border-color: red;
  background: red;
}</style>
