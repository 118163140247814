import { reactive } from 'vue';
import { useStorage } from '@vueuse/core';

const baseState = {
  initializing: true,
  pages: {
    login: {
      isCreative: false,
    },
  },
  auth: {
    isLoggedIn: false,
    user: {
      name: '',
      email: '',
      profile: {
        photo: '',
      },
      role: '',
    },
  },
};

const baseSession = {
  auth: {
    token: null,
  },
};

const state = reactive(baseState);

export const useGlobalStorage = () => useStorage('global-storage', { ...baseSession }, localStorage);

export const useGlobalState = () => ({ state });
