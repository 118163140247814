
import { defineComponent, ref } from "vue";
import { useAdmins, useAgencys, useAuth } from "@/modules";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Avatar from "primevue/avatar";
import { MatchAffSubsFromTrackingLink } from "../../utils/globals/regex";

interface EarningsPageProps {
  isAdminData: boolean;
}

export default defineComponent({
  props: {
    isAdminData: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DataTable,
    Column,
    Avatar,
  },
  setup(props: EarningsPageProps) {
    const skip = ref(0);
    const limit = ref(50);
    const { user } = useAuth();
    const { getEarningsForAdmin } = useAdmins().manager;
    const { getEarningsForAgency } = useAgencys().manager;
    const earnings = ref();
    const expandedRows = ref([]);

    (async () => {
      let response;

      if (props?.isAdminData) {
        response = await getEarningsForAdmin(limit.value, skip.value);
      } else {
        response = await getEarningsForAgency(limit.value, skip.value, false, user!.value!.id);
      }

      /* When the earnings are for an agency it should also display the creator that earned that particular amount.
       With the current database design that is not possible directly that's why we have to iterate through
       the campaign's tracking links and extract the creator from the URL.
       In the above response the `creatorId` is the agency itself and the creator that works for the agency
      is mapped nowhere. The tracking link contains the username though. */
      for await (const entity of response?.creatorEarnings) {
        for await (const currentLink of entity?.links) {
          const match = currentLink?.link?.match(MatchAffSubsFromTrackingLink);

          if (match) {
            let affSub3Value = match[3]; // username
            affSub3Value = affSub3Value.replaceAll("%20", " ");

            currentLink.creatorName = affSub3Value;
          }
        }
      }

      earnings.value = response;
    })();

    return {
      skip,
      limit,
      data: earnings,
      expandedRows,
    };
  },
});
