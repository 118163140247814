<template>
  <div>
    <Dialog
      :visible="displayCountriesModal"
      class="gi-dialog"
      :modal="true"
      :style="{ width: '80vh', height : '80%', 'min-height': 'auto' } "
      @update:visible="$emit('update:displayCountriesModal', false)"
    >
      <template #header>
        <h1 class="p-m-auto">Market</h1>
      </template>
      <section class="p-d-flex p-flex-column p-p-6">
        <div class="p-d-flex" style="cursor: pointer" @click="$emit('update:displayCountriesModal', false)">
    <img class="svg-btn" src="./assets/images/back-button.png">
          <span class="new-camp-button">Back to tier selection</span>
        </div>
        <h4>{{ tier }}</h4>
        <span class="p-input-icon-left w-100 p-mt-4">
            <i class="pi pi-search"/>
            <InputText type="text" v-model="searchValueMarket" placeholder="Search for a country"
                       class="tittle-input w-50" @input="filterCountries" />
        </span>
        <div class="text-box">
          <p class="countries" v-for="(item,index) in itemsTiers" :key="index" @click="$emit('selectTier')">
            {{ item }}
          </p>
        </div>
      </section>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { ref } from 'vue';

export default {
  components: {
    Dialog,
    InputText,
  },
  props: {
    tier: String,
    items: Array,
    displayCountriesModal: Boolean,
  },
  setup(props) {
    const active = ref(0);
    const searchValueMarket = ref();
    const itemsTiers = ref(props.items);

    const filterCountries = () => {
      const regExp = new RegExp(searchValueMarket.value, 'gi');
      itemsTiers.value = props.items.filter((v) => regExp.test(v));
    };

    return { active, searchValueMarket, itemsTiers, filterCountries };
  },
};
</script>
<style scoped lang="scss">
.new-camp-button {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #162020;
  padding: 8px;
}

.countries {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #686E6E;
  cursor: pointer;
}
.text-box {
  max-width: 550px;
  max-height: 304px;
  overflow-y: auto;
  margin-top: 36px;
}

.svg-btn {
  height: 30px;
  width: 30px;
}

.tittle-input {
  background: #FFFFFF;
  box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  height: 34px;
  width: 60vh;
}

.tittle-input::placeholder {
  font-size: 14px;
  line-height: 17px;
  color: #8B8F8F;
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight ){
  background-color: #E0010B;
  color:#ffff;
}
div::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #FFFFFF;
}
div::-webkit-scrollbar-thumb{
  background: #686E6E;
  border-radius: 5px;
}
</style>
