import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createBlock(_component_InputText, {
    class: "search-input p-mb-4",
    type: "text",
    modelValue: $setup.searchInput.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchInput.value) = $event)),
    onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('changeValueUrl', $setup.searchInput))),
    placeholder: "Enter video URL to upload it"
  }, null, 8, ["modelValue"]))
}