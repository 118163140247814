import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70009c9b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_latest_influencers_item = _resolveComponent("latest-influencers-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", {
      class: "p-d-flex p-justify-end p-mb-6 seeMore",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'creators' })))
    }, "See more influencers >"),
    _createElementVNode("section", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (inf, index) => {
        return (_openBlock(), _createBlock(_component_latest_influencers_item, {
          onFavoriteChange: $setup.favoriteChange,
          "is-favorite": $setup.isInfluencerFavorite(inf),
          key: `${index}_inf`,
          item: inf,
          limit: 4
        }, null, 8, ["onFavoriteChange", "is-favorite", "item"]))
      }), 128))
    ])
  ]))
}