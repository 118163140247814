
import { defineComponent, ref } from "vue";
import { useAdmins } from "@/modules";
import { formatDate } from "@/utils/globals/date-utils";
import { useCampaignApplications } from "@/modules/campaigns/campaign-applications-module";
import { Perspective, User } from "@/data/types";
import { useToastModule } from "@/modules";
import { applyPureReactInVue } from 'veaury';
import { isHomepageEnabled } from '@/utils/globals/feature-flags';
import NewPendingCampaignApplications from "@/react_app/NewAdmin/NewPendingCampaignApplications/NewPendingCampaignApplications";
import OldPendingCampaignApplications from "@/pages/admin/OldPendingCampaignApplications.vue";
import router from "@/router";

interface ProposalInfo {
  campaignId: string,
  applicationId: string,
  channelId?: string,
  payoutCap: number,
}

export default defineComponent({
  components: {
    NewPendingCampaignApplications: applyPureReactInVue(NewPendingCampaignApplications),
    OldPendingCampaignApplications
  },
  setup() {
    const {
      manager: { getCpmCampaignApplications, getCpmCampaignApplicationsResult },
    } = useAdmins();
    const { rejectApplication, acceptApplication, proposeIndividualCap } = useCampaignApplications(Perspective.ADMIN);
    const toast = useToastModule();
    const displayCapProposalDialog = ref(false);
    const loadingState = ref(false);
    const currentProposalInfo = ref<ProposalInfo>({campaignId: "", applicationId: "", channelId: "", payoutCap: 0});

    const selectedStatusFilter = ref("pending");
    const statusFilterValues = [
      { name: "Pending", value: "pending" },
      { name: "Accepted", value: "accepted" },
      { name: "Rejected", value: "rejected" },
    ];

    (async () => { await getCpmCampaignApplications(50, 0, "pending");
})()

    const resolveApplication =
    async (campaignId: string, applicationId: string, contractWork: boolean, action: "approve" | "reject", creatorId?: string, channelId?: string) =>
     {
      loadingState.value = true;
      const resolveApplicationFn = action === "approve" ? acceptApplication : rejectApplication;
         resolveApplicationFn(campaignId, applicationId, contractWork, creatorId, channelId).then((res) => {
        if(res !== undefined) {
          toast.showSuccess("Participation request successfully handled.");
          getCpmCampaignApplications(50, 0, "pending"); 
          if (action === "approve") manageCapProposalDialog(true);
        }
        loadingState.value = false;
       }).catch((err: Error) => {
        loadingState.value = false;
        toast.showError("Unable to resolve participation. Reason: " + err);
       });

       currentProposalInfo.value.applicationId = applicationId;
       currentProposalInfo.value.campaignId = campaignId;
       if (channelId) {
        currentProposalInfo.value.channelId = channelId;
      } else {
        currentProposalInfo.value.channelId = '';
      }
    };

    const displayChannelTitle = (userId: Partial<User>, channelId: string) => {
      if (!userId || !channelId) {
        return "N/A";
      }

      return userId.agencyWorkers?.find((worker) => worker.channel.channelId === channelId)?.channel?.title;
    };

    const parseUserType = (userType: "creator" | "agencyOwner") => {
      return userType === "agencyOwner" ? "Agency Owner" : "Creator";
    };

    const parseDate = (date: string) => {
      return date?.split("T")[0];
    };

    const createIndividualCapProposal = (proposalInfo: ProposalInfo) => {
      const { campaignId, applicationId, payoutCap, channelId } = proposalInfo;
      loadingState.value = true;
      if (isNaN(payoutCap)) {
        toast.showError("Unable to forward a proposal. The proposed cap needs to be a valid number.");
        return;
      }

      proposeIndividualCap(campaignId, applicationId, payoutCap, channelId).then((res) => {
        if(res === undefined) toast.showError("Something went wrong.");  
        else{
          toast.showSuccess("Successfully forwaded an individual payout cap for this participation.");
          getCpmCampaignApplications(50, 0, selectedStatusFilter.value);
        }
        manageCapProposalDialog(false);
        loadingState.value = false;
      }).catch((err) => {
        loadingState.value = false;
        toast.showError("Unable to forward a proposal. Reason: " + err);
      });
    };

    const changeStatusQuery = (selectedStatus: string) => {
      getCpmCampaignApplications(50, 0, selectedStatus);
    };

    const manageCapProposalDialog = (flag:boolean) =>{
      displayCapProposalDialog.value = flag;
    }

    const goToCampaign = (itemId: any) => {
      router.push({ name: 'campaign', params: { campaignID: itemId }});
    };

    return {
      parseDate,
      resolveApplication,
      parseUserType,
      cpmCampaignApplications: getCpmCampaignApplicationsResult,
      formatDate,
      selectedStatusFilter,
      statusFilterValues,
      displayCapProposalDialog,
      displayChannelTitle,
      createIndividualCapProposal,
      currentProposalInfo,
      changeStatusQuery,
      isHomepageEnabled,
      getCpmCampaignApplications,
      manageCapProposalDialog,
      loadingState,goToCampaign
    };
  },
});
