export const isNewLoginPageEnabled = true;

export const isNewLegalInfoEnabled = true;

export const isNewBrandEnabled = localStorage.getItem('new-design-toggle') === 'activated';

export const isHomepageEnabled = localStorage.getItem('new-design-toggle') === 'activated';

export const isInfluencersPageEnabled = localStorage.getItem('new-design-toggle') === 'activated';

export const isInfluencerProfileEnabled = localStorage.getItem('new-design-toggle') === 'activated';

export const isNewCampaignsPageEnabled = localStorage.getItem('new-design-toggle') === 'activated';

export const isContentItemsPageEnabled = localStorage.getItem('new-design-toggle') === 'activated';

export const isNewCreateCampaignEnabled = localStorage.getItem('new-design-toggle') === 'activated';

export const isNewCampaignsManagementEnabled = localStorage.getItem('new-design-toggle') === 'activated';

export const isNewMyContentPageEnabled = localStorage.getItem('new-design-toggle') === 'activated';

export const isCreatorAccountEnabled =  localStorage.getItem('new-design-toggle') === 'activated';

export const isNewCampaignInvitationsEnabled = localStorage.getItem('new-design-toggle') === 'activated';

export const isNewCampaignPageEnabled = localStorage.getItem('new-design-toggle') === 'activated';

export const isNewAdminEnabled = localStorage.getItem('new-design-toggle') === 'activated';
