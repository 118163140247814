import { AppPagePerspective, Perspective } from '@/data/types/page-types';
import { useAgencyManager } from './agency-manager-module';
import { useAgencyList } from './agency-list-module';

export const useAgencys = (perspective: AppPagePerspective = Perspective.AGENCY) => {
  const manager = useAgencyManager(perspective);
  const list = useAgencyList();

  return {
    manager,
    list,
  };
};
