import React from 'react';
import './NewCampaignComplete.css'
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import congrats from "../img/congrats.gif";

export default function(props) {
  return (
    <div className={'campaign-congrats'}>
      <div className={'coverBackground'} style={{ background: `linear-gradient(rgba(19, 10, 35, 0.7), rgba(19, 10, 35, 0.7)),
        url(${props.campaign?.photo}) no-repeat center center / cover` }}>
        <Image src={congrats} alt={'congrats gif'} className={'congratsGif'}/>
        <p className={'congratsText'}>
          Congratulations! <br/><br/>
          {props.campaign?.name} Campaign has been submitted for an approval!
        </p>
        <p className={'smallText'}>
          As our platform is still in its MVP stage, all campaigns will await approval, a member of
          our team will reach out to you via the email you registered with shortly.
        </p>
        <Button className={'seeDetailsButton'} onClick={() => props.handleRouting()}>See campaign details</Button>
      </div>
    </div>
  )
}
