import { watch } from 'vue';
import { useAPI, useToastModule } from '@/modules';
import { AgencyWorker, CampaignData } from '@/data/types';

export const useCampaignManager = () => {
  const toast = useToastModule();

  // create campaign
  const {
    loading: creationInProgress,
    error: creationError,
    data: creationResult,
    execute: sendCreateRequest,
  } = useAPI<CampaignData>('', false);

  const create = (details: {
    name: string;
    startDate?: string;
    endDate?: string;
    budget: {
      amount: number;
      currency: string;
    };
    period?: any;
    budgetType?: string;
    previousPhoto?: string[];
    gamekeys: string;
    attachments?: [{
      url: string;
      name: string;
    }] | any;
    targetMarket?: object;
    desiredRegions?: any;
    productName?: string;
    productUrls?: any;
    trackingLink?: string;
    callToAction?: string;
    photo?: string;
    dates?: {
      startDate?: Date;
      endDate?: Date;
    };
    cpi?: {
      android: {
        amount: number;
      };
      ios: {
        amount: number;
      };
    };
    cpc?: {
      amount: number;
    };
    cpm?: {
      amount: number;
      payoutCap: number;
    };
    paymentMethod: 'CPC' | 'CPM' | 'CPI' | 'CPL' | 'CPA';
    paymentInformation?: {
      companyName: string;
      email: string;
      address: string;
      city: string;
    };
    talkingPoints?: Array<string> | undefined;
    appliedFee: number;
  }) => sendCreateRequest({
    url: '/campaigns/drafts',
    method: 'POST',
    data: details,
  });

  watch(creationError, toast.handleRequestError);
  // < create campaign

  // load single campaign
  const {
    loading: loadingSingle,
    error: loadingSingleError,
    data: singleCampaign,
    execute: loadSingleCampaign,
  } = useAPI<CampaignData>('', false);
  const loadSingle = (campaignID: string) => loadSingleCampaign({ url: `/campaigns/${campaignID}` });
  // < load single

  // getTotalViewsCampaign
  const {
    loading: getTotalViewsCampaignLoading,
    error: getTotalViewsCampaignError,
    data: getTotalViewsCampaignData,
    execute: getTotalViewsCampaignReq,
  } = useAPI<CampaignData>('', false);
  const getTotalViewsCampaign = (campaignID: string) => getTotalViewsCampaignReq({
    url: `/campaigns/views/${campaignID}`,
    method: 'GET',
  });
  // < getTotalViewsCampaign

  // update
  const {
    loading: updatingInProgress,
    error: updatingError,
    data: updatingResult,
    execute: sendUpdateRequest,
  } = useAPI<CampaignData>('', false);

  const update = (campaignID: string, campaignBody: Record<string, any>) => sendUpdateRequest({
    url: `/campaigns/${campaignID}`,
    method: 'PUT',
    data: { action: 'update', ...campaignBody },
  });

  watch(updatingResult, () => {
    if (updatingResult.value) {
      singleCampaign.value = updatingResult.value;
    }
  });
  // < update

  // update cpi
  const {
    loading: changeCpiProgress,
    error: changeCpiError,
    data: changeCpiResult,
    execute: changeCpiRequest,
  } = useAPI<CampaignData>('', false);
  const changeCpi = (campaignID: string, data: {
    android: {
      amount: number;
    };
    ios: {
      amount: number;
    };
  }) => changeCpiRequest({
    url: `/campaigns/cpi/${campaignID}`,
    method: 'PUT',
    data,
  });

  watch(changeCpiResult, () => {
    if (changeCpiResult.value) {
      singleCampaign.value = changeCpiResult.value;
    }
  });
  // < update cpi

  // update cpc
  const {
    loading: changeCpcProgress,
    error: changeCpcError,
    data: changeCpcResult,
    execute: changeCpcRequest,
  } = useAPI<CampaignData>('', false);
  const changeCpc = (campaignID: string, data: {
    amount: number;
  }) => changeCpcRequest({
    url: `/campaigns/cpc/${campaignID}`,
    method: 'PUT',
    data,
  });
  watch(changeCpcResult, () => {
    if (changeCpcResult.value) {
      singleCampaign.value = changeCpcResult.value;
    }
  });
  // < update cpc

  // update cpm
  const {
    loading: changeCpmProgress,
    error: changeCpmError,
    data: changeCpmResult,
    execute: changeCpmRequest,
  } = useAPI<CampaignData>('', false);
  const changeCpm = (campaignID: string, data: {
    amount: number;
    payoutCap: number;
  }) => changeCpmRequest({
    url: `/campaigns/cpm/${campaignID}`,
    method: 'PUT',
    data,
  });
  watch(changeCpmResult, () => {
    if (changeCpmResult.value) {
      singleCampaign.value = changeCpmResult.value;
    }
  });
  // < update cpc

  // publish campaign
  const {
    loading: publishing,
    error: publishingError,
    data: publishingResult,
    execute: sendPublishRequest,
  } = useAPI<CampaignData>('', false);

  const publish = (campaignID: string) => sendPublishRequest({
    url: `/campaigns/${campaignID}`,
    method: 'PUT',
    data: { action: 'publish' },
  });
  watch(publishingResult, toast.handleRequestSuccess('Campaign published successfully!'));
  watch(publishingError, toast.handleRequestError);
  // < publish campaign

  // pending campaign
  const {
    loading: pendingProgress,
    error: pendingError,
    data: pendingResult,
    execute: sendPendingRequest,
  } = useAPI<CampaignData>('', false);

  const pending = (campaignID: string) => sendPendingRequest({
    url: `/campaigns/${campaignID}`,
    method: 'PUT',
    data: { action: 'pending' },
  });
  watch(pendingResult, toast.handleRequestSuccess('Campaign would be published after admin approval'));
  watch(pendingError, toast.handleRequestError);
  // < pending campaign

  // discard campaign
  const {
    loading: discarding,
    error: discardingError,
    data: discardingResult,
    execute: sendDiscardRequest,
  } = useAPI<boolean>('', false);

  const discard = (campaignID: string) => sendDiscardRequest({
    url: `/campaigns/${campaignID}`,
    method: 'PUT',
    data: { action: 'discard' },
  });
  watch(discardingResult, toast.handleRequestSuccess('Campaign discarded successfully!'));
  watch(discardingError, toast.handleRequestError);
  // < discard campaign

  // complete campaign
  const {
    loading: completing,
    error: completingError,
    data: completingResult,
    execute: sendCompleteRequest,
  } = useAPI<CampaignData>('', false);

  const complete = (campaignID: string) => sendCompleteRequest({
    url: `/campaigns/${campaignID}`,
    method: 'PUT',
    data: { action: 'complete' },
  });
  watch(completingResult, toast.handleRequestSuccess('Campaign completed successfully!'));
  watch(completingError, toast.handleRequestError);
  // < complete campaign

  // attachments
  const {
    loading: completingAttachments,
    error: AttachmentsError,
    data: AttachmentsResult,
    execute: sendRemoveAttachmentsRequest,
  } = useAPI<CampaignData>('', false);
  const attachmentRemove = (campaignID: string, attachmentID: string) => sendRemoveAttachmentsRequest({
    url: `/campaigns/attachments/${campaignID}/${attachmentID}`,
    method: 'DELETE',
  });

  // post attachments
  const {
    execute: sendAttachmentsRequest,
  } = useAPI<CampaignData>('', false);
  const attachments = (campaignID: string, attachmentsBody: any) => sendAttachmentsRequest({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: `/campaigns/attachments/${campaignID}`,
    method: 'POST',
    data: attachmentsBody,
  });

  const {
    loading: completingurlAttachments,
    error: AttachmentsurlError,
    data: AttachmentsurlResult,
    execute: sendRemoveAttachmentsurlRequest,
  } = useAPI<CampaignData>('', false);
  const attachmentRemoveUrl = (url: string) => sendRemoveAttachmentsurlRequest({
    url: `/campaigns/attachments/${url}`,
    method: 'DELETE',
  });
  // delete preview logo campaign
  const {
    loading: completingRemovePreviewLogo,
    error: removePreviewLogoError,
    data: removePreviewLogoResult,
    execute: sendRemovePreviewLogoRequest,
  } = useAPI<CampaignData>('', false);
  const removePreviewLogo = (campaignId: string, prevLogoId: number) => sendRemovePreviewLogoRequest({
    url: `/campaigns/logo/${campaignId}/${prevLogoId}`,
    method: 'DELETE',
  });

  // agree terms campaign
  const {
    loading: agreeTermsLoading,
    error: agreeTermsError,
    data: agreeTermsData,
    execute: agreeTermsReq,
  } = useAPI('', false);
  const agreeTerms = (id: string, channelIds?: AgencyWorker[]) => agreeTermsReq({
    url: `/campaigns/agree/${id}`,
    method: 'POST',
    params: { channelId: channelIds?.map((worker: AgencyWorker) => worker.channel?.channelId) },
  });
  // < agree terms campaign

  // changeBudgetWithCsv
  const {
    loading: changeBudgetWithCsvLoading,
    error: changeBudgetWithCsvError,
    data: changeBudgetWithCsvData,
    execute: changeBudgetWithCsvReq,
  } = useAPI('', false);
  const changeBudgetWithCsv = (id: string, data: any) => changeBudgetWithCsvReq({
    url: `/campaigns/change-budget/${id}`,
    method: 'POST',
    data,
  });
  // < changeBudgetWithCsv

  // generatePostBackURl
  const {
    loading: isPostBackURLGenerating,
    error: postBackURLGeneratingError,
    data: postBackURLData,
    execute: postBackURLReq,
  } = useAPI('', false);

  const generatePostBackURL = (name: string, expiration_date: string, preview_url: string, advertiser_id: number) => postBackURLReq({
    url: `/campaigns/generate-postback-url`,
    method: 'POST',
    data: {
      name,
      expiration_date,
      preview_url,
      advertiser_id
    }
  });
  watch(postBackURLGeneratingError, toast.handleRequestError);

  // < generatePostBackURl

  // activateCampaignWithCsv
  const {
    loading: activateCampaignWithCsvLoading,
    error: activateCampaignWithCsvError,
    data: activateCampaignWithCsvData,
    execute: activateCampaignWithCsvReq,
  } = useAPI('', false);
  const activateCampaignWithCsv = (id: string) => activateCampaignWithCsvReq({
    url: `/campaigns/activate/${id}`,
    method: 'POST',
  });
  watch(activateCampaignWithCsvError, toast.handleRequestError);
  // < activateCampaignWithCsv

  // editTrackingLink
  const {
    loading: editTrackingLinkLoading,
    error: editTrackingLinkError,
    data: editTrackingLinkData,
    execute: editTrackingLinkReq,
  } = useAPI('', false);
  const editTrackingLink = (campaignId: string, newLink: string, tuneManipulation: boolean) => editTrackingLinkReq({
    url: tuneManipulation ? '/campaigns/tracking-link/' : '/campaigns/update/tracking-link',
    method: tuneManipulation ? 'PATCH' : 'PUT',
    data: { campaignId, newLink },
  });
  watch(editTrackingLinkError, toast.handleRequestError);
  // < editTrackingLink

  return {
    editTrackingLink,
    editTrackingLinkData,
    editTrackingLinkError,
    editTrackingLinkLoading,
    // < editTrackingLink
    activateCampaignWithCsv,
    activateCampaignWithCsvData,
    activateCampaignWithCsvError,
    activateCampaignWithCsvLoading,
    // activateCampaignWithCsv
    changeBudgetWithCsv,
    changeBudgetWithCsvData,
    changeBudgetWithCsvError,
    changeBudgetWithCsvLoading,

    // generatePostBackURl
    generatePostBackURL,
    postBackURLData,
    postBackURLGeneratingError,
    isPostBackURLGenerating,
    // change budget with create csv
    getTotalViewsCampaign,
    getTotalViewsCampaignData,
    getTotalViewsCampaignError,
    getTotalViewsCampaignLoading,
    // < getTotalViewsCampaign
    agreeTerms,
    agreeTermsData,
    agreeTermsError,
    agreeTermsLoading,
    // preview logo delete
    completingRemovePreviewLogo,
    removePreviewLogoError,
    removePreviewLogoResult,
    removePreviewLogo,
    // post attachments
    attachments,
    // attachment delete
    attachmentRemove,
    AttachmentsResult,
    AttachmentsError,
    completingAttachments,
    // if campaign dont saved draft
    attachmentRemoveUrl,
    AttachmentsurlResult,
    AttachmentsurlError,
    completingurlAttachments,
    // creating
    create,
    creationInProgress,
    creationError,
    creationResult,

    // loading single
    loadSingle,
    loadingSingle,
    loadingSingleError,
    singleCampaign,

    // update campaign
    update,
    updatingInProgress,
    updatingError,
    updatingResult,

    // update cpi
    changeCpi,
    changeCpiResult,
    changeCpiError,
    changeCpiProgress,
    // update cpc
    changeCpc,
    changeCpcResult,
    changeCpcError,
    changeCpcProgress,
    // update cpm
    changeCpm,
    changeCpmResult,
    changeCpmError,
    changeCpmProgress,

    // pending
    pending,
    pendingResult,
    pendingError,
    pendingProgress,

    // publish
    publish,
    publishing,
    publishingError,
    publishingResult,

    // discard
    discard,
    discarding,
    discardingError,
    discardingResult,

    // complete
    complete,
    completing,
    completingError,
    completingResult,
  };
};
