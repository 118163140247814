import React from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { CloseIcon } from '@/react_app/img/Icons';

const DeleteApplication = (props) => {
    const { open, close, allRequest, deleteRequests } = props;
    return (
        <Dialog visible={open} onHide={() => close()} className='reasonDialog'>
            <div className='closeRow'>
                <div className='closeButton' onClick={() => close()}><CloseIcon /></div>
            </div>
            <div className='reasonContent'>
                <p className='reasonTitle'>DELETE CAMPAIGN REQUEST</p>
                <p className='reasonText'>You are about to delete the following  requests:</p>
                {allRequest && allRequest?.map((request) => (
                    <p key={request?.applicationId}>
                        <strong>{request?.creator?.name}'s</strong> application to <strong>{request?.campaignName}</strong>
                    </p>
                ))}
                <div className='reasonButtonGroup'>
                    <Button className='reasonButtonCancel' onClick={() => close()}>Cancel</Button>
                    <Button className='reasonButtonSave' onClick={() => deleteRequests()}>Delete</Button>
                </div>
            </div>
        </Dialog>
    )
}

export default DeleteApplication
