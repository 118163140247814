
import FaqUpdated from './home/faqUpdated.vue';
import LandingHeader from './home/LandingHeader.vue';
import LandingFooter from './home/LandingFooter.vue';
import SidebarView from './home/SidebarView.vue';
// import PlatformServices from './home/PlatformServices.vue';
import OurPlatform from './home/OurPlatform.vue';
import WhatIsGio from './home/WhatIsGio.vue';
import Testimonials from './home/Testimonials.vue';
import OurCaseStudies from './home/OurCaseStudies.vue';
import SponsorsRow from './home/SponsorsRow.vue';
import { scrollToComponent, discord, scrollToTop } from './home/utils';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';

export default {
  components: {
    FaqUpdated,
    LandingHeader,
    LandingFooter,
    SidebarView,
    WhatIsGio,
    OurPlatform,
    SponsorsRow,
    Testimonials,
    OurCaseStudies
  },
  setup() {
    const op = ref(false);
    const startLink = ref();
    const isMobile = ref<null | boolean>(null);
    const currentReview = ref(0);
    const currentCampaign = {
      name: 'Steel Series',
      amount: 10,
      logoPath: 'raid-logo.png', // not in use currently
    };

    const router = useRouter();

    const handleChangeReview = (index: number) => {
      currentReview.value = index;
    };

    const closeSidebar = () => {
      op.value = !op.value;
    };

    const handleLoginClick = () => {
      localStorage.setItem('login-view-Type', 'creator');
      router.push({ name: 'login' }).catch((err) => {
        console.error('Failed to navigate:', err);
      });
    };

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    onMounted(() => {
      checkIsMobile();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', checkIsMobile);
    });

    window.addEventListener('resize', checkIsMobile);

    return {
      currentCampaign,
      op,
      startLink,
      closeSidebar,
      scrollToComponent,
      discord,
      scrollToTop,
      handleLoginClick,
      checkIsMobile,
      currentReview,
      handleChangeReview,
      isMobile,
    };
  },
  data() {
    return {
      about: null,
      platform: null,
      testimonials: null,
      faq: null,
    };
  },
};
