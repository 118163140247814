import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized } from 'vue-router';
import { useAuth } from '@/modules';
import { UserType } from '@/data/types';

import Dashboard from '../pages/Dashboard.vue';
import Empty from '../pages/Empty.vue';
import PageLayout from '../pages/layout/PageLayout.vue';
import LandingPage from '../pages/LandingView.vue';
import WrapperPage from '../pages/utility/WrapperPage.vue';
import { AppRouteProps, Perspective } from '../data/types/page-types';

const getRouteProps = (staticProps: Record<string, unknown> = {}) => (route: RouteLocationNormalized): AppRouteProps => {
  const { user } = useAuth();
  let viewPerspective;
  if (user?.value?.type === UserType.BrandOwner) {
    viewPerspective = Perspective.BRAND;
  }
  if (user?.value?.type === UserType.Creator) {
    viewPerspective = Perspective.CREATOR;
  }
  if (user?.value?.type === UserType.Agency) {
    viewPerspective = Perspective.AGENCY;
  }
  if (!viewPerspective) {
    // default perspective
    viewPerspective = Perspective.CREATOR;
  }

  return {
    ...route.params,
    ...staticProps,
    viewPerspective,
  };
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: LandingPage,
    name: 'landing',
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import(/* webpackChunkName: "poly" */ '../pages/auth/TermsOfUse.vue'),
  },
  {
    path: '/legal-notice',
    name: 'legal-notice',
    component: () => import(/* webpackChunkName: "poly" */ '../pages/auth/LegalNotice.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "poly" */ '../pages/auth/PrivacyPolicy.vue'),
  },
  {
    path: '/app',
    name: 'app',
    component: PageLayout,
    props: getRouteProps(),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        props: getRouteProps(),
      },

      /// CONTENT
      {
        path: 'my-content-items/',
        component: WrapperPage,
        children: [
          {
            path: 'my',
            name: 'my-content-items', // TODO dont used?
            component: () => import(/* webpackChunkName: "content-items" */ '../pages/content/MyContent.vue'),
            props: getRouteProps({ private: true }),
          },
          {
            path: 'video-reason/:content',
            name: 'video-reason',
            component: () => import(/* webpackChunkName: "content-items" */ '../pages/campaigns/view/VideoReason.vue'),
            props: getRouteProps({ private: true }),
          },
          {
            path: 'licensed-items',
            name: 'licensed-items',
            component: () => import(/* webpackChunkName: "content-items" */ '../pages/content/licensed-items/LicensedItems.vue'),
            props: getRouteProps({ private: true }),
          },
          {
            path: 'license-requests',
            name: 'license-requests',
            component: () => import(/* webpackChunkName: "content-items" */'../pages/content/license-requests/LicenseRequests.vue'),
            props: getRouteProps({ private: true }),
          },
          {
            path: ':itemID',
            name: 'content-item',
            props: true,
            component: () => import(/* webpackChunkName: "content-items" */ '../pages/content/item/ContentItem.vue'),
          },
        ],
      },
      /// submissions
      {
        path: 'submissions/',
        component: WrapperPage,
        children: [
          {
            path: '/:campaignID/:id',
            name: 'submission-item',
            component: () => import(/* webpackChunkName: "submissions" */ '../pages/submissions/SubmissionItem.vue'),
            props: getRouteProps(),
          },
        ],
      },
      {
        path: 'content-items/',
        component: WrapperPage,
        children: [
          {
            path: '',
            name: 'content-items',
            component: () => import(/* webpackChunkName: "content-items" */ '../pages/content/ContentItems.vue'),
            props: getRouteProps(),
          },
          {
            path: ':itemID',
            name: 'content-item',
            component: () => import(/* webpackChunkName: "content-items" */ '../pages/content/item/ContentItem.vue'),
            props: getRouteProps(),
          },
        ],
      },

      /// CREATORS
      {
        path: 'creators/',
        component: WrapperPage,
        children: [
          {
            path: 'campaign-active/:campaignID',
            name: 'campaign-active',
            component: () => import(/* webpackChunkName: "creators" */ '../pages/creators/CampaignActivePage.vue'),
            props: getRouteProps(),
          },
          {
            path: '',
            name: 'creators',
            component: () => import(/* webpackChunkName: "creators" */ '../pages/creators/Creators.vue'),
            props: getRouteProps(),
          },
          {
            path: 'invitations',
            name: 'creator-invitations',
            component: () => import(/* webpackChunkName: "creators" */ '../pages/campaigns/invitations/Invitations.vue'),
            props: getRouteProps(),
          },
          {
            path: ':creatorID/account',
            name: 'creator-account',
            component: () => import(/* webpackChunkName: "creators" */ '../pages/creators/account/CreatorAccount.vue'),
            props: getRouteProps(),
          },
          {
            path: ':creatorID/my-content',
            name: 'my-content',
            component: () => import(/* webpackChunkName: "creators" */ '../pages/creators/MyContentPage.vue'),
            props: getRouteProps(),
          },
          {
            path: ':creatorID/content-manager',
            name: 'content-manager',
            component: () => import(/* webpackChunkName: "creators" */ '../pages/creators/ContentManagerPage.vue'),
            props: getRouteProps(),
          },
          {
            path: ':creatorID',
            name: 'creator',
            component: () => import(/* webpackChunkName: "creators" */ '../pages/creators/profile/Creator.vue'),
            props: getRouteProps(),
          },
        ],
      },

      /// AGENCYS
      {
        path: 'agency/',
        component: WrapperPage,
        children: [
          {
            path: 'create-agency',
            name: 'create-agency',
            component: () => import(/* webpackChunkName: "agency" */ '../pages/agencys/CreateAgency.vue'),
            props: getRouteProps(),
          },

          {
            path: 'campaign-stat',
            name: 'campaign-stat',
            component: () => import(/* webpackChunkName: "agency" */ '../pages/agencys/CampaignStat.vue'),
            props: getRouteProps(),
          },
          {
            path: 'channel-stat',
            name: 'channel-stat',
            component: () => import(/* webpackChunkName: "agency" */ '../pages/agencys/ChannelStat.vue'),
            props: getRouteProps(),
          },
        ],
      },
      /// BRANDS
      {
        path: 'brands/',
        component: WrapperPage,
        children: [
          {
            path: '',
            name: 'brands',
            component: () => import(/* webpackChunkName: "brands" */ '../pages/brands/Brands.vue'),
          },
          {
            path: '',
            name: 'my-brands',
            component: () => import(/* webpackChunkName: "brands" */ '../pages/brands/Brands.vue'),
            props: {
              brandOwnerView: true,
              showSearch: false,
            },
          },
          {
            path: 'create',
            name: 'create-brand',
            component: () => import(/* webpackChunkName: "brands" */ '../pages/brands/create/CreateBrand.vue'),
          },
          {
            path: ':brandID',
            name: 'brand',
            props: getRouteProps(),
            component: () => import(/* webpackChunkName: "brands" */ '../pages/brands/profile/Brand.vue'),
          }         
        ],
      },

      /// CAMPAIGNS
      {
        path: 'campaigns',
        component: WrapperPage,
        children: [
          {
            path: '',
            name: 'campaigns',
            component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/Campaigns.vue'),
            props: getRouteProps(),
          },
          {
            path: 'create',
            name: 'create-campaign',
            component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/create/CreateCampaign.vue'),
          },
          {
            path: ':campaignID/edit',
            name: 'edit-campaign',
            component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/create/CreateCampaign.vue'),
            props: getRouteProps({ edit: true }),
          },
          {
            path: ':campaignID',
            name: 'campaign',
            component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/view/Campaign.vue'),
            props: getRouteProps(),
          },
        ],
      },
      {
        path: 'campaigns-management',
        component: WrapperPage,
        props: getRouteProps({ private: true }),
        children: [
          {
            path: '',
            name: 'campaigns-management',
            component: () => import(/* webpackChunkName: "campaigns-management" */ '../pages/campaigns/CampaignsManagement.vue'),
            props: {
              showSearch: false,
            },
          },
        ],
      },
      {
        path: 'my-campaigns',
        component: WrapperPage,
        props: getRouteProps({ private: true, showSearch: false }),
        children: [
          {
            path: '',
            name: 'my-campaigns',
            component: () => import(/* webpackChunkName: "campaigns" */ '../pages/campaigns/MyCampaigns.vue'),
          },
          {
            path: 'stats/:campaignID',
            name: 'campaigns-stats',
            component: () => import(/* webpackChunkName: "campaigns-stats" */ '../pages/campaigns/CampaignStats.vue'),
            props: {
              showSearch: false,
            },
          },
          {
            path: 'pay-in/:campaignID',
            name: 'campaigns-payIn',
            component: () => import(/* webpackChunkName: "campaigns-draft" */ '../pages/campaigns/PayAndFinishCampaign.vue'),
            props: {
              showSearch: false,
            },
          },
        ],
      },
      {
        path: 'settings',
        name: 'settings',
        component: Empty,
      },
      /// ADMIN
      {
        path: 'admins',
        component: WrapperPage,
        children: [
          {
            path: '/dashboard',
            name: 'admin',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/Admin.vue'),
          },
          {
            path: 'overview-brands',
            name: 'overview-brands',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/OverviewBrands.vue'),
            // children: [
            //   {
            //     path: '',
            //     name: 'overview-brands',
            //     component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/OverviewBrands.vue'),
            //   },
            // ],
          },
          {
            path: 'overview-payments',
            name: 'overview-payments',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/OverviewPayments.vue'),
          },
          {
            path: 'earnings',
            name: 'earnings',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/EarningsPage.vue'),
          },
          {
            path: 'pending-payments',
            name: 'pending-payments',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/PendingPayments.vue'),
          },
          {
            path: 'important-stats',
            name: 'important-stats',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/ImportantStats.vue'),
          },
          {
            path: 'creators-earning',
            name: 'creators-earning',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/CreatorsEarning.vue'),
          },
          {
            path: 'pending-videos',
            name: 'pending-videos',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/PendingVideos.vue'),
          },
          {
            path: 'pending-campaign-applications',
            name: 'pending-campaign-applications',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/PendingCampaignApplications.vue'),
          },
          {
            path: 'detected-frauds',
            name: 'detected-frauds',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/DetectedFrauds.vue'),
          },
          {
            path: 'white-listing',
            name: 'white-listing',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/WhiteListing.vue'),
          },
          {
            path: 'campaign-content/:campaignId',
            name: 'campaign-content',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/CampaignContentList.vue'),
          },
          {
            path: 'invitations',
            name: 'admin-campaign-invitations',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/campaigns/invitations/Invitations.vue'),
            props: {
              viewPerspective: Perspective.ADMIN,
            },
          },
          {
            path: 'notifications',
            name: 'notifications',
            component: () => import(/* webpackChunkName: "admin" */ '../pages/admin/AdminNotification.vue'),          
          },
        ],
      },
    ],
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "auth" */ '../pages/auth/Auth.vue'),
    children: [{
      path: '',
      name: 'auth-admin',
      component: () => import(/* webpackChunkName: "admin" */ '../pages/auth/AdminAuth.vue'),
    }],
  },
  {
    path: '/verify',
    name: 'verify-user',
    component: () => import(/* webpackChunkName: "auth" */ '../pages/auth/verify/Verify.vue'),
    props: getRouteProps(),
  },
  {
    path: '/auth',
    name: 'auth',
    redirect: { name: 'login' },
    component: () => import(/* webpackChunkName: "auth" */ '../pages/auth/Auth.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth" */ '../pages/auth/LoginForm.vue'),
      },
      {
        path: '/logout',
        name: 'logout',
        redirect: () => {
          const { logout } = useAuth();
          logout();
          return { name: 'landing' };
        },
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "auth" */ '../pages/auth/RegistrationForm.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// eslint-disable-next-line consistent-return,@typescript-eslint/no-unused-vars
router.beforeEach(async (to, from, next) => {
  const { user: authUser, authenticationDone, loadUser } = useAuth();

  if (!authenticationDone.value) {
    await loadUser();
  }

  // eslint-disable-next-line no-shadow
  const user = authUser?.value;
  const { requiresAuth } = to.meta;

  if (user) {
    if (user.verified) {
      if (to.name === 'verify-user' || to.name === 'login') {
        next({ name: 'dashboard' });
      } else {
        next();
      }
    } else { // not verified
      if (to.name !== 'verify-user') { // eslint-disable-line no-lonely-if
        next({ name: 'verify-user' });
      } else {
        next();
      }
    }
  } else {
    if (requiresAuth) { // eslint-disable-line no-lonely-if
      next({ name: 'login' });
    } else if (to.name === 'verify-user') {
      next({ name: 'login' });
    } else {
      window.scrollTo(0, 0);
      next();
    }
  }
});

export default router;
