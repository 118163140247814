import React, { memo } from 'react';
import { Chart } from 'primereact/chart';


export const MainBudgetChart = memo(({ budget }) => {
  const dataMain = {
    datasets: [{ data: [budget?.totalBudget ? budget?.totalBudget : 100], backgroundColor: ['#E73D38'], borderWidth: 0 }],
  };

  const optionsMain = {
    cutout: '70%',
    plugins: { legend: { display: false } },
    interaction: { mode: false, intersect: false },
  };

  return (
    <div className={'chartWrapper'}>
      <Chart type="doughnut" data={dataMain} options={optionsMain} className={'chartGraphic'} />
      <div className={'chartPercentage'}>100%</div>
      <div className={'budgetText'}>
        <p className={'budgetLabel'}>Budget:</p>
        <p className={'budgetValue'}>$ {budget?.totalBudget}</p>
      </div>
    </div>
  );
});

export default MainBudgetChart;
