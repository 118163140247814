import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewContentListItem = _resolveComponent("NewContentListItem")!
  const _component_OldContentItemsListItem = _resolveComponent("OldContentItemsListItem")!

  return (_ctx.isInfluencerProfileEnabled)
    ? (_openBlock(), _createBlock(_component_NewContentListItem, {
        key: 0,
        item: _ctx.item,
        handleRouting: _ctx.handleRouting
      }, null, 8, ["item", "handleRouting"]))
    : (_openBlock(), _createBlock(_component_OldContentItemsListItem, {
        key: 1,
        item: _ctx.item,
        campaign: _ctx.campaign
      }, null, 8, ["item", "campaign"]))
}