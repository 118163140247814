
import { ref, defineComponent, watch } from 'vue';
import Dialog from 'primevue/dialog';
import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';
import { useAPI } from "@/modules";
import { CampaignData } from "@/data/types";
import { useCampaignManager } from "@/modules/campaigns/campaign-manager-module";

export default defineComponent({
  props: {
    items: Array,
    campaignId: String,
    isOwner: {
      type: Boolean,
      default: false,
    },
    openBasic: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Dialog,
    FileUpload,
    Button,
  },
  emits: ['updateAtt'],
  setup(props, ctx) {
    const openBasicX = ref(props?.openBasic);
    const campaignManager = useCampaignManager();

    watch(() => props?.openBasic, () => {
      openBasicX.value = props?.openBasic;
    });
    const {
      execute: sendAttachmentsRequest,
    } = useAPI<CampaignData>('', false);
    const attachments = (campaignID: string, attachmentsBody: any) => sendAttachmentsRequest({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `/campaigns/attachments/${campaignID}`,
      method: 'POST',
      data: attachmentsBody,
    });
    const onAdvancedUpload = async (event: any) => {
      const formData = new FormData();
      for await (const file of event.files) {
        formData.set('files[]', file);
        await attachments(props.campaignId as string, formData).then((data) => {
          if (data) {
            ctx.emit('updateAtt');
          }
        });
      }
    };

    const removeAttachment = async (id: string) => {
      await campaignManager.attachmentRemove(props.campaignId as string, id).then(() => {
        ctx.emit('updateAtt');
      });
    };

    return {
      openBasicX,
      removeAttachment,
      onAdvancedUpload,
    };
  },
});
