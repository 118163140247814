import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "content-list p-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandListItem = _resolveComponent("BrandListItem")!
  const _component_EmptyList = _resolveComponent("EmptyList")!
  const _component_app_list_page = _resolveComponent("app-list-page")!

  return (_openBlock(), _createBlock(_component_app_list_page, {
    title: (_ctx.brandOwnerView ? 'My ' : '') + 'Brands',
    pageListEndpoint: "/brands/",
    showSearch: _ctx.showSearch,
    searchType: "brands",
    searchEndpoint: "/brands/search/",
    onSearchResult: _ctx.updateSearchResults
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.brands, (brand) => {
          return (_openBlock(), _createBlock(_component_BrandListItem, {
            key: brand?.id,
            class: "p-col-12 p-md-6 p-lg-6 p-xl-3",
            brand: brand
          }, null, 8, ["brand"]))
        }), 128)),
        (!_ctx.brands.length)
          ? (_openBlock(), _createBlock(_component_EmptyList, {
              key: 0,
              title: "No Brands"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title", "showSearch", "onSearchResult"]))
}