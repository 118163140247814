import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3dc4acdc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-mt-6 p-mb-4" }
const _hoisted_2 = { class: "p-d-flex p-jc-between p-mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: "Campaign Invitation",
    visible: $props.showModal,
    class: "p-col-12 p-md-6 p-xl-4",
    style: {"min-height":"min-content"},
    modal: true,
    closable: false
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Button, {
          label: "OK",
          class: "p-button-link",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($props.showModal = false))
        }),
        _createVNode(_component_Button, {
          label: "Go to campaign",
          class: "gi-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'campaign-active', params: { campaignID: $props.campaignId } })))
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, "You have accepted the invitation from the company " + _toDisplayString($props.campaign?.name) + ".", 1)
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}