
import { defineComponent, onMounted, ref } from 'vue';
import { applyPureReactInVue } from 'veaury';
import { isNewAdminEnabled } from '@/utils/globals/feature-flags';
import NewAdminNotification from '@/react_app/NewAdmin/NewAdminNotification/NewAdminNotification';
import { useAuth, useNotificationsMethods } from '@/modules';
import { useSocketIo, useSocketMethods } from '@/services/socket';

export default defineComponent({
  components: {
    NewAdminNotification: applyPureReactInVue(NewAdminNotification),
  },
  setup() {
    const { user } = useAuth();
    const notificationMethods = useNotificationsMethods();

    const skip = ref(0);
    const limit = ref(10);
    const totalCount = ref(0);
    const userId = ref(user.value?.id);
    const notificationList = ref<any[]>([]);
    const loading = ref(true);
    const searchValue = ref('');

    const socket = useSocketIo(userId.value as string);
    const socketMethods = useSocketMethods(socket);

    const getMoreNotifications = async (skipValue: number, search: string) => {
      loading.value = true;
      skip.value = skipValue;
      socketMethods.getAllNotification(limit.value, skipValue, search);
      socket.on('all-notifications', (data: any) => {
        notificationList.value = data.notification;
        totalCount.value = data.count;
        loading.value = false;
      });
    };

    const handlePageChange = (skipValue: number) => {
      skip.value = skipValue;
      getMoreNotifications(skipValue, searchValue.value);
    };
    const searchNotifications = (search: string) => {
      searchValue.value = search;
      skip.value = 0;
      getMoreNotifications(0, search);
    };

    onMounted(() => {
      getMoreNotifications(skip.value, searchValue.value);
    });

    return {
      isNewAdminEnabled,
      notificationList,
      notificationMethods,
      searchNotifications,
      loading,
      socketMethods,
      totalCount,
      limit,
      handlePageChange,
    };
  },
});
