
import { defineComponent, ref } from 'vue';
import paymentInformationModal from '@/pages/campaigns/create/paymentInformationModal.vue';
import moment from 'moment';
import { useCampaigns, useContentItems, useToastModule, useUsers, } from '@/modules';
import { useRoute, useRouter } from 'vue-router';
import CampaignClosedCard from '@/pages/campaigns/CampaignClosedCard.vue';
import { AppPageProps, BrandData, CampaignPaymentMethod, Perspective } from '@/data/types';
import { formatDate } from '@/utils/globals/date-utils';
import { getGlobalCampaignByRegion } from '@/utils/campaign-utils';
import { applyPureReactInVue } from 'veaury';
import { isNewCampaignsManagementEnabled } from '@/utils/globals/feature-flags';
import OldCampaignStats from "@/pages/campaigns/OldCampaignStats.vue";
import NewCampaignStats from "@/react_app/NewCampaignStats/NewCampaignStats";

export default defineComponent({
  components: {
    NewCampaignStats: applyPureReactInVue(NewCampaignStats),
    OldCampaignStats
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    ...AppPageProps,
  },
  setup(props) {
    const route = useRoute();
    const campaigns = useCampaigns();
    const contentItemsModule = useContentItems();
    const router = useRouter();
    const user = useUsers();
    const toast = useToastModule();
    const currentCampaign = ref<any>();
    const selectedInviteQuery = ref();
    const campaignId = route.params.campaignID;

    const campaignClosed = ref(false);
    campaigns.manager
      .loadSingle(route.params.campaignID as string)
      .then((camp) => {
        currentCampaign.value = camp;
        if (camp?.status === 'completed') {
          campaignClosed.value = true;
        }
      });

    const showTerms = ref(false);
    const showBudgetModal = ref(false);
    const showWarningModal = ref(false);
    const cardShow = ref(false);
    const showPayment = ref(false);
    const showSuccessModal = ref(false);
    const campaignFinished = ref(false);
    const active = ref(0);
    const editCampaign = ref(false);
    const differenceout = ref();
    const remDays = ref();
    const isLoggedUserCampaignOwner = ref(false);
    const isLoadingPublishCampaign = ref(false);

    campaigns.manager
      .loadSingle(route.params.campaignID as string)
      .then((camp) => {
        currentCampaign.value = camp;
        const startDate = moment(camp?.dates?.startDate);
        const endDate = moment(camp?.dates?.endDate);
        const current = moment().startOf('day');
        const diff1 = moment.duration(current.diff(startDate)).asDays();
        const diff = moment.duration(endDate.diff(startDate)).asDays();
        const remainingDays = moment.duration(endDate.diff(current)).asDays();
        remDays.value = remainingDays;
        let difference = 0;
        if (startDate.isAfter(current)) {
          difference = 0;
        } else if (endDate.isBefore(current)) {
          difference = 100;
          campaignFinished.value = true;
        } else {
          difference = (diff1 / diff) * 100;
        }
        differenceout.value = difference;
        if (!camp?.dates?.endDate) {
          campaignFinished.value = true;
          difference = 100;
          differenceout.value = difference;
        }

        // Prevent users that are not the campaign owners to view the edit page of the campaign
        user.manager.loadMyProfile().then((loggedUser: any) => {
          const { brand: loggedUserBrand } = loggedUser;

          if (!loggedUser || !loggedUserBrand || !loggedUserBrand?.id) {
            isLoggedUserCampaignOwner.value = false;
            return;
          }

          const { brand: campaignBrand } = currentCampaign.value;

          if (
            typeof campaignBrand === 'string' &&
            loggedUserBrand.id === campaignBrand
          )
            isLoggedUserCampaignOwner.value = true;
          else isLoggedUserCampaignOwner.value = loggedUserBrand.id === (campaignBrand as BrandData).id;
        });
      });

    const updateCampaignData = async () => {
      await campaigns.manager.loadSingle(route.params.campaignID as string);
    };

    const paymentInfoChange = async (payload: any) => {
      showPayment.value = false;
      await campaigns.manager.update(currentCampaign.value?.id, {
        paymentInformation: {
          ...currentCampaign.value?.paymentInformation,
          ...payload,
        },
      });
      await campaigns.manager.loadSingle(route.params.campaignID as string);
    };

    campaigns.manager.getTotalViewsCampaign(route.params.campaignID as string);
    const showPaymentModal = ref(false);
    const showPaymentModalActive = ref(false);

    const campaignClosing = async () => {
      await campaigns.manager.complete(route.params.campaignID as string);
      active.value = 0;
      cardShow.value = true;
      campaignClosed.value = true;
      await router.push({ name: 'my-campaigns' });
    };
    const closeCard = () => {
      campaignClosed.value = true;
    };
    const newBudget = ref();

    const budgetModal = async (newBudgets: any) => {
      if (
        currentCampaign.value?.paymentMethod === CampaignPaymentMethod.CPI &&
        (newBudgets.cpi.android.amount >=
          currentCampaign.value?.cpi.android.amount ||
          newBudgets.cpi.ios.amount >= currentCampaign.value?.cpi.ios.amount)
      ) {
        await campaigns.manager.changeCpi(
          currentCampaign.value?.id,
          newBudgets.cpi
        );
        await campaigns.manager.loadSingle(route.params.campaignID as string);
      }
      if (currentCampaign.value?.paymentMethod === CampaignPaymentMethod.CPM) {
        await campaigns.manager.changeCpm(
          currentCampaign.value?.id,
          newBudgets.cpm
        );
        await campaigns.manager.loadSingle(route.params.campaignID as string);
      }
      if (currentCampaign.value?.paymentMethod === CampaignPaymentMethod.CPC) {
        await campaigns.manager.changeCpc(
          currentCampaign.value?.id,
          newBudgets.cpc
        );
        await campaigns.manager.loadSingle(route.params.campaignID as string);
      }

      if (newBudgets.budget.amount > currentCampaign.value?.budget.amount) {
        showPaymentModalActive.value = true;
      }
      if (newBudgets.budget.amount > currentCampaign.value?.budget.amount) {
        showPaymentModalActive.value = true;
      }
      showBudgetModal.value = false;
      newBudget.value = newBudgets;
    };

    contentItemsModule.list.getCampaignContent(
      route.params.campaignID as string
    );

    const editedCampaign = () => {
      editCampaign.value = true;
    };

    const createdCamp = ref();
    const createLoading = ref(false);

    const launchCampaign = async () => {
      createLoading.value = true;
      await campaigns.manager.activateCampaignWithCsv(
        route.params.campaignID as string
      );
      await campaigns.manager.loadSingle(route.params.campaignID as string);
      createLoading.value = false;
    };
    const goToNewCampaign = async () => {
      await router.push({
        name: 'campaign',
        params: { campaignID: createdCamp.value.id },
      });
    };
    const campaignPaySuccess = async () => {
      showSuccessModal.value = true;
      showPaymentModalActive.value = false;
    };
    if (route.query && route.query.transactionId) {
      campaignPaySuccess(); // FIXME nee update logic
      router.push(route.path);
    }

    const requestSuccess = async () =>
      contentItemsModule.list.getCampaignContent(
        route.params.campaignID as string
      );

    const updateAtt = async () => {
      await campaigns.manager.loadSingle(route.params.campaignID as string);
    };

    const inviteCreators = async (v: {
      value: { name: string; value: string };
    }) => {
      if (v.value.value === 'on') {
        await router.push({ name: 'creators' });
      }
      if (v.value.value === 'outside') {
        if (route.params?.campaignID) {
          selectedInviteQuery.value = '';
          toast.showSuccess(
            'The invitation link to your company has been copied.'
          );
          await navigator.clipboard.writeText(
            `${location.protocol}//${location.host}/?invite=${route.params.campaignID}`
          );
        }
      }
    };

    const handleReturn = () => {
      if (props.viewPerspective === Perspective.BRAND) {
        router.push({ name: 'campaigns-management' });
      } else if (props.viewPerspective === Perspective.AGENCY || props.viewPerspective === Perspective.CREATOR) {
        router.push({ name: 'my-campaigns' });
      }
    }

    const saveEdit = async (newData: any) => {
      await campaigns.manager.update(route.params.campaignID as string, newData).then(async () => {
        await campaigns.manager.loadSingle(route.params.campaignID as string);
        toast.showSuccess(
          'Campaign updated successfully.'
        );
      }).catch((error) => {
        console.error('Error :', error);
      });
    };

    const goToContent = (id: string) => {
      router.push({ name: 'content-item', params: { itemID: id } });
    }

    const pushDraft = async (id: string) => {
      await router.push({
        name: 'campaigns-stats',
        params: {
          campaignID: id,
        },
      }).then(() => {
        campaigns.manager.loadSingle(id);
      });
    }

    const handlePublishCampaign = async (id: string) => {
      isLoadingPublishCampaign.value = true;
      await campaigns.manager.pending(id).then(async (res) => {
        if (res) {
          isLoadingPublishCampaign.value = false;
          await campaigns.manager.loadSingle(id);
        }
      }).catch(error => {
        console.log('error', error?.message || 'Something went wrong');
        isLoadingPublishCampaign.value = false;
      });
    }

    return {
      isLoggedUserCampaignOwner,
      inviteCreators,
      updateAtt,
      formatDate,
      requestSuccess,
      campaignPaySuccess,
      saveEdit,
      launchCampaign,
      updateCampaignData,
      goToNewCampaign,
      getGlobalCampaignByRegion,
      budgetModal,
      closeCard,
      campaignClosing,
      paymentInfoChange,
      createLoading,
      remDays,
      differenceout,
      showPaymentModal,
      showPaymentModalActive,
      showSuccessModal,
      showTerms,
      active,
      currentCampaign,
      selectedInviteQuery,
      liveContentItems: contentItemsModule.list.getCampaignContentResult,
      totalCampaignsViews: campaigns.manager.getTotalViewsCampaignData,
      newBudget,
      paymentInformationModal,
      campaignClosed,
      showBudgetModal,
      CampaignClosedCard,
      editCampaign,
      showWarningModal,
      cardShow,
      campaignFinished,
      showPayment,
      campaign: campaigns.manager.singleCampaign,
      CampaignPaymentMethod,
      isNewCampaignsManagementEnabled,
      handleReturn,
      goToContent,
      pushDraft,
      handlePublishCampaign, campaignId,
      isLoadingPublishCampaign
    };
  },
});
