import React, { useEffect, useState } from "react";
import "./NewDraftApproval.css";
import { CopyIconSmall, TrashIcon } from "@/react_app/img/Icons";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { formatDate } from "@/utils/globals/date-utils";
import congrats from "../img/congrats.gif";


const videoTemplate = (rowData) => {
  return (
    <div className={'videoTemplate'}>
      <Image src={rowData.thumbnails.default.url} alt={'video thumbnail'} className={'videoThumbnail'} />
      <p className={'videoTitle'}>{rowData.title}</p>
    </div>
  )
};

const urlTemplate = (rowData) => {
  return (
    <div className={'urlTemplate'}>
      <p className={'urlLink'}>{rowData.url}</p>
      <div onClick={() => navigator.clipboard.writeText(rowData.url)}>
        <CopyIconSmall />
      </div>
    </div>
  )
};

const statusTemplate = () => {
  return (
    <div className={'statusTemplate'}>
      Uploaded
    </div>
  )
};

const dateTemplate = (rowData) => {
  return (
    <div className={'dateTemplate'}>
      {formatDate(rowData.createdAt)}
    </div>
  )
};

const deleteTemplate = () => {
  return (
    <div className={'deleteTemplate'}>
      <TrashIcon />
    </div>
  )
};

export default function (props) {
  const [progressCpmCampaign, setProgressCpmCampaign] = useState({
    videoDraft: false,
    draftAccepted: false,
    draftRejected: false,
    liveVideoPosted: false,
    rejectReason: "",
  });

  useEffect(() => {
    if (props && props?.progressCpmCampaign) {
      setProgressCpmCampaign(prevState => ({ ...prevState, ...props?.progressCpmCampaign }))
    }
  }, [props])

  return (
    <div className={props.displayNone ? 'submit-draft-approval display-none' : 'submit-draft-approval'}>
      <div className={'coverBackground'} style={{ background: `linear-gradient(rgba(19, 10, 35, 0.7), rgba(19, 10, 35, 0.7)), url(${props.campaign?.photo})` }}>
        {progressCpmCampaign?.draftAccepted && <Image src={congrats} alt={'congrats gif'} className={'congratsGif'} />}
        {!progressCpmCampaign?.draftAccepted ?
          <>
            {progressCpmCampaign?.draftRejected ? <div className={'linkAndGamekeys'}>
              <p className={'title'}>Your content has been Rejected!</p>
              <p className={'text'}>Make sure to read our comments and adjust your video content.</p>
            </div> :
              <div className={'linkAndGamekeys'}>
                <p className={'title'}>You have just submitted your draft video for an approval!</p>
                <p className={'text'}>As our platform is still in its MVP stage, all videos will await approval, a member of our team will review the content and you will get a notification once it gets approved.</p>
              </div>}
          </>
          :
          <div className={'linkAndGamekeys approved'}>
            <p className={'title'}>Your content has been approved!</p>
            <p className={'text'}>Now it's time to make your YouTube video public, so everyone can see it and you can start earning from your content!</p>
          </div>
        }
        <div className={`acceptWrapper ${progressCpmCampaign?.draftAccepted ? 'approved' : ''}`}>
          {!progressCpmCampaign?.draftAccepted ?
            <>
              {progressCpmCampaign?.draftRejected ?
                <Button className={'acceptButton'} onClick={() => props.handleChangeTab(1)}>Submit Draft Content</Button> :
                <Button className={'homeButton'} onClick={() => { props?.handleRoutingHomepage() }}>Return to Homepage</Button>
              }
            </> :
            <>
              {!progressCpmCampaign?.liveVideoPosted ? <Button className={'acceptButton'} onClick={() => props.handleChangeTab(3)}>Submit Content</Button>
                : <Button className={'homeButton'} onClick={() => { props?.handleRoutingHomepage() }}>Return to Homepage</Button>}
            </>
          }
        </div>
      </div>
      <div>
        <p className={'uploadedContentTitle'}>Uploaded Content</p>
        <DataTable value={props?.latestSubmits}>
          <Column field="title" header="Video" body={videoTemplate} style={{ width: '45%' }}></Column>
          <Column field="url" header="Video Link" body={urlTemplate} style={{ width: '28%' }}></Column>
          <Column field="" header="Status" body={statusTemplate} style={{ width: '14%' }}></Column>
          <Column field="createdAt" header="Submission Date" body={dateTemplate} style={{ width: '14%' }}></Column>
          <Column field="" header="" body={deleteTemplate} style={{ width: '9%' }}></Column>
        </DataTable>
        <div className={'buttonGroup'}>
          <Button className={'leaveButton'} onClick={() => props.leaveCampaign()}>Leave Campaign</Button>
        </div>
      </div>
    </div>
  )
}
