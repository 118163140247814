
import { useCampaigns } from '@/modules';
import { defineComponent, ref, watch } from 'vue';
import Dropdown from 'primevue/dropdown';
import LatestCampaignItem from '@/pages/creators/latest/LatestCampaignItem.vue';
import AppListPage from '../commons/AppListPage.vue';

export default defineComponent({
  name: 'Campaigns',
  components: { AppListPage, LatestCampaignItem, Dropdown },
  props: {
    brandOwnerView: Boolean,
    showSearch: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const campaigns = ref();

    const campaign = useCampaigns();
    campaign.list.loadCampaigns(false, 0).then((data) => {
      campaigns.value = data;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      sort();
    });
    const clearSearch = () => {
      campaign.list.loadCampaigns(false, 0).then((data) => {
        campaigns.value = data;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        sort();
      });
    };
    const updateSearchResults = (list: any) => {
      if (props.limit) {
        campaigns.value = list.slice(0, props.limit);
      } else {
        campaigns.value = list;
      }
    };

    const orders = ref([{
      name: 'Most recent start date',
      code: 'startDate',
    }, {
      name: 'Most recent end date',
      code: 'endDate',
    }]);
    const selectedOrder = ref(orders.value[0].code);
    const sort = () => {
      campaigns.value = campaigns.value.sort((a: any, b: any) => {
        const c = Math.abs(new Date(b.dates[selectedOrder.value]).getTime());
        const d = Math.abs(new Date(a.dates[selectedOrder.value]).getTime());
        return c - d;
      });
    };
    watch(selectedOrder, () => sort());
    return {
      campaigns,
      updateSearchResults,
      clearSearch,
      orders,
      selectedOrder,
    };
  },
});
