
import { ref, watch } from 'vue';
import ProgressBar from 'primevue/progressbar';
import { useAPI, useCampaigns } from '@/modules';
import { CampaignData } from '@/data/types';

export default {
  name: 'DropZone',
  components: { ProgressBar },
  props: {
    campaignID: String,
    attachments: Array,
    isMyCampaign: Boolean,
    file: Object, // if don't saved campaign
  },
  setup(props: any, ctx: any) {
    watch(() => props.attachments, props.attachments);
    const active = ref(false);
    const input = ref();
    const form = ref();
    const progressPercents = ref(0);
    const showIcon = ref(false);
    const showRemove = ref(false);

    const controller = useCampaigns(props.viewPerspective);

    const toggleActive = () => {
      active.value = !active.value;
    };

    const removeFileFromAwsAnsDatabase = (id: string, attachmentID: string) => controller.manager
        .attachmentRemove(props.campaignID, attachmentID);
    const removeFileFromAwsAnsDatabaseMethod = (campaignID: string, id: string, index: number) => {
      props.attachments.splice(index, 1);
      removeFileFromAwsAnsDatabase(campaignID, id);
    };
    const deleteWithUrl = async (index: number) => {
      await controller.manager.attachmentRemoveUrl(props.file?.url);
      props.attachments.splice(index, 1);
    };
    // post attachments
    const {
      execute: sendAttachmentsRequest,
    } = useAPI<CampaignData>('', false);
    const attachments = (campaignID: string, attachmentsBody: any) => sendAttachmentsRequest({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `/campaigns/attachments/${campaignID}`,
      method: 'POST',
      onUploadProgress: (progressEvent: any) => progressPercents.value = parseInt(Math.round((progressEvent.loaded
          / progressEvent.total) * 100).toString(), 10),
      data: attachmentsBody,
    });
    const onChange = async () => {
      if (!input.value.files[0]) {
        return;
      }
      const formData = new FormData();
      if (props?.campaignID) {
        for await (const file of input.value.files) {
          formData.set('files[]', file);
          await attachments(props.campaignID, formData).then((data) => {
            if (data) {
              showIcon.value = true;
              ctx.emit('onAddedFile', data);
              showRemove.value = true;
              setTimeout(() => {
                showIcon.value = false;
              }, 1000);
            }
          });
        }
      } else {
        for (const file of input.value.files) {
          ctx.emit('onAddedFile', file);
        }
        input.value.value = null;
      }


      // < post attachments
    };
    const drop = (e: { dataTransfer: any }) => {
      if (e.dataTransfer && e.dataTransfer.files) {
        const dt = new DataTransfer();
        e.dataTransfer.files.forEach((file: any) => {
          dt.items.add(file);
        });
        input.value.files = dt.files;
        toggleActive();
        onChange();
      }
    };

    return {
      showIcon,
      showRemove,
      progressPercents,
      // loaders shows
      active,
      toggleActive,
      drop,
      input,
      form,
      onChange,
      removeFileFromAwsAnsDatabase,
      removeFileFromAwsAnsDatabaseMethod,
      deleteWithUrl,
    };
  },
};
