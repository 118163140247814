<!-- eslint-disable max-len -->
<!-- eslint-disable vue/max-len -->
<template>
  <div>
    <Dialog :visible="show" class="p-col-12 p-md-6 p-xl-4 gi-dialog" :modal="true" :style="{ width: '1110px' }" @update:visible="$emit('update:show', false)">
      <template #header>
        <div class="p-flex-column" style="margin-left: 120px">
          <img class="image" src="@/assets/images/live-content-img.svg" />
          <p class="title-main">The advanced licensing creator program</p>
          <p style="margin-top: 90px">Besides creating creator ads for campaigns, here at GameInfluencer.IO we have another way for you to earn additional income.</p>
        </div>
      </template>
      <h2 class="p-mt-5" style="text-align: center">Additional licensing agreement</h2>
      <div class="p-d-flex p-flex-column p-p-4 p-ai-center vh-container">
        <div class="p-ai-start terms-conditions">Terms and Conditions</div>
        <div class="text-box">
          <p><strong>GameInfluencer.IO platform Terms of Service – Creator</strong></p>
          <strong>Definitions</strong>
          <p><strong>Action</strong> - shall mean (but not exclusively) a click, install, lead, sale, view defined as a payment trigger as set out below</p>
          <p><strong>Brands</strong> – Refers to entities or persons using GameInfluencer.IO or in contact with Gameinfluencer for creating campaigns</p>
          <p><strong>Campaign Brief</strong> – Campaign details and documents provided by Brand for clarification of Campaign</p>
          <p><strong>Campaign CPI Model</strong> – predetermined metric “cost per install” to set the prices paid per conversions</p>
          <p><strong>Campaign CPC Model</strong> – predetermined metric “cost per click” to set the prices paid per conversions</p>
          <p><strong>Campaign CPM Model</strong> – predetermined metric “cost per 1000 views” to set the prices paid per conversions</p>
          <p>
            <strong>Creators</strong> – refer to Content Creators who make use of GameInfluencer.IO or are in contact with Gameinfluencer to advertise product(s), service(s) or brand(s) for the
            benefit of, and/or under an assignment from, the Brands under this Agreement.
          </p>
          <p><strong>Creator Ad Campaign</strong> – Marketing Campaign available on GameInfluencer.IO which involves Content Creators</p>
          <p><strong>Creator Ad</strong> – Content produced following the Brief and in association with a specific Campaign from GameInfluencer.IO</p>
          <p><strong>Creators Channel</strong> – refers to the Content Creators channel on a Media Platform</p>
          <p><strong>Creator Link</strong> – The tracking link provided to Content Creator to track performance of a Campaign from GameInfluencer.IO</p>
          <p>
            <strong>Content</strong> – refers to all content uploaded by the creator to the relevant and agreed channel under this Agreement Posts made on the Creators Media
            Platforms
          </p>
          <p>
            <strong>Intellectual Property Rights</strong> – includes all patents, utility models, rights to inventions, copyright and neighbouring and related rights and rights in
            other subject matter, trade-marks and service marks, business names and domain names, rights in get-up and trade dress, goodwill and the right to sue for passing off or
            unfair competition, rights in designs, database rights, rights to use, and protect the confidentiality of, confidential information (including know-how and trade
            secrets), and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be
            granted, renewals or extensions of, and rights to claim priority, in relation to such rights, and rights to enforce and seek remedies and redress for the infringement
            of violation of any such rights, past, present, or future, and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the
            future in any part of the world;
          </p>
          <p>
            <strong>License Request</strong> – A feature of GameInfluencer.IO in which brands can request to purchase the license rights to a Creator Ad, after the completion of the campaign.
          </p>
          <p><strong>Media Platform</strong> – refers to social media platforms, including but not limited to, YouTube, Instagram and Tik Tok</p>
          <p><strong>Paid Content</strong> - refers to Posts made on the Creators channel for a specific Campaign that they have accepted on GameInfluencer.IO</p>
          <p><strong>Posts</strong> – refers to a Media Platform post to share Content with their community.</p>
          <p>
            <strong>Real Installs</strong> - A Real Install means only a real individual person that actively accesses the application via a Creator Link and then proceeds to
            install and launch the campaign promoted application while on the Internet. This only applies for a real individual person and cannot be a robot script or computer
            generated and can also not be identified as fraudulent or a duplicate by GameInfluencer.IO. A Real Install also means that is has come from the targeted tier specified in the
            relevant Campaign (e.g. Tier 1 countries).
          </p>
          <p><strong>Third-Party Services</strong> – refers to any piece of Hardware or Software necessary to run GameInfluencer.IO</p>
          <p>
            <strong>Third-party Tracking Data Providers</strong> – refers to Attribution data partner companies such as but is not limited to companies such as Apps Flyer, Tune,
            Adjust.
          </p>
          <p>
            <strong>Tracking Link</strong> – refers to a link that is created on the platform for Creators to share alongside their Ads which allows for the performance to be
            tracked.
          </p>

          <br /><br />
          <p>
            <strong>Introduction.</strong> The GameInfluencer.IO platform is operated and owned by GameInfluencer. By using GameInfluencer.IO Platform, you decide to accept these GameInfluencer.IO Platform Terms of Use
            (“Terms of Use”). GameInfluencer created this platform to bring together Brands and Creators, allowing Brands to acquire Content for their marketing campaigns, and for
            Creators to sell Paid Content, generating and sharing sponsored Posts through the Creators Channels (YouTube) with support from GameInfluencer to help provide control
            and direction in alignment with these Terms if Use. In these Terms of Use, Creators and Brands are collectively referred to as “you” depending on the context. The GameInfluencer.IO
            Platform allows Brands to create Creator Ad Campaigns via the which are then distributed to our network of Creators and displayed on the GameInfluencer.IO Platform. Creators can
            choose to participate and accept the Creator Ad Campaign based on information& terms outlined in the Brief and Terms of Service. A Creator will earn, and a Brand will
            pay Creators when:
          </p>
          <li>
            in relation to a Creator Ad Campaign, when the Creator Ad is published to the Creator’s Community, such as viewers and fans, via their Channels, as selected; and starts
            generating conversions via their Designated Tracking Link.
          </li>
          <li>
            in relation to a Creator Ad Campaign, the Brand requests and acquires rights to use one of the designated campaign Creator Ad in additional marketing channels as
            further clarified in the correlating License Request post campaign.
          </li>
          <br />
          <p>
            At GameInfluencer.IO it’s important for us to ensure all Posts are transparent and have the consumers best interest at heart. Put simply, all Posts acquired through GameInfluencer.IO should be
            transparent and have labeled disclosure such as #ad or any other guidelines set by the relevant Media Platform. Brands and Creators making use of GameInfluencer.IO are expected to
            stay up to date themselves regarding the latest best practices regarding the labeling of ads in Creator’s marketing Posts so that the consumers best interest is kept at
            heart. Making use of GameInfluencer.IO users comply with all applicable laws and industry self-regulation relating to influencer marketing and advertising, such as all applicable
            laws and regulations, including without limitation guidelines and laws relevant to promotional content. Creator will follow all FTC and other guidelines and laws
            relevant to promotional content. GameInfluencer.IO uses YouTube API Services to gain insight on video performance and the channels audience to provide Creators with relevant
            Campaigns. By making use of GameInfluencer.IO and uploading relevant Content on YouTube Creators agree and are bound to the YouTube Terms of Service and Google Privacy Policy, as of
            the latest updates. If the user fails to comply with any of the Platform guidelines and laws previously mentioned, the user can instantly be terminated from the GameInfluencer.IO
            platform. The use of GameInfluencer.IO is only upon your agreement to accept these Terms of Use. Every time you make use of GameInfluencer.IO you are bound and in agreement with these Terms of
            Use. If you don’t desire to be bound to these Terms of Use, don’t use GameInfluencer.IO.
          </p>
          <p>
            Only legal entities as well as natural persons as entrepreneurs of unlimited legal capacity may become party of the Agreement. GameInfluencer reserves the right to
            demand supporting documents from the Brand to prove its legal status and in case of an employee of the Brand and / or an agency registering with the GameInfluencer
            Network and / or concluding the Agreement, to demand a written authorization.
          </p>
          For further transparency, you are required to accept these Terms of Use as part of the application process by clicking the “I agree to the Terms of Use” box. Both when
          using the GameInfluencer.IO platform in any forms possible as well as accepting the Terms of Service when registering, a legally binding contract is created between GameInfluencer.IO and you the
          Creator.

          <p>
            <strong>Registration requirements.</strong> To register an account to use GameInfluencer.IO as a Creator (“Creator Account”), an individual must sign-in to the platform with their
            YouTube account, as specified on the platform and these Terms of Use. For signing up to the GameInfluencer.IO platform as well as when applying to campaigns, Creator’s must provide
            factual and clear information as part of the registration process, to ensure rightful ownership of Content as well as for Payment and Tax Filing purposes. Creators must
            also ensure they are eligible for payout through our payment Partner GigaPay; find their terms and conditions
            <a target='_blank' href="https://www.gigapay.co/resources/t-and-c/terms-and-conditions">here</a>. In order to be able to accept campaigns and receive monetary compensation you are
            required to connect your YouTube account. By doing so, you are granting GameInfluencer.IO permission to access historical account activity, usage data and audience insights, in
            compliance with these Terms of Use.
          </p>
          Any person aged 16+ years, or 12+ years with the written consent of a parent or legal guardian, may register an account on GameInfluencer.IO, but in must meet the following
          requirements to be rewarded compensation:
          <p>You must be of legal age to use the relevant social Media Platform and the relevant platforms prevailing terms of conditions.</p>
          <p>You must accept and participate in campaigns and receive monetary compensation.</p>
          <p>Your channel cannot feature any Content that is contrary to these terms of use.</p>
          <p>
            Your channel must be grown organically, it is forbidden to misrepresent sub counts, views, or audience data. Channels who acquired their followers through unethical
            behavior such as (but not limited to) purchasing of followers, fabricating subscribers or any other engagement metric displayed on YouTube can be removed from GameInfluencer.IO
            platform instantaneously without prior required notice.
          </p>
          <p>The content you release on this channel must be original and created by you, not recycled or reposted work of any other creator or entity.</p>
          <p>
            <strong>Creator & Content Requirements.</strong> You are personally responsible for reviewing the campaign briefs and campaign information to ensure you are eligible to
            accept the relevant campaigns. All Content you upload or submit on GameInfluencer.IO must comply with the requirements outlined in the Terms of Use and any additional requirements
            outlined by GameInfluencer.IO or the Brand for the relevant campaign submission. GameInfluencer.IO and GameInfluencer have absolute discretion to remove from the platform any Content submission
            they choose whether or not they have been submitted, reviewed, or approved by the Brand. You agree GameInfluencer and GameInfluencer.IO can review and moderate any piece of Content
            you upload on GameInfluencer.IO and give any necessary input and feedback.
          </p>
          <p>
            CPC and CPI campaigns do not typically require a draft and approval process, once a campaign has been accepted, content can be created, and conversions earned. CPM
            campaigns require the submission of a draft video, which may be refused with feedback to try again, or without.
          </p>
          <p>
            You agree to disclose sponsored ad Content and your relationship with the relevant Brand in an obvious and transparent manner to every day viewers of your channel and
            as stipulated in the requirements of your relevant social Media Platform such as, but no limited to, YouTube. GameInfluencer.IO will scan and review relevant uploads on your channel
            and historic upload behavior in regards to transparency disclosure and if required because of a lack of transparency, remove you from the GameInfluencer.IO platform.
          </p>
          For every upload on GameInfluencer.IO you guarantee that:
          <li>You are aged 16+ or aged 12+ with parental consent.</li>
          <li>If you are aged 16+ and your Content features children aged younger than 17 years, you are their parent or legal guardian.</li>
          <li>If you are aged between 12 and 16, your Content shouldn’t feature other kids around your age.</li>
          <br /><br />
          The submitted Content and uploaded Content for Campaigns shall remain live on your channel for a minimum of 180 days unless otherwise stipulated.
          <p>Basic Dos and Don’ts for creating content that are to be followed unless specifically mentioned in the brief:</p>
          <p><strong>Dos</strong></p>
          <li>Please make sure that the integration fits in with the rest of the video</li>
          <li>Content Structure – Please be creative while meeting format requirements</li>
          <li>Content – Keep it fun, exciting, entertaining, and informative</li>
          <li>Visual Quality – HD 1080p 30fps or above without pixelated footage</li>
          <li>Sound Quality – Clear voice with no background noise</li>
          <li>Editing Quality – Clean and enjoyable to watch</li>
          <li>Title – Make it as intriguing as possible</li>
          <li>Thumbnail – Make it eye-catching and look positive</li>
          <li>Script – Speak naturally. DO NOT read from the text on camera</li>
          <li>Please show your face if you usually do so in your past videos.</li>
          <p><strong>Don’ts</strong></p>

          <li>Do not make negative or derogatory comments about the promoted product and any brands associated with it in any of the content items released for this campaign</li>
          <li>Do not exploit or talk negatively on other brands such as competitors to the promoted product</li>
          <li>Please do not compare or allude to the in-game characters/creatures to people/creatures in real life or in other works.</li>
          <li>Please do not include violence, sex, politics related content.</li>
          <li>Please do not swear, drink, smoke, or do drugs during content.</li>
          <li>Please do not say words like “now the advertisement/integration begins” at the beginning of the video.</li>

          <p>
            In case of any breach of this Terms of Service or any guidelines provided by the GameInfluencer.IO platform could result in immediate termination of the agreement, any form of
            monetary compensation or remuneration as well potential immediate removal of published Content. In case any product was sent to the Creator as part of the campaign, it
            shall be returned to GameInfluencer incase it’s in connection with the Campaign. In case of breach of agreement, Creator understands that he could be liable to cover
            the damages incurred by GameInfluencer.IO & GameInfluencer including but not limited to damages to goodwill.
          </p>
          <p>
            Creator is only eligible for payout once they have ensured they have connected with our payment Partner GigaPay and have posted the relevant content for the campaign
            correctly. Alongside the relevant Content created by the Creator and published on their YouTube channel, the Creator agrees to include in the relevant description the
            potential Brand & GameInfluencer.IO provided Creator Link. This Creator Link will link to a relevant campaign promotion and is often, but not limited to, mobile store, website,
            product promotional page. Alongside the Creator Link, the Creator is expected to follow the provided guidelines for the sentence structuring around the link. When a
            Creator Link is provided it must be included and ensured that it’s the correct Creator Link provided by GameInfluencer.IO. It should be present in the Content in the beginning of
            the video’s description or any other place if required.
          </p>
          <p>
            The Brands & Creators understands that GameInfluencer.IO is in constant development and improvement and some of the platform will change over time. Brands & Creators have the right
            to use the platform through the registration process. Both Creators and Brands understand that depending on the market development, GameInfluencer.IO might be terminated or
            temporarily out of service. GameInfluencer.IO can be tweaked and improved whenever desired even if it potentially affects layout or current services, and can be done without
            requesting any consent from Creators or Brands. GameInfluencer shall notify the Brand, as far as plannable, with a period of 48 hours in advance of such technical
            measures, if these have an substantial impact on the GameInfluencer services rendered to Brand. Besides the key points outlined in the agreement, the entire platform is
            “as is” and doesn’t come with any warranties. The Brands & Creators are expected to use the platform as described and in good faith, any illegal or unlawful activities
            are forbidden. GameInfluencer.IO can deny any Creator or Brand access to GameInfluencer.IO without written notice and prior consent if GameInfluencer.IO & Gameinfluencer notice the Brand is harming the
            platform or affecting its useability in any form possible, including but not limited to, hacking and malware. Brands & Creators are responsible for keeping their login
            information confidential. In case any unrightful user acquires the login details, the Brand or Creator should inform GameInfluencer.IO & Gameinfluencer immediately as any changes
            made by the unrightful user in GameInfluencer.IO are sole liability of Brand. For Brands & Creators, any Third-Party Services, hardware or software necessary to successfully use
            GameInfluencer.IO are their sole responsibility, such as but not limited to computers, phones, Third-party Tracking Data Providers. In no way shape or form are GameInfluencer.IO & Gameinfluencer
            liable for the affected performance of GameInfluencer.IO based on these Third-Party Services, hardware or software performances. This agreement overrides all existing agreements,
            understandings or communications between the Brand and GameInfluencer.IO (and Gameinfluencer team) in regards to the topics covered in the Agreement and makes up the entire
            agreement between the Brand and the GameInfluencer.IO (and Gameinfluencer team).
          </p>
          <p>
            <strong>Relevant Data & personal information.</strong> GameInfluencer.IO collects basic personal data as filled out in the application process and payment section of the platform.
            Such data includes, but is not limited to, examples such as Channel name, contact information, payment details, and Photo’s. Creator’s and Platform accept that GameInfluencer.IO
            acquires tracking data, usage data, and other technical data relating to the relevant Content. GameInfluencer.IO requires to analyze the nature of the Content on the Creator’s
            Channel to provide them the most accurate Campaign opportunities to their Channel’s Content.
          </p>

          <p>
            <strong>Intellectual property rights.</strong> You own all the Intellectual Property Rights to your GameInfluencer.IO uploaded Content and all the necessary licensing to register
            the social media as well as license the Content to GameInfluencer.IO and the Brand. You have all the rights to sell the Content to GameInfluencer.IO for use of GameInfluencer.IO, the Brand or any other third
            party (as applicable), in the manner set out in these Terms of Use. All opinions expressed in the Content are entirely your own and your Content does not contain any
            misrepresentation that you or any entity has received approval or a potential sponsorship of any other entity which isn’t the case.
          </p>
          <p>
            All points made about a Brand in the Content posted for a Campaign are true and genuine representative of your opinion. You accurately state your use and experience and
            incase anything changes you actively reach out GameInfluencer.IO in a timely manner to notify them in case any views stated in your Content have changed. Besides any Third-Party
            Material mentioned and included in the Brand’s Campaign, the Content is entirely unique having not been previously broadcasted, streamed, published, posted or
            communicated to the public by you. If you do add Third Party Material you have all the required licenses, consents, and permissions to add them into your Content and
            for any third party such as GameInfluencer.IO, GameInfluencer, or the relevant Brand to use your Content as clarified in these Terms of Use. You are also required to fully disclose
            and use or license limitations to GameInfluencer.IO, GameInfluencer or the relevant Brand. In case you are a part of any guild or union, you ensure that you have not entered any
            agreement arrangement or understanding which would limit you from following these Terms of Use in relation to your submitted Content on GameInfluencer.IO.
          </p>
          <p>
            If the Content contains images or references to third parties or third party property outside of the material provided by the Brand for the campaign, you have written
            agreement that those images, property and references may be included into the Content and used by GameInfluencer.IO, GameInfluencer, the Brand or any third party in accordance with
            these Terms of Use without remuneration or compensation to the third party to promote GameInfluencer.IO, the Brand or any other third party in any media and in perpetuity and
            neither you, GameInfluencer.IO nor the Brand needs to obtain any licenses or pay any royalties to any third party regarding the relevant Content. The use of the GameInfluencer.IO submitted
            Content won’t infringe any legal rights, copyright or other Intellectual Property Rights of any entity or person or any form of liability to pay compensation pertaining
            to the use by the Brand, GameInfluencer or GameInfluencer.IO.
          </p>
          <p>
            When it comes to Campaign submissions, all right, title, interest and Intellectual Property Rights in the Content will remain held by you. These Terms of Use don’t
            constitute a transfer or grant any ownership rights in the Content to any other party, including GameInfluencer.IO, GameInfluencer and the Brand. In respect to the Content submitted
            on the GameInfluencer.IO platform for application or posted as part of a Campaign, grants GameInfluencer.IO & GameInfluencer (and its agents) the right to edit, re-format the Content into such
            formats or versions for use by GameInfluencer.IO for such media as GameInfluencer.IO requires to conduct the relevant Campaign.
          </p>
          <p>
            In exchange for the Campaign related payment, you grant in relation to every Content uploaded for application or published on your YouTube Channel as part of a
            Campaign:
          </p>
          <p>
            For GameInfluencer.IO and its agents a royalty-free, perpetual, worldwide, irrevocable, unconditional, non-exclusive, transferable, sub-licensable license to use the Post for the
            purpose of marketing and promoting GameInfluencer.IO (and its products and services) in any manner, without further notification to or consent of you or any further compensation
            payable to you. Aswell as the right to use the Creator’s Identity and performances in the Content to communicate the Content to the public in all languages, in all
            media such as online paid media banners, in all online owned media such as website, and in all owned social media, and in any earned media or public relations activity
            published by a third party.
          </p>
          <p>
            For the relevant Brand and its agents, the right to organically share and comment on the Content. The right to use the Creator’s Identity and performances to
            communicate the Content to the public in accordance with the Terms of Service.
          </p>
          <p>
            Regarding the Content uploaded to GameInfluencer.IO you consent to the relevant Brand and its successors, and anyone authorized by them, changing, copying, adding to, taking from,
            translating notwithstanding that such conduct may infringe your Moral Rights in the Content and to the extent possible, you grant a waiver of all Moral Rights for each
            Content submitted via GameInfluencer.IO.
          </p>
          <p>
            In regard to the “Additional Licensing request” after completion of Campaign, where Brands might request additional license from the Creator regarding the Content
            present in the Campaign. Depended on the filled out Licensing terms and after the Creator’s decision to accept the Additional License and additional monetary
            compensation, The brand will have an additional License to promote the Creator’s Content in its own marketing efforts such as, but not limited to, the right to use the
            Creator’s Identity and performances in the Content to communicate the Content to the public in all languages, in all media such as online paid media banners, in all
            online owned media such as website, and in all owned social media, and in any earned media or public relations activity published by a third party.
          </p>
          <p>
            <strong>Confidentiality.</strong> Throughout the duration of this Agreement, it may be necessary for the relevant Parties (Creator, Brand, GameInfluencer & GameInfluencer.IO) to
            have access to each other’s confidential and protected information for the sole purpose of performing the Services subject to this Agreement. None of the Parties
            permitted to share or disclose such confidential information whatsoever, unless mandated by law, without written permission from the relevant Party. All the obligations
            of confidentiality will survive the termination of the Agreement and stay in place 6 years after completion of the relevant Campaign. Upon the termination of the
            Agreement, the Parties involved agree to return any and all Confidential Information that is the property of the other Party upon written request. They shall promptly
            return all copies, whether in written, electronic, or other form or media, of the Party’s Confidential Information, or destroy all such copies and certify in writing to
            the Party that such Confidential Information has been destroyed.
          </p>
          <p>
            This confidentiality only applies to Content that is private and does not take into consideration information that is public, lawfully acquired through third party, or
            already in possession of the same information before the confidentially agreement.
          </p>
          <p>
            <strong>Force Majeure.</strong> Any of the relevant parties shall not be in breach of this Agreement for any delay or failure in performance caused by reasons out of
            its reasonable control. This includes, but is not limited to, acts of God or a public enemy; natural calamities; failure of a third party to perform; changes in the
            laws or regulations; actions of any civil, military, or regulatory authority; power outage or other disruptions of communication methods or any other cause which would
            be out of the reasonable control of the relevant party.
          </p>
          <p>
            <strong>Monetary compensation for Creators.</strong> GameInfluencer.IO will pay the Creator after having received the campaign funds from the relevant Brand. The payment will be
            made on a Monthly basis (30 days) and are based on the relevant Campaign’s payment Model. If the Campaign’s payment model is ‘CPM’ then the amount earned from this
            campaign will activate in the following month, so payment will come in the following payment cycle (up to 60 days). Creator is only eligible for payout once they have
            posted the relevant content for the campaign correctly. Creators must also ensure they are eligible for payout through our payment Partner GigaPay.
          </p>

          <p>
            Meaning a Creator will be paid for
            <strong>Real Conversions</strong> generated through their Creator Link for said Campaign. These conversions can be in the form of a Real Install, a Real Click, or a
            Real View. A <strong>Real Install</strong> means only a real individual person that actively accesses the application via the third-party distribution service such as
            iOS app store or Google play store, and who accesses the distribution service through the Creator’s designated Creator Link for the Campaign, and then proceeds to
            install and launch the Campaign promoted application while on the Internet. A <strong>Real Click</strong> means only a real individual person that actively accesses the
            distribution service through the Creator’s designated Creator Link for the Campaign. A <strong>Real View</strong> means only a real individual person that actively
            views the creators video content through their own free will.
          </p>
          <p>
            This only applies for a real individual person and cannot be a robot script or computer generated and can also not be identified as fraudulent or a duplicate by GameInfluencer.IO,
            GameInfluencer or the Brand on a later date. A Real Conversion also means that it has come from the targeted tier specified in the relevant Campaign (e.g. Tier 1
            countries).
            <strong
              >Payments will be made for as long as the Campaign runs or until sudden shortened closure of relevant Campaign. Once the campaign has finished running, the budget is
              complete, or the campaign is closed - payments for conversions will end.</strong
            >
            Any conversions that are already paid and later identified as a non-Real Conversions will get deducted from the Creators account before subsequent payments are made.
            Any identified fraud ways of acquiring the conversions can lead to the immediate termination of the Creators account and participation in Campaigns and the Creator will
            not receive any unpaid Monthly Payments on the Creators account. Creators will need to reach a minimum of $100 earned per 30-day cycle to activate their payout; if the
            payout limit is not reached then this amount will carry over to the following month and will accumulate. Creators will be liable and responsible for their own Tax &
            Government requirements as pertaining to the Monetary Compensation received through participation of Campaigns and the GameInfluencer.IO platform. GameInfluencer.IO & GameInfluencer can choose
            to, but are not required, to declare the Monetary Compensation paid to Creators. Payments will be made through our payment Partner GigaPay; find their terms and
            conditions
            <a target='_blank' href="https://www.gigapay.co/resources/t-and-c/terms-and-conditions">here.</a>
          </p>
          <p>
            <strong>Limitation of liability.</strong> The GameInfluencer.IO Platform & GameInfluencer are in no way liable for uncorrelated, incidental, indirect, special, or exemplary damages.
            Naturally this also includes, but is not limited to, any incident that cause a direct decrease of business opportunities or even potential revenue impact even if they
            are foreseeable and we have been advised of the potential consequences. The only exemption to this case is intentional misconduct or gross negligence or any potential
            breaches regarding intellectual Property and confidentiality. We do not restrict our liability beyond what is legal by law. The total aggregate liability is preceding
            up to 6 months of damages have been claimed to happen and are limited to campaign fees paid or payable by GameInfluencer.IO & GameInfluencer. The GameInfluencer.IO Platform & GameInfluencer are
            in no way liable for uncorrelated, incidental, indirect, special, or exemplary damages. Naturally this also includes, but is not limited to, any incident that cause a
            direct decrease of business opportunities or even potential revenue impact even if they are foreseeable and we have been advised of the potential consequences.
            Creator’s will be liable and won’t hold any other party accountable or liable for damages, liabilities, costs in relation to a third-party claim regarding Creator
            provided Content or the use of the services and Content in violation of this agreement. GameInfluencer shall not be liable for the Content on Creator Ads, for damages
            or other disturbances caused by defects and / or interoperability of Creator Ads and / or for damages resulting from the insufficient availability or imperfect
            functioning of the internet and / or the app stores. The only exemption to this case is intentional misconduct or gross negligence or any potential breaches regarding
            intellectual Property and confidentiality. We do not restrict our liability beyond what is legal by law.
          </p>
          <p>
            <strong>Termination.</strong> This agreement has a termination period of 1 month and can be terminated by any of the relevant Parties upon written notice. The written
            notice should be given in case either party breaches the preceding agreement. If the other party does not fix the breach of the agreement within 12 days of receiving
            written notice, the agreement will be terminated. Regarding any potential liability that was formed prior to the termination of the agreement, it will still hold. Also,
            regarding Influencer uploaded Content that was submitted on GameInfluencer.IO before the termination, all the relevant licenses and granted rights that were given to GameInfluencer.IO,
            GameInfluencer, and Brand will also hold regardless of the termination. Finally, when the agreement is terminated, all rights and licenses that were given to the
            Influencer will terminate immediately.
          </p>
          <p>
            <strong>Promotion.</strong> For the nature of the platform, The Influencer agrees that GameInfluencer.IO & Gameinfluencer have the right to use channel name, likeness, and biography
            for promoting the Influencer to other Brands as part of the GameInfluencer.IO’s services.
          </p>
          <p>
            <strong>Severability.</strong> If any term or provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or
            unenforceability shall not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction.
          </p>
          <p>
            <strong>Independent Contractor.</strong> GameInfluencer.IO, Gameinfluencer, Brand and Influencer expressly agree and understand that the above-listed Influencer is a contractor
            hired by GameInfluencer.IO and nothing in this Agreement shall be construed in any way or manner, to create between them a relationship of employer and employee. The Influencer is
            not entitled to receive any other compensation or any benefits from GameInfluencer.IO, Gameinfluencer and Brand. Except as otherwise required by law, the parties shall not withhold
            any sums or payments made to the Influencer for social security or other federal, state, or local tax liabilities or contributions, and all withholdings, liabilities,
            and contributions shall be solely the Influencer’s responsibility. The Influencer further understands and agrees that the Services are not covered under the
            unemployment compensation laws and are not intended to be covered by workers’ compensation laws. GameInfluencer.IO and Gameinfluencer are solely responsible for directing and
            controlling the performance of the Services, including the time, place, and manner in which the Services are performed. The Influencer shall use its best efforts,
            energy, and skill in its own name and in such manner as it sees fit.
          </p>
          <p>
            <strong>Platform Protection.</strong> Users aren’t allowed to extract data, copy, or reverse engineer any of GameInfluencer.IO platform source code, or damage & disrupt GameInfluencer.IO in any
            manner possible throughout multiple universes and in perpetuity. Any violation of the agreement terms and usage will be grounds for a lawsuit.
          </p>
          <p>
            <strong>Assigned Rights.</strong> Gameinfluencer & GameInfluencer.IO have all the right to assign rights and obligations entirely to a third-party business acquirer or partner
            without prior consent from Influencer. The Influencer isn’t allowed to delegate or assign any of the rights & obligations outlined in the agreement to a third-party due
            to the nature of the agreement requiring the Influencer’s personal likeness.
          </p>
          <p>
            <strong>Dispute Resolution and Governing Law.</strong> Parties to this Agreement shall first attempt to settle any dispute through good-faith negotiation. If the
            dispute cannot be settled between the parties via negotiation, either party may initiate mediation or binding arbitration in German Law. Any dispute should be aimed to
            solve through negotiations, however if the parties cannot solve the dispute through negotiations within 69 days, the case can be taken to court. All disputes arising in
            connection with this agreement or even parts thereof or its validity shall be finally settled according to the Arbitration Rules and the Supplementary Rules for
            Expedited Proceedings of the German Institution of Arbitration e.V. (DIS) without recourse to the ordinary courts of law. The place of arbitration is Munich. The
            substantive law is German law. The language is German or, by discretion of the judge, English. Only one judge shall oversee the dispute, which shall be appointed by the
            DIS. The Parties shall have the right to challenge the judge if they find him not to be materially familiar with the issues and/ or the industry in question. The Terms
            of Use and their interpretation shall be governed by the laws of Germany with the exception of the UN Convention on Contracts for the International Sale of Goods dated
            11.4.1980.
          </p>
          <p>
            <strong>Legal Fees.</strong> Should a dispute between the named Parties arise lead to legal action, the prevailing Party shall be entitled to any reasonable legal fees,
            including, but not limited to attorneys’ fees.
          </p>
        </div>
        <div v-if="acceptCampaignPrompt">
          <div class="p-mt-4 agreement">
            <Checkbox id="agreement" v-model="acceptedTerms" :binary="true" class="p-mt-4" />
            <label for="agreement" class="p-ml-2">
              I agree with the
              <a href="#">Terms And Conditions</a>
              for this Campaign
            </label>
          </div>
          <Button label="Accept Campaign" class="continue-button" @click="termsModalClose" />
          <MemberModal v-model:is-show="showMemberModal" @member-modal-close="modalClose"></MemberModal>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import MemberModal from "@/pages/creators/MemberModal.vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import { useCreators, useAuth } from "../../modules/index";

export default {
  components: {
    MemberModal,
    Button,
    Checkbox,
    Dialog,
  },
  props: {
    show: Boolean,
    acceptCampaignPrompt: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx, emit) {
    const showing = ref(props.show);
    const showMemberModal = ref(false);
    const acceptedTerms = ref(false);
    const creatorModule = useCreators();
    const { user } = useAuth();

    const termsModalClose = async () => {
      if (acceptedTerms.value && user.value) {
        showMemberModal.value = true;
        const response = await creatorModule.manager.creatorAgreeSiteRules(user.value.id, true);

        if (response === "OK") {
          ctx.emit("acceptTerms"); // trigger sending a campaign application
        }
      }

      ctx.emit("closeModal");
    };

    const modalClose = () => {
      showMemberModal.value = false;
      ctx.emit("closeModal");
    };
    
    return {
      showMemberModal,
      acceptedTerms,
      termsModalClose,
      modalClose,
      showing,
    };
  },
};
</script>
<style scoped lang="scss">
.image {
  position: absolute;
  margin-left: 20px;
  width: 100px;
  height: 100px;
}

.text-box {
  width: 875px;
  height: 295px;
  overflow-y: scroll;
}

.title-main {
  font-weight: 800;
  font-size: 30px;
  line-height: 50px;
  color: #181818;
  position: absolute;
  margin-left: 60px;
  margin-top: 30px;
}

.terms-conditions {
  font-weight: 700;
  font-size: 12px;
  margin: 0px 250px 10px 0px;
  line-height: 15px;
}

.continue-button {
  margin-top: 90px;
  border: none;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  width: 198px;
  height: 40.12px;
  background: #e0010b;
  box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.agreement {
  font-weight: 600;
  font-size: 12rem;

  a {
    color: $giRed;
    text-decoration: underline;
    font-weight: 700;

    &:hover {
      text-decoration: none;
    }
  }
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight) {
  background-color: #e0010b;
  color: #ffff;
}

div::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #ffffff;
}

div::-webkit-scrollbar-thumb {
  background: #686e6e;
  border-radius: 5px;
}
</style>
