import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fcd72de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "campaign-photo" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  src: "/images/campaign-default.svg",
  alt: "",
  referrerpolicy: "no-referrer",
  class: "default"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.campaignPhoto ? _ctx.campaignPhoto : _ctx.campaign?.photo)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.campaignPhoto ? _ctx.campaignPhoto : _ctx.campaign?.photo,
          alt: "",
          referrerpolicy: "no-referrer",
          class: "real"
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("img", _hoisted_3))
  ]))
}