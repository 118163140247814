import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewContentItem = _resolveComponent("NewContentItem")!
  const _component_OldContentItem = _resolveComponent("OldContentItem")!

  return (_ctx.isContentItemsPageEnabled)
    ? (_openBlock(), _createBlock(_component_NewContentItem, {
        key: 0,
        handleReturn: _ctx.handleReturn,
        item: _ctx.item,
        user: _ctx.user,
        goToCreatorProfile: _ctx.goToCreatorProfile,
        goToOtherContentItem: _ctx.goToOtherContentItem
      }, null, 8, ["handleReturn", "item", "user", "goToCreatorProfile", "goToOtherContentItem"]))
    : (_openBlock(), _createBlock(_component_OldContentItem, {
        key: 1,
        itemID: _ctx.itemID,
        creatorID: _ctx.creatorID
      }, null, 8, ["itemID", "creatorID"]))
}