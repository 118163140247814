import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewPendingPayments = _resolveComponent("NewPendingPayments")!
  const _component_OldPendingPayments = _resolveComponent("OldPendingPayments")!

  return ($setup.isNewAdminEnabled)
    ? (_openBlock(), _createBlock(_component_NewPendingPayments, {
        key: 0,
        data: $setup.data,
        limit: $setup.limit,
        changePage: $setup.pageChange,
        search: $setup.searchPayment,
        loading: $setup.loading
      }, null, 8, ["data", "limit", "changePage", "search", "loading"]))
    : (_openBlock(), _createBlock(_component_OldPendingPayments, { key: 1 }))
}