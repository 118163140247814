
import { PropType } from 'vue';
import { User } from '@/data/types';
import UserPhoto from '@/pages/commons/user/UserPhoto.vue';

export default {
  name: 'CreatorListItem',
  components: { UserPhoto },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup() {
    const calculateFollowers = (followers: Array<any>) => {
      const initialValue = 0;
      const sumWithInitial = followers.reduce(
          (accumulator, currentValue) => accumulator.followers || 0 + currentValue.followers || 0,
          initialValue
      );
      return sumWithInitial;
    }

    return {
      calculateFollowers,
    }
  }
};
