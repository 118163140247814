<template>
  <div class="slide-height">
    <div class="image-background">
      <img class="page-background" />
    </div>
    <div class="p-flex-column landing-header">
      <div class="io-text">GameInfluencer.IO</div>
      <div class="io-connect">{{ title }}</div>
      <div class="io-text-long-influencer">
        Your Gateway to Scalable Performance-Based Campaigns
      </div>
      <!-- <div class="io-text-long-influencer" v-if="view === 'creator'">
        Unleash your full potential as creator
      </div>
      <div class="io-text-long-influencer" v-if="view === 'brand'">
        Promote your game through influencer marketing campaigns
      </div> -->
      <button class="join-btn" @click="$router.push({ name: 'login' })">JOIN NOW</button>
    </div>

    <div class="landing-footer">
      <div class="p-d-flex p-ai-center camp-footer">
        <img src="../../assets/SteelSeries.png" class="camp-image" />
        <div class="footer-text">
          <div class="p-flex-column">
            <div class="camp-name">New {{ currentCampaign.name }} Campaign!</div>
            <div class="camp-footer-text">
              Test Arctis NOVA 5 console headsets!
            </div>
          </div>
        </div>
        <div>
          <button class="explore-btn join-btn" @click="$router.push({ name: 'login' })">EXPLORE</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  props: {
    view: {
      type: String,
    },
    currentCampaign: {
      name: String,
      amount: String,
      logoPath: String, // not in use currently
    }
  },
  setup() {
    const titles = ref(['Connect', 'Create', 'Promote', 'Track','Enjoy']);
    const title = ref('');
    const typing = ref(false);
    const currentIndex = ref(0);
    const typeSpeed = ref(100); // Adjust this to change the speed of typing
    const eraseDelay = ref(1000); // Adjust this to change the delay before erasing starts
    const nextTitleDelay = ref(1000); // Adjust this to change the delay before next title shows up

    onMounted(() => {
      typeTitle();
    });

    const typeTitle = () => {
      typing.value = true;
      const fullTitle = titles.value[currentIndex.value];
      let i = 0;
      const typeInterval = setInterval(() => {
        title.value += fullTitle[i];
        i++;
        if (i === fullTitle.length) {
          clearInterval(typeInterval);
          setTimeout(() => {
            eraseTitle();
          }, eraseDelay.value);
        }
      }, typeSpeed.value);
    };

    const eraseTitle = () => {
      const fullTitle = titles.value[currentIndex.value];
      let i = fullTitle.length - 1;
      const eraseInterval = setInterval(() => {
        title.value = title.value.slice(0, -1);
        i--;
        if (i < 0) {
          clearInterval(eraseInterval);
          typing.value = false;
          setTimeout(() => {
            showNextTitle();
          }, nextTitleDelay.value);
        }
      }, typeSpeed.value);
    };

    const showNextTitle = () => {
      currentIndex.value = (currentIndex.value + 1) % titles.value.length;
      typeTitle();
    };

    return {
      title,
    };
  },
});
</script>

<style scoped lang="scss">
* {
  font-family: 'Montserrat', sans-serif;
}

.image-background {
  background-size: cover;
  background-image: url('@/assets/starting-page-background.png');
  background-position: center;
  width: 100%;
  height: 800px;
}

.page-background {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background: linear-gradient(to bottom right, #130A23 14.17%, rgba(19, 10, 35, 0) 88%)
}

.io-text-long-influencer {
  font-weight: 500;
  font-size: 32rem;
  color: #FFFFFF;
  line-height: 39px;
  width: 100%;
}

.landing-footer {
  line-height: 1.5;
  width: 100%;
  background-color: #E73D38;
  margin: 0;
  position: relative;
  margin-top: -1px;
}

.camp-footer {
  margin-left: 6%;
  padding: 30px 0px;
}

.camp-image {
  width: 86px;
  height: 86px;
}

.footer-text {
  margin-left: 2%;
  margin-right: 2%;
}

.camp-name {
  color: #FFFFFF;
  font-size: 30rem;
  font-weight: 600;
}

.camp-footer-text {
  color: #FFFFFF;
  font-size: 24rem;
}

.explore-btn {
  background: #FFFF;
  color: #E6343C;
  margin-left: 100px;
  margin-right: 30px;
}

.slide-height {
  position: relative;
  margin-left: -1px;
  margin-top: -1px;
}

.explore-btn:hover {
  background: #E73D38;
  color: #FFFFFF;
}

.landing-header {
  position: absolute;
  top: 20%;
  left: 6%
}

.io-text {
  font-weight: 700;
  font-size: 36rem;
  color: #E73D38;
  line-height: 43px;
}

.io-connect {
  font-weight: 800;
  font-size: 128rem;
  color: #FFFFFF;
  line-height: 156px;
  margin-top: 2%;
  height: 156rem;
}

.join-btn {
  margin-top: 70px;
}

@media screen and (min-width: 767px) {
  .explore-btn {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 1100px) {
  .camp-name {
    font-size: 28rem
  }

  .camp-footer-text {
    font-size: 22rem;
  }

  .io-text {
    font-size: 32rem;
  }

  .io-connect {
    font-size: 112rem;
    height: 112rem;
    line-height: 112rem;
  }

  .io-text-long-influencer {
    font-size: 28rem;
  }
}

@media screen and (max-width: 1000px) {
  .camp-footer-text {
    font-size: 18rem;
  }
}

@media screen and (max-width: 800px) {
  .camp-name {
    font-size: 24rem
  }

  .camp-footer-text {
    font-size: 18rem;
  }

  .explore-btn {
    margin-left: 4vw;
  }

  .io-text {
    font-size: 28rem;
  }

  .io-connect {
    font-size: 100rem;
    line-height: 100rem;
    height: 100rem;
  }

  .io-text-long-influencer {
    font-size: 24rem;
    line-height: 26rem;
  }

  .join-btn {
    margin-top: 6%;
  }
}

@media screen and (max-width: 768px) {
  .image-background {
    background-image: url('@/assets/blood-line-mobile.png');
    background-size: cover;
  }

  .page-background {
    background-image: linear-gradient(to bottom, rgba(19, 10, 35, 0.8), rgba(19, 10, 35, 0.2));
  }

  .camp-image {
    margin: 2% auto;
    display: block;
  }

  .camp-name {
    text-align: center
  }

  .camp-footer {
    flex-direction: column;
    margin-left: 0%;
  }

  .camp-footer-text {
    text-align: center
  }

  .explore-btn {
    margin: 2% auto;
    display: block;
  }

  .landing-header {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 111;
  }

  .io-text {
    text-align: center;
    margin-top: 170px;
  }

  .io-connect {
    text-align: center;
    font-size: 80px;
    height: 80px;
    line-height: 80px;
  }

  .io-text-long-influencer {
    text-align: center;
    margin: auto;
    width: 360px;
  }

  .join-btn {
    margin: 0px auto;
    margin-top: 25px;
  }
}

@media screen and (max-width: 578px) {
  .camp-name {
    font-size: 16rem;
  }

  .camp-footer-text {
    font-size: 14rem;
  }

  .explore-btn {
    margin: 25px auto;
    position: static !important;
  }

  .image-background {
    height: 360px;
  }

  .landing-header {
    top: 28%;
  }
}

@media screen and (max-width: 413px) {
  .page-background {
    height: 100%;
  }

  .image-background {
    height: 390px;
  }

  .camp-name {
    font-size: 18rem;
  }

  .camp-footer-text {
    font-size: 12rem
  }

  .landing-header {
    position: absolute;
    top: 32%;
  }

  .io-connect {
    font-size: 60rem;
    line-height: 60px;
    height: 60px;
  }

  .io-text-long-influencer {
    font-size: 14rem;
    font-weight: 500;
    width: 300px;
  }

  .join-btn {
    position: absolute;
    left: 28%;
    top: 100%;
  }

  .landing-header {
    top: 15%;
  }
}

@media screen and (min-width: 991px) {
  .landing-footer {
    position: absolute;
    bottom: 0px;
  }
}</style>
