import React, { useState } from "react";
import "./NewAdminAuthorization.css";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";

export default function(props){
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return(
    <div className={'admin-auth'}>
      <p className={'authTitle'}>Authorization</p>
      <div className="p-mb-4">
        <p className={'inputFieldLabel'}>Email</p>
        <InputText className={'inputField'} value={email} onChange={(e) => setEmail(e.target.value)}/>
      </div>
      <div>
        <p className={'inputFieldLabel'}>Password</p>
        <Password value={password} onChange={(e) => setPassword(e.target.value)} feedback={false} toggleMask/>
      </div>
      <Button label={"SIGN IN"} className={'signInButton'} onClick={() => props.signInAdmin({email, password})}/>
    </div>
  )
}
