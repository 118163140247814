import React, { useEffect, useState } from "react";
import "./NewBrandInfoPage.css";
import { BackArrowIcon } from "@/react_app/NewAdmin/img/Icons";
import { Image } from 'primereact/image';
import { Divider } from 'primereact/divider';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { CampaignStatus } from "@/data/types";
import { formatDate } from '@/utils/globals/date-utils';
import { compactNumber } from '@/utils/globals/curency-utils';
import noImage from "../../img/no-image.png";
import {useBrands, useCampaigns} from "@/modules";

const campaignManagementTabs = [
  { value: 'All', type: 'all' },
  { value: 'Active', type: 'active' },
  { value: 'Drafts', type: 'draft' },
  { value: 'Completed', type: 'completed' },
  { value: 'Pending', type: 'pending' },
];

const campaignBodyTemplate = (rowData) => {
  return (
    <div className={'campaignTemplate'}>
      <Image src={rowData.photo} alt={'campaign cover'} className={'campaignTemplateImage'}/>
      <div className={'campaignTemplateText'}>
        <p className={'campaignTemplateTitle'}>{rowData.name}</p>
        <p className={'campaignTemplateDescription'}>{rowData.productName}</p>
      </div>
    </div>
  );
};

const statusBodyTemplate = (rowData) => {
  return (
    <div className={'statusTemplate'}>
      {rowData.status === CampaignStatus.Active &&
        <div className={'statusTemplateBox statusActive'} >
          Active
        </div>
      }
      {rowData.status === CampaignStatus.Draft &&
        <div className={'statusTemplateBox statusDraft'} >
          Draft
        </div>
      }
      {rowData.status === CampaignStatus.Completed &&
        <div className={'statusTemplateBox statusCompleted'} >
          Completed
        </div>
      }
      {rowData.status === 'pending' &&
        <div className={'statusTemplateBox statusDraft'} >
          Pending
        </div>
      }
    </div>
  );
};

const marketBodyTemplate = (rowData) => {
  return (
    <div className={'marketTemplate'}>
      {rowData.targetMarket.title}
    </div>
  );
};

const dateBodyTemplate = (rowData) => {
  return (
    <div className={'dateTemplate'}>
      {rowData.dates.endDate ?
        formatDate(rowData.dates.endDate) :
        '—'
      }
    </div>
  );
};

const priceBodyTemplate = (rowData) => {
  return (
    <div className={'priceTemplate'}>
      {compactNumber(rowData.budget.amount)}{' '}{rowData.budget.currency}
    </div>
  );
};

export default function(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);

  const brands = useBrands();

  useEffect(() => {
    brands.manager.loadBrand(props.brand?._id).then((result) => {
      setAllCampaigns(result.campaigns);
      setFilteredCampaigns(result.campaigns);
    });
  }, [props.brand]);

  useEffect(() => {
    const filteredCampaignsValue = campaignManagementTabs[activeTab].type === 'all' ? allCampaigns : allCampaigns.filter(campaign => campaign.status === campaignManagementTabs[activeTab].type);
    setFilteredCampaigns(filteredCampaignsValue);
    setSearchValue('');
  }, [activeTab]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    const filteredCampaignsValue = allCampaigns.filter((campaign) => campaign.name.toLowerCase().includes(e.target.value.toLowerCase()));
    setFilteredCampaigns(filteredCampaignsValue);
  }

  return (
    <div className={'brand-info-page'}>
      <div className={'returnWrapper'} onClick={() => props.handleReturn()}>
        <BackArrowIcon/>
        Overview Brands
      </div>
      <div className={'mainInfoWrapper'}>
        <div className={'mainInfoSquare'}>
          <Image alt={'brand photo'} src={props.brand?.photo ? props.brand?.photo : noImage} className={'brandPhoto'}/>
          <p className={'brandName'}>{props.brand?.name}</p>
          <p className={'brandLabel'}>Brand</p>
        </div>
        <div className={'legalInfo'}>
          <p className={'brandInfoTitle'}>Brand Info</p>
          <div className={'infoGroupsWrapper'}>
            <div className={'infoGroup'}>
              <p className={'infoData'}><span className={'infoLabel'}>Person type: </span>{props.brand?.uniquePayments[0]?.paymentInformation?.PersonType ?? '-'}</p>
              <p className={'infoData'}><span className={'infoLabel'}>Legal person type: </span>{props.brand?.uniquePayments[0]?.paymentInformation?.LegalPersonType ?? '-'}</p>
              <p className={'infoData'}><span className={'infoLabel'}>Birthday: </span>{props.brand?.uniquePayments[0]?.paymentInformation?.Birthday ?? '-'}</p>
            </div>
            <Divider layout="vertical" className={'verticalDivider'}/>
            <div className={'infoGroup'}>
              <p className={'infoData marginDouble'}>Legal representative address</p>
              <p className={'infoData'}><span className={'infoLabel'}>Address line 1: </span>{props.brand?.uniquePayments[0]?.paymentInformation?.LegalRepresentativeAddress?.AddressLine1 ?? '-'}</p>
              <p className={'infoData'}><span className={'infoLabel'}>City: </span>{props.brand?.uniquePayments[0]?.paymentInformation?.LegalRepresentativeAddress?.City ?? '-'}</p>
              <p className={'infoData'}><span className={'infoLabel'}>Postal code: </span>{props.brand?.uniquePayments[0]?.paymentInformation?.LegalRepresentativeAddress?.PostalCode ?? '-'}</p>
              <p className={'infoData'}><span className={'infoLabel'}>Country: </span>{props.brand?.uniquePayments[0]?.paymentInformation?.LegalRepresentativeAddress?.Country ?? '-'}</p>
            </div>
            <Divider layout="vertical" className={'verticalDivider'}/>
            <div className={'infoGroup'}>
              <p className={'infoData'}><span className={'infoLabel'}>First Name: </span>{props.brand?.uniquePayments[0]?.paymentInformation?.FirstName ?? '-'}</p>
              <p className={'infoData'}><span className={'infoLabel'}>Last Name: </span>{props.brand?.uniquePayments[0]?.paymentInformation?.LastName ?? '-'}</p>
              <p className={'infoData'}><span className={'infoLabel'}>Nationality: </span>{props.brand?.uniquePayments[0]?.paymentInformation?.Nationality ?? '-'}</p>
              <p className={'infoData'}><span className={'infoLabel'}>Country of residence: </span>{props.brand?.uniquePayments[0]?.paymentInformation?.CountryOfResidence ?? '-'}</p>
            </div>
          </div>
        </div>
      </div>
      <p className={'campaignsTitle'}>Campaigns</p>
      <div>
        <div className={'tabsWrapper'}>
          <div className={'tabs'}>
            {campaignManagementTabs.map((item, index) =>
              <div key={index} className={index === activeTab ? 'tabSelected' : 'tabNormal'} onClick={() => setActiveTab(index)}>
                {item.value}
              </div>)}
          </div>
        </div>
        <div className={'campaignsSearchBarWrapper'}>
          <i className="pi pi-search" style={{ position: 'absolute', left: '11px', top: '10px' }}/>
          <InputText className={'campaignsSearchBar'} placeholder='Search for campaign' value={searchValue} onChange={(e) => handleSearch(e)}/>
        </div>
      </div>
      <DataTable value={filteredCampaigns} removableSort emptyMessage={"No campaigns found."}>
        <Column field={"name"} header={"Campaign"} body={campaignBodyTemplate} sortable style={{ width: '40%' }}></Column>
        <Column field={"status"} header={"Status"} body={statusBodyTemplate} sortable style={{ width: '15%' }}></Column>
        <Column field={"targetMarket.title"} header={"Market"} body={marketBodyTemplate} sortable style={{ width: '15%' }}></Column>
        <Column field={"dates.endDate"} header={"Time Left"} body={dateBodyTemplate} sortable style={{ width: '15%' }}></Column>
        <Column field={"budget.amount"} header={"Price"} body={priceBodyTemplate} sortable style={{ width: '15%' }}></Column>
      </DataTable>
    </div>
  )
}
