
import { defineComponent, ref } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

export interface ModalPageConfig {
  title: string;
  component: string;
  dismissableMask: boolean;
  useConfirm: boolean;
  acceptLabel: string;
  rejectLabel: string;
  payload?: unknown;
}

const defaultConfig: ModalPageConfig = {
  title: process.env.VUE_APP_PRODUCT_NAME || 'System',
  component: 'div',
  dismissableMask: true,
  useConfirm: false,
  acceptLabel: 'Accept',
  rejectLabel: 'Reject',
};

export default defineComponent({
  components: { Button, Dialog },

  props: ['internalComponent'],

  setup(props, { emit }) {
    const pageHeader = ref('');
    const isModalVisible = ref(false);
    const pageComponent = ref();
    const config = ref(defaultConfig);
    const acceptLabel = ref('');
    const rejectLabel = ref('');
    const payload = ref();

    const open = (modalConfig: ModalPageConfig): void => {
      config.value = { ...defaultConfig, ...modalConfig };

      pageHeader.value = config.value.title;
      pageComponent.value = config.value.component;
      acceptLabel.value = config.value.acceptLabel;
      rejectLabel.value = config.value.rejectLabel;
      payload.value = config.value.payload;

      isModalVisible.value = true;
    };

    const close = () => {
      isModalVisible.value = false;
      emit('close', payload.value);
    };

    const accept = (): void => {
      close();
      emit('accept', payload.value);
    };

    const reject = (): void => {
      close();
      emit('reject', payload.value);
    };

    return {
      isModalVisible,
      open,
      close,
      accept,
      reject,
      config,
      pageHeader,
      pageComponent,
      acceptLabel,
      rejectLabel,
    };
  },
});
