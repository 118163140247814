
import { ref } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useAuth, useCreators } from '@/modules';
import { CampaignData, User, CampaignPaymentMethod } from '@/data/types';
import { useRoute } from 'vue-router';
import { formatDate } from '../../../utils/globals/date-utils';
import { findCurrentMetricsMontly } from '../../../utils/campaign-utils';

export default {
  name: 'Campaigns',
  components: {
    DataTable,
    Column,
  },
  props: {
    latestContracts: Array,
  },
  setup(props: any) {
    const { user } = useAuth();
    const route = useRoute();
    const creatorsModule = useCreators();
    const cpmStats = ref();

    const latestContractesMyArray = ref(props.latestContracts?.reverse());
    const clearFilters = () => {
      latestContractesMyArray.value = props.latestContracts?.reverse();
    };
    const expandedRows = ref<Array<any>>([]);

    if (props.latestContracts?.length) {
      const campaignsCpmIds: string[] = [];
      props.latestContracts.forEach((campaign: any) => {
        if (campaign?.paymentMethod === 'CPM') {
          campaignsCpmIds.push(campaign?.id);
        }
      });
      if (campaignsCpmIds?.length) {
        creatorsModule.manager.earningGeneratedSoFarCpm(campaignsCpmIds, route.params?.creatorID as string).then((data: any) => {
          cpmStats.value = data;
        });
      }
    }

    const currentRowWorkers = (campaign: any) => {
      const currentChannelsIDs: any[] = [];
      campaign.contracts.forEach((contract: any) => {
        currentChannelsIDs.push(contract.channel);
      });
      return user.value?.agencyWorkers?.length
        ? user.value?.agencyWorkers.filter((worker: any) => currentChannelsIDs.includes(worker.channel.channelId))
        : [];
    };

    const expandItem = (data: any) => {
      const index = expandedRows.value.findIndex((camp) => camp?.id.toString() === data?.id.toString());
      if (index < 0) {
        expandedRows.value = [data];
      }
      if (index >= 0) {
        expandedRows.value = [];
      }
    };

    const totalChannelsSoFar = (campaign: CampaignData, currentUser: User, isAgency: boolean): {
      IOS: number;
      android: number;
      currency: string;
      total: number;
    } => {
      const totalPayedPerMouth = {
        IOS: 0,
        android: 0,
        total: 0,
        currency: '',
      };
      let filteredLinks: any[] = [];
      if (isAgency) {
        const agencyChannels: Array<string> = [];
        if (currentUser.agencyWorkers?.length) {
          currentUser.agencyWorkers.forEach((channel: { channel: { channelId: string } }) => {
            agencyChannels.push(channel.channel.channelId);
          });
        }
        filteredLinks = campaign.trackingLinks.filter((channel) => (channel.channelId ? agencyChannels
          .includes(channel.channelId) : null));
      } else {
        filteredLinks = campaign.trackingLinks.filter((linkObject) => linkObject?.creatorId?.includes(currentUser?.id));
      }

      if (campaign.paymentMethod === CampaignPaymentMethod.CPI && campaign?.cpi) {
        filteredLinks.forEach((link) => {
          totalPayedPerMouth.IOS += link.paidFor.IOS;
          totalPayedPerMouth.android += link.paidFor.android;
        });
        totalPayedPerMouth.currency = campaign.budget?.currency;
        totalPayedPerMouth.IOS *= campaign.cpi.ios.amount;
        totalPayedPerMouth.android *= campaign.cpi.android.amount;
        totalPayedPerMouth.total = totalPayedPerMouth.android + totalPayedPerMouth.IOS;
      }
      if (campaign.paymentMethod === CampaignPaymentMethod.CPC && campaign?.cpc) {
        filteredLinks.forEach((link) => {
          totalPayedPerMouth.total += link?.paidFor?.clicks;
        });
        totalPayedPerMouth.currency = campaign.budget?.currency;
      }
      return totalPayedPerMouth;
    };

    const getMountlyEarning = (campaignWithContract: any, isAgency: boolean) => {
      let stats;
      if (isAgency) {
        const currentChannelId = campaignWithContract?.contracts[0]?.channel;
        stats = findCurrentMetricsMontly(currentChannelId, campaignWithContract, true);
      } else {
        const currentCreatorId = route.params?.creatorID;
        stats = findCurrentMetricsMontly(currentCreatorId, campaignWithContract, false);
      }
      return stats;
    };

    const findOneChannelCpmMetric = (
      channelsMetrics: Array<{
          amount: number;
          currency: string;
          channelId: string; }>,
      currentChannel: string,
    ) => channelsMetrics.find((el) => el?.channelId === currentChannel);

    return {
      getMountlyEarning,
      findOneChannelCpmMetric,
      expandItem,
      clearFilters,
      formatDate,
      findCurrentMetricsMontly,
      totalChannelsSoFar,
      latestContractesMyArray,
      CampaignPaymentMethod,
      currentRowWorkers,
      user,
      expandedRows,
      cpmStats,
    };
  },
};
