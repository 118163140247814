
import { defineComponent, ref, watch } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import CropperImage from '../../campaigns/view/CropperImage.vue';

export default defineComponent({
  name: 'InfluencerEdit',
  components: {
    Button,
    Dialog,
    InputText,
    Textarea,
    CropperImage,
  },
  props: {
    photo: String,
    show: Boolean,
    creator: Object,
    isAgency: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['save', 'update:show'],
  setup(props, ctx) {
    const name = ref(props.creator?.name || props.creator?.agencyData?.name || '');
    const aboutInfluencer = ref();
    const availablePhotos = ref(props.photo ? [props.photo] : []);
    const uploadPhotoInput = ref();
    const base64Photo = ref();
    const selectedPhoto = ref(props.photo || '');
    const showCrop = ref(false);
    const currentIndex = ref(0);

    watch(() => props.show, () => {
      aboutInfluencer.value = props.isAgency ? props?.creator?.agencyData?.description : props?.creator?.profile?.description;
      name.value = props.isAgency ? props?.creator?.agencyData?.name : props?.creator?.name;
      selectedPhoto.value = props.photo || '';
    });

    const handleFileUpload = () => {
      if (uploadPhotoInput.value) {
        const file = uploadPhotoInput.value.files[0];

        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result === null || typeof reader.result !== 'string') {
            return;
          }
          base64Photo.value = reader.result;
          availablePhotos.value.push(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
    const updateProfile = () => {
      ctx.emit('save', {
        name: name.value,
        photo: base64Photo.value,
        description: aboutInfluencer.value,
      });
    };

    const cropMainImage = (data: string) => {
      selectedPhoto.value = data;
      base64Photo.value = data;
      availablePhotos.value[currentIndex.value] = data;
      showCrop.value = !showCrop.value;
    };

    return {
      currentIndex,
      cropMainImage,
      updateProfile,
      name,
      aboutInfluencer,
      base64Photo,
      showCrop,
      handleFileUpload,
      uploadPhotoInput,
      availablePhotos,
      selectedPhoto,
    };
  },
});
